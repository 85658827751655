import React, { useEffect, useState } from "react";
import {
    apiConstants,
    get,
    getDownload,
    postMultipart,
} from "../../common/constants";
import {
    FormProvider,
    RHFFileField,
} from "../../common/hook-form";
import { useForm } from "react-hook-form";
// import * as Yup from "yup";
import {
    Button,
    Grid,
    Card,
    CardContent,
    Link,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// import { yupResolver } from "@hookform/resolvers/yup";
import {
    formatDate,
    // yupFileValidation,
} from "../../helpers/UtilityHelper";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { DataGrid } from "@mui/x-data-grid";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../common/MUIModal";
import PageTitle from "../../common/PageTitle";
import { FileDownloadOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const NDRBulkUpload = () => {
    // const { data: allCouriers } = useGetCouriersQuery();
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [errorMsg, setErrorMsg] = useState()
    const [dataa, setData] = useState([])
    const [page, setPage] = useState(0);
    const methods = useForm();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page1, setPage1] = useState(0);
    const [sizePerPage1, setSizePerPage1] = useState(10);
    const [reload, setReload] = useState(0)
    const [seeOrders, setSeeOrders] = useState(false)
    const [orderData, setOrderData] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    // const methodsBulkUpload = useForm({
    //     resolver: yupResolver(
    //         Yup.object().shape({
    //             ndrFile: Yup.mixed().required("Please Select a File"),
    //         })
    //     ),
    //     defaultValues: {
    //         ndrFile: ''
    //     }
    // });
    const { handleSubmit: handleSubmitBulkUpload, reset: resetForm } = methods;

    const onSubmitBulkUpload = async (data) => {
        setIsUploadingFile(true);
        try {
            let form = new FormData();
            form.append("file", data.ndrFile[0])
            form.append("remark", 'NDR Bulk Upload')
            await postMultipart(apiConstants.NDR_BULK_UPLOAD, form).then((res) => {
                setIsUploadingFile(false)
                if (res.status === 200) {
                    if (res.data && res.data?.status === 'ERROR') {
                        enqueueSnackbar("File Upload Failed", { variant: "error" });
                        setErrorMsg(res.data?.errorMsg)
                        setIsUploadingFile(false)
                    } else {
                        enqueueSnackbar("Successfully Uploaded!", { variant: "success" });
                        setIsUploadingFile(false)
                    }
                    // setResult(res?.data?.responseObj)
                }
            })
            setReload(reload + 1);
            setIsUploadingFile(false)
            resetForm()
            // navigate("/orders");
        } catch (err) {
            const message = err?.response?.data?.message || err.message;
            enqueueSnackbar(message || "Error uploading file", { variant: "error" });
            setIsUploadingFile(false)
        }
        resetForm({
            ndrFile: ''
        })
        setIsUploadingFile(false);
    };


    const handleBulkSample = () => {
        getDownload(
            `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=NDR_BULK_ACTION_V2`
        )
            .then((response) => {
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "bulk-upload-sample.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_BULK_UPLOAD_NDR}`).then((res) => {
            setData(res?.data?.responseObj)
        })
    }, [reload])

    const columnsOrderModal = [
        {
            field: "awb",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "AWB",
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.awbNumber

            },
        },
        {
            field: "action",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "NDR Action",
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.action

            },
        },
        {
            field: "remark",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Remark",
            flex: 2,
            renderCell: (params) => {
                const { row } = params;
                return row?.response?.remark

            },
        },
        {
            field: "actionBy",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Action User",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.actionedBy

            },
        },
    ];

    const columns = [
        {
            field: 'id', headerAlign: "center", hideSortIcons: true, headerName: 'Id', flex: 1.2
        },
        {
            field: 'file', headerAlign: "center", hideSortIcons: true, headerName: 'Uploaded File', flex: 1,
            align: 'center', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Link href={row?.s3RefToFile?.signedLink} target="_blank" rel='noreferrer' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}>File</Link>
                    </>
                )
            },
        },
        {
            field: 'fileProgress', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1
        },
        {
            field: 'createdAt', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Upload Date', width: 150, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {formatDate(row?.createdAt)}
                    </>
                )
            },
        },
        {
            field: 'totalRecords', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Upload Status', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Total Shipments: {row?.executionStatus?.totalTasks}
                        </Grid>
                        <Grid item xs={12}>
                            Failed Shipments: {row?.executionStatus?.failed}
                        </Grid>
                        <Grid item xs={12}>
                            Successful Shipments: {row?.executionStatus?.success}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'successOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Successful Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row?.executionStatus?.success > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        const flattenedArray = [].concat(...row?.taskListBatch);
                                        let k = flattenedArray?.filter((item) => item?.status === "SUCCESS");
                                        setOrderData(k);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Successful Shipments : {row?.executionStatus?.success}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        },
        {
            field: 'failedOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Failed Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row?.executionStatus?.failed > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        const flattenedArray = [].concat(...row?.taskListBatch);
                                        let k = flattenedArray?.filter((item) => item?.status !== "SUCCESS");
                                        setOrderData(k);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Failed Shipments : {row?.executionStatus?.failed}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        }
    ]

    return (
        <>
            <PageTitle>NDR Bulk Actions</PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <FormProvider
                                methods={methods}
                                onSubmit={handleSubmitBulkUpload(onSubmitBulkUpload)}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <RHFFileField required={true} fileType={['csv']} name="ndrFile" label="Click here to upload. Maximum 500 NDR actions can be uploaded in one file." accept={["csv"]} />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <LoadingButton
                                            startIcon={<FileUploadIcon />}
                                            type="submit"
                                            loading={isUploadingFile}
                                            variant="contained"
                                            color="warning"
                                            size="small"
                                        >
                                            Upload
                                        </LoadingButton>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{ ml: 1 }}
                                            startIcon={<FileDownloadOutlined />}
                                            onClick={() => handleBulkSample()}
                                        >
                                            Sample Download
                                        </Button>
                                    </Grid>
                                    {errorMsg && errorMsg !== '' && (
                                        <Grid item xs={12} sm={12} sx={{ justifyContent: 'right' }}>
                                            <Typography variant="body" color={'red'}>{"0 records succeeded. Error details : " + errorMsg + " . Please make changes to the file and reupload. "}</Typography>

                                        </Grid>
                                    )}
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ProzoDataGrid
                                columns={columns}
                                rows={dataa.length > 0 ? dataa : []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                hideFooterPagination={false}
                                filterMode={"server"}
                                paginationMode={'client'}
                                rowCount={dataa?.length}
                                getRowId={(row) => row.id}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <MUIModal
                open={seeOrders}
                title="AWB Details"
                maxWidth={"lg"}
                handleClose={() => setSeeOrders(false)}
            >
                <ProzoDataGrid
                    // autoHeight={false}
                    sizePerPage={sizePerPage1}
                    setSizePerPage={setSizePerPage1}
                    page={page1}
                    setPage={setPage1}
                    rowCount={orderData?.length}
                    columns={columnsOrderModal}
                    rows={orderData}
                    paginationMode={'client'}
                    pagination={true}
                    hideFooterPagination={false}
                    getRowId={(row) => Math.random()}
                />
            </MUIModal>
        </>

    );
};
export default NDRBulkUpload;
