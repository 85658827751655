import { createSlice } from '@reduxjs/toolkit';
import { postOrder } from '../../actions/orderCreate/createOrderAction';

const initialState = {
    loading: false,
    orderInfo: {},
    error: null,
    success: {},
    merchant: {},
}


export const createOrderSlice = createSlice({
    name: 'createOrderForm',
    initialState,
    reducers: {
        resetOrderForm: (state) => {
            state.loading = false
            state.error = null
        },
        resetOrder: (state) => {
            state.loading = false;
            state.orderInfo = {};
            state.error = null;
            state.merchant = {};
        },
        setOrderForm: (state, action) => {
            state.orderInfo = action.payload
        },
        setMerchant: (state, action) => {
            state.merchant = action.payload
        },
    },
    extraReducers: {
        // get orderSummary api aggregate/orders
        [postOrder.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null,
            }
        },
        [postOrder.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: payload,
            }
        },
        [postOrder.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetOrderForm, setOrderForm, setMerchant, resetOrder } = createOrderSlice.actions

export default createOrderSlice.reducer