/** @format */

import React, { useEffect, useRef, useState } from "react";
import withLayout from "../../hocs/Layout";
import {
  apiConstants,
  postGeneric,
  setAutoMerchantName,
} from "../../common/constants";
import { useSelector } from "react-redux";
import {
  Avatar,
  Backdrop,
  Box,
  Chip,
  Paper,
  Portal,
  Typography,
  styled,
} from "@mui/material";
import TypeSentence from "./TypeSentence";
import Typing from "./Typing";
import useChatBot from "./useChatBot";

const WdgetContainer = styled(Paper)(({ theme }) => ({
  position: "fixed",
  width: 470,
  height: "90%",
  zIndex: theme.zIndex.modal,
  right: 10,
  bottom: 10,
  boxShadow:
    "0px 1px 2px 0px rgb(30 30 45), 0px 1px 1px 0px rgb(30 30 45), 0px 0px 4px 0px rgb(30 30 45)",
}));

const UserProfile = styled(Box)(() => ({
  display: "flex",
  padding: "5px 10px",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  //background: '#1b1b28',
  background:
    "linear-gradient(117deg, rgba(64,86,125,1) 0%, rgba(30,30,45,0.9164915966386554) 75%)",
  height: 80,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
}));

const ProzoIcon = styled(Avatar)(() => ({
  height: 35,
  width: 35,
  marginRight: 10,
  "& img": {
    objectFit: "contain",
  },
}));

const TypingArea = styled(Box)(() => ({
  position: "absolute",
  width: "100%",
  bottom: 0,
  display: "flex",
  alignItems: "center",
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  "& input": {
    width: "100%",
    border: 0,
    padding: "24px 15px",
    outline: "none",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const ConversationArea = styled(Box)(() => ({
  marginTop: 0,
  marginBottom: 0,
  overflowY: "scroll",
  height: "calc(100% - 147px)",
  paddingTop: 5,
  paddingBottom: 10,
}));

const Conversation = styled(Box)(() => ({
  padding: "6px 10px",
  display: "inline-block",
  margin: "5px 10px",
  borderRadius: 20,
}));

const defaultMessageText = `Welcome to ProPilot, your AI ally at Prozo! I'm here to revolutionize your workday with an unparalleled efficiency. Please note that I am a work in progress bot and currently the following features are supported <br>\n1. AWB Level Analysis <br>\n2. In depth information for forward and reverse shipments for a given date range (15 days). <br>\n3. In depth analysis for RTO performance for a given date range (15 days). <br>\n4. TAT adherence analysis for forward shipments for a date range (15 days). <br>\n5. Pickup performance analysis for a date range (15 days). <br>\n6. Lost and damanged analysis for a date range (15 days). <br>\n7. NDR Analysis of a AWB number. <br>\n8. Overall performance for a date range (15 days)\n9. NDR Call Recording Related Data\n10. City and Pincode level RTO Analysis`;

const defaultMessage = {
  type: "received",
  message: defaultMessageText.replace(/\n/g, "  \n"),
  id: new Date().getTime(),
  isError: false,
  speed: 5,
};

const ProPilot = () => {
  const { userInfo } = useSelector((state) => state.user);
  const textFieldRef = useRef(null);
  const conversationFieldRef = useRef(null);
  const [conversation, setConversation] = useState([defaultMessage]);
  const [threadId, setThreadId] = useState(null);
  const [userScrolling, setUserScrolling] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isReloadRequired, setIsReloadRequired] = React.useState(false);
  const [ctToken, setCtToken] = useState("");

  useEffect(() => {
    generateTokenForPropilot();
    // eslint-disable-next-line
  }, []);

  async function generateTokenForPropilot() {
    try {
      const res = await postGeneric(
        `${apiConstants.GENERATE_AUTH_TOKEN_FOR_PROSHIP}`,
        {
          username: "+912345723457",
          merchantId: userInfo?.merchant?.id,
        }
      );
      if (res.status === 200) {
        setCtToken(res.data.token);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (ctToken && ctToken?.length > 0) {
      setIsDisabled(true);
      autoMerchantName();
    }
    // eslint-disable-next-line
  }, [ctToken]);

  const { onKeyPressHandler, isFetching } = useChatBot({
    textFieldRef,
    setConversation,
    threadId,
    setThreadId,
    ctToken,
  });

  async function autoMerchantName() {
    const payload = {
      message: `The merchant is ${userInfo?.merchant?.name} for all following requests`,
      thread_id: threadId,
      run_id: null,
      last_index: 0,
      poll: false,
    };
    const result = await setAutoMerchantName(payload, ctToken);

    if (result?.thread_id) {
      setThreadId(result?.thread_id);
    }
    setIsDisabled(false);
  }

  useEffect(() => {
    if (conversationFieldRef.current) {
      conversationFieldRef.current.scrollTo({
        behavior: "instant",
        top: conversationFieldRef.current.scrollHeight,
      });
    }

    setUserScrolling(false);
  }, [conversation.length, conversationFieldRef]);

  React.useEffect(() => {
    if (conversation && conversation.length > 0) {
      const size = conversation.length;
      const lastObject = conversation[size - 1];

      if (lastObject?.isError) {
        setIsReloadRequired(true);
      }
    }
  }, [conversation]);

  return (
    <div id='main-content' className='container-fluid'>
      <Box
        sx={{
          display: "flex",
          align: "center",
          "& #pro-pilot-container": {
            position: "relative",
            width: "100%",
            height: "86vh",
          },
        }}>
        <Portal disablePortal={true}>
          <Backdrop
            sx={{
              width: "100%",
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: true ? "relative" : "fixed",
            }}
            open={true}
            invisible={true}>
            <WdgetContainer id='pro-pilot-container'>
              <UserProfile>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    justifyContent: "center",
                  }}>
                  <ProzoIcon src='/img/logo-prozo.png' />
                  <Typography
                    fontWeight='bold'
                    color='#fff'
                    variant='body2'
                    fontSize={18}>
                    ProPilot
                  </Typography>
                </Box>
              </UserProfile>
              <ConversationArea
                ref={conversationFieldRef}
                onWheel={() => setUserScrolling(true)}>
                {conversation.map((conversation, i) => (
                  <Box
                    key={conversation.id}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      // conversation.type === "received"
                      //   ? "flex-start"
                      //   : "flex-end",
                    }}>
                    <Conversation
                      sx={{
                        border:
                          conversation.type === "received"
                            ? "1px solid #ccc"
                            : "1px solid #0090ff",
                        backgroundColor: conversation.isError
                          ? "#FFEBEE"
                          : conversation.type === "received"
                          ? "#e8e8ec"
                          : "#d5efff",
                      }}>
                      <TypeSentence
                        setUserScrolling={setUserScrolling}
                        userScrolling={userScrolling}
                        marked={i === 0}
                        startIndex={conversation?.startIndex || 0}
                        conversationFieldRef={conversationFieldRef}
                        type={conversation.type}
                        sentence={conversation.message}
                        speed={conversation.speed ? conversation.speed : 10}
                      />
                    </Conversation>
                  </Box>
                ))}

                {isFetching && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <Typing />
                  </Box>
                )}

                {isReloadRequired && (
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Conversation>
                      <Box sx={{ display: "flex", columnGap: "2px" }}>
                        <Chip
                          sx={{
                            "& span": {
                              textTransform: "none",
                              fontSize: 12,
                              fontWeight: 500,
                            },
                          }}
                          color='error'
                          variant='outlined'
                          label={"Please reload the page"}
                          onClick={() => window.location.reload()}
                        />
                      </Box>
                    </Conversation>
                  </Box>
                )}
              </ConversationArea>

              <TypingArea>
                <input
                  disabled={isFetching || isDisabled}
                  onKeyDown={onKeyPressHandler}
                  ref={textFieldRef}
                  autoFocus
                  type='text'
                  style={{ background: "#F5F5F5" }}
                  placeholder='&#x1F5E8; Type here to chat with Pro-Pilot'
                />
              </TypingArea>
            </WdgetContainer>
          </Backdrop>
        </Portal>
      </Box>
    </div>
  );
};

export default withLayout(ProPilot);
