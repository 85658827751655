import React from 'react';
import DateRangePicker from '../../components/DateRangePicker';
import { startOfMonth, subDays, subMonths } from 'date-fns';
import { getYYYYMMDD } from '../../helpers/UtilityHelper';

// const startOfLastToLastMonth = subMonths(new Date(), 1); // Calculate the 1st of the last to last month

const defaultRange = [
    {
        startDate: startOfMonth(new Date()),
        endDate: subDays(new Date(), 1),
        key: 'selection'
    }
];
// let defaultRange = [
//     {
//       startDate: new Date(2024, 0, 1), // November is represented as 10 (0-based index)
//       endDate: new Date(2024, 0, 6),  // November is represented as 10 (0-based index)
//       key: 'selection'
//     }
//   ];

const DateRangeContext = React.createContext()

export function useGetSelectedDateRange() {
    const { dateRange } = React.useContext(DateRangeContext)
    let from_date = null
    let to_date = null

    if (dateRange?.length > 0) {
        from_date = getYYYYMMDD(dateRange[0].startDate)
        to_date = getYYYYMMDD(dateRange[0].endDate)
    }

    return { from_date, to_date };
}

export default function DateSelector() {

    const { dateRange, setDateRange } = React.useContext(DateRangeContext)

    return (
        <DateRangePicker
            showTextBox={false}
            maxDate={subDays(new Date(), 1)}
            defaultRange={dateRange}
            onChange={(range) => {
                if (range.length > 0) {
                    setDateRange(range)
                }
            }}
        />
    )
}

export function DateRangeContextProvider({ children }) {
    const [dateRange, setDateRange] = React.useState(defaultRange)

    return (
        <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
            {children}
        </DateRangeContext.Provider>
    )
}