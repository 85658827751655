import { createSlice } from '@reduxjs/toolkit';
import { userSignup } from '../../actions/auth/signupAction';


const initialState = {
    loading: false,
    signupInfo: {}, // for user object
    error: null,
    success: false, // for monitoring the login process.
}


export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        reset: (state) => {
            state.loading = false
            state.signupInfo = {}
            state.error = null
        },
    },
    extraReducers: {
        // login user
        [userSignup.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userSignup.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.signupInfo = payload
        },
        [userSignup.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { reset } = signupSlice.actions

export default signupSlice.reducer