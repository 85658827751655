import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { FormProvider, RHFTextField } from '../../../../common/hook-form';
import RHFTextArea from '../../../../common/hook-form/RHFTextArea';
import MUIModal from '../../../../common/MUIModal';
import { apiConstants, post } from '../../../../common/constants';
import { useSnackbar } from 'notistack';

const AddPickupLocation = ({ show, handleClose, merchantId, currentId, reload, setReload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false); // State to control the LoadingButton

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required('Address name is required').matches(/^[0-9a-zA-Z-,'. ]+$/, 'Please enter a valid store name'),
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        contactNumber: Yup.string().required('Mobile Number is required').matches(/^[1-9][0-9]{9}$/, 'Please enter a mobile number'),
        gstin: Yup.string().required('Gstin is required').matches(/^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[a-zA-Z0-9]{3})$/, 'Please enter a valid gstin'),
        address_line: Yup.string().required('Address is required'),
        pincode: Yup.string().required('Pincode is required').matches(/^\d{6}$/, 'Please enter a valid pincode'),
        state: Yup.string().required('State is required').matches(/^[a-zA-Z-'. ]+$/, 'Please enter a valid state'),
        city: Yup.string().required('City is required').matches(/^[a-zA-Z-'. ]+$/, 'Please enter a valid city'),
    });

    const formMethods = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { handleSubmit, reset } = formMethods;

    const onSubmit = (param) => {
        setLoading(true);
        const addressCopy = Object.assign(
            {},
            { status: "ACTIVE", address_type: "PICKUP", country: "India" },
            currentId,
            param
        );
        post(
            `${apiConstants.BASE_LOCAL}${apiConstants.ADD_LOCATION}/${merchantId}`,
            addressCopy
        ).then((response) => {
            enqueueSnackbar("Successfully updated!", { variant: "success" });
            setLoading(false);
            hideModal();
            setReload(reload + 1);
        })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar("Some error occurred!", { variant: "error" });
                setLoading(false);
            });
    };

    const hideModal = () => {
        reset();
        handleClose();
    };

    useEffect(() => {
        if (currentId) {
            reset(currentId);
        }
    }, [currentId, reset]);

    return (
        <MUIModal open={show} maxWidth="lg" title="Add New Pickup Location" handleClose={hideModal}>
            <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="name" label="Address Name" required disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="contactNumber" label="Contact Number" required />
                    </Grid>
                    <Grid item xs={12}>
                        <RHFTextArea name="address_line" label="Address" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="pincode" label="Pincode" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="city" label="City" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="state" label="State" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="email" label="Email ID" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField name="gstin" label="GSTIN" required />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton type="submit" loading={loading} variant="contained" color="primary">
                            Save Pickup Location
                        </LoadingButton>
                    </Grid>
                </Grid>
            </FormProvider>
        </MUIModal>
    );
};

export default AddPickupLocation;
