import { createSlice } from '@reduxjs/toolkit';
import { userResetPassword } from '../../actions/auth/resetPasswordAction';


const initialState = {
  loading: false,
  rpInfo: {}, // for user object
  error: null,
  success: false, // for monitoring the login process.
}


export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false
      state.rpInfo = {}
      state.error = null
    },
    resetFormrp: (state) => {
      state.loading = false
      state.rpInfo = {}
      state.error = null
    },
  },
  extraReducers: {
    // login user
    [userResetPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userResetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.rpInfo = payload
    },
    [userResetPassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { reset,resetFormrp } = resetPasswordSlice.actions

export default resetPasswordSlice.reducer