import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiConstants } from '../../common/constants';

const REPORT_API_ENDPOINT = `${apiConstants.MERCHANT_DETAILS}`
export const fetchReportData = createAsyncThunk(
    'config/merchant', 
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiConstants.BASE_LOCAL}${REPORT_API_ENDPOINT}/${id}`);
            return response.data.reportsPiiDataEnabled;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
