import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../../common/constants";

export const getOrderSummary = createAsyncThunk(
    'orderSummary',
    async (param, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('userToken')
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.DASHBOARD_ORDER_SUMMARY}${param.type}/?from_date=${param.dateRange.fromDate}&to_date=${param.dateRange.toDate}`;
            const { data } = await axios.get(
                url,
                config
            )

            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);