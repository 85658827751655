import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, DialogActions } from "@mui/material";

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm, onReject,disableButtons }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpen(false)
            if (onReject instanceof Function)
              onReject()
          }}
          disabled={disableButtons}
          color="success">
          No
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpen(false);
            onConfirm(12);
          }}
          disabled={disableButtons}
          color="error">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
