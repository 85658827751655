import React from 'react';
import { parseDateAndTime } from '../../../helpers/UtilityHelper';
import { Button, Card, CardContent, CardHeader, CardMedia, Chip, Grid, Stack, Typography } from '@mui/material';

export function Grid34(props) {
    return (
        <Grid item xs={6} sm={3}>
            <Typography variant='subtitle2' sx={{ paddingLeft: 4, paddingRight: 4 }}>{props.label}</Typography>
        </Grid>
    );
}

export function Chip34(props) {
    return (
        <Stack direction="row" sx={{ paddingLeft: 4, paddingRight: 4 }}>{props.label}
            <Chip size='small' sx={{ paddingLeft: 4, paddingRight: 4 }}>{props.label}</Chip>
        </Stack>

    );
}

export const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
    }).format(value);

const OrderSummary = (props) => {
    const { orderDetailInfo } = props;
    const invoice_value = (orderDetailInfo.length >= 1 && orderDetailInfo[0].invoice_value) ? orderDetailInfo[0].invoice_value : 0;
    const payment_mode = (orderDetailInfo.length >= 1 && orderDetailInfo[0].payment_mode) ? orderDetailInfo[0].payment_mode : "";
    const awb_number = (orderDetailInfo.length >= 1 && orderDetailInfo[0].awb_number) ? orderDetailInfo[0].awb_number : "";
    const courier = (orderDetailInfo.length >= 1 && orderDetailInfo[0].actualCourierProviderName) ? orderDetailInfo[0].actualCourierProviderName : "";
    const createdDate = (orderDetailInfo.length >= 1 && orderDetailInfo[0].createdDate) ? parseDateAndTime(orderDetailInfo[0].createdDate) : "";
    const item_list = (orderDetailInfo.length >= 1 && orderDetailInfo[0].item_list) ? orderDetailInfo[0].item_list : [];
    const shipment_detail = (orderDetailInfo.length >= 1 && orderDetailInfo[0].shipment_detail) ? orderDetailInfo[0].shipment_detail[0] : "";
    const shipmentType = (orderDetailInfo.length >= 1 && orderDetailInfo[0].shipmentType) ? orderDetailInfo[0].shipmentType : "";
    const orderStatus = (orderDetailInfo.length >= 1 && orderDetailInfo[0].orderStatus) ? orderDetailInfo[0].orderStatus : "";

    return (
        <React.Fragment>
            <Card sx={{ boxShadow: 1, paddingLeft: 2 }}>
                <CardHeader title="Order Summary" />
                <CardContent >
                    <Grid container spacing={4} >
                        <Grid item xs={4} md={1}>
                            <CardMedia
                                component="img"
                                sx={{ width: 50, paddingBottom: 1 }}
                                image="img/intransit.svg"
                                alt=" " />
                            <Typography> {orderStatus.replace(/_/g, ' ')}</Typography>
                        </Grid>


                        <Grid item xs={6} md={3}>
                            <Typography variant="h4" paddingBottom={0.5} >{awb_number}</Typography>
                            <Typography paddingBottom={1} > {createdDate}</Typography>
                            <Typography >{courier}</Typography>
                            <Typography > ₹{invoice_value}  | {item_list.length} items</Typography>
                        </Grid>


                        <Grid item xs={6} md={3}>
                            <Typography variant="h6" paddingBottom={0.5} >Dimension : {(shipment_detail.item_length) ? shipment_detail.item_length : ""}x
                                {(shipment_detail.item_breadth) ? shipment_detail.item_breadth : ""}x
                                {(shipment_detail.item_height) ? shipment_detail.item_height : ""}cm
                            </Typography>
                            <Typography variant="h6" paddingBottom={0.5} >Weight : {(shipment_detail.item_weight) ? ((shipment_detail.item_weight) / 1000).toFixed(2) : ""} kg</Typography>
                            <Typography variant='h6' >Vol. Weight : {(shipment_detail.item_length && shipment_detail.item_breadth && shipment_detail.item_height) ? ((shipment_detail.item_length * shipment_detail.item_breadth * shipment_detail.item_height) / 5000).toFixed(2) : 0} kg</Typography>
                        </Grid>


                        <Grid item xs={8} md={2}>
                            <Typography variant="h6" paddingBottom={0.5} >Shipment</Typography>
                            <Typography > {(shipmentType.category) ? shipmentType.category : ""}</Typography>
                            <Typography variant='h7' paddingBottom={1}> Invoice :   ₹{invoice_value}</Typography>
                            <Typography> Payment Mode : {payment_mode} </Typography>
                        </Grid>



                        <Grid item xs={12} md={3} sx={{ paddingBottom: 3 }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 250, height: 130 }}
                                image="img/map.png"
                                alt=" "></CardMedia>
                            <Button variant="contained" href={`/tracking-detail?waybill=${awb_number}`} sx={{ width: 250 }} >Track Order</Button>

                            {/* <div className="col-12 col-md-3 col-lg-3"> <img src="img/map.png" className="img-fluid w-100" width="304" height="141" alt="" />
                                    <Link to={`/tracking-detail?waybill=${awb_number}`} className="btn btn-primary d-block w-100 m-0" >Track order</Link>
                                    </div> */}
                        </Grid>


                    </Grid>


                </CardContent>
            </Card>
        </React.Fragment>
    );

}
export default OrderSummary;