import { createSlice } from '@reduxjs/toolkit';
import { getTrackingLists } from '../../actions/trackingList/trackingListAction';
const initialState = {
    loading: false,
    trackingListInfo: {
        Status: null,
        waybillDetails: [],
    },
    error: null,
    success: false,
}


export const trackingListSlice = createSlice({
    name: 'trackingList',
    initialState,
    reducers: {
        resetTrackingList: (state) => {
            state.loading = false
            state.trackingListInfo = {
                Status: null,
                waybillDetails: [],
            }
            state.error = null
        },
        updateTrackingList: (state, { payload }) => {
            return {
                ...state,
                trackingListInfo: {
                    Status: payload.Status,
                    waybillDetails: [...payload.waybillDetails]
                }
            }
        },
    },
    extraReducers: {
        // get orderSummary api aggregate/orders
        [getTrackingLists.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null
            }
        },
        [getTrackingLists.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                trackingListInfo: {
                    Status: payload.Status,
                    waybillDetails: [...state.trackingListInfo.waybillDetails, ...payload.waybillDetails]
                }
            }
        },
        [getTrackingLists.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetTrackingList, updateTrackingList } = trackingListSlice.actions

export default trackingListSlice.reducer