import React, { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserName, getRole } from "../helpers/ReduxHelpers";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import { apiConstants, post } from "../common/constants";
import _ from "lodash"
import { Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { getMenuWithConfig } from "./MenuConfig";
import { ExpandLess, ExpandMore, FiberManualRecord } from "@mui/icons-material";

const DRAWER_BG_COLOR = '#1b1b28'

const Sidebar = () => {
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  const userName = getUserName();

  const [enabled, setEnabled] = useState(false);
  const [initialStep] = useState(0);
  const [doneLabel] = useState("Finish");
  const accessList = getRole();
  let VerifiedRole = _.intersection(accessList, ["ROLE_PROSHIPADMIN", "ROLE_ADMIN"])
  const [openSubMenu, setOpenSubMenu] = React.useState('')
  const [selectedMenu, setSelectedMenu] = React.useState(null);

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      navigate("/login");
    }
  }, [navigate, userInfo]);

  const onExit = () => {
    setEnabled(false)
  }

  useEffect(() => {
    const payload = {
      "collection": "users",
      "andfilter": [
        {
          "field": "username",
          "operator": "eq",
          "value": userName
        }
      ],
      "offset": 0,
      "limit": 1
    }
    if (VerifiedRole?.length > 0) {
      post(`${apiConstants.BASE_LOCAL}${apiConstants.GET_USER}`, payload).then(response => {
        if (response?.data?.data[0]?.logins?.pastLogins?.length <= 1) {
          setEnabled(true);
        }
      }).catch((err) => console.log(err))
    }

  }, [VerifiedRole?.length, userName])

  const steps = [
    {
      element: '#menu-main',
      title: 'Welcome To Proship',
      intro: 'Welcome to ProShip.',
    },
    {
      element: '#menu-dashboard',
      title: 'Home',
      intro: 'Leverage comprehensive order analysis, delivery performance assessment, and RTO evaluation through our visualizations to drive significant improvements and enhance the overall success of your business.',
      position: 'right',
    },
    {
      element: '#menu-shipment',
      title: 'Shipment',
      intro: 'Effortlessly track the progress of your current shipments, seamlessly create new shipments (including partial and reverse shipments), efficiently manage bulk shipments, and handle labels with utmost ease.',
      position: 'right'
    },
    // {
    //   element: '#menu-rvp',
    //   title: 'RVP',
    //   intro: 'We carefully evaluate and provide guidance to swiftly resolve NDRs, guaranteeing an enhanced customer experience for your valuable clients.',
    //   position: 'right'
    // },
    {
      element: '#menu-ndr',
      title: 'NDR',
      intro: 'We carefully evaluate and provide guidance to swiftly resolve NDRs, guaranteeing an enhanced customer experience for your valuable clients.',
      position: 'right'
    },
    // {
    //   element: '#menu-npr',
    //   title: 'NPR',
    //   intro: 'We carefully evaluate and provide guidance to swiftly resolve NDRs, guaranteeing an enhanced customer experience for your valuable clients.',
    //   position: 'right'
    // },
    {
      element: '#menu-wd',
      title: 'Weight Discrepancy',
      intro: 'At Proship, we believe in transparency. We provide you with clear information and practical steps to quickly resolve any weight discrepancies.',
      position: 'right'
    },
    {
      element: '#menu-tools',
      title: 'Tools',
      intro: "Discover service availability for any pin code with a few clicks. Use our Rate Calculator to determine your shipment's rate.",
      position: 'right'
    },
    {
      element: '#menu-wallet',
      title: 'FINANCE',
      intro: "Effortlessly oversee and manage wallet transactions while gaining comprehensive control over your COD remittance.",
      position: 'right'
    },
    {
      element: '#menu-help',
      title: 'Help',
      intro: "Whether you need to raise a ticket or have any queries, we've got you covered.",
    },
  ];

  const menuArray = getMenuWithConfig(VerifiedRole)

  const handleSubMenuClick = (menuUrl) => {
    if (openSubMenu === menuUrl) {
      setOpenSubMenu('');
    } else {
      setOpenSubMenu(menuUrl);
    }
  };

  const setSelectedMenuBasedOnUrl = () => {
    const foundMenu = menuArray.find((menu) =>
      pathname === menu.pathname ||
      (menu.menuItems && menu.menuItems.some((submenu) => pathname === submenu.pathname))
    );
    setSelectedMenu(foundMenu);
  };

  React.useEffect(() => {
    setSelectedMenuBasedOnUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <React.Fragment>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={() => onExit()}
        options={{ doneLabel }}
      />

      {/* <TopBar setEnabled={setEnabled} /> */}

      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={{ backgroundColor: DRAWER_BG_COLOR }}
        PaperProps={{
          sx: {
            backgroundColor: DRAWER_BG_COLOR,
            color: "#a2a3b7",
            width: 230,
            height: '100%',
            overflow: 'auto',
            visibility: 'visible',
            '&:hover': {
              visibility: 'visible',
            },
            '&::-webkit-scrollbar': {
              width: 3,
              background: DRAWER_BG_COLOR,
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#637381',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: DRAWER_BG_COLOR,
            },
          }
        }}
      >
        <List
          sx={{
            backgroundColor: DRAWER_BG_COLOR,
            visibility: 'visible'
          }}>
          <ListItem
            sx={{
              position: 'sticky',
              top: 0,
              background: DRAWER_BG_COLOR,
              py: 3,
              zIndex: (a) => a.zIndex.drawer + 1,
            }}>
            <img
              src="/img/logo-prozo.png"
              width="27"
              height="40"
              alt="logo"
              id="home"
              style={{ margin: "0 auto" }}
            />
          </ListItem>

          {menuArray.map((menu) => {
            const isMenuActive = pathname.includes(menu.pathname);
            const isSubMenuOpen = [openSubMenu, selectedMenu?.pathname].includes(menu.pathname)
            const hasSubMenu = menu?.menuItems?.length > 0

            return (
              <React.Fragment key={menu.pathname}>
                <ListItem
                  button
                  component={hasSubMenu ? 'li' : Link}
                  to={hasSubMenu ? null : menu.pathname}
                  onClick={() => hasSubMenu && handleSubMenuClick(menu.pathname)}
                  hidden={!menu.visibility}
                  id={menu?.id || null}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: isMenuActive || (selectedMenu?.pathname === menu.pathname) ? '#fff' : '#a2a3b7',
                    '&:hover': {
                      '& svg': {
                        fill: '#F0B41B' // Change the color to your desired color
                      }
                    }
                  }}
                >
                  {menu.icon && (
                    <ListItemIcon sx={{ minWidth: 40, '& svg': { fontSize: '20px' } }}>
                      {menu.icon}
                    </ListItemIcon>
                  )}
                  {menu.label && (
                    <ListItemText primary={menu.label} primaryTypographyProps={{ fontSize: 13 }} />
                  )}
                  {hasSubMenu && (
                    <ListItemIcon onClick={() => handleSubMenuClick(menu.pathname)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end', // Right-align the icon
                      }}>
                      {isSubMenuOpen ? <ExpandLess fontSize='smallest' /> : <ExpandMore fontSize='smallest' />}
                    </ListItemIcon>
                  )}
                </ListItem>
                {hasSubMenu && (
                  <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      sx={{ marginLeft: '10px' }}
                    >
                      {menu.menuItems.map((submenu) => (
                        <ListItem
                          button
                          key={submenu.label}
                          component={Link}
                          to={submenu.pathname}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: pathname.includes(submenu.pathname) ? '#fff' : '#a2a3b7',
                            '&:hover': {
                              '& svg': {
                                fill: '#F0B41B' // Change the color to your desired color
                              }
                            }
                          }}
                        >
                          <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 10 }} />
                          </ListItemIcon>
                          <ListItemText primary={submenu.label} sx={{ marginLeft: '-30px' }} primaryTypographyProps={{ fontSize: 12 }} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            )
          })}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
