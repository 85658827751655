import React from 'react';

const UserNotFound = (props) => {
  const { handleShow } = props;
  return (
    <>
      <div className="row" id="add-location">
        <div className="col-12 col-md-6 offset-md-3 d-md-flex justify-content-md-center align-items-center flex-column text-center"><img src="img/add-user.svg" width="375" alt="" style={{ "marginBottom": "30px" }} />
          <p className="m-0 text-center">
            Add people and assign their roles. This can be skipped if required.
          </p>
          <button
            type="button"
            className="btn btn-outline-primary btn-cancel mt-3 mb-4"
            data-bs-toggle="modal"
            data-bs-target="#AddUserModal"
            onClick={handleShow}
          >
            Add user
          </button>
        </div>
      </div>
    </>
  );
}

export default UserNotFound;