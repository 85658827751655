import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material"
import { useState } from "react";
import UploadIcon from '@mui/icons-material/Upload';
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { apiConstants, postGeneric } from "../../../common/constants";
import PageTitle from "../../../common/PageTitle";
import { RHFFileField, FormProvider } from "../../../common/hook-form";
import DownloadSampleFile from "../../../common/DownloadSampleFile";
import withLayout from "../../../hocs/Layout";
import { useNavigate } from "react-router-dom";



function UploadTracking() {
    const { enqueueSnackbar } = useSnackbar();
    const sampleName = 'B2B_TRACK_ONLY_V2_SAMPLE_NO_MER_ID';
    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate();

    const methods = useForm(
        ({
            defaultValues: {
                uploadFile: ''
            }
        })
    )

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.uploadFile[0])
        try {
            await postGeneric(apiConstants.BULK_UPLOAD_B2B_TRACK, formData).then((res) => {
                console.log(res, 'res');
                if (res.status === 200 && res.data.success) {
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    navigate('/b2b-orders')
                    reset()
                }
                else {
                    // enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    enqueueSnackbar(`Some error occurred`, { variant: 'error' })
                    reset()
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
            reset()
        }

        setIsSubmitting(false)
    }
    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>Upload PTL Orders For Tracking</PageTitle>
                <Box sx={{
                    width: 1,
                    boxShadow: 1,
                    backgroundColor: "#fff",
                    p: 2
                }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFFileField name='uploadFile' required />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={sampleName} excel={"excel"} />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Box>
            </div>
        </>
    )
}

export default withLayout(UploadTracking)