import React from "react";
import PageTitle from "../../../common/PageTitle";
import withLayout from "../../../hocs/Layout";
import TrackBox from "../../tracking/list/trackBox";
export const BulkTracking = (props) => {
    return (
        <div id="main-content" className="container-fluid">
            <PageTitle>Bulk Shipment Tracking</PageTitle>
            <TrackBox />
        </div>
    )
}


export default withLayout(BulkTracking);
