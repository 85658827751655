import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import "./login.css";
import { LoadingButton } from "@mui/lab"
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from 'yup'
import { apiConstants } from '../../common/constants';
import axios from 'axios';

export default function ResetPassword() {
    const [isLoading, setIsLoading] = React.useState(false)
    const { rpInfo, } = useSelector((state) => state.resetPasswordSlice);
    const { fpInfo, userEmail } = useSelector((state) => state.forgotPasswordSlice);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    // redirect authenticated user to profile screen
    useEffect(() => {
        if (Object.keys(fpInfo).length !== 0) {
            enqueueSnackbar("Please check your email for password.", { variant: 'success' });
        }
        if (Object.keys(rpInfo).length !== 0) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, rpInfo]);

    const onSubmit = async (reqData) => {
        reqData.username = userEmail
        setIsLoading(true)
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.RESET_PASSWORD}`;
            const { data } = await axios.post(url, reqData, config)
            enqueueSnackbar(data.message || "Password changed successfully", { variant: 'success' })
            navigate('/')
            setIsLoading(false)
            return data
        } catch (e) {
            console.log(e)
            enqueueSnackbar(e.response?.data?.message || "Something went wrong", { variant: 'error' })
            setIsLoading(false)

        }
    };

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                // username: Yup.string().required("Please enter username"),
                current_password: Yup.string().required("Please enter OTP recieved on email"),
                new_password: Yup.string()
                    .required("Please enter a valid password")
                    .min(8, 'Password must be at least 8 characters')
                    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
                    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
                    .matches(/[0-9]/, 'Password must contain at least one number'),
                confirm_password: Yup.string()
                    .required("Please confirm your password")
                    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'), // Custom validation
            })
        ),
        defaultValues: {
            // username: '',
            current_password: '',
            new_password: '',
            confirm_password: ''

        }
    });

    const { handleSubmit } = methods;

    return (
        <>
            <div id="login-wrapper" className="container-fluid vh-100">
                <div className="row h-100">
                    <div className="col-12 col-md-5 col-lg-4"><img src="img/prozo-logo2.svg" className="logo" height="100" alt="" />
                        <h5 style={{ marginLeft: "95px" }}>Shipping made easy</h5>
                        {/* <ul className="">
                            <li>Decrease your return to origin (RTO) for unsuccessful deliveries.</li>
                            <li>Manage non-delivery reports (NDR) seamlessly. </li>
                            <li>Choose the right courier partner for each shipment taking into account all the necessary data points.</li>
                        </ul> */}
                    </div>
                    <div className="col-12 col-lg-5 position-relative"> </div>
                    <div className="clearfix"></div>
                </div>
                <div className="card card-reset card-login shadow-none border-0 p-0 bg-transparent">
                    <div className="card-body">
                        <h1>Reset Password</h1>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {/* <RHFTextField name="username" label="Email Address" size="large" /> */}
                                    <Typography variant="subtitle1">
                                        {userEmail}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField
                                        name="current_password"
                                        size="large"
                                        label="Enter OTP ( Please check email for OTP )"
                                        type='text'
                                        mandatory
                                        trim
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField
                                        name="new_password"
                                        size="large"
                                        label="New Password"
                                        mandatory
                                        type={showPassword ? 'text' : 'password'}
                                        trim
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField
                                        name="confirm_password"
                                        size="large"
                                        label="Confirm Password"
                                        mandatory
                                        type={showPassword ? 'text' : 'password'}
                                        trim
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel onChange={() => setShowPassword(v => !v)} checked={showPassword} control={<Checkbox />} label="Show passwords" />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton loading={isLoading} type="submit" size="large" color="warning" variant="contained">Submit</LoadingButton>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </div>
                </div>
            </div >
        </>
    );
}