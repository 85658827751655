import React, { useEffect, useState } from "react";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Button, Grid, Typography } from "@mui/material";
import PageTitle from "../../../common/PageTitle";
import { apiConstants, postGeneric } from "../../../common/constants";
import MUIModal from "../../../common/MUIModal";
import { Link } from "react-router-dom";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import Loader from "../../../common/Loader";
import { refreshS3SignedUrl } from "../../../helpers/UtilityHelper";

const RejectedOrders = () => {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [data, setData] = useState([])
    const [dataCount, setCount] = useState(0)
    const [itemModalData, setItemModalData] = useState();
    const [approveModalData, setApproveModalData] = useState();
    const [approveModaOpen, setApproveModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const merchantId = getMerchant().id;

    useEffect(() => {
        setIsLoading(true)
        const payload = {
            "andfilter": [
                {
                    "field": "itemData.isRequestPlaced",
                    "operator": "eq",
                    "value": true
                },
                {
                    "field": "merchantOid",
                    "operator": "eq",
                    "value": merchantId
                }
            ],
            "orfilter": [
                {
                    "field": "itemData.rejectedItems",
                    "operator": "gt",
                    "value": 0
                }
            ],
            "skipCount": true,
            "controllerFilter": true,
            "excludes": "otp"
        }
        postGeneric(`${apiConstants.RETURN_EXCHANGE_ORDER_LIST}?conditionFlag=Rejected&offset=${page * sizePerPage}&limit=${sizePerPage}`, payload).then((res) => {
            setCount(res.data?.resultData?.count)
            setData(res.data?.resultData?.data)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
            setIsLoading(false)
        })
    }, [page, sizePerPage, merchantId])

    console.log("dataCount", dataCount);

    const columns = [
        {
            field: "fwdawbNumber",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Forward AWB Number",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                {/* {row.fwdawbNumber} */}
                                <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row?.fwdawbNumber}`}>
                                    {row?.fwdawbNumber}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: "shippingLabelId",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Shipping Label ID",
            flex: 1,
        },
        {
            field: "action",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Action",
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setApproveModalData(row?.item);
                                    setItemModalData(row);
                                    setApproveModalOpen(true);
                                }}
                            >
                                View Items
                            </Button>
                        </Grid>
                    </Grid>
                )
            },
        },
    ]
    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>Return Requests</PageTitle>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={data?.length > 0 ? data?.map((val, index) => ({ ...val, id: index })) : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        rowHeight={180}
                        // rowCount={Number.MAX_VALUE}
                        rowCount={dataCount ? dataCount : Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid>
            <MUIModal title='Rejected Items' open={approveModaOpen} handleClose={() => setApproveModalOpen(false)} children={<RejectedDataList approveModalData={approveModalData} itemModalData={itemModalData} />} />
        </>
    );
};

export default RejectedOrders


const RejectedDataList = ({ approveModalData }) => {

    const columns = [
        {
            field: "item",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Name",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.item_name;
            },
        },
        {
            field: "sku_id",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "SKU ID",
            flex: 0.5,
            renderCell: ({ row }) => {
                return row?.sku_id;
            },
        },
        {
            field: "units",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Rejected Units",
            flex: 0.5,
            renderCell: ({ row }) => {
                return row?.units
            }
        },
        {
            field: "returnImagesUrl",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Image",
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='subtitle2'
                                style={{ color: '#007bff', textDecoration: 'underline', display: 'inline', cursor: 'pointer' }}
                                onClick={() => handleView(row?.rvpImgUrl)}
                            >
                                View
                            </Typography>
                            {/* <a
                                href={row?.rvpImgUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    style={{ color: '#007bff', textDecoration: 'underline', display: 'inline' }}
                                >
                                    View
                                </Typography>
                            </a> */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                );
            },
        },
    ];

    const handleView = async (url) => {
        const refreshedUrl = await refreshS3SignedUrl(url);
        window.open(refreshedUrl, '_blank');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={approveModalData?.map((val, index) => ({ ...val, id: index }))}
                    // sizePerPage={sizePerPage}
                    // setSizePerPage={setSizePerPage}
                    // setPage={setPage}
                    // page={page}
                    hideFooterPagination
                    rowHeight={180}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row.id}
                />
            </Grid>
        </Grid>
    )
}