import { useState } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { GetApp } from "@mui/icons-material";
import { apiConstants, getGeneric } from "./constants";
import { downloadExcelFile, downloadFile } from "../helpers/UtilityHelper";

export default function DownloadSampleFile({ fileName, excel, label, csv = false }) {

    const { enqueueSnackbar } = useSnackbar();
    const [isDownloading, setIsDownloading] = useState(false)

    const onButtonClick = (fileName) => {
        setIsDownloading(true)
        const responseObj = excel ? { responseType: 'blob' } : {}
        getGeneric(apiConstants.DOWNLOAD_SAMPLE_FILE, { fileName }, responseObj)
            .then(({ data }) => {
                if (data) {
                    if (excel) {
                        downloadExcelFile(data, fileName)
                    }
                    // if (csv) {
                    //     downloadCSV(data, fileName)
                    // }
                    else {
                        downloadFile(data, fileName)
                    }
                }

            }).catch(err => {
                console.log(err)
                enqueueSnackbar("Unable to download sample file", { variant: 'error' })
            }).finally(() => {
                setIsDownloading(false)
            })
    }

    return (
        <LoadingButton
            onClick={() => onButtonClick(fileName)}
            loading={isDownloading}
            variant='outlined'
            color='primary'
            startIcon={<GetApp />}
            sx={{
                fontSize: 12,
                letterSpacing: 0,
                fontWeight: 500,
                ml: 2,
                px: 1,
                py: 0,
                textTransform: "capitalize",
            }}
            >
            {label ? label : 'Download Sample File'}
        </LoadingButton>
    )
}