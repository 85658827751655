import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const ReverseRate = ({ data, region }) => {
  const [rowData, setRowData] = useState([]);
  const [zone, setZone] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    let rowsArr = [];
    data.filter((item) => {
      let filterRows = [];

      item.filter((i) => {
        if (i.type === "REVERSE") {
          i?.slab?.filter((slab) => {
            return filterRows.push({
              ...slab,
              zoneName: i.zoneName,
              cp: i.cp,
              type: i.type,
            });
          });
        } else {
          return [];
        }
      });

      rowsArr.push(filterRows);
    });
    setRowData(rowsArr);
    setFilterData(rowsArr);
  }, [data]);

  const zoneFilter = (zone) => {
    let zoneData = [];
    rowData.map((item) => {
      const filterData = item.filter((items) => {
        return items.zoneName === zone ? items : "";
      });

      zoneData.push(filterData);
    });
    setFilterData(zoneData);
  };
  const columns = [
    {
      field: "zoneName",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Zones",
      flex: 1,
      align: "center",
    },
    {
      field: "startWt",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Start Weight",
      flex: 1,
      align: "center",
    },
    {
      field: "maxWt",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Max Weight",
      flex: 1,
      align: "center",
    },
    {
      field: "additionalUnit",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Additional Weight",
      flex: 1,
      align: "center",
    },
    {
      field: "additionalUnitRate",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Additional Price",
      flex: 1,
      align: "center",
    },
    {
      field: "fixedCODCharges",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Min COD Charges",
      flex: 1,
      align: "center",
    },
    {
      field: "invoicePercentCOD",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "COD %",
      flex: 1,
      align: "center",
    },
    {
      field: "withQCCharge",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "QC CHARGE",
      flex: 1,
      align: "center",
    },
    {
      field: "taxPercent",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Tax %",
      flex: 1,
      align: "center",
    },
  ];

  const handleFilter = (e) => {
    setZone(e.target.value);
    zoneFilter(e.target.value);
  };
  return (
    <Box>
      <FormControl sx={{ width: "25ch" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={zone}
          onChange={handleFilter}
          inputProps={{
            "aria-label": "Without label",
          }}
          startAdornment={
            <InputAdornment position="start">
              <Typography sx={{ color: "#858585", fontWeight: "bold" }}>
                ZONE :
              </Typography>
            </InputAdornment>
          }
          input={<OutlinedInput />}
          IconComponent={(props) => (
            <Box>
              <KeyboardArrowDownOutlinedIcon
                {...props}
                sx={{
                  border: "1px solid #858585",
                  borderRadius: 1,
                }}
              />
            </Box>
          )}
          sx={{
            border: "1px solid #858585",
            color: "#858585",
            fontWeight: "bold",
          }}
        >
          {region.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {data.map((item, index) => {
        return (
          <Box my={3}>
            <Typography my={3} variant="h5">
              {item[index]?.cp}
            </Typography>
            <ProzoDataGrid
              rowHeight={170}
              rows={filterData[index] || 0}
              columns={columns}
              pagination={false}
              hideFooterPagination={true}
              filterMode={"server"}
              rowCount={data?.length || 0}
              getRowId={(row, index) => {
                return row.zoneName;
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ReverseRate;
