import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  formatCurrency,
  formatDate,
} from "../../helpers/UtilityHelper";
import { VerticalBarChart, BarChart } from "../charts/ColumnChart";
import { Link } from "react-router-dom";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import SingleProgress from "../charts/SingleProgress";
import OrderItemsModal from "../../common/OrderItemsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { apiConstants, post } from "../../common/constants";
import { Stack } from "@mui/system";

export default function RtoDelay({ rtoDelayed }) {
  // console.log(rtoDelayed);
  const [sizePerPage, setSizePerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [orderItems, setOrderItems] = React.useState(null);
  const [openList, setOpenList] = React.useState(false);

  const statusChartData = _(rtoDelayed)
    .groupBy(function (item) {
      return item?.status?.replaceAll("_", " ");
    })
    .map(function (items, key) {
      return { name: key, value: items.length };
    })
    .orderBy("name", "asc")
    .value();
  // console.log(statusChartData)

  const delayedAgingChartData = _(rtoDelayed)
    .groupBy(function (item) {
      if(item.rtoDeliveryBreachedAgeingDays>=101) return "100 +";
      if(item.rtoDeliveryBreachedAgeingDays>=0 && item.rtoDeliveryBreachedAgeingDays<=15) return "0-15";
      if(item.rtoDeliveryBreachedAgeingDays>=16 && item.rtoDeliveryBreachedAgeingDays<=30) return "16-30";
      if(item.rtoDeliveryBreachedAgeingDays>=31 && item.rtoDeliveryBreachedAgeingDays<=45) return "31-45";
      if(item.rtoDeliveryBreachedAgeingDays>=46 && item.rtoDeliveryBreachedAgeingDays<=60) return "46-60";
      if(item.rtoDeliveryBreachedAgeingDays>=61 && item.rtoDeliveryBreachedAgeingDays<=75) return "61-75";
      if(item.rtoDeliveryBreachedAgeingDays>=76 && item.rtoDeliveryBreachedAgeingDays<=100) return "76-100";
    })
    .map(function (items, key) {
      return { name: key, value: items.length };
    })
    // .orderBy("name", "asc")
    .value();

  const courierWiseSplit = _(rtoDelayed)
    .groupBy("courierName")
    .map(function (items, key) {
      const totalItems = rtoDelayed.length;
      const itemsGrouped = items.length;
      return {
        name: key.replaceAll('Forward', '').replaceAll('_', ' ').replaceAll('FORWARD', '').replaceAll('-', ''),
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();

  
  const rtoDelayKeys = ["0-15 Days", "16-30 Days", "31-45 Days", "46-60 Days", "61-75 Days", "76-100 Days", "100+ Days"];

  const rtoDelayGroupByCourier = _(rtoDelayed)
    .groupBy("courierName")
    .map(function (items, key) {
      const itemsGrouped = _(items).groupBy("rtoDelayLabel").value();
      let obj = { name: key };
      const total = items.length;
      const totalRevenue = _.sumBy(items, "invoiceValue");
      for (let k of Object.keys(itemsGrouped)) {
        const totalCount = itemsGrouped[k].length;
        const totalRevenueCourier = _.sumBy(itemsGrouped[k], "invoiceValue");
        obj[k] = totalCount;
        obj[`${k}_perc`] = (totalCount / total) * 100;
        obj[`${k}_revenue`] = totalRevenueCourier;
        obj[`${k}_revenue_perc`] = (totalRevenueCourier / totalRevenue) * 100;
      }
      return obj;
    })
    .value();
    // console.log(rtoDelayGroupByCourier)

  const getItemList = (awb) => {
    const bodyData = {
      includes: "itemList",
      andfilter: [
        {
          field: "awbNumber",
          operator: "eq",
          value: awb,
        },
      ],
    };

    post(`${apiConstants.BASE_LOCAL}${apiConstants.ORDER_LISTS}`, bodyData)
      .then((response) => {
        setOpenList(true);
        setOrderItems(response.data.data[0].item_list);
      })
      .catch((err) => {
        setOpenList(false);
        console.error(err);
      });
  };

  const handleClose = () => {
    setOrderItems(null);
    setOpenList(false);
  };
  return (
    <Grid container spacing={2}>
      <OrderItemsModal
        items={orderItems}
        setOpen={handleClose}
        openList={openList}
      />

      <Grid item xs={12} md={5}>
        <Card>
          <CardHeader
            title="Delayed RTO Shipments"
            subheader="The section below includes all shipments which haven't been delivered back to the origin after 15 days of the RTO request."
          />
          <CardContent>
            <BarChart
              height={362}
              data={statusChartData}
              chartOptions={{
                xaxis: "name",
                xLabel: "Current Status",
                yLabel: "Number of Shipments",
                data: [
                  {
                    dataKey: "value",
                    label: "Shipments",
                  },
                ],
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={7}>
        <Card>
          <CardHeader
            title="RTO Delivery Delay Ageing"
            subheader="The sections below groups the delayed RTO shipments by the ageing of the shipment. The ageing is calculated from the date of RTO request plus 15 days."
          />
          <CardContent>
            <VerticalBarChart
              height={362}
              data={delayedAgingChartData}
              chartOptions={{
                yaxis: "name",
                yLabel: "Ageing (in Days)",
                xLabel: "Number of Shipments",
                data: [
                  {
                    dataKey: "value",
                    label: "Shipments",
                  },
                ],
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack spacing={1}>

          <Card>
            <CardHeader title="Courier Partners for RTO Delivery Delays" />
            <CardContent>
              <SingleProgress data={courierWiseSplit} />
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title="Pickup Delays"
            action={
              <DownloadExcelMultipleSheets
                sheets={[
                  {
                    name: "RTO Delivery Delayed Shipments",
                    data: rtoDelayed,
                    columns: [
                      { label: "Reference", value: "shippingLabelId" },
                      { label: "Courier Partner", value: "courierName" },
                      { label: "AWB Number", value: "awb_number" },
                      {
                        label: "Order Date",
                        value: (row) =>
                          formatDate(row.awbRegisteredDate, "dd MMM, yyyy"),
                      },
                      { label: "Zone", value: "merchantZone" },
                      { label: "Delay", value: "pickupDelayText" },
                      { label: "Pickup Attempt", value: "failedPickup" },
                      { label: "Invoice Value", value: "invoice_value" },
                    ],
                  },
                ]}
                filename="Delayed Shipments"
              />
            }
          />
          <CardContent sx={{ px: 0 }}>
            <ProzoDataGrid
              columns={[
                {
                  field: "shippingLabelId",
                  headerName: "Reference",
                  width: 140,
                },
                {
                  field: "Reference",
                  headerName: "ID",
                  width: 120,
                  renderCell: ({ row }) => {
                    return (
                      <Box
                        width={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        {/* <Button
                          sx={{
                            padding: "0px 5px",
                            fontSize: "11px",
                            backgroundColor: "aliceblue",
                            borderRadius: "3px",
                          }}
                          startIcon={
                            <VisibilityIcon
                              sx={{ fontSize: "15px !important" }}
                            />
                          }
                          variant="link"
                          onClick={() => setOrderItems(row.item_list)}
                        >
                          View Items
                        </Button> */}

                        <Button
                          sx={{
                            padding: "0px 5px",
                            fontSize: "11px",
                            backgroundColor: "aliceblue",
                            borderRadius: "3px",
                          }}
                          startIcon={
                            <VisibilityIcon
                              sx={{ fontSize: "15px !important" }}
                            />
                          }
                          variant="link"
                          onClick={() => {
                            getItemList(row.awbNumber);
                          }}
                        >
                          View Items
                        </Button>
                      </Box>
                    );
                  },
                },
                {
                  field: "courierName",
                  headerName: "Courier Partner",
                  width: 180,
                },
                {
                  field: "invoice_value",
                  headerName: "Invoice Value",
                  width: 120,
                  renderCell: ({ row }) => formatCurrency(row.invoiceValue),
                },
                {
                  field: "awb_number",
                  headerName: "AWB Number",
                  width: 140,
                  renderCell: ({ row }) => {
                    return (
                      <Link to={`/order-detail?waybill=${row.awbNumber}`}>
                        {row.awbNumber}
                      </Link>
                    );
                  },
                },
                {
                  field: "order_date",
                  headerName: "Created Date",
                  width: 180,
                  renderCell: ({ row }) =>
                    formatDate(row.awbRegisteredDate, "dd MMM, yyyy hh:mm aa"),
                },

                { field: "merchantZone", headerName: "Zone", width: 100 },

                {
                  field: "rtoRequestDate",
                  headerName: "RTO Request Date",
                  width: 180,
                  renderCell: ({ row }) => {
                    return formatDate(
                      row.rtoRequestedTimestamp,
                      "dd MMM, yyyy hh:mm aa"
                    );
                  },
                },
                {
                  field: "lastStatusUpdateTime",
                  headerName: "Last Status Update",
                  width: 180,
                  renderCell: ({ row }) => {
                    return formatDate(
                      row.lastStatusUpdateTime,
                      "dd MMM, yyyy hh:mm aa"
                    );
                  },
                },

                {
                  field: "rtoDeliveryBreachedAgeingDays",
                  headerName: "Delay",
                  width: 100,
                  renderCell: ({ row }) => {
                    return (
                      <Typography
                        fontSize="14px"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {row.rtoDeliveryBreachedAgeingDays} days
                      </Typography>
                    );
                  },
                  cellClassName: ({ row }) => {
                    if (row.rtoDeliveryBreachedAgeingDays >= 15) {
                      return "delay-dark-red";
                    }

                    return `delay-cell-${row.rtoDeliveryBreachedAgeingDays}`;
                  },
                },
              ]}
              autoHeight={true}
              rowHeight={70}
              rows={_.orderBy(
                rtoDelayed,
                "rtoDeliveryBreachedAgeingDays",
                "desc"
              ).splice(page * sizePerPage, sizePerPage)}
              sizePerPage={sizePerPage}
              setSizePerPage={setSizePerPage}
              setPage={setPage}
              page={page}
              pagination={true}
              hideFooterPagination={false}
              filterMode={"server"}
              rowCount={rtoDelayed.length}
              getRowId={(row) => row.id}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Courier Wise Delay" />
          <CardContent>
            {rtoDelayGroupByCourier.map((row) => {
              const data = rtoDelayKeys.map((key) => ({
                name: key,
                value: row[key] || 0,
                perc: Math.round(row[`${key}_perc`] || 0),
              }));
              return (
                <Box mb={3}>
                  <Typography variant="caption" fontWeight={500}>
                    {row.name}
                  </Typography>
                  <SingleProgress data={data} />
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
