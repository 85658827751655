import * as React from "react"
import {suffix, formatDate, formatNumber, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";

export default function AllocationCourierWiseMOP({ data, selectedDates, isLoading }) {
    const __tableRef = React.useRef(null)

    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = 'Courier Allocation (Mode of Payment)'

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={(selectedDates.length * 5) + 1}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName="Allocation-MOP" fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2} style={{ minWidth: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <React.Fragment key={i}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={4}>{formatDate(date, 'MMM yy')}</td>
                                <td align="center" style={{
                                    backgroundColor: monthColors[selectedDates.length]
                                }} rowSpan={2}>Total</td>
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={i}>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>COD</td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>%</td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>PREPAID</td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>%</td>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={i}>
                                {/* <td style={{ minWidth: 200 }}>{row.name}</td> */}
                                <td style={{ width: 200 }}>{row.parent ? row.parent:row.name}</td>
                                {selectedDates.map((_d, j) => (
                                    <React.Fragment key={j}>
                                        {(i === data.length - 1) ? (
                                            <>
                                                <td style={{
                                                    backgroundColor: monthColors[j]
                                                }} align="right" colSpan={2}>{formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCod`])}</td>
                                                <td style={{
                                                    backgroundColor: monthColors[j]
                                                }} colSpan={2} align="right">{formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalPrepaid`])}</td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[selectedDates.length]
                                                }}>{formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCount`])}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>{formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCod`])}</td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>{suffix(row[`${formatDate(_d, 'MMM_yy')}_codPercentage`], { postfix: '%' })}</td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>{formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalPrepaid`])}</td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>{suffix(row[`${formatDate(_d, 'MMM_yy')}_prepaidPercentage`], { postfix: '%' })}</td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[selectedDates.length]
                                                }}>{formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCount`])}</td>
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}