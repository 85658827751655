import { Grid, Skeleton } from "@mui/material";

export default function DashboardSkeleton({ isDashboard }) {

    if (isDashboard) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Skeleton height={150} width='100%' />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton height={150} width='100%' />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton height={150} width='100%' />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton height={150} width='100%' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton width="50%" />
                    <Skeleton width="90%" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton width="50%" />
                    <Skeleton width="90%" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton width="50%" />
                    <Skeleton width="90%" />
                    <Skeleton height={300} width='100%' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton width="50%" />
                    <Skeleton width="90%" />
                    <Skeleton height={300} width='100%' />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12}>
                <table className="data-table">
                    <thead>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(5).fill("").map((_e, i) => (
                            <tr key={i}>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        </Grid>
    )
}