import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../../common/hook-form";
import UploadIcon from "@mui/icons-material/Upload";
import { apiConstants, postGeneric } from "../../../common/constants";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function UploadImage(props) {
  const { id, setReload, setOpen } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    setIsSubmitting(true);
    let formData = new FormData();
    if (data.file1) {
      formData.append("file1", data.file1[0]);
    }
    if (data.file2) {
      formData.append("file2", data.file2[0]);
    }
    if (data.file3) {
      formData.append("file3", data.file3[0]);
    }
    if (data.file4) {
      formData.append("file4", data.file4[0]);
    }
    if (data.file5) {
      formData.append("file5", data.file5[0]);
    }
    if (data.file6) {
      formData.append("file6", data.file6[0]);
    }

    postGeneric(
      `${apiConstants.IMAGE_UPLOAD_WEIGHT_DISCREPANCY}/${id}/MERCHANT`,
      formData
    )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Image is uploaded Successfully", {
            variant: "success",
          });
          setReload((reload) => reload + 1);
          setOpen((open) => false);
          setIsSubmitting(false);
          reset();
        } else {
          enqueueSnackbar("Image is not uploaded", { variant: "error" });
          setReload((reload) => reload + 1);
          setOpen((open) => false);
          setIsSubmitting(false);
          reset();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };
  return (
    <Card>
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Image 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RHFFileField name="file1" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Image 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RHFFileField name="file2" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Image 3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RHFFileField name="file3" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Image 4</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RHFFileField name="file4" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Image 5</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RHFFileField name="file5" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Image 6</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RHFFileField name="file6" />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                size="medium"
                startIcon={<UploadIcon />}
                type="submit"
              >
                Upload
              </LoadingButton>
            </Grid>
          </Grid>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default UploadImage;
