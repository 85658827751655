import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import { grpupedOrderStatus } from "../../../../helpers/UtilityHelper"
import { StackedBarChart } from "../../../charts/BarChart"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    labelY: 'Shipments Count',
    stackId: 'status',
    data: grpupedOrderStatus.map(status => ({
        dataKey: status,
        label: status.replace(/_/g, " "),
        chartType: 'bar',
        fill: getFillColor(status)
    }))
}

function getFillColor(status) {
    switch (status) {
        case 'ORDER_PLACED':
            return 'rgb(0, 167, 235)'
        case 'INTRANSIT':
            return 'rgba(240, 180, 27, 0.8)'
        case 'DELIVERED':
            return 'rgb(0, 197, 153)'
        case 'RTO':
            return 'rgb(255, 11, 80)'
        case 'DELIVERY_FAILED':
            return '#FC46AA'
        case 'CANCELLED':
            return 'black'
        case 'LOST':
            return 'orange'
        default:
    }

}

export default function StatusWiseSplit({ data }) {
    return (
        <Card elevation={1}>
            <CardHeader title="Status Wise Shipments Trends" />
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <StackedBarChart data={data} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}