import React, { useRef, useLayoutEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
import NodeContent from "./NodeContent";
import ExpandButton from "./ExpendButton";

const OrganizationalChart = (props) => {
  const d3Container = useRef(null);

  useLayoutEffect(() => {    
    const chart = new OrgChart();
    let timer;
    if (props.data && d3Container.current) {
      chart
        .container(d3Container.current)
        .data(props.data)
        .zoomBehavior(null)
        .initialZoom(0.6)
        .svgHeight(500)
        .childrenMargin(() => 40)
        .neightbourMargin(() => 20)
        .nodeWidth((d) => 250)
        .nodeHeight((d) => 120)
        .compactMarginBetween((d) => 50)
        .buttonContent((node, state) => {
          return ReactDOMServer.renderToStaticMarkup(
            <ExpandButton {...node.node} />
          );
        })
        .nodeContent((d) => {
          return ReactDOMServer.renderToStaticMarkup(
            <NodeContent {...d} />
          );
        }).render()


      timer = setTimeout(() => {
        chart.expandAll() 
      }, 1000)
    }

    return () => clearTimeout(timer)

  }, [props, props.data]);


  return (
    <div className="card">
      <h5 className="card-title">Shipments Status Split</h5>
      <div className="card-body" style={{ textAlign: 'center' }} ref={d3Container} />
    </div>
  );
};

export default OrganizationalChart;
