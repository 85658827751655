import { createSlice } from '@reduxjs/toolkit';
import { getServiceability } from '../../actions/serviceability/serviceabilityAction';
const initialState = {
    loading: false,
    serviceabilityInfo: {},
    error: null,
    success: false,
    zipcode: {}
}


export const orderListSlice = createSlice({
    name: 'serviceabilityList',
    initialState,
    reducers: {
        resetOrderList: (state) => {
            state.loading = false
            state.serviceabilityInfo = {}
            state.error = null
            state.zipcode = {}
        },
        setFromandTo: (state, action) => {
            state.zipcode = action.payload
        },
    },
    extraReducers: {
        // get orderSummary api aggregate/orders
        [getServiceability.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null,
            }
        },
        [getServiceability.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                serviceabilityInfo: payload,
            }
        },
        [getServiceability.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetOrderList, setFromandTo } = orderListSlice.actions

export default orderListSlice.reducer