import React from 'react';
import { useNavigate } from 'react-router-dom';

const PreviewOrderDetail = (props) => {
    const navigate = useNavigate();
    const { orderDetail } = props;
    const awb_number = (orderDetail && orderDetail.result && orderDetail.result.awb_number) ? orderDetail.result.awb_number : "";
    const order_type = (orderDetail && orderDetail.result && orderDetail.result.order_type) ? orderDetail.result.order_type : "";
    const customer_detail = (orderDetail && orderDetail.result && orderDetail.result.customer_detail) ? orderDetail.result.customer_detail : {};
    const invoice_value = (orderDetail && orderDetail.result && orderDetail.result.invoice_value) ? orderDetail.result.invoice_value : {};

    return (
        <>
            <div className="col-12 col-md-5 col-lg-3 order-tips mt-4 mt-md-0">
                <div className="card card-tips">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="text-start">Order details</h4>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="order-summary">
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label d-block">Order type</label>
                                    {order_type}</div>
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label d-block">Shipment type</label>
                                    *Express</div>
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="text-start">Customer details</h4>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label d-block">Name</label>
                                    {(customer_detail && customer_detail.to_name) ? customer_detail.to_name : ""}
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label d-block">Phone number</label>
                                    {(customer_detail && customer_detail.to_phone_number) ? customer_detail.to_phone_number : ""}
                                </div>
                                <div className="col-12">
                                    <label className="form-label d-block">Address</label>
                                    {(customer_detail && customer_detail.to_address) ? customer_detail.to_address : ""}
                                    , {(customer_detail && customer_detail.to_city) ? customer_detail.to_city : ""}
                                    , {(customer_detail && customer_detail.to_state) ? customer_detail.to_state : ""}
                                    , {(customer_detail && customer_detail.to_country) ? customer_detail.to_country : ""}-
                                    {(customer_detail && customer_detail.to_pincode) ? customer_detail.to_pincode : ""}
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 est-invoice"> Estimated invoice <span>₹ {invoice_value}</span> </div>
                        </div>
                        <button type="button" className="btn btn-primary btn-next btn w-100 my-0" onClick={() => navigate(`/order-detail?waybill=${awb_number}`)}>View details</button>
                    </div>
                </div>
            </div>
        </>
    )

}
export default PreviewOrderDetail;