import * as React from "react"
import { Grid } from "@mui/material";
import usePerformanceDashboardReverseOrder from "../../common/hooks/dashboards/usePerformanceDashboardReverseOrder";
import RTOReasonWise from "./dashboard_sections/RTOReasonWise";
import RTOCourierWise from "./dashboard_sections/RTOCourierWise";

function RTOAnalysis({ datesAsFilter, selectedDates }) {
    const { data = [], allCouriers, isLoading, courierWiseData } = usePerformanceDashboardReverseOrder(datesAsFilter);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RTOCourierWise
                    isLoading={isLoading}
                    data={courierWiseData}
                    allCouriers={allCouriers}
                    selectedDates={selectedDates}
                />
            </Grid>
            <Grid item xs={12}>
                <RTOReasonWise
                    isLoading={isLoading}
                    data={data}
                    allCouriers={allCouriers}
                    selectedDates={selectedDates}
                />
            </Grid>
        </Grid>
    )
}

export default RTOAnalysis;