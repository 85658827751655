import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';

// ----------------------------------------------------------------------


RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          inputProps={{ 'aria-label': 'controlled' }}
          render={({ field, fieldState: { error } }) => <Checkbox {...field} size={'small'} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onSelected = (option) =>
          field?.value?.includes(option) ? field?.value?.filter((value) => value !== option) : [...field.value, option];

        return (
          <FormControl component="fieldset" error={!!error}>
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup sx={{ flexDirection: 'row' }}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={field?.value?.includes(option.value)}
                      onChange={() => field.onChange(onSelected(option.value))}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      }}
    />
  );
}

RHFRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFRadioGroup({ name, label, options, row = true, ...other }) {
  const { control, getFieldState } = useFormContext();
  const { error } = getFieldState(name)
  const color = other?.color || 'primary'

  return (
    <FormControl component="fieldset" size='small' color={color} error={!!error}>
      <FormLabel component="legend" style={{ fontSize: '14px' }}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <RadioGroup value={field.value} row={row} {...field}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color={error ? 'error' : color} />}
                label={option.label}
                sx={{ "& .MuiFormControlLabel-label": { fontSize: '14px', color: error ? '#db5f5b' : null } }}
                {...other}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}