import React from "react";
import { Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { allZonez, formatNumber } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import MaterialTable from "../../../../common/MaterialTable";
import { StackedBarChart } from "../../../charts/BarChart";

export default function ZoneWiseSplit({ data }) {

    const [view, setView] = React.useState("chart")

    const stackedBarChartOptions = {
        xaxis: 'name',
        stackId: 'status',
        labelY: 'Count',
        data: allZonez.map((z) => ({ dataKey: z, label: z }))
    }

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        {
            label: 'Count',
            value: (row) => {
                return formatNumber(row.count)
            },
            numeric: true,
            align: 'right'
        },
        {
            label: 'Percentage',
            value: (row) => {
                return `${row.percentage}%`
            },
            numeric: true,
            align: 'right',
        },
    ]

    return (
        <Card elevation={1}>
            <CardHeader title="Zone Wise Split" action={(
                <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                    <ToggleButton value="chart">
                        <BarChartIcon />
                    </ToggleButton>
                    <ToggleButton value="table">
                        <TableChartIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )} />
            <CardContent sx={{ px: 0 }}>
                {view === 'chart' && (
                    <StackedBarChart data={data.lineChartData} chartOptions={stackedBarChartOptions} />
                )}
                {view === 'table' && (
                    <MaterialTable size="small" exportExcel={false} rows={data.allLostGroupByZone} columns={tableColumns} />
                )}
            </CardContent>
        </Card>
    )
}