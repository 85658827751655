import * as React from "react"
import  {suffix, formatDate, formatNumber, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import { useRef } from "react";
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";

export default function Allocation({ data, selectedDates, isLoading }) {
    const __tableRef = useRef(null)
    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = 'Courier Allocation';

    return (
        <div className="customScroll" style={{ width: '100%' }}>

            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={(selectedDates.length + 1) * 2 + 1}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName="Allocation" fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2} style={{ width: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <td
                                style={{
                                    backgroundColor: monthColors[i]
                                }}
                                colSpan={2}
                                key={i}
                                align="center"
                            >
                                {formatDate(date, 'MMM yy')}
                            </td>
                        ))}
                        <td align="center" style={{
                            backgroundColor: monthColors[selectedDates.length]
                        }} colSpan={2}>Total</td>
                    </tr>
                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={i}>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>Count</td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>%</td>
                            </React.Fragment>
                        ))}

                        <td align="right" style={{
                            backgroundColor: monthColors[selectedDates.length]
                        }}>Count</td>
                        <td align="right" style={{
                            backgroundColor: monthColors[selectedDates.length]
                        }}>%</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={i}>
                                <td style={{ width: 200 }}>{row.parent ? row.parent:row.name}</td>
                                {selectedDates.map((_d, i) => (
                                    <React.Fragment key={i}>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>
                                            {formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCount`])}
                                        </td>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>
                                            {suffix(row[`${formatDate(_d, 'MMM_yy')}_percentage`], { postfix: '%' })}
                                        </td>
                                    </React.Fragment>
                                ))}
                                <td align="right" style={{
                                    backgroundColor: monthColors[selectedDates.length]
                                }}>
                                    {formatNumber(row.totalCount)}
                                </td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[selectedDates.length]
                                }}>
                                    {suffix(row.totalPercentage, { postfix: '%' })}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}