import { Button, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { FormProvider, RHFTextField } from "../../../common/hook-form"
import Loader from "../../../common/Loader"
import { formatCurrency, parseDateAndTime } from "../../../helpers/UtilityHelper"
import withLayout from "../../../hocs/Layout"
import { useLazyGetPaginatedShipmentsQuery } from "../../../redux/commonRTK"
import _ from "lodash"
// import { Link } from 'react-router-dom'
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Box } from "@mui/system"
// import MUIModal from "../../../common/MUIModal"
import { exclude } from "./index"
import PageTitle from "../../../common/PageTitle"
import { CSVLink } from "react-csv"

const excludes = exclude + ",courierId,shipmentDetail,customerDetail";

const defaultFilters = [
    { field: "orderSubStatus", operator: "eq", value: "UNPROCESSED" }
]

function StuckOrders() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0)
    const [andFilters, setAndFilters] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [isExporting, setIsExporting] = useState(false);
    const csvLink = useRef(null);
    const [getPaginatedShipments, { data, isLoading, isFetching }] = useLazyGetPaginatedShipmentsQuery()

    const csvHeaders = [
        { label: "Reference Id", key: "reference" },
        { label: "Order Id", key: "orderId" },
        { label: "Created Date", key: "createdDate" },
        { label: "Invoice Value", key: "invoice_value" },
        { label: "Payment Mode", key: "payment_mode" },
        { label: "Pickup Pincode", key: "pickup_details.from_pincode" },
        { label: "Delivery Pincode", key: "delivery_details.to_pincode" },
        { label: "Stuck Remarks", key: "remark" },
        // Add more fields as needed
    ];

    useEffect(() => {
        if (data?.count && data?.data) {
            setTotalRows(data.count)
        }
    }, [data])

    useEffect(() => {
        setPage(0)
    }, [sizePerPage])

    useEffect(() => {
        getPaginatedShipments({
            offset: (page * sizePerPage),
            limit: sizePerPage,
            andfilter: [...defaultFilters, ...andFilters],
            excludes
        })

        // eslint-disable-next-line
    }, [page, andFilters])

    const methods = useForm({
        defaultValues: {
            reference: '',
            pickup_pincode: '',
            drop_pincode: ''
        }
    });
    const {
        handleSubmit,
        reset
    } = methods;

    const applyFilters = (filters) => {
        let appliedFilters = [];
        if (filters.reference) {
            appliedFilters.push({ "field": "reference", "operator": "eq", "value": filters.reference })
        }

        if (filters.pickup_pincode) {
            appliedFilters.push({ "field": "pickupDetails.from_pincode", "operator": "eq", "value": filters.pickup_pincode })
        }

        if (filters.drop_pincode) {
            appliedFilters.push({ "field": "deliveryDetails.to_pincode", "operator": "eq", "value": filters.drop_pincode })
        }

        setAndFilters(appliedFilters)
    }

    const fetchAllDataForExport = async () => {
        setIsExporting(true);
        const pageSize = 200; // Fetch 200 records at a time
        let allData = [];
        let offset = 0;

        while (true) {
            const response = await getPaginatedShipments({
                offset: offset,
                limit: pageSize,
                andfilter: [...defaultFilters, ...andFilters],
                excludes
            }).unwrap();

            allData = allData.concat(response.data);
            if (response.data.length < pageSize) break;
            offset += pageSize;
        }

        setExportData(allData);
        setIsExporting(false);
        setTimeout(() => {
            csvLink.current.link.click();
        }, 0);
    };
    const columns = [
        {
            field: 'actions', headerAlign: "left", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                // const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body"><b>Reference Id : </b>{row?.reference}</Typography>
                        <Typography variant="body"><b>Order Id : </b>{row?.orderId}</Typography>
                        <Typography variant="body"><b>Shipping label Id : </b>{row?.shippingLabelId}</Typography>
                        <Typography variant="body">{(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}</Typography>
                        <Typography variant="body"><b>Invoice Value : </b>{(row.invoice_value) ? formatCurrency(row.invoice_value) : "N/A"}</Typography>
                        <Typography variant="body"><b>Payment Mode : </b>{paymentMode}</Typography>
                        <Typography variant="body"><b>Total Units : </b>{_.sumBy(row.item_list, 'units')}</Typography>
                    </Box>
                )
            },
        },
        {
            field: 'pickup', headerAlign: "left", hideSortIcons: true, headerName: 'Pickup Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body"><b>Pickup Details</b></Typography>
                            <Typography variant="body">{row.pickup_details.from_name}</Typography>
                            <Typography variant="body">{row.pickup_details.from_address},{row.pickup_details.from_city}, <span style={{ color: 'red', fontWeight: 500 }}>{row.pickup_details.from_pincode}</span></Typography>
                            <Typography variant="body"><b>Delivery Details</b></Typography>
                            <Typography variant="body">Name : {row.delivery_details.to_name}</Typography>
                            <Typography variant="body">Phone : {row.delivery_details.to_phone_number}</Typography>
                            <Typography variant="body">{row.delivery_details.to_address},{row.delivery_details.to_city},{row.delivery_details.to_state}, <span style={{ color: 'red', fontWeight: 500 }}>{row.delivery_details.to_pincode}</span></Typography>
                        </Box>

                    </>
                )
            },
        },

        // {
        //     field: 'itemDetails', headerAlign: "center", align: "center", hideSortIcons: true, headerName: 'Item Details', flex:1, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        //                 <Button onClick={() => { setItemModal(true); setItemModalData(row.item_list) }}>Items</Button>
        //                 <Typography variant="body">Total Units : {_.sumBy(row.item_list, 'units')}</Typography>
        //             </Box>
        //         )
        //     },
        // },
        {
            field: 'remark', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Remarks', flex: 2.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body">{row.remark}</Typography>
                    </Box>
                )
            },
        },
        /* {
            field: 'action', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Action', width: 130, renderCell: (params) => {
                const { row } = params;
                console.log(row)
                return <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail?waybill=${row.awb_number}`}>Details</Button>
            },
        }, */
    ];

    // const columnsItemModal = [
    //     { field: 'sku_id', headerAlign: "center", hideSortIcons: true, headerName: 'SKU', width: 130 },
    //     { field: 'item_name', headerAlign: "center", hideSortIcons: true, headerName: 'SKU Name', width: 200 },
    //     { field: 'units', headerAlign: "center", hideSortIcons: true, headerName: 'Units', width: 100 }
    // ]

    return (
        <div id="main-content" className="container-fluid">
            <PageTitle>Stuck Shipments</PageTitle>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(applyFilters)}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={3}>
                                    <RHFTextField name="reference" label="Order/Reference Number" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <RHFTextField name="pickup_pincode" label="Pickup Pincode" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <RHFTextField name="drop_pincode" label="Drop Pincode" variant="filled" />
                                </Grid>

                                <Grid item xs={12} md={3} sx={{display:'flex'}}>
                                    <Button type="submit" variant="contained">Filter</Button>
                                    <Button sx={{ ml: 1, mr: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                                        reset()
                                    }}>Reset</Button>
                                    <Button variant="contained" color="success" onClick={fetchAllDataForExport}>
                                        {isExporting ? 'Exporting...' : 'Export CSV'}
                                    </Button>
                                    <CSVLink
                                        data={exportData}
                                        headers={csvHeaders}
                                        filename={"stuck_orders.csv"}
                                        className="hidden"
                                        target="_blank"
                                        ref={csvLink}
                                    />
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        hideFooterPagination={false}
                        autoHeight={true}
                        rows={data?.data || []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        filterMode={"server"}
                        rowCount={totalRows}
                        getRowId={(row) => row.id} />
                </Grid>
            </Grid>
            {(isLoading || isFetching) && <Loader />}
            {/* <MUIModal
                open={itemModal}
                title="Item Details"
                handleClose={() => setItemModal(false)}
            >
                <ProzoDataGrid
                    autoHeight={false}
                    columns={columnsItemModal}
                    rows={itemModalData}
                    // pagination={false}
                    paginationMode="client"
                    rowCount={itemModalData?.length}
                    getRowId={(row) => row.sku_id} />
            </MUIModal> */}
        </div>
    )
}

export default withLayout(StuckOrders)