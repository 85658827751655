import { differenceInDays, eachDayOfInterval, eachWeekOfInterval, getWeek } from 'date-fns';
import React from 'react';
import { formatDate } from '../../helpers/UtilityHelper';
import getColor from '../charts/colors';
import _ from 'lodash';
import { DualAxisChart } from '../charts/CompositChart';
import { Typography } from '@mui/material';

export default function ShipingCostRevenue({ data = [], from_date, to_date }) {
  const startDate = new Date(from_date);
  const endDate = new Date(to_date);

  let allData = data.data || [];
  // console.log(allData);
  allData = allData.map((row) => {

    const awbDate = new Date(row.awbRegisteredDate)
    const week = getWeek(awbDate)

    return {
      ...row,
      week: 'Week ' + week,
      awbRegisteredDate: formatDate(awbDate, 'yyyy-MM-dd')
    }
  })

  const dateDiff = differenceInDays(endDate, startDate)
  let allDates = [], allGroupedData = [];

  let type = 'days';
  if (dateDiff < 32) {
    allDates = eachDayOfInterval({ start: startDate, end: endDate }).map(row => formatDate(row, 'yyyy-MM-dd'))
    allGroupedData = _.groupBy(allData, 'awbRegisteredDate')
  } else if (dateDiff >= 30 && dateDiff < 180) {
    allDates = eachWeekOfInterval({ start: startDate, end: endDate }).map(row => 'Week ' + getWeek(row))
    allGroupedData = _.groupBy(allData, 'week')
    type = 'week'
  }

  const chartData = []
  for (const k of allDates) {
    const obj = { name: k }

    if (type === 'days') {
      obj.name = formatDate(k, 'dd-MMM')
    }

    obj.revenue = _.sumBy(allGroupedData[k], 'invoice_value')
    const shipingCharge = _.sumBy(allGroupedData[k], 'merchant_pricing.price')

    obj.perc = shipingCharge ? Math.round((shipingCharge / obj.revenue) * 100) : 0
    chartData.push(obj)
  }

  const chartOptions = {
    xaxis: 'name',
    yaxis: {
      left: {
        label: 'Total Revenue',
        tickFormatter: (v) => {
          return `${v / 100000}L`
        },
        data: [{
          dataKey: 'revenue',
          label: 'Total Revenue',
          fill: getColor(0),
          chartType: 'bar'
        }]
      },
      right: {
        label: 'Shipping Cost %',
        tickFormatter: (v) => {
          return `${v}%`
        },
        data: [{
          dataKey: 'perc',
          unit: '%',
          label: 'Shipping',
          fill: getColor(1),
          chartType: 'line'
        }]
      }
    }
  }

  return (
    <div className="card">
      <Typography variant="h5" className="card-title">Revenue - Shipping Cost Trend</Typography>
      <Typography variant="caption">Depiction of shipping cost as a percentage of invoice value of all orders placed on Proship platform</Typography>
      <DualAxisChart data={chartData} chartOptions={chartOptions} />

    </div>
  );
}
