import React from 'react'
import DatamapsIndia from 'react-datamaps-india';

const OrderDistributionMap = (props) => {
  return (
    <DatamapsIndia
      regionData={props.regionData}
      hoverComponent={({ value }) => {
        return (
          <>
            <p>{value.name}</p>
            <p>{value.per || 0} <b>({value.value || 0})</b></p>
          </>
        )
      }}
      mapLayout={{
        startColor: '#a9ce7d',
        endColor: '#50C878',
        hoverTitle: 'Count',
        noDataColor: '#E1E1E1',
        borderColor: '#8D8D8D',
        hoverBorderColor: '#8D8D8D',
        hoverColor: '#50C878',
      }}
    />
  )
}

export default OrderDistributionMap;