import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import InfoBox from "../../../../common/InfoBox"
import { DualAxisChart } from "../../../../components/charts/CompositChart"
import { formatNumber, numberPrecision,stringToColor } from "../../../../helpers/UtilityHelper"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    yaxis: {
        left: {
            label: 'Delivery %',
            data: [
                {
                    dataKey: 'deliveryPercentage',
                    label: 'Delivery Percentage',
                    chartType: 'line',
                    unit: '%',
                    fill: 'red',
                },
            ],
        },
        right: {
            label: 'Number Of Shipments',
            data: [
                {
                    dataKey: 'deliveredOrders',
                    label: 'Delivered',
                    chartType: 'line',
                    fill: stringToColor("Delivered"),
                },
                {
                    dataKey: 'pickedUpOrders',
                    label: 'Picked up',
                    chartType: 'line',
                    fill: stringToColor("Picked up"),
                },
            ],
        },
    },
}

export default function DeliveryTrends({ data }) {
    return (
        <Card elevation={1}>
            <CardHeader title="Delivery Percentage Trends" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <InfoBox header={'Picked Shipments'} value={formatNumber(data.pickedUpOrderCount)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header={'Delivered Shipments'} value={numberPrecision(data.allForwardDeliveredPercentage*data.pickedUpOrderCount/100,0)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header={'Delivery %age'} value={numberPrecision(data.allForwardDeliveredPercentage,2)+" %"}  />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <DualAxisChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}