import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  TextField,
  Autocomplete,
} from "@mui/material";

RHFSearchableSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  variant: PropTypes.string,
  disableOptions: PropTypes.array,
  size: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.number,
  enableNoneSelect: PropTypes.bool,
};

function RHFSearchableSelectField({
  name,
  options = [],
  variant,
  label,
  disableOptions = [],
  size = 'medium',
  width = 150,
  maxHeight = 300,
  enableNoneSelect = false,
  noOptionsText = 'No options',
  mt = 1,
  mb = 1,
  ...other
}) {
  // console.log(options, "options")
  // console.log(noOptionsText, "noOptionsText")
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ minWidth: width, mt, mb }}>
          <Autocomplete
            value={options.find(option => option.value === value) || null}
            onChange={(event, item) => {
              onChange(item ? item.value : '');
            }}
            options={options}
            getOptionDisabled={(option) =>
              disableOptions.includes(option.value)
            }
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <li {...props} style={{ color: 'black' }}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={variant || "filled"}
                error={!!error}
                helperText={error?.message}
                size={size}
                {...other}
              />
            )}
            noOptionsText={options.length === 0 ? noOptionsText : noOptionsText}
            fullWidth
            size={size}
          />
          {enableNoneSelect && <FormHelperText sx={{ fontSize: 12 }}>Select "None" for no selection.</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default RHFSearchableSelectField;

