import { getGeneric, postGeneric } from "../common/constants"

export default async function baseQuery ({ url, data, type }) {
    try {
        if (type === "POST") {
            const response = await postGeneric(url, data)
            return response
        } else {
            const response = await getGeneric(url, data)
            return response
        }
    } catch (err) {
        throw err
    }
}