import * as React from "react"
import { formatDate, formatNumber, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'

export default function MonthTotalWiseSplit({ isLoading, data, selectedDates }) {

    const __tableRef = React.useRef(null)

    if (isLoading) {
        return <DashboardSkeleton />
    }
    const heading = 'Total Shipments';

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={selectedDates.length}
                        >
                            {heading}
                        </td>
                    </tr>
                    <tr>
                        {selectedDates.map((date, i) => (
                            <td align="center" style={{
                                backgroundColor: monthColors[i]
                            }} key={i}>{formatDate(date, 'MMM yy')}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {selectedDates.map((date, i) => (
                            <td align="center" style={{
                                backgroundColor: monthColors[i]
                            }} key={i}>{formatNumber(data[i].total)}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}