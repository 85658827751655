import React from "react";
import { Card, CardContent, CardHeader, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { avgPickupAgeing, formatNumber, numberPrecision, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import CompositChart from "../../../charts/CompositChart";
import InfoBox from "../../../../common/InfoBox";
import MaterialTable from "../../../../common/MaterialTable";

export default function OverallAgeing({ data }) {

    const [view, setView] = React.useState("chart")

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxisLabel: 'Percentage',
        data: [
            {
                dataKey: 'avgPickup',
                label: 'Avg Pickup Days',
                chartType: 'line',
            }
        ]
    }

    const { rows, allColumns } = transformChartDataToTable(data.lineChartData, avgPickupAgeing.map((z) => {
        const key = `Ageing_${z}_percentage`
        return { key, label: z.replace(/_/g, ' ') }
    }))

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return row[z] + '%'
            },
            label: z
        })))
    ]

    const breachedPercentage = (data.totalBreachedShipments / data.totalShipmentPickedUp) * 100

    return (
        <Card elevation={1}>
            <CardHeader title="Overall Pickup Ageing" action={(
                <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                    <ToggleButton value="chart">
                        <BarChartIcon />
                    </ToggleButton>
                    <ToggleButton value="table">
                        <TableChartIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )} />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <InfoBox header="Total Shipments Picked up" value={formatNumber(data.totalShipmentPickedUp)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Breached Shipments" value={`${formatNumber(data.totalBreachedShipments)} (${numberPrecision(breachedPercentage)}%)`} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="On Time Pickups" value={`${formatNumber(data.totalShipmentPickedUp - data.totalBreachedShipments)} (${numberPrecision(100 - breachedPercentage)}%)`} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Avg Pickup Days" value={numberPrecision(data.avgPickupDays)} />
                    </Grid>
                </Grid>
                {view === 'chart' && (
                    <CompositChart data={data.lineChartData} chartOptions={chartOptions} />
                )}
                {view === 'table' && (
                    <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />
                )}
            </CardContent>
        </Card>
    )
}