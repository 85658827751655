import React, { useEffect, useState } from "react"
import { Button, Grid } from "@mui/material"
import useNdr from "./useNdr"
import ProzoDataGrid from "../../../common/ProzoDataGrid"
import DashboardSkeleton from "../DashboardSkeleton"
import WhatsAppChatPopup from "./Conversation"
import { FormProvider, RHFTextField, RHFSelectField } from '../../../common/hook-form';
import _ from "lodash";
import { useForm } from 'react-hook-form';

export default function NDR() {
    const [page, setPage] = React.useState(0)
    const [sizePerPage, setSizePerPage] = React.useState(20)
    const { isLoading, groupedByAwbNumber } = useNdr()
    const [filteredData, setFilteredData] = useState(groupedByAwbNumber)
    const yes_no = ["Yes", "No"];
    const [conversation, setConversation] = React.useState(null)

    const methods = useForm({
        defaultValues: {
            awbNumber: '',
            isconversationCompleted: ''
        }
    });

    const {
        handleSubmit,
        reset
    } = methods;

    useEffect(() => {
        setFilteredData(groupedByAwbNumber || []);
    }, [isLoading]);

    if (isLoading) return <DashboardSkeleton isDashboard />

    const columnsItemModal = [
        {
            field: "awbNumber",
            headerName: "AWB Number",
            flex: 1
        },
        {
            field: "paymentMode",
            headerName: "Payment Mode",
            flex: 1
        },
        {
            field: "currentStatus",
            headerName: "Current Shipping Status",
            flex: 1
        },
        {
            field: "courierPartner",
            headerName: "Courier Partner",
            flex: 1
        },
        {
            field: "isconversationCompleted",
            headerName: "Conversation Completed",
            flex: 0.5,
            renderCell: ({ row }) => {
                return <span>{row?.isconversationCompleted ? 'Yes' : 'No'}</span>
            },
            valueGetter: (params) => {
                const { row } = params;
                return row?.isconversationCompleted ? 'Yes' : 'No'
            },
        },
        {
            field: "conversationsSummary",
            headerName: "Conversations Summary",
            type: 'number',
            flex: 1,
            renderCell: ({ row }) => {
                // return <ConversationAction conversations={row?.conversations || []} setConversation={setConversation} />
                return <Button onClick={() => { setConversation(row?.conversations[0]) }}>View Conversation</Button>
            }
        },
    ];

    const applyPickupFilters = (filters) => {
        let filtered = groupedByAwbNumber;

        if (filters.awbNumber) {
            filtered = filtered.filter((item) => item.awbNumber === filters.awbNumber);
        }

        if (filters.isconversationCompleted) {
            const isCompleted = filters.isconversationCompleted === "Yes";
            filtered = filtered.filter((item) => !!item.isconversationCompleted === isCompleted);
        }
        setFilteredData(filtered);
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(applyPickupFilters)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} >
                                <RHFTextField name="awbNumber" label="AWB" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={3} >
                                <RHFSelectField name="isconversationCompleted" variant="filled" width="100%" label="Conversation Completed" options={yes_no.map((op) => ({ label: _.startCase(op), value: op }))} />
                            </Grid>
                            <Grid item xs={6} >
                                <Button type="submit" variant="contained">Filter</Button>
                                <Button sx={{ ml: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                                    reset();
                                    setFilteredData(groupedByAwbNumber);
                                }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>

                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columnsItemModal}
                        rows={filteredData || []}
                        page={page}
                        exportToExcel={true}
                        setPage={setPage}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        hideFooterPagination={false}
                        rowCount={filteredData?.length}
                        getRowId={(row) => row?.id}
                        autoHeight={true}
                        rowHeight={100}
                        pagination={true}
                        filterMode={"server"}
                        paginationMode="client"
                    />
                </Grid>
            </Grid>
            {conversation &&
                <WhatsAppChatPopup conversation={conversation} setConversation={setConversation} />
            }
        </React.Fragment>
    )
}