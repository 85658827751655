import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../../common/constants";

export const userSignup = createAsyncThunk(
    'api/merchant/create',
    async (param, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.SIGNUP}`;

            const { data } = await axios.post(
                url,
                param,
                config
            )
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);