import React from "react";
import {
    Button,
    Grid,
    Paper,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { RHFTextField, FormProvider } from "../../../common/hook-form";


export const FilterLocation = ({ onFilter, resetFilter }) => {
    const methods = useForm({
        defaultValues: {
            name: '',
            contactNumber: ''
        }
    });
    const {
        handleSubmit,
        reset
    } = methods;

    const onSubmit = (data) => {
        onFilter(data);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={3}>
                                    <RHFTextField name="name" label="Name" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <RHFTextField name="contactNumber" label="Contact No." variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button type="submit" variant="contained" size="small" sx={{ ml: 1 }}>Filter</Button>
                                    <Button sx={{ ml: 1 }} variant="contained" size="small" color="error" onClick={() => {
                                        resetFilter()
                                        reset()
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}