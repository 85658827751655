import { createSlice } from '@reduxjs/toolkit';
import { userForgotPassword } from '../../actions/auth/forgotPasswordAction';


const initialState = {
  loading: false,
  fpInfo: {}, // for user object
  error: null,
  success: false, // for monitoring the login process.
  userEmail: '', // new property to store the email
}


export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false
      state.fpInfo = {}
      state.error = null
      state.userEmail = '';
    }
  },

  extraReducers: {
    // login user
    [userForgotPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },

    [userForgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.fpInfo = payload.data
      state.userToken = payload.userToken
      state.userEmail = payload.username;

    },
    [userForgotPassword.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { reset } = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer