import { Grid, Button, Paper, Typography } from '@mui/material';
import { useState } from "react"
import { useForm } from "react-hook-form"
import { FormProvider, RHFFileField, RHFTextField } from "../../../common/hook-form"
import { apiConstants, postMultipart } from '../../../common/constants';
import { useSnackbar } from 'notistack';
import PageTitle from '../../../common/PageTitle';
import { ManifestCourierFilter } from '../../../common/hook-form/CourierFilter';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadSampleFile from '../../../common/DownloadSampleFile';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import MUIModal from '../../../common/MUIModal';
import ErrorIcon from '@mui/icons-material/Error';
import ProzoDataGrid from '../../../common/ProzoDataGrid';

function BulkManifest() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [invalidAwb, setInvalidAwb] = useState([]);
    const { enqueueSnackbar } = useSnackbar()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }

    const validateFileType = (file, fileTypes) => {
        if (!file) return true; // No file uploaded, no need to validate
        const validTypes = Array.isArray(fileTypes) ? fileTypes : [fileTypes];
        return validTypes.some((type) => file.type.includes(type));
    };

    // Yup method for file type validation
    Yup.addMethod(Yup.mixed, 'fileType', function (fileTypes) {
        return this.test('fileType', `Invalid file type. Allowed: ${fileTypes.join(', ')}`, function (value) {
            const { path, createError } = this;
            if (value && value.length > 0 && !validateFileType(value[0], fileTypes)) {
                return createError({ path, message: `Invalid file type. Allowed: ${fileTypes.join(', ')}` });
            }
            return true;
        });
    });

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                courier: Yup.mixed().required("Please Select Courier"),
                pickup_pincode: Yup.string().required('Pincode is required').matches(/^\d{6}$/, 'Please enter a valid pincode'),
                file: Yup.mixed().required("Please Select a File").fileType(['text/csv']), // Custom file type validation
            })
        ),
        defaultValues: {
            courier: null,
            pickup_pincode: '',
            file: null
        }
    });

    const {
        handleSubmit,
        reset
    } = methods;

    const onSubmitBulkUpload = async (data) => {
        setIsUploadingFile(true);
        try {
            let form = new FormData();
            form.append("file", data.file[0])
            await postMultipart(`${apiConstants.GENERATE_BULK_MANIFEST}?courierId=${data.courier}&pickupPincode=${data.pickup_pincode}`, form).then((res) => {
                if (res.status === 200 && res.data?.awbWithErrors === true) {
                    enqueueSnackbar("File Upload Failed", { variant: "error" });
                    setIsUploadingFile(false)
                    handleOpen()
                    const { invalidAwbList, awbWithDifferentCourier, awbWithDifferentPickup, alreadyManifestedAwbList, awbWithDifferentOrderStatus,duplicateAwbList } = res.data;
                    addAwbToMap(invalidAwbList, "Invalid AWB");
                    addAwbToMap(awbWithDifferentCourier, "Different Courier");
                    addAwbToMap(awbWithDifferentPickup, "Different Pickup");
                    addAwbToMap(alreadyManifestedAwbList, "Already Manifested");
                    addAwbToMap(awbWithDifferentOrderStatus, "Different Order Status");
                    addAwbToMap(duplicateAwbList, "Duplicate AWB");
                    const combinedAwbList = Array.from(awbMap.values());
                    setInvalidAwb(combinedAwbList);
                    setIsUploadingFile(false)
                } else if (res.status === 200 && res.data?.awbWithErrors === false) {
                    enqueueSnackbar("Success", { variant: "success" });
                }
            })
        } catch (err) {
            console.log(err);
            const message = err?.response?.data?.message || err.message;
            enqueueSnackbar(message || "Error uploading file", { variant: "error" });
            setIsUploadingFile(false)
        }
        reset()
        setIsUploadingFile(false);
    };

    const awbMap = new Map();
    const addAwbToMap = (awbList, reason) => {
        (awbList || []).forEach(awb => {
            if (awbMap.has(awb)) {
                awbMap.set(awb, {
                    ...awbMap.get(awb),
                    reason: awbMap.get(awb).reason + `, ${reason}`
                });
            } else {
                awbMap.set(awb, { id: awb, awbNumber: awb, reason });
            }
        });
    };


    const columns = [
        {
            field: 'invalidAwbList', headerAlign: "left", align: 'center', hideSortIcons: true, headerName: "AWB", flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container sx={{ height: 30 }} >
                            <Grid item xs={12}>
                                {row.awbNumber}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'reason', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: "Reason", flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container sx={{ height: 30 }} >
                            <Grid item xs={12}>
                                {row.reason}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]

    return (
        <>
            <PageTitle>Bulk Upload Manifest</PageTitle>
            <MUIModal
                open={open}
                handleClose={handleClose}
                maxWidth={'md'}
            >
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <ErrorIcon fontSize="large" color='error' sx={{ mb: 1 }} />
                        <Typography variant="h6">Upload Failed</Typography>
                        <Typography variant="body2" color='error'>Check the AWB numbers below for more details.</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <ProzoDataGrid
                            columns={columns}
                            hideFooterPagination={false}
                            autoHeight={true}
                            rows={invalidAwb || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            rowCount={invalidAwb?.length || 0}
                            paginationMode={"client"}
                            getRowId={(row) => row.id} />
                    </Grid>
                </Grid>
            </MUIModal>
            <Grid container spacing={2}>
                <Grid item xs={12} spacing={2}>
                    <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitBulkUpload)}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sx={{ overflow: 'hidden' }}>
                                    <ManifestCourierFilter />
                                </Grid>
                                <Grid item xs={4} >
                                    <RHFTextField name="pickup_pincode" label="Pickup Pincode" variant="filled" />
                                </Grid>
                                <Grid item xs={12} >
                                    <RHFFileField name="file" fileType={['csv']} label="Click here to upload. Maximum 1000 AWB's can be uploaded in one file." />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LoadingButton
                                        startIcon={<FileUploadIcon />}
                                        type="submit"
                                        loading={isUploadingFile}
                                        variant="contained"
                                        color="warning"
                                        size="small"
                                    >
                                        Upload
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => { reset() }}
                                        sx={{ ml: 1 }}
                                    >
                                        Reset
                                    </Button>
                                    <DownloadSampleFile fileName={"BULK_MANIFEST"} label='SAMPLE' />
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
export default BulkManifest
