import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withLayout from '../../hocs/Layout';
import { Box } from '@mui/system';
import { Chip, Grid } from '@mui/material';
/* import UserInfo from './info'; */
import MerchantDetails from '../merchant-onboarding/details';
import MerchantKYC from '../merchant-onboarding/kyc';
import BankDetails from '../merchant-onboarding/details/bankDetails';
import { MerchantLocations } from '../merchant-onboarding/locations/pickupLocation/index';
import { post, apiConstants } from '../../common/constants';
import { getToken } from "../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";

function Profile() {
    // const { userInfo } = useSelector(state => state.user)
    const userToken = jwt_decode(getToken());
    // const kycStatus = userInfo?.merchant?.status
    const merchantId =
        userToken && userToken.merchantOid ? userToken.merchantOid : "";
    const [reload, setReload] = useState(0);
    const [chipColor, setChipColor] = useState('warning');
    const [merchantData, setMerchantData] = useState();
    const [merchantStatus, setMerchantStatus] = useState('KYC_PENDING');

    useEffect(() => {
        getData();
        if(merchantStatus === 'KYC_PENDING'){
            setChipColor("warning");
        }
        if(merchantStatus === 'KYC_UPLOADED'){
            setChipColor("warning");
        }
        if(merchantStatus === 'KYC_APPROVED'){
            setChipColor("success");
        }
        
    }, [reload,merchantStatus]);

    const getData = async () => {
        let url = apiConstants.BASE_LOCAL + apiConstants.GET_MERCHANT_SEARCH;
        const { data } = await post(url, { "offset": 0, "limit": 1, "orfilter": [], "andfilter": [{ "field": "id", "operator": "eq", "value": merchantId }], "excludes": "mappedOrderRule,sla,codRemittanceConfig" })
        setMerchantData(data);
        setMerchantStatus(data?.data[0]?.status);
    }

    return (
        <div id="main-content" className="container-fluid">
            <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                    <Accordion square elevation={1} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ mr: 2 }} variant="h6" fontWeight={700}>Profile</Typography>
                                {merchantStatus &&
                                    <Chip size="small" color={chipColor} label={merchantStatus?.replace('_', " ")} />
                                }
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MerchantDetails />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square elevation={1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6" fontWeight={700}>Bank Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <BankDetails />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square elevation={1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box>
                                <Typography variant="h6" fontWeight={700}>KYC Details</Typography>
                                {merchantStatus === 'KYC_PENDING' && (
                                    <Chip size="small" sx={{
                                        color: '#d96c00',
                                        backgroundColor: '#fff5eb'
                                    }} label={"KYC verification might take 2-3 business days"} />
                                )}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MerchantKYC setReload={setReload} merchantData={merchantData?.data[0]} merchantStatus={merchantStatus} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square elevation={1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box>
                                <Typography variant="h6" fontWeight={700}>Pickup Locations</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                            <MerchantLocations />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <UserInfo />
                </Grid> */}
            </Grid>
        </div>
    )
}

export default withLayout(Profile)