import React from 'react';
import { ScatterChart as ScatterChartRe, Scatter, XAxis, YAxis, Legend, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { formatDate } from '../../helpers/UtilityHelper';
import _ from 'lodash';
import ChartTooltip from './ChartTooltip';
import getColor from './colors';

function formatYAxis(tickItem) {
  return tickItem + " days"
}

function getColorScatter(status) {
  if (status === 'ORDER_PLACED') {
    return "rgb(0, 167, 235)"
  }
  if (status === 'INTRANSIT') {
    return "rgba(240, 180, 27, 0.8)"
  }
  if (status === 'DELIVERED') {
    return "rgb(0, 197, 153)"
  }
  if (status === 'RTO') {
    return "rgb(255, 11, 80)"
  }
  if (status === 'DELIVERY_FAILED') {
    return "#aa0717"
  }
}

export default function ProzoScatterChart({ data, height }) {
  data.sort((d1, d2) => new Date(d2.date).getTime() - new Date(d1.date).getTime());
  const TooltipScatter = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip-div">
          <p className="label">{formatDate(payload[0].payload.date, "dd, MMM yyyy")}</p>
          <p className="item">Zone: {payload[0].payload.zone}</p>
          <p className="item">Courier: {payload[0].payload.courier}</p>
          <p className="item">TAT: {payload[0].payload.days}</p>
          <p className="item">AWB: {payload[0].payload.awb}</p>
          <p className="item">Status: {payload[0].payload.status}</p>
          <p className="item">Pickup Pincode: {payload[0].payload.pickup}</p>
          <p className="item">Drop: {payload[0].payload.drop_city}, {payload[0].payload.drop_state}, {payload[0].payload.drop}</p>
        </div>
      );
    }

    return null;
  }
  
  return (
    <ResponsiveContainer width="100%" height={height || 550}>
      <ScatterChartRe>
        <CartesianGrid strokeDasharray="5 5" stroke="#ccc" />
        <XAxis fontSize="11px" fontWeight="500" dataKey="date" name="Zone" tickFormatter={(v) => formatDate(v, 'dd MMM')} />
        <YAxis fontSize="11px" fontWeight="500" type="number" allowDecimals={false} dataKey="days" name="Days" tickFormatter={formatYAxis} />
        <Tooltip content={<TooltipScatter />} />

        <Scatter data={data} animationDuration={1500}>
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={getColorScatter(entry.status)} onClick={() => window.open(`/order-detail?waybill=${entry.awb}`)} cursor="pointer" />
          })}
        </Scatter>

        <Legend iconSize="10px" wrapperStyle={{ fontSize: 11, fontWeight: 500 }} payload={
          _.unionBy(data, 'status').map((item, index) => ({
            id: item.zone,
            type: "circle",
            value: ` ${item.status}`,
            color: getColorScatter(item.status)
          }))
        } />

      </ScatterChartRe>
    </ResponsiveContainer>
  );
}

export function ScatterChart({ data, height = 350, chartOptions }) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <ScatterChartRe
        width="100%"
        height={height}
      >
        <CartesianGrid strokeDasharray="5 5" stroke="#ccc" />
        <XAxis fontSize="11px" fontWeight="500" dataKey={chartOptions.xaxis.dataKey} name={chartOptions.xaxis.label} unit={chartOptions.xaxis.unit || ""} />
        <YAxis fontSize="11px" fontWeight="500" dataKey={chartOptions.yaxis.dataKey} name={chartOptions.yaxis.label} unit={chartOptions.yaxis.unit || ""} />
        <Tooltip content={<ChartTooltip />} />

        <Scatter data={data} animationDuration={1500}>
          {data.map((v, i) => {
            return <Cell label="Helll" key={`cell-${i}`} fill={v.fill || getColor(i)} />
          })}
        </Scatter>
      </ScatterChartRe>
    </ResponsiveContainer>
  )
}