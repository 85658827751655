/** @format */

import React from "react";
import ReactMarkdown from "react-markdown";

const TypeSentence = ({
  sentence,
  speed,
  type,
  conversationFieldRef,
  userScrolling,
  setUserScrolling,
  startIndex,
}) => {
  const [typedWords, setTypedWords] = React.useState([]);

  React.useEffect(() => {
    if (conversationFieldRef.current && !userScrolling) {
      conversationFieldRef.current.scrollTo({
        behavior: "instant",
        top: conversationFieldRef.current.scrollHeight,
      });
    }
  }, [typedWords.length, conversationFieldRef, userScrolling]);

  React.useEffect(() => {
    const words = sentence?.split("") || [];

    if (type === "sent") {
      setTypedWords(words);
      return;
    }

    let index = startIndex;

    const totalLength = words.length;
    const typingInterval = setInterval(() => {
      if (index < totalLength) {
        const currentWord = words[index];
        setTypedWords((prevTypedWords) => [...prevTypedWords, currentWord]);
        index++;
      } else {
        setUserScrolling(false);
        clearInterval(typingInterval);
      }
    }, speed);

    return () => clearInterval(typingInterval);
  }, [sentence, speed, type, startIndex, setUserScrolling]);

  return (
    <ReactMarkdown>{typedWords.join("").replace(/<br>/g, "\n")}</ReactMarkdown>
  );
};

export default TypeSentence;
