import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import ErrorBoundary from "../../../../common/ErrorBoundary"
import { Box } from "@mui/system"
import { TableSkeleton } from "../../../../common/Skeletons"
import useNdrAnalysis from "./useNdrAnalysis"
import NDROverview from "./NDROverview"
import ResolutionSummary from "./ResolutionSummary"
import ActionAgeing from "./ActionAgeing"
import ReAttemptConversion from "./ReAttemptConversion"
import ReAttemptAdherence from "./ReAttemptAdherence"
import ExportChartDataToExcel from "../../../../common/ExportChartDataToExcel"
import { getDDMMYYYYHHmmToIST, transformChartDataToTable } from "../../../../helpers/UtilityHelper"
import RTOBeforeDeliveryAttempt from "./RTOBeforeDeliveryAttempt"

const actionCounts = ['Overall', 0, 1, 2, 3, 4, 5, 6];

function NdrComponent() {

    const [calcOn, setCalcOn] = React.useState('DOD')
    const [userActionCount, setUserActionCount] = React.useState('Overall');
    const { isLoading, data } = useNdrAnalysis(calcOn, userActionCount)
    console.log(data)

    const { rows, allColumns } = transformChartDataToTable(data?.lineChartData || [],
        [
            { key: 'totalDelivered', 'label': 'NDR Delivered (Closed)' },
            { key: 'totalOpen', 'label': 'NDR Open' },
            { key: 'totalRTO', 'label': 'NDR RTO (Closed)' },
        ])

    const downloadOptions = [
        {
            label: 'Download NDR Summary',
            fileName: 'NDR_SUMMARY',
            data: rows || [],
            columns: [
                { label: 'Name', value: 'name' },
                ...allColumns.map(col => ({ label: col, value: col }))
            ]
        },
        {
            label: 'NDR Open Orders',
            fileName: 'NDR_OPEN_ORDERS',
            data: data?.openNDR || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Channel', value: 'channelName' },
                { label: 'Courier', value: 'courierName' },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Drop City', value: 'deliveryCity' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'NDR Resolution Days', value: 'ndrResDays' },
                { label: 'NDR Action Count', value: (row) => (row?.actionUpdateLog?.length) },
                { label: 'Order Date', value: (row) => getDDMMYYYYHHmmToIST(row.awbRegisteredDate) },
                { label: 'NDR Reported Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrReportedDate) },
                { label: 'NDR Resolution Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrResolutionDate) },

            ]
        },
        {
            label: 'NDR Lost Orders',
            fileName: 'NDR_LOST_ORDERS',
            data: data?.closedNDRLost || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Channel', value: 'channelName' },
                { label: 'Courier', value: 'courierName' },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Drop City', value: 'deliveryCity' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'NDR Resolution Days', value: 'ndrResDays' },
                { label: 'NDR Action Count', value: (row) => (row?.actionUpdateLog?.length) },
                { label: 'Order Date', value: (row) => getDDMMYYYYHHmmToIST(row.awbRegisteredDate) },
                { label: 'NDR Reported Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrReportedDate) },
                { label: 'NDR Resolution Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrResolutionDate) },

            ]
        },
        {
            label: 'NDR RTO Orders',
            fileName: 'NDR_RTO_ORDERS',
            data: data?.closedNDRRTO || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Channel', value: 'channelName' },
                { label: 'Courier', value: 'courierName' },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Drop City', value: 'deliveryCity' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'NDR Resolution Days', value: 'ndrResDays' },
                { label: 'NDR Action Count', value: (row) => (row?.actionUpdateLog?.length) },
                { label: 'Order Date', value: (row) => getDDMMYYYYHHmmToIST(row.awbRegisteredDate) },
                { label: 'NDR Reported Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrReportedDate) },
                { label: 'NDR Resolution Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrResolutionDate) },

            ]
        },
        {
            label: 'NDR Delivered Orders',
            fileName: 'NDR_Delivered_ORDERS',
            data: data?.closedNDRDelivered || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Channel', value: 'channelName' },
                { label: 'Courier', value: 'courierName' },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Drop City', value: 'deliveryCity' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'NDR Resolution Days', value: 'ndrResDays' },
                { label: 'NDR Action Count', value: (row) => (row?.actionUpdateLog?.length) },
                { label: 'Order Date', value: (row) => getDDMMYYYYHHmmToIST(row.awbRegisteredDate) },
                { label: 'NDR Reported Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrReportedDate) },
                { label: 'NDR Resolution Date', value: (row) => getDDMMYYYYHHmmToIST(row?.ndrResolutionDate) },

            ]
        }
    ]

    return (
        <Accordion expanded>
            <AccordionSummary sx={{ boxShadow: 1, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">NDR Analysis</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <ExportChartDataToExcel options={downloadOptions} />
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        NDR overview chart gives the reader a comprehensive review of total NDR shipments handled in a given date range and current NDR status of these shipments (NDR Open or Closed).
                        <br />Select action count as <b>overall</b> to comprehensively analyze ‘NDR overview’ chart.
                        NDR open shipments (marked in RED color on bar graphs) for OLDER dates is an alert sign and these shipments should be attended to on an urgent basis.

                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} justifyContent="flex-end" display="flex">
                                    <ToggleButtonGroup
                                        exclusive
                                        onChange={(_e, v) => v !== null ? setUserActionCount(v) : false}
                                        value={userActionCount}
                                        size="small"
                                        color="error"
                                        sx={{
                                            mr: 1,
                                            '& .MuiToggleButton-root': {
                                                fontWeight: 'bold',
                                                px: 2
                                            }
                                        }}>
                                        <ToggleButton disabled>Filter By User Action Count</ToggleButton>
                                        {actionCounts.map(count => <ToggleButton key={count} value={count}>{count}</ToggleButton>)}
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <NDROverview data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ResolutionSummary data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ActionAgeing data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <RTOBeforeDeliveryAttempt
                                        data={data?.lineChartData || []}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReAttemptConversion
                                        reattemptConversionSummary={data?.reattemptConversionSummary}
                                        data={data?.lineChartData || []}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReAttemptAdherence
                                        data={data?.lineChartData || []}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function NDRSummary() {
    return (
        <ErrorBoundary>
            <NdrComponent />
        </ErrorBoundary>
    )
}