import { createSlice } from '@reduxjs/toolkit';
import { getOrderSummary } from '../../actions/dashboard/orderSummaryAction';


const initialState = {
  loading: false,
  orderSummaryInfo: [], // for dashboard object
  error: null,
  success: false, // for monitoring the success process.
}


export const orderSummarySlice = createSlice({
  name: 'orderSummary',
  initialState,
  reducers: {
    resetorderSummary: (state) => {
      state.loading = false
      state.orderSummaryInfo = []
      state.error = null
    },
  },
  extraReducers: {
    // get orderSummary api aggregate/orders
    [getOrderSummary.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getOrderSummary.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.orderSummaryInfo = payload;
    },
    [getOrderSummary.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { resetorderSummary } = orderSummarySlice.actions

export default orderSummarySlice.reducer