import withLayout from "../app/hocs/Layout"


function UnauthorizedAccess() {
    return (
        <div id="main-content" className="container-fluid pb-5">
            <img alt="401" src="/img/5206817.jpg" style={{height:'90vh', margin:'0 auto'}} />
        </div>
    )
}

export default withLayout(UnauthorizedAccess)
