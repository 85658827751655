import React, { useEffect, useRef, useState } from "react";
import jwt_decode from "jwt-decode";
import {
    apiConstants,
    post,
    get,
    postMultipart,
    getDownload,
} from "../../../../common/constants";
import "../location.css";
import AddDeliveryLocation from "./addDeliveryLocation";
import Loader from "../../../../common/Loader";
import {
    Button,
    ButtonGroup,
    Chip,
    Grid,
    IconButton,
    Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import ProzoDataGrid from "../../../../common/ProzoDataGrid";
import { useSnackbar } from "notistack";
import { AddCircleRounded, DownloadRounded, Edit, UploadFileRounded } from "@mui/icons-material";
import { getToken } from "../../../../helpers/ReduxHelpers";
import withLayout from "../../../../hocs/Layout";
import { findInObjArr } from "../../../../helpers/UtilityHelper";
import PageTitle from "../../../../common/PageTitle";
import { FilterLocation } from "../Filter";

function MerchantDeliveryLocations() {
    const [show, setShow] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [deliveryLocation, setDelLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [filteredLocationList, setFilteredLocationList] = useState([]);
    const bulkUploadRef = useRef();

    const { enqueueSnackbar } = useSnackbar();


    const handleClose = () => {
        setShow(false);
        setCurrentId(null);
    };

    const handleShow = () => setShow(true);

    const userToken = jwt_decode(getToken());
    const merchantId =
        userToken && userToken.merchantOid ? userToken.merchantOid : "";

    const handleSubmit = (param) => {
        setFormLoading(true);
        const addressCopy = Object.assign(
            {},
            { status: "ACTIVE", address_type: "PICKUP", country: "India" },
            currentId,
            param
        );
        post(
            `${apiConstants.BASE_LOCAL}${apiConstants.ADD_DELIVERY_LOCATIONS}/${merchantId}`,
            addressCopy
        )
            .then((response) => {
                setFormLoading(false);
                handleClose();
                loadLocation();
            })
            .catch((err) => {
                console.error(err);
                setFormLoading(false);
            });
    };

    const loadLocation = () => {
        setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_DELIVERY_LOCATIONS}/${merchantId}`)
            .then((response) => {
                setLoading(false);
                if (response.data && response.data.length > 0) {
                    setDelLocation(response.data);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleEditClick = (id) => {
        const result = findInObjArr(deliveryLocation, id, "id");
        setCurrentId(result);
        handleShow();
    };

    const handleDownloadSampleClick = () => {
        setLoading(true);
        getDownload(
            `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=BULK_DELIVERY_LOC_UPLOAD`
        )
            .then((response) => {
                setLoading(false);
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "delivery-location.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleBulkUpload = (e) => {
        const param = e.target.files[0];
        const formData = new FormData();
        formData.append("file", param);
        formData.append("fileName", param.name);
        setLoading(true);
        postMultipart(`${apiConstants.UPLOAD_DELIVERY_LOCATIONS}`, formData)
            .then((response) => {
                setLoading(false);
                loadLocation();
                // enqueueSnackbar(response?.data, { variant: "success" });
                enqueueSnackbar("Uploaded", { variant: "success" });
                if (bulkUploadRef.current) {
                    bulkUploadRef.current.value = null;
                }
            })
            .catch((err) => {
                enqueueSnackbar("Could not be uploaded!", { variant: "error" });
                setLoading(false);
                if (bulkUploadRef.current) {
                    bulkUploadRef.current.value = null;
                }
            });
    };

    useEffect(() => {
        loadLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilter = (filterValues) => {
        const { contactNumber, name } = filterValues;
        const filteredLocations = deliveryLocation.filter((location) => {
            const matchesContactNumber = contactNumber
                ? location.contactNumber.includes(contactNumber)
                : true;
            const matchesName = name
                ? location.name.toLowerCase().includes(name.toLowerCase())
                : true;
            return matchesContactNumber && matchesName;
        });
        setFilteredLocationList(filteredLocations);
    };

    useEffect(() => {
        setFilteredLocationList(deliveryLocation);
    }, [deliveryLocation]);

    const resetFilter = () => {
        setFilteredLocationList(deliveryLocation);
    };

    return (
        <>
            <PageTitle>Delivery Location</PageTitle>
            {loading && <Loader />}
            <input
                type="file"
                onChange={handleBulkUpload}
                ref={bulkUploadRef}
                style={{ display: "none" }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box
                        sx={{
                            justifyContent: "flex-end",
                            width: 1,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ButtonGroup
                            sx={{
                                "& button": {
                                    borderRight: "0 !important",
                                    color: "inherit",
                                    textTransform: "capitalize",
                                },
                            }}
                            variant="text"
                        >
                            <Button
                                startIcon={<AddCircleRounded color="primary" />}
                                type="button"
                                color="primary"
                                onClick={handleShow}
                            >
                                Add
                            </Button>
                            <Button
                                startIcon={<UploadFileRounded color="primary" />}
                                type="button"
                                color="warning"
                                onClick={() => bulkUploadRef.current.click()}
                            >
                                Upload
                            </Button>
                            <Button
                                startIcon={<DownloadRounded color="primary" />}
                                type="button"
                                color="warning"
                                onClick={handleDownloadSampleClick}
                            >
                                Sample Download
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <FilterLocation onFilter={handleFilter} resetFilter={resetFilter} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <ProzoDataGrid
                        rows={filteredLocationList || []}
                        getRowId={(row) => row.id}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        rowCount={filteredLocationList?.length || 0}
                        paginationMode={'client'}
                        columns={[
                            { headerName: "Name", field: "name", flex: 1 },
                            {
                                headerName: "Address",
                                field: "pincode",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    const addArr = [];
                                    if (row.address_line) addArr.push(row.address_line);
                                    if (row.city) addArr.push(row.city);
                                    if (row.state) addArr.push(row.state);
                                    if (row.pincode) addArr.push(row.pincode);
                                    if (row.country) addArr.push(row.country);
                                    return addArr.join(", ");
                                },
                            },
                            { headerName: "Drop Address", field: "dropOrganization", flex: 1 },
                            { headerName: "Contact No", field: "contactNumber", flex: 1 },
                            { headerName: "Email", field: "email", flex: 1 },
                            { headerName: "GSTIN", field: "gstin", flex: 1 },
                            {
                                headerName: "Status",
                                field: "status",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    return (
                                        <Chip
                                            sx={{ fontWeight: 500 }}
                                            label={row.status ? 'ACTIVE' : 'INACTIVE'}
                                            size="small"
                                            color={row.status === "ACTIVE" ? "success" : "error"}
                                        />
                                    );
                                },
                            },
                            {
                                headerName: "Action",
                                field: "action",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    return (
                                        <>
                                            <Tooltip title="Edit Delivery Location">
                                                <IconButton onClick={() => handleEditClick(row.id)}>
                                                    <Edit color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    );
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <AddDeliveryLocation
                show={show}
                formLoading={formLoading}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                currentId={currentId}
            />
        </>
    );
}

export default withLayout(() => {
    return (
        <div id="main-content" className="container-fluid">
            <MerchantDeliveryLocations />
        </div>
    );
});


