import React, { useEffect, useState } from 'react';
import Loader from '../../common/Loader';
import { apiConstants, get, post, razorPayID } from '../../common/constants';
import jwt_decode from "jwt-decode";
import { getToken } from '../../helpers/ReduxHelpers';
import { findWalletBalance } from '../../helpers/UtilityHelper';
import withLayout from '../../hocs/Layout';
import BalanceDetails from './balanceDetails';
import './wallet.css';
// import TransactionHistory from './transactionHistory';
// import WalletHistory from './walletHistory';
// import { useSelector } from 'react-redux';
import OrderTransaction from './orderTransaction';
// import FilterWallet from './filter';
import OtherTransaction from './otherTransaction';
import { Box } from '@mui/material';
import DateRangePicker from '../DateRangePicker';
import { subDays } from "date-fns";
import PageTitle from '../../common/PageTitle';


const Wallet = () => {
    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const [merchant, setMerchant] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState("wb");
    // const [walletData, setWalletBalance] = useState({});

    // const fromAndTo = getDashboardDateFilter(viewbyDDOptSelected);

    // const initFromDate = fromAndTo.fromDate;
    // const initToDate = fromAndTo.toDate;
    // const [show, setShow] = useState(false);
    // const [fromDate, setFromDate] = useState(initFromDate);
    // const [toDate, setToDate] = useState(initToDate);
    const [dateRange, setDateRange] = useState([{
        endDate: new Date(),
        startDate: subDays(new Date(), 3),
        key: 'selection'
    }])
    // console.log(dateRange)
    // const handleClose = () => {
    //     setShow(false);
    // };

    // const handleShow = () => setShow(true);

    // const handleFilterSubmit = (param) => {
    //     setFromDate(param.fromDate);
    //     setToDate(param.toDate);
    //     setShow(false);
    // }

    const loadMerchant = () => {
        setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_ALL_WALLET}?merchantId=${merchantId}`).then(response => {
            setLoading(false);
            setMerchant(response.data);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }



    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function initiateRazorPay(paydata) {
        const merchantDetails = findWalletBalance(merchant);
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const { amount, id, currency } = paydata;
        const options = {
            key: razorPayID, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: merchantDetails.merchantName,
            description: "Wallet Recharge Proship",
            image: "",
            order_id: id,
            handler: async function (response) {
                // const data = {
                //     orderCreationId: id,
                //     razorpayPaymentId: response.razorpay_payment_id,
                //     razorpayOrderId: response.razorpay_order_id,
                //     razorpaySignature: response.razorpay_signature,
                // };
                verifypayment(response, paydata);
                // console.log("razorpay response", response);
            },
            prefill: {
                name: merchantDetails.merchantName,
                email: merchantDetails.merchantEmail,
                //contact: "9999999999",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

    }
    const verifypayment = (data, paymentdata) => {
        //VERIFY_PAYMENT
        const payload = {
            "payment_id": data.razorpay_payment_id,
            "order_id": data.razorpay_order_id,
            "wallet_type": walletDetails.walletType,
            "currency": paymentdata.currency,
            "amount": paymentdata.amount,
        }
        setLoading(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.VERIFY_PAYMENT}`, payload).then(response => {
            setLoading(false);
            //setMerchant(response.data);
            loadMerchant();
        })
            .catch(err => { console.error(err); setLoading(false); });
    }

    const makePayment = (amount) => {
        const payload = {
            "merchantOId": merchantId,
            "amount": parseInt(amount),
            "currency": "INR"
        }
        setLoading(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.ADD_WALLET_BALANCE}`, payload).then(response => {
            setLoading(false);
            // setWalletBalance(response.data);
            initiateRazorPay(response.data);
        })
            .catch(err => { console.error(err); setLoading(false); });

    }

    useEffect(() => {
        loadMerchant();
        
        // eslint-disable-next-line
    }, []);

    let walletDetails = {};
    if (merchant && Object.keys(merchant).length > 0) {
        walletDetails = findWalletBalance(merchant);
    }

    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>Proship Wallet</PageTitle>
                {(loading) && <Loader />}
                <div className="row">

                    {/* <div className="col-12 col-md-12 col-lg-9 text-end mb-3 mb-lg-0">
                        <a
                            href="#"
                            className="btn btn-link py-0"
                            onClick={handleShow}
                        >
                            <img src="img/filter.svg" width="22" alt="" />
                            <span className="d-none d-md-inline-block">Filter</span>
                        </a>
                    </div> */}

                </div>
                <div className="row">
                    <div className="col-12 position-relative">
                        {/* <h6>*Beta version</h6> */}
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <DateRangePicker
                                defaultRange={dateRange}
                                maxDate={new Date()}
                                onChange={(range) => setDateRange(range)}
                            />
                        </Box>

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="ndr-pending-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#ndr-pending-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="ndr-pending-tab-pane"
                                    aria-selected="true"
                                    onClick={() => setCurrentTab("wb")}
                                >
                                    Wallet Balance
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="ndr-closed-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#ndr-closed-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="ndr-closed-tab-pane"
                                    aria-selected="false"
                                    onClick={() => setCurrentTab("ot")}
                                >
                                    Order Transactions History
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="ndr-intiated-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#ndr-intiated-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="ndr-intiated-tab-pane"
                                    aria-selected="false"
                                    onClick={() => setCurrentTab("othistory")}
                                >
                                    Other Transactions History
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="NDRTabContent">
                            <div className="tab-pane fade show active" id="ndr-pending-tab-pane" role="tabpanel" aria-labelledby="ndr-pending-tab" tabIndex="0">
                                <BalanceDetails
                                    walletDetails={walletDetails}
                                    makePayment={makePayment}
                                    merchantId={merchantId}
                                />
                            </div>
                            <div className="tab-pane fade" id="ndr-intiated-tab-pane" role="tabpanel" aria-labelledby="ndr-intiated-tab" tabIndex="0">
                                {currentTab === "othistory" &&
                                    <OtherTransaction
                                        merchantId={merchantId}
                                        walletDetails={walletDetails}
                                        dateRange={dateRange}
                                    />
                                }
                            </div>
                            <div className="tab-pane fade" id="ndr-closed-tab-pane" role="tabpanel" aria-labelledby="ndr-closed-tab" tabIndex="0">
                                {currentTab === "ot" &&
                                    <OrderTransaction
                                        merchantId={merchantId}
                                        walletDetails={walletDetails}
                                        dateRange={dateRange}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12"> </div>
                </div>
            </div>
            {/* <FilterWallet
                show={show}
                handleFilterSubmit={handleFilterSubmit}
                handleClose={handleClose}
                fromDate={fromDate}
                toDate={toDate}
            /> */}
        </>
    );
}

export default withLayout(Wallet);