import React from 'react';
import "./notification.css";
import Marquee from "react-fast-marquee";


const MarqueeNotification = ({ notificationList }) => {
    return (
        <div className='notification-container'>
            <Marquee pauseOnHover speed='80'>
                {notificationList?.length > 0 && notificationList?.map((msg, index) => (
                    <div key={index} className="notification-item">
                        {<span className="notification-dot"></span>}
                        {<span className="notification-header">{msg?.header}</span>}
                        {" - "}
                        {msg?.text}
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default MarqueeNotification;
