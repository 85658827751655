import { createSlice } from '@reduxjs/toolkit';
import { createBulkOrder } from '../../actions/bulkOrderCreate/bulkOrderCreateAction';
const initialState = {
    loading: false,
    bulkOrderInfo: {},
    error: null,
    success: false,
}


export const bulkOrderCreateSlice = createSlice({
    name: 'bulkOrderCreate',
    initialState,
    reducers: {
        resetBulkOrderCreate: (state) => {
            state.loading = false
            state.bulkOrderInfo = {}
            state.error = null
        },
    },
    extraReducers: {
        // get orderSummary api aggregate/orders
        [createBulkOrder.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null
            }
        },
        [createBulkOrder.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                bulkOrderInfo: {payload}
            }
        },
        [createBulkOrder.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetBulkOrderCreate } = bulkOrderCreateSlice.actions

export default bulkOrderCreateSlice.reducer