import { useParams } from "react-router-dom";
import { differenceInDays, parse } from "date-fns";
import _ from 'lodash';
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK";
import { apiConstants } from "../../../../common/constants";
import { useGetSelectedFilters } from "../../../../common/contexts/Filters";
import { getMerchant } from "../../../../helpers/ReduxHelpers";

export default function useStuckShipments(selectedHour) {

    const { id } = getMerchant();

    const requestPayload = { url: apiConstants.getSnapshotsNotUpdateLastNHours, data: { hours: selectedHour, merchantId: id } };
    const { data: rawData, isLoading, isFetching } = useGenericPostRequestQuery(requestPayload);
    const filters = useGetSelectedFilters()

    if (isLoading || isFetching) return { isLoading: true };

    let data = filters ? _.filter(rawData, filters) : rawData;
    let uniqCourierNames = _.map(_.uniqBy(data, 'courier'), 'courier');

    const dataGroupedByCourier = _(data)
        .groupBy('courier')
        .map(function (items, name) {
            return { name, count: items.length }
        }).value();

    const dataGroupedByStatus = _(data)
        .groupBy('orderStatus')
        .map(function (items, name) {
            return { name: name.replace(/_/g, " "), count: items.length }
        }).value();

    const dataGroupedByAgeing = _(data)
        .groupBy(function (item) {

            let dayDiff;
            let key;

            if (item.awbRegisteredDate) {
                dayDiff = differenceInDays(new Date(), parse(item.awbRegisteredDate, 'dd-MM-yyyy HH:mm', new Date()))
            }

            if (dayDiff <= 7) {
                key = '1-7 Days';
            } else if (dayDiff <= 14) {
                key = '8-14 Days';
            } else if (dayDiff <= 21) {
                key = '15-21 Days';
            } else if (dayDiff <= 30) {
                key = '22-30 Days';
            } else {
                key = '30+ Days';
            }


            return key;
        })
        .map(function (items, key) {

            const row = { name: key, count: items.length };
            const groupedByCourier = _.groupBy(items, 'courier');

            for (const courierId of Object.keys(groupedByCourier)) {
                row[courierId] = groupedByCourier[courierId].length;
            }

            return row;
        }).value()

    return { rawData, dataGroupedByAgeing, dataGroupedByCourier, dataGroupedByStatus, uniqCourierNames, isLoading: false }
}