import { Card, CardContent, CardHeader, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import { parseDateAndTime } from '../../../helpers/UtilityHelper';

const TrackingResult = (props) => {
    const { trackingDetailInfo } = props;
    const waybill = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].waybill) ? trackingDetailInfo.waybillDetails[0].waybill : "";
    const orderId = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].orderId) ? trackingDetailInfo.waybillDetails[0].orderId : "";
    const currentLocation = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].currentLocation) ? trackingDetailInfo.waybillDetails[0].currentLocation : "";
    const currentStatus = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].currentStatus) ? trackingDetailInfo.waybillDetails[0].currentStatus : "";
    const pickupLocation = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].pickupLocation) ? trackingDetailInfo.waybillDetails[0].pickupLocation : "";
    const deliveryLocation = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].deliveryLocation) ? trackingDetailInfo.waybillDetails[0].deliveryLocation : "";
    const courierPartner = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].courierPartner) ? trackingDetailInfo.waybillDetails[0].courierPartner : "";
    const paymentMode = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].paymentMode) ? trackingDetailInfo.waybillDetails[0].paymentMode : "";
    const edd = (trackingDetailInfo.waybillDetails && trackingDetailInfo.waybillDetails.length > 0 && trackingDetailInfo.waybillDetails[0].edd) ? parseDateAndTime(trackingDetailInfo.waybillDetails[0].edd, true) : "";
    const color = paymentMode === 'COD' ? "error" : "success";
    return (
        <>
            <Grid container spacing={1} sx={{ paddingBottom:3, paddingLeft:1 }}>
                <Card>
                    <CardHeader title="Tracking result" />
                    <CardContent>

                        <Typography paddingBottom={2}><img src="img/pickup-schedule.svg" className="float-start me-2" width="35" alt="" />
                        <Typography component='span' variant='subtitle2'> {currentStatus}</Typography>
                           </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>AWB number</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{waybill}</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>Order id</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{orderId}</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>Current location</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{currentLocation}</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>Courier partner</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{courierPartner}</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>Payment type</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'><Chip size="small" color={color} label={paymentMode}/></Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>Pick up location</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{pickupLocation}</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>Delivery location</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{deliveryLocation}</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2' color={"black"}>EDD</Typography></Grid>
                            <Grid item xs={12} md={6}><Typography variant='subtitle2'>{edd}</Typography></Grid>



                        </Grid>



                    </CardContent>
                </Card>
            </Grid>
            {/* <div className="col-12">
                <div className="card card-pr card-tracking-result">
                    <div className="card-body p-0">
                        <h5 className="card-title">Tracking result</h5>
                        <div className="row">
                            <div className="col-12"></div>
                            <div className="col-12 col-md d-flex justify-content-start align-items-center">
                                <img src="img/pickup-schedule.svg" className="float-start me-2" width="35" alt="" />
                                {currentStatus}
                                <div className="clearfix d-block d-md-none"></div>
                            </div>
                            <div className="col-12 d-block d-md-none d-lg-none">
                                <hr className="d-block d-md-none" />
                            </div>
                            <div className="col-6 col-md mb-4 mb-md-0">
                                <label className="form-label">AWB number</label>
                                <br />{waybill}</div>
                            <div className="col-6 col-md mb-4 mb-md-0">
                                <label className="form-label">Order id</label>
                                <br />{orderId}</div>
                            <div className="col-6 col-md mb-4 mb-md-0">
                                <label className="form-label">Current location</label>
                                <br />{currentLocation}</div>
                            <div className="col-6 col-md mb-4 mb-md-0">
                                <label className="form-label">Courier partner</label>
                                <br />{courierPartner}</div>
                            <div className="col-6 col-md mb-4 mb-md-0">
                                <label className="form-label">Payment type</label>
                                <br />{paymentMode}</div>
                            <div className="col-6 col-md mb-4 mb-md-0">
                                <label className="form-label">Pick up location</label>
                                <br />{pickupLocation}</div>
                            <div className="col-6 col-md">
                                <label className="form-label">Delivery location</label>
                                <br />{deliveryLocation}</div>
                            <div className="col-6 col-md">
                                <label className="form-label">EDD</label><br />
                                {edd}</div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};
export default TrackingResult;