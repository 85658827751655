import React from "react"
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import _ from 'lodash'
import { formatDate, getOrderStatus, getYYYYMMDD, numberPrecision } from "../../../../helpers/UtilityHelper"
import { addDays, addMonths, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from "date-fns"
import useGetData from "./useGetData"
import { addWeeks } from "date-fns"

const weekStartsOn = 1

export default function useRVP(groupOn) {
    const [isCalc, setIsCalc] = React.useState(true)
    const { from_date, to_date } = useGetSelectedDateRange()
    const { allPickupShipments, allPendingPickupShipments, allDeliveredShipments, allInTransitShipments, finalDataWithAllAdditionalData, isLoading } = useGetData()

    const result = React.useMemo(() => {

        if (isLoading) {
            setIsCalc(true)
            return;
        }

        let intervalArr = [],
            courierWiseDeliveryAttempt = [],
            courierWiseDelivered = [],
            uniqCourierIds = _(finalDataWithAllAdditionalData).uniqBy('courier').map('courier').value(),
            deliveryOutTat = [],
            chartData = [];

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allRegisteredDataGroupedByDate = _.groupBy(finalDataWithAllAdditionalData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()
            let name = null;

            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }
            let row = { name }
            const allRegisteredDatesBetween = _.filter(Object.keys(allRegisteredDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allRegisteredDataBetweenDate = allRegisteredDatesBetween.map(key => allRegisteredDataGroupedByDate[key]).flat()

            row.percentage = Number(numberPrecision((allRegisteredDataBetweenDate.length / finalDataWithAllAdditionalData.length) * 100)) || 0

            // Group By pickup ageing
            _(allRegisteredDataBetweenDate)
                .groupBy(function (shipment) {

                    if (shipment.diffInDaysPickup >= 0 && shipment.diffInDaysPickup <= 1) {
                        return '0-1 Day'
                    } else if (shipment.diffInDaysPickup <= 3) {
                        return '2-3 Days'
                    } else if (shipment.diffInDaysPickup <= 7) {
                        return '4-7 Days'
                    } else if (shipment.diffInDaysPickup <= 15) {
                        return '5-15 Days'
                    }

                    return '15+ Days'

                }).forEach(function (items, key) {
                    const count = items.length
                    row[`Ageing_${key}_count`] = count
                    row[`Ageing_${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100)) || 0
                })

            // Group By delivery ageing
            _(allRegisteredDataBetweenDate)
                .groupBy(function (shipment) {

                    if (shipment.diffInDaysDelivery >= 0 && shipment.diffInDaysDelivery <= 1) {
                        return '0-1 Day'
                    } else if (shipment.diffInDaysDelivery <= 3) {
                        return '2-3 Days'
                    } else if (shipment.diffInDaysDelivery <= 7) {
                        return '4-7 Days'
                    } else if (shipment.diffInDaysDelivery <= 15) {
                        return '5-15 Days'
                    }

                    return '15+ Days'

                }).forEach(function (items, key) {
                    const count = items.length
                    row[`Delivery_${key}_count`] = count
                    row[`Delivery_${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100)) || 0
                })

            // Group by status
            _(allRegisteredDataBetweenDate)
                .groupBy(function (item) {
                    return getOrderStatus(item.orderStatus)
                }).forEach(function (items, key) {
                    row[key] = items.length
                })

            // Group By Courier
            _(allRegisteredDataBetweenDate)
                .groupBy('courier').forEach(function (items, key) {
                    const count = items.length
                    row[`${key}_count`] = count;
                    row[`${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100)) || 0
                })

            chartData.push(row)
        }

        const courierWiseAgeingPickup = _(finalDataWithAllAdditionalData)
            .groupBy('courier')
            .map(function (items, key) {
                const row = { name: key, ...groupByKeyObj(items, 'diffInDaysPickup', items.length) };
                return row;
            }).value()

        const courierWiseAgeingDelivery = _(finalDataWithAllAdditionalData)
            .groupBy('courier')
            .map(function (items, key) {
                const row = { name: key, ...groupByKeyObj(items, 'diffInDaysDelivery', items.length) };
                return row;
            }).value()

        const results = { courierWiseAgeingPickup, courierWiseAgeingDelivery, chartData, deliveryOutTat, courierWiseDeliveryAttempt, uniqCourierIds, courierWiseDelivered }

        setIsCalc(false)
        return results;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDataWithAllAdditionalData?.length, from_date, to_date, isLoading, groupOn])

    if (isCalc) return { isLoading: isCalc }
    if (result) {
        result.totalRVPOrders = finalDataWithAllAdditionalData?.length
        result.pickedUpRVP = allPickupShipments?.length
        result.pendingPickup = allPendingPickupShipments?.length
        result.delivered = allDeliveredShipments?.length
        result.intransit = allInTransitShipments?.length

        result.rvpDeliveredPercentage = Math.round(result.delivered / result.pickedUpRVP * 100) || 0
        result.rvpPickupPercentage = Math.round((result.pickedUpRVP / result.totalRVPOrders) * 100) || 0
        result.rvpPendingPickupPercentage = Math.round((result.pendingPickup / result.totalRVPOrders) * 100) || 0
    }
    return { data: result, isLoading: isCalc };
}

function groupByKeyObj(items, key, totalRvpOrders) {

    let row = {};
    const groupedData = _.groupBy(items, (ship) => getAgeingKey(ship[key]));
    for (const key of Object.keys(groupedData)) {
        const count = groupedData[key].length;
        row[`${key}_count`] = count;
        row[`${key}_perc`] = Number(numberPrecision((count / totalRvpOrders) * 100));
    }

    return row;
}

function getAgeingKey(diffInDaysPickup) {
    if (diffInDaysPickup >= 0 && diffInDaysPickup <= 1) {
        return '0-1 Day'
    } else if (diffInDaysPickup <= 3) {
        return '2-3 Days'
    } else if (diffInDaysPickup <= 7) {
        return '4-7 Days'
    } else if (diffInDaysPickup <= 15) {
        return '5-15 Days'
    }

    return '15+ Days'
}