import * as React from 'react'
import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  makeStyles,
  Grid
} from '@mui/material'

export default function AccSkeleton({ repeat }) {
  return (
    <>
      {Array(repeat)
        .fill('')
        .map((k, i) => (
          <Stack
            key={i}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            width='100%'
            height={60}
            sx={{
              backgroundColor: '#fff',
              marginTop: 2,
              marginBottom: 2,
              padding: 2,
              borderRadius: 1,
              border: '1px solid rgba(145, 158, 171, 0.24)',
            }}
            alignItems='center'
            justifyContent='center'
          >
            <Box width='10%'>
              <Skeleton width='100%' height='25px' />
            </Box>
            <Box width='19%'>
              <Skeleton width='100%' height='25px' />
            </Box>
            <Box width='19%'>
              <Skeleton width='100%' height='25px' />
            </Box>
            <Box width='19%'>
              <Skeleton width='100%' height='25px' />
            </Box>
            <Box width='19%'>
              <Skeleton width='100%' height='25px' />
            </Box>
            <Box width='10%' display='flex' alignItems='center' justifyContent='center'>
              <Skeleton width='100%' height='25px' />
            </Box>
          </Stack>
        ))}
    </>
  )
}

export function TableSkeleton({ rows = 5, columns = 5, size = 'small' }) {
  return (
    <React.Fragment>
      <Table size={size} sx={{ marginBottom: 2, bgcolor: 'white' }}>
        <TableBody
          sx={{
            '& tr td, td td': {
              border: '1px solid #ccc',
            },
          }}
        >
          {Array(rows)
            .fill('')
            .map((row, i) => (
              <TableRow key={i}>
                {Array(columns)
                  .fill('')
                  .map((column, j) => (
                    <TableCell key={j}>
                      <Skeleton />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export function FormSkeleton() {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
    </Grid>
  );
};
export function CourierSkeleton() {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Grid>
    </Grid>
  );
};
