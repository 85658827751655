import React from 'react';

const Loader = () => {
    return (
        <div className="row">
            <div className="col-12 position-relative">
                <div className="content-loader">
                    <img src="/img/wmsloader.svg" width="50" alt="wmsloader" />
                </div>
            </div>
        </div>
    );
};
export default Loader;