import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import { formatNumber, grpupedOrderStatus, numberPrecision } from "../../../../helpers/UtilityHelper"
import InfoBox from "../../../../common/InfoBox"
import { StackedBarChart } from "../../../charts/BarChart"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    labelY: 'Shipments Count',
    stackId: 'status',
    data: grpupedOrderStatus.map(status => ({
        dataKey: status,
        label: status.replace(/_/g, " "),
        chartType: 'bar',
        fill: getFillColor(status)
    }))
}

function getFillColor(status) {
    switch (status) {
        case 'ORDER_PLACED':
            return 'rgb(0, 167, 235)'
        case 'INTRANSIT':
            return 'rgba(240, 180, 27, 0.8)'
        case 'DELIVERED':
            return 'rgb(0, 197, 153)'
        case 'RTO':
            return 'rgb(255, 11, 80)'
        case 'DELIVERY_FAILED':
            return '#FC46AA'
        case 'CANCELLED':
            return 'black'
        case 'LOST':
            return 'orange'
        default:
    }

}

export default function StatusWiseSplit({ data }) {
    const totalColumnsCount = data?.groupedByStatus?.length || 4

    return (
        <Card elevation={1}>
            <CardHeader title="Status Wise Shipments Trends" />
            <CardContent>
                <Grid container spacing={2}>
                    {data?.groupedByStatus?.map((row) => (
                        <Grid key={row.status} item xs={Number(numberPrecision(12 / totalColumnsCount))}>
                            <InfoBox header={row.status.replace(/_/g, " ")} value={formatNumber(row.count)} />
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <StackedBarChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}