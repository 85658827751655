import { createSlice } from '@reduxjs/toolkit';
import { fetchReportData } from './actions/reportAction';

const initialState = {
  reportData: null,
  loading: false,
  error: null,
};


export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchReportData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchReportData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reportData = payload;
    },
    [fetchReportData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default reportSlice.reducer;
