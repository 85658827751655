import { useGetRTODashboardQuery } from "../../../redux/commonRTK";
import { formatDate, ndrReasons } from '../../../helpers/UtilityHelper';
import { useGetCouriersQuery } from '../../../redux/commonRTKPersist';
import _ from 'lodash';

export default function usePerformanceDashboardReverseOrder(dates = []) {
    const { data: allCourses, isLoading: isLoading1, isFetching: isFetching1 } = useGetCouriersQuery()
    const { data, isLoading, isFetching } = useGetRTODashboardQuery({
        from_date: _.get(dates, '0.start'),
        to_date: _.get(dates, '0.end'),
        order_status: 'RTO'
    })
    const { data: data1, isLoading: isLoading2, isFetching: isFetching2 } = useGetRTODashboardQuery({
        from_date: _.get(dates, '1.start'),
        to_date: _.get(dates, '1.end'),
        order_status: 'RTO'
    })
    const { data: data2, isLoading: isLoading3, isFetching: isFetching3 } = useGetRTODashboardQuery({
        from_date: _.get(dates, '2.start'),
        to_date: _.get(dates, '2.end'),
        order_status: 'RTO'
    })

    if (
        isLoading || isFetching ||
        isLoading1 || isFetching1 ||
        isLoading2 || isFetching2 ||
        isLoading3 || isFetching3
    ) {
        return { isLoading: true }
    }

    let allOrders = [
        data || [],
        data1 || [],
        data2 || []
    ];

    allOrders = allOrders.map(alO => ({
        total: alO.length,
        grouped: _.groupBy(alO, 'ndrData.ndrCpStatusCode'),
        groupedByCourier: _.groupBy(alO, 'courierId'),
    }))

    const oveallTotalCount = _.sumBy(allOrders, 'total')
    const { finalData, allCouriers } = getReasonCourierWise({
        allOrders,
        dates,
        oveallTotalCount,
        allCourses
    })

    const courierWiseData = getCourierWise({
        allOrders,
        dates,
        oveallTotalCount,
        allCourses
    })

    return { data: finalData, isLoading: false, allCouriers, courierWiseData }
}

function getReasonCourierWise({ allOrders, dates, oveallTotalCount, allCourses }) {

    const finalData = [];

    const allReasons = Object.keys(ndrReasons);
    let allCouriersHasData = [];

    for (const row of allReasons) {

        const rowSplit = {
            name: ndrReasons[row],
            totalCount: allOrders.map((_row) => {
                return _.get(_row, `grouped.${row}`, [])
            }).flat().length,
        };

        for (const index in dates) {

            const key = formatDate(new Date(dates[index].start), 'MMM_yy')
            if (allOrders[index] && allOrders[index]['total'] <= 0) continue;

            const groupedOrders = _.get(allOrders, `${index}.grouped.${row}`, [])
            const groupedByCourier = _.groupBy(groupedOrders, 'courierId')

            rowSplit[`${key}_totalCount`] = groupedOrders.length;
            rowSplit[`${key}_percentage`] = Number(((groupedOrders.length / allOrders[index]['total']) * 100).toFixed(2)) || 0


            // Ageing wise split
            for (const row of allCourses) {
                const courier = _.startCase(row.name)
                const courierData = groupedByCourier[row.id] || [];
                if (courierData.length === 0) continue;

                allCouriersHasData.push(courier)
                rowSplit[`${key}_${courier}_total`] = courierData.length;
                rowSplit[`${key}_${courier}_percentage`] = Number(((courierData.length / groupedOrders.length) * 100).toFixed(2)) || 0
            }
        }

        rowSplit.totalPercentage = Number(((rowSplit.totalCount / oveallTotalCount) * 100).toFixed(2)) || 0
        if (rowSplit.totalCount > 0) finalData.push(rowSplit)
    }

    const grandTotalRow = {
        name: 'Grand Total',
        totalCount: _.sumBy(finalData, 'totalCount'),
        totalPercentage: Math.round(_.sumBy(finalData, 'totalPercentage')),
    }

    for (const index in dates) {
        const key = formatDate(new Date(dates[index].start), 'MMM_yy')
        grandTotalRow[`${key}_totalCount`] = _.sumBy(finalData, function (row) {
            return row[`${key}_totalCount`]
        })
        grandTotalRow[`${key}_percentage`] = Math.round(_.sumBy(finalData, function (row) {
            return row[`${key}_percentage`]
        }))
        for (const row of allCourses) {
            const courier = _.startCase(row.name)
            const _t = _.sumBy(finalData, `${key}_${courier}_total`)
            grandTotalRow[`${key}_${courier}_total`] = _t
            grandTotalRow[`${key}_${courier}_percentage`] = Number(((_t / allOrders[index]['total']) * 100).toFixed(2)) || 0
        }
    }

    // Calculating Grand Total Row
    finalData.push(grandTotalRow);
    return { finalData, allCouriers: _.uniq(allCouriersHasData) };
}

function getCourierWise({ allOrders, dates, oveallTotalCount, allCourses }) {

    const finalData = [];

    for (const row of allCourses) {

        const rowSplit = {
            name: _.startCase(row.name),
            parent: row.parent,
            totalCount: allOrders.map((_row) => {
                return _.get(_row, `groupedByCourier.${row.id}`, [])
            }).flat().length,
        };

        for (const index in dates) {

            const key = formatDate(new Date(dates[index].start), 'MMM_yy')
            if (allOrders[index] && allOrders[index]['total'] <= 0) continue;

            const groupedOrders = _.get(allOrders, `${index}.groupedByCourier.${row.id}`, [])

            rowSplit[`${key}_totalCount`] = groupedOrders.length;
            rowSplit[`${key}_percentage`] = Number(((groupedOrders.length / allOrders[index]['total']) * 100).toFixed(2)) || 0
        }

        rowSplit.totalPercentage = Number(((rowSplit.totalCount / oveallTotalCount) * 100).toFixed(2)) || 0
        if (rowSplit.totalCount > 0) finalData.push(rowSplit)
    }

    const grandTotalRow = {
        name: 'Grand Total',
        totalCount: _.sumBy(finalData, 'totalCount'),
        totalPercentage: Math.round(_.sumBy(finalData, 'totalPercentage')),
    }

    for (const index in dates) {
        const key = formatDate(new Date(dates[index].start), 'MMM_yy')
        grandTotalRow[`${key}_totalCount`] = _.sumBy(finalData, function (row) {
            return row[`${key}_totalCount`]
        })
        grandTotalRow[`${key}_percentage`] = Math.round(_.sumBy(finalData, function (row) {
            return row[`${key}_percentage`]
        }))
    }

    // Calculating Grand Total Row
    finalData.push(grandTotalRow);
    return finalData;
}