import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../../common/constants";

export const userForgotPassword = createAsyncThunk(
    'auth/forgot_password',
    async ({ username }, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.FORGOT_PASSWORD}?username=${username}`;

            const { data } = await axios.get(
                url,
                config
            )
            return {
                data,
                username
            }
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);