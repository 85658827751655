import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, RHFTextField } from "../../../common/hook-form";
import Loader from "../../../common/Loader";
import { addressStringWithPrefix } from "../../../helpers/UtilityHelper";
import withLayout from "../../../hocs/Layout"
import { useLazyGetShipmentDetailQuery } from "../../../redux/commonRTK"
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { apiConstants, postGeneric } from "../../../common/constants";
import MUIModal from "../../../common/MUIModal"
import _ from 'lodash'

const excludes = "version,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate";

function ReverseOrder() {
    const { id } = useParams()
    const [order, setOrder] = useState(null)
    const [products, setProducts] = useState([])
    const [getShipmentDetail, { data, isLoading, isFetching }] = useLazyGetShipmentDetailQuery()
    const { enqueueSnackbar } = useSnackbar()
    const [isChangingAddress, setIsChangingAddress] = useState(false)
    const navigate = useNavigate()
    const [disableInputs, setDisableInputs] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        getShipmentDetail({
            excludes,
            andfilter: [{ field: "awbNumber", operator: "eq", value: id }],
            offset: 0,
            limit: 1
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (data?.data) {
            setOrder(data?.data[0])
            setProducts(data?.data[0]?.item_list?.map((item) => ({
                ...item,
                returnUnit: item.units
            })))
        }
    }, [data])

    const validationRulesDeliveryAddress = Yup.object().shape({
        from_address: Yup.string().required(),
        from_city: Yup.string().required(),
        from_country: Yup.string().required(),
        from_email: Yup.string().required(),
        from_name: Yup.string().required(),
        from_state: Yup.string().required(),
        from_pincode: Yup.string().required(),
        from_phone_number: Yup.string().required(),
        gstin: Yup.string().required(),
    })

    const methodsDeliveryAddress = useForm({
        resolver: yupResolver(validationRulesDeliveryAddress),
        defaultValues: {
            from_address: '',
            from_city: '',
            from_country: '',
            from_email: '',
            from_name: '',
            from_state: '',
            from_pincode: '',
            from_phone_number: '',
            gstin: '',
        }
    })

    const { handleSubmit: handleSubmitDeliveryAddress, setValue: setValueAddressChange } = methodsDeliveryAddress

    const validationRules = Yup.object().shape({
        shipment_detail: Yup.array(Yup.object().shape({
            item_length: Yup.number().required().moreThan(0),
            item_height: Yup.number().required().moreThan(0),
            item_breadth: Yup.number().required().moreThan(0),
            item_weight: Yup.number().required().moreThan(0),
        })),
        rvp_reason: Yup.string()
    })

    const methods = useForm({
        resolver: yupResolver(validationRules),
        defaultValues: {
            shipment_detail: [{
                item_length: 0,
                item_height: 0,
                item_breadth: 0,
                item_weight: 0
            }],
            rvp_reason: '',
        }
    })

    const { handleSubmit, setValue } = methods

    useEffect(() => {
        setValueAddressChange('from_name', order?.pickup_details?.from_name)
        setValueAddressChange('from_address', order?.pickup_details?.from_address)
        setValueAddressChange('from_city', order?.pickup_details?.from_city)
        setValueAddressChange('from_state', order?.pickup_details?.from_state)
        setValueAddressChange('from_country', order?.pickup_details?.from_country)
        setValueAddressChange('from_email', order?.pickup_details?.from_email)
        setValueAddressChange('from_pincode', order?.pickup_details?.from_pincode)
        setValueAddressChange('from_phone_number', order?.pickup_details?.from_phone_number)
        setValueAddressChange('gstin', order?.pickup_details?.gstin)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    useEffect(() => {
        setValue('shipment_detail[0].item_length', order?.shipment_detail[0].item_length)
        setValue('shipment_detail[0].item_height', order?.shipment_detail[0].item_height)
        setValue('shipment_detail[0].item_breadth', order?.shipment_detail[0].item_breadth)
        setValue('shipment_detail[0].item_weight', order?.shipment_detail[0].item_weight)

        setDisableInputs(_.sumBy(products, 'units') === _.sumBy(products, 'returnUnit'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, products])

    const columns = [
        { field: 'item_name', headerName: 'Name', width: 180, },
        { field: 'sku_id', headerName: 'SKU', width: 180 },
        { field: 'hsn', headerName: 'HSN', width: 180 },
        { field: 'selling_price', headerName: 'Price', width: 180 },
        { field: 'tax', headerName: 'Tax', width: 180 },
        { field: 'units', headerName: 'Units', width: 180 },
        {
            field: 'returnUnit',
            headerName: 'Return Units',
            width: 180,
            headerClassName: 'return-unit',
            editable: true,
            valueParser: (value, { row }) => {
                if (row.units < value) return row.units;
                return parseInt(value) || 0;
            },
            cellClassName: () => "return-unit"
        }
    ];

    const onCellEditStop = ({ row }, e) => {
        const returnUnit = +e.target.value
        setProducts((products) => {
            const filteredProducts = products.map((p) => {
                if (p.sku_id === row.sku_id) {
                    return { ...p, returnUnit }
                }

                return p
            })

            return filteredProducts;
        })
    }

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let request = _.pick({ ...order, ...data }, [
            'customer_detail',
            'invoice_value',
            'cod_amount',
            'client_order_id',
            'invoice_number',
            'shipping_charge',
            'giftwrap_charge',
            'transaction_charge',
            'payment_mode',
            'reference',
            'shipment_detail',
            "rvp_reason"
        ])

        request.payment_mode = 'PREPAID'
        request.is_reverse = true
        request.skipRule = false
        request.order_type = 'Reverse Shipment'
        // request.qc_type = 'doorstep'
        request.reference = request.reference + '_RVP'

        request.pickup_details = {
            from_address: order?.delivery_details?.to_address,
            from_city: order?.delivery_details?.to_city,
            from_country: order?.delivery_details?.to_country,
            from_email: order?.delivery_details?.to_email,
            from_name: order?.delivery_details?.to_name,
            from_state: order?.delivery_details?.to_state,
            from_pincode: order?.delivery_details?.to_pincode,
            from_phone_number: order?.delivery_details?.to_phone_number,
        }
        request.delivery_details = {
            to_address: order?.pickup_details?.from_address,
            to_city: order?.pickup_details?.from_city,
            to_country: order?.pickup_details?.from_country,
            to_email: order?.pickup_details?.from_email,
            to_name: order?.pickup_details?.from_name,
            to_state: order?.pickup_details?.from_state,
            to_pincode: order?.pickup_details?.from_pincode,
            to_phone_number: order?.pickup_details?.from_phone_number,
            gstin: order?.pickup_details?.gstin,
        }
        request.item_list = products.filter(p => (p.returnUnit && p.returnUnit > 0))

        postGeneric(apiConstants.CREATE_ORDER, request)
            .then((response) => {
                enqueueSnackbar("Reverse Shipment Successfully Created!", { variant: 'success' })
                navigate("/orders")
            }).catch((error) => {
                setIsSubmitting(false)
                enqueueSnackbar(error?.response?.data?.message || "Oops Something went wrong!", { variant: 'error' })
            })
    }

    const onSubmitDeliveryAddress = (data) => {
        setOrder(order => {
            const orderObj = { ...order }
            orderObj.pickup_details = { ...data }
            return orderObj
        })
        setIsChangingAddress(false)
    }

    // console.log(disableInputs)
    const returnProducts = products.filter(p => (p.returnUnit && p.returnUnit > 0))

    if (isLoading || isFetching) return <Loader />


    return (
        <div id="main-content" className="container-fluid">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader title="Customer Details" />
                        <CardContent sx={{ minHeight: 208 }}>
                            <Typography fontWeight={500}>{order?.customer_detail?.to_name}</Typography>
                            <Typography variant="body2">{addressStringWithPrefix('to', order?.customer_detail)}</Typography>
                            <Typography variant="body2">Email: {order?.customer_detail?.to_email}</Typography>
                            <Typography variant="body2">Phone: {order?.customer_detail?.to_phone_number || 'N/A'}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader title="Pickup Address" />
                        <CardContent sx={{ minHeight: 208 }}>
                            <Typography fontWeight={500}>{order?.delivery_details?.to_name}</Typography>
                            <Typography variant="body2">{addressStringWithPrefix('to', order?.delivery_details)}</Typography>
                            <Typography variant="body2">Email: {order?.delivery_details?.to_email}</Typography>
                            <Typography variant="body2">Phone: {order?.delivery_details?.to_phone_number || 'N/A'}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader title={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body">Delivery Address</Typography>
                                <Button size="small" onClick={() => setIsChangingAddress(true)}>Change Address</Button>
                            </Box>
                        } />
                        <CardContent sx={{ minHeight: 200 }}>
                            <Typography fontWeight={500}>{order?.pickup_details?.from_name}</Typography>
                            <Typography variant="body2">{addressStringWithPrefix('from', order?.pickup_details)}</Typography>
                            <Typography variant="body2">Email: {order?.pickup_details?.from_email}</Typography>
                            <Typography variant="body2">Phone: {order?.pickup_details?.from_phone_number}</Typography>
                            <Typography variant="body2">GSTIN: {order?.pickup_details?.gstin}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                    <DataGrid
                        sx={{ background: '#fff' }}
                        columns={columns}
                        getRowId={(row) => row.id}
                        rows={products.length > 0 ? products.map((val, index) => ({ ...val, id: index })) : []}
                        experimentalFeatures={{ newEditingApi: true }}
                        hideFooterPagination={true}
                        autoHeight
                        disableColumnMenu
                        disableColumnFilter
                        disableSelectionOnClick
                        onCellEditStop={onCellEditStop}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title="Shipments Details" />
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <RHFTextField disabled={disableInputs} InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }} name="shipment_detail[0].item_length" type="number" label="Length" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <RHFTextField disabled={disableInputs} InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }} name="shipment_detail[0].item_height" type="number" label="Height" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <RHFTextField disabled={disableInputs} InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }} name="shipment_detail[0].item_breadth" type="number" label="Breadth" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <RHFTextField disabled={disableInputs} InputProps={{
                                            endAdornment: <InputAdornment position="start">gm</InputAdornment>,
                                        }} name="shipment_detail[0].item_weight" type="number" label="Weight" />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <RHFTextField name="rvp_reason" label="Reason" multiline />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            disabled={returnProducts.length === 0}
                                            type="submit"
                                            variant="contained"
                                            size="large">
                                            Create Reverse Shipment
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <MUIModal
                open={isChangingAddress}
                handleClose={() => setIsChangingAddress(false)}
                title="Change Delivery Address"
            >
                <FormProvider
                    onSubmit={handleSubmitDeliveryAddress(onSubmitDeliveryAddress)}
                    methods={methodsDeliveryAddress}
                >
                    <Grid container spacing={2} sx={{ my: 1, alignItems: 'center' }}>
                        <Grid item xs={12}>
                            <RHFTextField name="from_name" label="Name" />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFTextField name="from_address" label="Address" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="from_city" label="City" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="from_state" label="State" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="from_country" label="Country" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="from_email" label="Email Address" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="from_pincode" label="Pincode" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="from_phone_number" label="Phone Number" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="gstin" label="GSTIN" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button type="submit" size="large" variant="contained">Update Address</Button>
                        </Grid>
                    </Grid>
                </FormProvider>
            </MUIModal>
        </div>
    )
}

export default withLayout(ReverseOrder)