import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {  TextField } from '@mui/material';

RHFTextArea.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextArea({ name, size = 'medium', ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={(event) => {
            if (other.type === 'number') {
              field.onChange(+event.target.value)
            } else {
              field.onChange(event.target.value)
            }
          }}
          sx={{ fontSize: 14 }}
          fullWidth
          size={size}
          rows={other.rows || 2}
          variant="outlined"
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          multiline
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          helperText={error?.message}
          {...other}
          label={(
            <span>
              {other.label}
              {other.mandatory && (
                <span style={{ color: 'red' }}> *</span>
              )}

            </span>
          )}
        />
      )}
    />
  );
}
