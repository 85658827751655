import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Container,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useResponsive from "./useResponsive";

import { Controller, useForm, useFormContext } from "react-hook-form";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "../../common/hook-form";
import { useSnackbar } from "notistack";
import { apiConstants, postGeneric } from "../../common/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { Box } from "@mui/system";
import MUIModal from "../../common/MUIModal";
// import { makeStyles } from '@mui/styles';

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    background: "#F9FAFB",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "start",
  padding: theme.spacing(12, 0),
}));

const useStyles = styled((theme) => ({
  otpInput: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
}));

const InputStyle = {
  width: "50px",
  height: "50px",
  border: "none",
  borderBottom: "1px solid #ccc",
  // background: "#F9FAFB"
};

// ----------------------------------------------------------------------

export default function Otp() {
  const classes = useStyles();
  const mdUp = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open,setOpen] = useState(false);
  const [mobile,setMobile] = useState(state.contact_no)

  //   const { control } = useFormContext();

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        otpValidation: Yup.string().required("OTP is required"),
      })
    ),
    defaultValues: {
      otpValidation: "",
    },
  });

  const { handleSubmit } = methods;

  const onVerify = (data) => {
    setIsLoading(true);
    const postData = {
      username: state.email,
      email: state.email,
      otp: {
        otpValue: methods.getValues().otpValidation,
      },
    };

    postGeneric(apiConstants.VERIFY_OTP, postData)
      .then((response) => {
        setIsLoading(false);
        enqueueSnackbar(
          "Signup Successful! An email has been sent to the email id with password",
          { variant: "success" }
        );
        navigate("/create-new-password", { state: state.email });
      })
      .catch((error) => {
        setError(true);
        setIsLoading(false);
        enqueueSnackbar(
          error?.response?.data?.message || "Oops Something went wrong!",
          { variant: "error" }
        );
      });
  };
  const handleOpen =()=>{
    setOpen(true)
  }

  const resendCode = (number) => {
    const postData = {
      username: state.email,
      email: state.email,
      mobile: `+91${number}`,
    };
    postGeneric(apiConstants.GENERATE_OTP, postData)
      .then((a) => {
        enqueueSnackbar("Request sent", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || "Oops Something went wrong!",
          { variant: "error" }
        );
      });
      setOpen(false)
  };

  return (
    <RootStyle>
      <HeaderStyle>
        <img
          src="/img/prozo-logo2.svg"
          className="logo"
          height="60"
          alt="logo"
        />
      </HeaderStyle>

      {mdUp && (
        <SectionStyle>
          <Typography
            textTransform="uppercase"
            fontWeight="bold"
            variant="h5"
            sx={{ px: 5 }}
          >
            Prozo Logistics Network (PLN)
          </Typography>
          <Typography variant="body2" sx={{ px: 5, mt: 1 }}>
            One place for all your shipping needs; FTL, PTL & D2C Freight
            including hyperlocal freight!
          </Typography>
        </SectionStyle>
      )}

      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h4" mb={2}>
            Enter Verification Code
          </Typography>
          <Typography variant="body1" mb={3}>
            We have sent you the verfication code to your mobile number. "OTP is valid for 10 minutes.
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onVerify)}>
            <Box mb={3}>
              <Controller
                name="otpValidation"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Box>
                      <OtpInput
                        value={field.value}
                        numInputs={6}
                        separator={
                          <span className="mx-3" style={{ color: "#ccc" }}>
                            -
                          </span>
                        }
                        inputStyle={InputStyle}
                        {...field}
                        error={!!error}
                      />
                      {error ? (
                        <FormHelperText error>Invalid OTP</FormHelperText>
                      ) : null}
                    </Box>
                  );
                }}
              />
            </Box>
            <Box mb={3}>
              <Button variant="text" onClick={()=> handleOpen()}>
                Send the code again
              </Button>
              <MUIModal open={open}
                title="Update Mobile Number for OTP"
                handleClose={() => setOpen()}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography variant="h6" sx={{paddingTop:1}}> Mobile Number :</Typography>
                     
                      </Grid>
                      <Grid item xs={8}>
                      <TextField fullWidth value={mobile} onChange={(e)=>{
                        setMobile(e.target.value)
                      }}/>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button variant="contained" onClick={() => resendCode(mobile)}>Send otp</Button>
                      </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </MUIModal>
            </Box>

            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={() => onVerify()}
              loading={isLoading}
            >
              Verify
            </LoadingButton>
          </FormProvider>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
