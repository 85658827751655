import * as React from "react"
import {suffix, formatDate, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";

export default function AttemptedWiseTAT({ data, selectedDates, isLoading }) {
    const __tableRef = React.useRef(null)

    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = 'Delivery Attempt IN/OUT TAT';

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={(selectedDates.length * 5) + 1}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName="Delivery Attempt" fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={3} style={{ width: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <React.Fragment key={i}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={3}>{formatDate(date, 'MMM yy')}</td>
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_date, i) => (
                            <React.Fragment key={i}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={2}>TAT</td>
                                <td align="right" rowSpan={2} style={{
                                    backgroundColor: monthColors[i]
                                }}>Total</td>
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={i}>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>IN</td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>OUT</td>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={i}>
                                {/* <td style={{ width: 200 }}>{row.name}</td> */}
                                <td style={{ width: 200 }}>{row.parent ? row.parent:row.name}</td>
                                {selectedDates.map((_d, j) => (
                                    <React.Fragment key={j}>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[j]
                                        }}>
                                            {suffix(row[`${formatDate(_d, 'MMM_yy')}_inTatPercentage`], { postfix: '%' })}
                                        </td>
                                        <td style={{
                                            backgroundColor: monthColors[j]
                                        }} align="right">
                                            {suffix(row[`${formatDate(_d, 'MMM_yy')}_outTatPercentage`], { postfix: '%' })}
                                        </td>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[j]
                                        }}>
                                            {suffix(Math.round(row[`${formatDate(_d, 'MMM_yy')}_inTatPercentage`] + row[`${formatDate(_d, 'MMM_yy')}_outTatPercentage`]), { postfix: '%' })}
                                        </td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}