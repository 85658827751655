import React from "react"
import useStatus from "./useStatus"
import { Box, Button, Card, CardContent, Chip, Grid } from "@mui/material"
import StatCard from "../../StatCard"
import { formatDate, formatNumber, statusNotifications } from "../../../helpers/UtilityHelper"
import DashboardSkeleton from "../DashboardSkeleton"
import { StackedBarChart } from "../../charts/ColumnChart"
import ProzoDataGrid from "../../../common/ProzoDataGrid"
import { FormProvider, RHFDateField, RHFSelectField, RHFTextField } from "../../../common/hook-form"
import _ from "lodash"
import { useForm } from "react-hook-form"
import WhatsAppChatPopup from "./Conversation"

let chartColumns = [
    {
        label: 'Order Placed',
        dataKey: 'proship_order_placed'
    },
    {
        label: 'In Transit',
        dataKey: 'proship_order_in_transit'
    },
    {
        label: 'Out For Delivery',
        dataKey: 'proship_out_for_delivery'
    },
    {
        label: 'Picked Up',
        dataKey: 'proship_order_picked_up'
    },
    {
        label: 'Not Delivered',
        dataKey: 'proship_order_not_delivered'
    },
    {
        label: 'Delivered',
        dataKey: 'proship_order_delivered'
    },
    {
        label: 'Delivery Delay',
        dataKey: 'proship_delivery_delay'
    },
]

export default function Status() {
    const { isLoading, data, totalSent, totalRead, totalFailed, groupedByTemplateDate } = useStatus()
    const [conversation, setConversation] = React.useState(null)

    const rows = [{
        title: 'Total Messages Sent',
        number: formatNumber(totalSent),
        icon: '/img/whatsapp/sent.png'
    }, {
        title: 'Read %',
        number: formatNumber((totalRead * 100 / (totalSent - totalFailed)).toFixed(0)),
        icon: '/img/whatsapp/read.png'
    }, {
        title: 'Total Messages Undelivered',
        number: formatNumber(totalFailed),
        icon: '/img/order/pickup-failed.svg'
    }]

    if (isLoading) return <DashboardSkeleton isDashboard />

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                {rows.map((row) => (
                    <Grid item xs={12} md={4} key={row.title}>
                        <StatCard item={row} />
                    </Grid>
                ))}
                {data && data.length > 0 && (
                    <Grid item xs={12}>
                        <Card elevation={1}>
                            <CardContent>
                                <StackedBarChart width='100%' height={450} chartOptions={{
                                    xaxis: 'name',
                                    stackId: 'status',
                                    labelY: 'Messages Count',
                                    xLabel: 'Date',
                                    data: chartColumns
                                }} data={groupedByTemplateDate} />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {data && data.length > 0 && (
                    <Grid item xs={12}>
                        <TabularData data={data} setConversation={setConversation} />
                    </Grid>
                )}
            </Grid>
            {conversation &&
                <WhatsAppChatPopup conversation={conversation} setConversation={setConversation} />
            }
        </React.Fragment>
    )
}

function createMessageText(payload, template, template_name) {
    let k = ''
    if (payload.length > 0) {
        switch (template_name) {
            case 'proship_order_delivered':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{brand_name}", payload[1]?.text)
                    .replace("{order_id}", payload[2]?.text)
                    .replace("{tracking_url}", payload[3]?.text + "/" + payload[4]?.text)
                break;
            case 'proship_out_for_delivery':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{brand_name}", payload[1]?.text)
                    .replace("{order_id}", payload[2]?.text)
                    .replace("{tracking_url}", payload[3]?.text + "/" + payload[4]?.text)
                break;
            case 'proship_order_in_transit':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{brand_name}", payload[1]?.text)
                    .replace("{order_id}", payload[2]?.text)
                    .replace("{courier_name}", payload[3]?.text)
                    .replace("{ed_date}", payload[4]?.text)
                    .replace("{tracking_url}", payload[5]?.text + "/" + payload[6]?.text)
                break;
            case 'proship_order_picked_up':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{brand_name}", payload[1]?.text)
                    .replace("{order_id}", payload[2]?.text)
                    .replace("{courier_name}", payload[3]?.text)
                    .replace("{tracking_url}", payload[4]?.text + "/" + payload[5]?.text)
                break;
            case 'proship_order_placed':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{order_id}", payload[1]?.text)
                    .replace("{product_name}", payload[2]?.text)
                    .replace("{brand_name}", payload[3]?.text)
                    .replace("{tracking_url}", payload[4]?.text + "/" + payload[5]?.text)
                break;
            case 'proship_order_not_delivered':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{brand_name}", payload[1]?.text)
                    .replace("{order_id}", payload[2]?.text)
                    .replace("{tracking_url}", payload[3]?.text + "/" + payload[4]?.text)
                break;
            case 'proship_delivery_delay':
                k = template?.replace("{customer_name}", payload[0]?.text)
                    .replace("{brand_name}", payload[1]?.text)
                    .replace("{order_id}", payload[2]?.text)
                    .replace("{tracking_url}", payload[3]?.text + "/" + payload[4]?.text)
                break;
            default:
                k = '';
        }
        return k;
    } else {
        return '';
    }
}

function TabularData({ data, setConversation }) {
    let finalData = [...data]
    const notifications = statusNotifications;
    // map on finalData and create a message text
    finalData = finalData.map(row => {
        let k = '';
        // Switch case to create template name
        switch (row.template_name) {
            case 'proship_order_placed':
                k = 'PLACED';
                break;
            case 'proship_order_in_transit':
                k = 'LIVE';
                break;
            case 'proship_out_for_delivery':
                k = 'OUT_FOR_DELIVERY';
                break;
            case 'proship_order_picked_up':
                k = 'PICKED';
                break;
            case 'proship_order_not_delivered':
                k = 'Not Delivered';
                break;
            case 'proship_order_delivered':
                k = 'DELIVERED';
                break;
            case 'proship_delivery_delay':
                k = 'DELAY';
                break;
            case 'proship_delivery_failed':
                k = 'NDR';
                break;
            default:
                k = 'UNKNOWN'; // or any default value you want
        }
        const defaultText = _.find(notifications, { val: k })?.msg
        let conversation = {};
        conversation.action = 'message';
        conversation.timestamp = row.created_date;
        // construct the message body
        const data = Array.isArray(row?.payload_body?.template?.components)
            ? row.payload_body.template.components[0]?.parameters
            : [];
        conversation.body = createMessageText(data, defaultText, row.template_name);
        let conversationArray = [];
        conversationArray.push(conversation);
        const newRow = { ...row, conversation_history: conversationArray };
        // If you need to find a notification or something similar, do it here and assign appropriately.
        // row.message = template ? template.label : 'Default Message'; // Example
        return newRow;
    });

    const [page, setPage] = React.useState(0)
    const [sizePerPage, setSizePerPage] = React.useState(50)

    const method = useForm()
    const { watch } = method

    const templateName = watch('template')
    const mobileNumber = watch('mobile')
    const awbNumber = watch('awbNumber')
    const createdDate = watch('createdDate')

    if (templateName) {
        finalData = finalData.filter(row => row.template_name === templateName)
    }

    if (awbNumber) {
        finalData = finalData.filter(row => row?.meta_data?.awbNumber === awbNumber)
    }

    if (mobileNumber) {
        finalData = finalData.filter(row => {
            const contactNumber = row.payload_body?.to.replace('+91', '') || null
            return contactNumber ? contactNumber === mobileNumber : false
        })
    }

    if (createdDate) {
        const createdDateOnly = formatDate(createdDate, 'dd/MMM/yyyy')
        finalData = finalData.filter(row => {
            const cDate = formatDate(row.created_date, 'dd/MMM/yyyy')
            return cDate === createdDateOnly
        })
    }

    const columnsItemModal = [
        {
            field: "identifier",
            headerName: "Recipient Mobile",
            flex: 1,
            renderCell: ({ row }) => {
                const contactNumber = row?.payload_body?.to || ""
                const lastDigits = contactNumber.slice(-3)
                return `*******${lastDigits}`
            }
        },
        {
            field: "awb",
            headerName: "AWB Number",
            flex: 1,
            renderCell: ({ row }) => {
                let awb = '';
                // const payload_body = row?.payload_body?.template.components[0]?.parameters;
                const payload_body = Array.isArray(row?.payload_body?.template?.components)
                    ? row.payload_body.template.components[0]?.parameters
                    : [];
                if (payload_body.length > 0) {
                    awb = payload_body[payload_body.length - 1].text;
                }
                return awb;
            }
        },
        {
            field: "template_name",
            headerName: "Shipment Status",
            filterable: true,
            flex: 1,
            renderCell: ({ row }) => {
                const template = _.find(chartColumns, { dataKey: row.template_name })
                return template ? template.label : ''
            }
        },
        {
            field: "status",
            headerName: "Message Status",
            flex: 1,
            renderCell: ({ row }) => {
                if (row.status)
                    return <Chip label={row.current_status.toUpperCase()} sx={{ fontWeight: 500, fontSize: 11 }} size="small" />

                return "-"
            }
        },
        {
            field: "created_at",
            headerName: "Date",
            flex: 1,
            renderCell: ({ row }) => {
                return formatDate(row.created_date)
            }
        },
        {
            field: "conversationsSummary",
            headerName: "Conversations Summary",
            type: 'number',
            flex: 1,
            renderCell: ({ row }) => {
                // return <ConversationAction conversations={row?.conversations || []} setConversation={setConversation} />
                return <Button onClick={() => { setConversation(row) }}>View Conversation</Button>
            }
        },
    ];

    return (
        <React.Fragment>
            <Box sx={{ background: '#fff', p: 1 }}>
                <FormProvider methods={method}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <RHFSelectField size='medium' width="100%" label="Shipment Status" name="template" options={[
                                { label: 'All', value: undefined },
                                ...chartColumns?.map((row) => ({ label: row.label, value: row.dataKey }))
                            ]} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFTextField label="AWB Number" name="awbNumber" size='medium' />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFTextField label="Mobile Number" name="mobile" size='medium' />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFDateField label="Created Date" name="createdDate" size='medium' />
                        </Grid>
                    </Grid>
                </FormProvider>
            </Box>
            <ProzoDataGrid
                columns={columnsItemModal}
                rows={[...finalData].splice(page * sizePerPage, sizePerPage)}
                page={page}
                setPage={setPage}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                hideFooterPagination={false}
                rowCount={finalData.length}
                getRowId={(row) => row.identifier + row._id.timestamp}
                autoHeight={true}
                rowHeight={70}
                pagination={true}
                filterMode={"server"}
            />
        </React.Fragment>
    )
}