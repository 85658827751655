import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { formatNumber, ndrReasons, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import MaterialTable from "../../../../common/MaterialTable";

export default function ReasonAnalysis({ data }) {

    const reasonKeys = Object.keys(ndrReasons)
    const { rows, allColumns } = transformChartDataToTable(data, [{ key: 'totalRtoCases', 'label': 'Total RTO', order: 1 }, ...reasonKeys.map(r => ({ key: `${r}_value`, label: ndrReasons[r] }))])


    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return formatNumber(row[z])
            },
            label: z
        })))
    ]
    return (
        <Card elevation={1}>
            <CardHeader title="RTO Reason Analysis" />
            <CardContent>
                <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />
            </CardContent>
        </Card>
    )
}