import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';

RHFDateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  disablePast: PropTypes.bool, // Optional prop to disable past dates and times
};

RHFDateTimeField.defaultProps = {
  disablePast: false, // By default, do not disable past dates and times
};

export default function RHFDateTimeField({ name, disablePast, mode, InputProps, disabled, ...other }) {
  const { control } = useFormContext();
  const minDateTime = disablePast ? new Date() : undefined; // Disable past dates and times based on the prop

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <LocalizationProvider dateAdapter={mode === 'time' ? AdapterDayjs : AdapterDateFns}>
            {mode === 'time' ? (
              <TimePicker
                {...field}
                ampm={false}  // Use 24-hour format
                views={['hours', 'minutes']}
                inputFormat="HH:mm"
                disabled={disabled}
                minutesStep={10}
                value={field.value || null}
                onChange={(newValue) => field.onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    variant="outlined"
                    {...other}
                  />
                )}
              />

            ) : (
              <DateTimePicker
                {...field}
                inputFormat="dd/MM/yyyy HH:mm"
                value={field.value || null}
                minDateTime={minDateTime}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    variant="outlined"
                    {...other}
                  />
                )}
              />
            )}
          </LocalizationProvider>
        );
      }}
    />
  );
}

