import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, Select, MenuItem, FormHelperText, InputLabel, Checkbox } from '@mui/material';

RHFMultipleSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array
};

export default function RHFMultipleSelectField({ name, options, variant, label, width = 150, ...other }) {
  const { control } = useFormContext();
  const uniqueId = (new Date()).getTime();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant={variant || "filled"} error={!!error} sx={{ minWidth: width, maxWidth: width }}>
          <InputLabel id={`demo-multiple-${uniqueId}`} sx={{ fontSize: 14 }}>{label}</InputLabel>
          <Select
            {...field}
            multiple
            labelId={`demo-multiple-${uniqueId}`}
            // value={typeof field?.value === 'number' && field?.value === null ? "ALL" : [...field.value]}
            value={Array.isArray(field.value) ? field.value : []}
            fullWidth={true}
            size='small'
            {...other}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {options.map((e, i) => <MenuItem sx={{ fontSize: 14 }} key={i} value={e.value}>
              <Checkbox checked={field?.value?.includes(e.value)} />
              {e.label}
            </MenuItem>)}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
