import * as React from "react"
import { suffix, formatDate, formatNumber, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import { useRef } from "react";
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";

export default function RTOReasonWise({ data, selectedDates, isLoading, allCouriers }) {
    const __tableRef = useRef(null)

    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = 'RTO Reason Wise';

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={selectedDates.length*(selectedDates.length*2)+1}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName={heading} fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={3} style={{ minWidth: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <React.Fragment key={date}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={allCouriers.length * 2}>{formatDate(date, 'MMM yy')}</td>
                                <td rowSpan={2} colSpan={2} align="center">Total</td>
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_date, i) => {
                            return allCouriers.map((_ageing, _i) => (
                                <td key={_ageing} align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={2}>{_ageing}</td>
                            ))
                        })}
                    </tr>

                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={_d}>
                                {allCouriers.map((_ageing, _i) => (
                                    <React.Fragment key={_ageing}>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>Count</td>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>%</td>
                                    </React.Fragment>
                                ))}
                                <td align="right">Count</td>
                                <td align="right">%</td>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={row.name}>
                                <td style={{ minWidth: 200 }}>{row.name}</td>
                                {selectedDates.map((_d, j) => (
                                    <React.Fragment key={_d}>
                                        {allCouriers.map((_ageing, _j) => (
                                            <React.Fragment key={_j}>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>
                                                    {formatNumber(row[`${formatDate(_d, 'MMM_yy')}_${_ageing}_total`])}
                                                </td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>
                                                    {suffix(row[`${formatDate(_d, 'MMM_yy')}_${_ageing}_percentage`], { postfix: '%' })}
                                                </td>
                                            </React.Fragment>
                                        ))}

                                        <td align="right">
                                            {formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCount`])}
                                        </td>
                                        <td align="right">
                                            {suffix(row[`${formatDate(_d, 'MMM_yy')}_percentage`], { postfix: '%' })}
                                        </td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}