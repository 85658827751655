import { createSlice } from '@reduxjs/toolkit';
import { getTrackingDetails } from '../../actions/trackingDetail/trackingDetailAction';
const initialState = {
    loading: false,
    trackingDetailInfo: {
        Status: null,
        waybillDetails: [],
    },
    error: null,
    success: false,
}


export const trackingDetailSlice = createSlice({
    name: 'TrackDetail',
    initialState,
    reducers: {
        resetTrackingDetail: (state) => {
            state.loading = false
            state.trackingDetailInfo = {
                Status: null,
                waybillDetails: [],
            }
            state.error = null
        },
    },
    extraReducers: {
        
        [getTrackingDetails.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null
            }
        },
        [getTrackingDetails.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                trackingDetailInfo: {
                    Status: (payload.Status) ? payload.Status : null,
                    waybillDetails: (payload && payload.waybillDetails) ? [...state.trackingDetailInfo.waybillDetails,...payload.waybillDetails] : []
                }
            }
        },
        [getTrackingDetails.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetTrackingDetail } = trackingDetailSlice.actions

export default trackingDetailSlice.reducer