import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

RHFPassword.propTypes = {
  name: PropTypes.string,
};

export default function RHFPassword({ name, label, sx, ...other }) {
  const { control } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl sx={sx} fullWidth variant="filled">
            <InputLabel htmlFor="outlined--adornment-password">
              {label}
            </InputLabel>
            <FilledInput
              {...field}
              id="filled-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
              value={field.value}
              error={!!error}
            //   helperText={error?.message}
              {...other}
            />
            <Typography variant="caption" color="error">
              {error?.message}
            </Typography>
          </FormControl>
        );
      }}
    />
  );
}
