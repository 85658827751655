import * as React from "react"
import { suffix, formatDate, grpupedOrderStatus, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import _ from 'lodash'
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";

export default function MonthCourierWiseStatus({ data, selectedDates, isLoading }) {
    const __tableRef = React.useRef(null);

    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = "Courier Wise Shipment Status";

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={(selectedDates.length + 1) * grpupedOrderStatus.length}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName={heading} fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={3} style={{ minWidth: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <React.Fragment key={i}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={grpupedOrderStatus.length + 1}>{formatDate(date, 'MMM yy')}</td>
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_date, i) => (
                            <React.Fragment key={i}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={grpupedOrderStatus.length}>Status</td>
                                <td align="right" rowSpan={2} style={{
                                    backgroundColor: monthColors[i]
                                }}>Total</td>
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={i}>
                                {grpupedOrderStatus.map((status, _i) => (
                                    <td key={status} align="right" style={{
                                        backgroundColor: monthColors[i]
                                    }}>{_.startCase(status)}</td>
                                ))}
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={i}>
                                {/* <td style={{ minWidth: 200 }}>{row.name}</td> */}
                                <td style={{ width: 200 }}>{row.parent ? row.parent:row.name}</td>
                                {selectedDates.map((_d, j) => (
                                    <React.Fragment key={j}>
                                        {grpupedOrderStatus.map((_status, _j) => (
                                            <td align="right" key={_status} style={{
                                                backgroundColor: monthColors[j]
                                            }}>
                                                {suffix(row[`${formatDate(_d, 'MMM_yy')}_${_status}_percentage`], { postfix: '%' })}
                                            </td>
                                        ))}
                                        <td align="right" style={{
                                            backgroundColor: monthColors[j]
                                        }}>
                                            100%
                                        </td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}