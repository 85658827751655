import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userLogin } from "../../redux/actions/userAction";
import { useEffect } from "react";
import { useNavigate,  useLocation } from "react-router-dom";
import "./login.css";
import "react-toastify/dist/ReactToastify.css";
import { reset } from "../../redux/reducers/auth/forgotPasswordSlice";
import { resetFormrp } from "../../redux/reducers/auth/resetPasswordSlice";
import { useSnackbar } from "notistack";
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import RHFPassword from "../../common/hook-form/RHFPassword";
import { apiConstants, postGeneric } from "../../common/constants";

export default function CreateNewPassword() {
  const dispatch = useDispatch();
  const {  userInfo, error } = useSelector((state) => state.user);
  const { rpInfo } = useSelector((state) => state.resetPasswordSlice);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  // const [passwordType, togglePasswordType] = useState("password");

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        oldPassword: Yup.string().required("Password is required"),
        newPassword: Yup.string().required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
          .required("Password is required"),
      })
    ),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { handleSubmit } = methods;

  // redirect authenticated user to profile screen
  useEffect(() => {
    dispatch(reset());
    if (Object.keys(rpInfo).length !== 0) {
      enqueueSnackbar("Your password has been updated successfully.", {
        variant: "success",
      });
      dispatch(resetFormrp());
    }
    if (Object.keys(userInfo).length !== 0) {
      navigate("/");
    }

    // eslint-disable-next-line
  }, [navigate, userInfo]);

  // const handlePasswordType = () => {
  //   if (passwordType === "password") {
  //     togglePasswordType("text");
  //   } else {
  //     togglePasswordType("password");
  //   }
  // };

  const onSubmit = (data) => {
    // dispatch(userLogin(data))
    // console.log("data", data, state);

    const postData = {
      username: state,
      current_password: data.oldPassword,
      new_password: data.newPassword,
    };

    postGeneric(apiConstants.RESET_PASSWORD, postData)
      .then((response) => {
        // console.log("password response", response);
        if (response.status === 200) {
          const logindata = {
            username: state,
            password: data.newPassword,
          };
          enqueueSnackbar("Your password has been updated successfully.", {
            variant: "success",
          });

          dispatch(userLogin(logindata));
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || "Oops Something went wrong!",
          { variant: "error" }
        );
      });
  };

  if (error && error.error) {
    enqueueSnackbar(error.error, { variant: "error" });
  }

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  return (
    <div id="login-wrapper" className="container-fluid vh-100">
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6}>
          <img
            src="/img/prozo-logo2.svg"
            className="logo"
            height="60"
            alt="logo"
          />
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4">
              Shipping made easy with Proship
            </Typography>
          </Box>
          <List sx={{ mt: 2 }}>
            <ListItem sx={{ py: 0 }}>
              <ListItemIcon sx={{ mr: 0 }}>
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Decrease your return to origin (RTO) for unsuccessful deliveries." />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemIcon>
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Manage non-delivery reports (NDR) seamlessly." />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemIcon>
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Choose the right courier partner for each shipment taking into account all the necessary data points." />
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={12} lg={6}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card className="card card-login shadow-none border-0 p-0 bg-transparent">
              <Box display="flex">
                <Typography variant="h4">Create New Password</Typography>
                <img
                  src="img/arrow-forward.svg"
                  className="ms-2"
                  width="11"
                  alt=""
                />
              </Box>
              <CardContent className="card-body p-0">
                <RHFPassword
                  name="oldPassword"
                  label="Enter old password"
                  sx={{ mt: 3, mb: 2 }}
                />
                <RHFPassword
                  name="newPassword"
                  label="Enter new password"
                  sx={{ mt: 2, mb: 2 }}
                />
                <RHFPassword
                  name="confirmPassword"
                  label="Confirm new password"
                  sx={{ mt: 2, mb: 3 }}
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </CardContent>
            </Card>
          </FormProvider>
        </Grid>
      </Grid>
    </div>
  );
}
