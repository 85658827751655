import React, { useEffect, useState } from "react";
import withLayout from "../../../hocs/Layout";
import {
  apiConstants,
  getDownload,
  postMultipart,
} from "../../../common/constants";
import "./create-order.css";
import {
  FormProvider,
  RHFFileField,
  RHFSelectField,
} from "../../../common/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Tooltip,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MUIModal from "../../../common/MUIModal";
import InfoIcon from '@mui/icons-material/Info';
import PageTitle from "../../../common/PageTitle";
import { FileDownloadOutlined } from "@mui/icons-material";
import BulkOrderResults from "./BulkOrderResult";
import { useSnackbar } from "notistack";

const BulkOrderCreate = (props) => {
  const shipmentType = props.shipmentType;
  const {enqueueSnackbar} = useSnackbar();
  // const { data: allCouriers } = useGetCouriersQuery();
  const [bulkOrderIdentifier, setBulkOrderIdentifier] = useState()
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [sampleName, setSampleName] = useState()
  // const [result, setResult] = useState()

  const [reload, setReload] = useState(0)


  const methodsBulkUpload = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        orderFile: Yup.mixed().required("Please Select a File"),
      })
    ),
    defaultValues: {
      orderFile: ''
    }
  });

  const { handleSubmit: handleSubmitBulkUpload, reset: resetForm } = methodsBulkUpload;

  const list = ["Forward", "Reverse"]
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const createB2COrder = async (data) => {
    let form = new FormData();
    form.append("file", data.orderFile[0])
    form.append("bulkOrderIdentifier", bulkOrderIdentifier)
    await postMultipart(apiConstants.BULK_CREATE_ORDER_v2, form).then((res) => {
      setIsUploadingFile(false)
      if (res.status === 200) {
        enqueueSnackbar("Successfully Uploaded!", { type: "success" });
        setIsUploadingFile(false)
        // setResult(res?.data?.responseObj)
      }else{
        enqueueSnackbar(`${res.data}`, { variant: 'error' })
      }
    })
  }

  const createB2BOrder = async (data) => {
    let form = new FormData();
    form.append("file", data.orderFile[0])
    await postMultipart(apiConstants.BULK_CREATE_ORDER_B2B, form).then((res) => {
      setIsUploadingFile(false)
      if (res.status === 200) {
        enqueueSnackbar("Successfully Uploaded!", { type: "success" });
        setIsUploadingFile(false)
      } else {
        enqueueSnackbar(`${res.data}`, { variant: 'error' })
      }
    })
  }


  const onSubmitBulkUpload = async (data) => {
    setIsUploadingFile(true);
    try {
      if (shipmentType === 'B2B') {
        createB2BOrder(data)
      } else {
        createB2COrder(data)
      }
      setReload(reload + 1);
      setBulkOrderIdentifier("")
      setIsUploadingFile(false)
      resetForm()
      // navigate("/orders");
    } catch (err) {
      const message = err?.response?.data?.message || err.message;
      enqueueSnackbar(message || "Error creating order", { type: "error" });
      setIsUploadingFile(false)
    }
    resetForm({
      orderFile: ''
    })
    setBulkOrderIdentifier('')

    setIsUploadingFile(false);
  };

  useEffect(() => {
    if (bulkOrderIdentifier === "Forward") {
      setSampleName("FORWARD_BULK_ORDER")
    }
    if (bulkOrderIdentifier === "Reverse") {
      setSampleName("REVERSE_BULK_ORDER")
    }
    if (shipmentType === "B2B") {
      setSampleName("B2B_BULK_ORDER_V1")
    }
  }, [bulkOrderIdentifier, shipmentType])

  const handleBulkSample = () => {
    getDownload(
      `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=${sampleName}`
    )
      .then((response) => {
        const binaryData = [];
        binaryData.push(response.data);
        const href = URL.createObjectURL(
          new Blob(binaryData, { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "bulk-upload-sample.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.error(err);
      });
  };


  return (
    <>
      <div id="main-content" className="container-fluid">
        <PageTitle>Create Bulk Shipments</PageTitle>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <FormProvider
                  methods={methodsBulkUpload}
                  onSubmit={handleSubmitBulkUpload(onSubmitBulkUpload)}
                >
                  <Grid container spacing={1}>
                    {shipmentType === 'B2C' && (
                      <Grid item xs={12} sm={6}>
                        <RHFSelectField name='bulkOrderIdentifier' label='Select Order Type*' options={list.map((val) => ({
                          value: val,
                          label: val
                        }))}
                          value={bulkOrderIdentifier} width='50%' onChange={(e) => {
                            setBulkOrderIdentifier(e.target.value)
                          }} required />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <RHFFileField name="orderFile" accept={["csv"]} required={true} />
                    </Grid>
                    <Grid item xs={12} sm={1.5}>
                      <LoadingButton
                        startIcon={<FileUploadIcon />}
                        type="submit"
                        loading={isUploadingFile}
                        variant="contained"
                        color="warning"
                        size="small"
                      >
                        Upload
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'right' }}>
                      {sampleName ?
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ ml: '-60px' }}
                          startIcon={<FileDownloadOutlined />}
                          onClick={() => handleBulkSample()}
                        >
                          Sample Download
                        </Button> : <Typography color='error' component='span' sx={{ padding: 3 }} variant='body2'>Please Select Order type to download sample</Typography>}
                      <Tooltip title='Details of Sample Columns'>
                        <IconButton size='small' color='info' onClick={handleOpen}> <InfoIcon /></IconButton>
                      </Tooltip>
                      <MUIModal
                        open={open}
                        title="Details of Sample Columns"
                        handleClose={() => setOpen(false)}>
                        <Card>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant='h6'>SkipRule: <Typography component='span'>Possible values - true / false ; True when we process order via specific courier ; False when we want to follow the courier allocation.</Typography></Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant='h6'>Courier Name: <Typography component='span'>Should be blank if "SkipRule" is "false".</Typography></Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant='h6'>Is Reverse: <Typography component='span'>Should be "false" if its a Forward Order..</Typography></Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>

                      </MUIModal>
                    </Grid>
                  </Grid>
                </FormProvider>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <BulkOrderResults shipmentType={shipmentType} reload={reload} />
          </Grid>
        </Grid>
      </div>

    </>

  );
};
export default withLayout(BulkOrderCreate);
