import React from 'react';
import withLayout from '../../../hocs/Layout';
import PageTitle from '../../../common/PageTitle';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import PendingOrders from './PendingOrders';
import ApprovedOrders from './ApprovedOrders';
import RejectedOrders from './Rejected';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelIcon from '@mui/icons-material/Cancel';

const ReturnOrders = () => {
    const [value, setValue] = React.useState("1");
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div id="main-content" className="container-fluid">
                <TabContext value={value}>
                    <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
                        <TabList
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            sx={{
                                "& .MuiTab-root": {
                                    px: 8,
                                    py: 2,
                                },
                            }}
                        >
                            <Tab
                                icon={<PauseCircleFilledIcon color='primary' sx={{ml:1}} />}
                                iconPosition="right"
                                label="Pending"
                                value="1"
                            />
                            <Tab
                                icon={<DoneAllIcon color='success' sx={{ml:1}} />}
                                label="Approved"
                                value="2"
                                iconPosition="right"
                            />
                            <Tab
                                icon={<CancelIcon color='error' sx={{ml:1}} />}
                                label="Rejected"
                                value="3"
                                iconPosition="right"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0 }}>
                        <PendingOrders />
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0 }}>
                        <ApprovedOrders />
                    </TabPanel>
                    <TabPanel value="3" sx={{ px: 0 }}>
                        <RejectedOrders />
                    </TabPanel>
                </TabContext>
            </div>
        </>
    );
}

export default withLayout(ReturnOrders);
