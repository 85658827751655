import { Grid, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import { useLazyGetManifestSearchQuery } from '../../../redux/commonRTK';
import { parseDateAndTime } from '../../../helpers/UtilityHelper';
import PageTitle from '../../../common/PageTitle';
import { getToken } from '../../../helpers/ReduxHelpers';
import jwt_decode from "jwt-decode";

function ManifestList() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [getManifestList, { data }] = useLazyGetManifestSearchQuery()
    const userToken = jwt_decode(getToken());
    const merchantId =
        userToken && userToken.merchantOid ? userToken.merchantOid : "";


    useEffect(() => {
        getManifestList({
            offset: (page * sizePerPage),
            limit: sizePerPage,
            andfilter: [{
                field: "merchantDetail.key",
                operator: "eq",
                value: merchantId,
            }],
        })
        // eslint-disable-next-line
    }, [page, sizePerPage])

    const columns = [
        {
            field: 'manifestNumber', headerAlign: "left", hideSortIcons: true, headerName: 'Manifest Number', flex: 1
        },
        {
            field: 'courierDetail', headerAlign: "left", hideSortIcons: true, headerName: 'Buyer Name', flex: 1, renderCell: (params) => {
                const { row } = params;
                let courierParent = (row.courierDetail?.parent).includes('Blended')? row.actualCourierProviderName :row.courierDetail?.parent;
                return courierParent;
            },
        },
        {
            field: 'manifest', headerAlign: "left", hideSortIcons: true, headerName: 'Manifest', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Link href={row?.manifestUrl} target='_blank'>Manifest</Link>
                )
            },
        },
        {
            field: 'totalOrders', headerAlign: "left", hideSortIcons: true, headerName: 'Total Shipments', flex: 0.8
        },
        {
            field: 'date', headerAlign: "left", hideSortIcons: true, headerName: 'Manifest Date', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return row.generatedDate ? parseDateAndTime(row.generatedDate) : ""
            },
        },
    ];
    return (
        <>
            <PageTitle>All Manifests</PageTitle>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={data?.data || []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={180}
                        rowCount={data?.count || 0}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ManifestList





