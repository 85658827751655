import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTrackingLists } from '../../../redux/actions/trackingList/trackingListAction';
import { updateTrackingList } from '../../../redux/reducers/trackingList/trackingListSlice';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { get, apiConstants, getDownload } from "../../../common/constants";

const TrackBox = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };
    const handleUpload = (e) => {
        navigate(`/tracking-list`);
        dispatch(getTrackingLists(e.target.files[0]));
    };
    const onSubmit = (data) => {
        // if (data.awb.indexOf(',') > -1) {
            let awb = (data.awb).replace(/\s/g, '');
            setLoading(true);
            get(`${apiConstants.BASE_LOCAL}${apiConstants.TRACK_ORDER}?waybills=${awb}`).then(response => {
                setLoading(false);
                dispatch(updateTrackingList(response.data));
                navigate(`/tracking-list`);
            })
                .catch(err => { console.error(err); setLoading(false); });
        // }
        // else {
        //     navigate(`/order-detail?waybill=${data.awb}`);
        // }
    };

    const handleBulkSample = () => {
        getDownload(
            `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=TRACK_ORDER`
        )
            .then((response) => {
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "TRACK_ORDER.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    return (
        <>
            <div className="row">
                <div className="col-12 position-relative">
                    <div className={`track-order ${errors.awb ? "is-invalid" : ""}`}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input
                                {...register('awb', {
                                    required: {
                                        value: true,
                                        message: "You must enter awb number"
                                    }
                                })}
                                className="form-control"
                                placeholder="Enter one or multiple AWB numbers (comma separated) to track orders"
                                aria-label="Track Orders"
                                aria-describedby="button-track-orders"
                            />
                            <button className="btn btn-dark" type="submit" id="button-track-orders">
                                {
                                    (loading) ? <span className="spinner-border spinner-border-sm"></span> :
                                        <span className="d-md-block">Track Order </span>
                                }

                                <img src="img/search3.svg" className="d-md-none" width="22" alt="" />
                            </button>
                            {errors.awb && <div className="invalid-feedback" role="alert">{errors.awb.message}</div>}
                        </form>
                    </div>
                </div>
                <div className="col-12 text-md-end mb-3"> For bulk tracking upload file here
                    <input type='file' id='file' onChange={handleUpload} ref={inputFile} style={{ display: 'none' }} />
                    <button className="btn btn-link btn-upload" onClick={onButtonClick}><img src="img/upload.svg" width="22" alt="" /> Upload</button>
                    <button className="btn btn-link btn-upload" onClick={handleBulkSample}><img src="img/download.svg" width="22" alt="" />Sample</button>
                    {/* <button onClick={()=> handleBulkSample()}></button> */}
                    {/* <Button
                        variant="outlined"
                        startIcon={<img src="img/download.svg" width="22" alt="" />}
                        className="btn btn-link btn-download ms-3"
                        onClick={() => handleBulkSample()}
                    >
                        Sample Upload
                    </Button> */}
                </div>
            </div>
        </>
    );

};
export default TrackBox;