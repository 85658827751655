import React from "react";
import { Card, Grid, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { TableSkeleton } from "../../../../common/Skeletons";
import _ from "lodash";
import InfoBox from "../../../../common/InfoBox";
import useGetCourier from "../useGetCourier";

export default function CourierSplit({ data, courierIds }) {
    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()

    if (isLoadingCouriers || isFetchingCouriers) return <TableSkeleton rows={10} />

    const allPresentCouriers = allCouriers.filter(row => courierIds.includes(row.id))
    const { rows: rowCount, allColumns } = transformChartDataToTable(data, [
        ...allPresentCouriers.map((z) => {
            const key = `c_${z.id}_value`
            return { key, label: z.id.replace(/_/g, " ") }
        })
    ])
    const { rows: rowPerc } = transformChartDataToTable(data, [
        ...allPresentCouriers.map((z) => {
            const key = `c_${z.id}_perc`
            return { key, label: z.id.replace(/_/g, " ") }
        })
    ])

    const infoBoxArray = [];

    allPresentCouriers.forEach(item => {
        infoBoxArray.push({
            courier_name: item.name,
            total_count: _.sumBy(data, "c_" + item.id + '_value'),
        });
    });

    return (
        <Card elevation={1}>
            <CardHeader title="Courier Wise Split" />
            <CardContent>
                <Grid container spacing={2}>
                    {_.orderBy(infoBoxArray, "total_count", "desc").map((item, index) => (
                        <Grid item xs={2} key={index}>
                            <InfoBox header={item?.courier_name?.replace(/_/g, " ")} value={item.total_count} />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <TableContainer sx={{ maxHeight: 1200 }}>
                            <Table size="small" density sx={{ borderCollapse: 'collapse' }}>
                                <TableHead sx={{ backgroundColor: '#000', color: '#fff' }}>
                                    <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                                        <TableCell sx={{ borderRight: '1px solid #ccc', color: '#fff', position: 'sticky', left: 0, zIndex: 2, backgroundColor: '#000' }} colSpan={1}>Name</TableCell>
                                        {allColumns.map((val, j) => (
                                            <TableCell key={j} sx={{ borderRight: '1px solid #ccc', color: '#fff' }} colSpan={2}>{val}</TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ position: 'sticky', left: 0, zIndex: 2, backgroundColor: '#000' }} />
                                        {allColumns.map((_val, i) => (
                                            <React.Fragment key={i}>
                                                <TableCell sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>Count</TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>%</TableCell>
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rowCount.map((row, i) => {
                                        const inTatData = _.find(rowPerc, { name: row.name })
                                        return (
                                            <TableRow key={i} sx={{ borderBottom: '1px solid #ccc' }}>
                                                <TableCell sx={{ borderRight: '1px solid #ccc', position: 'sticky', left: 0, zIndex: 2, backgroundColor: '#000', color: '#fff' }}>{_.filter(allCouriers, { id: row.name })[0]?.parent + " / " + _.filter(allCouriers, { id: row.name })[0]?.id}</TableCell>
                                                {allColumns.map((val, j) => (
                                                    <React.Fragment key={j}>
                                                        <TableCell sx={{ borderRight: '1px solid #ccc' }}>{_.get(row, val, 0)}</TableCell>
                                                        <TableCell sx={{ borderRight: '1px solid #ccc' }}>{_.get(inTatData, val, 0) + '%'}</TableCell>
                                                    </React.Fragment>
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}