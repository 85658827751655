import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, CardHeader, Chip, Grid, Typography, Step, StepLabel, Stepper, Divider } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { useGetPtlPurQuery, useGetSearchMerchantQuery } from '../../../redux/commonRTK';
import PageTitle from '../../../common/PageTitle';
import { formatDate, parseDateAndTime } from '../../../helpers/UtilityHelper';
import withLayout from '../../../hocs/Layout';
// import { useGetPtlPurQuery, useGetSearchMerchantQuery } from '../../redux/commonApiCalls'
// import PageTitle from '../../components/PageTitle'
// import { formatDate } from '../../common/util';
// import { parseDateAndTime } from '../../helpers/UtilityHelper';

const columns = [
    {
        field: 'weight', headerName: 'Per Box Weight (kg)', flex: 1, renderCell: (params) => {
            const { row } = params;
            let weight = row.weight ? row.weight : 0;
            return (weight / 1000).toFixed(2) + " kg";
        },
    },
    {
        field: 'count',
        headerName: 'Box Count',
        flex: 1
    },
    {
        field: 'length',
        headerName: 'Length (cm)',
        flex: 1
    },
    {
        field: 'width',
        headerName: 'Width (cm)',
        flex: 1
    },
    {
        field: 'height',
        headerName: 'Height (cm)',
        flex: 1
    },

]


function PickupRequestDetails() {
    const { id } = useParams()
    const { data: orders = [] } = useGetPtlPurQuery({
        limit: 1,
        offset: 0,
        orfilter: [],
        andfilter: [
            {
                field: "id",
                operator: "eq",
                value: id,
            },
        ],
    });
    const order = orders[0]
    const [pickupAddress, setPickupAddress] = React.useState(null);
    const orderHistory = order?.statusHistory ? order?.statusHistory : [];
    const payment_mode = ((order && order.paymentType) ? order.paymentType : "");
    const color = payment_mode === 'COD' ? "error" : "success";
    const { data: merchant = [] } = useGetSearchMerchantQuery({
        limit: 1,
        // includes:"pickup_details",
        excludes: "sla,kycDetails,mappedOrderRule",
        offset: 0,
        orfilter: [],
        andfilter: [
            {
                field: "id",
                operator: "eq",
                value: order?.merchantDetail?.key,
            },
        ],
    });

    useEffect(() => {
        let pickupAddressArray = merchant[0]?.pickup_details;
        let pickupAddress = pickupAddressArray?.find((address) => address?.name === order?.warehouseName);
        let pickupAddressString = pickupAddress?.name + " , " + pickupAddress?.address_line + " , " + pickupAddress?.city + " , " + pickupAddress?.state + " , " + pickupAddress?.pincode + " , " + pickupAddress?.contactNumber;
        setPickupAddress(pickupAddressString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [JSON.stringify(merchant)])
    }, [merchant[0]?.pickup_details])

    return (
        <>
            <div id="main-content" className="container-fluid">
            <PageTitle>Request Details / {order?.reference}</PageTitle>
                <Grid paddingBottom={2}>
                    <Card>
                        {/* <CardHeader title="Pickup Request Details" sx={{ fontWeight: 800 }} /> */}
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>ID</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.id) ? order.id : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reference Number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.reference) ? order.reference : ""} </Typography></Grid>

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchantDetail) ? order.merchantDetail?.name : ""} </Typography></Grid>

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courierDetail) ? order.courierDetail?.name + " ( " + order.courierDetail?.prozoId + " )" : ""} </Typography></Grid>


                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipment Type</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.orderType) ? order.orderType : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Dispatch Mode</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.dispatchMode) ? order.dispatchMode : ""}</Typography></Grid>

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Created Date</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.createdDate) ? formatDate(order.createdDate) : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Created By</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.createdDate) ? order.createdByUser : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Pickup Request Date</Typography></Grid>
                                {/* <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.orderReadyDate) ? formatDate(order.orderReadyDate) : ""}</Typography></Grid> */}
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.orderReadyDate) ? parseDateAndTime(order?.orderReadyDate, order?.orderReadyTime) : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Payment Mode</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={color} label={payment_mode} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>COD Type</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order?.paymentType === 'COD' ? order.codType : '')}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Auto Allocation</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.isAutoAllocateWhileUpload) ? <Chip size='small' color='success' label='Yes'>Yes</Chip> : <Chip size='small' color='error' label='No'></Chip>}</Typography></Grid>


                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Appointment Delivery</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.appointmentDelivery) ? <Chip size='small' color='success' label='Yes'>Yes</Chip> : <Chip size='small' color='error' label='No'></Chip>}</Typography></Grid>

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Insurance</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.insuranceRequired) ? <Chip size='small' color='success' label='Yes'>Yes</Chip> : <Chip size='small' color='error' label='No'></Chip>}</Typography></Grid>

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Carrier Risk</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.carrierRisk) ? <Chip size='small' color='success' label='Yes'>Yes</Chip> : <Chip size='small' color='error' label='No'></Chip>}</Typography></Grid>

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Pickup Request Status</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.status) ? order.status : ''}</Typography></Grid>
                            </Grid>

                        </CardContent>
                    </Card>

                </Grid>
                <Grid paddingBottom={2}>
                    <Card >
                        <CardHeader title="Address Details" />
                        <Grid container spacing={1} sx={{ padding: 3 }}>
                            <Grid item xs={6} md={2}><Typography variant='subtitle2' color={"black"}>Pickup Address</Typography></Grid>
                            <Grid item xs={6} md={10}><Typography variant='subtitle2'>{(order && order?.warehouseName) ? pickupAddress : ""}</Typography></Grid>
                            <Divider />
                            <Grid item xs={6} md={2}><Typography variant='subtitle2' color={"black"}>Drop Address</Typography></Grid>
                            <Grid item xs={6} md={10}><Typography variant='subtitle2'>{(order && order?.dropoffLocation) ? order?.dropoffLocation?.consignee + " , " + order?.dropoffLocation?.address + " , " + order.dropoffLocation?.city + " , " + order.dropoffLocation?.zip + ". Contact: " + order.dropoffLocation?.phone : ""}</Typography></Grid>

                        </Grid>

                    </Card>
                </Grid>

                <Grid>
                    <Card >
                        <CardHeader title="Shipment Details" />

                        <Grid container spacing={1} sx={{ padding: 3 }}>
                            <Grid item xs={6} md={1}><Typography variant='subtitle2' color={"black"}>Total Weight</Typography></Grid>
                            <Grid item xs={6} md={10}><Typography variant='subtitle2'>{(order && order.totalPackageWeight) ? <Chip size='small' color='success' label={(order.totalPackageWeight / 1000).toFixed(2) + " kg."}></Chip> : ''}</Typography></Grid>
                            <Grid item xs={12}>
                                <DataGrid
                                    columns={columns}
                                    getRowId={(row) => Math.random().toString(36).substr(2, 9)}
                                    rows={((order && order?.dimensions) ? order?.dimensions : [])}
                                    hideFooterPagination={true}
                                    autoHeight
                                    disableColumnMenu
                                    disableColumnFilter
                                    disableSelectionOnClick
                                />
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
                {order && order.status && (
                    <Grid paddingBottom={2}>
                        <Card >
                            <CardHeader title="Pickup Request History" />
                            <Grid container spacing={1} sx={{ padding: 3 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        overflow: "scroll",
                                        alignItems: "flex-start",
                                        background: "#f0f0f0", // Adding a background color
                                        padding: "20px", // Adding some padding
                                        borderRadius: "10px", // Adding rounded corners
                                    }}
                                >
                                    <Stepper activeStep={order?.status} alternativeLabel>
                                        {orderHistory.map((item, index) => (
                                            <Step key={index} sx={{ minWidth: "200px" }}>
                                                <StepLabel>
                                                    <Typography variant="subtitle1">
                                                        {item.status}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle2"
                                                    >
                                                        {parseDateAndTime(item.timestamp)}
                                                    </Typography>
                                                    <Typography component="div" variant="caption">
                                                        <div>{item.remark}</div>
                                                    </Typography>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </div>
                            </Grid>

                        </Card>
                    </Grid>
                )}
            </div>
        </>

    )
}
export default withLayout(PickupRequestDetails)

