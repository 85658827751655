import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from "react-router-dom";
import withLayout from '../../../hocs/Layout';
import TrackingReportSubmit from './trackingReportSubmit';
import TrackingResult from './trackingResult';
import TrackingSummary from './trackingSummary';
import { getTrackingDetails } from '../../../redux/actions/trackingDetail/trackingDetailAction';
import Loader from '../../../common/Loader';
import { useForm } from "react-hook-form";
import {CardMedia, Grid, IconButton, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const TrackingDetail = () => {
    const { handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            awbNo:''   
        }
    });
    let query = useQuery();
    const dispatch = useDispatch();
    const [awb,setAwb] = useState()
    const { trackingDetailInfo, loading } = useSelector((state) => state.trackingDetailSlice);

    useEffect(() => {
        if(awb !== null){
        dispatch(getTrackingDetails({ "waybill": query.get("waybill") }));
        }
    }, []);

    const onSubmit = (data) => {
        if(awb !== null){
        dispatch(getTrackingDetails({ "waybill": awb }));
        }
    };

    return (
        <>
            <div id="main-content" className="container-fluid">
                {loading && <Loader />}
                {/* <div className="row d-none d-md-flex">
                    <div id="page-title" className="col-12">
                        <Link to={`/`} className="page-title">
                            <img src="img/back.svg" width="21px" alt="" />
                            Tracking Result
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 position-relative">
                        <div className={`track-order ${errors.awb ? "is-invalid" : ""}`}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input
                                    {...register('awb', {
                                        required: {
                                            value: true,
                                            message: "You must enter awb number"
                                        }
                                    })}
                                    className="form-control"
                                    placeholder="You can track orders here"
                                    aria-label="Track Orders"
                                    aria-describedby="button-track-orders"
                                />
                                <button className="btn btn-dark" type="submit" id="button-track-orders"><span className="d-none d-md-block">Track Order</span><img src="img/search3.svg" className="d-md-none" width="22" alt="" /></button>
                                {errors.awb && <div className="invalid-feedback" role="alert">{errors.awb.message}</div>}
                            </form>
                        </div>
                    </div>
                </div> */}

                <Typography sx={{ paddingBottom: 3 }}>
                    <Link to={`/`}  >
                        <CardMedia component="img"
                            src="img/back.svg"
                            alt=""
                            sx={{ width: 21 }}
                        />
                        <Typography component='span' variant='h6'> Home</Typography>
                    </Link>
                </Typography>
                <div className={`track-order ${errors.awb ? "is-invalid" : ""}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField sx={{ width: 1370}}
                                    label="You must enter awb number"
                                    type="text"
                                    name="awbNo"
                                    value={awb}
                                    onChange={(e)=> setAwb(e.target.value)}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                type="submit"
                                                edge="end">
                                                <SearchIcon fontSize="large" />
                                            </IconButton>
                                        ),
                                    }}
                                />
                        {errors.awb && <div className="invalid-feedback" role="alert">{errors.awb.message}</div>}
                    </form>
                </div>




                <TrackingResult trackingDetailInfo={trackingDetailInfo} />
                <Grid container spacing={3} sx={{ flexDirection: "row"}}>
                    <Grid item xs={12} md={12}>
                    <TrackingReportSubmit />  
                    </Grid>
                    <Grid item xs={12} md={12}>
                      
                        <TrackingSummary trackingDetailInfo={trackingDetailInfo} />
                    </Grid>
                </Grid>

            </div>
        </>
    );
};

export default withLayout(TrackingDetail);
