import * as React from "react";
import { BarChart as BarChartRe, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ChartTooltip from "./ChartTooltip";
import getColor from "./colors";

export function BarChart({ width = "100%", data, height, chartOptions, ...other }) {

    return (
        <ResponsiveContainer width={width} height={height ? height : 350}>
            <BarChartRe
                data={data}
                {...other}
            >
                <CartesianGrid strokeDasharray="5 5" stroke="#dee2e6" />
                <XAxis dataKey={chartOptions.xaxis} label={{ value: (chartOptions.xLabel ? chartOptions.xLabel : ''), position: 'insideBottom', offset: 0, dy: 3, fontSize: '11px', fontWeight: 500, fill: '#666666' }} fontSize="11px" fontWeight="500" />
                <YAxis label={{ value: (chartOptions.yLabel ? chartOptions.yLabel : ''), position: "insideLeft", angle: -90, dy: -10, fontSize: '11px', fontWeight: 500, fill: '#666666' }} fontSize="11px" fontWeight="500" />
                <Tooltip content={<ChartTooltip />} />
                <Legend verticalAlign="top" align="right" iconSize="10px" iconType="circle" wrapperStyle={{ fontSize: 11, fontWeight: 500 }} />
                {
                    chartOptions.data.map((row, i) => (
                        <Bar
                            key={i}
                            dataKey={row.dataKey}
                            fill={row.fill || getColor(row.colorIndex || i)}
                            name={row.label}
                            maxBarSize={40}
                            unit={row.unit || ""}
                            animationEasing="ease-in-out"
                            animationDuration={1000}
                        />
                    ))
                }
            </BarChartRe>
        </ResponsiveContainer>
    )
}

export function VerticalBarChart({ data, height, width, chartOptions, ...other }) {

    return (
        <ResponsiveContainer width={width || '100%'} height={height ? height : 350}>
            <BarChartRe
                data={data}
                {...other}
                layout="vertical"
            >
                <CartesianGrid strokeDasharray="5 5" stroke="#dee2e6" />
                <XAxis dataKey={chartOptions.xaxis} label={{ value: (chartOptions.xLabel ? chartOptions.xLabel : ''), position: 'insideBottom', offset: 0, dy: 3, fontSize: '11px', fontWeight: 500, fill: '#666666' }} type="number" fontSize="11px" fontWeight="500" />
                <YAxis label={{ value: (chartOptions.yLabel ? chartOptions.yLabel : ''), position: "insideLeft", angle: -90, dy: 0, fontSize: '11px', fontWeight: 500, fill: '#666666' }} dataKey={chartOptions.yaxis} reversed type="category" fontSize="11px" fontWeight="500" />
                <Tooltip content={<ChartTooltip />} />
                <Legend verticalAlign="top" align="right" iconSize="10px" iconType="circle" wrapperStyle={{ fontSize: 11, fontWeight: 500 }} />
                {
                    chartOptions.data.map((row, i) => (
                        <Bar
                            key={i}
                            dataKey={row.dataKey}
                            fill={row.fill || getColor(row.colorIndex || i)}
                            name={row.label}
                            unit={row.unit || ""}
                            maxBarSize={40}
                            animationEasing="ease-in-out"
                            animationDuration={1000}
                        />
                    ))
                }
            </BarChartRe>
        </ResponsiveContainer>
    )
}

export function StackedBarChart({ data, height, chartOptions, ...other }) {
    return (
        <ResponsiveContainer width="100%" height={height ? height : 350}>
            <BarChartRe
                data={data}
                {...other}
            >
                <CartesianGrid strokeDasharray="5 5" stroke="#dee2e6" />
                <XAxis dataKey={chartOptions.xaxis} fontSize="11px" fontWeight="500" label={{ value: (chartOptions.xLabel ? chartOptions.xLabel : ''), position: 'insideBottom', offset: 0, dy: 3, fontSize: '11px', fontWeight: 500, fill: '#666666' }} />
                <YAxis fontSize="11px" fontWeight="500" label={{ value: chartOptions.labelY, angle: -90, position: 'insideLeft', fontSize: '11px', fontWeight: 500, fill: '#666666' }} />
                <Tooltip content={<ChartTooltip />} />
                <Legend verticalAlign="top" align="right" iconSize="10px" iconType="circle" wrapperStyle={{ fontSize: 11, fontWeight: 500 }} />
                {
                    chartOptions.data.map((row, i) => (<Bar
                        key={i}
                        dataKey={row.dataKey}
                        fill={row.fill || getColor(row.colorIndex || i)}
                        name={row.label}
                        stackId={chartOptions.stackId}
                        maxBarSize={40}
                        unit={row.unit || ""}
                        animationEasing="ease-in-out"
                        animationDuration={1000}
                    />))
                }
            </BarChartRe>
        </ResponsiveContainer>
    )
}
export function HorizontalStackedBarChart({ data, height, chartOptions, ...other }) {
    return (
        <ResponsiveContainer height={23} width={'100%'}>
            <BarChart
                data={data}
                layout={'vertical'}
                barGap={10}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            >
                <XAxis type={'number'} hide domain={['dataMin', 'dataMax']} />
                <YAxis
                    dataKey='axis'
                    type={'category'}
                    axisLine={false}
                    tickLine={false}
                    hide
                />
                {data.map((key, i) => (
                    <Bar key={key} stackId={'stack'} dataKey={key} fill={key.fill || getColor(key.colorIndex || i)} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
}