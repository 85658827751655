import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../../common/constants";

export const getServiceability= createAsyncThunk(
    'getServiceability',
    async (param, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.getItem('userToken')
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.SERVICEABILITY_PINCODE}`;
            const { data } = await axios.post(
                url,
                param,
                config
            )
            let courierData;
            try {
                const courierPayload = {
                    "andfilter":[{"field":"isDeleted","operator":"eq","value":"0"}],
                    offset: 0,
                    limit: 100,
                };
                let { data } = await axios.post(`${apiConstants.BASE_LOCAL}${apiConstants.COURIER_LIST}`,courierPayload, config)
                courierData = data
                
            } catch (err) { }

            return { ...data, courier: courierData }
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);