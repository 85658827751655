import React from "react";
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { numberPrecision } from "../../../../helpers/UtilityHelper";
import { Box } from "@mui/system";
import _ from 'lodash'
import { BarChart } from "../../../../components/charts/BarChart";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import useGetCourier from "../useGetCourier";

export default function CourierStatusWiseSplit({ data, title, isCourierView }) {
    const [view, setView] = React.useState("table")

    const { data: allCouriers = [] } = useGetCourier();
    const couriersGroupedById = _.groupBy(allCouriers, 'id');

    data.forEach((item) => {
        if (item.name && couriersGroupedById[item.name]) {
            const courier = couriersGroupedById[item.name][0];
            const courierName = courier ? (courier.parent || courier.name) : undefined;

            if (courierName) {
                item.name = courierName;
            }
        }
    })

    return (
        <Card elevation={1}>
            <CardHeader title={title} action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Data In %">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Data In Count">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />

            <CardContent>
                {view === 'chart' && (
                    <BarChart
                        height={362}
                        data={data}
                        chartOptions={{
                            xaxis: 'name',
                            xLabel: 'Courier',
                            yLabel: 'Number of Shipments',
                            data: [
                                {
                                    dataKey: 'count',
                                    label: 'Shipments',
                                },
                            ],
                        }}
                    />
                )}

                {view === 'table' && (
                    <TableContainer>
                        <Table size="small" density>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Number Of Shipments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.orderBy(data, 'count', 'desc').map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{numberPrecision(row.count)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </CardContent>
        </Card>
    )
}