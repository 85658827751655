import * as React from "react"
import { Box, Grid, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { eachMonthOfInterval, endOfMonth, subMonths } from "date-fns";
import { formatDate } from "../../helpers/UtilityHelper";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import ForwardAnalysis from "./ForwardAnalysis";
import PickupAnalysis from "./PickupAnalysis";
import RTOAnalysis from "./RTOAnalysis";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
    '& .MuiToggleButton-root': {
        textTransform: 'uppercase',
        '&::after': {
            borderRight: '1px solid #00000029',
            display: 'block',
            content: '""',
            height: '60%',
            position: 'absolute',
            right: '-2px'
        },
        '&:last-child::after': {
            borderRight: '0px solid #00000029 !important',
        }
    },
}));
const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "black",
        backgroundColor: '#f1c40f'
    }
});

export default function PerformanceDashboard({ tab }) {
    const title = React.useMemo(() => {
        if (tab === 5) return "Performance / Forward"
        if (tab === 6) return "Performance / Pickup"
        if (tab === 7) return "Performance / RTO"
    }, [tab])

    const dates = React.useMemo(() => {
        const end = new Date();
        const start = subMonths(end, 5)
        const months = eachMonthOfInterval({ start, end })
        return months.map((month) => ({
            start: formatDate(month, 'yyyy-MM-dd'),
            end: formatDate(endOfMonth(month), 'yyyy-MM-dd')
        }))
    }, [])

    const { enqueueSnackbar } = useSnackbar()

    let initialDates = ([...dates]).slice(-2).map(d => d.start)
    const [selectedDates, setSelectedDates] = React.useState(initialDates);
    const datesAsFilter = dates.filter(date => selectedDates.includes(date.start));

    const handleFormat = (_e, newFormats) => {
        if (newFormats.length > 3) {
            enqueueSnackbar("Can't compare more than 3 months!", { variant: 'info' })
            return;
        }
        setSelectedDates([...newFormats].sort());
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        my: 2,
                        alignItems: 'center'
                    }}>
                        <Typography variant="h6">{title}</Typography>
                        <Paper elevation={1} sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.divider}`,
                            flexWrap: 'wrap',
                        }}>
                            <StyledToggleButtonGroup
                                value={selectedDates}
                                onChange={handleFormat}
                                size="small"
                            >
                                {dates.map((date, i) => (
                                    <StyledToggleButton sx={{ borderRight: 'solid 1px' }} key={i} value={date.start}>
                                        {formatDate(date.start, "MMM yy")}
                                    </StyledToggleButton>
                                ))}
                            </StyledToggleButtonGroup>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
            {(tab === 5) && (<ForwardAnalysis datesAsFilter={datesAsFilter} selectedDates={selectedDates} />)}
            {(tab === 6) && (<PickupAnalysis datesAsFilter={datesAsFilter} selectedDates={selectedDates} />)}
            {(tab === 7) && (<RTOAnalysis datesAsFilter={datesAsFilter} selectedDates={selectedDates} />)}
        </React.Fragment>
    )
}