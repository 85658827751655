import { Button, Card, CardContent, CardHeader,  TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
// import { parseDateAndTime } from '../../../helpers/UtilityHelper';
// import Carousel from '../../merchant-onboarding/carousel';

const TrackingReportSubmit = (props) => {
    return (
        <>
        {/* <Grid container spacing={1}> */}
            <Card>
                <CardHeader title="Report Issue?"/>
                <CardContent>
                    <Typography sx={{paddingBottom:2}}>Facing problem?</Typography>
                    <TextField fullWidth id="filled-hidden-label-large" multiline rows={4} />
                    {/* <Grid container justify="center"> */}
                    <Box textAlign='right' paddingTop={2}><Button variant="contained" >Submit</Button></Box>
                    {/* </Grid> */}
                    {/* <Carousel/> */}
                </CardContent>
            </Card>
        {/* </Grid> */}
            {/* <div className="col-12 col-md-6">
                <div className="card card-pr card-report-issue">
                    <div className="card-body p-0">
                        <h5 className="card-title">Report Issue?</h5>
                        <div className="mb-3">
                            <label className="form-label">Facing problem?</label>
                            <textarea className="form-control" placeholder="Enter text"></textarea>
                            <button className="btn-submit btn btn-warning"> Submit </button>
                        </div>
                    </div>
                </div>
                <div className="card card-ad-space bg-transparent d-none">
                    <Carousel/>
                </div>
            </div> */}
        </>
    );
};
export default TrackingReportSubmit;