import React from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function MUITabs({ active, headers, handleChange }) {
  return (
    <Tabs
      value={active}
      indicatorColor='primary'
      textColor='primary'
      onChange={handleChange}
      variant='scrollable'
      sx={{ minWidth: "10%" }}
      scrollButtons='auto'
    >
      {headers.map((item, index) => (
        <Tab label={item.value} key={index} icon={item.icon && item.icon} iconPosition={item.iconPosition? item.iconPosition:"top"} sx={{
          px: 7,
          py:2,
          backgroundColor: '#fff'
        }}></Tab>
      ))}
    </Tabs>
  );
}
