import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default () => (
    <Carousel autoPlay showThumbs={false} infiniteLoop={true}>
        <div>
            <img src="img/ads/01.png" alt="ads" width="200px" />
        </div>
        <div>
            <img src="img/ads/02.png" alt="ads" width="200px" />
        </div>
        <div>
            <img src="img/ads/03.png" alt="ads" width="200px" />
        </div>
        <div>
            <img src="img/ads/04.png" alt="ads" width="200px" />
        </div>
        <div>
            <img src="img/ads/05.png" alt="ads" width="200px" />
            {/*<p className="legend">Legend 5</p>*/}
        </div>
        <div>
            <img src="img/ads/06.png" alt="ads" width="200px" />
        </div>

    </Carousel>
);
