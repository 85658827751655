import React from 'react';
import { Button, Grid } from '@mui/material';
import { CourierFilter, FormProvider, RHFSelectField, RHFTextField } from '../../common/hook-form';
import { useForm } from 'react-hook-form'
import _ from 'lodash';

const paymentModes = [{ label: 'Prepaid', value: 'PREPAID' }, { label: 'COD', value: 'COD' }]
const defaultFilter = { paymentMode: '', pickupPincode: '', courier: '' }

export const FilterContext = React.createContext()

export function useGetSelectedFilters() {
    let { filters } = React.useContext(FilterContext)

    if (filters) {
        for (const k of Object.keys(filters)) {
            if (_.isEmpty(filters[k])) {
                delete filters[k]
            }
        }
    }

    return filters;
}

export default React.memo(({ hideFilters = [] }) => {

    const { setFilters } = React.useContext(FilterContext)

    const methods = useForm({ defaultValues: defaultFilter })
    const { handleSubmit } = methods

    const onFilterSubmit = React.useCallback((filters) => {
        setFilters(filters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onFilterSubmit)}>
            <Grid container spacing={2} alignItems="center">
                {!hideFilters.includes("paymentMode") && (
                    <Grid item xs={3}>
                        <RHFSelectField enableNoneSelect name="paymentMode" options={paymentModes} width='100%' label="Payment Mode" />
                    </Grid>
                )}
                {!hideFilters.includes("courier") && (
                    <Grid item xs={3}>
                        <CourierFilter enableNoneSelect />
                    </Grid>
                )}
                {!hideFilters.includes("pickupPincode") && (
                    <Grid item xs={3}>
                        <RHFTextField name="pickupPincode" label="Pickup Pincode" variant="filled" />
                    </Grid>
                )}
                <Grid item xs={3}>
                    <Button variant='contained' color='primary' size="large" type="submit">Apply</Button>
                </Grid>
            </Grid>
        </FormProvider>
    )
})

export function FiltersContextProvider({ children }) {
    const [filters, setFilters] = React.useState()

    return (
        <FilterContext.Provider value={{ filters, setFilters }}>
            {children}
        </FilterContext.Provider>
    )
}