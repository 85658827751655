import { Button, Grid } from "@mui/material";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { formatDate, refreshS3SignedUrl } from "../../../helpers/UtilityHelper";

function Pods(props) {
    const { id } = props;
    const data = id.pods

    const handleView = async (url) => {
        const refreshedUrl = await refreshS3SignedUrl(url);
        window.open(refreshedUrl, '_blank');
    };

    const columns = [
        {
            field: 'preSignedUrl',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Link',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (

                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => { handleView(row.preSignedUrl) }}>View</Button>
                            </Grid>
                        </Grid >
                    </>
                )
            },
        },
        {
            field: 'podDateTime',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2} >
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {formatDate(row?.podDateTime)}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]
    return (
        <ProzoDataGrid
            columns={columns}
            autoHeight={true}
            rows={data.length > 0 ? data.map((val, index) => ({ ...val, id: index })) : []}
            pagination={false}
            hideFooterPagination={true}
            rowHeight={120}
            getRowId={(row) => row.id}
        />
    )
}

export default Pods;