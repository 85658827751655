import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withLayout from '../../../../hocs/Layout';
// import jwt_decode from "jwt-decode";
// import { getToken } from '../../../../helpers/ReduxHelpers';
// import { useNavigate } from 'react-router-dom';
import { createBulkOrder } from '../../../../redux/actions/bulkOrderCreate/bulkOrderCreateAction';
import Loader from '../../../../common/Loader';

const CreateBulkOrder = () => {
    // const userToken = jwt_decode(getToken());
    // const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const inputFile = useRef(null);
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const [error, setError] = useState();

    const { loading } = useSelector((state) => state.bulkOrderCreateSlice);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };
    const handleUpload = (e) => {
        const allowedExtension = ['text/csv'];
        const type = e.target.files[0].type;
        const fileData = e.target.files[0];
        if (allowedExtension.indexOf(type) > -1) {
            //BULK_UPLOAD_ORDER 
            dispatch(createBulkOrder(fileData));
        }
        else{
            setError("Please upload valid file");
        }
    };



    return (
        <>
            <div id="main-content" className="container-fluid">
                {loading && 
                    <Loader />
                }
                <div className="row">
                    <div id="page-title" className="col-12 col-md-3"><a href="create-order4.htm" className="page-title"><img src="img/back.svg" width="21px" alt="" /> Create Order</a></div>
                    <div className="col-12 col-md-9 text-start text-md-end create-order-switch"> <span>Create order for</span> Single
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span></label>
                        Bulk </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-7 col-lg-9">
                        
                        <div className="row">
                            <div className="col-12 mb-4">
                                <h4 className="form-title">Item Details</h4>
                            </div>
                            <div className={`col-12 mb-4 ${error ? "is-invalid" : ""}`}>
                                <form>
                                <input type='file' id='file' onChange={handleUpload} ref={inputFile} style={{ display: 'none' }} />
                                    <div className="imageuploadify well">
                                        <div className="imageuploadify-overlay">
                                            <i className="fa fa-picture-o"></i>
                                        </div>
                                        <div className="imageuploadify-images-list text-center">
                                            <span className="imageuploadify-message">Browse and select the csv file</span>
                                            <button type="button" onClick={onButtonClick} className="btn btn-browse" style={{"background":"white","color":"rgb(58, 160, 255)"}}>
                                                <img src="img/browse.svg" height="20" />Browse
                                            </button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                    {error && <div className="invalid-feedback" role="alert">{error}</div>}
                                </form>
                            </div>
                            <div className="col-12">
                                <div className="d-flex align-items-center download-template">
                                    <div className="flex-shrink-0"> <img src="img/upload-document.svg" height="64" alt="..." /> </div>
                                    <div className="flex-grow-1 ms-3"> You can also download the template for the records<br />
                                        <a href="#" target="_blank">Download the template</a> </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mb-5">
                            <div className="col-12 text-end">
                                <button type="button" className="btn btn-outline-primary btn-cancel me-md-4" >Cancel</button>
                                <button type="button" className="btn btn-primary btn-next onboarding-btn">Previous</button>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </>
    )

}
export default withLayout(CreateBulkOrder);