import Loader from "../../common/Loader";
import { useState } from "react";
import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { apiConstants, get, post } from "../../common/constants";
import { useEffect } from "react";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { LoadingButton } from "@mui/lab";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";

function ConfiguWebHook({ setReloadView }) {
    const [loading, setLoading] = useState(false);
    const { id: merchantId } = getMerchant();
    const [reload, setReload] = useState(0)
    // const [data,setData] = useState([])
    const methods = useForm({
        // resolver: yupResolver(PostSchema),
        defaultValues: {
            list: [
                {
                    webHookUrl: "",
                    subscribedEvents: "",
                    isWebHookActive: "",
                },
            ],
        },
    });
    const { handleSubmit, control, register, setValue, reset } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });

    const Active = [
        { label: "True", value: true },
        { label: "False", value: false },
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async (data) => {
        const updateList = data.list.map((item) => {
            let obj = {};
            obj["webHookUrl"] = item["webHookUrl"]
            obj["isWebHookActive"] = item["isWebHookActive"]
            let manipulatedData = [];
            // if (item?.subscribedEvents?.length <= 1 && item?.subscribedEvents[0] === "ALL") {
            //     manipulatedData = OrderStatusEnum.map((d) => (d.remark))
            // } else if (item?.subscribedEvents === "" || item?.subscribedEvents.length === 0) {
            //     manipulatedData = OrderStatusEnum.map((d) => (d.remark))
            // }
            // else if (item?.subscribedEvents?.length >= 2) {
            //     if (item.subscribedEvents.includes("ALL")) {
            //         manipulatedData = OrderStatusEnum.map((d) => (d.remark))
            //     } else {
            //         manipulatedData = item.subscribedEvents.map((d) => (d === "ALL" ? null : d))
            //     }
            // }
            if (item?.subscribedEvents?.length <= 1 && item?.subscribedEvents[0] === "ALL") {
                manipulatedData = null;
            }
            else if (item?.subscribedEvents?.length >= 2) {
                manipulatedData = item.subscribedEvents.map((d) => (d === "ALL" ? null : d))
            }
            obj["subscribedEvents"] = manipulatedData
            return obj;
        }
        )
        setIsSubmitting(true)
        const request = {
            merchantId: merchantId,
            webHooks: updateList
        }
        await post(`${apiConstants.BASE_LOCAL}${apiConstants.WEBHOOK}`, request).then((res) => {
            if (res.data.responseObj === "success") {
                enqueueSnackbar("WebHook is updated Successfully", { variant: 'success' })
                setReload(reload => reload + 1)
                setReloadView(reloadView => reloadView + 1)
            }
            else {
                enqueueSnackbar(res.data.responseObj, { variant: 'error' })
                setReload(reload => reload + 1)
            }

        }).catch((err) => console.log(err))
        setIsSubmitting(false)
        reset()


    };

    useEffect(() => {
        setLoading(true)
        get(`${apiConstants.BASE_LOCAL}${apiConstants.WEBHOOK_SEARCH}?merchantId=${merchantId}`).then((res) => {
            // setData(res?.data)
            // if (res?.data?.length !== 0) {
            //     for (let i = 0; i < res.data.length; i++) {
            //         setValue(`list.${i}.webHookUrl`, res.data[i].webHookUrl)
            //         setValue(`list.${i}.subscribedEvents`, res.data[i]?.subscribedEvents)
            //         setValue(`list.${i}.isWebHookActive`, res.data[i].isWebHookActive)
            //     }
            // }
            // else {
            //     setValue(`list.${0}.webHookUrl`, "")
            //     setValue(`list.${0}.subscribedEvents`, "")
            //     setValue(`list.${0}.isWebHookActive`, "")
            // }
            // setLoading(false)
            console.log('res', res);
            if (res?.data?.length > 0) {
                reset();
                res.data.forEach((item, index) => {
                    if (index === 0) {
                        setValue('list.0.webHookUrl', item.webHookUrl);
                        setValue('list.0.subscribedEvents', item.subscribedEvents);
                        setValue('list.0.isWebHookActive', item.isWebHookActive);
                    } else {
                        append(item);
                    }
                });
                setLoading(false)
            } else {
                reset({
                    list: [{ webHookUrl: '', subscribedEvents: '', isWebHookActive: '' }],
                });
                setLoading(false)
            }

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, append, reset, setValue])

    const addBlankField = () => {
        append({
            webHookUrl: "",
            subscribedEvents: "",
            isWebHookActive: "",
        });
    }

    const OrderStatusEnum = [
        {
            remark: "ORDER_PLACED",
            code: 1,
            description: "Order Has Been Placed / Manifested on Courier Partner",
        },
        { remark: "PICKUP_PENDING", code: 2, description: "Pickup Pending" },
        { remark: "PICKUP_FAILED", code: 3, description: "Pickup Failed" },
        { remark: "PICKED_UP", code: 4, description: "Pickup Has Been Done" },
        { remark: "INTRANSIT", code: 5, description: "In Transit" },
        {
            remark: "OUT_FOR_DELIVERY",
            code: 6,
            description: "Shipment Out For Delivery",
        },
        {
            remark: "NOT_SERVICEABLE",
            code: 7,
            description: "Area For Delivery Is Not Servicable",
        },
        { remark: "DELIVERED", code: 8, description: "Shipment Delivered" },
        { remark: "FAILED_DELIVERY", code: 9, description: "Delivery Failed" },
        {
            remark: "CANCELLED_ORDER",
            code: 10,
            description: "Order Has Been Cancelled",
        },
        {
            remark: "RTO_REQUESTED",
            code: 11,
            description: "Rto For Shipment has been Requested",
        },
        { remark: "RTO", code: 12, description: "Marked As Return" },
        {
            remark: "RTO_OUT_FOR_DELIVERY",
            code: 13,
            description: "Shipment Is Out For Delivery For RTO",
        },
        { remark: "RTO_DELIVERED", code: 14, description: "RTO Delivered" },
        { remark: "RTO_FAILED", code: 15, description: "RTO Failed" },
        { remark: "LOST", code: 16, description: "Shipment is Lost" },
        { remark: "DAMAGED", code: 17, description: "Shipment is damaged" },
        {
            remark: "SHIPMENT_DELAYED",
            code: 18,
            description: "Shipment Is Delayed Or Misroute",
        },
        {
            remark: "CONTACT_CUSTOMER_CARE",
            code: 19,
            description: "Contact To The Customer Care",
        },
        {
            remark: "SHIPMENT_HELD",
            code: 20,
            description: "Shipment Is being held",
        },
        { remark: "RTO_INTRANSIT", code: 21, description: "RTO In Transit" },
        {
            remark: "OUT_FOR_PICKUP",
            code: 25,
            description: "Shipment Out For Pickup",
        },
        {
            remark: "RTO_CONTACT_CUSTOMER_CARE",
            code: 26,
            description: "RTO Contact Customer Care",
        },
        {
            remark: "RTO_SHIPMENT_DELAY",
            code: 27,
            description: "RTO Shipment Delayed",
        },
        {
            remark: "AWB_REGISTERED",
            code: 28,
            description: "AWB registered on proship",
        },
        {
            remark: "EXCHANGE_PICKUP",
            code: 30,
            description:
                "Exchange successful, Shipment delivered and picked up from the customer",
        },
        {
            remark: "EXCHANGE_INTRANSIT",
            code: 31,
            description: "Exchanged shipment In Transit to warehouse",
        },
        {
            remark: "EXCHANGE_DELIVERED",
            code: 32,
            description: "When an exchange shipment is delivered back",
        },
        {
            remark: "MANIFESTED",
            code: 33,
            description: "When shipment is manifested",
        },
        {
            remark: "RETURN_ORDER_PLACED",
            code: 101,
            description: "Rto For Shipment has been Requested",
        },
    ];
    return (
        <>
            {(loading) && <Loader />}
            <Card>
                <CardHeader title='WebHook Configuration' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {fields?.map((field, index) => (
                                <>
                                    {console.log("field", field)}
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="h6">WebHook URL :</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            name="webHookUrl"
                                            label="WebHook Url"
                                            {...register(`list.${index}.webHookUrl`)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="h6">Subscribed Events :</Typography>
                                    </Grid>
                                    {console.log("field", field)}
                                    {/* {((field.subscribedEvents !== null && field.subscribedEvents?.length !== 0) || field.subscribedEvents === "") ? */}
                                    {field.subscribedEvents !== null ?
                                        <Grid item xs={6} md={6}>
                                            <RHFMultipleSelectField
                                                width="100%"
                                                name="subscribedEvents"
                                                options={[
                                                    { label: "All", value: "ALL" },
                                                    ...OrderStatusEnum.map((mer) => ({
                                                        label: mer.description,
                                                        value: mer.remark,
                                                    })),
                                                ]}
                                                {...register(`list.${index}.subscribedEvents`)}
                                                label="Events"
                                            />
                                        </Grid> :
                                        <Grid item xs={6} md={6} >
                                            <RHFMultipleSelectField
                                                width="100%"
                                                name="subscribedEvents"
                                                options={[
                                                    { label: "All", value: "ALL" },
                                                    ...OrderStatusEnum.map((mer) => ({
                                                        label: mer.description,
                                                        value: mer.remark,
                                                    })),
                                                ]}
                                                {...register(`list.${index}.subscribedEvents`)}
                                                label="Events"
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="h6">WebHook Active :</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <RHFSelectField
                                            width="100%"
                                            name="isWebHookActive"
                                            options={Active.map((mer) => ({
                                                label: mer.label,
                                                value: mer.value,
                                            }))}
                                            label="Status"
                                            {...register(`list.${index}.isWebHookActive`)}
                                        />
                                    </Grid>
                                    {fields.length !== 1 && (
                                        <Grid item xs={12} md={12}>
                                            <Button
                                                onClick={() => remove(index)}
                                                color="error"
                                                variant="contained"
                                                startIcon={<RemoveCircleOutlineIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                    {fields.length === index + 1 && (
                                        <Grid item xs={6} md={6}>
                                            <Button
                                                onClick={addBlankField} // Updated to call the function
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                            >
                                                Add
                                            </Button>
                                            {/* {data?.length > 1 ? <Typography color='error' variant="h6">Please click to view more</Typography> : ""} */}
                                        </Grid>
                                    )}
                                </>
                            ))}
                            <Grid item xs={6} md={6}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default ConfiguWebHook