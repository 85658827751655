import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import InfoBox from "../../../../common/InfoBox"
import { DualAxisChart } from "../../../../components/charts/CompositChart"
import { formatNumber, numberPrecision } from "../../../../helpers/UtilityHelper"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    yaxis: {
        left: {
            label: 'Count',
            data: [
                {
                    dataKey: 'totalCount',
                    label: 'Number of Shipments',
                    chartType: 'line'
                },
            ],
        },
        /* right: {
            label: 'Shipping Cost %',
            data: [
                {
                    dataKey: 'shippingCostPercent',
                    unit: '%',
                    label: 'Shipments Cost %',
                    chartType: 'line',
                    fill: '#C37DC4',
                },
            ],
        }, */
    },
}

export default function ShipmentsOverview({ data }) {
    console.log(data)
    return (
        <Card elevation={0}>
            <CardHeader title="Shipments Overview" />
            <CardContent>
                <Grid container spacing={2}>
                <Grid item xs={3}>
                        <InfoBox header="Total Forward Shipments" value={formatNumber(data.forwardOrderCount)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Picked Shipments" value={formatNumber(data.pickedUpOrderCount)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Pending Pickup Shipments" value={formatNumber(data.awbRegisteredOrderCount - data.pickedUpOrderCount)} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="Prepaid / COD %" value={numberPrecision(data.prepaidPercentage) + " / " + numberPrecision(data.codPercentage)} />
                    </Grid>                  
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <DualAxisChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}