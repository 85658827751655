import React from "react";

const withLayout = (PageComponent) => {
    return function WithPage({ ...props }) {
        return (
            <div id="layout-wrapper">
                {/* <Sidebar /> */}
                {/* Called The Component Parameter */}
                <PageComponent {...props} />
            </div>
        );
    };
};

export default withLayout;