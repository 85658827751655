import withLayout from "../../hocs/Layout"
import { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { apiConstants, get } from "../../common/constants";
import { Button, Card, CardContent, Chip } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { FormProvider, RHFMultiCheckbox } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import MUIModal from "../../common/MUIModal";
import PageTitle from "../../common/PageTitle";

function ViewwebHook() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const { id: merchantId } = getMerchant();
    const [open, setOpen] = useState(false)
    const methods = useForm();
    const [rows, setRow] = useState([])
    const { handleSubmit, setValue } = methods;

    const onSubmit = (data) => {

    }


    useEffect(() => {
        setLoading(true)
        get(`${apiConstants.BASE_LOCAL}${apiConstants.WEBHOOK_SEARCH}?merchantId=${merchantId}`).then((res) => {
            setData(res?.data)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })

    }, [merchantId])

    const handleEditClick = (item) => {
        setRow(item.row.subscribedEvents)
        setOpen(true)
        setValue("subscribedEvents", item.row.subscribedEvents)
    }

    const OrderStatusEnum = [
        {
            remark: "ORDER_PLACED",
            code: 1,
            description: "Order Has Been Placed / Manifested on Courier Partner",
        },
        { remark: "PICKUP_PENDING", code: 2, description: "Pickup Pending" },
        { remark: "PICKUP_FAILED", code: 3, description: "Pickup Failed" },
        { remark: "PICKED_UP", code: 4, description: "Pickup Has Been Done" },
        { remark: "INTRANSIT", code: 5, description: "In Transit" },
        {
            remark: "OUT_FOR_DELIVERY",
            code: 6,
            description: "Shipment Out For Delivery",
        },
        {
            remark: "NOT_SERVICEABLE",
            code: 7,
            description: "Area For Delivery Is Not Servicable",
        },
        { remark: "DELIVERED", code: 8, description: "Shipment Delivered" },
        { remark: "FAILED_DELIVERY", code: 9, description: "Delivery Failed" },
        {
            remark: "CANCELLED_ORDER",
            code: 10,
            description: "Order Has Been Cancelled",
        },
        {
            remark: "RTO_REQUESTED",
            code: 11,
            description: "Rto For Shipment has been Requested",
        },
        { remark: "RTO", code: 12, description: "Marked As Return" },
        {
            remark: "RTO_OUT_FOR_DELIVERY",
            code: 13,
            description: "Shipment Is Out For Delivery For RTO",
        },
        { remark: "RTO_DELIVERED", code: 14, description: "RTO Delivered" },
        { remark: "RTO_FAILED", code: 15, description: "RTO Failed" },
        { remark: "LOST", code: 16, description: "Shipment is Lost" },
        { remark: "DAMAGED", code: 17, description: "Shipment is damaged" },
        {
            remark: "SHIPMENT_DELAYED",
            code: 18,
            description: "Shipment Is Delayed Or Misroute",
        },
        {
            remark: "CONTACT_CUSTOMER_CARE",
            code: 19,
            description: "Contact To The Customer Care",
        },
        {
            remark: "SHIPMENT_HELD",
            code: 20,
            description: "Shipment Is being held",
        },
        { remark: "RTO_INTRANSIT", code: 21, description: "RTO In Transit" },
        {
            remark: "OUT_FOR_PICKUP",
            code: 25,
            description: "Shipment Out For Pickup",
        },
        {
            remark: "RTO_CONTACT_CUSTOMER_CARE",
            code: 26,
            description: "RTO Contact Customer Care",
        },
        {
            remark: "RTO_SHIPMENT_DELAY",
            code: 27,
            description: "RTO Shipment Delayed",
        },
        {
            remark: "AWB_REGISTERED",
            code: 28,
            description: "AWB registered on proship",
        },
        {
            remark: "EXCHANGE_PICKUP",
            code: 30,
            description:
                "Exchange successful, Shipment delivered and picked up from the customer",
        },
        {
            remark: "EXCHANGE_INTRANSIT",
            code: 31,
            description: "Exchanged shipment In Transit to warehouse",
        },
        {
            remark: "EXCHANGE_DELIVERED",
            code: 32,
            description: "When an exchange shipment is delivered back",
        },
        {
            remark: "MANIFESTED",
            code: 33,
            description: "When shipment is manifested",
        },
        {
            remark: "RETURN_ORDER_PLACED",
            code: 101,
            description: "Rto For Shipment has been Requested",
        },
    ];


    return (
        <>
            <div id="main-content" className="container-fluid">
                {/* <MUIModal
                    open={addOpen}
                    title="Webhooks Configuration"
                    handleClose={() => setAddOpen(false)}
                    children={<ConfiguWebhook setReloadView={setReloadView} />}
                /> */}
                {(loading) && <Loader />}
                <PageTitle>Webhooks Configuration</PageTitle>
                {/* <Button variant="contained" onClick={handleOpen} sx={{ mb: 2 }}>Add Webhooks</Button> */}
                <div className="row">
                    <div className="row">
                        <div className="col-12">
                            <Card>
                                <CardContent>
                                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                        <ProzoDataGrid
                                            columns={[
                                                // {
                                                //     field: 'Format Type',
                                                //     renderCell: ({ row }) => row?.formatType,
                                                //     width: 200

                                                // },
                                                {
                                                    field: 'Status',
                                                    renderCell: ({ row }) => {
                                                        const status = row?.isWebHookActive === true ? "Active" : "In-Active";
                                                        const color = status === 'Active' ? "success" : "error"
                                                        return (<Chip variant="filled" label={status} color={color} />)
                                                    },
                                                    width: 200

                                                },
                                                {
                                                    field: 'WebHook Url',
                                                    renderCell: ({ row }) => row?.webHookUrl,
                                                    width: 600

                                                },
                                                {
                                                    field: 'Subscribed Event Count',
                                                    renderCell: ({ row }) => row?.subscribedEvents?.length,
                                                    width: 200

                                                },
                                                {
                                                    field: 'Subscribed Events',
                                                    renderCell: (item) => {
                                                        return (
                                                            <Button variant='contained' size='small' onClick={() => handleEditClick(item)}>View Events</Button>
                                                        )
                                                    },
                                                    width: 200

                                                },
                                            ]}
                                            autoHeight={true}
                                            rows={data || []}
                                            // sizePerPage={sizePerPage}
                                            // setSizePerPage={setSizePerPage}
                                            // setPage={setPage}
                                            // page={page}
                                            pagination={false}
                                            hideFooterPagination={true}
                                            filterMode={"server"}
                                            rowHeight={120}
                                            rowCount={data?.length || 0}
                                            getRowId={(row) => Math.random()}
                                        />

                                        <MUIModal open={open} handleClose={() => setOpen(false)} children={
                                            rows?.length > 0 ?
                                                <RHFMultiCheckbox name='subscribedEvents' options={[
                                                    ...OrderStatusEnum.map((mer) => ({
                                                        label: mer.description,
                                                        value: mer.remark,
                                                    }))
                                                ]} />
                                                : "All events have been subscribed."}
                                            title={"Subscribed Events"} />
                                    </FormProvider>
                                </CardContent>

                            </Card>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withLayout(ViewwebHook);