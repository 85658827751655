import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {

      return (
        <Card sx={{ mt: 2, backgroundColor: 'rgba(206, 17, 38, 0.05)' }}>
          <CardHeader title="Something Went Wrong" />
          <CardContent>
            {this.state.error && this.state.error.toString()}
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <summary>Where it occured:</summary>
              {this.state.errorInfo.componentStack}
            </details>
          </CardContent>
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;