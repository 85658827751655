import * as React from "react"
import { Grid } from "@mui/material";
import usePerformanceDashboard from "../../common/hooks/dashboards/usePerformanceDashboard";
import PickupAgeing from "./dashboard_sections/PickupAgeing";

function PickupAnalysis({ datesAsFilter, selectedDates }) {

    const { data = [], isLoading } = usePerformanceDashboard(datesAsFilter);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PickupAgeing
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>
        </Grid>
    )
}

export default PickupAnalysis;