import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';

export default function RHFDateRangeFieldFromTo({
  gridSize = 6,
  fromDateProps,
  toDateProps,
}) {
  const { control } = useFormContext();

  return (
    <>
      <Grid item md={gridSize} xs={12}>
        <Controller
          name="fromDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                {...field}
                inputFormat="dd/MM/yyyy"
                value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                renderInput={(params) => <TextField
                  {...params}
                  label="From Date"
                  error={!!error}
                  fullWidth
                  variant="outlined"
                  helperText={error?.message}
                />}
                {...fromDateProps}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item md={gridSize} xs={12}>
        <Controller
          name="toDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                {...field}
                inputFormat="dd/MM/yyyy"
                value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                renderInput={(params) => <TextField
                  {...params}
                  label="To Date"
                  error={!!error}
                  fullWidth
                  variant="outlined"
                  helperText={error?.message}
                />}
                {...toDateProps}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </>
  )
}
