/** @format */

import "./splash-screen.css";

function Typing() {
  return (
    <div className='chat-bubble'>
      <div className='typing'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </div>
    </div>
  );
}

export default Typing;
