import React, { useEffect, useState } from 'react';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Button, Chip, Grid } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { parseDateAndTime } from '../../helpers/UtilityHelper';
import Loader from '../../common/Loader';
import { useGetNDRDataQuery } from '../../redux/commonRTK';
import { buildFilterConditions } from '../../helpers/UtilityHelper';
import { ActionLogModal } from '.';
import { Link } from 'react-router-dom';
import PageTitle from '../../common/PageTitle';

const InitiatedNdrData = (props) => {

    const [initiatedNdrData, setInitiatedNDRData] = useState({});
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [logModal, setLogModal] = useState(false);
    const [logModalData, setLogModalData] = useState();
    const exclude =
        "terminalStatus,shipmentDetail.cartonItems,invoiceNumber,shipmentType,returnInfo,labelUrl,dispatchMode,giftwrapCharge,transactionCharge,shippingCharge,pickupDetails,isInsured,codType,riskType,appointmentDelivery,async,order_date,courierDetail.prozoId,courierDetail.key,createdDate,actualPickUpTime,lastStatusUpdateTime,epd,edd,order_history,merchantPricing,merchant,courier,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,itemList,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,modifiedByUser,lastModifiedDate";

    const filterConditions = buildFilterConditions(props.filters);
    const { data: initiatedData, isLoading } = useGetNDRDataQuery({
        andfilter: [
            {
                field: 'ndrData.ndrStatus',
                operator: 'eq',
                value: 'INITIATED',
            },
            ...filterConditions.andfilter,
        ],
        orfilter: [...filterConditions.orfilter],
        offset: page * sizePerPage,
        limit: sizePerPage,
        skipCount: true,
        excludes: exclude,

    });
    useEffect(() => {
        setPage(0)
    }, [sizePerPage, props.filters])


    const columns = [
        {
            field: 'orderdetails', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.reference}
                        </Grid>
                        {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                            <Grid item xs={12}>
                                {row.shippingLabelId}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {(row.awbRegisteredDate) ? parseDateAndTime(row.awbRegisteredDate) : ""}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'courierDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.awb_number}`}>
                                {row.awb_number}
                            </Link>
                            <Grid item xs={12}>
                                {row?.courierDetail?.name}<br />
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'status', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                const orderStatus = row.orderStatus.replace(/_/g, ' ');
                return (<Chip color="error" size="small" label={orderStatus} />)
            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Address', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container >
                            <Grid item xs={12}>
                                Name : {row.delivery_details.to_name}
                            </Grid>
                            <Grid item xs={12}>
                                Phone : {row.delivery_details.to_phone_number}
                            </Grid>
                            <Grid item xs={12}>
                                {row.delivery_details.to_address},{row.delivery_details.to_city},{row.delivery_details.to_state},{row.delivery_details.to_pincode}
                            </Grid>
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'ndrReason', headerAlign: "center", hideSortIcons: true, headerName: 'NDR Reason', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.ndrCpStatusDescription) ? row.ndrData.ndrCpStatusDescription : "-NA-"}

                            </Grid>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.ndrReportedDate) ? parseDateAndTime(row.ndrData.ndrReportedDate, true) : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'ndrcomments', headerAlign: "center", hideSortIcons: true, headerName: 'NDR Comments', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.remark) ? row.ndrData.remark : "-"}

                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        // {
        //     field: 'attempts', headerAlign: "center", hideSortIcons: true, headerName: 'Attempts', flex: 0.8, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>
        //                 <Grid container rowSpacing={2}>
        //                     {(row.ndrData && row.ndrData.reAttemptCount) ? `${row.ndrData.reAttemptCount} attempt` : "0 attempts"}
        //                 </Grid>

        //             </>
        //         )
        //     },
        // },
        {
            field: 'ofdAttemptCount', headerAlign: "center", hideSortIcons: true, headerName: 'OFD Attempt Count', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {(row.ndrData && row.ndrData.ofdCount) ? `${row.ndrData.ofdCount} attempt` : "0 attempts"}
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'actionLog', headerAlign: "center", hideSortIcons: true, headerName: 'Action Log', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setLogModalData(row.ndrData?.actionUpdateLog);
                                    setLogModal(true);
                                }}
                            >
                                Action Log
                            </Button>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

    ];

    useEffect(() => {
        if (initiatedData) {
            setInitiatedNDRData(initiatedData);
        }
    }, [initiatedData]);


    return (
        <>
            <PageTitle>NDR Management</PageTitle>
            <Grid item container spacing={2}>
                {(isLoading) && <Loader />}
                <ActionLogModal data={logModalData} logModal={logModal} setLogModal={setLogModal} />
                <Grid item xs={12}>
                    <ProzoDataGrid columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(initiatedNdrData.data && initiatedNdrData.data.length > 0) ? initiatedNdrData.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={180}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id} />
                </Grid>
            </Grid>
        </>
    );
};

export default InitiatedNdrData

