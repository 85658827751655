import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getUserName } from "../../helpers/ReduxHelpers";
import { apiConstants, post } from "../../common/constants";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import withLayout from "../../hocs/Layout";

function ChangePassword() {
    const [loading, setLoading] = useState(false);
    const userName = getUserName();

    const { enqueueSnackbar } = useSnackbar()

    const method = useForm({
        resolver: yupResolver(Yup.object().shape({
            username: Yup.string().required(),
            current_password: Yup.string().required("Please enter current password"),
            new_password: Yup.string().required("Please enter new password").matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
              ),
            // confirm_password: Yup.string().required()
            confirm_password: Yup.string()
                .required("Please confirm your password")
                .oneOf([Yup.ref('new_password'), null], 'Passwords must match'), // Custom validation
        })),
        defaultValues: {
            username: userName,
            current_password: "",
            new_password: "",
            confirm_password: ""
        }
    })

    const { handleSubmit: handleSubmit1, reset } = method;

    const onSubmit = (data) => {
        setLoading(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.CHANGE_PASSWORD}`, data)
            .then(response => {
                setLoading(false);
                reset();
                enqueueSnackbar(response.data.message, { variant: 'success' });
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err?.response?.data?.message || 'Password could not be changed!', { variant: 'error' });
                setLoading(false);
            });
    }

    return (
        <div id="main-content" className="container-fluid">
            <Card elevation={1}>
                <CardHeader title="Reset Password" />
                <CardContent>
                    <FormProvider methods={method} onSubmit={handleSubmit1(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="username" label="Username" variant="filled" disabled />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="current_password" label="Current Password *" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="new_password" label="New Password" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="confirm_password" label="Confirm Password" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LoadingButton type="submit" loading={loading} variant="contained" size="large">Reset Password</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </div>
    );
};

export default withLayout(ChangePassword)