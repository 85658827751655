import { createSlice } from '@reduxjs/toolkit';
import { getOrdeDetails } from '../../actions/orderDetail/orderDetailAction';
const initialState = {
    loading: false,
    orderDetailInfo: [], // for dashboard object
    error: null,
    success: false, // for monitoring the success process.
}


export const orderDetailSlice = createSlice({
    name: 'orderDetail',
    initialState,
    reducers: {
        resetOrderDetails: (state) => {
            state.loading = false
            state.orderDetailInfo = []
            state.error = null
        },
    },
    extraReducers: {
        // get orderSummary api aggregate/orders
        [getOrdeDetails.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null
            }
        },
        [getOrdeDetails.fulfilled]: (state, { payload }) => {
            //console.log(payload);return;
            return {
                ...state,
                loading: false,
                orderDetailInfo: [payload.orderDetailInfo]
            }
        },
        [getOrdeDetails.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetOrderDetails } = orderDetailSlice.actions

export default orderDetailSlice.reducer