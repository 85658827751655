import React from "react";
import { Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { avgPickupAgeing, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { StackedBarChart } from "../../../charts/BarChart";
import MaterialTable from "../../../../common/MaterialTable";

export default function PincodeWiseSplit({ data }) {

    const [view, setView] = React.useState("chart")

    const stackedBarChartOptions = {
        xaxis: 'name',
        stackId: 'ageingPercentage',
        labelY: 'Percentage',
        data: avgPickupAgeing.map((z) => ({ dataKey: `${z}_perc`, label: z.replace(/_/g, " "), unit: '%' }))
    }
    
    
    const { rows, allColumns } = transformChartDataToTable(data.pickupPincodeWiseAgeing, avgPickupAgeing.map((z) => {
        const key = `${z}_perc`
        return { key, label: z.replace(/_/g, ' ') }
    }))

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return row[z] + '%'
            },
            label: z
        })))
    ]

    return (
        <Card elevation={1}>
            <CardHeader title="Pincode Wise Ageing" action={(
                <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                    <ToggleButton value="chart">
                        <BarChartIcon />
                    </ToggleButton>
                    <ToggleButton value="table">
                        <TableChartIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )} />
            <CardContent sx={{ px: 0 }}>
                {view === 'chart' && (
                    <StackedBarChart data={data.pickupPincodeWiseAgeing} chartOptions={stackedBarChartOptions} />
                )}
                {view === 'table' && (
                    <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} />
                )}
            </CardContent>
        </Card>
    )
}