import React, { useState } from 'react';
import './ndr.css';
import Loader from '../../common/Loader';
import { Button, Card, CardContent, Chip, Grid } from '@mui/material';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { FormProvider, RHFSelectField, RHFTextField } from '../../common/hook-form';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { apiConstants, postGeneric } from '../../common/constants';
import _ from 'lodash';
import { formatDate } from '../../helpers/UtilityHelper';
import PageTitle from '../../common/PageTitle';
import MUIModal from '../../common/MUIModal';
import jwt_decode from "jwt-decode";
import { getToken } from '../../helpers/ReduxHelpers';
import { Link } from 'react-router-dom';

const NDRCall = () => {
    const [page, setPage] = useState(0);
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState();
    const [data, setData] = useState();
    const [sizePerPage, setSizePerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [ndrQ, setNdrQ] = useState([])
    const [ndrQModal, setNdrQModal] = useState(false)
    const userToken = jwt_decode(getToken());
    const merchantId =
        userToken && userToken.merchantOid ? userToken.merchantOid : "";

    const methods = useForm({
        defaultValues: {
            awbnumber: '',
            ndrResults: ''
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const NDRResults = ["Reattempt Delivery", "Cancel Delivery", "Delivered", "Wrong Number", "Not Connected",
        "Reattempt Delivery Address Updated", "Busy or Improper Response", "Language Barrier", "Redial"]
    const options = NDRResults.map((op) => ({ label: _.startCase(op), value: op }))

    const handleFilterSubmit = (param) => {
        setFiltersChange(filterChange + 1)
        setFilters(param)
    }

    useEffect(() => {
        setPage(0)
    }, [sizePerPage, filters])

    useEffect(() => {
        setLoading(true)
        const filtersToApply = [{ field: "merchantOid", operator: "eq", value: merchantId }];
        if (filters && (filters.awbnumber || filters.ndrResults)) {
            if (filters.awbnumber) {
                filtersToApply.push({ field: "awb_number", operator: "eq", value: filters.awbnumber });
            }
            if (filters.ndrResults) {
                filtersToApply.push({ field: "ndr_result", operator: "eq", value: filters.ndrResults });
            }
        }
        const payload = {
            "collection": "futworks_ndr_recording",
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "direction": "DESC",
                "field": "created_at"
            }, "andfilter": filtersToApply
        }
        postGeneric(apiConstants.REPORT_GENERIC_SEARCH, payload).then((res) => {
            setLoading(false)
            setData(res?.data?.data)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sizePerPage, page, filterChange, filters])

    const columns = [
        {
            field: 'awb_number', headerAlign: "left", hideSortIcons: true, headerName: 'AWB No.', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.awb_number}`}>
                        {row.awb_number}
                    </Link>
                )
            },
        },
        {
            field: 'ndr_result', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Results', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.ndr_result}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'call_start_time', headerAlign: "left", hideSortIcons: true, headerName: 'Call Time', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                return formatDate(row.call_start_time)
            },
        },
        {
            field: 'call_duration', headerAlign: "left", hideSortIcons: true, headerName: 'Call Duration (Mins)', flex: 1, renderCell: (params) => {
                const { row } = params;
                return row?.call_duration
            },
        },
        {
            field: 'ndr_outcome', headerAlign: "left", hideSortIcons: true, headerName: 'Outcome', flex: 1, renderCell: (params) => {
                const { row } = params;
                const outcome = row?.ndr_outcome === true ? 'TRUE' : 'FALSE'
                const color = row?.ndr_outcome === true ? "success" : "error";
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Chip size="small" color={color} label={outcome} />
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'recording_url', headerAlign: "left", hideSortIcons: true, headerName: 'Recordings', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <a href={row.recording_url} target="_blank" rel="noopener noreferrer">
                        {row.recording_url ? "Play" : "-"}
                    </a>
                )
            },
        },
        {
            field: 'call_responses', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Call Response', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row?.call_responses && row.call_responses.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setNdrQ(row.call_responses);
                                        setNdrQModal(true);
                                    }}
                                >
                                    Questions asked to Customer
                                </Button>
                            </Grid>

                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        }
    ];


    return (
        <>
            <PageTitle>Call Recordings</PageTitle>
            <Grid item container spacing={4}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(handleFilterSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <RHFTextField name="awbnumber" label="AWB Number" color="secondary" variant="filled" />
                                    </Grid>
                                    <Grid item xs={3} style={{ overflow: 'hidden' }}>
                                        <RHFSelectField name="ndrResults" label="NDR Results" width='100%' color="secondary" variant="filled" options={_.orderBy(options, 'value')} />
                                    </Grid>
                                    <Grid item xs={4} sx={{ mt: 1 }}>
                                        <Button type="submit" variant="contained" color="info">Filter</Button>
                                        <Button type="submit" variant="contained" color="error" onClick={() => {
                                            reset({
                                                awbnumber: '',
                                                ndrResults: '',
                                            })
                                            setData()
                                        }} sx={{ ml: 1 }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {loading && <Loader />}
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={data ? data.map((row, index) => ({ ...row, id: index + 1, })) : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        // columnVisibilityModel={columnVisibilityModel}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        filterMode={"server"}
                        // exportToExcel={true}
                        // rowHeight={1}
                        getRowId={(row) => row.id} />
                </Grid>
                <MUIModal
                    open={ndrQModal}
                    title="Call Details"
                    maxWidth={"lg"}
                    handleClose={() => setNdrQModal(false)}
                >
                    <ProzoDataGrid
                        // autoHeight={false}
                        columns={[{
                            field: "question", headerName: "Question", flex: 3
                        }, {
                            field: "answer", flex: 1, headerName: "Answer"
                        },]}
                        autoHeight={true}
                        rows={ndrQ || []}
                        pagination={false}
                        hideFooterPagination={true}
                        rowHeight={120}
                        getRowId={(row) => Math.random()}
                    />
                </MUIModal>
            </Grid>
        </>
    );
}
export default NDRCall;