import MUIModal from "./MUIModal";
import ProzoDataGrid from "./ProzoDataGrid";

export default function OrderItemsModal({ items, setOpen, openList }) {
  const columnsItemModal = [
    {
      field: "sku_id",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "SKU",
      width: 130,
      align: "center",
    },
    {
      field: "item_name",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "SKU Name",
      width: 200,
      align: "center",
    },
    {
      field: "units",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Units",
      width: 100,
      align: "center",
    },
  ];

  return (
    <MUIModal
      open={openList}
      title="Item Details"
      handleClose={() => setOpen()}
    >
      <ProzoDataGrid
        // autoHeight={false}
        columns={columnsItemModal}
        rows={items ? items : []}
        pagination={false}
        hideFooterPagination={true}
        rowCount={items?.length || 0}
        getRowId={(row) => row.sku_id}
      />
    </MUIModal>
  );
}
