import React from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import PrivateRoutes from "./PrivateRoute"

import Login from '../app/components/auth/Login';
import ForgotPassword from '../app/components/auth/ForgotPassword';
import ResetPassword from '../app/components/auth/ResetPassword';
import Signup from '../app/components/auth/Signup';
import Otp from "../app/components/auth/otp";
import CreateNewPassword from "../app/components/auth/createNewPassword";
import TOC from "../app/components/auth/Toc";
import InactivityModal from "../app/common/InactivityModal";

export default function AppRoutes() {

    const { userInfo } = useSelector((state) => state.user)
    let isAuthorized = userInfo.accessToken || false

    return (
        <BrowserRouter>
            <InactivityModal />
            <Routes>
                <Route>
                    {isAuthorized ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            <Route index element={<Navigate to='/dashboard' />} />
                        </>
                    ) : (
                        <>
                            <Route exact element={<Login />} path="/login" />
                            <Route exact element={<TOC />} path="/toc" />
                            <Route exact element={<ForgotPassword />} path="/forgot-password" />
                            <Route exact element={<ResetPassword />} path="/reset-password" />
                            <Route exact element={<Signup />} path="/signup" />
                            <Route exact element={<Otp />} path="/otp" />
                            <Route exact element={<CreateNewPassword />} path="/create-new-password" />
                            <Route path='*' element={<Navigate to='/login' />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}