import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants,  postGeneric } from "../../../common/constants";
import { FormProvider, RHFTextField } from "../../../common/hook-form";
import * as Yup from "yup";

export default function UpdateStatus(props) {
  const { Status, id, setReload, setOpen } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        remarks: Yup.string().required("Remarks are required"),
      })
    ),
    defaultValues: {
      remarks: "",
    },
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    setIsSubmitting(true);
    postGeneric(apiConstants.WEIGHT_DISCREPANCY_UPDATE, [
      {
        awbNumber: id,
        status:
          Status === "DISPUTE"
            ? "DISPUTED_BY_MERCHANT"
            : Status === "ACCEPT"
            ? "ACCEPTED_BY_MERCHANT"
            : "",
        remark: data.remarks,
      },
    ])
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Status is updated Successfully", {
            variant: "success",
          });
          setReload((reload) => reload + 1);
          setOpen((dispute) => false);
          setOpen((agree) => false);
          setIsSubmitting(false);
        } else {
          enqueueSnackbar("Status is not Updates", { variant: "error" });
          setReload((reload) => reload + 1);
          setOpen((dispute) => false);
          setOpen((agree) => false);
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };
  return (
    <Card>
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} ms={12}>
              <RHFTextField name="remarks" label="Please provide remarks" />
            </Grid>
            <Grid item xs={12} ms={12}>
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                size="medium"
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </FormProvider>
      </CardContent>
    </Card>
  );
}
