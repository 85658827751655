import { Card, CardContent, CardHeader,  Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, {useState,useEffect}from 'react';
import { parseDateAndTime, parseTime, sortByTimestamp } from '../../../helpers/UtilityHelper';

const TrackingSummary = (props) => {
    const { trackingDetailInfo } = props;
    const [currentStage, setCurrentStage] = useState(0);
    const order_history = (trackingDetailInfo?.waybillDetails?.length >= 1 && trackingDetailInfo?.waybillDetails[0]?.order_history) ? sortByTimestamp(trackingDetailInfo?.waybillDetails[0]?.order_history, "timestamp") : [];

    useEffect(()=>{
        
        for(let i=0; i<order_history?.length; i++){
            if(order_history[i].orderStatusCode !== 8){
                setCurrentStage(order_history.length-1);
            }
            else{
                setCurrentStage(order_history.length);
            }
           }
       })
    return (
        <>
            {/* <Grid container spacing={1}> */}
                <Card>
                    <CardHeader title="Order Status" />

                    <CardContent>
                    <Stepper activeStep={currentStage} alternativeLabel>
                {order_history.reverse().map((item, index) => (
                   
                    <Step key={index} >
                        <StepLabel>
                            <Typography variant='subtitle2' color={"black"}>{item.orderStatusDescription}</Typography>
                            <Typography variant='subtitle2'color={"green"}>{parseDateAndTime(item.timestamp, true)}</Typography>
                            <Typography component='span' variant='subtitle2'>{item.remark} |  {parseTime(item.timestamp)}</Typography>
                        </StepLabel>
                    </Step>
                   
                ))}
           </Stepper>
                    </CardContent>

                </Card>
            {/* </Grid> */}
            {/* <div className="col-12 col-md-6">
                <div className="card card-pr card-order-status">
                    <div className="card-body p-0">
                        <h5 className="card-title">Order Status</h5>
                        <ul className="timeline">

                            {order_history.map((item, index) =>
                                <li className="timeline-item timeline-item-detailed" key={`order_tracking_${index}`}>
                                    <div className="timeline-content">
                                        <div className="timeline-avatar"><img src={`${orderIcon(item.orderStatusDescription)}`} width="33" alt="Avatar" /></div>
                                        <div className="timeline-header">{item.orderStatusDescription}
                                            <div className="timeline-summary">
                                            <p>{item.remark} |  {parseTime(item.timestamp)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-date"><span>{parseDateAndTime(item.timestamp,true)}</span></div>
                                </li>
                            )}


                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    );
};
export default TrackingSummary;