import React from "react";
import { Button, Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { deliveryAgeingBucket, formatNumber, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box } from "@mui/system";
import MaterialTable from "../../../../common/MaterialTable";
import CompositChart from "../../../charts/CompositChart";

export default function AgeingSplitSplit({ data, title }) {
    const [view, setView] = React.useState("chart")
    const [showCount, setShowCount] = React.useState(false)

    const ageingKeys = Object.keys(deliveryAgeingBucket)

    const { rows, allColumns } = transformChartDataToTable(data, [
        ...ageingKeys.map((z) => {
            const key = `${z}_percentage`
            return { key, label: deliveryAgeingBucket[z] }
        })
    ])

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return formatNumber(row[z])
            },
            label: z
        })))
    ]

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxisLabel: 'Percentage',
        data: [
            {
                dataKey: 'avgDelay',
                label: 'Out-TAT %',
                chartType: 'line',
                unit: '%',
                fill: 'red'
            },
        ]
    }

    let dataToAppend = []

    if (showCount) {
        dataToAppend = ageingKeys.map((z) => ({
            dataKey: `${z}_percentage`,
            label: deliveryAgeingBucket[z],
            chartType: 'stackedBar',
            unit: '%',
            stackId: 'ageing'
        }))
    } else {
        dataToAppend = []
    }

    chartOptions.data = [...chartOptions.data, ...dataToAppend]

    return (
        <Card elevation={1}>
            <CardHeader title={title} action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        disableElevation
                        onClick={() => setShowCount(st => !st)}
                        variant={showCount ? "contained" : "outlined"}
                        sx={{ mr: 1 }}
                    >
                        Show Ageing
                    </Button>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Chart">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Table">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent sx={{ px: view === 'chart' ? 1 : 0 }}>
                {view === 'chart' && (
                    <CompositChart data={data} chartOptions={chartOptions} />
                )}
                {view === 'table' && (
                    <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />
                )}
            </CardContent>
        </Card>
    )
}