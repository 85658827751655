import React, { useState } from 'react';
import withLayout from '../../hocs/Layout';
import "../../../app/components/wallet/wallet.css";
import PageTitle from '../../common/PageTitle';
import B2cNotification from './B2cNotification';
import B2bNotification from './B2bNotification';

const Notification = () => {
    const [currentTab, setCurrentTab] = useState("B2c");

    const handleTabClick = (tab) => {
        setCurrentTab(tab);
    };

    return (
        <>
            <div id="main-content" className="container-fluid">
            <PageTitle>Manage Notifications</PageTitle>
                <div className="row">
                    <div className="col-12 position-relative">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className='nav-link active'
                                    id="cod-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#cod-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="cod-tab-pane"
                                    aria-selected="true"
                                    onClick={() => handleTabClick("B2c")}
                                >
                                    B2C
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className='nav-link'
                                    id="mis-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#mis-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="mis-tab-pane"
                                    aria-selected="false"
                                    onClick={() => handleTabClick("B2b")}
                                >
                                    B2B
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="NDRTabContent">
                            <div className='tab-pane fade show active' id="cod-tab-pane" role="tabpanel" aria-labelledby="cod-tab" tabIndex="0">
                                <B2cNotification />
                            </div>
                            <div className='tab-pane fade' id="mis-tab-pane" role="tabpanel" aria-labelledby="mis-tab" tabIndex="0">
                                {currentTab === "B2b" &&
                                    <B2bNotification
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withLayout(Notification);
