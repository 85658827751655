import _ from "lodash";
import { useGetCouriersQuery } from "../../redux/commonRTKPersist";

export default function useSlasWithCourier(merchantDetail) {
  const {
    data: allCouriers = [],
    isLoading,
    isFetching,
  } = useGetCouriersQuery();

  const activeSlas = (merchantDetail?.sla || []).filter(sla => sla.active);

  if (isLoading || isFetching) {
    return { isLoading: true };
  }

  let slasWithCourierId = activeSlas?.map((sla) => {
    const courierByProzoId = allCouriers.find((c) =>
      sla.cplist.includes(c.prozoId)
    );

    const slaArr = [];
    for (const [, val] of Object.entries(sla.sla)) {
      try {
        if (val && val.slab) delete val.slab;
      } catch (err) { }
      slaArr.push({
        ...val,
        courierId: courierByProzoId?.id || null,
        courierName: courierByProzoId?.name || "-",
      });
    }
    return slaArr;
  });

  return { slas: _.flatten(slasWithCourierId), isLoading: false };
}
