import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DownloadExcelButton } from '../helpers/ExcelGenerator';
import _ from 'lodash';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

/**
 * 
 * @param [{ label, fileName, columns }] options 
 * @returns React Dropdown Component 
 */

function ExportChartDataToExcel({ options = [] }) {
    const [excelProps, setExcelProps] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const timerRef = React.useRef()
    const open = Boolean(anchorEl);

    const handleClick = React.useCallback((event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback((e, value) => {
        e.stopPropagation()
        setExcelProps(_.find(options, { label: value }))
        setAnchorEl(null)
    }, [options]);

    React.useEffect(() => {
        timerRef.current = setTimeout(() => {
            setExcelProps(null)
        }, 5000)
        return () => clearTimeout(timerRef.current)
    }, [excelProps])



    if (options.length === 0) {
        return null
    }

    return (
        <React.Fragment>
            {excelProps && <DownloadExcelButton filename={excelProps?.fileName} data={excelProps.data} columns={excelProps.columns} hideElement />}
            <Button
                sx={{ mr: 1 }}
                variant="contained"
                disableElevation
                color='warning'
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Download
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((op) => (
                    <MenuItem key={op.label.replace(/ /g, "")} onClick={(e) => handleClose(e, op.label)} disableRipple>
                        <FileDownloadIcon />
                        {op.label}
                    </MenuItem>
                ))}
            </StyledMenu>
        </React.Fragment>
    );
}

export default React.memo(ExportChartDataToExcel)