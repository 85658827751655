import * as React from "react"
import { suffix, formatDate, formatNumber, monthColors, allZones } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";

export default function MonthCourierWiseZone({ data, selectedDates, isLoading }) {
    const __tableRef = React.useRef(null)

    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = "Zone Wise Delivered Shipments";

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={((selectedDates.length + 1) * (allZones.length * 3))}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName={heading} fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={3} style={{ minWidth: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <React.Fragment key={i}>
                                <td align="center" style={{
                                    backgroundColor: monthColors[i]
                                }} colSpan={allZones.length * 3}>{formatDate(date, 'MMM yy')}</td>
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_date, i) => (
                            <React.Fragment key={i}>
                                {allZones.map((_zone, _i) => (
                                    <td key={_zone} align="center" style={{
                                        backgroundColor: monthColors[i]
                                    }} colSpan={3}>{_zone}</td>
                                ))}
                            </React.Fragment>
                        ))}
                    </tr>

                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={i}>
                                {allZones.map((_zone, _i) => (
                                    <React.Fragment key={_zone}>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>Count</td>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>Delivered</td>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>%</td>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={i}>
                                {/* <td style={{ minWidth: 200 }}>{row.name}</td> */}
                                <td style={{ width: 200 }}>{row.parent ? row.parent:row.name}</td>
                                {selectedDates.map((_d, j) => (
                                    <React.Fragment key={j}>
                                        {allZones.map((_zone, _j) => (
                                            <React.Fragment key={_j}>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>
                                                    {formatNumber(row[`${formatDate(_d, 'MMM_yy')}_${_zone}_total`])}
                                                </td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>
                                                    {formatNumber(row[`${formatDate(_d, 'MMM_yy')}_${_zone}_delivered`])}
                                                </td>
                                                <td align="right" style={{
                                                    backgroundColor: monthColors[j]
                                                }}>
                                                    {suffix(row[`${formatDate(_d, 'MMM_yy')}_${_zone}_percentage`], { postfix: '%' })}
                                                </td>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}