import React from 'react';
import {
  delivered as delShip,
  formatNumber,
  placed,
  rto as rtoShip,
  cancelled as cancelledShip,
  transit,
  deliveryFailed,
  lost
} from '../../../helpers/UtilityHelper';
import TrackBox from '../../tracking/list/trackBox';
import { Link } from 'react-router-dom';

const OrderSummary = ({ data }) => {
  const flatData = data?.data || []
  const live = (flatData.filter((or) => transit.includes(or.orderStatus)))?.length || 0;
  const notPicked = (flatData.filter((or) => placed.includes(or.orderStatus)))?.length || 0;
  const delivered = (flatData.filter((or) => delShip.includes(or.orderStatus)))?.length || 0;
  const rto = (flatData.filter((or) => rtoShip.includes(or.orderStatus)))?.length || 0;
  const ndr = (flatData.filter((or) => deliveryFailed.includes(or.orderStatus)))?.length || 0;
  const cancellled = (flatData.filter((or) => cancelledShip.includes(or.orderStatus)))?.length || 0;
  const lostShip = (flatData.filter((or) => lost.includes(or.orderStatus)))?.length || 0;

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <div className="card card-pr card-order-stats h-auto m-0">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/total-order.svg" width="36" height="36" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{formatNumber(flatData.length)}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0"> Total Shipments</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/out-for-pickup.svg" width="38" height="36" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{notPicked}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0"> Pending Pickup</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/intransit.svg" width="48" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{live}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">In Transit</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/delivered.svg" height="36" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{delivered}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Delivered</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/ndr2.svg" height="36" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{ndr}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Delivery Failed</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/rto.svg" width="46" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{rto}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">RTO</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/cancellled.svg" width="42" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{cancellled}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Cancelled</div>
              </div>
              {/* <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/pending-ndr.svg" width="42" alt="dashboard-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{lostShip}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Lost</div>
              </div> */}
              <div className="col-12 col-md-3 col-lg mb-4 mb-lg-0 d-flex justify-content-md-end align-items-end">
                <Link
                  to={`/orders`}
                  className="btn-view-all"
                >
                  View all
                </Link>
              </div>
            </div>
            <br />
            <TrackBox />
            {/*<div className="track-order position-relative mt-4">
                      <input type="text" className="form-control" placeholder="You can track orders here" aria-label="Track Orders" aria-describedby="button-track-orders" />
                      <button className="btn btn-dark" type="button" id="button-track-orders" onclick="window.location.href='tracking-result.htm';"><span className="d-none d-md-block">Track Order</span><img src="img/search3.svg" className="d-md-none" width="22" alt="dashboard-icon"/></button>
                    </div>
                    <div className="row">
                      <div className="col-12 text-end"> For bulk tracking upload file here
                        <button className="btn btn-link btn-upload"><img src="img/upload.svg" width="22" alt="dashboard-icon" /> Upload</button>
                      </div>
    </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
