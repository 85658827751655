import { Button, Card, CardContent, Chip, DialogContentText, Grid, IconButton, Tooltip } from "@mui/material"
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form"
import { LoadingButton } from "@mui/lab"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { downloadCSV, formatCurrency, formatDate, formatNumber } from "../../helpers/UtilityHelper";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { apiConstants, post, postGeneric } from "../../common/constants"
import ProzoDataGrid from "../../common/ProzoDataGrid"
import { getToken } from "../../helpers/ReduxHelpers"
import jwt_decode from "jwt-decode";
import withLayout from "../../hocs/Layout"
import Loader from "../../common/Loader"
import { getYear } from "date-fns"
import MUIModal from "../../common/MUIModal"
import _ from "lodash";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PageTitle from "../../common/PageTitle"
import { useSnackbar } from "notistack"
// import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator"

const CODRemittance = () => {
    const userToken = jwt_decode(getToken());
    const merchantId =
        userToken && userToken.merchantOid ? userToken.merchantOid : "";
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState({
        merchantId: merchantId,
        yearAndMonth: null,
        year: null,
        createdDateSort: "DESC",
    });
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [reload, setReload] = useState(0)
    const [rowCount, setRowCount] = useState()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const [rows, setRow] = useState([])
    const { enqueueSnackbar } = useSnackbar();


    const Status = [{
        label: "GENERATED",
        value: "GENERATED"
    },
    {
        label: "REMITTED",
        value: "REMITTED"
    },
    {
        label: "APPROVED",
        value: "APPROVED"
    },
    {
        label: "PARTIALLY PAID",
        value: "PARTIALLY_PAID"
    }
    ];


    const Month = [{
        label: "January",
        value: "01"
    },
    {
        label: "February",
        value: "02"
    },
    {
        label: "March",
        value: "03"
    },
    {
        label: "April",
        value: "04"
    },
    {
        label: "May",
        value: "05"
    },
    {
        label: "June",
        value: "06"
    },
    {
        label: "July",
        value: "07"
    },
    {
        label: "August",
        value: "08"
    },
    {
        label: "September",
        value: "09"
    },
    {
        label: "October",
        value: "10"
    },
    {
        label: "November",
        value: "11"
    },
    {
        label: "December",
        value: "12"
    }
    ]

    const columns = [
        // {
        //     field: 'Month',
        //     renderCell: ({ row }) => month(row?.fromDate),
        //     width: 200

        // },
        {
            field: 'Period',
            flex: 1.5,
            renderCell: ({row}) => {
                // const { row } = params;
                // let dateString = row.startDayMonthEndDayMonth;
                // const startDate = dateString.substring(0, 2);
                // const startMonth = dateString.substring(2, 4);
                // const endDate = dateString.substring(4, 6);
                // const endMonth = dateString.substring(6, 8);

                // const startPeriod = startDate && startMonth ? `${startDate} ${month(startMonth)}` : '';
                // const endPeriod = endDate && endMonth ? `${endDate} ${month(endMonth)}` : '';
                return `${formatDate(new Date(row.fromDate), 'dd MMM')} - ${formatDate(new Date(row.toDate), 'dd MMM')}`;
            },
        },
        // {
        //     field: 'From Date',
        //     renderCell: ({ row }) => formatDate(row?.fromDate),

        //     width: 200

        // },
        // {
        //     field: 'To Date',
        //     renderCell: ({ row }) => formatDate(row?.toDate),
        //     width: 200
        // },
        {
            field: 'Total Orders',
            renderCell: ({ row }) => formatNumber(row?.totalOrders),
            flex: 1
        },
        {
            field: 'Status',
            align: 'left',
            renderCell: ({ row }) => (<Chip
                label={row.reportStage}
                size="small"
                sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                    backgroundColor: row.reportStage === 'GENERATED' ? '#008000' : row.reportStage === 'REMITTED' ? '#084298' : row.reportStage === 'PARTIALLY_PAID' ? '#ED553B' : "#ffa500",
                    color: 'white'
                }}
            />),
            flex: 1.5
        },
        {
            field: 'COD Amount Payable',
            renderCell: ({ row }) => formatCurrency(row?.totalCODAmount),
            flex: 1.5
        },

        {
            field: 'Remitted Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant ? formatCurrency(row?.amountRemittedToMerchant) : "-",
            flex: 1.5
        },
        {
            field: 'Action',
            flex: 1.2,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Tooltip title="Download Details">
                            <IconButton onClick={() => window.open(row.reportSignedLinkS3)} color="button" size="small">
                                <DownloadSharpIcon color="primary" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download CSV">
                            <IconButton onClick={() => handleCodDownload(row)} color="button" size="small">
                                <DownloadSharpIcon color="success" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {row?.merchantPaymentDetails?.length > 0 &&
                            <Tooltip title="View Remittance Details">
                                <IconButton
                                    onClick={() => handleFinance(row)}
                                    color="button" size="small">
                                    <CurrencyRupeeIcon color="primary" fontSize="small" />
                                </IconButton>
                            </Tooltip>}
                    </>
                )
            }
        }
    ]

    const handleCodDownload = (data) => {
        let payload = {
            fromDate: formatDate(data.fromDate, "yyyy-MM-dd"),
            toDate: formatDate(data.toDate, "yyyy-MM-dd"),
            merchantId: data.merchantRef,
        }
        setLoading(true)
        postGeneric(`${apiConstants.COD_REMITTANCE_REPORT}`, payload
        ).then((res) => {
            setLoading(false)
            if (res.status === 200 && res.data.status === "ERROR") {
                enqueueSnackbar(res.data.errorMsg, { variant: "error" });
            } else {
                let csvData = res.data;
                const filename = 'cod_remittance.csv';
                downloadCSV(csvData, filename);
            }
        }).catch(err => {
            setLoading(false)
            console.error(err)
            enqueueSnackbar("Some error occurred", { variant: "error" });
        })
    }

    const handleFinance = (row) => {
        setOpen(true);
        setRow(row)
    }

    const methods = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            year: '',
            month: ''
        }
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        var newDate = "";
        if (data.year && data.month) {
            newDate = data.year + data.month
        }
        if (data.month) {
            const date = getYear(new Date())
            newDate = date + data.month
        }
        setFilterData({
            merchantId: data.merchant,
            yearAndMonth: newDate,
            year: data.year,
            createdDateSort: "DESC",
            reportStageIn: data.status
        })
    }

    useEffect(() => {
        setLoading(true)
        filterData.skip = page * sizePerPage;
        filterData.limit = sizePerPage;
        post(`${apiConstants.BASE_LOCAL}${apiConstants.COD_MERCHANT_REPORT}`, filterData
        ).then((res) => {
            setLoading(false)
            let d = res.data.responseObj;
            const filteredRecords = _.filter(d, record => {
                const fromDate = new Date(record.fromDate);
                const firstMay = new Date('2023-05-01T00:00:00.000+00:00');
                return fromDate > firstMay;
            });
            setData(filteredRecords)
        }).catch(err => {
            setLoading(false)
            console.error(err)
        })
    }, [reload, sizePerPage, page, filterData])

    // const sheets = [
    //     {
    //         name: "COD Remittance",
    //         data: rowCount || [],
    //         columns: [
    //             {
    //                 label: 'Merchant NAme',
    //                 value: (col) => (col?.merchantName)
    //             },
    //             {
    //                 label: 'From Date',
    //                 value: (col) => formatDate(col?.fromDate)
    //             },
    //             {
    //                 label: 'To Date',
    //                 value: (col) => formatDate(col?.toDate)
    //             },
    //             {
    //                 label: 'Total Orders',
    //                 value: (col) => formatNumber(col?.totalOrders)
    //             },
    //             {
    //                 label: 'COD Amount',
    //                 value: (col) => formatCurrency(col?.totalCODAmount)
    //             },
    //             {
    //                 label: 'Status',
    //                 value: (col) => col?.reportStage
    //             },
    //             {
    //                 label: 'Remitted Amount',
    //                 value: (col) => col?.amountRemittedToMerchant ? formatCurrency(col?.amountRemittedToMerchant) : "NA",
    //             },
    //             // {
    //             //     label: 'Payment Detail',
    //             //     value: (col) => (col?.merchantPaymentDetails) 

    //             //   <>{col?.merchantPaymentDetails ? col?.merchantPaymentDetails[0]?.bankName : "" }
    //             /* {col?.merchantPaymentDetails[0]?.amount ? col?.merchantPaymentDetails[0]?.amount : "" }
    //             {col?.merchantPaymentDetails[0]?.paymentMode ? col?.merchantPaymentDetails[0]?.paymentMode : "" }
    //             {col?.merchantPaymentDetails[0]?.utrNo ? col?.merchantPaymentDetails[0]?.utrNo : "" } */
    //             //   </>  
    //             // }
    //             // },


    //         ]
    //     }
    // ]

    useEffect(() => {
        filterData.skip = 0;
        filterData.limit = 10000;
        post(`${apiConstants.BASE_LOCAL}${apiConstants.COD_MERCHANT_REPORT}`,
            filterData
        ).then((res) => {
            setRowCount(res?.data?.responseObj)
        }).catch(err => console.error(err))
    }, [reload, filterData])

    return (
        <div id="main-content" className="container-fluid">
            {(loading) && <Loader />}
            <PageTitle>COD Remittance</PageTitle>
            <div className="row">
                <div className="row">
                    <div className="col-12">
                        <Card>
                            {/* <CardHeader title='COD Remittance' /> */}
                            <CardContent>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                                        <Grid item xs={12} md={2}>
                                            <RHFSelectField width="100%" name="status" label={`Select Status`} options={Status.map((val) => ({
                                                value: val.value,
                                                label: val.label
                                            }))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <RHFSelectField name='month' label='Select Month' width={'100%'} options={Month.map((val) => ({
                                                value: val.value,
                                                label: val.label
                                            }))} />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <RHFTextField name='year' label='Year' variant="filled" />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <LoadingButton
                                                variant="contained"
                                                size="medium"
                                                type="submit"
                                            >Submit</LoadingButton>
                                            <Button onClick={() => {
                                                reset({
                                                    status: '',
                                                    date: '',
                                                })
                                                setFilterData({
                                                    merchantId: merchantId,
                                                    yearAndMonth: null,
                                                    year: null,
                                                    createdDateSort: "DESC",
                                                })
                                                setReload(reload + 1)
                                            }}
                                                variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>
                                            {/* <Typography component='span' sx={{ ml: 1 }}><DownloadExcelMultipleSheets sheets={sheets} filename="COD Remittance" /></Typography> */}


                                        </Grid>

                                    </Grid>
                                </FormProvider>
                                <Grid>
                                    <ProzoDataGrid
                                        columns={columns}
                                        autoHeight={true}
                                        rows={data || []}
                                        sizePerPage={sizePerPage}
                                        setSizePerPage={setSizePerPage}
                                        setPage={setPage}
                                        page={page}
                                        pagination={true}
                                        hideFooterPagination={false}
                                        filterMode={"server"}
                                        rowHeight={120}
                                        rowCount={rowCount?.length || 0}
                                        getRowId={(row) => row.id}
                                    />

                                </Grid>
                            </CardContent>
                        </Card>
                        <MUIModal open={open} finance handleClose={() => setOpen(false)} children={<DialogContentText id="alert-dialog-description">
                            <ProzoDataGrid
                                columns={[{
                                    field: 'bankName',
                                    headerName: 'Bank Name',
                                    width: 180,
                                },
                                {
                                    field: 'amount',
                                    headerName: 'Amount',
                                    width: 180,
                                    renderCell: (row) => formatCurrency(row?.row?.amount)
                                },
                                {
                                    field: 'paymentMode',
                                    headerName: 'Payment Mode',
                                    width: 180,
                                },
                                {
                                    field: 'paymentDate',
                                    headerName: 'Payment Date',
                                    width: 180,
                                    renderCell: (row) => formatDate(row?.row?.paymentDate)
                                },
                                {
                                    field: 'utrNo',
                                    headerName: 'UTR No',
                                    width: 220,
                                },]}
                                autoHeight={true}
                                rows={rows?.merchantPaymentDetails || []}
                                rowCount={rows?.merchantPaymentDetails?.length || 0}
                                getRowId={(row) => Math.random()}
                                hideFooterPagination={true} />
                        </DialogContentText>}
                            title={`Remittance Details`} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withLayout(CODRemittance);