import React from "react";
import { formatNumber } from "../../../helpers/UtilityHelper";
import "./style.css";

const NodeContent = (props) => {
    return (
        <>
            <div className="node-container">
                <div className="node-details">
                    <div className="node-content">
                        <div className="node-info">
                            <div className="node-name">{props.data?.title}</div>
                            <div className="node-role">{formatNumber(props.data.count)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NodeContent;
