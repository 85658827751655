import React from "react";
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { numberPrecision, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { TableSkeleton } from "../../../../common/Skeletons";
import { Box } from "@mui/system";
import _ from 'lodash'
import PercentIcon from '@mui/icons-material/Percent';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import useGetCourier from "../useGetCourier";
const ageingDays = ['0-1 Day', '2-3 Days', '4-7 Days', '5-15 Days', '15+ Days'];

export default function CourierWiseAgeingSplit({ data, title }) {

    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()
    const [tableView, setTableView] = React.useState("perc")

    if (isLoadingCouriers || isFetchingCouriers) return <TableSkeleton rows={10} />

    return (
        <Card elevation={1}>
            <CardHeader title={title} action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setTableView(v)} value={tableView}>
                        <ToggleButton value="perc" title="View Data In %">
                            <PercentIcon />
                        </ToggleButton>
                        <ToggleButton value="count" title="View Data In Count">
                            <LooksOneIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent sx={{ px: 0 }}>
                <TableContainer sx={{ maxHeight: 1200, overflowX: 'auto' }}>
                    <Table size="small" density sx={{ borderCollapse: 'collapse', minWidth: 750 }}>
                        <TableHead sx={{ backgroundColor: '#000', color: '#fff' }}>
                            <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                                <TableCell sx={{
                                    borderRight: '1px solid #ccc',
                                    color: '#fff',
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 2,
                                    backgroundColor: '#000'
                                }}>Name</TableCell>
                                {ageingDays.map((val, j) => (
                                    <TableCell key={j} sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>{val}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, i) => {

                                const obj = _.find(allCouriers, { id: row.name });

                                return (
                                    <TableRow key={i} sx={{ borderBottom: '1px solid #ccc' }}>
                                        <TableCell sx={{
                                            borderRight: '1px solid #ccc',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                            backgroundColor: '#fff',
                                            padding: '6px',
                                        }}>
                                            {`${obj.parent || obj.name}/${row.name}`}
                                        </TableCell>
                                        {ageingDays.map((val) => (
                                            <TableCell key={val} sx={{ borderRight: '1px solid #ccc' }}>
                                                {numberPrecision(_.get(row, `${val}_${tableView}`, 0))}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </CardContent>
        </Card>
    )
}