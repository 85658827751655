import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { userLogin } from "../../redux/actions/userAction";
import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'
import "./login.css";
import 'react-toastify/dist/ReactToastify.css';
import { reset } from '../../redux/reducers/auth/forgotPasswordSlice';
import { resetFormrp } from '../../redux/reducers/auth/resetPasswordSlice';
import { useSnackbar } from 'notistack';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function TOC() {

    return (
        <div className="container-fluid vh-100 p-0">
            <Grid container spacing={2}>
                <Grid item sm={12} lg={12}>
                    {/* <img src="/img/prozo-logo2.svg" className="logo" height="60" alt="logo" style={{marginBottom: 20}} /> */}
                    <object type="application/pdf" standby="Loading..." data="toc.pdf#toolbar=0&navpanes=0&scrollbar=0" style={{height: '100vh', width: '100%'}}></object>
                </Grid>
            </Grid>
        </div>
    );
}