import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { formatNumber } from "../../../../helpers/UtilityHelper";
import _ from 'lodash'
import MaterialTable from "../../../../common/MaterialTable";

export default function PincodeAndCityWise({ data }) {

    const pincodeWise = [
        {
            label: 'Pincode',
            value: 'pincode',
            align: 'left'
        },
        {
            label: 'RTO Count',
            value: (row) => {
                return formatNumber(row.rto)
            },
            numeric: true,
            align: 'right'
        },
    ]

    const cityWise = [
        {
            label: 'City',
            value: 'city',
            align: 'left'
        },
        {
            label: 'RTO Count',
            value: (row) => {
                return formatNumber(row.rto)
            },
            numeric: true,
            align: 'right'
        },
    ]



    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card elevation={1}>
                    <CardHeader title="Cities with most RTO's" />
                    <CardContent sx={{ px: 0 }}>
                        <MaterialTable itemsPerPage={10} minWidth="100%" exportExcel={false} rows={_.orderBy(data?.rtoGroupedByCity || [], 'rto', 'desc')} columns={cityWise} size="small" />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card elevation={1}>
                    <CardHeader title="Pincodes with most RTO's" />
                    <CardContent sx={{ px: 0 }}>
                        <MaterialTable itemsPerPage={10} minWidth="100%" exportExcel={false} rows={_.orderBy(data?.rtoGroupedByPincode || [], 'rto', 'desc')} columns={pincodeWise} size="small" />
                    </CardContent>
                </Card>
            </Grid>

        </Grid>
    )
}