import React, { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { getToken } from '../../../helpers/ReduxHelpers';
import { get, apiConstants, patch } from '../../../common/constants';
import './onboarding.css';
import { FormControl, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFSelectField, RHFTextField } from '../../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


export default function BankDetails() {
    const [formLoading, setFormLoading] = useState(false);
    const [reload, setReload] = useState(1);

    const { enqueueSnackbar } = useSnackbar()

    const methodBillingDetails = useForm({
        resolver: yupResolver(Yup.object().shape({
            beneficiaryName: Yup.string().required("Name is required"),
            accountNumber: Yup.string().required("Account Number is required"),
            bankName: Yup.string().required("Bank Name is required"),
            accountType: Yup.string().required("Account type is required"),
            ifscCode: Yup.string().required("IFSC Code is required"),
        })),
        defaultValues: {
            beneficiaryName: "",
            accountNumber: "",
            bankName: "",
            accountType: "saving",
            ifscCode: ""
        }
    })
    const { handleSubmit: handleSubmitBankDetails, setValue: setValueBankDetails } = methodBillingDetails

    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";

    useEffect(() => {
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT}/${merchantId}`).then(response => {
            setValueBankDetails('beneficiaryName', response.data?.bank_details?.beneficiaryName)
            setValueBankDetails('accountNumber', response.data?.bank_details?.accountNumber)
            setValueBankDetails('bankName', response.data?.bank_details?.bankName)
            setValueBankDetails('accountType', response.data?.bank_details?.accountType?.toLowerCase())
            setValueBankDetails('ifscCode', response.data?.bank_details?.ifscCode)            
        })
            .catch(err => { console.error(err); });
    }, [reload]);

    const handleSubmit = (data) => {
        setFormLoading(true);
        patch(`${apiConstants.BASE_LOCAL}${apiConstants.UPDATE_MERCHANT}`, data)
            .then(_response => {
                setFormLoading(false);
                setReload(r => r + 1)
                enqueueSnackbar("Bank details updated", { variant: 'success' })
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar("Bank details could not be updated", { variant: 'error' })
                setFormLoading(false);
            });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl component="fieldset" sx={{ width: 1, p: 2 }}>
                    <FormProvider methods={methodBillingDetails} onSubmit={handleSubmitBankDetails(handleSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="beneficiaryName" label="Name" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="accountNumber" label="Account Number" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="bankName" label="Bank Name" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFSelectField options={[{
                                    label: 'Saving Account',
                                    value: 'saving'
                                }, {
                                    label: 'Current Account',
                                    value: 'current'
                                }]} name="accountType" label="Account Type" variant="filled" width="100%" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField name="ifscCode" label="IFSC Code" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LoadingButton type="submit" loading={formLoading} size="medium" variant="contained">Save Bank Details</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </FormControl>
            </Grid>
        </Grid>
    );
}