import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { getToken } from '../../helpers/ReduxHelpers';
import { apiConstants, getGeneric, postGeneric } from '../../common/constants';
import { useForm } from "react-hook-form";
import { Button, Card, CardContent, FormControlLabel, FormGroup, Grid, Switch, Tooltip, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import Loader from "../../common/Loader"
import { useSnackbar } from "notistack";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import ConfirmDialog from "../../common/ConfirmDialog";

const LostAndDamageEmail = () => {
    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [weightDiscrepancyConfig, setWDConfig] = useState();
    const [isLNDEnabled, setLNDEnabled] = useState(false);
    const [reload, setReload] = useState(0);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [switchConfirm, setSwitchConfirm] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);


    const columns = [
        {
            field: 'emails',
            headerAlign: "left",
            align: 'left',
            hideSortIcons: true,
            headerName: 'Emails',
            flex: 1.2,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 15 }}>
                        {row?.email}
                    </div>
                )
            },
        },
        {
            field: 'action',
            headerAlign: "left",
            align: 'left',
            hideSortIcons: true,
            headerName: 'Action',
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Button
                        onClick={() => handleCancelOrder(row)}
                        disabled={!isLNDEnabled}
                        color="error"
                        variant="contained"
                        size="small"
                    >
                        Delete
                    </Button>
                );
            },
        },
    ];

    const emailListRegExp = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}\s*,\s*)*[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}$/

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                email: Yup.string()
                    .matches(emailListRegExp, { message: 'Invalid email format', excludeEmptyString: true })
                    .required("Email is required!"),
            })
        ),
        defaultValues: {
            email: '',
        }
    })
    const { handleSubmit: handleSubmitForm, reset } = methods

    const postMerchantConfig = (payload) => {
        postGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}/lndConfig`, payload).then(response => {
            enqueueSnackbar('Success', { variant: 'success' })
            reset()
            setReload(reload => reload + 1)
            setLoading(false)
        })
            .catch((err) => {
                console.error(err)
                enqueueSnackbar('Some error occurred', { variant: 'error' })
                setLoading(false)
            })
    }


    const handleCancelOrder = (data) => {
        setDeleteData(data)
        setDeleteConfirm(true)
    }
    const handleDelete = () => {
        const index = weightDiscrepancyConfig?.lostDamageConfig?.emails.indexOf(deleteData.email);
        if (index !== -1) {
            weightDiscrepancyConfig?.lostDamageConfig?.emails.splice(index, 1);
        }
        const payload = {
            "enableWA": false,
            "enableSMS": false,
            "mobileNos": [],
            "enableEmail": true,
            "emails": [...weightDiscrepancyConfig?.lostDamageConfig?.emails]
        }
        postMerchantConfig(payload)
    }


    const onSubmit = (data) => {
        setLoading(true);
        const existingEmails = weightDiscrepancyConfig?.lostDamageConfig?.emails ?? [];
        const emailsArray = data.email.split(',').map(email => email.trim());
        const payload = {
            "enableWA": false,
            "enableSMS": false,
            "mobileNos": [],
            "enableEmail": true,
            "emails": [...existingEmails, ...emailsArray]
        }
        postMerchantConfig(payload)
    }

    useEffect(() => {
        setLoading(true)
        getGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}`).then((res) => {
            setWDConfig(res.data);
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }, [merchantId, reload])


    const toggleEmail = (e) => {
        let payload = {
            "enableWA": false,
            "enableSMS": false,
            "mobileNos": [],
            "enableEmail": !weightDiscrepancyConfig?.lostDamageConfig?.enableEmail,
            "emails": weightDiscrepancyConfig?.lostDamageConfig?.emails
        }
        postMerchantConfig(payload)
    }
    useEffect(() => {
        setLNDEnabled(weightDiscrepancyConfig?.lostDamageConfig?.enableEmail)
    }, [weightDiscrepancyConfig?.lostDamageConfig?.enableEmail])

    const handleSwitchConfirmation = (e) => {
        setSwitchConfirm(true);
    };
    const handleSwitchAction = () => {
        toggleEmail()
    };
    return (
        <>
            {(isLoading) && <Loader />}
            <ConfirmDialog
                title=''
                open={deleteConfirm}
                setOpen={setDeleteConfirm}
                disableButtons={weightDiscrepancyConfig?.lostDamageConfig?.emails?.length === 1}
                onConfirm={() => handleDelete()}
            >
                {weightDiscrepancyConfig?.lostDamageConfig?.emails?.length === 1 ? "Please disable via Toggle Switch" : "Are you sure you want to delete ?"}
            </ConfirmDialog>
            <ConfirmDialog
                title=''
                open={switchConfirm}
                setOpen={setSwitchConfirm}
                onConfirm={() => handleSwitchAction()}
            >
                Are you sure you want to change the email configuration?
            </ConfirmDialog>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmitForm(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <RHFTextField color="warning"
                                            name="email"
                                            label="Add Emails *"
                                            size="medium"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LoadingButton loading={isLoading}
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            size="small"
                                        >Add</LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Tooltip title={isLNDEnabled ? "Disable L&D Emails" : "Enable L&D Emails"}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={isLNDEnabled}
                                                            onChange={(e) => handleSwitchConfirmation(e)}
                                                        // disabled={!merchantConfig?.codRemittanceCommunication?.emails?.length > 0}
                                                        />} label={<Typography color='error' variant="subtitle2">{`Use toggle to ${isLNDEnabled ? 'Disable' : 'Enable'} emails`}</Typography>}
                                                    labelPlacement="bottom"
                                                />
                                            </FormGroup>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={(weightDiscrepancyConfig && weightDiscrepancyConfig?.lostDamageConfig?.emails?.length > 0)
                                ? weightDiscrepancyConfig?.lostDamageConfig?.emails.map((email, index) => ({ id: index, email }))
                                : []
                            }
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            paginationMode='client'
                            pagination={true}
                            rowHeight={120}
                            rowCount={weightDiscrepancyConfig?.lostDamageConfig?.emails?.length || 0}
                            // hideCountNumber={true}
                            getRowId={(row) => row.id}
                        />
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};

export default LostAndDamageEmail;
