import * as React from "react"
import { suffix, formatDate, formatNumber, monthColors } from "../../../helpers/UtilityHelper";
import DashboardSkeleton from '../DashboardSkeleton'
import ExcelDownloadButton from "../../../common/ExcelDownloadButton";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/InfoOutlined';

export default function MonthStatusWiseSplit({ isLoading, data, selectedDates }) {

    const __tableRef = React.useRef(null)

    if (isLoading) {
        return <DashboardSkeleton />
    }

    const heading = 'Shipment Status Split';

    return (
        <div className="customScroll" style={{ width: '100%' }}>
            <table ref={__tableRef} className="data-table table-cell-col">
                <thead>
                    <tr>
                        <td
                            style={{ fontSize: 14, background: '#fff' }}
                            colSpan={(selectedDates.length + 1) * 2 + 1}
                        >
                            {heading}
                            <ExcelDownloadButton sheetName={heading} fileName={heading} ele={__tableRef} />
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2} style={{ width: 200 }}>Courier</td>
                        {selectedDates.map((date, i) => (
                            <td align="center" style={{
                                backgroundColor: monthColors[i]
                            }} colSpan={2} key={i}>{formatDate(date, 'MMM yy')}</td>
                        ))}
                        <td align="right" style={{
                            backgroundColor: monthColors[selectedDates.length]
                        }} colSpan={2}>Total</td>
                    </tr>
                    <tr>
                        {selectedDates.map((_d, i) => (
                            <React.Fragment key={i}>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>Count</td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[i]
                                }}>%</td>
                            </React.Fragment>
                        ))}

                        <td align="right" style={{
                            backgroundColor: monthColors[selectedDates.length]
                        }}>Count</td>
                        <td align="right" style={{
                            backgroundColor: monthColors[selectedDates.length]
                        }}>%</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, i) => (
                            <tr key={i}>
                                <td style={{ width: 200 }}>
                                    {row.name}
                                    {(row.name !== 'Grand Total') && (
                                        <Tooltip title={row?.comment?.join(' + ')}>
                                            <InfoIcon fontSize="10px" sx={{ ml: '5px', cursor: 'pointer' }} />
                                        </Tooltip>
                                    )}
                                </td>
                                {selectedDates.map((_d, i) => (
                                    <React.Fragment key={i}>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>
                                            {formatNumber(row[`${formatDate(_d, 'MMM_yy')}_totalCount`])}
                                        </td>
                                        <td align="right" style={{
                                            backgroundColor: monthColors[i]
                                        }}>
                                            {suffix(row[`${formatDate(_d, 'MMM_yy')}_percentage`], { postfix: '%' })}
                                        </td>
                                    </React.Fragment>
                                ))}
                                <td align="right" style={{
                                    backgroundColor: monthColors[selectedDates.length]
                                }}>
                                    {formatNumber(row.totalCount)}
                                </td>
                                <td align="right" style={{
                                    backgroundColor: monthColors[selectedDates.length]
                                }}>
                                    {suffix(row.totalPercentage, { postfix: '%' })}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}