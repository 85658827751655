import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { userForgotPassword } from "../../redux/actions/auth/forgotPasswordAction";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import "./login.css";
import { LoadingButton } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import { FormProvider, RHFTextField } from "../../common/hook-form"
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from 'yup'
import { Password } from '@mui/icons-material';
import { useSnackbar } from "notistack"

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { fpInfo, error } = useSelector((state) => state.forgotPasswordSlice);
    const navigate = useNavigate()
    const [isLoading, setIoading] = React.useState(false)

    const searchParams = new URLSearchParams(useLocation().search)
    const username = searchParams.get('username') || ""

    // redirect authenticated user to profile screen
    useEffect(() => {
        if (Object.keys(fpInfo).length !== 0) {
            navigate('/reset-password');
        }
        else if (error) {
            console.log("error", error);
            enqueueSnackbar(error || "Something went wrong", { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, fpInfo, error]);


    const onSubmit = async (data) => {
        setIoading(true)
        await dispatch(userForgotPassword(data))
        setIoading(false)
    };
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                username: Yup.string().required("Please enter a valid email address"),
            })
        ),
        defaultValues: {
            username: username,
        }
    })
    const { handleSubmit } = methods
    //console.log("loginData", loginData);
    return (
        <>
            <div id="login-wrapper" className="container-fluid vh-100">
                <div className="row h-100 d-none d-md-block">
                    <div className="col-12 col-md-5 col-lg-4"><img src="img/prozo-logo2.svg" className="logo" height="100" alt="" />
                        <h5 style={{ marginLeft: "95px" }}>Shipping made easy</h5>
                        {/* <ul className="">
                            <li>Decrease your return to origin (RTO) for unsuccessful deliveries.</li>
                            <li>Manage non-delivery reports (NDR) seamlessly. </li>
                            <li>Choose the right courier partner for each shipment taking into account all the necessary data points.</li>
                        </ul> */}
                    </div>
                    <div className="col-12 col-lg-5 position-relative"> </div>
                    <div className="clearfix"></div>
                </div>
                <div className="card card-reset card-login shadow-none border-0 p-0 bg-transparent">
                    <div className="card-body">
                        <img src="/img/prozo-logo2.svg" className="d-md-none d-lg-none m-auto mb-5" height="50" alt="proship-logo" />
                        <h1>Forgot Password</h1>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <RHFTextField name="username" label="Email Address" size="large" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ width: '100%', display: 'flex', my: 1 }}>
                                        <LoadingButton loading={isLoading} type="submit" startIcon={<Password />} size="large" color="warning" variant="contained">Send OTP</LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </div>
                </div>

            </div>
        </>

    );
}