/** @format */

import { hasPageAccess, isAdmin } from "../helpers/ReduxHelpers";
import InsightsIcon from "@mui/icons-material/Insights";
import {
  AccountCircleOutlined,
  AddchartOutlined,
  ConstructionOutlined,
  FireTruckOutlined,
  HomeOutlined,
  LocalGroceryStoreOutlined,
  LocalShippingOutlined,
  ScaleOutlined,
  SettingsOutlined,
  SummarizeOutlined,
  SupportAgentOutlined,
  TaxiAlertOutlined,
  WalletOutlined,
} from "@mui/icons-material";
import BusAlertIcon from '@mui/icons-material/BusAlert';
import HistoryIcon from '@mui/icons-material/History';

export function getMenuWithConfig(VerifiedRole) {
  const menuConfigs = [
    {
      pathname: "/proship-pilot",
      visibility:
        VerifiedRole.includes("ROLE_PROSHIPADMIN") === false &&
        (isAdmin() || hasPageAccess("/Performance")),
      icon: <InsightsIcon />,
      label: "ProPilot",
    },
    {
      pathname: "/",
      visibility:
        VerifiedRole.includes("ROLE_PROSHIPADMIN") === false &&
        (isAdmin() || hasPageAccess("/")),
      icon: <HomeOutlined />,
      label: "Home",
      id: "menu-dashboard",
    },
    {
      pathname: "/Performance",
      visibility:
        VerifiedRole.includes("ROLE_PROSHIPADMIN") === false &&
        (isAdmin() || hasPageAccess("/Performance")),
      icon: <AddchartOutlined />,
      label: "Performance",
    },
    {
      pathname: "/control-tower",
      visibility: isAdmin() || hasPageAccess("/control-tower"),
      icon: <InsightsIcon />,
      label: "Control Tower",
    },
    {
      pathname: "/orders",
      visibility: isAdmin() || hasPageAccess("/orders"),
      icon: <LocalShippingOutlined />,
      label: "Shipments",
      id: "menu-shipment",
      menuItems: [
        { pathname: "/orders", label: "Forward Shipments" },
        // { pathname: "/reverse-orders", label: "Reverse Shipments" },
        // { pathname: "/return-orders", label: "Return Order Requests" },
        { pathname: "/create-order", label: "Create Forward Shipment" },
        { pathname: "/create-order-rvp", label: "Create Reverse Shipment" },
        { pathname: "/bulk-order-create", label: "Create Bulk Shipments" },
        { pathname: "/download-labels", label: "Bulk Download Labels" },
        { pathname: "/download-invoice", label: "Bulk Download Invoice" },
        { pathname: "/stuck-orders", label: "Stuck Shipments" },
        { pathname: "/pending-pickups", label: "Manifest" },
        { pathname: "/bulk-tracking", label: "Bulk Tracking" },
      ],
    },
    {
      pathname: "/b2b-orders",
      visibility: isAdmin() || hasPageAccess("/b2b-orders"),
      icon: <FireTruckOutlined />,
      label: "PTL Shipments",
      menuItems: [
        { pathname: "/b2b-orders", label: "PTL Shipments" },
        { pathname: "/rate-calculator-ptl", label: "Rate Calculator (PTL)" },
        { pathname: "/create-b2b-orders", label: "Create PTL Shipment" },
        {
          pathname: "/upload-b2b-orders",
          label: "Create Bulk PTL Shipments",
        },
        { pathname: "/ptl-pickup-requests", label: "Pickup Requests" },
        { pathname: "/b2b_tracking_upload", label: "Upload For Tracking" },
        {
          pathname: '/ptl-appointment-deliveries',
          label: 'Appointment Deliveries'

        }
      ],
    },
    {
      pathname: "/returns",
      visibility: isAdmin() || hasPageAccess("/orders"),
      icon: <HistoryIcon />,
      label: "RVP",
      menuItems: [
        { pathname: "/reverse-orders", label: "Reverse Shipments" },
        { pathname: "/return-orders", label: "Return Order Requests" },
      ],
    },
    {
      pathname: "/channel-orders",
      visibility: isAdmin() || hasPageAccess("/channel-orders"),
      icon: <LocalGroceryStoreOutlined />,
      label: "Channel Orders",
    },
    {
      pathname: "/ndr",
      visibility: isAdmin() || hasPageAccess("/ndr"),
      icon: <TaxiAlertOutlined />,
      label: "NDR",
      id: "menu-ndr",
    },
    {
      pathname: "/npr",
      visibility: isAdmin() || hasPageAccess("/ndr"),
      icon: <BusAlertIcon />,
      label: "NPR",
      id: "menu-npr",
    },
    {
      pathname: "/weight-discrepancy",
      visibility: isAdmin() || hasPageAccess("/weight-discrepancy"),
      icon: <ScaleOutlined />,
      label: "Weight Discrepancy",
      id: "menu-wd",
    },
    {
      pathname: "/serviceable-pincodes",
      visibility: isAdmin() || hasPageAccess("/serviceable-pincodes"),
      icon: <ConstructionOutlined />,
      label: "Tools",
      id: "menu-tools",
      menuItems: [
        { pathname: "/serviceable-pincodes", label: "Check Serviceability" },
        { pathname: "/rate-calculator", label: "Rate Calculator" },
        { pathname: "/rate-calculator-ptl", label: "Rate Calculator (PTL)" },
        { pathname: "/merchant-rules", label: "Allocation Rules" },
        { pathname: "/zone-mapping", label: "Download Serviceability" },
      ],
    },
    {
      pathname: "/wallet",
      visibility: isAdmin() || hasPageAccess("/wallet"),
      icon: <WalletOutlined />,
      label: "Finance",
      id: "menu-wallet",
      menuItems: [
        { pathname: "/wallet", label: "Wallet" },
        { pathname: "/cod-remittance", label: "COD Remittance" },
        { pathname: "/merchant-invoice", label: "Merchant Invoice" },
      ],
    },
    {
      pathname: "/profile",
      visibility: isAdmin(),
      icon: <AccountCircleOutlined />,
      label: "Profile",
    },
    {
      pathname: "/setting",
      visibility: isAdmin() || hasPageAccess("/setting"),
      icon: <SettingsOutlined />,
      label: "Settings",
    },
    {
      pathname: "/reports",
      visibility: isAdmin() || hasPageAccess("/reports"),
      icon: <SummarizeOutlined />,
      label: "Reports",
    },
    {
      pathname: "/escalation_management",
      visibility: isAdmin() || hasPageAccess("/escalation_management"),
      icon: <SupportAgentOutlined />,
      label: "Support",
    },
  ];
  return menuConfigs;
}
