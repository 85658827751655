import { createApi } from "@reduxjs/toolkit/query/react";
import { apiConstants } from "../common/constants";
import baseQuery from "./baseQuery";
import getShipmentsByParallelRequests from "./queries/parallel_shipments";
import getOrdersByDateRange, { getAllIntransitOrders, getAllNotPickedOrders, getAwbRegistedBuyDateRange, getDeliveredBuyDateRange, getRTODliveredByDateRange } from "./queries/get_shipments_by_date_range";

const excludes =
  "merchant,courier,pickupDetails.from_name,pickupDetails.from_phone_number,pickupDetails.from_address,pickupDetails.from_country,pickupDetails.from_email,pickupDetails.gstin,orderId,skipRule,giftwrap_charge,transactionCharge,clientOrderId,overriddenCourierRule,actualCourierProviderName,giftwrapCharge,overriddenCourierRule,transactionCharge,skipRule,createdDate,lastModifiedDate,createdByUser,modifiedByUser,merchantId,skipRule,version,overriddenCourierRule,dispatchMode,channelName,invoiceNumber,transactionCharge,giftwrapCharge,securityKey,invoiceNumber,channelType,invoiceUrl,labelUrl,referenceNumber,securityKey,sortCode,mongoInvoiceSignedUrlDetails,mongoLabelSignedUrlDetails,shipmentDetail,customerDetail,merchant,orderManifest";

export const commonRTK = createApi({
  reducerPath: "commonRTK",
  baseQuery: baseQuery,

  // cache expiry time in seconds
  keepUnusedDataFor: 2 * 30 * 60,
  endpoints: (builder) => ({
    getRTODashboard: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_BY_STATUS_CUSTOM, data }),
    }),
    getOrderItemWeight: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_ITEM_WEIGHT, data }),
    }),
    getOrdersGroupByStates: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_BY_STATE, data }),
    }),
    getOrdersGroupByZone: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_ZONE, data }),
    }),
    getOrdersGroupByPaymentMode: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_BY_PAYMENT_MODE, data }),
    }),
    getOrdersGroupByPickupPincodes: builder.query({
      query: (data) => ({
        url: apiConstants.AGG_ORDER_BY_PICKUP_PINCODE,
        data,
      }),
    }),
    getOrdersGroupByStatus: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_BY_STATUS, data }),
    }),
    getNDROrdersGroupByStatus: builder.query({
      query: (data) => ({ url: apiConstants.AGGREGATE_NDR_STATUS, data }),
    }),
    getOrdersGroupByCourier: builder.query({
      query: (data) => ({
        url: apiConstants.AGG_ORDERS_DAY_WISE_COURIER,
        data,
      }),
    }),
    getShipmentsData: builder.query({
      query: (data) => ({
        url: apiConstants.ORDER_LISTS,
        data: {
          excludes: excludes,
          andfilter: [
            { field: "awbRegisteredDate", operator: "gte", value: data.from_date },
            { field: "awbRegisteredDate", operator: "lte", value: data.to_date },
            { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
            ...(data?.filters || []),
          ],
          offset: 0,
          limit: 15000,
        },
        type: "POST",
      }),
    }),
    getRTOGroupedByCourier: builder.query({
      query: (data) => ({ url: apiConstants.AGG_RTO_BY_COURIER, data }),
    }),
    getOrdersByStatusCode: builder.query({
      query: (data) => ({ url: apiConstants.AGG_ORDER_BY_STATUS_CUSTOM, data }),
    }),
    getMerchant: builder.query({
      query: ({ id }) => ({ url: `${apiConstants.GET_MERCHANT}/${id}` }),
    }),
    getMerchantDetails: builder.query({
      query: ({ id }) => ({ url: `${apiConstants.MERCHANT_DETAILS}/${id}` }),
    }),
    getAllReportTypes: builder.query({
      query: (data) => ({ url: apiConstants.GET_ALL_REPORT_TYPES, data }),
      transformResponse: (response) => {
        return response.responseObj;
      },
    }),
    getShipmentsInParallel: builder.query({
      queryFn: getShipmentsByParallelRequests,
    }),
    getPaginatedShipments: builder.query({
      query: (data) => ({ url: apiConstants.ORDER_LISTS, data, type: "POST" }),
    }),
    getShipmentDetail: builder.query({
      query: (data) => ({ url: apiConstants.ORDER_LISTS, data, type: "POST" }),
    }),
    getManifestSearch: builder.query({
      query: (data) => ({ url: apiConstants.MANIFEST_LISTS, data, type: "POST" }),
    }),
    getAllCourierPartners: builder.query({
      query: (data) => ({ url: apiConstants.COURIER_LIST, data, type: "POST" }),
      transformResponse: (res) => res.data
    }),
    getChannelOrder: builder.query({
      query: (data) => ({
        url: apiConstants.CHANNEL_ORDER_SEARCh,
        data,
        type: "POST",
      }),
    }),
    getManifestOrders: builder.query({
      query: (data) => ({
        url: apiConstants.MANIFEST_SEARCH_ORDERS,
        data,
        type: "POST",
      }),
    }),
    getNDRData: builder.query({
      query: (data) => ({
        url: apiConstants.NDR_LISTS,
        data,
        type: "POST",
      }),
    }),
    getNPRData: builder.query({
      query: (data) => ({
        url: apiConstants.NPR_LISTS,
        data,
        type: "POST",
      }),
    }),
    getForwardDelay: builder.query({
      query: (data) => {
        return {
          url: apiConstants.CONTROL_TOWER_DELIVERY_PENDING,
          data: {
            merchant_list: data.merchant,
            shipmentType_list: data.shipment_type,
            orderType_list: data.order_type
          },
          type: "GET",
        };
      },
    }),
    getLost: builder.query({
      query: (data) => {
        return {
          url: apiConstants.CONTROL_TOWER_LOST,
          data: {
            merchant_list: data.merchant,
            shipmentType_list: data.shipment_type,
            from_date: data.from_date,
            to_date: data.to_date,
          },
          type: "GET",
        };
      },
    }),
    getRtoDelay: builder.query({
      query: (data) => {
        return {
          url: apiConstants.CONTROL_TOWER_RTO_PENDING,
          data: {
            merchant_list: data.merchant,
            shipmentType_list: data.shipment_type,
            orderType_list: data.order_type
          },
          type: "GET",
        };
      },
    }),
    getPickUpDelay: builder.query({
      query: (data) => {
        return {
          url: apiConstants.CONTROL_TOWER_PICKUP_PENDING,
          data: {
            merchant_list: data.merchant,
            shipmentType_list: data.shipment_type,
            orderType_list: data.order_type
          },
          type: "GET",
        };
      },
    }),
    getAllAwbRegisteredByDateRange: builder.query({
      queryFn: getAwbRegistedBuyDateRange
    }),
    getOrderTransactionByDateRange: builder.query({
      query: (data) => ({ url: apiConstants.ORDER_TRANSACTION, data }),
    }),
    getAllDeliveredOrdersByDateRange: builder.query({
      queryFn: getDeliveredBuyDateRange
    }),
    getAllRTODeliveredOrdersByDateRange: builder.query({
      queryFn: getRTODliveredByDateRange
    }),
    getAllInTransitOrders: builder.query({
      queryFn: getAllIntransitOrders
    }),
    getAllNotPickedOrders: builder.query({
      queryFn: getAllNotPickedOrders
    }),
    getOrdersByMultipleDates: builder.query({
      queryFn: getOrdersByDateRange
    }),
    genericPostRequest: builder.query({
      query: (data) => ({ ...data })
    }),
    getPtlPur: builder.query({
      query: (data) => ({ url: apiConstants.PTL_PUR_SEARCH, data, type: "POST" }),
      transformResponse: (res) => res.data
    }),
    getSearchMerchant: builder.query({
      query: (data) => ({ url: apiConstants.MERCHANT_SEARCH, data, type: "POST" }),
      transformResponse: (res) => res.data
    }),
    getAllOrders: builder.query({
      query: (data) => ({ url: apiConstants.ORDER_LISTS, data,type: "POST" }),
      transformResponse: (res) => res
    }),
  }),
});

export const {
  useGetRTODashboardQuery,
  useGetOrderItemWeightQuery,
  useGetOrdersGroupByStatesQuery,
  useGetOrdersGroupByPaymentModeQuery,
  useGetOrdersGroupByPickupPincodesQuery,
  useGetOrdersGroupByStatusQuery,
  useGetOrdersGroupByZoneQuery,
  useGetShipmentsDataQuery,
  useGetOrdersGroupByCourierQuery,
  useGetRTOGroupedByCourierQuery,
  useGetOrdersByStatusCodeQuery,
  useGetMerchantQuery,
  useGetAllReportTypesQuery,
  useGetShipmentsInParallelQuery,
  useLazyGetPaginatedShipmentsQuery,
  useGetPaginatedShipmentsQuery,
  useGetShipmentDetailQuery,
  useLazyGetManifestSearchQuery,
  useLazyGetShipmentDetailQuery,
  useLazyGetChannelOrderQuery,
  useGetChannelOrderQuery,
  useGetForwardDelayQuery,
  useGetPickUpDelayQuery,
  useGetRtoDelayQuery,
  useGetOrderTransactionByDateRangeQuery,
  useGetAllAwbRegisteredByDateRangeQuery,
  useGetAllDeliveredOrdersByDateRangeQuery,
  useGetAllRTODeliveredOrdersByDateRangeQuery,
  useGetAllInTransitOrdersQuery,
  useGetAllNotPickedOrdersQuery,
  useGetOrdersByMultipleDatesQuery,
  useGetManifestOrdersQuery,
  useGetAllCourierPartnersQuery,
  useGetNDROrdersGroupByStatusQuery,
  useGetNDRDataQuery,
  useGetNPRDataQuery,
  useGenericPostRequestQuery,
  useGetPtlPurQuery,
  useGetSearchMerchantQuery,
  useGetLostQuery,
  useGetAllOrdersQuery,
  useGetMerchantDetailsQuery
} = commonRTK;
