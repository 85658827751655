// @/src/components/Table/index.jsx
import React from "react";
import withLayout from "../../hocs/Layout";
import { Link } from "react-router-dom";
import './setting.css';
import PageTitle from "../../common/PageTitle";
import { useSelector } from "react-redux";

const Setting = () => {
    const { userInfo } = useSelector((state) => state.user);

    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>Settings</PageTitle>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/company.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">Profile</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <Link to={"/profile"} className="btn btn-link px-0">Company Profile</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/address.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">Locations</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <Link to={"/merchant-step2"} className="btn btn-link px-0">Pickup Locations</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/address.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">Locations</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <Link to={"/delivery-location"} className="btn btn-link px-0">Delivery Locations</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/notifications.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">Notifications</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <Link to={"/notification"} className="btn btn-link px-0">Seller notifications</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/accounts.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">Users</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <Link to={"/merchant-step3"} className="btn btn-link px-0">Manage users</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/api.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">API</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <ul className="list-unstyled m-0">
                                            {/* <li><a href="/configue-webhook" className="btn btn-link px-0">Configure Webhooks</a></li> */}
                                            <li><a href="/view-webhook" className="btn btn-link px-0">View Webhooks</a></li>
                                            <li><a href="https://documenter.getpostman.com/view/27749698/2s93mBwyZ1" target="_blank" rel="noreferrer" className="btn btn-link px-0">API Documentation</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card card-settings m-0">
                            <div className="card-body p-0 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-shrink-0 text-center"> <img src="img/email.svg" alt="img-icon" />
                                        <p className="mt-2 mb-0 text-center">Configurations</p>
                                    </div>
                                    <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                        <Link to={"/email-config"} className="btn btn-link px-0">Email Configurations</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {!userInfo?.merchant?.isPTLMerchant &&
                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="card card-settings m-0">
                                <div className="card-body p-0 d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="flex-shrink-0 text-center"> <img src="img/api.svg" alt="img-icon" />
                                            <p className="mt-2 mb-0 text-center">Configurations</p>
                                        </div>
                                        <div className="flex-grow-1 ms-4 ms-md-3 ms-lg-4 ms-xl-5">
                                            <ul className="list-unstyled m-0">
                                                <li><a href="/ptl-phone-config" className="btn btn-link px-0">Phone Configurations</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div>

        </>
    );
};

export default withLayout(Setting);