import React from 'react';
import { useSelector } from 'react-redux';
import withLayout from '../../../hocs/Layout';
import TrackBox from './trackBox';
import TrackingListitem from './trackingListItem';
// import { resetTrackingList } from '../../../redux/reducers/trackingList/trackingListSlice';
// import { useNavigate } from 'react-router-dom'
import PageTitle from '../../../common/PageTitle';
const ListTracking = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate()
  const { trackingListInfo } = useSelector((state) => state.trackingListSlice);
  // const handleBack = () => {
  //   dispatch(resetTrackingList());
  //   navigate('/');
  // }
  return (
    <>
      <div id="main-content" className="container-fluid">
        <PageTitle>Shipment Tracking</PageTitle>
        <TrackBox />
        <div className="row">
          <div className="col-12">
            <TrackingListitem trackingListInfo={trackingListInfo} />

          </div>
        </div>
      </div>
    </>
  );
};

export default withLayout(ListTracking);
