import * as React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  LinearProgress,
  Box,
  Button,
} from "@mui/material";
import _ from "lodash";
import { VerticalBarChart } from "../charts/ColumnChart";
import { ScatterChart } from "../charts/ProzoScatterChart";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../helpers/UtilityHelper";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import SingleProgress from "../charts/SingleProgress";
import { Stack } from "@mui/system";
import OrderItemsModal from "../../common/OrderItemsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { apiConstants, post } from "../../common/constants";

export default function ForwardDelayed({ data }) {
  const delayedAgingChartData = _(data)
    .groupBy(function (item) {
      if (item.deliveryBreachedAgeingDays >= 7) return "7+";
      return `${item.deliveryBreachedAgeingDays}`;
    })
    .map(function (items, key) {
      return { name: key, value: items.length };
    })
    .orderBy("name", "asc")
    .value();

  const noOfAttemptsChart = _(data)
    .groupBy(function (item) {
      return item.deliveryAttemptCount;
    })
    .map(function (items, key) {
      const totalItems = data.length;
      const itemsGrouped = items.length;
      return {
        name: key,
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();

  const courierZoneWiseData = _(data)
    .groupBy("merchantZone")
    .map(function (items, key) {
      const groupedByCourier = _.groupBy(items, "courierName");
      let chartData = [];
      for (const kk of Object.keys(groupedByCourier)) {
        chartData.push({ courier: kk, value: groupedByCourier[kk].length });
      }
      return {
        zone: key === "REGIONAL" ? "WITHIN STATE" : key,
        data: chartData,
      };
    })
    .value();

  const paymentModeSplit = _(data)
    .groupBy("paymentMode")
    .map(function (items, key) {
      const totalItems = data.length;
      const itemsGrouped = items.length;
      return {
        name: key,
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();

  const courierWiseSplit = _(data)
    .groupBy("courierName")
    .map(function (items, key) {
      const totalItems = data.length;
      const itemsGrouped = items.length;
      return {
        name: key.replaceAll('Forward','').replaceAll('_',' ').replaceAll('FORWARD','').replaceAll('-',''),
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();

  const chartOptions = {
    xaxis: {
      label: "Courier",
      dataKey: "courier",
    },
    yaxis: {
      label: "Shipments",
      dataKey: "value",
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <Card>
          <CardHeader
            title="Delayed Shipments Ageing"
            subheader="Ageing of delayed shipments. A shipment is considered to be marked as delayed if current date is greater than pickup date + SLA. If pickup date is not available for any reason, order date is considered as the pickup date."
          />
          <CardContent>
            <VerticalBarChart
              height={362}
              data={delayedAgingChartData}
              chartOptions={{
                yaxis: "name",
                yLabel: "Ageing (in Days)",
                xLabel: "Number of Shipments",
                data: [
                  {
                    dataKey: "value",
                    label: "Shipments",
                  },
                ],
              }}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={7}>
        <Stack spacing={1}>
          <Card>
            <CardHeader title="Number of Delivery Attempts" />
            <CardContent>
              <SingleProgress data={noOfAttemptsChart} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Payment Mode of Delayed Shipments" />
            <CardContent>
              <SingleProgress data={paymentModeSplit} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Courier Partners for Delayed Shipments" />
            <CardContent>
              <SingleProgress data={courierWiseSplit} />
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12}>
        <TableData
          data={data}
          title="Delayed Shipments"
          subheader="The table below displays all shipments in IN TRANSIT, FAILED DELIVERY, OUT FOR DELIVERY, SHIPMENT DELAYED, CONTACT CUSTOMER CARE,  SHIPMENT_HELD, PICKED_UP, PICKUP PENDING, ORDER PLACED, OUT FOR PICKUP and PICKUP FAILED status(es) and have breached the agreed delivery SLA. A shipment is considered to be marked as delayed if current date is greater than pickup date + SLA. If pickup date is not available for any reason, order date is considered as the pickup date."
        />
      </Grid>

      {/* <Grid item xs={12} md={12}>
        <TableData
          data={_.filter(data, { needAttention: true })}
          title="Shipments Needing Attention"
          subheader="The table below lists shipments which are supposed to be delivered today but haven't been marked Out for Delivery yet."
        />
      </Grid> */}

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title="Courier wise Zone wise Shipments"
            subheader="The below charts splits delayed shipments into 5 delivery zone and marks courier partners in each of these zones."
          />
          <CardContent>
            <Grid container spacing={2}>
              {courierZoneWiseData.map((row, k) => {
                if (row.zone !== "undefined") {
                  return (
                    <Grid item key={k} xs={12} md={3}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          mb: 2,
                          fontWeight: 500,
                          fontSize: 15,
                        }}
                      >
                        {row.zone}
                      </Typography>
                      <ScatterChart
                        data={row.data}
                        chartOptions={chartOptions}
                        height={250}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function TableData({ data, title, subheader }) {
  const [sizePerPage, setSizePerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [orderItems, setOrderItems] = React.useState(null);
  const [openList, setOpenList] = React.useState(false);

  const getItemList = (awb) => {
    const bodyData = {
      includes: "itemList",
      andfilter: [
        {
          field: "awbNumber",
          operator: "eq",
          value: awb,
        },
      ],
    };

    post(`${apiConstants.BASE_LOCAL}${apiConstants.ORDER_LISTS}`, bodyData)
      .then((response) => {
        setOpenList(true);
        setOrderItems(response.data.data[0].item_list);
      })
      .catch((err) => {
        setOpenList(false);
        console.error(err);
      });
    //
  };

  const handleClose = () => {
    setOrderItems(null);
    setOpenList(false);
  };

  return (
    <Card>
      <OrderItemsModal
        items={orderItems}
        setOpen={handleClose}
        openList={openList}
      />
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <DownloadExcelMultipleSheets
            sheets={[
              {
                name: "Shipments",
                data: data,
                columns: [
                  { label: "Reference", value: "shippingLabelId" },
                  { label: "Courier Partner", value: "courierName" },
                  { label: "AWB Number", value: "awb_number" },
                  {
                    label: "Order Date",
                    value: (row) => formatDate(row.order_date, "dd MMM, yyyy"),
                  },
                  {
                    label: "Pickup Date",
                    value: (row) =>
                      formatDate(row.pickupDate, "dd MMM, yyyy hh:mm aa"),
                  },
                  {
                    label: "Edd",
                    value: (row) =>
                      formatDate(row.edd, "dd MMM, yyyy hh:mm aa"),
                  },
                  { label: "Zone", value: "merchantZone" },
                  { label: "Delay", value: "deliveryBreachedAgeingDays" },
                  { label: "Attempts", value: "totalAttempts" },
                  { label: "Invoice Value", value: "invoice_value" },
                ],
              },
            ]}
            filename="Delayed Shipments"
          />
        }
      />
      <CardContent sx={{ px: 0 }}>
        <ProzoDataGrid
          columns={[
            {
              field: "shippingLabelId",
              headerName: "Reference",
              width: 130,
            },
            {
              field: "Reference",
              headerName: "Order Reference",
              width: 150,
              renderCell: ({ row }) => {
                return (
                  <Box
                    width={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Link to={`/order-detail?waybill=${row.awbNumber}`}>
                      {row.reference}
                    </Link>
                    <Button
                      sx={{
                        padding: "0px 5px",
                        fontSize: "11px",
                        backgroundColor: "aliceblue",
                        borderRadius: "3px",
                      }}
                      startIcon={
                        <VisibilityIcon sx={{ fontSize: "15px !important" }} />
                      }
                      variant="link"
                      onClick={() => {
                        getItemList(row.awbNumber);
                      }}
                    >
                      View Items
                    </Button>
                  </Box>
                );
              },
            },
            { field: "courierName", headerName: "Courier Partner", width: 180 },
            {
              field: "awb_number",
              headerName: "AWB Number",
              width: 150,
              renderCell: ({ row }) => {
                return (
                  <Link to={`/order-detail?waybill=${row.awbNumber}`}>
                    {row.awbNumber}
                  </Link>
                );
              },
            },
            {
              field: "order_date",
              headerName: "Order Date",
              width: 120,
              renderCell: ({ row }) =>
                formatDate(row.awbRegisteredDate, "dd MMM, yyyy"), //awbRegisteredDate
            },
            {
              field: "pickupDate",
              headerName: "Pickup Date",
              width: 120,
              renderCell: ({ row }) =>
                formatDate(row.pickupDate, "dd MMM, yyyy hh:mm aa"),
            },
            {
              field: "edd",
              headerName: "EDD",
              width: 180,
              renderCell: ({ row }) => {
                return (
                  <Typography
                    fontSize="14px"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <AccessTimeIcon
                      fontSize="small"
                      color="error"
                      sx={{ mr: "5px" }}
                    />
                    {formatDate(
                      row.estimatedDeliveryDate,
                      "dd MMM, yyyy hh:mm aa"
                    )}
                  </Typography>
                );
              },
            },
            {
              field: "maxTat",
              headerName: "SLA (Days)",
              width: 100,
              align: "center",
              headerAlign: "center",
            },
            {
              field: "merchantZone",
              headerName: "Zone",
              width: 120,
              renderCell: ({ row }) => {
                if (_.isEmpty(row.merchantZone)) return;
                return (
                  <Typography
                    fontSize="14px"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LocationOnOutlinedIcon
                      fontSize="small"
                      sx={{ mr: "5px" }}
                    />{" "}
                    {row.merchantZone}
                  </Typography>
                );
              },
            },

            {
              field: "deliveryBreachedAgeingDays",
              headerName: "Delay",
              width: 100,
              renderCell: ({ row }) => {
                return (
                  <Typography
                    fontSize="14px"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {row.deliveryBreachedAgeingDays}
                  </Typography>
                );
              },
              cellClassName: ({ row }) => {
                if (row.forwardDelayInDays >= 8) {
                  return "delay-dark-red";
                }

                return `delay-cell-${row.forwardDelayInDays}`;
              },
            },
            {
              field: "totalAttempts",
              headerName: "No. of Attempts",
              width: 140,
              align: "center",
              headerAlign: "center",
              renderCell: ({ row }) => {
                let d = row.deliveryAttemptCount ?row.deliveryAttemptCount :0;
                return (
                  <Box
                    width={1 / 1.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="body2">
                      {d}
                    </Typography>
                    <Box width={1 / 1.5} sx={{ ml: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={Math.round(d * 25)}
                      />
                    </Box>
                  </Box>
                );
              },
            },
          ]}
          autoHeight={true}
          rowHeight={70}
          rows={[...data].splice(page * sizePerPage, sizePerPage)}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          setPage={setPage}
          page={page}
          pagination={true}
          hideFooterPagination={false}
          filterMode={"server"}
          rowCount={data.length}
          getRowId={(row) => row.id}
        />
      </CardContent>
    </Card>
  );
}
