import React from "react";
import { Button, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { stringToColor, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { TableSkeleton } from "../../../../common/Skeletons";
import { Box } from "@mui/system";
import _ from 'lodash'
import PercentIcon from '@mui/icons-material/Percent';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { DualAxisChart } from "../../../charts/CompositChart";
import useGetCourier from "../useGetCourier";

export default function CourierWiseSplit({ data, uniqCourierIds, title }) {
    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()
    const [view, setView] = React.useState("chart")
    const [tableView, setTableView] = React.useState("percentage")
    const [showCount, setShowCount] = React.useState(false)

    if (isLoadingCouriers || isFetchingCouriers) return <TableSkeleton rows={10} />

    const allPresentCouriers = allCouriers.filter(row => uniqCourierIds.includes(row.id))

    const { rows: rowsOutTat, allColumns } = transformChartDataToTable(data, [
        ...allPresentCouriers.map((z) => {
            const key = tableView === 'percentage' ? `c_${z.id}_perc_out` : `c_${z.id}_count_out`
            return { key, label: `${z.parent}/${z.id}` }
        })
    ])

    const { rows: rowsInTat } = transformChartDataToTable(data, [
        ...allPresentCouriers.map((z) => {
            const key = tableView === 'percentage' ? `c_${z.id}_perc_in` : `c_${z.id}_count_in`
            return { key, label: `${z.parent}/${z.id}` }
        }),
    ])

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'Out TAT Percentage',
                data: [
                    {
                        dataKey: 'totalOutTatPercentage',
                        label: 'Out-TAT %',
                        chartType: 'line',
                        fill: 'red',
                        unit: '%'
                    },
                ]
            }
        },
    }

    if (showCount) {
        chartOptions.yaxis.right = {
            label: 'RTO Count',
            data: allPresentCouriers.map((z) => ({ dataKey: `c_${z.id}_perc_out`, label: `${z.parent}/${z.id}` + ' %', chartType: 'line', fill: stringToColor(z.name), unit: '%' }))
        }
    } else {
        delete chartOptions.yaxis.right
    }

    return (
        <Card elevation={1}>
            <CardHeader title={title} action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button disableElevation onClick={() => setShowCount(st => !st)} variant={showCount ? "contained" : "outlined"} sx={{ mr: 1 }}>Show All Courier</Button>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Chart">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Table">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {(view === "table") && (
                        <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setTableView(v)} value={tableView}>
                            <ToggleButton value="percentage" title="View Data In %">
                                <PercentIcon />
                            </ToggleButton>
                            <ToggleButton value="number" title="View Data In Count">
                                <LooksOneIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </Box>
            )} />
            <CardContent sx={{ px: view === 'chart' ? 1 : 0 }}>
                {view === 'chart' && (
                    <DualAxisChart data={data} chartOptions={chartOptions} />
                )}
                {view === 'table' && (
                    <TableContainer sx={{ maxHeight: 1200, overflowX: 'auto' }}>
                        <Table size="small" density sx={{ minWidth: 750, borderCollapse: 'collapse' }}>
                            <TableHead sx={{ backgroundColor: '#000', color: '#fff' }}>
                                <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                                    {/* Header sticky cell */}
                                    <TableCell sx={{
                                        borderRight: '1px solid #ccc',
                                        color: '#fff',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 2,
                                        backgroundColor: '#000'  // Header background color
                                    }} colSpan={1}>Name</TableCell>
                                    {allColumns.map((val, j) => (
                                        <TableCell key={j} sx={{ borderRight: '1px solid #ccc', color: '#fff' }} colSpan={2}>{val}</TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    {/* Placeholder for the sticky cell in the second header row */}
                                    <TableCell sx={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 2,
                                        backgroundColor: '#000',  // Header background color
                                        borderRight: '1px solid #ccc'
                                    }} />
                                    {allColumns.map((_val, i) => (
                                        <React.Fragment key={i}>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>IN</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>OUT</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsOutTat.map((row, i) => {
                                    const inTatData = _.find(rowsInTat, { name: row.name })
                                    return (
                                        <TableRow key={i} sx={{ borderBottom: '1px solid #ccc' }}>
                                            {/* Body sticky cell */}
                                            <TableCell sx={{
                                                borderRight: '1px solid #ccc',
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 1,
                                                backgroundColor: '#fff',  // Body row background color
                                                // Add padding if needed, and make the text bold or adjust as per design
                                                padding: '6px',
                                            }}>{row.name}</TableCell>
                                            {allColumns.map((val, j) => (
                                                <React.Fragment key={j}>
                                                    <TableCell sx={{ borderRight: '1px solid #ccc' }}>
                                                        {_.get(inTatData, val, 0) + (tableView === 'percentage' ? '%' : '')}
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: '1px solid #ccc' }}>
                                                        {_.get(row, val, 0) + (tableView === 'percentage' ? '%' : '')}
                                                    </TableCell>

                                                </React.Fragment>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </CardContent>
        </Card>
    )
}