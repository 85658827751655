import {useGetRtoDelayQuery} from "../../redux/commonRTK";
import _ from "lodash";
import { getCourierName } from "../../helpers/UtilityHelper";
import { useGetCouriersQuery } from "../../redux/commonRTKPersist";


export default function useRtoDelay(merchantID) {
  let rawData = useGetRtoDelayQuery({ 'merchant': merchantID, 'shipment_type': "B2C", 'order_type': 'FORWARD' });
  let { data: courierDetails = [], isLoading: isLoading3 } =
    useGetCouriersQuery();

  let filterData = [];
  let k = rawData?.data?.data.filter((item, index) => {
    return (item._id.isBreached === true);
  });

  k?.filter((item, index) => {
    item.data.filter((e) =>
      filterData.push({ ...e, courierID: item?._id?.courier })
    );
  });

  if (rawData?.isLoading || rawData?.isFetching || isLoading3) {
    return { isCalculating: true };
  }

  const rtoDelay = _(filterData || [])
    .transform(function (results, item) {
      const sla = courierDetails.find(
        (s) => {
          return s.id === item.courierID;
        } // key courier
      );
      let rtoDeliveryBreachedAgeingDays= item?.rtoDeliveryBreachedAgeingDays;
      let rtoDelayLabel='';
        if (rtoDeliveryBreachedAgeingDays >= 0 && rtoDeliveryBreachedAgeingDays <= 15) {
          rtoDelayLabel = "0-15 Days";
        } else if (rtoDeliveryBreachedAgeingDays > 15 && rtoDeliveryBreachedAgeingDays <= 30) {
          rtoDelayLabel = "16-30 Days";
        } else if (rtoDeliveryBreachedAgeingDays > 30 && rtoDeliveryBreachedAgeingDays <= 45) {
          rtoDelayLabel = "31-45 Days";
        } else if (rtoDeliveryBreachedAgeingDays > 45 && rtoDeliveryBreachedAgeingDays <= 60) {
          rtoDelayLabel = "46-60 Days";
        } else if (rtoDeliveryBreachedAgeingDays > 60 && rtoDeliveryBreachedAgeingDays <= 75) {
          rtoDelayLabel = "61-75 Days";
        } else if (rtoDeliveryBreachedAgeingDays > 75 && rtoDeliveryBreachedAgeingDays <= 100) {
          rtoDelayLabel = "76-100 Days";
        } else if (rtoDeliveryBreachedAgeingDays > 100) {
          rtoDelayLabel = "100+ Days";
        }
      results.push({
        ...item,
        rtoDelayed: item?.rtoDeliveryBreachedAgeingDays > 0 ? true : false,
        status: item?.orderStatus,
        rtoDelayLabel: rtoDelayLabel,
        rtoDelayInDays: item?.rtoDeliveryBreachedAgeingDays,
        rtoPendingDays: item?.rtoDeliveryPendingAgeingDays,
        courierName: getCourierName(sla?.name || ""),
      });

    })
    .orderBy("rtoDeliveryBreachedAgeingDays", "desc")
    .value();

  return { data: rtoDelay };
}
