import React from "react";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DualAxisChart } from "../../../charts/CompositChart";

function ReAttemptAdherence({ data }) {

    const [showCount, setShowCount] = React.useState(false)

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'Adherence %',
                data: [
                    {
                        dataKey: 'reattemptAdherence',
                        label: 'Adherence %',
                        chartType: 'line',
                        fill: 'green',
                        unit: '%'
                    },
                ]
            }
        },
    }

    if (showCount) {
        chartOptions.yaxis.right = {
            label: 'Shipment Count',
            data: [
                {
                    dataKey: 'totalReattemptRequested',
                    label: 'Reattempt Requested',
                    chartType: 'line',
                },
                {
                    dataKey: 'reattemptDelivered',
                    label: 'Delivered',
                    chartType: 'line',
                },
                {
                    dataKey: 'actualTotalReattempt',
                    label: 'Actual Re-attempt',
                    chartType: 'line',
                },
            ]
        }
    }

    return (
        <Card elevation={1}>
            <CardHeader
                title="Re-attempt Adherence" subheader={
                    <Typography variant="body2">
                        The chart shows the data on the adherence of re-attempt requests to NDR shipments by the courier partners.
                        <br /> A poor re-attempt adherence percentage indicates that the re-attempt action is not effective.
                    </Typography>
                }
                action={(<Button
                    disableElevation
                    onClick={() => setShowCount(st => !st)}
                    variant={showCount ? "contained" : "outlined"}
                    sx={{ mr: 1 }}
                >
                    Show Count
                </Button>)}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <DualAxisChart data={data} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ReAttemptAdherence;