import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useLostPerformance from "./useLostPerformance";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import { TableSkeleton } from "../../../../common/Skeletons";
import { formatCurrency, getDDMMYYYYHHmmToIST } from "../../../../helpers/UtilityHelper";
import ZoneWiseSplit from "./ZoneWiseSplit";
import CourierWiseSplit from "./CourierWiseSplit";
import InfoBox from "../../../../common/InfoBox";
import { DualAxisChart } from "../../../charts/CompositChart";
import ExportChartDataToExcel from "../../../../common/ExportChartDataToExcel";
import { useSelector } from "react-redux";

function PerformanceLostComponent() {

    const [calcOn, setCalcOn] = React.useState('WOW')
    const [shipType, setShipType] = useState("B2C")
    const { isLoading, data } = useLostPerformance(calcOn, shipType)
    const { userInfo } = useSelector((state) => state.user)
    const handleChange = (event) => {
        setShipType(event.target.value);
    }
    const downloadOptions = [
        {
            label: 'Download All Lost Orders',
            fileName: 'Lost_Orders',
            data: data?.allLostData || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Channel', value: 'channelName' },
                { label: 'Client Order ID', value: 'clientOrderId' },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Invoice Value', value: 'invoiceValue' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'AWB Date', value: (row) => getDDMMYYYYHHmmToIST(row.awbRegisteredDate) },
                { label: 'Estimated Delivery Date', value: (row) => getDDMMYYYYHHmmToIST(row.estimatedDeliveryDate) }
            ]
        }
    ]

    return (
        <Accordion expanded>
            <AccordionSummary sx={{ boxShadow: 1, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">Lost & Damaged</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {(userInfo?.merchant?.isD2CMerchant && userInfo?.merchant?.isPTLMerchant) &&
                                <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Shipment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={shipType}
                                        onChange={handleChange}
                                        label="Shipment Type"
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: 'red', // Change the icon color
                                            },
                                        }}
                                    >
                                        <MenuItem value={"B2C"}>B2C</MenuItem>
                                        <MenuItem value={"B2B"}>B2B</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <ExportChartDataToExcel options={downloadOptions} />
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides an details on all lost and damaged shipments. All data is populated on basis of the date of shipment creation in Proship.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <InfoBox header="Total Shipments" value={data.total} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InfoBox header="Lost Shipments" value={data.lost} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InfoBox header="Lost Shipments %" value={`${data.totalLostPercentage}%`} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InfoBox header="Lost Shipments Value" value={formatCurrency(data.totalLostValue)} />
                                </Grid>
                                {data && data?.lost > 0 && (
                                    <Grid item xs={12}>
                                        <Card elevation={1}>
                                            <CardHeader title="Lost and Damaged Trends" subheader="The chart below provide information about shipments that were marked as lost and damaged during transit. " />
                                            <CardContent>
                                                <DualAxisChart
                                                    data={data.lineChartData}
                                                    chartOptions={{
                                                        layout: 'horizontal',
                                                        xaxis: 'name',
                                                        yaxis: {
                                                            left: {
                                                                label: 'Count',
                                                                data: [
                                                                    {
                                                                        dataKey: 'totalLostCases',
                                                                        label: 'Shipments Count',
                                                                        chartType: 'line'
                                                                    },
                                                                ],
                                                            },
                                                            right: {
                                                                label: 'Percentage',
                                                                data: [
                                                                    {
                                                                        dataKey: 'lostPercentage',
                                                                        unit: '%',
                                                                        label: 'Shipments Percentage',
                                                                        chartType: 'line',
                                                                        fill: '#C37DC4',
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                                {(data?.lost > 0) && (
                                    <>
                                        <Grid item xs={12}>
                                            <ZoneWiseSplit data={data} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CourierWiseSplit data={data} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function PerformanceLost() {
    return (
        <ErrorBoundary>
            <PerformanceLostComponent />
        </ErrorBoundary>
    )
}