import React from "react";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { formatNumber } from "../../../../helpers/UtilityHelper";
import InfoBox from "../../../../common/InfoBox";
import { DualAxisChart } from "../../../charts/CompositChart";

function ReAttemptConversion({ data, reattemptConversionSummary }) {

    const [showCount, setShowCount] = React.useState(false)

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'Conversion %',
                data: [
                    {
                        dataKey: 'reattemptConversionPercentage',
                        label: 'Conversion %',
                        chartType: 'line',
                        fill: 'green',
                        unit: '%'
                    },
                ]
            }
        },
    }

    if (showCount) {
        chartOptions.yaxis.right = {
            label: 'Shipment Count',
            data: [
                {
                    dataKey: 'totalReattemptRequested',
                    label: 'Reattempt Requested',
                    chartType: 'line',
                },
                {
                    dataKey: 'reattemptDelivered',
                    label: 'Delivered',
                    chartType: 'line',
                },
                {
                    dataKey: 'reattemptUndelivered',
                    label: 'Undelivered',
                    chartType: 'line',
                },
            ]
        }
    }

    return (
        <Card elevation={1}>
            <CardHeader
                title="Re-attempt Delivery Conversion" subheader={
                    <Typography variant="body2">
                        The chart shows the data around successful deliveries post taking a re-attempt action on a NDR shipment.
                        <br /> A poor re-attempt conversion rate indicates that the re-attempt action is not effective and is not leading to successful deliveries to end customers.
                    </Typography>
                }
                action={(<Button
                    disableElevation
                    onClick={() => setShowCount(st => !st)}
                    variant={showCount ? "contained" : "outlined"}
                    sx={{ mr: 1 }}
                >
                    Show Count
                </Button>)}
            />
            <CardContent>
                <Grid container spacing={2}>
                    {reattemptConversionSummary && (
                        <React.Fragment>
                            <Grid item xs={6} md={3}>
                                <InfoBox header="Re-attempt Requested" value={formatNumber(reattemptConversionSummary.totalReattemptRequested)} />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <InfoBox header="Actual Re-attempt" value={formatNumber(reattemptConversionSummary.reattemptDelivered + reattemptConversionSummary.actualReattemptHappen)} />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <InfoBox header="Delivered" value={`${formatNumber(reattemptConversionSummary.reattemptDelivered)} (${reattemptConversionSummary.reattemptConversionPercentage}%)`} />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <InfoBox header="Undelivered" value={formatNumber(reattemptConversionSummary.reattemptUndelivered)} />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <DualAxisChart data={data} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ReAttemptConversion;