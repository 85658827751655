import React, { useState, useEffect } from "react";
import { /* useNavigate, */ useSearchParams } from "react-router-dom";
import { formatNumber, getOrderStatus, getTAT, /* getZoneWiseTATBreachedOrNotSplit */ } from "../../helpers/UtilityHelper";
// import { /* useGetMerchantQuery, useGetOrdersByStatusCodeQuery, */ useGetShipmentsInParallelQuery } from "../../redux/commonRTK";
import _ from 'lodash';
import ProzoScatterChart/* , { ScatterChart } */ from "../charts/ProzoScatterChart";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import Loader from "../../common/Loader";
/* import { useGetCouriersQuery } from "../../redux/commonRTKPersist"; */
import { /* Box, */ Button, Menu, MenuItem, /* Typography */ } from "@mui/material";
/* import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator"; */
/* import useSlasWithCourier from "../../common/hooks/useSlasWithCourier"; */
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
/* import { getMerchant } from "../../helpers/ReduxHelpers"; */

/* const orderColumns = [
    {
        "value": "orderDate",
        "label": "Order Date"
    },
    {
        "value": "reference",
        "label": "Reference"
    },
    {
        "value": "orderType",
        "label": "Order Type"
    },
    {
        "value": "orderStatus",
        "label": "Order Status"
    },
    {
        "value": "invoiceValue",
        "label": "Invoice Value"
    },
    {
        "value": "is_reverse",
        "label": "Is Reverse"
    },
    {
        "value": "paymentMode",
        "label": "Payment Mode"
    },
    {
        "value": "awbNumber",
        "label": "Awb Number"
    },
    {
        "value": "merchantZone",
        "label": "Merchant Zone"
    },
    {
        "value": "merchantId",
        "label": "Merchant Id"
    },
    {
        "value": "courierId",
        "label": "Courier Id"
    },
    {
        "value": "pickupState",
        "label": "Pickup State"
    },
    {
        "value": "pickupPincode",
        "label": "Pickup Pincode"
    },
    {
        "value": "pickupCity",
        "label": "Pickup City"
    },
    {
        "value": "dropState",
        "label": "Drop State"
    },
    {
        "value": "dropPincode",
        "label": "Drop Pincode"
    },
    {
        "value": "dropCity",
        "label": "Drop City"
    },
    {
        "label": "Weight",
        "value": function (row) {
            return row.weight[0] ? row.weight[0] : ""
        }
    },
    {
        "value": "zone",
        "label": "Zone"
    }
] */

export default function Performance({ data }) {
    const scatterData = data;
    // const { fromDate: from_date, toDate: to_date } = { fromDate: formatDate(dateRange[0].startDate, 'yyyy-MM-dd'), toDate: formatDate(dateRange[0].endDate, 'yyyy-MM-dd') }
    // const { data: scatterData, isLoading, isFetching } = useGetShipmentsInParallelQuery({ from_date, to_date })
   /*  const { data: deliveredOrders, isLoading: isLoading1, isFetching: isFetching1 } = useGetOrdersByStatusCodeQuery({ from_date, to_date, order_status: 'DELIVERED' })
    const { data: allCouriers = [], isLoading: isLoading2, isFetching: isFetching2 } = useGetCouriersQuery() */
    /* const merchantId = getMerchant().id;
    const { data: merchantDetail } = useGetMerchantQuery({ id: merchantId }); */

    /* const { isLoading: isLoading3, slas } = useSlasWithCourier(merchantDetail) */

    // if (isLoading || isFetching /* || isLoading1 || isFetching1 || isLoading2 || isFetching2 || isLoading3 */) {
    //     return <Loader />
    // }

    return (
        <div className="row">
            {/* {(isLoading || isFetching) && <Loader />} */}
            {/* <div className="col-md-12 col-sm-12">
                <div className="card">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="caption">
                            Performance is calculated on basis of actual shipments delivered in the selected time duration. Shipments that were created in the selected time duration but were delivered at a later date are not part of the analysis.
                        </Typography>
                    </Box>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <DeliveredTat allOrders={deliveredOrders} slas={slas} />
            </div>
            <div className="col-md-6 col-sm-12">
                <DeliveredTat allOrders={deliveredOrders} calcAttemptedTat={true} slas={slas} />
            </div>
            <div className="col-md-6 col-sm-12">
                <CodVsPrepaid allOrders={deliveredOrders} slas={slas} />
            </div>
            <div className="col-md-6 col-sm-12">
                <CodVsPrepaid allOrders={deliveredOrders} calcAttemptedTat={true} slas={slas} />
            </div> */}
            <div className="col-12">
                <TATDeliveryAnalysis scatterData={scatterData} />
            </div>
            {/* <div className="col-12">
                <TATDistribution scatterData={scatterData} allCouriers={allCouriers} />
            </div> */}
        </div>
    )
}

/* function CodVsPrepaid({ allOrders, calcAttemptedTat = false, slas = [] }) {
    const groupedByPaymentMode = _.groupBy(allOrders, 'paymentMode')
    const key = Object.keys(groupedByPaymentMode)
    let tableData = key.map((k) => {
        return {
            mode: k,
            data: getZoneWiseTATBreachedOrNotSplit({
                allOrders: groupedByPaymentMode[k],
                footerText: 'TOTAL',
                calcAttemptedTat,
                slas
            })
        }
    })

    if (tableData.length > 0) {
        const flatArr = _.flatten(tableData.map(r => r.data))
        const totalWithIntat = _.sum(flatArr.map(r => {
            if (r.zone !== 'TOTAL') {
                return r.withintat.volume
            }

            return 0
        }))
        const totalBreached = _.sum(flatArr.map(r => {
            if (r.zone !== 'TOTAL') {
                return r.breached.volume
            }
            return 0
        }))

        tableData.push({
            mode: 'TOTAL',
            data: [{
                zone: "",
                withintat: {
                    volume: totalWithIntat,
                    perc: (totalWithIntat / (totalWithIntat + totalBreached)) * 100
                },
                breached: {
                    volume: totalBreached,
                    perc: (totalBreached / (totalWithIntat + totalBreached)) * 100
                }
            }]
        })
    }

    const downloadData = _.flatten(tableData.map((row) => {
        return row.data.map((col) => ({ ...col, mode: row.mode }))
    }));

    return (
        <div className="card">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h6">{
                    calcAttemptedTat ? 'COD Vs Prepaid (Attempted TAT)' : 'COD Vs Prepaid (Delivered TAT)'
                }</Typography>
                <DownloadExcelMultipleSheets
                    sheets={[
                        {
                            name: calcAttemptedTat ? 'Attempted TAT Analysis' : 'Delivery TAT Analysis',
                            data: downloadData,
                            columns: [
                                {
                                    label: "Payment Mode",
                                    value: "mode"
                                },
                                {
                                    label: "Zone",
                                    value: "zone"
                                },
                                {
                                    label: "Within TAT Volume",
                                    value: (col) => {
                                        return col.withintat.volume;
                                    }
                                },
                                {
                                    label: "Within TAT %age",
                                    value: (col) => {
                                        return col.withintat.perc;
                                    }
                                },
                                {
                                    label: "Breached TAT Volume",
                                    value: (col) => {
                                        return col.breached.volume;
                                    }
                                },
                                {
                                    label: "Within TAT %age",
                                    value: (col) => {
                                        return col.breached.perc;
                                    }
                                }

                            ]
                        },
                        {
                            name: 'Shipments',
                            columns: orderColumns,
                            data: allOrders
                        }
                    ]}
                    data={downloadData}
                    filename={calcAttemptedTat ? 'AttemptedTAT_Analysis_Proship' : 'DeliveryTAT_Analysis_Proship'}
                />
            </Box>
            <table className="data-table last-row-footer">
                <thead>
                    <tr>
                        <td colSpan={2} />
                        <td colSpan={2} className="text-center">Within TAT</td>
                        <td colSpan={2} className="text-center">TAT Breached</td>
                    </tr>
                    <tr>
                        <td>Payment Mode</td>
                        <td>Zone</td>
                        <td className="text-right">Volume</td>
                        <td className="text-right">%</td>
                        <td className="text-right">Volume</td>
                        <td className="text-right">%</td>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((k, i) => (
                        <React.Fragment key={i}>
                            {
                                k.data.map((row, j) => (
                                    <tr key={k.mode + j}>
                                        {(j === 0) && <td rowSpan={k.data.length}>{k.mode}</td>}
                                        <td>{row.zone === "undefined" ? 'Unknown' : row.zone === 'REGIONAL' ? 'WITHIN STATE' : row.zone}</td>
                                        <td className="text-right">{formatNumber(row.withintat.volume)}</td>
                                        <td className="text-right">{formatNumber(Math.round(row.withintat.perc))}%</td>
                                        <td className="text-right">{formatNumber(row.breached.volume)}</td>
                                        <td className="text-right">{formatNumber(Math.round(row.breached.perc))}%</td>
                                    </tr>
                                ))
                            }
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
} */

/* function DeliveredTat({ allOrders, calcAttemptedTat = false, slas = [] }) {
    const tableData = getZoneWiseTATBreachedOrNotSplit({ allOrders, calcAttemptedTat, slas })

    return (
        <div className="card">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h6">
                    {
                        calcAttemptedTat ? 'Attempted TAT' : 'Delivery TAT'
                    }</Typography>
                <DownloadExcelMultipleSheets
                    filename={calcAttemptedTat ? 'AttemptedTAT_Analysis_Proship' : 'DeliveryTAT_Analysis_Proship'}
                    sheets={[
                        {
                            data: tableData,
                            name: calcAttemptedTat ? 'Attempted TAT Analysis' : 'Delivery TAT Analysis',
                            columns: [
                                {
                                    label: "Zone",
                                    value: "zone"
                                },
                                {
                                    label: "Within TAT Volume",
                                    value: (col) => {
                                        return col.withintat.volume;
                                    }
                                },
                                {
                                    label: "Within TAT %age",
                                    value: (col) => {
                                        return col.withintat.perc;
                                    }
                                },
                                {
                                    label: "Breached TAT Volume",
                                    value: (col) => {
                                        return col.breached.volume;
                                    }
                                },
                                {
                                    label: "Within TAT %age",
                                    value: (col) => {
                                        return col.breached.perc;
                                    }
                                }

                            ]
                        },
                        {
                            data: allOrders,
                            name: 'Shipments',
                            columns: orderColumns
                        }
                    ]}
                />
            </Box>
            <table className="data-table last-row-footer">
                <thead>
                    <tr>
                        <td colSpan={1} />
                        <td colSpan={2} className="text-center">Within TAT</td>
                        <td colSpan={2} className="text-center">TAT Breached</td>
                    </tr>
                    <tr>
                        <td>Zone</td>
                        <td className="text-right">Volume</td>
                        <td className="text-right">%</td>
                        <td className="text-right">Volume</td>
                        <td className="text-right">%</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData?.map((row, i) => (
                            <tr key={i}>
                                <td>{row.zone === "undefined" ? 'Unknown' : row.zone === 'REGIONAL' ? 'WITHIN STATE' : row.zone}</td>
                                <td className="text-right">{formatNumber(row.withintat.volume)}</td>
                                <td className="text-right">{formatNumber(Math.round(row.withintat.perc))}%</td>
                                <td className="text-right">{formatNumber(row.breached.volume)}</td>
                                <td className="text-right">{formatNumber(Math.round(row.breached.perc))}%</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
} */

function TATDeliveryAnalysis({ scatterData }) {
    const chunkSize = 1000;
    const [dataChunks, setDataChunks] = useState([])
    const [searchParams] = useSearchParams()
    /* const navigate = useNavigate() */
    const [zone, setZone] = useState("")
    const [status, setStatus] = useState("")
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (scatterData?.data) {
            const chunks = _.chunk(scatterData.data, chunkSize)
            setDataChunks(chunks)
        }
    }, [scatterData])

    // Scatter chart calc  
    const data = dataChunks[page - 1];
    let d = [];
    for (const element of data || []) {

        let obj = {};

        if (element.merchantZone) {
            obj.zone = element.merchantZone
        } else {
            continue;
        }

        if (obj.zone === 'REGIONAL') obj.zone = 'WITHIN STATE'
        obj.status = getOrderStatus(element.orderStatus)
        obj.days = getTAT(element)
        obj.courier = element.courierDetail?.parent;
        obj.date = element.order_date;
        obj.type = element.order_date;
        obj.createdDate = element.order_type;
        obj.awb = element.awb_number;
        obj.pickup = element.pickup_details?.from_pincode;
        obj.drop = element.delivery_details?.to_pincode;
        obj.drop_city = element.delivery_details?.to_city;
        obj.drop_state = element.delivery_details?.to_state;
        d.push(obj);
    }

    /* let timer;
    const filterHandler = (e, name) => {
        clearTimeout(timer)
        const filterValue = e.target.value
        timer = setTimeout(() => {
            if (_.isEmpty(filterValue)) {
                searchParams.delete(name)
            } else {
                searchParams.set(name, filterValue)
            }
            navigate(`?${searchParams.toString()}`, { replace: true })
        }, 500)
    } */

    let dataFilters = Object.fromEntries(searchParams);
    if (dataFilters.days) {
        dataFilters.days = parseInt(dataFilters.days)
    }
    delete dataFilters.viewBy;
    let chartFilteredData = _.filter(d, dataFilters);
    if (zone && zone !== "") {
        chartFilteredData = chartFilteredData.filter(row => row.zone === zone);
    }

    if (status && status !== "") {
        chartFilteredData = chartFilteredData.filter(row => row.status === status);
    }

    return (
        <div className="card p-0">
            <div className="card-body">
                <div className="d-flex align-items-center mb-3 justify-content-between">
                    <h5 className="card-title mb-0">All Shipments Scatter</h5>
                    <div>
                        <ToggleButtonGroup
                            color="secondary"
                            value={status}
                            exclusive
                            size="small"
                            onChange={(_event, nextView) => setStatus(nextView)}
                        >
                            <ToggleButton color="secondary" sx={{ color: "red" }} value="">All Status</ToggleButton>
                            {(_.uniqBy(d, 'status')).map((status, i) => <ToggleButton key={i} value={status.status}>{_.startCase(status.status)}</ToggleButton>)}
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            color="primary"
                            value={zone}
                            exclusive
                            size="small"
                            onChange={(_event, nextView) => setZone(nextView)}
                            sx={{ ml: 1 }}
                        >
                            <ToggleButton color="error" value="">All Zones</ToggleButton>
                            {(_.uniqBy(d, 'zone')).map((zone, i) => <ToggleButton key={i} value={zone.zone}>{zone.zone}</ToggleButton>)}
                        </ToggleButtonGroup>
                        {
                            dataChunks.length > 0 && (
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <React.Fragment>
                                            <Button sx={{ ml: 1, backgroundColor: '#eef3fb' }} disableElevation endIcon={<KeyboardArrowDownIcon />} {...bindTrigger(popupState)}>
                                                {formatNumber(chunkSize * (page - 1))} TO {formatNumber(chunkSize * page)} Orders
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                {Array(dataChunks.length - 1).fill("").map((_e, i) => (
                                                    <MenuItem onClick={() => {
                                                        setPage(i + 1)
                                                        popupState.close()
                                                    }} key={i}>{formatNumber((i * chunkSize) || 1)} To {formatNumber(chunkSize * (i + 1))}</MenuItem>
                                                ))}
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            )
                        }
                    </div>
                </div>
                <ProzoScatterChart data={chartFilteredData} />
            </div>
            {/* <div className="card-footer">
                <div className="col-12">
                    <div className="hstack gap-3 p-3 chartFilter align-items-center">
                        <input onChange={(e) => filterHandler(e, 'days')} type="number" className="form-control form-control-sm rounded" placeholder="Days" />
                        <select onChange={(e) => filterHandler(e, 'courier')} className="form-control form-control-sm rounded">
                            <option value="">-Courier-</option>
                            {(_.uniqBy(d, 'courier')).map((row, i) => {
                                return <option key={i} value={row.courier}>{row.courier}</option>
                            })}
                        </select>
                        <input onChange={(e) => filterHandler(e, 'drop')} type="number" className="form-control form-control-sm rounded" placeholder="Pincode" />
                        <select onChange={(e) => filterHandler(e, 'drop_state')} className="form-control form-control-sm rounded">
                            <option value="">-Drop State-</option>
                            {(_.uniqBy(d, 'drop_state')).map((row, i) => {
                                return <option key={i} value={row.drop_state}>{row.drop_state}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

/* function TATDistribution({ scatterData, allCouriers }) {
    const data = scatterData?.data || []
    const groupedByZone = _.groupBy(data.filter((ship) => 'DELIVERED' === getOrderStatus(ship.orderStatus)), 'merchantZone')
    const chartData = []

    for (const k of Object.keys(groupedByZone)) {
        const zoneData = groupedByZone[k]
        const groupedByCourier = _.groupBy(zoneData, 'courierDetail.key')
        for (const kk of Object.keys(groupedByCourier)) {
            const tatArr = groupedByCourier[kk].map((shipment) => getTAT(shipment))
            const avgTat = Math.round((_.sum(tatArr)) / tatArr.length)
            chartData.push({
                courier: allCouriers.find(c => kk.includes(c.id))?.name?.replace("_", " "),
                value: avgTat,
                zone: k === 'REGIONAL' ? 'WITHIN STATE' : k
            })
        }
    }

    const zoneWiseTat = _.groupBy(chartData, 'zone')
    const chartOptions = {
        xaxis: {
            label: 'Courier',
            dataKey: 'courier',
        },
        yaxis: {
            label: 'Avg. TAT',
            dataKey: 'value',
            unit: ' Days'
        }
    }

    return (
        <div className="card p-0">
            <Typography variant="h6" style={{ "margin": 20 }}>Courier - Zone wise TAT </Typography>
            <div className="row">
                {
                    Object.keys(zoneWiseTat).map((k, i) => (
                        <div className="col-xs col-lg-3" key={i}>
                            <div className="p-1">
                                <h5 className="text-center">{k}</h5>
                                <ScatterChart data={zoneWiseTat[k]} chartOptions={chartOptions} height={250} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
} */