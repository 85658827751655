/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./actions/userAction";

// initialize userToken from local storage
/* const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null */

const initialState = {
  loading: false,
  userInfo: {}, // for user object
  userToken: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the login process.
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // deletes token from storage
      localStorage.removeItem("proPilotToken"); // deletes token from storage
      state.loading = false;
      state.userInfo = {};
      state.userToken = null;
      state.error = null;
    },
    login: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.userToken;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout, login } = userSlice.actions;

export default userSlice.reducer;
