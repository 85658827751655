import { Button, Card, CardContent, CardHeader, Chip, Grid, Typography } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import ConfirmDialog from "../../common/ConfirmDialog";
import Loader from "../../common/Loader";
import { apiConstants, patch, post } from "../../common/constants";
import { toast } from "react-toastify";
import { supportTicketStatus, escalationArray, formatDate, parseDateAndTime } from "../../helpers/UtilityHelper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import DateRangePicker from "../DateRangePicker";
import { subDays } from "date-fns";
import withLayout from "../../hocs/Layout";
import SideDrawer from "../weight/list/SideDrawer";
import CreateTicket from "./CreateTicket";
import PageTitle from "../../common/PageTitle";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MUIModal from "../../common/MUIModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ExportTicketData from "./ExportData";



const EscalationManagement = ({ reload }) => {
    // const [show, setShow] = useState(false);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(30);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [resData, setresData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [filterChange, setFiltersChange] = useState(0);
    const [currentId, setCurrentId] = useState();
    const [ticketId, setTicketId] = useState()
    const { userInfo } = useSelector((state) => state.user);
    const initialDateRange = [{
        endDate: new Date(),
        startDate: subDays(new Date(), 120),
        key: 'selection'
    }];
    const [dateRange, setDateRange] = useState(initialDateRange)
    const getFormattedDates = (range) => ({
        start_date: formatDate(range[0].startDate, "yyyy-MM-dd"),
        end_date: formatDate(range[0].endDate, "yyyy-MM-dd"),
    });
    const [andFilters, setAndFilters] = useState(getFormattedDates(initialDateRange))

    const columns = [
        {
            field: 'id', headerAlign: "left", headerName: 'TicketID', flex: 0.3,
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.id
            },
        },
        {
            field: 'awb', headerAlign: "left", flex: 0.4, headerName: 'AWB(s)',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>

                        {row.custom_fields?.cf_awb_number387695 ?
                            <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.custom_fields?.cf_awb_number387695}`}>
                                {row.custom_fields?.cf_awb_number387695}
                            </Link> : "-"}

                    </>
                )

            },
        },
        // {
        //     field: 'courier', headerAlign: "left", flex: 0.5, headerName: 'Courier',
        //     align: 'left', renderCell: (params) => {
        //         const { row } = params;
        //         const cpId = row?.custom_fields?.cf_cp_id
        //         return cpId
        //     },
        // },
        {
            field: 'createdBy', headerAlign: "left", flex: 0.4, headerName: 'Created By',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_user_name ? row.custom_fields?.cf_user_name : "-"
            },
        },
        {
            field: 'category', headerAlign: "left", flex: 0.4, headerName: 'Category',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_category
            },
        },
        {
            field: 'subcategory', headerAlign: "left", flex: 0.5, headerName: 'SubCategory',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_sub_category
            },
        },
        {
            field: 'fr_due_by', headerAlign: "left", flex: 0.3, headerName: 'FTR Date',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.fr_due_by)
            },
        },
        {
            field: 'due_by', headerAlign: "left", flex: 0.3, headerName: 'Resolution Due By',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.due_by)
            },
        },
        {
            field: 'created_at', headerAlign: "left", flex: 0.3, headerName: 'Created Date',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.created_at)
            },
        },
        {
            field: 'updated_at', headerAlign: "left", flex: 0.3, headerName: 'Last Updated',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.updated_at)
            },
        },
        {
            field: 'status', headerAlign: "left", flex: 0.3, headerName: 'Ticket Status',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                const status = supportTicketStatus.find((elem) => elem.value === row?.status)
                if (status && status.label) {
                    if (status?.label === 'Closed') {
                        return (<Chip color="error" size="small" label={status.label} />)
                    } else {
                        return (<Chip color="success" size="small" label={status.label} />)
                    }
                }
                // return status?.label

            },
        },
        {
            field: 'action', headerAlign: "left", flex: 0.4, headerName: 'Action',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={5}>
                                {row.status !== 5 ?
                                    <Button onClick={() => { toggleDelete(row?.id) }}>
                                        Close
                                    </Button> :
                                    <Button disabled>
                                        Close
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Button
                                    onClick={() => { view(row?.id, row.custom_fields?.cf_awb_number387695) }}
                                >
                                    View
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]

    const methods = useForm({
        defaultValues: {
            status: '',
            category: '',
            subcategory: '',
            awb: ''
        }
    });
    const { handleSubmit, reset } = methods;

    // const selectedCategory = watch("category");
    const data = escalationArray.filter((val) => val.categoryTitle);
    const categoryOptions = _.orderBy(data, "name").map((val) => ({
        value: val.categoryTitle,
        label: val.categoryTitle,
    }));

    const subCategoriesArray = escalationArray.flatMap(category =>
        category.subCategories.map(subCategory => ({
            name: subCategory.name,
            subCatTitle: subCategory.subCatTitle
        }))
    );

    // const subcategoryOptions =
    //     selectedCategory &&
    //     data.find((val) => val.categoryTitle === selectedCategory)?.subCategories.map((subCat) => ({
    //         value: subCat.subCatTitle,
    //         label: subCat.subCatTitle,
    //     }));
    const subcategoryOptions =
        subCategoriesArray?.map((subCat) => ({
            value: subCat.subCatTitle,
            label: subCat.subCatTitle,
        }));



    const onSubmit = (filters) => {
        let payload = {
            start_date: formatDate(dateRange[0].startDate, "yyyy-MM-dd"),
            end_date: formatDate(dateRange[0].endDate, "yyyy-MM-dd"),
        }
        if (filters.status) {
            payload.status_list = [filters.status]
        }
        if (filters.category) {
            payload.category_list = [filters.category]
        }
        if (filters.subcategory) {
            payload.subcategory_list = [filters.subcategory]
        }
        if (filters.awb) {
            payload.awbnumber_list = [filters.awb]
        }
        setAndFilters(payload)
    }

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await post(`${apiConstants.BASE_LOCAL}${apiConstants.FILTER_FRESHDESK}`, {
                    ...andFilters,
                    page: page + 1,
                    merchantOid: userInfo?.merchant?.id
                });
                const parsedRes = JSON.parse(response.data?.responseObj);
                setresData(parsedRes);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
                toast("Some error occurred!", { type: "error" });
            }
        };

        fetchData();
    }, [andFilters, reload, page, filterChange, userInfo]);

    const handleReset = () => {
        reset({
            status: '',
            category: '',
            subcategory: ''
        });
        setDateRange(initialDateRange)
        setAndFilters(getFormattedDates(initialDateRange));
        // setFiltersChange(filterChange + 1);
    }

    const closeticket = (id) => {
        patch(`${apiConstants.BASE_LOCAL}${apiConstants.CLOSE_FRESHDESK}/${id}`).then((res) => {
            setFiltersChange(filterChange + 1);
        }).catch((err) => {
            console.log(err);
            toast("Some error occurred!", { type: "error" });
        })
    }

    const toggleDelete = (id) => {
        setCurrentId(id);
        setDeleteConfirm(true);
    };

    const navigate = useNavigate()
    const view = (id, awb) => {
        navigate(`/escalation_management/view?awb=${awb}&ticketId=${id}`)
    }
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
        setSidebarOpen(false)
    }
    return (
        <>
            <PageTitle>Support</PageTitle>
            {isLoading && <Loader />}
            <div id="main-content" className="container-fluid">
                <ConfirmDialog
                    title=''
                    open={deleteConfirm}
                    setOpen={setDeleteConfirm}
                    onConfirm={() => closeticket(currentId)}
                >
                    Are you sure you want to close this ticket?
                </ConfirmDialog>
                <MUIModal
                    open={open}
                    handleClose={handleClose}
                >
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                            <CheckCircleIcon fontSize="large" color='success' />
                        </Grid>
                        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                            <Typography variant="h5">Ticket Created Successfully</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                            <Typography variant="subtitle2" color={'error'}>
                                Updates may take up to a minute to appear on your screen. Kindly avoid making duplicate requests.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                            <Typography variant="subtitle1">Ticket ID : {ticketId}</Typography>
                        </Grid>
                    </Grid>
                </MUIModal>
                <SideDrawer title='Create Ticket' subtitle='Provide details to raise a support case' open={sidebarOpen ? true : false} setOpen={() => setSidebarOpen(false)} children={<CreateTicket setFiltersChange={setFiltersChange} handleOpen={handleOpen} setTicketId={setTicketId} />}>
                </SideDrawer>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ mb: 1 }}>
                            <CardHeader title=""
                                action={<>
                                    {/* <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => {
                                        setShow(true)
                                    }}>
                                        Export
                                    </Button> */}
                                    <Button variant="contained" color="primary" onClick={() => {
                                        setSidebarOpen(true)
                                    }}>
                                        Create ticket
                                    </Button>

                                </>
                                }
                            />
                            <CardContent>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={2} >
                                            <DateRangePicker
                                                defaultRange={dateRange}
                                                maxDate={new Date()}
                                                onChange={(range) => setDateRange(range)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <RHFTextField
                                                label="AWB"
                                                name="awb"
                                                color="secondary"
                                                variant="filled"
                                                width="100%"
                                            />
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <RHFSelectField
                                                label="Status"
                                                name="status"
                                                options={supportTicketStatus.map((val) => ({
                                                    value: val.value,
                                                    label: val.label
                                                }))}
                                                width="100%"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <RHFSelectField
                                                label="Category"
                                                name="category"
                                                options={_.orderBy(categoryOptions, 'label', 'asc')}
                                                width="100%"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <RHFSelectField
                                                label="Subcategory"
                                                name="subcategory"
                                                options={subcategoryOptions}
                                                width="100%"
                                            />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <LoadingButton type="submit" variant="contained">
                                                Submit
                                            </LoadingButton>
                                            <Button sx={{ ml: 1 }} variant="contained" color="error" onClick={() => { handleReset() }}>
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </CardContent>
                        </Card>
                        <ProzoDataGrid
                            columns={columns}
                            rows={resData?.results?.length > 0 ? resData?.results : []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            paginationMode={"server"}
                            rowCount={resData?.total || 0}
                            getRowId={(row) => row.id}
                        />
                        {/* <ExportTicketData
                            show={show}
                            setShow={setShow}
                            merchantOid={userInfo?.merchant?.id}
                            handleClose={() => setShow(false)}
                        /> */}
                    </Grid>
                </Grid >
            </div >
        </>
    )
}

export default withLayout(EscalationManagement)

