import _ from "lodash"
import RHFSelectField from "./RHFSelectField"
import { apiConstants, getGeneric, postGeneric } from "../constants"
import { useEffect } from "react"
import { useState } from "react"
import useGetCourier from "../../components/dashboard/performance/useGetCourier"
import { useGetAllCourierPartnersQuery } from "../../redux/commonRTK"

export default function CourierFilter({ enableNoneSelect = false }) {
    const { data } = useGetCourier()

    return (
        <RHFSelectField
            enableNoneSelect={enableNoneSelect}
            width="100%"
            name="courier"
            label={`Select Courier`}
            options={
                _.orderBy(data, 'name').map((val) => ({
                    value: val.id,
                    label: val.parent
                }))
            }
        />
    )

}
export function CourierFilterParent({ enableNoneSelect = false }) {
    const { data } = useGetCourier()
    const uniqueOptions = data ? _.uniq(data.map(val => val.parent)) : [];
    return (
        <RHFSelectField
            enableNoneSelect={enableNoneSelect}
            width="100%"
            name="courier"
            label={`Select Courier`}
            options={
                _.orderBy(uniqueOptions, 'name').map((val) => ({
                    value: val,
                    label: val
                }))
            }
        />
    )
}
export function ManifestCourierFilter({ enableNoneSelect = false }) {
    const [uniqueOptions, setUniqueOptions] = useState([]);

    useEffect(() => {
        const fetchCourierData = async () => {
            const defaultFilters = [
                { field: "orderStatus", operator: "in", value: ['ORDER_PLACED', 'PICKUP_PENDING', 'PICKUP_FAILED', 'OUT_FOR_PICKUP', 'AWB_REGISTERED'] },
                { field: "manifestDetails", operator: "exists", value: false },
                { field: "shipmentType", operator: "eq", value: "B2C" }
            ];
            const payload = {
                "andfilter": [...defaultFilters],
                "includes": "courierDetail",
                limit: 5000,
            };
            try {
                const { data } = await postGeneric(apiConstants.ORDER_LISTS, payload);
                const uniqueCouriers = data.data?.reduce((acc, item) => {
                    if (item.courierDetail?.key) {
                        const { key, parent } = item.courierDetail
                        if (!acc[key]) {
                            acc[key] = parent
                        }
                    }
                    return acc
                }, {})
                
                setUniqueOptions(Object.keys(uniqueCouriers).map(key => ({ id: key, parent: uniqueCouriers[key] })));
            } catch (error) {
                console.error('Error fetching courier data:', error);
            }
        };
        fetchCourierData();
    }, []);
    return (
        <RHFSelectField
            enableNoneSelect={enableNoneSelect}
            width="100%"
            name="courier"
            label={`Select Courier`}
            options={_.orderBy(uniqueOptions, 'name').map((val) => ({
                value: val.id,
                label: val.parent
            }))}
        />
    )
}

export function WhitelistB2BCourier({ id, setSelectedCourier }) {
    const [whitelistCouriers, setWhitelistCouriers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getGeneric(`${apiConstants.MERCHANT_DETAILS}/${id}`)
            .then((response) => {
                if (response?.data?.b2bCheapestCPList) {
                    setWhitelistCouriers(response.data.b2bCheapestCPList);
                } else {
                    setWhitelistCouriers([]);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, [id]);

    const handleCourierSelection = (selectedValue) => {
        const selectedCourier = selectedValue;
        setSelectedCourier(selectedCourier);
    };

    return (
        <>
            {loading ? (
                <p>Loading...</p>
            ) : whitelistCouriers.length === 0 ? (
                <p>No couriers available</p>
            ) : (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={whitelistCouriers.map((val) => ({
                        value: val,
                        label: val,
                    }))
                    }
                    onChange={(event) => {
                        handleCourierSelection(event.target.value);
                    }}
                />
            )}
        </>
    );
}

export function CourierList({ filterList, setSelectedCourier }) {
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data } = useGetAllCourierPartnersQuery(filter);
    const filteredData = filterList ? data?.filter(val => val.supported_shipment === filterList) : null;
    // used for bulk allocation
    const handleCourierSelection = (selectedValue) => {
        const selectedOption = _.orderBy(filteredData, 'value').find(val => val.id === selectedValue);
        setSelectedCourier(selectedOption.prozoId);
    };
    return (
        <>
            {filteredData && setSelectedCourier ? (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={_.orderBy(filteredData, 'name').map(val => ({
                        value: val.id,
                        label: val.cpAccountCode + " (" + val.prozoId + ")",
                        additionalData: val.prozoId
                    }))}
                    onChange={(event) => { handleCourierSelection(event.target.value) }}
                />
            ) : filteredData && !setSelectedCourier ? (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={_.orderBy(filteredData, 'name').map(val => ({
                        value: val.id,
                        label: val.cpAccountCode + " (" + val.prozoId + ")",
                    }))}
                />
            ) : (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={_.orderBy(data, 'name').map(val => ({
                        value: val.id,
                        label: val.cpAccountCode + " (" + val.prozoId + ")",
                    }))}
                />
            )}
        </>
    );
}
