import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../common/constants";
import { decodeAuthToken } from '../../helpers/UtilityHelper';

export const userLogin = createAsyncThunk(
    'auth/signin',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = { headers: { 'Content-Type': 'application/json' } };
            const loginUrl = `${apiConstants.BASE_LOCAL}${apiConstants.AUTH}`;
            const { data } = await axios.post(loginUrl, { username, password }, config)
            // store user's token in local storage
            localStorage.setItem('userToken', data.accessToken);

            const { merchantOid } = decodeAuthToken(data.accessToken)
            // const { mechantId } = decodeAuthToken(data.accessToken)
            config.headers.Authorization = `Bearer ${data.accessToken}`

            let merchantData;
            try {
                //let { data } = await axios.get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT}/${merchantOid}`, config)
                // let {data}=await axios.post(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT_SEARCH}/${merchantOid}`, config)
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
                    },
                };
                let loginUrl = apiConstants.BASE_LOCAL + apiConstants.GET_MERCHANT_SEARCH;
                const { data } = await axios.post(loginUrl, {"offset":0,"limit":1,"orfilter": [],"andfilter":[{"field":"id","operator":"eq","value":merchantOid}],"excludes":"mappedOrderRule,sla,codRemittanceConfig"},config)
                merchantData = data.data[0];
                delete merchantData.codRemittanceConfig
                delete merchantData.mappedOrderRule
                delete merchantData.wallet
                delete merchantData.pickup_details
                delete merchantData.bank_details
                delete merchantData.alertSubscription
                delete merchantData.gstDetails
                delete merchantData.merchantWebHooks
                delete merchantData.activeSlas
            } catch (err) { }

            return { ...data, merchant: merchantData }
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
               
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);