// Assuming you have already set up Redux and created a store

// manifestSearchOrdersReducer.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingdata: false,
  orders: [],
  error: null,
};

const manifestSearchOrdersSlice = createSlice({
  name: 'manifestSearchOrders',
  initialState,
  reducers: {
    searchOrdersRequest: (state) => {
      state.loadingdata = true;
      state.error = null;
    },
    searchOrdersSuccess: (state, action) => {
      state.loadingdata = false;
      state.orders = action.payload;
      state.error = null;
    },
    searchOrdersFailure: (state, action) => {
      state.loadingdata = false;
      state.error = action.payload;
    },
    resetSearchOrders: (state) => {
      state.orders = []
    },
  },
});

export const {
  searchOrdersRequest,
  searchOrdersSuccess,
  searchOrdersFailure,
  resetSearchOrders
} = manifestSearchOrdersSlice.actions;

export default manifestSearchOrdersSlice.reducer;
