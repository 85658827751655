import { formatNumber } from "../../helpers/UtilityHelper"
import getColor from "./colors"

export default function SingleProgress({ data }) {
    return (
        <>
            <div className="progress" style={{ height: '30px' }}>
                {
                    data.map((row, i) => {
                        return (
                            <div key={i} className="progress-bar fade-in" style={{ width: `${row.perc}%`, backgroundColor: getColor(i) }}>{row.perc}%</div>
                        )
                    })
                }
            </div>
            <div className="hstack gap-2 mt-2 justify-content-center">
                {
                    data.map((row, i) => {
                        return (
                            <div className="p-legend" key={i}>
                                <div className="legend" style={{ backgroundColor: getColor(i) }} />
                                <p className="m-0">{row.name} - {formatNumber(row.value)}({row.perc}%)</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}