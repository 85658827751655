import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/Upload';

const UploadDialog = ({ open, onClose, loading, onFileChange, onUpload }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Upload Item Images</DialogTitle>
            <DialogContent>
                <input
                    type="file"
                    onChange={onFileChange}
                    accept="image/*"
                    multiple
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={onUpload}
                    color="primary"
                    variant="contained"
                    startIcon={<UploadIcon />}
                >
                    Upload
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default UploadDialog;
