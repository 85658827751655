import React, { useState } from 'react';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import { titleCase } from '../../../helpers/UtilityHelper';
import { Button, Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const UserList = (props) => {
    const [sizePerPage, setSizePerPage] = useState(10)
    const [page, setPage] = useState(0)
    // const inputFile = useRef(null);
    const {
        handleShow,
        userList,
        toggleStatus,
        handleEditClick,
        // toggleDelete
    } = props;
    const userData = (userList.length > 0) ? userList : [];

    // const parseRoles = (roles) => {
    //     if (roles && roles.length > 0) {
    //         return roles.join(", ").replace(/ROLE_/g, ' ');
    //     }
    // }

    return (
        <>
            {/* <div className="row">
                <div className="col-12 col-md-6">
                    <h4 className="form-title">Users</h4>
                </div>
                <div className="col-md-6 text-end">
                    <a href="#" onClick={handleShow} className="btn btn-link link-top no-border">
                        <img src="img/create-order.svg" width="22" alt="proship-img" />
                        Add
                    </a>

                </div>
    </div>*/}
            <div className="row">
                <div className="col-12">
                    <Card>
                        <CardHeader
                            title='All Users'
                            action={(
                                <Button variant='contained'
                                    onClick={handleShow}
                                    size="small"
                                    startIcon={<AddIcon />}>
                                    Add User
                                </Button>
                            )} />

                        <CardContent sx={{ px: 0 }}>
                            <ProzoDataGrid
                                // columnVisibilityModel={false}
                                columns={[
                                    {
                                        field: 'fullName',
                                        headerName: 'Name',
                                        flex: 1
                                    },
                                    {
                                        field: 'roles',
                                        headerName: 'Role',
                                        flex: 1.3
                                    },
                                    {
                                        field: 'email',
                                        headerName: 'Contact Info',
                                        flex: 1
                                    },
                                    {
                                        field: 'status',
                                        headerName: 'Status',
                                        flex: 1,
                                        renderCell: (item) => {
                                            return (
                                                <>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Switch color='info' />} label={titleCase(item.value)} onChange={() => toggleStatus(item.id, item.value)}
                                                            checked={(item.value === "ACTIVE") ? true : false} />
                                                    </FormGroup>
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        fieldName: 'action',
                                        headerName: 'Action',
                                        flex: 1,
                                        renderCell: (item) => {
                                            return (
                                                // <Button variant='contained' size='small' startIcon={<EditIcon size="small" />} onClick={() => handleEditClick(item.id)}>Edit</Button>
                                                <Typography component='span' sx={{ display: 'flex', justifyContent: 'end', pr: 5 }}>
                                                    <Button variant='contained'
                                                        onClick={() => handleEditClick(item.id)}
                                                        size="small"
                                                        startIcon={<EditIcon size="small" />}>Edit</Button>
                                                </Typography>
                                            )
                                        }
                                    }
                                ]}
                                rowHeight={180}
                                rows={userData?.filter(p => !((p.username).startsWith('system_user'))) || []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                paginationMode={'client'}
                                hideFooterPagination={false}
                                rowCount={userData?.length}
                                getRowId={(row) => row.id}
                            />
                        </CardContent>
                    </Card>

                </div>
            </div>
        </>
    );
};
export default UserList;


