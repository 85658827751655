import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import InfoBox from "../../../../common/InfoBox"
import { numberPrecision } from "../../../../helpers/UtilityHelper"
import { DualAxisChart } from "../../../charts/CompositChart"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    yaxis: {
        left: {
            label: 'Shipment Count',
            data: [
                {
                    dataKey: 'LOCAL',
                    label: 'Local Zone',
                    chartType: 'line',
                },
                {
                    dataKey: 'METROS',
                    label: 'Metros Zone',
                    chartType: 'line',
                },
                {
                    dataKey: 'ROI',
                    label: 'ROI Zone',
                    chartType: 'line',
                },
                {
                    dataKey: 'SPECIAL',
                    label: 'Special Zone',
                    chartType: 'line',
                },
                {
                    dataKey: 'REGIONAL',
                    label: 'Regional Zone',
                    chartType: 'line',
                },
            ],
        }
    },
}

export default function ZoneWiseTrends({ data }) {
    return (
        <Card elevation={1}>
            <CardHeader title="Zone Wise Shipments Trends" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={2.4}>
                        <InfoBox header="Local Zone" value={`${numberPrecision(data?.localOrderCount * 100 / data.pickedUpOrderCount, 2)} %`} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="Metro Zone" value={`${numberPrecision(data?.metroOrderCount * 100 / data.pickedUpOrderCount, 2)}%`} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="ROI Zone" value={`${numberPrecision(data?.roiOrderCount * 100 / data.pickedUpOrderCount, 2)}%`} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="Special Zone" value={`${numberPrecision(data?.specialOrderCount * 100 / data.pickedUpOrderCount, 2)} %`} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="Regional Zone" value={`${numberPrecision(data?.regionalOrderCount * 100 / data.pickedUpOrderCount, 2)} %`} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <DualAxisChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}