import { useGetMerchantQuery, useGetShipmentsInParallelQuery } from "../../../redux/commonRTK";
import { formatDate, getOrderStatus, getTAT, grpupedOrderStatus, allZones, overallAgeingDays, getAllOrderStatusIncluded } from '../../../helpers/UtilityHelper';
import { useGetCouriersQuery } from '../../../redux/commonRTKPersist';
import { getMerchant } from "../../../helpers/ReduxHelpers";
import _ from 'lodash';
import useSlasWithCourier from "../../../common/hooks/useSlasWithCourier"
import { addDays, differenceInDays, differenceInMilliseconds } from "date-fns";

const INCLUDE_PROPS = 'edd,awbRegisteredDate,courierDetail,merchantZone,orderStatus,paymentMode,order_history.orderStatusCode,order_history.timestamp,merchantZone'
const filters = [{ field: 'orderType', operator: 'eq', value: 'FORWARD SHIPMENT' }]

export default function usePerformanceDashboard(dates = []) {
    const merchantId = getMerchant().id;
    const { data: merchantDetail, isLoading: isLoading5, isFetching: isFetching5 } = useGetMerchantQuery({ id: merchantId });
    const { slas = [], isLoading: isLoading4 } = useSlasWithCourier(merchantDetail)
    // console.log(slas)
    
    let { data: allCouriers, isLoading: isLoading1, isFetching: isFetching1 } = useGetCouriersQuery()
    allCouriers = _.uniqBy(allCouriers, 'name')

    const { data, isLoading, isFetching } = useGetShipmentsInParallelQuery({
        from_date: _.get(dates, '0.start'),
        to_date: _.get(dates, '0.end'),

        includes: INCLUDE_PROPS,
        filters
    })
    const { data: data1, isLoading: isLoading2, isFetching: isFetching2 } = useGetShipmentsInParallelQuery({
        from_date: _.get(dates, '1.start'),
        to_date: _.get(dates, '1.end'),
        includes: INCLUDE_PROPS,
        sort: { "field": "awbRegisteredDate", "direction": "DESC" },
        filters
    })
    const { data: data2, isLoading: isLoading3, isFetching: isFetching3 } = useGetShipmentsInParallelQuery({
        from_date: _.get(dates, '2.start'),
        to_date: _.get(dates, '2.end'),
        includes: INCLUDE_PROPS,
        filters
    })

    if (
        isLoading || isFetching ||
        isLoading1 || isFetching1 ||
        isLoading2 || isFetching2 ||
        isLoading3 || isFetching3 ||
        isLoading4 || isFetching5 ||
        isLoading5
    ) {
        return { isLoading: true }
    }

    let allOrders = [
        data?.data || [],
        data1?.data || [],
        data2?.data || []
    ];

    allOrders = allOrders.map(alO => ({
        total: alO.length,
        grouped: _.groupBy(alO, 'courierDetail.name'),
        groupedByStatus: _.groupBy(alO, function (item) {
            return getOrderStatus(item.orderStatus)
        }),
    }))

    const oveallTotalCount = _.sumBy(allOrders, 'total')
    const finalData = getCourierWise({ allOrders, dates, oveallTotalCount, allCouriers, slas })
    const statusWiseSplit = getStatusWise({ allOrders, dates, oveallTotalCount })

    return { data: finalData, statusWiseSplit, isLoading: false, allOrders }
}

function getStatusWise({ allOrders, dates, oveallTotalCount }) {

    const finalData = [];

    for (const status of grpupedOrderStatus) {
        const rowSplit = {
            name: _.startCase(status),
            comment: getAllOrderStatusIncluded(status),
            totalCount: allOrders.map((_row) => {
                return _.get(_row, `groupedByStatus.${status}`, [])
            }).flat().length,
        };

        for (const index in dates) {
            const key = formatDate(new Date(dates[index].start), 'MMM_yy')
            if (allOrders[index] && allOrders[index]['total'] <= 0) continue;

            const groupedOrders = _.get(allOrders, `${index}.groupedByStatus.${status}`, [])
            rowSplit[`${key}_totalCount`] = groupedOrders.length;
            rowSplit[`${key}_percentage`] = Number(((groupedOrders.length / allOrders[index]['total']) * 100).toFixed(2)) || 0
        }

        rowSplit.totalPercentage = Number(((rowSplit.totalCount / oveallTotalCount) * 100).toFixed(2)) || 0
        if (rowSplit.totalCount > 0) finalData.push(rowSplit)
    }

    const grandTotalRow = {
        name: 'Grand Total',
        totalCount: _.sumBy(finalData, 'totalCount'),
        totalPercentage: Math.round(_.sumBy(finalData, 'totalPercentage')),
    }

    for (const index in dates) {
        const key = formatDate(new Date(dates[index].start), 'MMM_yy')
        grandTotalRow[`${key}_totalCount`] = _.sumBy(finalData, function (row) {
            return row[`${key}_totalCount`]
        })
        grandTotalRow[`${key}_percentage`] = Math.round(_.sumBy(finalData, function (row) {
            return row[`${key}_percentage`]
        }))
    }

    // Calculating Grand Total Row
    finalData.push(grandTotalRow);
    return finalData;
}

function getCourierWise({ allOrders, dates, oveallTotalCount, allCouriers, slas }) {

    const finalData = [];
    for (const row of allCouriers) {
        // console.log(allCouriers)

        const rowSplit = {
            name: _.startCase(row.name),
            //parent: row.name,
            totalCount: allOrders.map((_row) => {
                return _.get(_row, `grouped.${row.name}`, [])
            }).flat().length,
        };

        for (const index in dates) {

            const key = formatDate(new Date(dates[index].start), 'MMM_yy')
            if (allOrders[index] && allOrders[index]['total'] <= 0) continue;

            const groupedOrders = _.get(allOrders, `${index}.grouped.${row.name}`, [])
            const groupedByPaymentMode = _.groupBy(groupedOrders, 'payment_mode')
            const groupedByStatus = _.groupBy(groupedOrders, function (item) {
                return getOrderStatus(item.orderStatus)
            })

            const groupedByZone = _.groupBy(groupedOrders, 'merchantZone')

            const groupedByTat = groupByCourierTat({
                groupedOrders,
                slas,
                courierId: row.id,
                calcAttemptedTat: true
            })

            const groupedByActualDeliveryTat = groupByCourierTat({
                groupedOrders,
                slas,
                courierId: row.id,
                calcAttemptedTat: false
            })

            const groupedByAgeingDays = _.groupBy(groupedOrders, function (item) {
                const pickedUpHis = item.order_history?.find(i => i.orderStatusCode === 4)
                if (!pickedUpHis) return 'Not PickedUp'

                let pickupTime = new Date(pickedUpHis.timestamp)
                let awbRegisteredDate = new Date(item.awbRegisteredDate)
                let awbRegisterCompare = new Date(item.awbRegisteredDate)
                awbRegisterCompare.setHours(11)
                awbRegisterCompare.setMinutes(0)
                awbRegisterCompare.setSeconds(0)

                const diffTime = differenceInMilliseconds(awbRegisteredDate, awbRegisterCompare)

                if (diffTime <= 0) {
                    awbRegisteredDate = addDays(awbRegisteredDate, 1)
                }

                const ageingDiff = differenceInDays(pickupTime, awbRegisteredDate)

                if (ageingDiff > 5) return 'Above 5'
                return ageingDiff;
            })

            const totalPrepaidCount = groupedByPaymentMode?.PREPAID?.length || 0
            const totalCodCount = groupedByPaymentMode?.COD?.length || 0
            const totalInTat = groupedByTat?.IN?.length || 0
            const totalOutTat = groupedByTat?.OUT?.length || 0
            const totalActualInTat = groupedByActualDeliveryTat?.IN?.length || 0
            const totalActualOutTat = groupedByActualDeliveryTat?.OUT?.length || 0

            rowSplit[`${key}_totalCount`] = groupedOrders.length;
            rowSplit[`${key}_percentage`] = Number(((groupedOrders.length / allOrders[index]['total']) * 100).toFixed(2)) || 0
            rowSplit[`${key}_totalPrepaid`] = totalPrepaidCount;
            rowSplit[`${key}_totalCod`] = totalCodCount;

            rowSplit[`${key}_prepaidPercentage`] = Number(((totalPrepaidCount / groupedOrders.length) * 100).toFixed(2)) || 0
            rowSplit[`${key}_codPercentage`] = Number(((totalCodCount / groupedOrders.length) * 100).toFixed(2)) || 0

            rowSplit[`${key}_totalInTat`] = totalInTat;
            rowSplit[`${key}_inTatPercentage`] = Number(((totalInTat / groupedOrders.length) * 100).toFixed(2)) || 0

            rowSplit[`${key}_totalOutTat`] = totalOutTat;
            rowSplit[`${key}_outTatPercentage`] = Number(((totalOutTat / groupedOrders.length) * 100).toFixed(2)) || 0

            rowSplit[`${key}_totalActualInTat`] = totalActualInTat;
            rowSplit[`${key}_actualInTatPercentage`] = Number(((totalActualInTat / groupedOrders.length) * 100).toFixed(2)) || 0

            rowSplit[`${key}_totalActualOutTat`] = totalActualOutTat;
            rowSplit[`${key}_actualOutTatPercentage`] = Number(((totalActualOutTat / groupedOrders.length) * 100).toFixed(2)) || 0

            // Status wise split
            for (const status of grpupedOrderStatus) {
                const statusCount = (groupedByStatus[status] || []).length;
                rowSplit[`${key}_${status}_total`] = statusCount;
                rowSplit[`${key}_${status}_percentage`] = Number(((statusCount / groupedOrders.length) * 100).toFixed(2)) || 0
            }

            // Zone wise split
            for (const zone of allZones) {
                const zoneData = groupedByZone[zone] || [];
                const groupedByStatus = _.groupBy(zoneData, 'orderStatus');
                const deliveredCount = (groupedByStatus['DELIVERED'] || []).length

                rowSplit[`${key}_${zone}_total`] = zoneData.length;
                rowSplit[`${key}_${zone}_delivered`] = deliveredCount;
                rowSplit[`${key}_${zone}_percentage`] = Number(((deliveredCount / zoneData.length) * 100).toFixed(2)) || 0
            }

            // Ageing wise split
            for (const ageing of overallAgeingDays) {
                const ageingData = groupedByAgeingDays[ageing] || [];

                rowSplit[`${key}_${ageing}_total`] = ageingData.length;
                rowSplit[`${key}_${ageing}_percentage`] = Number(((ageingData.length / groupedOrders.length) * 100).toFixed(2)) || 0
            }
        }

        rowSplit.totalPercentage = Number(((rowSplit.totalCount / oveallTotalCount) * 100).toFixed(2)) || 0
        if (rowSplit.totalCount > 0) finalData.push(rowSplit)
    }

    const grandTotalRow = {
        name: 'Grand Total',
        totalCount: _.sumBy(finalData, 'totalCount'),
        totalPercentage: Math.round(_.sumBy(finalData, 'totalPercentage')),
    }

    for (const index in dates) {

        const key = formatDate(new Date(dates[index].start), 'MMM_yy')
        grandTotalRow[`${key}_totalCount`] = _.sumBy(finalData, function (row) {
            return row[`${key}_totalCount`]
        })
        grandTotalRow[`${key}_percentage`] = Math.round(_.sumBy(finalData, function (row) {
            return row[`${key}_percentage`]
        }))
        grandTotalRow[`${key}_totalPrepaid`] = Math.round(_.sumBy(finalData, function (row) {
            return row[`${key}_totalPrepaid`]
        }))
        grandTotalRow[`${key}_totalCod`] = Math.round(_.sumBy(finalData, function (row) {
            return row[`${key}_totalCod`]
        }))

        grandTotalRow[`${key}_inTatPercentage`] = Number(((_.sumBy(finalData, `${key}_totalInTat`) / allOrders[index]['total']) * 100).toFixed(2)) || 0;
        grandTotalRow[`${key}_outTatPercentage`] = Number(((_.sumBy(finalData, `${key}_totalOutTat`) / allOrders[index]['total']) * 100).toFixed(2)) || 0;

        grandTotalRow[`${key}_actualInTatPercentage`] = Number(((_.sumBy(finalData, `${key}_totalActualInTat`) / allOrders[index]['total']) * 100).toFixed(2)) || 0;
        grandTotalRow[`${key}_actualOutTatPercentage`] = Number(((_.sumBy(finalData, `${key}_totalActualOutTat`) / allOrders[index]['total']) * 100).toFixed(2)) || 0;

        for (const status of grpupedOrderStatus) {
            grandTotalRow[`${key}_${status}_total`] = Number(((_.sumBy(finalData, `${key}_${status}_total`) / allOrders[index]['total']) * 100).toFixed(2)) || 0;
            grandTotalRow[`${key}_${status}_percentage`] = Number(((_.sumBy(finalData, `${key}_${status}_total`) / allOrders[index]['total']) * 100).toFixed(2)) || 0;
        }

        for (const zone of allZones) {
            grandTotalRow[`${key}_${zone}_total`] = _.sumBy(finalData, `${key}_${zone}_total`)
            grandTotalRow[`${key}_${zone}_delivered`] = _.sumBy(finalData, `${key}_${zone}_delivered`)
            grandTotalRow[`${key}_${zone}_percentage`] = Number(((grandTotalRow[`${key}_${zone}_delivered`] / grandTotalRow[`${key}_${zone}_total`]) * 100).toFixed(2)) || 0
        }

        for (const ageing of overallAgeingDays) {
            const _t = _.sumBy(finalData, `${key}_${ageing}_total`)
            grandTotalRow[`${key}_${ageing}_total`] = _t
            grandTotalRow[`${key}_${ageing}_percentage`] = Number(((_t / allOrders[index]['total']) * 100).toFixed(2)) || 0
        }
    }

    // Calculating Grand Total Row
    finalData.push(grandTotalRow);
    return finalData;
}

// Grouped by courier TAT 
function groupByCourierTat({ groupedOrders, calcAttemptedTat, slas, courierId }) {
    return _.groupBy(groupedOrders, function (item) {
        const sla = slas.find(s => s.zoneName === item.merchantZone && s.courierId === courierId)
        const maxTat = sla?.maxTat || 0
        const tatOfOrder = getTAT(item, calcAttemptedTat)        
        if (tatOfOrder <= maxTat) {
            return 'IN'
        }
        return 'OUT'
    })
}