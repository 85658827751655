import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import { formatNumber, numberPrecision } from "../../../../helpers/UtilityHelper"
import InfoBox from "../../../../common/InfoBox"
import { StackedBarChart } from "../../../charts/BarChart"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    labelY: 'NDR Count',
    stackId: 'status',
    data: [
        {dataKey: 'totalDelivered', label: 'NDR Delivered (Closed)', chartType: 'bar', fill:'rgb(0, 197, 153)'},
        {dataKey: 'totalRTO', label: 'NDR RTO (Closed)', chartType: 'bar',fill:'rgb(0, 167, 235)'},
        {dataKey: 'totalLost', label: 'NDR Lost (Closed)', chartType: 'bar',fill:'black'},
        {dataKey: 'totalOpen', label: 'NDR Open', chartType: 'bar', fill:'rgb(255, 11, 80)'}
    ]
}
export default function NDROverview({ data }) {
    return (
        <Card elevation={1}>
            <CardHeader title="NDR OverView" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <InfoBox header="Total NDR" value={formatNumber(data?.totalNDRCount)} />
                    </Grid>
                    <Grid item xs={2}>
                        <InfoBox header="NDR Open" value={formatNumber(data?.openNDRCount) + ' (' + numberPrecision(data?.openNDRCount*100/data?.totalNDRCount, 1)+ '%)'} tc='red'/>
                    </Grid>
                    <Grid item xs={2}>
                        <InfoBox header="NDR Closed - Delivered" value={formatNumber(data?.closedNDRDeliveredCount) + ' (' + numberPrecision(data?.closedNDRDeliveredCount*100/data?.totalNDRCount, 1)+ '%)'} />
                    </Grid>
                    <Grid item xs={2}>
                        <InfoBox header="NDR Closed - RTO" value={formatNumber(data?.closedNDRRTOCount) + ' (' + numberPrecision(data?.closedNDRRTOCount*100/data?.totalNDRCount, 1)+ '%)'} />
                    </Grid>
                    <Grid item xs={2}>
                        <InfoBox header="NDR Lost" value={formatNumber(data?.closedNDRLostCount) + ' (' + numberPrecision(data?.closedNDRLostCount*100/data?.totalNDRCount, 1)+ '%)'} tc='red' />
                    </Grid>
                    <Grid item xs={2}>
                        <InfoBox header="NDR Closed %" value={numberPrecision((data?.closedNDRRTOCount + data?.closedNDRDeliveredCount)*100/data?.totalNDRCount)} />
                    </Grid>
                   
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <StackedBarChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}