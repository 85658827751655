import React, { useEffect, useState } from "react";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Grid, IconButton, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { apiConstants, patch, postGeneric } from "../../../common/constants";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FormProvider, RHFRadioGroup, RHFSelectField, RHFTextField } from "../../../common/hook-form";
import { useForm } from "react-hook-form";
import { useGetCouriersQuery } from "../../../redux/commonRTKPersist";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../../../common/ConfirmDialog";
import { refreshS3SignedUrl } from "../../../helpers/UtilityHelper";
import ImageCarousel from "./ImageCarousel";
import UploadDialog from "./UploadDialog";


const ApproveRequest = ({ approveModalData, itemModalData, setApproveModalOpen, setReload }) => {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [rowData, setRowData] = useState(null);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageOpen, setImageOpen] = useState(false);
    const [isApproveLoading, setApproveLoading] = useState(false);
    const [isRejectLoading, setRejectLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [rejectConfirm, setRejectConfirm] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const { data: allCouriers } = useGetCouriersQuery()
    const { enqueueSnackbar } = useSnackbar();
    const merchantId = getMerchant().id;
    const couriers = allCouriers && _.uniqBy(
        allCouriers.filter((val) => val.supported_shipment === "B2C")).map((val) => ({
            label: val.prozoId,
            value: val.prozoId,
        }));

    const generateQuantityOptions = (currentQuantity) => {
        const options = [];
        for (let i = currentQuantity; i >= 1; i--) {
            options.push({ label: `${i}`, value: i });
        }
        return options;
    };

    const handleUpload = (row) => {
        setRowData(row);
        setUploadOpen(true);
    };

    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };
    // upload item image
    const handleFileUpload = async () => {
        if (selectedFiles.length && rowData) {
            setUploadLoading(true)
            const formData = new FormData();
            selectedFiles.forEach((file, index) => {
                formData.append(`file_${index}`, file);
            });
            try {
                const response = await patch(`${apiConstants.BASE_LOCAL}${apiConstants.UPLOAD_SKU_IMAGE}?skuId=${rowData.item?.sku_id}&merchantOid=${merchantId}&skuName=${rowData.item?.item_name}&userPhone=${itemModalData?.userPhoneNumber}&awbNumber=${itemModalData?.awbNumber}`, formData);
                if (response.status === 200) {
                    setUploadOpen(false);
                    setUploadLoading(false);
                    setApproveModalOpen(false);
                    setReload(reload => reload + 1);
                    enqueueSnackbar('Files uploaded successfully', { variant: 'success' })
                } else {
                    enqueueSnackbar('File upload failed', { variant: 'error' })
                    setUploadLoading(false);
                }
            } catch (error) {
                enqueueSnackbar('Error uploading files', { variant: 'error' })
                setUploadLoading(false);
            }
        } else {
            enqueueSnackbar('Please select images to upload', { variant: 'error' })
        }
    };
    // view carousel
    const handleImageClick = (item) => {
        if (item === 'NA' || (Array.isArray(item) && item.length === 0)) {
            return null;
        } else {
            const itemUrl = item.split(',')
            setSelectedItem(itemUrl);
            setImageOpen(true);
        }
    };

    const methods = useForm({ defaultValues: { courier_type: "Prozo Choice" } })
    const { handleSubmit, watch, setValue } = methods;

    const courierSelection = watch('courier_type')

    const handleQuantityChange = (rowId, newValue) => {
        setSelectedQuantities(prevState => ({
            ...prevState,
            [rowId]: newValue
        }));
    };

    const handleSelectionModelChange = (newSelection) => {
        const selectedRows = rowsWithId.filter(row => newSelection.includes(row.id));
        setSelectedRowsData(selectedRows);
    };

    const updatedRowsData = selectedRowsData.map(row => ({
        ...row,
        selectedQuantity: selectedQuantities[row.id] !== undefined ? selectedQuantities[row.id] : row?.remainingActionableItems
    }));
    // reject items
    const handleReject = () => {
        setRejectLoading(true)
        const itemDetail = updatedRowsData?.map(item => {
            return {
                [item.item?.sku_id]: item.selectedQuantity
            };
        });
        const payload = {
            "id": itemModalData.pk_id,
            "rejectedItems": itemDetail,
            "fromMerchant": merchantId
        }
        postGeneric((`${apiConstants.REJECT_RETURN_REQUEST}`), payload).then((res) => {
            setRejectLoading(false)
            setApproveModalOpen(false)
            setReload(reload => reload + 1)
            enqueueSnackbar('Return Request for Order has been Rejected', { variant: 'success' })
        }).catch((err) => {
            setRejectLoading(false)
            setApproveModalOpen(false)
            enqueueSnackbar(err?.response?.data?.message || 'Some error occurred', { variant: 'error' })
            console.log(err);
        })

    }
    // approve items
    const onSubmit = () => {
        if (selectedRowsData.length > 0) {
            setConfirmApprove(true);
        } else {
            enqueueSnackbar('Please select at least one item to approve.', { variant: 'error' })
        }

    };
    const handleDialogConfirm = (data) => {
        handleApproveAction(data);
    };
    const handleApproveAction = (data) => {
        setApproveLoading(true);
        const shipment_detail = data.shipment_detail?.map(item => ({
            ...item,
            customDWSWeight: 50 // Adding the new field
        }));
        const itemDetail = updatedRowsData?.map(item => ({
            [item.item?.sku_id]: item.selectedQuantity
        }));
        const itemRvp = updatedRowsData?.map(item => ({
            [item.item?.sku_id]: "RVP reason"
        }));
        const skipRule = courierSelection === "Other" ? true : false;
        const payload = {
            "skipRule": skipRule,
            'courier': data.courierPartner,
            "itemSkuMap": itemDetail,
            "itemRvpMap": itemRvp,
            "invoiceValue": data.invoiceValue,
            "shipment_detail": shipment_detail,
            ...(skipRule ? {} : { 'courier': data.courierPartner })
        };
        postGeneric(`${apiConstants.APPROVE_RETURN_REQUEST}?id=${itemModalData.pk_id}`, payload).then((res) => {
            enqueueSnackbar(res?.data?.meta?.message, { variant: 'success' });
            setApproveLoading(false);
            setApproveModalOpen(false);
            setReload(reload => reload + 1);
        }).catch((err) => {
            setApproveLoading(false);
            setApproveModalOpen(false);
            enqueueSnackbar(err?.response?.data?.message || 'Some error occurred', { variant: 'error' });
            console.log(err);
        });
    };

    const handleView = async (url) => {
        const refreshedUrl = await refreshS3SignedUrl(url);
        window.open(refreshedUrl, '_blank');
    };


    const rowsWithId = approveModalData.map((row, index) => ({
        ...row,
        id: index,
    }));

    const handleRejectAction = () => {
        handleReject()
    };

    const handleRejectConfirmation = (e) => {
        if (selectedRowsData.length > 0) {
            setRejectConfirm(true);
        } else {
            enqueueSnackbar('Please select at least one item to reject.', { variant: 'error' })
        }
    };

    useEffect(() => {
        if (itemModalData?.isAllOrderReturn) {
            const payload = {
                awbNumber: itemModalData?.awbNumber,
                isAllReturn: itemModalData?.isAllOrderReturn
            }
            postGeneric((`${apiConstants.GET_RETURN_SHIPMENT_DETAILS}?merchantOid=${merchantId}`), payload).then((res) => {
                setValue("invoiceValue", res?.data?.invoiceValue)
                setValue("shipment_detail.0.item_breadth", res?.data?.item_breadth)
                setValue("shipment_detail.0.item_height", res?.data?.item_height)
                setValue("shipment_detail.0.item_length", res?.data?.item_length)
                setValue("shipment_detail.0.item_weight", res?.data?.item_weight)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [])

    const columns = [
        {
            field: "item",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Name",
            flex: 0.6,
            renderCell: (params) => {
                const { row } = params;
                return row.item?.item_name;
            },
        },
        {
            field: "sku_id",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "SKU ID",
            flex: 0.5,
            renderCell: ({ row }) => {
                return row.item?.sku_id;
            },
        },
        {
            field: "selling_price",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Selling Price",
            flex: 0.5,
            renderCell: ({ row }) => {
                return row.item?.selling_price;
            },
        },
        {
            field: "skuRvpReason",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Return Reason",
            flex: 0.5,
            renderCell: ({ row }) => {
                return row.item?.skuRvpReason;
            },
        },
        {
            field: "units",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Units",
            flex: 0.5,
            renderCell: ({ row }) => {
                const options = generateQuantityOptions(row?.remainingActionableItems);
                const selectedQuantity = selectedQuantities[row.id] !== undefined ? selectedQuantities[row.id] : row?.remainingActionableItems;
                return (
                    <Select
                        value={selectedQuantity}
                        onChange={(event) => { handleQuantityChange(row.id, event.target.value) }}
                        sx={{ width: 100, height: 40 }}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                );
            }
        },
        {
            field: "returnImagesUrl",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Return Item Image",
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='subtitle2'
                                style={{ color: '#007bff', textDecoration: 'underline', display: 'inline', cursor: 'pointer' }}
                                onClick={() => handleView(row?.returnImagesUrl)}
                            >
                                View
                            </Typography>
                            {/* <a
                                href={row?.returnImagesUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    style={{ color: '#007bff', textDecoration: 'underline', display: 'inline' }}
                                >
                                    View
                                </Typography>
                            </a> */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                );
            },
        },
        {
            field: "item_url",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Item Image",
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    <>
                        {row.item?.item_url ?
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle2'
                                        style={{ color: '#007bff', textDecoration: 'underline', display: 'inline', cursor: 'pointer' }}
                                        onClick={() => handleImageClick(row.item?.item_url)}                                        >
                                        View
                                    </Typography>
                                </Grid>
                            </Grid> :
                            <Tooltip title="Upload Item Image" >
                                <IconButton
                                    onClick={() => handleUpload(row)}
                                    color="info" size="small">
                                    <FileUploadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        <ImageCarousel open={imageOpen} onClose={() => setImageOpen(false)} images={selectedItem} />
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={rowsWithId}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        onSelectionModelChange={handleSelectionModelChange}
                        checkboxSelection={true}
                        hideFooterPagination
                        rowHeight={180}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant="caption" fontWeight={500}>
                            <strong>Pickup Details :</strong> {itemModalData?.pickupDetails ? `${itemModalData?.pickupDetails?.FromAddress}, ${itemModalData?.pickupDetails?.FromCity},${itemModalData?.pickupDetails?.FromState},${itemModalData?.pickupDetails?.FromPincode}` : 'No Details'}<br />
                            <strong>Delivery Details :</strong> {itemModalData?.deliveryDetails ? `${itemModalData?.deliveryDetails?.ToAddress}, ${itemModalData?.deliveryDetails?.ToCity},${itemModalData?.deliveryDetails?.ToState},${itemModalData?.deliveryDetails?.ToPincode}` : 'No Details'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RHFTextField required variant="filled" name="shipment_detail.0.item_weight" label="Weight (gms)" type="number" placeholder="Enter Weight"
                            value={watch("shipment_detail.0.item_weight") || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RHFTextField required variant="filled" name="shipment_detail.0.item_length" label="Length (cm)" type="number" placeholder="Enter Length"
                            value={watch("shipment_detail.0.item_length") || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RHFTextField required variant="filled" name="shipment_detail.0.item_breadth" label="Breadth (cm)" type="number" placeholder="Enter Breadth"
                            value={watch("shipment_detail.0.item_breadth") || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RHFTextField required variant="filled" name="shipment_detail.0.item_height" label="Height (cm)" type="number" placeholder="Enter Height"
                            value={watch("shipment_detail.0.item_height") || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RHFTextField required variant="filled" name="invoiceValue" label="Invoice Value" type="number" placeholder="Enter Invoice Value"
                            value={watch("invoiceValue") || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
                        <RHFRadioGroup label="Courier Selection" name="courier_type" options={[
                            {
                                label: "As per Recommendation Rules",
                                value: "Prozo Choice",
                            },
                            { label: "Other Courier", value: "Other" },
                        ]}
                        />
                    </Grid>
                    {courierSelection === 'Other' && (
                        <Grid item xs={6}>
                            <RHFSelectField
                                variant="filled"
                                width="100%"
                                name="courierPartner"
                                label="Courier Partner"
                                noOptionsText='No courier partner available for this pickup location'
                                options={couriers}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={4} sx={{ mt: 2 }}>
                    <LoadingButton
                        loading={isApproveLoading}
                        variant="contained"
                        type="submit"
                    >
                        Approve
                    </LoadingButton>
                    <LoadingButton
                        loading={isRejectLoading}
                        variant="contained"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={() => {
                            handleRejectConfirmation();
                        }}
                    >
                        Reject
                    </LoadingButton>
                </Grid>
            </FormProvider>
            <ConfirmDialog
                title=''
                open={rejectConfirm}
                setOpen={setRejectConfirm}
                onConfirm={() => handleRejectAction()}>
                Are you sure you want to reject this item ?
            </ConfirmDialog>
            <ConfirmDialog
                title=''
                open={confirmApprove}
                setOpen={setConfirmApprove}
                onConfirm={() => handleDialogConfirm(methods.getValues())}
            >
                Are you sure you want to approve this item?
            </ConfirmDialog>
            <UploadDialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                loading={uploadLoading}
                onFileChange={handleFileChange}
                onUpload={handleFileUpload}
            />
        </>
    );
};

export default ApproveRequest;
