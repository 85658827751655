import _ from "lodash";
import { differenceInDays } from "date-fns";
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector";
import { useGetSelectedFilters } from "../../../../common/contexts/Filters";
import { delivered, flattenResponseData, transit } from "../../../../helpers/UtilityHelper";
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK";
import { apiConstants } from "../../../../common/constants";
import { getMerchant } from "../../../../helpers/ReduxHelpers";

function useGetData() {

    const orderStatus = ['CANCELLED_ORDER', 'AWB_REGISTERED', 'PICKUP_PENDING', 'PICKUP_FAILED']

    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()

    const skip = !from_date || !to_date
    const request = { from_date, to_date, merchant_list: id, orderType_list: 'REVERSE', shipmentType_list: "B2C" }

    const { data, isLoading: isLoadingReg, isFetching: isFetchingReg } = useGenericPostRequestQuery({
        url: apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE,
        data: request
    }, { skip })
    const allRegistedShipments = _.filter(flattenResponseData(data || []), filters)

    const allPickupShipments = allRegistedShipments?.filter(item => !orderStatus.includes(item.orderStatus)) || []
    const allPendingPickupShipments = allRegistedShipments?.filter(item => orderStatus.includes(item.orderStatus)) || []
    const allDeliveredShipments = allRegistedShipments?.filter(item => delivered.includes(item.orderStatus)) || []
    const allInTransitShipments = allRegistedShipments?.filter(item => transit.includes(item.orderStatus)) || []

    // Fetching the additional details of the picked up shipments
    const pickupPayload = { merchantId: id, awbNumbers: allPickupShipments?.map(r => r.awbNumber) }
    const genericRequestPickup = { url: apiConstants.get_specific_data_pickup, type: 'POST', data: [pickupPayload] }
    const { data: additionalPickupData, isLoading: isLoadingPickup, isFetching: isFetchingPickup } = useGenericPostRequestQuery(genericRequestPickup, { skip: allPickupShipments.length === 0 })

    // Fetching the additional details of the delivered shipments
    const deliveredPayload = { merchantId: id, awbNumbers: allDeliveredShipments?.map(r => r.awbNumber) }
    const genericRequestDelivered = { url: apiConstants.get_specific_data_delivery, type: 'POST', data: [deliveredPayload] }
    const { data: additionalDelivered, isLoading: isLoadingDelivered, isFetching: isFetchingDelivered } = useGenericPostRequestQuery(genericRequestDelivered, { skip: allDeliveredShipments.length === 0 })

    if (isLoadingReg || isFetchingReg || isLoadingPickup || isFetchingPickup || isLoadingDelivered || isFetchingDelivered) return { isLoading: true }

    const additionalPickupDataMap = additionalPickupData?.reduce((map, item) => {
        map.set(item.awbNumber, item)
        return map;
    }, new Map());

    const additionalDeliveredMap = additionalDelivered?.reduce((map, item) => {
        map.set(item.awbNumber, item)
        return map;
    }, new Map())

    const finalDataWithAllAdditionalData = allRegistedShipments?.map((item) => {

        let shipment = { ...item }

        if (additionalPickupDataMap) {
            if (additionalPickupDataMap?.has(shipment.awbNumber)) {
                const pickupDetails = additionalPickupDataMap?.get(shipment.awbNumber)
                shipment = { ...shipment, ...pickupDetails }
            }
        }

        if (additionalDeliveredMap) {

            if (additionalDeliveredMap?.has(shipment.awbNumber)) {
                const deliveryDetails = additionalDeliveredMap?.get(shipment.awbNumber)
                shipment = { ...shipment, ...deliveryDetails }
            }
        }

        const diffInDaysPickup = differenceInDays(new Date(shipment.actualPickUpTime), new Date(shipment.awbRegisteredDate)) || -1
        const diffInDaysDelivery = differenceInDays(new Date(shipment.actualPickUpTime), new Date(shipment.actualDeliveryTime)) || -1

        return { ...shipment, diffInDaysPickup, diffInDaysDelivery };

    }) || []



    return { allPickupShipments, allPendingPickupShipments, allDeliveredShipments, allInTransitShipments, finalDataWithAllAdditionalData, isLoading: false }
}

export default useGetData;