import {
  useGetLostQuery,
} from "../../redux/commonRTK";
import _ from "lodash";

import { useGetCouriersQuery } from "../../redux/commonRTKPersist";

export default function useLostAndDamaged(merchantID, fromDate, toDate, shipType) {
  // Use React Query's isLoading to check if data is still loading
  const {
    data: rawData,
    isLoading: isLoading1,
  } = useGetLostQuery({
    merchant: merchantID,
    // shipment_type: "B2C",
    shipment_type: shipType,
    from_date: fromDate,
    to_date: toDate,
  });

  const { data: courierDetails = [], isLoading: isLoading3 } =
    useGetCouriersQuery();

  // Check if data is still loading for either of the queries
  if (isLoading1 || isLoading3) {
    return { isCalculating: true };
  }

  let flattenedData = [];
  _.forEach(rawData, (record) => {
    const { data: orderData, time } = record;
    // console.log(time)
    // Iterate through the order data and create flat objects for each _id
    _.forEach(orderData, (order) => {
      const { _id } = order;
      // console.log(_id)
      _.forEach(order?.data, (obj) => {
        flattenedData.push({
          date: time,
          merchant: _id.merchant,
          pickupPincode: _id.pickupPincode,
          courierName: courierDetails.find((s) => s.id === _id.courier)?.parent,
          ...obj, // Spread all fields from the innermost order object
        });
      });
    });
  });
  return { data: flattenedData };
}
