import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { getToken } from '../../helpers/ReduxHelpers';
import { apiConstants, getGeneric, postGeneric } from '../../common/constants';
import { useForm } from "react-hook-form";
import { Button, Card, CardContent, Grid, Switch, Tooltip, Typography } from "@mui/material";
import { FormProvider, RHFDateTimeField, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import Loader from "../../common/Loader"
import { useSnackbar } from "notistack";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import ConfirmDialog from "../../common/ConfirmDialog";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { parseTime2 } from "../../helpers/UtilityHelper";

const NDREmailConfig = () => {
    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [ndrConfig, setNDRConfig] = useState();
    const [isNDREnabled, setNDREnabled] = useState(false);
    const [reload, setReload] = useState(0);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [switchConfirm, setSwitchConfirm] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [timeVal, setTimeVal] = useState('');

    const columns = [
        {
            field: 'emails',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Emails',
            flex: 1.2,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 15 }}>
                        {row?.email}
                    </div>
                )
            },
        },
        {
            field: 'action',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Action',
            flex: 0.5,
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Button
                        onClick={() => handleCancelOrder(row)}
                        disabled={!isNDREnabled}
                        color="error"
                        variant="contained"
                        size="small"
                    >
                        Delete
                    </Button>

                );
            },
        },
    ];

    const emailListRegExp = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}\s*,\s*)*[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}$/
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                email: Yup.string()
                    .matches(emailListRegExp, { message: 'Invalid email format', excludeEmptyString: true })
                    .required("Email is required!"),
            })
        ),
        defaultValues: {
            email: '',
        }
    })
    const { handleSubmit: handleSubmitForm, reset } = methods

    const postNDRConfigUpdate = (payload) => {
        postGeneric(`${apiConstants.UPDATE_NDR_EMAIL_CONFIG}`, payload).then(response => {
            enqueueSnackbar('Success', { variant: 'success' })
            reset()
            setReload(reload => reload + 1)
            setLoading(false)
        })
            .catch((err) => {
                console.error(err)
                enqueueSnackbar('Some error occurred', { variant: 'error' })
                setLoading(false)
            })
    }
    const postNDRConfigAdd = (payload) => {
        postGeneric(`${apiConstants.ADD_NDR_EMAIL_CONFIG}`, payload).then(response => {
            enqueueSnackbar('Success', { variant: 'success' })
            reset()
            setReload(reload => reload + 1)
            setLoading(false)
        })
            .catch((err) => {
                console.error(err)
                enqueueSnackbar('Some error occurred', { variant: 'error' })
                setLoading(false)
            })
    }

    const onSubmit = (data) => {
        setLoading(true);
        const emailsArray = data.email.split(',').map(email => email.trim());
        const existingEmails = ndrConfig?.last30DaysNdrReceivedByEmails ?? [];
        const payload = {
            merchantOId: merchantId,
            last30DaysNdrReceivedByEmails: [...existingEmails, ...emailsArray],
            last30DaysNdrReceivedByCCEmails: ["ProshipTechTeam@prozo.com"],
            isLast30DaysNdrEnabled: true,
            last30DaysNdrEmailCronTime: timeVal
        }
        if (ndrConfig?.last30DaysNdrReceivedByEmails) {
            postNDRConfigUpdate(payload)
        } else {
            postNDRConfigAdd(payload)
        }
    }
    const handleCancelOrder = (data) => {
        setDeleteData(data)
        setDeleteConfirm(true)
    }
    const handleDelete = () => {
        const index = ndrConfig?.last30DaysNdrReceivedByEmails?.indexOf(deleteData.email);
        if (index !== -1) {
            ndrConfig?.last30DaysNdrReceivedByEmails.splice(index, 1);
        }
        const payload = {
            merchantOId: merchantId,
            last30DaysNdrReceivedByEmails: [...ndrConfig?.last30DaysNdrReceivedByEmails],
            last30DaysNdrReceivedByCCEmails: ["ProshipTechTeam@prozo.com"],
            isLast30DaysNdrEnabled: true,
            last30DaysNdrEmailCronTime: timeVal
        }
        postNDRConfigUpdate(payload)
    }
    useEffect(() => {
        setLoading(true)
        getGeneric(`${apiConstants.GET_NDR_EMAIL_CONFIG}?merchantOId=${merchantId}`).then((res) => {
            setNDRConfig(res.data);
            setTimeVal(res.data?.last30DaysNdrEmailCronTime)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }, [merchantId, reload])
    const toggleEmail = () => {
        let payload = {
            merchantOId: merchantId,
            last30DaysNdrReceivedByEmails: [...ndrConfig?.last30DaysNdrReceivedByEmails],
            last30DaysNdrReceivedByCCEmails: ["ProshipTechTeam@prozo.com"],
            isLast30DaysNdrEnabled: isNDREnabled ? false : true,
            last30DaysNdrEmailCronTime: timeVal
        }
        postNDRConfigUpdate(payload)
    }

    useEffect(() => {
        setNDREnabled(ndrConfig?.isLast30DaysNdrEnabled)
    }, [ndrConfig])

    const handleSwitchConfirmation = (e) => {
        setSwitchConfirm(true);
    };
    const handleSwitchAction = () => {
        toggleEmail()
    };

    const formatTime = (time) => {
        if (time instanceof Date) {
            return time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        }
        return time;
    }

    return (
        <>
            {(isLoading) && <Loader />}
            <ConfirmDialog
                title=''
                open={deleteConfirm}
                setOpen={setDeleteConfirm}
                onConfirm={() => handleDelete()}>
                Are you sure you want to delete this email ?
            </ConfirmDialog>
            <ConfirmDialog
                title=''
                open={switchConfirm}
                setOpen={setSwitchConfirm}
                onConfirm={() => handleSwitchAction()}>
                Are you sure you want to change the email configuration?
            </ConfirmDialog>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmitForm(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                                <RHFTextField color="warning"
                                    name="email"
                                    label="Add Emails *"
                                    size="medium"
                                />
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ mt: 3.5 }}>
                                <LoadingButton loading={isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="info"
                                    size="small"
                                >Add
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent:'flex-end',alignItems:'center'}}>
                                <Tooltip title={isNDREnabled ? "Disable NDR Emails" : "Enable NDR Emails"}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={isNDREnabled}
                                                    onChange={(e) => handleSwitchConfirmation(e)}
                                                    disabled={!ndrConfig?.last30DaysNdrReceivedByEmails?.length > 0}
                                                />} label={<Typography color='error' variant="subtitle2">{`Use toggle to ${isNDREnabled ? 'Disable' : 'Enable'} NDR emails`}</Typography>}
                                            labelPlacement="bottom"
                                        />
                                    </FormGroup>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </FormProvider>
                    <EmailTime postNDRConfigUpdate={postNDRConfigUpdate}
                        postNDRConfigAdd={postNDRConfigAdd}
                        merchantId={merchantId} ndrConfig={ndrConfig}
                        setLoading={setLoading} isNDREnabled={isNDREnabled}
                        setReload={setReload}
                        setTimeVal={setTimeVal}
                    />
                    <Grid item xs={12} sx={{mt:1}}>
                        <Typography variant="subtitle2" component="span">Current Email Time : </Typography>
                        <Typography variant="subtitle2" component="span" color='error'>
                            {isNDREnabled ? (timeVal ? formatTime(timeVal) : 'Not Updated') : "Emails Disabled"}
                        </Typography>
                    </Grid>
                </CardContent>
            </Card>
            <Grid item xs={12} sx={{ mt: 1 }}>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={ndrConfig?.last30DaysNdrReceivedByEmails?.length > 0 ? ndrConfig?.last30DaysNdrReceivedByEmails?.map((email, index) => ({ id: index, email })) : []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    rowHeight={120}
                    paginationMode='client'
                    rowCount={ndrConfig?.last30DaysNdrReceivedByEmails?.length || 0}
                    getRowId={(row) => row.id}
                />
            </Grid>
        </>
    );
};

export default NDREmailConfig;



const EmailTime = ({ merchantId, ndrConfig, setLoading, setReload, setTimeVal, isNDREnabled }) => {
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                time: Yup.date()
                    .required('Time is required!')
                    .typeError('Time is required!'),
            })
        ),
        defaultValues: {
            time: ''
        }
    })
    const { handleSubmit, reset } = methods

    const onSubmit = (data) => {
        setLoading(true);
        let payload = {
            merchantOId: merchantId,
            last30DaysNdrReceivedByEmails: [...ndrConfig?.last30DaysNdrReceivedByEmails],
            last30DaysNdrReceivedByCCEmails: ["ProshipTechTeam@prozo.com"],
            isLast30DaysNdrEnabled: true,
            last30DaysNdrEmailCronTime: data.time ? parseTime2(data.time) : ''
        }
        postGeneric(`${apiConstants.UPDATE_NDR_EMAIL_CONFIG}`, payload).then(response => {
            setTimeVal(data.time);
            setReload(reload => reload + 1);
            reset()
        })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })

    }
    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <RHFDateTimeField mode="time"
                            name="time"
                            label="Update Email Time "
                            size='medium'
                            disabled={!ndrConfig?.last30DaysNdrReceivedByEmails?.length > 0 || !isNDREnabled}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button size="small"
                            variant="contained"
                            disabled={!ndrConfig?.last30DaysNdrReceivedByEmails?.length > 0 || !isNDREnabled}
                            type="submit"
                            color="info"
                        >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    )
}