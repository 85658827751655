import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ageing, formatNumber, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import _ from "lodash";
import InfoBox from "../../../../common/InfoBox";
import { DualAxisChart } from "../../../charts/CompositChart";
import MaterialTable from "../../../../common/MaterialTable";

export default function RTOAnalysis({ data }) {
    const [view, setView] = React.useState("chart")
    const { rows, allColumns } = transformChartDataToTable(data, [{ key: 'totalRtoCases', 'label': 'Total RTO' }, ...ageing.map(r => ({ key: r, label: r.replace("_", " ") }))])
    const [attemptData, setAttemptData] = useState();

    useEffect(() => {
        setAttemptData(data);
    }, [data]);

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'RTO Count',
                data: ageing.map((z) => ({ dataKey: z, label: z.replace('_', " "), chartType: 'line', fill: z==='0_Attempts'?'red':'' }))
            }
        },
    }

    const sums = {
        total_0_attempts: _.sumBy(data, '0_Attempts'),
        total_1_attempts: _.sumBy(data, '1_Attempts'),
        total_2_attempts: _.sumBy(data, '2_Attempts'),
        total_3_attempts: _.sumBy(data, '3_Attempts'),
        total_3_plus_attempts: _.sumBy(data, '3+_Attempts'),
    };

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return formatNumber(row[z])
            },
            label: z
        })))
    ]

    const [att, setAtt] = useState(5)

    const handleChange = (event) => {
        setAtt(event.target.value);
        let updatedArray = []
        if (event.target.value === 0) {
            const keysToRemove = ["1_Attempts", "2_Attempts", "3_Attempts", "3+_Attempts"];
            updatedArray = data.map(obj => _.omit(obj, keysToRemove));
        }
        if (event.target.value === 1) {
            const keysToRemove = ["0_Attempts", "2_Attempts", "3_Attempts", "3+_Attempts"];
            updatedArray = data.map(obj => _.omit(obj, keysToRemove));
        }
        if (event.target.value === 2) {
            const keysToRemove = ["0_Attempts", "1_Attempts", "3_Attempts", "3+_Attempts"];
            updatedArray = data.map(obj => _.omit(obj, keysToRemove));
        }
        if (event.target.value === 3) {
            const keysToRemove = ["0_Attempts", "1_Attempts", "2_Attempts", "3+_Attempts"];
            updatedArray = data.map(obj => _.omit(obj, keysToRemove));
        }
        if (event.target.value === 4) {
            const keysToRemove = ["0_Attempts", "1_Attempts", "2_Attempts", "3_Attempts"];
            updatedArray = data.map(obj => _.omit(obj, keysToRemove));
        }
        if (event.target.value === 5) {
            updatedArray = data;
        }
        setAttemptData(updatedArray)
    };

    return (
        <Card elevation={1}>
            <CardHeader title="RTO - Delivery Reattempt Analysis" subheader={
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={2.4}>
                        <InfoBox header="RTO / 0 Delivery Attempts" value={sums.total_0_attempts} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="RTO / 1 Delivery Attempts" value={sums.total_1_attempts} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="RTO / 2 Delivery Attempts" value={sums.total_2_attempts} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="RTO / 3 Delivery Attempts" value={sums.total_3_attempts} />
                    </Grid>
                    <Grid item xs={2.4}>
                        <InfoBox header="RTO / 3+ Delivery Attempts" value={sums.total_3_plus_attempts} />
                    </Grid>
                </Grid>
            } action={(
                <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                    <ToggleButton value="chart">
                        <BarChartIcon />
                    </ToggleButton>
                    <ToggleButton value="table">
                        <TableChartIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )} />
            <CardContent>
                {view === 'chart' && (
                    <React.Fragment>
                        <FormControl sx={{ width: '20%', ml: '70px', mb: '10px' }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Attempt</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={att}
                                label="Attempts"
                                onChange={handleChange}
                            >
                                <MenuItem value={5}>All</MenuItem>
                                <MenuItem value={0}>0 Attempts</MenuItem>
                                <MenuItem value={1}>1 Attempt</MenuItem>
                                <MenuItem value={2}>2 Attempts</MenuItem>
                                <MenuItem value={3}>3 Attempts</MenuItem>
                                <MenuItem value={4}>3+ Attempts</MenuItem>

                            </Select>
                        </FormControl>
                        <DualAxisChart data={attemptData} chartOptions={chartOptions} />
                    </React.Fragment>
                )}
                {view === 'table' && (
                    <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />
                )}
            </CardContent>
        </Card>
    )
}