import React, { useEffect, useState } from "react";

import "./create-order.css";
import {
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
} from "@mui/material";
import {
    formatDate,
} from "../../../helpers/UtilityHelper";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import MUIModal from "../../../common/MUIModal";
import { apiConstants, postGeneric } from "../../../common/constants";
import jwt_decode from "jwt-decode";
import { getToken } from "../../../helpers/ReduxHelpers";


export default function BulkOrderResults(props) {
    const { shipmentType, reload } = props;
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [seeOrders, setSeeOrders] = useState(false)
    const [orderData, setOrderData] = useState([])
    const userToken = jwt_decode(getToken());
    const merchantId = userToken && userToken.merchantId ? userToken.merchantId : "";

    useEffect(() => {
        let type = shipmentType === "B2B" ? "B2B_ORDER" : "ORDER";
        const request = {
            "collection": "bulk_orders_result",
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "andfilter": [
                {
                    "field": "type",
                    "operator": "eq",
                    "value": type
                },
                { field: "merchantId", operator: "eq", value: merchantId }
            ],
            "sort": {
                "field": "time",
                "direction": "DESC"
            }
        }
        postGeneric(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setData(res?.data?.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sizePerPage, page, reload, shipmentType])


    const columnsOrderModal = [
        {
            field: "reference",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Reference Number",
            width: 200,
        },
        {
            field: "awb",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "AWB",
            width: 200,
        },
        {
            field: "reason",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Message",
            width: 500,
        },
    ];

    const columns = [
        {
            field: '_id', headerAlign: "left", hideSortIcons: true, headerName: 'Id',
            align: 'left', flex:1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?._id}
                    </>
                )
            },
        },
        {
            field: 'file', headerAlign: "left", hideSortIcons: true, headerName: 'File Name',
            align: 'left', flex:1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.fileName}
                    </>
                )
            },
        },
        {
            field: 'status', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex:0.8, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        {row?.status === 'COMPLETED' ? 'Completed' : 'Stuck / Failed'}
                    </>
                )
            },
        },
        {
            field: 'type', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Order Type', flex:0.6, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        {row?.type}
                    </>
                )
            },
        },
        {
            field: 'time', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Upload Date', flex:1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        {formatDate(row?.time)}
                    </>
                )
            },
        },
        {
            field: 'totalOrders', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Total Orders', flex:0.5, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return row?.totalOrders
            },
        },
        {
            field: 'processed', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Processed Orders', flex:0.5, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return row?.processed

            },
        },
        {
            field: 'successOrders', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Successful Orders', flex:1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row?.successOrders?.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setOrderData(row?.successOrders);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Successful Orders : {row?.successOrders?.length}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        },
        {
            field: 'failedOrders', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Failed Orders', flex:1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row?.failedOrders?.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setOrderData(row?.failedOrders);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Failed Orders : {row?.failedOrders?.length}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        },
    ]


    console.log("data", data);
    return (
        <>
            <Card>
                <CardHeader title='Upload History' />
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        rows={data ? data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        getRowId={(row) => row._id}
                    />
                </CardContent>
            </Card>

            <MUIModal
                open={seeOrders}
                title="Upload Details"
                maxWidth={"lg"}
                handleClose={() => setSeeOrders(false)}
            >
                <ProzoDataGrid
                    // autoHeight={false}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    page={page}
                    setPage={setPage}
                    rowCount={orderData?.length}
                    columns={columnsOrderModal}
                    rows={orderData}
                    paginationMode={'client'}
                    pagination={true}
                    hideFooterPagination={false}
                    getRowId={(row) => Math.random()}
                />
            </MUIModal>
        </>
    )

}