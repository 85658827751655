import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import _ from 'lodash'
import { Toolbar, Tooltip } from '@mui/material'
import { DownloadExcelButton } from '../helpers/ExcelGenerator'
import { styled } from '@mui/system'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#212B36',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const { order, orderBy, frozenColumnsCount /* onRequestSort */ } = props
  /* const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }; */

  const headCells = props.columns || []

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <StyledTableCell
            key={i}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              // Apply sticky style conditionally
              ...(i < frozenColumnsCount
                ? {
                  position: 'sticky',
                  left: i * 50, // Define FIXED_COLUMN_WIDTH based on your design
                  zIndex: 3, // Ensure it's above the body cells
                  backgroundColor: '#fff', // Adjust as per your header design
                }
                : {}),
            }}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function MaterialTable({
  columns,
  rows,
  exportExcel = true,
  fileName = 'excelExport',
  frozenColumnsCount = 0,
  toolBarActions,
  minWidth = 750,
  noDataMessage = 'No data found to display',
  size = 'dense',
  itemsPerPage = 25,
}) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(itemsPerPage)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const { excelRows, excelColumns } = getExcelRowsColumns(rows, columns)

  return (
    <Box w={1}>
      {exportExcel && (
        <>
          <Toolbar
            className='export-excel-button'
            sx={{ justifyContent: 'flex-end', minHeight: '48px!important' }}
          >
            {toolBarActions}
            <Tooltip title='Download as excel document'>
              <div>
                <DownloadExcelButton columns={excelColumns} data={excelRows} filename={fileName} />
              </div>
            </Tooltip>
          </Toolbar>
        </>
      )}

      <TableContainer sx={{ maxHeight: 1200 }}>
        <Table stickyHeader sx={{ minWidth: minWidth }} size={size}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            frozenColumnsCount={frozenColumnsCount}
            columns={columns}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    {columns.map((column, index) => (
                      <>
                        <TableCell
                          align={column.align || 'center'}
                          key={index}
                          style={{
                            ...(_.isFunction(column.style) ? column.style(row) : {}),
                            // Apply sticky style conditionally
                            ...(index < frozenColumnsCount
                              ? {
                                position: 'sticky',
                                left: index * 50, // Define FIXED_COLUMN_WIDTH based on your design
                                zIndex: 1,
                                backgroundColor: '#fff', // or any color that suits your design
                              }
                              : {}),
                          }}
                        >
                          {_.isFunction(column.tableValue)
                            ? column.tableValue(row)
                            : _.isFunction(column.value)
                              ? column.value(row)
                              : row[column.value]}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align='right'>
                  {noDataMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

function getExcelRowsColumns(rows, columns) {
  const excelRows = []
  let excelColumns = columns.map((col) => {
    if (_.isFunction(col.value)) {
      col.uniqId = new Date().getTime()
    }

    return col
  })

  for (let r of rows) {
    let row = { ...r }
    for (let col of columns) {
      if (_.isFunction(col.value)) {
        row[col.uniqId] = col.value(row)
      }
    }

    excelRows.push(row)
  }

  excelColumns = excelColumns
    .map((col) => {
      let obj = { label: col.label, value: col.value }
      if (_.isFunction(col.value)) {
        obj.value = col.uniqId
      }

      return obj
    })
    .filter((col) => col.label.toLowerCase() !== 'action')

  return { excelRows, excelColumns }
}
