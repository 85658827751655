import { Button, Grid, Stack, Typography } from "@mui/material";
import { RHFDateField, RHFRadioGroup, RHFSearchableSelectField, RHFTextField } from "../../common/hook-form";
import React from "react";
import { useFormContext } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { useGetAllCourierPartnersQuery, useGetMerchantDetailsQuery } from "../../redux/commonRTK";

function OtherDetails({ setActiveStep, creatingOrder }) {
    const { watch, resetField } = useFormContext()

    const paymentMode = watch('payment_mode');
    const invoiceValue = watch('invoice_value');

    const askForCourier = invoiceValue > 50_000

    const currentUser = getMerchant();

    const requestPayload = {
        andfilter: [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        offset: 0,
        limit: 500,
        includes: "id,name,parent,prozoId"
    }

    const { data: merchant } = useGetMerchantDetailsQuery({ id: currentUser.id }, { skip: !askForCourier })
    const { data: allCouriers = [] } = useGetAllCourierPartnersQuery(requestPayload, { skip: !askForCourier });

    const b2bCheapestCPList = merchant?.b2bCheapestCPList || [];

    const validCouries = allCouriers.filter(co => b2bCheapestCPList.includes(co.prozoId)).map(co => ({
        label: co.name,
        value: co.prozoId
    }));

    React.useEffect(() => {
        resetField('courier_id');
    }, [askForCourier])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="caption" fontWeight={500}><strong>Note:</strong> If the invoice value exceeds 50,000, kindly furnish the E-waybill number along with its expiration date.</Typography>
            </Grid>
            <Grid item xs={3}>
                <RHFRadioGroup
                    label="Payment Mode"
                    name="payment_mode"
                    required
                    options={[
                        { label: 'PREPAID', value: 'PREPAID' },
                        { label: 'COD', value: 'COD' },
                    ]}
                />
            </Grid>

            <Grid item xs={3}>
                <RHFRadioGroup
                    label="Insured"
                    name="insured"
                    required
                    options={[
                        { label: 'Yes', value: "true" },
                        { label: 'No', value: "false" },
                    ]}
                />
            </Grid>

            <Grid item xs={3}>
                <RHFRadioGroup
                    label="Appointment Delivery"
                    name="appointment_delivery"
                    required
                    options={[
                        { label: 'Yes', value: "true" },
                        { label: 'No', value: "false" },
                    ]}
                />
            </Grid>
            <Grid item xs={3}>
                <RHFRadioGroup
                    label="Risk Type"
                    name="riskType"
                    required
                    options={[
                        { label: 'Owner', value: "OWNER" },
                        { label: 'Carrier', value: "CARRIER" },
                    ]}
                />
            </Grid>

            {(paymentMode === 'COD') && (
                <Grid item xs={4}>
                    <RHFTextField name="cod_amount" label="COD Amount" type="number" required variant="filled" autoFocus />
                </Grid>
            )}

            <Grid item xs={4}>
                <RHFTextField name="ewaybill" label="Ewaybill Serial Number" variant="filled" autoFocus />
            </Grid>

            <Grid item xs={4}>
                <RHFDateField name="ewb_exp_date" label="Ewaybill Expiry Date" />
            </Grid>

            {askForCourier && (
                <Grid item xs={6}>
                    <RHFSearchableSelectField
                        options={validCouries}
                        width="100%"
                        placeholder="Please Select Courier"
                        name="courier_id"
                        mt={0}
                        mb={0}
                    />
                </Grid>
            )}

            <Grid item xs={12} sx={{ mt: 2 }}>
                <Stack direction="row" columnGap={1}>
                    <Button disabled={creatingOrder} variant="contained" size="small" color="error" onClick={() => setActiveStep(2)}>Prev</Button>
                    <LoadingButton variant="contained" size="small" type="submit" loading={creatingOrder}>
                        Create Shipment
                    </LoadingButton>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default OtherDetails;