import { Box,  Typography } from '@mui/material'

export default function InfoBox(props) {
  let { header, value, tc = '#009EF7' } = props

  return (
    <Box
      sx={{
        minHeight: '80px',
        height: '100%',
        width: '100%',
        borderRadius: 1,
        border: '1px solid #009ef736',
        backgroundColor: '#E0F2F1',
        p:1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography color={tc} variant='h5'>{value}</Typography>
      <Typography color={tc} variant='body2' fontWeight={500}>{header}</Typography>
    </Box>
  )
}
