import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Card, CardContent, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import withLayout from "../../hocs/Layout";
import { apiConstants, getGeneric } from "../../common/constants";
import { getToken } from "../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";
import { useGetCouriersQuery } from "../../redux/commonRTKPersist";
import ForwardRate from "./Forward";
import ReverseRate from "./Reverse";
import RTORate from "./RTO";

const RateCard = () => {
  const [value, setValue] = useState("1");
  const [rateData, setRateData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  let { data: courierDetails = [], isLoading: isLoading3 } =
    useGetCouriersQuery();
  const userToken = jwt_decode(getToken());
  const merchantId =
    userToken && userToken.merchantOid ? userToken.merchantOid : "";

  useEffect(() => {
    getRateCardList(merchantId);
  }, []);

  const Region = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];

  const getRateCardList = (id) => {
    getGeneric(`${apiConstants.RATE_CARD}/${id}`)
      .then((response) => {
        const filterActive = response.data.sla?.filter(
          (item) => item.active === true
        );

        const arr = filterActive.map((item, index) => {
          const slaArr = Object.values(item?.sla);
          const zoneFilter = slaArr.filter((zone) =>
            Region.includes(zone.zoneName)
          );

          let type = "";
          courierDetails.filter((courier) =>
            courier.prozoId === item.cplist[0] ? (type = courier.type) : ""
          );

          const addType = zoneFilter.map((zone, index) => {
            return { ...zone, cp: item.cplist[0], type: type };
          });

          return addType;
        });

        setRateData(arr);
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || "Oops Something went wrong!",
          { variant: "error" }
        );
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabBox = {
    border: "1px solid #858585",
    borderRight: 0,
    borderLeft: 0,
    mt: 5,
  };

  const tabList = {
    px: 4,
    py: 2,
    "& .MuiTab-root.Mui-selected.Mui-selected": {
      color: "#ffffff",
    },
    "& .MuiTabs-indicator": {
      height: 55,
      backgroundColor: "#707070",
      borderRadius: 0.5,
      zIndex: -1,
      boxShadow: "3px 2px 7px rgba(67,67,67, .25)",
    },
  };

  const tabs = {
    fontWeight: "semi-bold",
    fontSize: 20,
    width: 265,
    color: "#858585",
    height: 55,
  };

  return (
    <div id="main-content" className="container-fluid">
      <Card sx={{ minWidth: 275 }}>
        <CardContent sx={{ px: 0 }}>
          <Typography
            sx={{ fontSize: 24, fontWeight: "bold", mb: 0, px: 4 }}
            gutterBottom
          >
            Rate Card
          </Typography>
          <Typography
            sx={{ mb: 1.5, fontSize: 12, px: 4 }}
            color="text.secondary"
          >
            See through all rates
          </Typography>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value} sx={{ px: 4 }}>
              <Box sx={tabBox}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={tabList}
                >
                  <Tab sx={tabs} label="Forward" value="1" />
                  <Tab sx={tabs} label="Reverse" value="2" />
                  <Tab sx={tabs} label="RTO" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ px: 4 }}>
                <Box display="flex" flexDirection="column">
                  <ForwardRate data={rateData} region={Region} />
                </Box>
              </TabPanel>
              <TabPanel sx={{ px: 4 }} value="2">
                <ReverseRate data={rateData} region={Region} />
              </TabPanel>
              <TabPanel sx={{ px: 4 }} value="3">
                <RTORate data={rateData} region={Region} />
              </TabPanel>
            </TabContext>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default withLayout(RateCard);
