import { getAccess, isAdmin } from "../app/helpers/ReduxHelpers";
import UnauthorizedAccess from "./UnAuthorized";

export default function ProtectedRoute({ role, children }) {
  const accessList = getAccess();
  const checkPassed = accessList.includes(role) || isAdmin();
  if (checkPassed) {
    return <>{children}</>;
  } else {
    return <UnauthorizedAccess />;
  }
}
