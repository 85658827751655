import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import withLayout from '../../../../hocs/Layout';
// import jwt_decode from "jwt-decode";
import '../create-order.css';
import { useSelector } from 'react-redux';
import PreviewOrderDetail from './previewOrderDetail';
import { useNavigate } from 'react-router-dom';
import { resetOrder } from '../../../../redux/reducers/orderCreate/createOrderSlice';

const PreviewOrder = () => {
    const navigate = useNavigate();
    const { success } = useSelector((state) => state.createOrderSlice);
    const awb_number = (success && success.result && success.result.awb_number) ? success.result.awb_number : "";
    useEffect(() => {
        resetOrder();
    }, []);
    return (
        <>
            <div id="main-content" className="container-fluid">
                <div className="row">
                    <div id="page-title" className="col-12">
                        <a href="#" onClick={() => navigate(`/create-order`)} className="page-title"><img src="img/back.svg" width="21px" alt="" />
                            Create Order
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-7 col-lg-9 d-flex justify-content-center align-items-center">
                        <div className="row mb-5">
                            <div className="col-12 col-md-10 offset-md-1 text-center order-confirmed"> <img src="img/confirm-order.svg" height="180" alt="" />
                                <h3>Order {awb_number} is confirmed</h3>
                                <hr />
                                <h4>What would you like to do now ?</h4>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <button type="button" style={{ "width": "auto" }} className="btn btn-outline-primary btn-cancel mt-3 me-lg-4" onClick={() => navigate(`/orders`)}>View all orders</button>
                                        <button type="button" style={{ "width": "auto" }} className="btn btn-primary btn-next mt-3" onClick={() => navigate(`/create-order`)}>Create new order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PreviewOrderDetail
                        orderDetail={success}
                    />
                </div>
            </div>
        </>
    )

}
export default withLayout(PreviewOrder);