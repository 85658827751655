import React from "react";
import { Button, Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material"
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { formatNumber, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { Box } from "@mui/system";
import { DualAxisChart } from "../../../charts/CompositChart";
import MaterialTable from "../../../../common/MaterialTable";

export default function SplitInLineChart({ data }) {
    console.log(data)

    const [showCount, setShowCount] = React.useState(false)
    const [view, setView] = React.useState("chart")

    const { rows, allColumns } = transformChartDataToTable(data, [
        { key: 'rtoPercentage', 'label': 'RTO %', order: 2 },
        { key: 'PREPAID', 'label': 'Prepaid %', order: 1 },
        { key: 'COD', 'label': 'COD %', order: 0 },
    ])

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'RTO %',
                data: [
                    {
                        dataKey: 'rtoPercentage',
                        unit: '%',
                        label: 'Overall RTO %',
                        chartType: 'line',
                    },
                    {
                        dataKey: 'COD',
                        unit: '%',
                        label: 'COD RTO %',
                        chartType: 'line'
                    },
                    {
                        dataKey: 'PREPAID',
                        unit: '%',
                        label: 'PREPAID RTO %',
                        chartType: 'line',
                        fill: 'red'

                    },
                ],
            },
        },
    }

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return formatNumber(row[z])
            },
            label: z
        })))
    ]

    if (showCount) {
        chartOptions.yaxis.right = {
            label: 'RTO Count',
            data: [
                {
                    dataKey: 'totalRtoCases',
                    label: 'Total RTO Count',
                    chartType: 'line',
                    fill: 'green'
                },
            ],
        }
    } else {
        delete chartOptions.yaxis.right
    }

    return (
        <Card elevation={1}>
            <CardHeader title="RTO Trends" action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={() => setShowCount(st => !st)} variant={showCount ? "contained" : "outlined"} sx={{ mr: 1 }}>Show Count</Button>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent>
                {(view === 'chart') && (<DualAxisChart data={data} chartOptions={chartOptions} />)}
                {(view === 'table') && (<MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />)}
            </CardContent>
        </Card>
    )
}