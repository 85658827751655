import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import InfoBox from "../../../../common/InfoBox"
import { formatNumber } from "../../../../helpers/UtilityHelper"
// import { ndrActionAgeing } from "../../../../helpers/UtilityHelper"
import { StackedBarChart } from "../../../charts/BarChart"

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    labelY: 'NDR Action Count',
    stackId: 'status',
    data: [
        { dataKey: 'Ageing_0_Day', label: '0', chartType: 'bar', fill: 'rgb(255, 11, 80)' },
        { dataKey: 'Ageing_1_Day', label: '1', chartType: 'bar', fill: 'rgb(255, 225, 91)' },
        { dataKey: 'Ageing_2_Days', label: '2', chartType: 'bar', fill: 'rgb(0, 167, 235)' },
        { dataKey: 'Ageing_3_Days', label: '3', chartType: 'bar', fill: 'black' },
        { dataKey: 'Ageing_4-5_Days', label: '4-5', chartType: 'bar', fill: '#C37DC4' },
        { dataKey: 'Ageing_6+_Days', label: '6+', chartType: 'bar', fill: 'rgb(0, 197, 153)' }
    ]
    // data: ndrActionAgeing.map((ag) => ({
    //     dataKey: `Ageing_${ag}`,
    //     label: ag.replace(/_Days|_Day|_/g, " "),
    //     chartType: 'bar'
    // }))
}
export default function ActionAgeing({ data }) {

    const summaryData = data?.lineChartData?.reduce((acc, curr) => {
        Object.keys(curr).forEach(key => {
            if (key.includes('Ageing')) {
                acc[key] = acc[key] ? acc[key] + curr[key] : curr[key]
            }
        })
        return acc
    }, {})

    function convertAgeingString(str) {
        return str.replace(/Ageing_([^_]+)_.*/, '$1 Action Count');
    }

    return (
        <Card elevation={1}>
            <CardHeader title="NDR User Actions Count Analysis" subheader={
                <Typography variant="body2">
                    This chart shows the count of actions (Reattempt or RTO) taken by the user on the NDR shipments.
                    <br /> A user action is an action take by the system (automated NDR rule), by the customer (NDR whatsapp flow), by proship ops team (NDR calling) or the brands customer service team (proship merchant panel).
                </Typography>
            } />
            <CardContent>
                <Grid container spacing={2}>
                    {Object.keys(summaryData).map((key, index) => (
                        <Grid item key={index} xs={3}>
                            <InfoBox header={convertAgeingString(key)} value={formatNumber(summaryData[key])} tc={key === 'Ageing_0_Day' ? 'red' : '#009EF7'} />
                        </Grid>
                    ))}


                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <StackedBarChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}