import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

RHFSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  disableOptions: PropTypes.array,
  multiSelect: PropTypes.bool,
  // Add other propTypes as necessary
};

export default function RHFSelectField({
  name,
  options = [],
  variant,
  label,
  disableOptions = [],
  size = 'small',
  width = 150,
  maxHeight = 300,
  noOptionsText = 'No options available',
  enableNoneSelect = false,
  multiSelect = false, // New prop for multi-selection
  ...other
}) {
  const { control } = useFormContext();
  const noOptionsAvailable = options.length === 0;
  const uniqueId = new Date().getTime();
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: maxHeight,
      },
    },
  };



  const outlinedInputStyles = {
    style: {
      paddingTop: '12px', // Increase padding to move selected options down
      paddingBottom: '0px', // Adjust as needed for visual balance
      height: '48px',
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          variant={variant || "filled"}
          error={!!error}
          sx={{ minWidth: width }}
        >
          <InputLabel shrink={true} id={`mui-select-${uniqueId}`} sx={{ fontSize: 14 }}>
            {label}
            {other.mandatory && <span style={{ color: "red" }}> *</span>}
          </InputLabel>
          <Select
            {...field}
            multiple={multiSelect} // Enable multi-select based on the prop
            labelId={`mui-select-${uniqueId}`}
            value={multiSelect ? (field.value || []) : field.value} // Handle value as an array if multi-select is enabled
            input={<OutlinedInput label={label} {...outlinedInputStyles}/>} // Apply the custom styles here
            fullWidth={true}
            size={size}
            MenuProps={menuProps}
            {...other}
            error={!!error}
          >
            {enableNoneSelect && !multiSelect && (
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
            )}

            {noOptionsAvailable ? (
              <MenuItem disabled value="">
                <em>{noOptionsText}</em>
              </MenuItem>
            ) : (
              options.map((option, index) => (
                <MenuItem
                  disabled={disableOptions.includes(option.value)}
                  key={index}
                  value={option.value}
                  sx={{ fontSize: 14 }}
                >
                  {option.label}
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText sx={{ fontSize: 12 }}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
