import React from "react";
import { Button, Card, CardContent, CardHeader, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { formatNumber, stringToColor, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { TableSkeleton } from "../../../../common/Skeletons";
import { Box } from "@mui/system";
import _ from "lodash";
import InfoBox from "../../../../common/InfoBox";
import { DualAxisChart } from "../../../charts/CompositChart";
import MaterialTable from "../../../../common/MaterialTable";
import useGetCourier from "../useGetCourier";

export default function CourierWiseSplit({ data, courierIds }) {

    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()
    const [view, setView] = React.useState("chart")
    const [showCount, setShowCount] = React.useState(false)

    if (isLoadingCouriers || isFetchingCouriers) return <TableSkeleton rows={10} />

    const allPresentCouriers = allCouriers.filter(row => courierIds.includes(row.id))

    const { rows, allColumns } = transformChartDataToTable(data, [
        { key: 'totalRtoCases', 'label': 'Total RTO' },
        ...allPresentCouriers.map(z => ({ key: `c_${z.id}_value`, label: `${z.parent}/${z.id}` }))
    ])

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'RTO Count',
                data: [
                    {
                        dataKey: 'totalRtoCases',
                        label: 'RTO Count',
                        chartType: 'line',
                        fill: 'red'
                    },
                ]
            }
        },
    }

    const tableColumns = [
        {
            label: 'Name',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return formatNumber(row[z])
            },
            label: z
        })))
    ]

    if (showCount) {
        chartOptions.yaxis.right = {
            label: 'RTO Count',
            data: allPresentCouriers.map((z) => ({ dataKey: `c_${z.id}_perc`, label: `${z.parent}/${z.id}`, chartType: 'line', unit: '%', fill: stringToColor(z.name) }))
        }
    } else {
        delete chartOptions.yaxis.right
    }

    const infoBoxArray = [];

    allPresentCouriers.forEach(item => {
        infoBoxArray.push({
            courier_name: item.name,
            total_count: _.sumBy(data, "c_" + item.id + '_value'),
        });
    });

    return (
        <Card elevation={1}>
            <CardHeader title="RTO - Courier Wise Analysis" action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={() => setShowCount(st => !st)} variant={showCount ? "contained" : "outlined"} sx={{ mr: 1 }}>Show All Courier</Button>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent>
                <Grid container spacing={2}>
                    {_.orderBy(infoBoxArray, "total_count", "desc").map((item, index) => (
                        <Grid item xs={2} key={index}>
                            <InfoBox header={item?.courier_name?.replace(/_/g, " ")} value={item.total_count} />
                        </Grid>
                    ))}

                    {(view === 'chart') && (
                        <Grid item xs={12}>
                            <DualAxisChart data={data} chartOptions={chartOptions} />
                        </Grid>
                    )}
                    {(view === 'table') && (
                        <Grid item xs={12}>
                            <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}