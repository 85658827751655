import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import withLayout from "../../../hocs/Layout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { useGetChannelOrderQuery } from "../../../redux/commonRTK";
import UnfulfilledChannelOrders from "./UnfulfilledChannelOrders";
import FulfilledChannelOrders from "./fulfilledChannelOrders";
import PageTitle from "../../../common/PageTitle";

function ChannelOrders() {
  const { data } = useGetChannelOrderQuery({
    limit: 1,
    offset: 0,
    includes:"id",
    andfilter: [
      {
        field: "channelOrderStatus",
        operator: "eq",
        value: "FULFILLED",
      },
    ],
  });
  const { data: dataUnfulfilled } = useGetChannelOrderQuery({
    limit: 1,
    offset: 0,
    includes:"id",
    andfilter: [
      {
        field: "channelOrderStatus",
        operator: "in",
        value: ["CREATED", "CANCELLED", "HOLD", "DELETED", "RELEASE"],
      },
    ],
  });

  const [value, setValue] = React.useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="main-content" className="container-fluid">
      <PageTitle>Channel Orders</PageTitle>
      <TabContext value={value}>
        <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
          <TabList
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            sx={{
              "& .MuiTab-root": {
                px: 7,
                py: 2,
              },
            }}
          >
            <Tab
              icon={
                <Badge
                  max={999}
                  badgeContent={dataUnfulfilled?.count}
                  color="error"
                >
                  <PendingActionsIcon color="error" />
                </Badge>
              }
              iconPosition="top"
              label="Unfulfilled"
              value="1"
            />
            <Tab
              label="Fulfilled"
              value="2"
              icon={
                <Badge max={999} badgeContent={data?.count} color="primary">
                  <LocalShippingIcon color="primary" />
                </Badge>
              }
              iconPosition="top"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ px: 0 }}>
          <UnfulfilledChannelOrders />
        </TabPanel>
        <TabPanel value="2" sx={{ px: 0 }}>
          <FulfilledChannelOrders />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default withLayout(ChannelOrders);
