import { createSlice } from "@reduxjs/toolkit"

const initialState = []

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        insertNotification: (state, { payload }) => {
            console.log(payload)
            const notificationId = "ID__" + new Date().getTime()
            state = [...state, {
                ...payload,
                notificationId,
                view: false
            }]

            return state;
        },
        markAsView: (state, { payload }) => {
            const { notificationId } = payload
            state = [...state].map((st) => {
                let notification = { ...st }
                if (notification.notificationId === notificationId) {
                    notification.view = true
                }
                return notification;
            })
            
            return state;
        }
    }
})

export const { insertNotification, markAsView } = notificationSlice.actions
export default notificationSlice.reducer