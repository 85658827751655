import { TabContext, TabList, TabPanel } from "@mui/lab";
import {  Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import withLayout from "../../../hocs/Layout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PendingPickups from "./PendingPickups";
import ManifestList from "./ManifestList";
import { UploadFileOutlined } from "@mui/icons-material";
import BulkManifest from "./BulkManifest";

function Manifest() {
    const [value, setValue] = React.useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="main-content" className="container-fluid">
      <TabContext value={value}>
        <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
          <TabList
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            sx={{
              "& .MuiTab-root": {
                px: 7,
                py: 2,
              },
            }}
          >
            <Tab
              icon={
                  <PendingActionsIcon color="error" />
                
              }
              iconPosition="top"
              label="Pending Pickups"
              value="1"
            />
            <Tab
              label="Manifest"
              value="2"
              icon={
                  <LocalShippingIcon color="primary" />
              }
              iconPosition="top"
            />
            <Tab
              label="Bulk Upload Manifest"
              value="3"
              icon={
                <UploadFileOutlined color="success" />
              }
              iconPosition="top"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ px: 0 }}>
          <PendingPickups />
        </TabPanel>
        <TabPanel value="2" sx={{ px: 0 }}>
          <ManifestList />
        </TabPanel>
        <TabPanel value="3" sx={{ px: 0 }}>
          <BulkManifest />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default withLayout(Manifest);
