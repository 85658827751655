/** @format */

import { Box, Button, Grid, IconButton } from "@mui/material";
import * as React from "react";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { apiConstants } from "../../common/constants";
import { useSnackbar } from "notistack";
import { decodeAuthToken } from "../../helpers/UtilityHelper";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";
import { fetchReportData } from "../../redux/actions/reportAction";

export default function LoginForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        username: Yup.string().required("Please enter username"),
        password: Yup.string().required("Please enter password"),
      })
    ),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { handleSubmit, getValues } = methods;
  const navigate = useNavigate();

  const onSubmit = async (reqData) => {
    setIsLoading(true);

    try {
      let config = { headers: { "Content-Type": "application/json" } };
      const loginUrl = `${apiConstants.BASE_LOCAL}${apiConstants.AUTH}`;
      const authResponse = await axios.post(loginUrl, reqData, config);
      const { data: dataLogin } = authResponse;

      // store user's token in local storage
      localStorage.setItem("userToken", dataLogin.accessToken);
      const { merchantOid, roles } = decodeAuthToken(dataLogin.accessToken);

      // restrict ROLE_PROSHIPADMIN to login
      if (roles?.length > 0 && roles?.includes('ROLE_PROSHIPADMIN')) {
        setIsLoading(false);
        dispatch({ type: 'USER_LOGGED_OUT' })
        enqueueSnackbar("Access Denied", { variant: "error" });
        return;
      }


      let merchantData;
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
      };

      let merchantUrl =
        apiConstants.BASE_LOCAL + apiConstants.GET_MERCHANT_SEARCH;
      const { data } = await axios.post(
        merchantUrl,
        {
          offset: 0,
          limit: 1,
          orfilter: [],
          andfilter: [{ field: "id", operator: "eq", value: merchantOid }],
          excludes: "mappedOrderRule,sla,codRemittanceConfig",
        },
        config
      );
      merchantData = data.data[0];
      console.log(merchantData);
      delete merchantData.codRemittanceConfig;
      delete merchantData.mappedOrderRule;
      delete merchantData.wallet;
      delete merchantData.pickup_details;
      delete merchantData.bank_details;
      delete merchantData.alertSubscription;
      delete merchantData.gstDetails;
      delete merchantData.merchantWebHooks;
      delete merchantData.activeSlas;
      delete merchantData.alertSubscriptionB2B;
      const userInfo = { ...dataLogin, merchant: merchantData };
      dispatch(login(userInfo));
      dispatch(fetchReportData(merchantOid));
    } catch (e) {
      console.error(e);
      if (e.response?.status === 401) {
        enqueueSnackbar("Invalid username or password", { variant: "error" });
      } else {
        enqueueSnackbar(e.response.data?.error || "Login failed", {
          variant: "error",
        });
      }
    }
    setIsLoading(false);
  };

  const forgotPasswordHandler = () => {
    let url = "/forgot-password";
    const { username } = getValues();
    if (username !== "") {
      url += `?username=${username}`;
    }

    navigate(url);
  };

  return (
    <div className='card card-login shadow-none border-0 p-0 bg-transparent'>
      <div className='card-body'>
        <h1>Login to Proship</h1>
        <div className='d-flex align-items-center justify-center mt-5 p-2'>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <RHFTextField
                  name='username'
                  label='Email Address'
                  size='large'
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowPassword((v) => !v)}>
                        <VisibilityOffIcon />
                      </IconButton>
                    ),
                  }}
                  name='password'
                  size='large'
                  label='Password'
                  type={showPassword ? "text" : "password"}
                />
                <Box sx={{ width: "100%", display: "flex", my: 3 }}>
                  <LoadingButton
                    loading={isLoading}
                    type='submit'
                    startIcon={<LoginIcon />}
                    size='large'
                    color='warning'
                    variant='contained'>
                    Login
                  </LoadingButton>
                  <Button
                    sx={{ ml: 2, textTransform: "capitalize", fontWeight: 500 }}
                    onClick={forgotPasswordHandler}
                    variant='text'>
                    Forgot password
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
