import React from "react"
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { useGetSelectedFilters } from "../../../../common/contexts/Filters"
import _ from 'lodash'
import { flattenResponseData, formatDate, getOrderStatus, getShipmentInfo, getYYYYMMDD, grpupedOrderStatus, numberPrecision, placed } from "../../../../helpers/UtilityHelper"
import { addDays, addMonths, addWeeks, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from "date-fns"
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK"
import { apiConstants } from "../../../../common/constants"
import { getMerchant } from "../../../../helpers/ReduxHelpers"
import { useSelector } from "react-redux"

const weekStartsOn = 1

export default function useOverallPerformance(groupOn, shipmentType) {
    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()
    const { userInfo } = useSelector((state) => state.user)
    const shipmentInfo = getShipmentInfo(userInfo, shipmentType);

    const skip = !from_date || !to_date
    const request = { from_date, to_date, merchant_list: id, orderType_list: 'FORWARD', shipmentType_list: shipmentInfo }

    const { data: allRegisteredForward, isLoading: isLoadingReg, isFetching: isFetchingReg } = useGenericPostRequestQuery({
        url: apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE,
        data: request
    }, { skip });

    const [isCalc, setIsCalc] = React.useState(true)
    const result = React.useMemo(() => {

        if (isFetchingReg || isLoadingReg /* || isFetchingReg1 || isLoadingReg1 */) {
            setIsCalc(true)
            return;
        }
        const allRegistered = [...allRegisteredForward]
        const allRegisteredData = _.filter(flattenResponseData(allRegistered), filters)
        const allForwardData = _.filter(allRegisteredData, { orderType: 'FORWARD SHIPMENT' })
        const allForwardDelivered = _.filter(allForwardData, { orderStatus: 'DELIVERED' })
        const nonPickedUp = _.filter(allForwardData, (item) =>
            _.includes(['CANCELLED_ORDER', ...placed], item.orderStatus)
        );

        const pickedUp = _.reject(allForwardData, (item) =>
            _.includes(['CANCELLED_ORDER', ...placed], item.orderStatus)
        );
        const allRVPData = _.filter(allRegisteredData, { orderType: 'REVERSE SHIPMENT' })

        const allLocalData = _.filter(pickedUp, { merchantZone: 'LOCAL' })

        const allRegionalData = _.filter(pickedUp, { merchantZone: 'REGIONAL' })
        const allROIData = _.filter(pickedUp, { merchantZone: 'ROI' })
        const allMetroData = _.filter(pickedUp, { merchantZone: 'METROS' })
        const allSpecialData = _.filter(pickedUp, { merchantZone: 'SPECIAL' })
        const groupedByPaymentMode = _.groupBy(allRegisteredData, function (item) {
            return item.paymentMode.toUpperCase()
        })

        let intervalArr = [],
            awbRegisteredOrderCount = allRegisteredData.length,
            pickedUpOrderCount = pickedUp.length,
            lineChartData = [],
            forwardOrderCount = allForwardData?.length,
            reverseOrderCount = allRVPData?.length,
            localOrderCount = allLocalData?.length,
            regionalOrderCount = allRegionalData?.length,
            roiOrderCount = allROIData?.length,
            metroOrderCount = allMetroData?.length,
            specialOrderCount = allSpecialData?.length,
            prepaidPercentage = ((groupedByPaymentMode?.PREPAID?.length || 0) / awbRegisteredOrderCount) * 100,
            codPercentage = ((groupedByPaymentMode?.COD?.length || 0) / awbRegisteredOrderCount) * 100,
            allForwardDeliveredPercentage = (allForwardDelivered.length / (forwardOrderCount - nonPickedUp?.length)) * 100,
            uniqCourierIds = _(allForwardData).uniqBy('courier').map(function (i) {
                return i.courier
            }).value();

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allRegisteredDataGroupedByDate = _.groupBy(allRegisteredData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        const groupedByStatus = _(allRegisteredData)
            .groupBy(function (item) {
                return getOrderStatus(item.orderStatus)
            })
            .map(function (items, status) {
                return { status, count: items?.length }
            })
            .value()

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()

            let name = null;
            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }

            const row = { name }
            const allRegisteredDatesBetween = _.filter(Object.keys(allRegisteredDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })
            
            const allRegisteredDataBetweenDate1 = allRegisteredDatesBetween.map(key => allRegisteredDataGroupedByDate[key]).flat()
            const allRegisteredDataBetweenDate = _.reject(allRegisteredDataBetweenDate1, (item) =>
                _.includes(['CANCELLED_ORDER', 'AWB_REGISTERED', 'PICKUP_PENDING', 'PICKUP_FAILED'], item.orderStatus));

            row.totalCount = allRegisteredDataBetweenDate.length
            row.totalShippingCostForward = _.sumBy(allRegisteredDataBetweenDate, (item) => {
                const price = parseFloat(item.price);
                return isNaN(price) ? 0 : price;
            });
            
            // fetch this value from rto delivered snapshot instead
            row.totalShippingCostRTO = _.sumBy(allRegisteredDataBetweenDate, (item) => {
                const priceRTO = parseFloat(item.priceRTO);
                return isNaN(priceRTO) ? 0 : priceRTO;
            });
            
            row.totalRevenue = _.sumBy(allRegisteredDataBetweenDate, (item) => {
                const invoiceValue = parseFloat(item.invoiceValue);
                return isNaN(invoiceValue) ? 0 : invoiceValue;
            });

            row.shippingCostPercent = Number(numberPrecision((row.totalShippingCostForward + row.totalShippingCostRTO) * 100 / row.totalRevenue, 2));

            const groupedByZone = _(allRegisteredDataBetweenDate)
                .groupBy('merchantZone')
                .map(function (data, zone) {
                    return { zone, value: data.length }
                }).value()

            for (const data of groupedByZone) {
                row[data.zone] = data.value
            }

            const grouedByStatus = _(allRegisteredDataBetweenDate)
                .groupBy(function (item) {
                    return getOrderStatus(item.orderStatus)
                }).value()

            for (const st of grpupedOrderStatus) {
                row[st] = grouedByStatus[st]?.length || 0
            }

            const delivered = _.get(grouedByStatus, 'DELIVERED', []).length;
            const inTransit = _.get(grouedByStatus, 'INTRANSIT', []).length;
            const deliveryFailed = _.get(grouedByStatus, 'DELIVERY_FAILED', []).length;
            const rto = _.get(grouedByStatus, 'RTO', []).length;
            const lost = _.get(grouedByStatus, 'LOST', []).length;
            const pickedUp = inTransit + deliveryFailed + rto + lost;

            row.deliveredOrders = delivered;
            row.pickedUpOrders = pickedUp + delivered;

            row.deliveryPercentage = Math.round((delivered / (delivered + pickedUp)) * 100) || 0;

            const groupedByCourier = _.groupBy(allRegisteredDataBetweenDate1, 'courier')

            for (const k of uniqCourierIds) {
                const count = groupedByCourier[k]?.length || 0
                row[`c_${k}_value`] = count;
                row[`c_${k}_perc`] = Number(numberPrecision((count / row.totalCount) * 100))
            }

            lineChartData.push(row)
        }

        const results = { allForwardDeliveredPercentage, allRegisteredData, groupedByStatus, prepaidPercentage, codPercentage, awbRegisteredOrderCount, pickedUpOrderCount, lineChartData, allRegisteredDataGroupedByDate, allRegistered, forwardOrderCount, reverseOrderCount, localOrderCount, regionalOrderCount, roiOrderCount, metroOrderCount, specialOrderCount, uniqCourierIds }

        setIsCalc(false)
        return results;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from_date, to_date, allRegisteredForward, isLoadingReg, isFetchingReg, groupOn, filters])

    if (isCalc) return { isLoading: isCalc }
    return { data: result, isLoading: isCalc };

}