export default function Chip() {
  return {
    MuiChip: {
      styleOverrides: {
        label: {
          textTransform: 'capitalize',
        }
      }
    }
  }
}
