import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { formatDate } from "../../../helpers/UtilityHelper";

function StatusLog(props) {
  const { id } = props;
  const data = id.statusLog;
  // const newData = data.map((item) => {
  //   item.id = Math.random() * 1000;
  // });

  const columns = [
    {
      field: "status",
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      headerName: "Status",
      flex:1,
      renderCell: (params) => {
        const { row } = params;
        return <>{row.status}</>;
      },
    },
    {
      field: "date",
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      headerName: "Date",
      flex:0.8,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row.date)}</>;
      },
    },
    {
      field: "remark",
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      headerName: "Remarks",
      flex:1.5,
      renderCell: (params) => {
        const { row } = params;
        return <>{row.remark}</>;
      },
    },
    {
      field: "submittedBy",
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      headerName: "Submitted By",
      flex:1,
      renderCell: (params) => {
        const { row } = params;
        return <>{row.submittedBy}</>;
      },
    },
  ];
  return (
    <ProzoDataGrid
      columns={columns}
      autoHeight={true}
      rows={data || []}
      pagination={false}
      hideFooterPagination={true}
      rowHeight={120}
      getRowId={(row) => row?.date+row?.status}
    />
  );
}

export default StatusLog;
