import { useGetAllCourierPartnersQuery } from "../../../redux/commonRTK";

function useGetCourier() {
    const requestPayload = {
        andfilter: [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        offset: 0,
        limit: 500,
        includes: "id,name,parent,prozoId"
    }

    return useGetAllCourierPartnersQuery(requestPayload)
}

export default useGetCourier;