import * as React from "react"
import { Grid } from "@mui/material";
import usePerformanceDashboard from "../../common/hooks/dashboards/usePerformanceDashboard";
import Allocation from "./dashboard_sections/Allocation";
import AllocationCourierWiseMOP from "./dashboard_sections/AllocationCourierWiseMOP";
import MonthStatusWiseSplit from "./dashboard_sections/MonthStatusWiseSplit";
import AttemptedWiseTAT from "./dashboard_sections/AttemptedWiseTAT";
import MonthCourierWiseStatus from "./dashboard_sections/MonthCourierWiseStatus";
import MonthCourierWiseZone from "./dashboard_sections/MonthCourierWiseZone";
import ActualDeliveredTATWise from "./dashboard_sections/ActualDeliveredTATWise";
import MonthTotalWiseSplit from "./dashboard_sections/MonthTotalWiseSplit";

function ForwardAnalysis({ datesAsFilter, selectedDates }) {

    const { data = [], statusWiseSplit = [], isLoading, allOrders } = usePerformanceDashboard(datesAsFilter);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MonthTotalWiseSplit
                    isLoading={isLoading}
                    data={allOrders}
                    selectedDates={selectedDates}
                />
            </Grid>
            <Grid item xs={12}>
                <Allocation
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>
            <Grid item xs={12}>
                <AttemptedWiseTAT
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>
            <Grid item xs={12}>
                <ActualDeliveredTATWise
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>
            
            <Grid item xs={12}>
                <MonthStatusWiseSplit
                    isLoading={isLoading}
                    data={statusWiseSplit}
                    selectedDates={selectedDates}
                />
            </Grid>
            <Grid item xs={12}>
                <AllocationCourierWiseMOP
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>


            <Grid item xs={12}>
                <MonthCourierWiseStatus
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>
            <Grid item xs={12}>
                <MonthCourierWiseZone
                    isLoading={isLoading}
                    data={data}
                    selectedDates={selectedDates}
                />
            </Grid>
        </Grid>
    )
}

export default ForwardAnalysis;