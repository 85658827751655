import { IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { exportTableAsExcel } from "../helpers/UtilityHelper";

export default function ExcelDownloadButton({ ele, fileName, sheetName }) {

    const clickHandler = () => {
        exportTableAsExcel(ele.current, sheetName, fileName);
    }

    return (
        <IconButton size="small" onClick={clickHandler} sx={{ ml: 1 }} color="primary">
            <DownloadIcon />
        </IconButton>
    )
}