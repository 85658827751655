import React, { useEffect, useState } from "react";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Box, Button, Grid, Typography } from "@mui/material";
import PageTitle from "../../../common/PageTitle";
import { apiConstants, postGeneric } from "../../../common/constants";
import { parseDateAndTime } from "../../../helpers/UtilityHelper";
import MUIModal from "../../../common/MUIModal";
import ApproveRequest from "./Action";
import { Link } from "react-router-dom";
import Loader from "../../../common/Loader"
import { getMerchant } from "../../../helpers/ReduxHelpers";


const PendingOrders = () => {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [data, setData] = useState([])
    const [dataCount, setCount] = useState(0)
    const [itemModalData, setItemModalData] = useState();
    const [approveModalData, setApproveModalData] = useState();
    const [approveModaOpen, setApproveModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const merchantId = getMerchant().id;

    useEffect(() => {
        setIsLoading(true)
        const payload = {
            "andfilter": [
                {
                    "field": "itemData.isRequestPlaced",
                    "operator": "eq",
                    "value": true
                },
                {
                    "field": "merchantOid",
                    "operator": "eq",
                    "value": merchantId
                }
            ],
            "orfilter": [
                {
                    "field": "itemData.remainingActionableItems",
                    "operator": "gt",
                    "value": 0
                }
            ],
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "controllerFilter": true,
            "excludes": "otp"
        }
        postGeneric(`${apiConstants.RETURN_EXCHANGE_ORDER_LIST}?conditionFlag=Pending&offset=${page * sizePerPage}&limit=${sizePerPage}`, payload).then((res) => {
            setCount(res.data?.resultData?.count)
            setData(res.data?.resultData?.data)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
            setIsLoading(false)
        })
    }, [page, sizePerPage, reload, merchantId])

    const columns = [
        {
            field: "awbNumber",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Forward AWB Number",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                {/* {row.awbNumber} */}
                                <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row?.awbNumber}`}>
                                    {row?.awbNumber}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: "clientOrderId",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Client Order ID",
            flex: 0.7,
        },
        {
            field: "orderRequestedDate",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Return Request Recieve Date",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row.orderRequestedDate)
            },
        },
        {
            field: "createdDate",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row.createdDate)
            },
        },
        {
            field: "deliveryDate",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Delivery Date",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row.deliveryDate)
            },
        },
        {
            field: "isAllOrderReturn",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Return Type",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row.isAllOrderReturn ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="body2">
                            Order wise return
                        </Typography>
                        <Typography variant="body2" fontWeight="bold">
                            Reason : {row?.rvpReason}
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="body2" >
                        SKU wise return
                    </Typography>
                );

            },
        },
        {
            field: "action",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Action",
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setApproveModalData(row.itemData);
                                    setItemModalData(row);
                                    setApproveModalOpen(true);
                                }}
                            >
                                View Items
                            </Button>
                        </Grid>
                    </Grid>
                )
            },
        },
    ]
    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>Return Requests</PageTitle>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={data?.length > 0 ? data?.map((val, index) => ({ ...val, id: index })) : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        rowHeight={180}
                        // rowCount={Number.MAX_VALUE}
                        rowCount={dataCount ? dataCount : Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid>
            <MUIModal title='Manage Return Request' open={approveModaOpen} handleClose={() => setApproveModalOpen(false)} children={<ApproveRequest approveModalData={approveModalData} itemModalData={itemModalData} setApproveModalOpen={setApproveModalOpen} setReload={setReload} />} />
        </>
    );
};

export default PendingOrders
