import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../../common/constants";

export const createBulkOrder= createAsyncThunk(
    'createBulkOrder',
    async (param, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+localStorage.getItem('userToken')
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.BULK_UPLOAD_ORDER}`;
            //console.log(param);return false;
            const formData = new FormData();
            formData.append('file', param);
            formData.append('fileName', param.name);
            const { data } = await axios.post(url,formData,config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);