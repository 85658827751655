import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { post, apiConstants } from '../../../common/constants';
import withLayout from '../../../hocs/Layout';
import './courier.css';
import CourierItems from './courierItem';
import Loader from '../../../common/Loader';
const headers = [
    { label: "Name", key: "name" },
    { label: "Type", key: "type" }
];

const ListCourier = () => {
    const limit = 10;
    const [courierList, setCouriers] = useState([]);
    const [remaining, setRemaining] = useState(0);
    const [count, setCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop !==
            document.documentElement.offsetHeight
        ) {
            return;
        }
        else {
            if (remaining !== 0) {
                setOffset(offset + limit);
                setLoading(true);
                post(`${apiConstants.BASE_LOCAL}${apiConstants.COURIER_LIST}`, {
                    "andfilter":[{"field":"isDeleted","operator":"eq","value":"0"}],
                    offset: (offset + limit),
                    limit: limit,
                }).then(response => {
                    setLoading(false);
                    setCouriers([...courierList, ...response.data.data]);
                    //setRemaining(response.data.remaining);
                })
                    .catch(err => console.error(err));
            }

        }
    }
    useEffect(() => {
        setLoading(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.COURIER_LIST}`, {
            "andfilter":[{"field":"isDeleted","operator":"eq","value":"0"}],
            offset: offset,
            limit: limit,
        }).then(response => {
            setLoading(false);
            setCouriers(response.data.data);
            setCount(response.data.count);
            setRemaining(response.data.remaining);
        })
            .catch(err => console.error(err));
    }, []);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [courierList, offset, remaining]);
    // console.log("courierList", courierList);
    return (
        <>
            <div id="main-content" className="container-fluid">
                {loading &&
                    <Loader />
                }
                <div className="row">
                    <div id="page-title" className="col-12 col-md-3 col-lg-3">
                        <a href="dashboard.htm" className="page-title">
                            <img src="img/back.svg" width="21px" alt="" /> Courier Listing
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-9 text-end mb-3 mb-lg-0">
                        <a href="#" className="btn btn-link">
                            <img src="img/search.svg" width="22" alt="" />
                            <span className="d-none d-md-inline-block">Search</span>
                        </a>
                        <a href="#" className="btn btn-link">
                            <img src="img/filter.svg" width="22" alt="" />
                            <span className="d-none d-md-inline-block">Filter</span>
                        </a>
                        <div className="btn btn-link">
                            <img src="img/download.svg" width="22" alt="" />
                            <CSVLink
                                data={(courierList && courierList.length > 0) ? courierList : []}
                                headers={headers}
                                className="d-none d-md-inline-block"
                                filename={"courier-list.csv"}
                            >
                                Download
                            </CSVLink>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-courier">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-6 col-md-4 col-lg-auto mb-4 mb-md-0 me-0 me-lg-4">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0"> <img src="img/courier2.svg" height="38" alt="" /> </div>
                                                    <div className="flex-grow-1 ms-3"> <a href="#">
                                                        <h4 className="m-0">{count}</h4>
                                                        All couriers</a></div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-1 mb-4 mb-md-0 me-0 me-lg-4">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0"> <img src="img/active-courier.svg" width="42" height="38" alt="" /> </div>
                                                    <div className="flex-grow-1 ms-3"> <a href="#">
                                                        <h4 className="m-0">*02</h4>
                                                        Active</a></div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-1 mb-4 mb-md-0 me-0 me-lg-4">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0"> <img src="img/inactive-courier.svg" width="42" height="38" alt="" /> </div>
                                                    <div className="flex-grow-1 ms-3"> <a href="#">
                                                        <h4 className="m-0">*02</h4>
                                                        Inactive</a></div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-auto mt-md-3 mt-lg-0 mb-4 mb-md-0 border-start border-secondary">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 ms-lg-5"> <a href="#">
                                                        <h4 className="m-0">*26500</h4>
                                                        Total pincodes</a></div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-auto mt-md-3 mt-lg-0 mb-4 mb-md-0">
                                                <div className="d-flex align-items-center ms-lg-4">
                                                    <div className="flex-grow-1"> <a href="#">
                                                        <h4 className="m-0">*23500</h4>
                                                        Serviceable pins</a></div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-auto mt-md-3 mt-lg-0 mb-4 mb-md-0">
                                                <div className="d-flex align-items-center ms-lg-4">
                                                    <div className="flex-grow-1"> <a href="#">
                                                        <h4 className="m-0">*2500</h4>
                                                        Unserviceable pins</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Serviceable pincodes</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <CourierItems courierList={courierList} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default withLayout(ListCourier);