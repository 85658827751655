import React, { useEffect, useState } from 'react';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Button, Chip, Grid } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { parseDateAndTime } from '../../helpers/UtilityHelper';
import Loader from '../../common/Loader';
import { useGetNPRDataQuery } from '../../redux/commonRTK';
import { buildFilterConditions } from '../../helpers/UtilityHelper';
import { apiConstants, post } from '../../common/constants';
import { useSnackbar } from "notistack";
import { Link } from 'react-router-dom';
import PageTitle from '../../common/PageTitle';
import NprAction from './action';
import ConfirmDialog from '../../common/ConfirmDialog';

const PendingNprData = (props) => {

    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [pendingNdrData, setPendingNDRData] = useState({});
    const { enqueueSnackbar } = useSnackbar()
    const [currentNDRId, setCurrentNDRId] = useState({});
    const [currentNDRAction, setCurrentNDRAction] = useState();
    const [actionShow, setActionShow] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [reload, setReload] = useState(0)
    const [cancelConfirm, setCancelConfirm] = React.useState(false);

    const exclude =
        "terminalStatus,shipmentDetail.cartonItems,invoiceNumber,shipmentType,returnInfo,labelUrl,dispatchMode,giftwrapCharge,transactionCharge,shippingCharge,isInsured,codType,riskType,appointmentDelivery,async,order_date,courierDetail.key,createdDate,actualPickUpTime,lastStatusUpdateTime,epd,edd,order_history,merchantPricing,merchant,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,itemList,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,modifiedByUser,lastModifiedDate,courier";
    const filterConditions = buildFilterConditions(props.filters);
    const { data: pendingData, isLoading, refetch } = useGetNPRDataQuery({
        andfilter: [
            {
                "field": "orderStatus",
                "operator": "in",
                "value": [
                    "PICKUP_FAILED"
                ]
            },
            {
                "field": "is_reverse",
                "operator": "eq",
                "value": true

            },
            {
                "field": "nprData.nprStatus",
                "operator": "in",
                "value": [
                    "REPORTED"
                ]
            },
            ...filterConditions.andfilter
        ],
        orfilter: [...filterConditions.orfilter],
        offset: page * sizePerPage,
        limit: sizePerPage,
        skipCount: true,
        controllerFilter: true,
        excludes: exclude,
    })

    useEffect(() => {
        setPage(0)
    }, [sizePerPage, props.filters])

    useEffect(() => {
        refetch();
    }, [reload, refetch]);


    const columns = [
        {
            field: 'orderdetails', headerAlign: "left", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.reference}
                        </Grid>
                        {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                            <Grid item xs={12}>
                                {row.shippingLabelId}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {(row.awbRegisteredDate) ? parseDateAndTime(row.awbRegisteredDate) : ""}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'courierDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Courier Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.awb_number}`}>
                                    {row.awb_number}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.parent}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'status', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                // const orderStatus = row.orderStatus.replace(/_/g, ' ');
                return (<Chip size="small" label={row?.nprData?.nprStatus} />)

            },
        },
        {
            field: 'pickup_details', headerAlign: "left", hideSortIcons: true, headerName: 'Pickup Address', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container >
                            <Grid item xs={12}>
                                Name : {row.pickup_details?.from_name}
                            </Grid>
                            <Grid item xs={12}>
                                Phone : {row.pickup_details?.from_phone_number}
                            </Grid>
                            <Grid item xs={12}>
                                {row.pickup_details?.from_address},{row.pickup_details?.from_city},{row.pickup_details?.from_state},{row.pickup_details?.from_pincode}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'nprCpStatusDescription', headerAlign: "left", hideSortIcons: true, headerName: 'NPR Status Description', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.nprData && row.nprData.nprCpStatusDescription) ? row.nprData.nprCpStatusDescription : "-NA-"}

                            </Grid>
                            <Grid item xs={12}>
                                {(row.nprData && row.nprData.nprReportedDate) ? parseDateAndTime(row.nprData.nprReportedDate, true) : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        // {
        //     field: 'ndrcomments', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Comments', flex: 1.2, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>
        //                 <Grid container rowSpacing={2}>
        //                     <Grid item xs={12}>
        //                         {(row.nprData && row.nprData.remark) ? row.nprData.remark : "-"}

        //                     </Grid>
        //                 </Grid>
        //             </>
        //         )
        //     },
        // },
        {
            field: 'ofPCount', headerAlign: "left", hideSortIcons: true, headerName: 'OFP Attempt Count', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.nprData && row.nprData?.ofPCount) ? `${row.nprData?.ofPCount} attempt` : "0 attempts"}
                            </Grid>
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Payment Details', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "left", hideSortIcons: true, headerName: 'Action', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" color="primary"
                                disabled={row?.nprData?.nprStatus === "CLOSED"}
                                onClick={() => handleActionShow(row, "reattempt")}>Reattempt</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" color="error"
                                disabled={row?.nprData?.nprStatus === "CLOSED"}
                                onClick={() => handleClickCancel(row, "CANCEL_PICKUP")}>Cancel</Button>
                        </Grid>

                    </Grid>
                )
            },
        },
    ];

    useEffect(() => {
        if (pendingData) {
            setPendingNDRData(pendingData);
        }
    }, [pendingData]);


    const handleActionShow = (row, type) => {
        setCurrentNDRId(row);
        setCurrentNDRAction(type);
        setActionShow(true);
    };
    const handleClickCancel = (row, type) => {
        setCurrentNDRId(row);
        setCurrentNDRAction(type);
        setCancelConfirm(true);
    };

    const handleActionClose = () => {
        setActionShow(false);
    };

    const handleReattempt = (data) => {
        const payload = Object.assign(
            {},
            {

                action: "REATTEMPT",
            },
            data
        );

        const param = {
            npr_waybills_data: [payload],
        };
        sendNDRAction(param);
    };
    const handleCancel = () => {
        const payload = Object.assign(
            {},
            {
                action: "CANCEL_PICKUP",
                awb: currentNDRId.awb_number,
            },
        );
        const param = {
            npr_waybills_data: [payload],
        };
        sendNDRAction(param);
    };
    const sendNDRAction = (param) => {
        setIsLoadingBtn(true)
        setIsExecuting(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.REATTEMPT_NPR}`, param)
            .then((response) => {
                setIsLoadingBtn(false)
                setIsExecuting(false);
                setCurrentNDRId({});
                setCurrentNDRAction();
                setActionShow(false);
                setReload(reload => reload + 1)
                props.setReload(reload => reload + 1)
                if (response?.data?.meta?.success === true && response.status === 200) {
                    enqueueSnackbar(response.data?.meta?.message || 'Action performed successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(response.data?.meta?.message, { variant: 'error' });
                }
            })
            .catch((err) => {
                setIsLoadingBtn(false)
                const message = err?.response?.data?.message || err.message;
                if (message === 'Target System Error') {
                    enqueueSnackbar("Please wait a few minutes before you try again", { variant: 'error' });
                }
                else {
                    enqueueSnackbar(err?.response?.data?.message || err.message, { variant: 'error' });
                }
                setIsExecuting(false);
            });
    };

    return (
        <>
            <PageTitle>NPR Management</PageTitle>
            <Grid item container spacing={2}>
                {(isLoading || isExecuting) && <Loader />}
                {/* <Grid item xs={12}>
                    <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        onClick={() => setBulkOrderShow(true)}
                    >
                        Bulk Upload Sample
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ ml: 1 }} variant='body' color={'error'}>In case of RTO : We will only take RTO action. Other details i.e
                        Address, Preferred Date, Contact number will not be considered.
                    </Typography>
                </Grid> */}
                {console.log('pendingNdrData', pendingNdrData)}
                <Grid item xs={12}>
                    <ProzoDataGrid columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(pendingNdrData.data && pendingNdrData.data.length > 0) ? pendingNdrData.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={180}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id} />
                </Grid>
                <NprAction
                    show={actionShow}
                    handleClose={handleActionClose}
                    currentNDRId={currentNDRId}
                    currentNDRAction={currentNDRAction}
                    handleReattemp={handleReattempt}
                    setReload={setReload}
                    isLoadingBtn={isLoadingBtn}
                />
                <ConfirmDialog
                    title=''
                    open={cancelConfirm}
                    setOpen={setCancelConfirm}
                    onConfirm={() => handleCancel()}>
                    Are you sure you want to Cancel?
                </ConfirmDialog>
            </Grid>
        </>
    );
};

export default PendingNprData

