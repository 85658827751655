import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FormProvider, RHFDateTimeField } from "../../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, postGeneric } from "../../../common/constants";
import { convertDateTime } from "../../../helpers/UtilityHelper";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

function AppointmentDateUpdate(props) {

    const { awb, setOpen, setReload, setFilters, setFiltersChange, filterChange } = props;
    console.log(awb)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            date: Yup.string().required("Please Select Date"),
        })),
        defaultValues: {
            date: ""
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        const request = {
            "collection": "orders",
            "id": awb.id,
            "isObjectId": true,
            "updates": [
                {
                    "field": "appointmentDeliveryDate",
                    "value": convertDateTime(data.date),
                    "type": "DATE_TIME"
                }
            ]
        }
        try {
            await postGeneric(apiConstants.GENERIC_UPDATE, request).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('EDD is Successfully Updated', { variant: 'success' })
                    setReload(reload => reload + 1)
                    reset()
                    setOpen(() => false)
                    setFilters([]);
                    setFiltersChange(filterChange + 1)
                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    reset()
                    setOpen(() => false)
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
            reset()
            setOpen(() => false)
        }

        setIsSubmitting(false)

    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Typography>Appointment Date</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RHFDateTimeField disablePast={true} name='date' label='Appointment Date' />
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="small"
                                type="submit">
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>

            </CardContent>
        </Card>
    )
}

export default AppointmentDateUpdate;