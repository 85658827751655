import React from 'react';
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import ChartTooltip from './ChartTooltip';
import getColor from './colors';

export default function CompositChart({ data, height, chartOptions }) {

    return (
        <ResponsiveContainer width="100%" height={height || 350}>
            <ComposedChart
                data={data}
            >
                <CartesianGrid strokeDasharray="5 5" stroke="#dee2e6" />
                <XAxis dataKey={chartOptions.xaxis} scale="band" fontSize="11px" fontWeight="500" />
                <YAxis fontSize="11px" fontWeight="500" />
                <Tooltip content={<ChartTooltip />} />
                <Legend verticalAlign="top" align="right" iconSize="10px" iconType="circle" wrapperStyle={{ fontSize: 11, fontWeight: 500 }} />

                {
                    chartOptions.data.map((row, i) => {
                        if (row.chartType === 'bar') {
                            return <Bar
                                key={i}
                                dataKey={row.dataKey}
                                fill={row.fill || getColor(row.colorIndex || i)}
                                name={row.label}
                                maxBarSize={40}
                                unit={row.unit || ""}
                                animationEasing="ease-in-out"
                                animationDuration={1000}
                            />
                        }

                        if (row.chartType === 'area') {
                            return <Area
                                key={i}
                                dataKey={row.dataKey}
                                fill={row.fill || getColor(row.colorIndex || i)}
                                name={row.label}
                                unit={row.unit || ""}
                                animationEasing="ease-in-out"
                                animationDuration={1000}
                            />
                        }

                        if (row.chartType === 'line') {
                            return <Line
                                key={i}
                                dataKey={row.dataKey}
                                connectNulls={true}
                                stroke={row.fill || getColor(row.colorIndex ?? i)}
                                name={row.label}
                                unit={row.unit || ""}
                                animationEasing="ease-in-out"
                                animationDuration={1000}
                                strokeWidth={2}
                            />
                        }

                        if (row.chartType === 'stackedBar') {
                            return <Bar
                                key={i}
                                stackId={row.stackId}
                                dataKey={row.dataKey}
                                fill={row.fill || getColor(row.colorIndex || i)}
                                name={row.label}
                                maxBarSize={40}
                                unit={row.unit || ""}
                                animationEasing="ease-in-out"
                                animationDuration={1000}
                            />
                        }

                        return null;
                    })
                }
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export function DualAxisChart({ height = 400, data, chartOptions }) {
    return (
        <ResponsiveContainer width='100%' height={height}>
            <ComposedChart width='100%' height={height} data={data} margin={[0, 0, 0, 0]}>
                <CartesianGrid strokeDasharray='3 3' />
                <Legend
                    verticalAlign='top'
                    align='right'
                    iconSize='10px'
                    wrapperStyle={{ fontSize: 11, fontWeight: 500 }}
                />

                <XAxis height={40} dataKey={chartOptions.xaxis} fontSize='11px' fontWeight='500' />

                {chartOptions.yaxis.left && (
                    <YAxis
                        width={80}
                        yAxisId='left'
                        fontSize='11px'
                        fontWeight='500'
                        type='number'
                        tickFormatter={(v) => {
                            if (chartOptions.yaxis.left.tickFormatter instanceof Function) {
                                return chartOptions.yaxis.left.tickFormatter(v)
                            }

                            return v
                        }}
                    >
                        <Label
                            value={chartOptions.yaxis.left.label}
                            angle={-90}
                            position='insideLeft'
                            fontSize={11}
                            fontWeight={500}
                        />
                    </YAxis>
                )}

                {chartOptions.yaxis.right && (
                    <YAxis
                        width={80}
                        yAxisId='right'
                        orientation='right'
                        fontSize='11px'
                        fontWeight='500'
                        type='number'
                        tickFormatter={(v) => {
                            if (chartOptions.yaxis.right.tickFormatter instanceof Function) {
                                return chartOptions.yaxis.right.tickFormatter(v)
                            }

                            return v
                        }}
                    >
                        <Label
                            value={chartOptions.yaxis.right.label}
                            angle={-90}
                            position='outsideLeft'
                            fontSize={11}
                            fontWeight={500}
                        />
                    </YAxis>
                )}

                <Tooltip content={<ChartTooltip p='tool_tip' />} />
                {chartOptions.yaxis.left?.data.map((row, i) => {
                    if (row.chartType === 'line') {
                        return (
                            <Line
                                key={row.chartType + i + 'left'}
                                yAxisId='left'
                                dataKey={row.dataKey}
                                unit={row.unit || ''}
                                type='monotone'
                                name={row.label}
                                stroke={row.fill || getColor(i)}
                                animationEasing='ease-in-out'
                                animationDuration={1000}
                                strokeWidth={2}
                                connectNulls={true}
                            />
                        )
                    }
                    if (row.chartType === 'bar') {
                        return (
                            <Bar
                                key={row.chartType + i + 'left'}
                                yAxisId='left'
                                dataKey={row.dataKey}
                                unit={row.unit || ''}
                                name={row.label}
                                fill={row.fill || getColor(i)}
                                animationEasing='ease-in-out'
                                animationDuration={1000}
                                maxBarSize={40}
                            />
                        )
                    }
                })}
                {chartOptions.yaxis.right?.data.map((row, i) => {
                    if (row.chartType === 'line') {
                        return (
                            <Line
                                key={row.chartType + i + 'right'}
                                yAxisId='right'
                                unit={row.unit}
                                type='monotone'
                                dataKey={row.dataKey}
                                name={row.label}
                                stroke={row.fill || getColor(i)}
                                animationDuration={300}
                                strokeWidth={2}
                                connectNulls={true}
                            />
                        )
                    }
                    if (row.chartType === 'bar') {
                        return (
                            <Bar
                                key={row.chartType + i + 'right'}
                                yAxisId='right'
                                dataKey={row.dataKey}
                                unit={row.unit || ''}
                                name={row.label}
                                fill={row.fill || getColor(i)}
                                animationEasing='ease-in-out'
                                animationDuration={1000}
                                maxBarSize={40}
                            />
                        )
                    }
                })}
            </ComposedChart>
        </ResponsiveContainer>
    )
}