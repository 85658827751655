import * as React from 'react'
import { Button, Menu, MenuItem } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useGetCouriersQuery } from '../redux/commonRTKPersist';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export default function CourierPartnerPopover({ values = [], setCourier, data }) {
    const { data: allCouriers = [] } = useGetCouriersQuery()

    if (!data) data = allCouriers

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <Button size="large" variant="outlined" sx={{
                        borderColor: 'rgba(145, 158, 171, 0.24)',
                        textTransform: 'capitalize',
                        color: '#637381',
                        background: '#fff'
                    }} endIcon={<KeyboardArrowDownIcon />} {...bindTrigger(popupState)}>
                        {values.length > 0 ?
                            (values.map(val => val.name)).join(", ")
                            : 'Courier Partners'}
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        {data.map((m, i) => <MenuItem dense sx={{ fontSize: 13, display: 'flex', justifyContent: 'space-between' }} key={i} onClick={() => {
                            if (setCourier instanceof Function) {
                                const ids = values.map(v => v.id)
                                if (ids.includes(m.id)) {
                                    setCourier(values.filter(v => v.id !== m.id))
                                } else {
                                    setCourier([...values, m])
                                }
                            }
                            popupState.close()
                        }}>
                            {m.name}
                            {(values.map(v => v.id)).includes(m.id) &&
                                <DoneAllIcon fontSize="small" sx={{ color: '#f3c349' }} />
                            }
                        </MenuItem>)}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    )
}