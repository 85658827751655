import React/* , { useState } */ from "react";
/* import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userLogin } from "../../redux/actions/userAction";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"; */
import "./login.css";
import "react-toastify/dist/ReactToastify.css";
/* import { reset } from "../../redux/reducers/auth/forgotPasswordSlice";
import { resetFormrp } from "../../redux/reducers/auth/resetPasswordSlice";
import { useSnackbar } from "notistack"; */
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LoginForm from "./LoginForm";

export default function Login() {
  /* const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { rpInfo } = useSelector((state) => state.resetPasswordSlice);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar(); */

  // redirect authenticated user to profile screen
  /* useEffect(() => {
    dispatch(reset());
    if (Object.keys(rpInfo).length !== 0) {
      enqueueSnackbar("Your password has been updated successfully.", {
        variant: "success",
      });
      dispatch(resetFormrp());
    }
   
    if (Object.keys(userInfo).length !== 0) {
      navigate("/");
    }

    // eslint-disable-next-line
  }, [navigate, userInfo]); */

  return (
    <div id="login-wrapper" className="container-fluid vh-100">
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6}>
          <img
            src="/img/prozo-logo2.svg"
            className="logo"
            height="60"
            alt="logo"
          />
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4">
              Shipping made easy with Proship
            </Typography>
          </Box>
          <List sx={{ mt: 2 }}>
            <ListItem sx={{ py: 0 }}>
              <ListItemIcon sx={{ mr: 0 }}>
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Decrease your return to origin (RTO) for unsuccessful deliveries." />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemIcon>
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Manage non-delivery reports (NDR) seamlessly." />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemIcon>
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Choose the right courier partner for each shipment taking into account all the necessary data points." />
            </ListItem>
          </List>
          <Button
            href='/signup'
            sx={{ mt: 2 }}
            size="large"
            variant="contained"
            color="warning"
          >
            Sign up now
          </Button>
        </Grid>
        <Grid item sm={12} lg={6}>
          <LoginForm />
        </Grid>
      </Grid>
    </div>
  );
}
