import { eachDayOfInterval } from "date-fns";
import { apiConstants, getGeneric, postGeneric } from "../../common/constants";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { formatDate } from "../../helpers/UtilityHelper";
import _ from 'lodash'

export async function getAwbRegistedBuyDateRange(dates = []) {
    return await makeRequest(apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE, dates);
}

export async function getDeliveredBuyDateRange(dates = []) {
    return await makeRequest(apiConstants.ALL_DELIVERED_BY_DATE_RANGE, dates);
}

export async function getRTODliveredByDateRange(dates = []) {
    return await makeRequest(apiConstants.ALL_RTO_DELIVERED_BY_DATE_RANGE, dates);
}

export async function getAllIntransitOrders() {
    return await makeRequest(apiConstants.ALL_INTRANSIT_ORDERS)
}

export async function getAllNotPickedOrders() {
    return await makeRequest(apiConstants.ALL_NOT_PICKED_ORDERS)
}

async function makeRequest(url, dates = null) {

    try {

        if (!dates) {

            const response = await getGeneric(url, {
                merchant_list: getMerchant().id
            })

            return { data: response?.data }
        }

        if (dates.length === 0) return { isError: true };

        const responses = await Promise.all(dates.map(date => getGeneric(url, {
            from_date: date.start,
            to_date: date.end,
            merchant_list: getMerchant().id
        })))

        return { data: responses.map(response => response?.data) }

    } catch (err) {
        return { isError: true, error: err.message };
    }
}

export default async function getOrdersByDateRange(dates = []) {

    try {

        if (dates.length === 0) return { isError: true };

        const data = [];

        for (const _date of dates) {
            const eachDayDates = eachDayOfInterval({
                start: new Date(_date.start),
                end: new Date(_date.end)
            })

            const dateChunks = _(eachDayDates)
                .chunk(3)
                .map(function (item) {
                    return {
                        start: formatDate(item[0], 'yyyy-MM-dd HH:mm:ss'),
                        end: formatDate(item[item.length - 1], 'yyyy-MM-dd HH:mm:ss')
                    }
                }).value()

            const responses = await Promise.all(
                dateChunks.map((_date) => postGeneric(apiConstants.ORDER_LISTS, {
                    includes: 'courierDetail,merchantZone,orderStatus,paymentMode,order_history.orderStatusCode,order_history.timestamp',
                    andfilter: [
                        {
                            field: "awbRegisteredDate",
                            operator: "gte",
                            value: `Date('${_date.start}')`
                        },
                        {
                            field: "awbRegisteredDate",
                            operator: "lte",
                            value: `Date('${_date.end}')`
                        },
                        {
                            "field": "orderSubStatus",
                            "operator": "ne",
                            "value": "UNPROCESSED"
                        }
                    ],
                    offset: 0,
                    limit: 20000,
                    skipCount: true
                })))

            const flatResponse = responses.map(response => response?.data?.data || []).flat()
            data.push(flatResponse)
        }

        return { data }

    } catch (err) {
        console.error(err)
        return { data: [] }
    }

}