import React, { useEffect, useState } from "react";
import { apiConstants, downloadZip, getGeneric, postGeneric } from "../../common/constants";
import { useForm } from "react-hook-form";
import withLayout from "../../hocs/Layout";
import {
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useGetAllReportTypesQuery } from "../../redux/commonRTK";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import RHFDateRangeFieldFromTo from "../../common/hook-form/RHFDateRangeFieldFromTo";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { downloadCSV, formatDate } from "../../helpers/UtilityHelper";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import DateField from "../../common/DateField";
import Loader from "../../common/Loader";
import _ from "lodash";
import MUIModal from "../../common/MUIModal";
import { addDays } from "date-fns";
import { isSameDay } from "date-fns/esm";
import { useSnackbar } from "notistack";
import { insertNotification } from "../../redux/notificationSlice";
import { CSVLink } from "react-csv";
import PageTitle from "../../common/PageTitle";

const Reports = () => {
  const { data = [] } = useGetAllReportTypesQuery();
  let allReports = data.map((v) => ({ label: v.replace(/_/g, " "), value: v }));
  allReports.push({ label: "POD B2C", value: "POD_B2C" });
  allReports.push({ label: "POD B2B", value: "POD_B2B" });
  allReports.push({ label: "Weight Discrepancy", value: "Weight_Discrepancy" });
  allReports.push({ label: "COD Remittance", value: "COD_Remittance" });
  // allReports.push({ label: "Channel Order MIS", value: "MERCHANT_CHANNEL_ORDER_MIS" });
  allReports = allReports.filter((v) =>
    ["MIS", "B2B_MIS", "NDR", "RTO", "Weight_Discrepancy", "COD_Remittance", "MERCHANT_CHANNEL_ORDER_MIS", "QC_IMAGES_REPORT", "NON_TERMINAL_MIS","NPR_AWB_REG_DT","NPR"].includes(v.value)
  );

  // const Status = [
  //   {
  //     label: "ALL",
  //     value: "ALL"
  //   },
  //   {
  //     label: "GENERATED",
  //     value: "GENERATED"
  //   },
  //   {
  //     label: "REMITTED",
  //     value: "REMITTED"
  //   },
  //   {
  //     label: "APPROVED",
  //     value: "APPROVED"
  //   },
  //   {
  //     label: "PARTIALLY PAID",
  //     value: "PARTIALLY_PAID"
  //   }
  // ];


  const { merchant } = useSelector((state) => state.user.userInfo);
  const [createNewReport, setCreateNewReport] = useState(false);
  const [allFilteredReports, setAllFilteredReports] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(30);
  const [page, setPage] = useState(0);
  const [filterDate, setFilterDate] = useState(new Date());
  const [loadingReports, setIsLoadingReports] = useState(false);
  const [reload, setReload] = useState(1);
  const [isSystemGenerated, setIsSystemGenerated] = useState("NO");
  const [filterByReportType, setFilterByReportType] = useState("DAILY");
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const status = [{ label: "New", value: 'NEW' }, { label: "DISPUTED BY COURIER", value: 'DISPUTED_BY_COURIER' }, { label: "DISPUTED BY MERCHANT", value: 'DISPUTED_BY_MERCHANT' }, { label: "ACCEPTED BY COURIER", value: 'ACCEPTED_BY_COURIER' }, { label: "ACCEPTED BY MERCHANT", value: 'ACCEPTED_BY_MERCHANT' }, { label: "AUTO ACCEPTED BY SYSTEM", value: 'AUTO_ACCEPTED_BY_SYSTEM' }]
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const [csvDataCOD, setCsvDataCOD] = useState([]);
  const [csvReadyCOD, setCsvReadyCOD] = useState(false);
  const codReportHeaders = [
    { label: "COD Cycle Start Date", key: "from_date" },
    { label: "COD Cycle End Date", key: "end_date" },
    { label: "Payment Mode", key: "paymentMode" },
    { label: "Bank Name", key: "bankName" },
    { label: "UTR Number", key: "utrNo" },
    { label: "Payment Date", key: "paymentDate" },
    { label: "Amount", key: "amount" },
    { label: "Total Orders", key: "totalOrders" },
  ];



  useEffect(() => {
    setIsLoadingReports(true);
    let reportObj = {};
    reportObj.merchantId = merchant.id;
    reportObj.yearAndMonth = formatDate(filterDate, "yyyyMM");
    getGeneric(apiConstants.GET_ALL_GENERATED_REPORTS, reportObj)
      .then((response) => {
        if (response?.data?.responseObj) {
          setAllFilteredReports(
            _.orderBy(response.data.responseObj, "createdAt", "desc")
          );
        }
        setIsLoadingReports(false);
      })
      .catch((err) => {
        setIsLoadingReports(false);
        console.error(err);
      });

    // eslint-disable-next-line
  }, [filterDate, reload]);

  useEffect(() => {
    setPage(0);
  }, [filterByReportType, isSystemGenerated]);

  const currentDate = new Date();
  const defaultYear = currentDate.getFullYear();
  const defaultStatus = 'ALL'
  const defaultMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  ;

  const validationRules = {
    reportName: Yup.string().required('Report Name is required'),
    fromDate: Yup.mixed()
      .when('reportName', {
        is: (reportName) => reportName && reportName !== "COD_Remittance",
        then: Yup.date()
          .typeError('Invalid date')
          .required('From Date is required'),
        otherwise: Yup.string().notRequired(),
      }),
    toDate: Yup.mixed()
      .when('reportName', {
        is: (reportName) => reportName && reportName !== "COD_Remittance",
        then: Yup.date()
          .typeError('Invalid date')
          .required('To Date is required'),
        otherwise: Yup.string().notRequired(),
      }),
    year: Yup.number()
      .required('Year is required')
      .test('is-4-digits', 'Year must be a 4-digit number', (value) => {
        return /^[0-9]{4}$/.test(value);
      })
      .max(defaultYear, `Year cannot be greater than ${defaultYear}`),
  };


  let defaultValues = {
    reportName: "",
    fromDate: "",
    toDate: "",
    createdDateGT: "",
    createdDateLT: "",
    status: defaultStatus,
    year: defaultYear,
    month: defaultMonth,
  };

  const methods = useForm({
    resolver: yupResolver(Yup.object().shape(validationRules)),
    defaultValues,
  });

  const { handleSubmit, watch, reset } = methods;
  const selectedReportName = watch('reportName');


  const onSubmit = async (data) => {
    try {
      let fetchingReport = false;
      let reportLink;
      if (data.reportName === "POD_B2C") {
        const payload = {
          from_date: formatDate(data.fromDate, "yyyy-MM-dd"),
          merchantOId: merchant.id,
          shipmentType: "B2C",
          to_date: formatDate(data.toDate, "yyyy-MM-dd"),
          type: "ZIP"
        };
        await handlePODDownload(payload, 'B2C');
      } else if (data.reportName === "POD_B2B") {
        const payload = {
          from_date: formatDate(data.fromDate, "yyyy-MM-dd"),
          merchantOId: merchant.id,
          shipmentType: "B2B",
          to_date: formatDate(data.toDate, "yyyy-MM-dd"),
          type: "ZIP"
        };
        await handlePODDownload(payload, 'B2B');
      }
      else if (data.reportName === "Weight_Discrepancy") {
        await downloadWeightDiscrepancyReport("Weight_Discrepancy");
      }
      else if (data.reportName === "COD_Remittance") {
        handleCodDownload(data)
      }
      else {
        enqueueSnackbar("Report will be downloaded automatically!", {
          variant: "success",
        });
        setCreateNewReport(false);
        let request = {
          reportName: data.reportName,
          fromDate: formatDate(data.fromDate, "yyyy-MM-dd"),
          toDate: formatDate(data.toDate, "yyyy-MM-dd"),
          merchantRef: merchant.id,
          overwrite: true,
        };
        while (true) {
          if (fetchingReport) delete request.overwrite;
          const { data } = await postGeneric(
            apiConstants.CREATE_REPORT_ASYNC,
            request
          );
          fetchingReport = true;
          if (data.status === "SUCCESS" && data.requsetState === "COMPLETED") {
            reportLink = data.reportMeta?.s3Link?.signedLink;
            window.open(data.reportMeta?.s3Link?.signedLink, true);
            break;
          }
          else if (data.status === "ERROR" && data.requsetState === "COMPLETED") {
            enqueueSnackbar('Some error occurred', { variant: "error" });
            break;
          }
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
        setReload((reload) => setReload(reload + 1));
        dispatch(
          insertNotification({
            title: data.reportName + " Report Generated",
            description:
              "Report is successfully generated! you can download it now!",
            url: reportLink,
          })
        );
      }
    } catch (err) {
      console.log('err', err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };


  // const handleCodDownload = (data) => {
  //   var newDate = "";
  //   if (data.year && data.month) {
  //     newDate = data.month === "ALL" ? "" : data.year + data.month
  //   }
  //   let payload = {
  //     merchantId: data.merchant,
  //     yearAndMonth: newDate,
  //     year: data.year,
  //     createdDateSort: "DESC",
  //     reportStageIn: data.status === "ALL" ? "" : data.status
  //   }
  //   setLoading(true)
  //   payload.limit = 1000;
  //   postGeneric(`${apiConstants.COD_MERCHANT_REPORT}`, payload
  //   ).then((res) => {
  //     setLoading(false)
  //     let d = res.data.responseObj;
  //     const filteredRecords = _.filter(d, record => {
  //       const fromDate = new Date(record.fromDate);
  //       const firstMay = new Date('2023-05-01T00:00:00.000+00:00');
  //       return fromDate > firstMay;
  //     });
  //     let k = _.flatMap(filteredRecords, data =>
  //       _.map(data.merchantPaymentDetails, detail => ({
  //         ...detail,
  //         totalOrders: data.totalOrders,
  //         dateRangeKey: data.dateRangeKey
  //       }))
  //     );
  //     if (!filteredRecords.length > 0) {
  //       enqueueSnackbar("No data available", { variant: "warning" });
  //     } else {
  //       const csvData = k.map(item => ({
  //         ...item,
  //         paymentMode: item.paymentMode,
  //         bankName: item.bankName,
  //         utrNo: item.utrNo,
  //         paymentDate: formatDate(item.paymentDate),
  //         amount: item.amount,
  //         totalOrders: item.totalOrders,
  //         from_date: formatCustomDate(item.dateRangeKey.substring(0, 8)),
  //         end_date: formatDateEnd(item.dateRangeKey.substring(0, 4), item.dateRangeKey.substring(8, 14))
  //       }));
  //       setCsvDataCOD(csvData);
  //       setCsvReadyCOD(true);
  //     }
  //   }).catch(err => {
  //     setLoading(false)
  //     console.error(err)
  //   })
  // }
  const handleCodDownload = (data) => {
    let payload = {
      fromDate: formatDate(data.fromDate, "yyyy-MM-dd"),
      toDate: formatDate(data.toDate, "yyyy-MM-dd"),
      merchantId: merchant?.id,
    }
    setLoading(true)
    postGeneric(`${apiConstants.COD_REMITTANCE_REPORT}`, payload
    ).then((res) => {
      setLoading(false)
      if (res.status === 200 && res.data.status === "ERROR") {
        enqueueSnackbar(res.data.errorMsg, { variant: "error" });
      } else {
        let csvData = res.data;
        const filename = 'cod_remittance.csv';
        downloadCSV(csvData, filename);
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
    })
  }

  // function formatCustomDate(dateKey) {
  //   const year = dateKey.substring(0, 4);
  //   const month = dateKey.substring(4, 6);
  //   const day = dateKey.substring(6, 8);
  //   return `${day}-${month}-${year}`;
  // }
  // function formatDateEnd(year, endPart) {
  //   const month = endPart.substring(0, 2);
  //   const day = endPart.substring(2, 4);
  //   return `${day}-${month}-${year}`;
  // }

  // download weight descrepancy
  const downloadWeightDiscrepancyReport = async (fileName) => {
    const payload = {
      skip: 0,
      limit: 100000,
      // awbNumber: "",
      merchantId: merchant.id,
      createdDateGT: minDate ? formatDate(minDate, "yyyy-MM-dd") : "",
      createdDateLT: maxDate ? formatDate(maxDate, "yyyy-MM-dd") : "",
      statusIn: selectedStatus
    }
    postGeneric(`${apiConstants.GET_WEIGHT_DICREPENACY}?download=true`, payload).then((res) => {
      reset()
      setCreateNewReport(false);
      const data = res?.data
      var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      let csvContent = URL.createObjectURL(blob);
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const eleId = `${fileName}`
      link.setAttribute("id", eleId);
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.getElementById(eleId).remove()
      // setLoading(false)
    }).catch((err) => {
      setCreateNewReport(false);
      // setLoading(false)
      console.log(err);
      enqueueSnackbar("Something went wrong", { variant: "error" });
      reset()
      // setIsSubmitting(false);
    });
  };

  // to download POD zip file
  const handlePODDownload = async (payload, shipmentType) => {
    try {
      const response = await downloadZip(apiConstants.DOWNLOAD_POD, payload, { responseType: 'blob' });
      const binaryData = [];
      binaryData.push(response.data);
      const href = URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `POD_${shipmentType}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      console.error(error);
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "fromDate") {
        const minDate = value.fromDate;
        const maxDate = addDays(minDate, 91);
        setMinDate(minDate);
        setMaxDate(maxDate);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const filteredReports = _(allFilteredReports)
    .filter(function (val) {
      if (
        isSystemGenerated === "YES" &&
        val.reportType === filterByReportType
      ) {
        return true;
      }

      if (isSystemGenerated === "NO" && val.reportType === "MANUAL") {
        return true;
      }

      return false;
    })
    .orderBy("createdAt", "desc")
    .value();

  const filteredReportsRows = filteredReports.filter(
    (item) => item.reportName !== "ALL_MERCHANT_MIS"
  );

  return (
    <>
      <div id="main-content" className="container-fluid pb-5">
        {loadingReports && <Loader />}
        <PageTitle>All Reports</PageTitle>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 2, md: 1 }}>
          <Grid item xs={12} md={4} order={{ xs: 2 }}>
            <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
              <Tabs
                value={isSystemGenerated}
                onChange={(_e, v) => setIsSystemGenerated(v)}
                variant="fullWidth"
              >
                <Tab value="NO" label="User Generated Reports" sx={{ height: 73 }} />
                {/* <Tab
                    sx={{ height: 73 }}
                    value="YES"
                    label="Automated Reports"
                  /> */}
              </Tabs>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            alignItems="center"
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: 1,
                px: 2,
                py: 1.2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel>Report Type</InputLabel>
                    <Select
                      disabled={isSystemGenerated === "NO"}
                      onChange={(e) => setFilterByReportType(e.target.value)}
                      value={filterByReportType}
                    >
                      <MenuItem value="WEEKLY">Weekly</MenuItem>
                      <MenuItem value="MONTHLY">Monthly</MenuItem>
                      {/* <MenuItem value="MANUAL">Manual</MenuItem> */}
                      <MenuItem value="DAILY">Daily</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DateField
                    onChange={(v) => setFilterDate(v)}
                    label="Filter By Month Year"
                    inputFormat="MM/yyyy"
                    views={["year", "month"]}
                    openTo="month"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setCreateNewReport(true);
                    }}
                    variant="contained"
                    size="large"
                    color="warning"
                    fullWidth
                  >
                    Create New Report
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <ProzoDataGrid
          columns={[
            {
              field: "id",
              headerName: "SN.",
              filterable: false,
              sortable: false,
              renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
            },
            {
              field: "reportName",
              headerName: "Report Name",
              flex: 1,
              align: "left",
              headerAlign: "left",
              renderCell: ({ row }) => {
                console.log(row);

                return (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {row.reportName}
                      </Grid>
                    </Grid>
                  </>
                )
              }
            },
            // { field: "reportName", headerName: "Report Name", flex: 1 },
            /* { field: 'isSystemGenerated', headerName: 'System Generated', flex: 1, headerAlign: 'center', align: 'center', renderCell: ({ row }) => row.isSystemGenerated ? 'YES' : 'NO' }, */
            {
              field: "reportType",
              headerName: "Report Type",
              flex: 0.8,
              headerAlign: "left",
              align: "left",
            },
            {
              field: "duration",
              headerName: "Duration",
              flex: 1,
              headerAlign: "left",
              sortable: false,
              align: "left",
              renderCell: ({ row }) => {
                const startDate = new Date(row.fromDate);
                const endDate = new Date(row.toDate);

                if (isSameDay(startDate, endDate)) {
                  return formatDate(startDate, "dd MMM");
                }

                return `${formatDate(startDate, "dd MMM")} - ${formatDate(
                  endDate,
                  "dd MMM"
                )}`;
              },
            },
            /*  { field: 'fromDate', headerName: 'From Date', flex: 1, headerAlign: 'center', align: 'center', renderCell: ({ row }) => formatDate(row.fromDate, 'dd MMM, yyyy') },
                        { field: 'toDate', headerName: 'To Date', flex: 1, headerAlign: 'center', align: 'center', renderCell: ({ row }) => formatDate(row.toDate, 'dd MMM, yyyy') }, */

            {
              field: "createdAt",
              headerName: "Generated Date",
              flex: 1,
              align: "left",
              headerAlign: "left",
              renderCell: ({ row }) =>
                formatDate(row.createdAt, "dd MMM, yyyy hh:mm aaa"),
            },
            {
              field: "action",
              headerName: "Action",
              flex: 0.8,
              align: "left",
              headerAlign: "left",
              sortable: false,
              renderCell: ({ row }) => {
                return (
                  <Button
                    onClick={() => window.open(row.s3Link.signedLink, true)}
                    variant="link"
                    startIcon={
                      <FileDownloadIcon sx={{ fontSize: "15px !important" }} />
                    }
                    sx={{
                      my: 2,
                      padding: "4px 10px",
                      fontSize: "11px",
                      backgroundColor: "teal",
                      borderRadius: "3px",
                      fontWeight: 500,
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "teal",
                      },
                    }}
                  >
                    Download
                  </Button>
                );
              },
            },
          ]}
          autoHeight={true}
          rowHeight={80}
          rows={[...filteredReportsRows].splice(
            page * sizePerPage,
            sizePerPage
          )}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          setPage={setPage}
          page={page}
          pagination={true}
          hideFooterPagination={false}
          filterMode={"server"}
          rowCount={filteredReportsRows.length}
          getRowId={(row) => row.id}
          disableColumnFilter={false}
        />
      </div>
      <MUIModal
        open={createNewReport}
        title="Generate New Report"
        handleClose={() => {
          setCreateNewReport(false)
          reset()
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <RHFSelectField
                width="100%"
                name="reportName"
                options={allReports}
                label="Report Name"
              />
            </Grid>
            {/* {selectedReportName === "COD_Remittance" ? (
              <>
                <Grid item xs={12} md={6}>
                  <RHFSelectField name='month' label='Select Month' width={'100%'} options={Month.map((val) => ({
                    value: val.value,
                    label: val.label
                  }))} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField name='year' label='Year' variant="filled" />
                </Grid>
              </>) : */}
            <RHFDateRangeFieldFromTo
              gridSize={6}
              fromDateProps={{
                disableFuture: true,
              }}
              toDateProps={{
                disableFuture: true,
                minDate,
                maxDate,
              }}
            />
            {/* } */}

            {selectedReportName === "Weight_Discrepancy" && (
              <Grid item xs={12} md={6}>
                <RHFSelectField
                  name='status'
                  label='Select Status'
                  options={status.map((op) => ({
                    label: op.label.toLowerCase().replace(/(^\w| \w)/g, match => match.toUpperCase()),
                    value: op.value,
                  }))}
                  value={selectedStatus}
                  variant="filled"
                  width="100%"
                  onChange={(e) => setSelectedStatus(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <LoadingButton type="submit" size="medium" variant="contained">
                Get Report
              </LoadingButton>

              {csvReadyCOD && (
                <CSVLink
                  data={csvDataCOD}
                  headers={codReportHeaders}
                  filename={"COD_Report.csv"}
                  className="btn btn-success btn-md"
                  target="_blank"
                  style={{ marginLeft: '10px' }}
                  onClick={() => setCsvReadyCOD(false)}  // Reset the CSV ready state after download
                >
                  Download CSV
                </CSVLink>
              )}
            </Grid>
          </Grid>
        </FormProvider>
        {loading && <Loader />}
      </MUIModal >
    </>
  );
};

export default withLayout(Reports);
