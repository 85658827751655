import {
  useGetForwardDelayQuery,
} from "../../redux/commonRTK";
import _ from "lodash";
import { getCourierName } from "../../helpers/UtilityHelper";
import {
  differenceInDays,
  differenceInHours,
  isToday,
} from "date-fns";
import { useGetCouriersQuery } from "../../redux/commonRTKPersist";


export default function useForwardDelay(merchantID) {
  let rawData = useGetForwardDelayQuery({ 'merchant': merchantID, 'shipment_type': "B2C", 'order_type':'FORWARD' });
  let { data: courierDetails = [], isLoading: isLoading3 } =
    useGetCouriersQuery();

  let filterData = [];
  let k = rawData?.data?.data.filter((item, index) => {
    return (item._id.isBreached===true);
  });

  k?.filter((item, index) => {
    item.data.filter((e) =>
      filterData.push({ ...e, courierID: item?._id?.courier })
    );
  });

  if (rawData?.isLoading || rawData?.isFetching || isLoading3) {
    return { isCalculating: true };
  }

  const forwardDelay = _(filterData || [])
    .transform(function (results, item) {
      const sla = courierDetails.find(
        (s) => {
          return s.id === item.courierID;
        } // key courier
      );

      const maxTat =
        item.estimatedDeliveryDate && item?.actualPickUpTime
          ? differenceInDays(
            new Date(item.estimatedDeliveryDate),
            new Date(item.actualPickUpTime)
          )
          : 1;

      const ourForDelivery = item.order_history
        ? item?.order_history.filter((his) => his.orderStatusCode === 6) //outForDeliveryTimestamp
        : [];

      let pickupTimestamp = item?.actualPickUpTime //actualPickUpTime
        ? item?.actualPickUpTime
        : item.awbRegisteredDate;
      if (pickupTimestamp !== "NA") {
        const edd = item.estimatedDeliveryDate; //estimatedDeliveryDate

        const diffInHours = differenceInHours(new Date(), new Date(edd));
        const pickupDelayText = `${item.deliveryBreachedAgeingDays} days`;
        const dayDiff = Math.ceil(diffInHours / 24);
        let forwardDelayInDays = item.deliveryBreachedAgeingDays;

        let needAttention = false;
        if (isToday(new Date(edd))) {
          needAttention = true;
        }

        results.push({
          ...item,
          forwardDelayed: dayDiff > 0,
          forwardDelayInDays,
          edd,
          maxTat,
          pickupDelayText,
          needAttention,
          totalAttempts: ourForDelivery.length,
          courierName: getCourierName(sla?.name || ""),
          pickupDate: pickupTimestamp,
        });
      } else {
        console.log("not found");
      }
    })
    .filter(function (item) {
      return item.forwardDelayed;
    })
    .orderBy("forwardDelayInDays", "desc")
    .value();

  return { data: forwardDelay };
}
