import React from "react"
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { useGetSelectedFilters } from "../../../../common/contexts/Filters"
import _ from 'lodash'
import { ageing, flattenResponseData, formatDate, getShipmentInfo, getYYYYMMDD, numberPrecision, rto } from "../../../../helpers/UtilityHelper"
import { addDays, addMonths, addWeeks, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from "date-fns"
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK"
import { apiConstants } from "../../../../common/constants"
import { getMerchant } from "../../../../helpers/ReduxHelpers"
import { useSelector } from "react-redux"

const weekStartsOn = 1

export default function useRtoPerformance(groupOn = 'WOW', shipmentType) {
    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()
    const { userInfo } = useSelector((state) => state.user)
    const shipmentInfo = getShipmentInfo(userInfo, shipmentType);

    const skip = !from_date || !to_date
    const request = { from_date, to_date, merchant_list: id, orderType_list: 'FORWARD', shipmentType_list: shipmentInfo }
    const { data: rtoPendingData, isLoading: isLoadingPendingRto, isFetching: isFetchingPendingRto } = useGenericPostRequestQuery({
        url: apiConstants.CONTROL_TOWER_RTO_PENDING,
        data: request
    }, { skip })
    const { data: allRegistered, isLoading: isLoadingReg, isFetching: isFetchingReg } = useGenericPostRequestQuery({
        url: apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE,
        data: request
    }, { skip })
    const [isCalc, setIsCalc] = React.useState(true)
    const rtoDeliveredShipments = _.filter(flattenResponseData(allRegistered), { orderStatus: 'RTO_DELIVERED' })
    const allRtoDeliveredFlattenData = _.filter(rtoDeliveredShipments, filters)

    const rtoPayload = { merchantId: id, awbNumbers: allRtoDeliveredFlattenData?.map(r => r.awbNumber) }
    const genericRequest = { url: apiConstants.get_specific_data_rto_delivery, type: 'POST', data: [rtoPayload] }
    const { data: rtoDetails, isLoading: isLoadingRtoDetails, isFetching: isFetchingRtoDetails } = useGenericPostRequestQuery(genericRequest, { skip: allRtoDeliveredFlattenData.length === 0 })


    const allRtoDeliveredData = React.useMemo(() => {

        if (!allRtoDeliveredFlattenData || !rtoDetails) return []

        const rtoDetailsMap = rtoDetails.reduce((acc, item) => {
            acc.set(item.awbNumber, item)
            return acc;
        }, new Map())

        return allRtoDeliveredFlattenData.map(function (item) {

            if (rtoDetailsMap.has(item.awbNumber)) {
                return { ...item, ...rtoDetailsMap.get(item.awbNumber) }
            }

            return item;
        })


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRtoDeliveredFlattenData?.length, rtoDetails?.length])

    const rtoDataCount = allRtoDeliveredData.length

    const result = React.useMemo(() => {

        if (isFetchingReg || isLoadingReg || isLoadingPendingRto || isFetchingPendingRto || isLoadingRtoDetails || isFetchingRtoDetails) {
            setIsCalc(true)
            return;
        }

        const allRtoPendingData = _.filter(flattenResponseData([rtoPendingData]), filters)
        const allRegisteredData = _.filter(flattenResponseData(allRegistered), filters)

        const allRtoPendingAndDelivered = _.uniqBy([...allRtoPendingData, ...allRtoDeliveredData], 'awbNumber')

        const allRtoData = _(allRegisteredData).filter(function (item) {
            return rto.includes(item.orderStatus)
        }).map(function (i) {
            const rtoItem = _.find(allRtoPendingAndDelivered, { awbNumber: i.awbNumber })
            if (rtoItem)
                return { ...rtoItem, ...i }

            return i
        }).value()

        const allRtoGroupedByPaymentMode = _.groupBy(allRtoData, 'paymentMode')

        const deliveryAttemptSum = _.sumBy(allRtoData, function (item) {
            return item?.deliveryAttemptCount || 0
        })

        let intervalArr = [],
            awbRegisteredOrderCount = allRegisteredData.length,
            rtoCount = allRtoData.length,
            rtoCodCount = allRtoGroupedByPaymentMode?.COD?.length || 0,
            // rtoPrepaidCount = allRtoGroupedByPaymentMode?.PREPAID?.length || 0,
            overallRtoPercentage = numberPrecision((rtoCount / awbRegisteredOrderCount) * 100),
            lineChartData = [],
            avgDeliveryAttemptCount = numberPrecision(deliveryAttemptSum / rtoCount),
            uniqCourierIds = _(allRtoData).uniqBy('courier').map(function (i) {
                return i.courier
            }).value();

        const allRtoDataGroupedByDate = _.groupBy(allRtoData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })
        const allRegisteredDataGroupedByDate = _.groupBy(allRegisteredData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()

            let name = null;
            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }

            const row = { name }

            const allRtoDatesBetween = _.filter(Object.keys(allRtoDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allAwbDatesBetween = _.filter(Object.keys(allRegisteredDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allRtoDataBetweenDate = allRtoDatesBetween.map(key => allRtoDataGroupedByDate[key]).flat()

            const allRegisteredDataBetweenDate = allAwbDatesBetween.map(key => allRegisteredDataGroupedByDate[key]).flat()

            const allPrepaidDataBetweenDate = _.filter(allRegisteredDataBetweenDate, function (item) {
                return item.paymentMode === 'PREPAID'
            })

            const allCodDataBetweenDate = _.filter(allRegisteredDataBetweenDate, function (item) {
                return item.paymentMode === 'COD'
            })

            const groupedByAgeing = _(allRtoDataBetweenDate).groupBy(function (item) {
                //let attempt = item.rtoDeliveryFailedCount
                let attempt = item?.deliveryAttemptCount || 0
                return attempt > 3 ? '3+_Attempts' : attempt + '_Attempts'
            }).value()
            // let s = ''
            // _.forEach(allRtoDataBetweenDate, function (key) {
            //     if (!key.deliveryAttemptCount || key.deliveryAttemptCount === 0) {
            //         s = s + key.awbNumber + '\n'
            //     }
            // })
            // console.log(s)
            for (const key of ageing) {
                row[key] = groupedByAgeing[key]?.length || 0
            }
            _(allRtoDataBetweenDate)
                .groupBy(function (item) {
                    return item.paymentMode.toUpperCase()
                })
                .forIn(function (value, key) {
                    let totalROTForPaymentMode = 0;
                    if(key==='COD'){
                        totalROTForPaymentMode = allCodDataBetweenDate?.length
                    }else if(key==='PREPAID'){
                        totalROTForPaymentMode = allPrepaidDataBetweenDate.length
                    }else{
                        totalROTForPaymentMode = rtoCount
                    }
                    const count = value.length
                    row[key] = Number(numberPrecision((count / totalROTForPaymentMode) * 100))
                })

            _(allRtoDataBetweenDate)
                .groupBy('ndrCpStatusCode')
                .forIn(function (value, key) {
                    const count = value.length
                    row[`${key}_value`] = count;
                    row[`${key}_perc`] = Number(numberPrecision((count / rtoCount) * 100))
                })

            const groupedByCourier = _.groupBy(allRtoDataBetweenDate, 'courier')

            for (const k of uniqCourierIds) {
                const count = groupedByCourier[k]?.length || 0
                row[`c_${k}_value`] = count;
                row[`c_${k}_perc`] = Number(numberPrecision((count / rtoCount) * 100))
            }

            row.totalRtoCases = allRtoDataBetweenDate.length
            row.rtoPercentage = Number(numberPrecision((allRtoDataBetweenDate.length / allRegisteredDataBetweenDate.length) * 100))
            row.rtoRevenue = _.sumBy(allRtoDataBetweenDate, 'invoiceValue')

            lineChartData.push(row)
        }

        const rtoGroupedByPincode = _(allRtoData)
            .groupBy('dropPincode')
            .map(function (items, pincode) {
                const itemsCount = items.length;
                return { pincode, rto: itemsCount }
            })
            .orderBy('rto', 'desc')
            .take(20)
            .value()

        const rtoGroupedByCity = _(allRtoData)
            .groupBy('deliveryCity')
            .map(function (items, city) {
                const itemsCount = items.length;
                return { city, rto: itemsCount }
            })
            .orderBy('rto', 'desc')
            .take(20)
            .value()

        const results = { allRtoData, allRtoPendingData, uniqCourierIds, avgDeliveryAttemptCount, rtoGroupedByCity, awbRegisteredOrderCount, rtoCount, rtoCodCount, overallRtoPercentage, lineChartData, rtoGroupedByPincode }
        setIsCalc(false)
        return results;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingRtoDetails, isFetchingRtoDetails, rtoPendingData, isLoadingPendingRto, isFetchingPendingRto, from_date, to_date, rtoDataCount, allRegistered, isLoadingRtoDetails, isLoadingReg, isFetchingRtoDetails, isFetchingReg, groupOn, filters])

    if (isCalc) return { isLoadingRtoDetails: isCalc }
    return { data: result, isLoadingRtoDetails: isCalc };
}