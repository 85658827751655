import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Grid, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { apiConstants, post } from "../../../common/constants";
import { FormProvider, RHFTextField } from "../../../common/hook-form";
import { RemoveCircleTwoTone } from "@mui/icons-material";

function PlacedReversedOrder(props) {
    const { pod, setReversed, setReload } = props
    const methods = useForm()
    const { handleSubmit, control, setValue, getValues } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { append, remove, fields } = useFieldArray({
        name: 'item_list',
        control: control,
    })

    const onSubmit = (data) => {
        setIsSubmitting(true)
        const payload = {
            "rvpReason": data.rvpReason,
            "reference": data.reference,
            "invoiceValue": data.invoiceValue,
            "itemList": data?.item_list?.map(item => ({
                sku_id: item.sku_id,
                units: item.units
            })),
            "shipment_detail": pod?.shipment_detail?.map(item => ({
                item_length: item.item_length,
                item_weight: item.item_weight,
                item_height: item.item_height,
                item_breadth: item.item_breadth,
            }))
        }
        post(`${apiConstants.BASE_LOCAL}${apiConstants.RVP_ORDER}`, payload).then((res) => {
            setIsSubmitting(false)
            if (res.status === 200) {
                setReversed(() => false)
                let awb = res.data.body?.result?.awb_number
                setReload(reload => reload + 1)
                enqueueSnackbar("RVP Order Created Successfully, AWB: " + awb, { variant: 'success' })
            }
            else {
                enqueueSnackbar("Something went wrong", { variant: 'error' })
            }
        }).catch((err) => {
            setIsSubmitting(false)
            console.log(err, 'error');
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
        // setReversed(() => false)
    }
    const totalFields = fields?.length || 0;

    React.useEffect(() => {
        if (fields.length === 0 && pod) {
            setValue('invoiceValue', pod.invoice_value);
            setValue('reference', pod.reference);

            pod.item_list.forEach((item, index) => {
                const returnItem = pod?.returnItemDetails?.returnItemMap[item.sku_id];
                console.log("returnItem", returnItem?.returnRemainingUnits);
                if (returnItem === undefined || returnItem?.returnRemainingUnits > 0) {
                    const itemVal = { ...item }
                    if (returnItem) {
                        itemVal['units'] = returnItem?.returnRemainingUnits
                    }
                    append(itemVal);
                }
            });
        }
    }, [pod, append, fields.length, setValue]);



    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <RHFTextField name='invoiceValue' label='Invoice Value' size="medium" required disabled />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name='reference' label='Reference' size="medium" required disabled />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name='rvpReason' label='RVP Reason' size="medium" required />
                        </Grid>
                        {fields.map((field, index) => (
                            <Grid item xs={12} key={field.id}>
                                <FieldGroup index={index} getValues={getValues} setValue={setValue} remove={remove} append={append} totalFields={totalFields} />
                            </Grid>
                        ))}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit">Submit</LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default PlacedReversedOrder;

function FieldGroup({ index, onKeyPressHandler, totalFields, remove, getValues }) {

    const keyName = `item_list.${index}`;
    return (
        <Box sx={{ backgroundColor: '#3783e71a', borderRadius: 1, p: 1.5, border: '1px solid #ccc' }}>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={3}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.sku_id`}
                        label="SKU ID"
                        required variant="filled"
                        autoFocus={true}
                        placeholder="Search for item details"
                        disabled
                    // InputProps={{
                    //     endAdornment: (
                    //         <LoadingButton size="small" loading={isSearching} onClick={handleSearchClick}>Search</LoadingButton>
                    //     )
                    // }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.item_name`}
                        label="Name"
                        placeholder="Product name"
                        required
                        variant="filled"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.brand`}
                        label="Brand"
                        required
                        variant="filled"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.units`}
                        label="Units"
                        type="number"
                        required
                        variant="filled"
                        disabled={getValues(`item_list.${index}.units`) === 0}
                    />
                </Grid>
                <Grid item xs={12} md={1}>
                    {/* {((index + 1) === totalFields) && (
                        <IconButton size="small" color="primary" onClick={() => append(emptyItemObject)}>
                            <AddCircleTwoTone />
                        </IconButton>
                    )} */}
                    {(totalFields !== 1) && (
                        <IconButton size="small" color="error" onClick={() => remove(index)}>
                            <RemoveCircleTwoTone />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}
