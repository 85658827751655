import * as React from 'react'
import {
  BarChart as BarChartRe,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import ChartTooltip from './ChartTooltip'
import getColor from './colors'

export function BarChart({ width = '100%', data, height, chartOptions, ...other }) {
  return (
    <ResponsiveContainer width={width} height={height ? height : 350}>
      <BarChartRe data={data} {...other}>
        <CartesianGrid strokeDasharray='5 5' stroke='#dee2e6' />
        <XAxis
          dataKey={chartOptions.xaxis}
          label={{
            value: chartOptions.xLabel ? chartOptions.xLabel : '',
            position: 'insideBottom',
            offset: 0,
            dy: 3,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
          fontSize='11px'
          fontWeight='500'
        />
        <YAxis
          label={{
            value: chartOptions.yLabel ? chartOptions.yLabel : '',
            position: 'insideLeft',
            angle: -90,
            dy: -10,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
          fontSize='11px'
          fontWeight='500'
        />
        <Tooltip content={<ChartTooltip />} />
        <Legend
          verticalAlign='top'
          align='right'
          iconSize='10px'
          iconType='circle'
          wrapperStyle={{ fontSize: 11, fontWeight: 500 }}
        />
        {chartOptions.data.map((row, i) => (
          <Bar
            key={i}
            dataKey={row.dataKey}
            fill={row.fill || getColor(row.colorIndex || i)}
            name={row.label}
            maxBarSize={40}
            unit={row.unit || ''}
            animationEasing='ease-in-out'
            animationDuration={1000}
          />
        ))}
      </BarChartRe>
    </ResponsiveContainer>
  )
}

export function VerticalBarChart({ data, height, width, chartOptions, ...other }) {
  return (
    <ResponsiveContainer width={width || '100%'} height={height ? height : 350}>
      <BarChartRe data={data} {...other} layout='vertical'>
        <CartesianGrid strokeDasharray='5 5' stroke='#dee2e6' />
        <XAxis
          dataKey={chartOptions.xaxis}
          label={{
            value: chartOptions.xLabel ? chartOptions.xLabel : '',
            position: 'insideBottom',
            offset: 0,
            dy: 3,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
          type='number'
          fontSize='11px'
          fontWeight='500'
        />
        <YAxis
          label={{
            value: chartOptions.yLabel ? chartOptions.yLabel : '',
            position: 'insideLeft',
            angle: -90,
            dy: 0,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
          dataKey={chartOptions.yaxis}
          reversed
          type='category'
          fontSize='11px'
          fontWeight='500'
        />
        <Tooltip content={<ChartTooltip />} />
        <Legend
          verticalAlign='top'
          align='right'
          iconSize='10px'
          iconType='circle'
          wrapperStyle={{ fontSize: 11, fontWeight: 500 }}
        />
        {chartOptions.data.map((row, i) => (
          <Bar
            key={i}
            dataKey={row.dataKey}
            fill={row.fill || getColor(row.colorIndex || i)}
            name={row.label}
            unit={row.unit || ''}
            maxBarSize={40}
            animationEasing='ease-in-out'
            animationDuration={1000}
          />
        ))}
      </BarChartRe>
    </ResponsiveContainer>
  )
}

export function StackedBarChart({ data, height, chartOptions, ...other }) {
  let xRotateLabels = chartOptions?.rotateLabels ? chartOptions?.rotateLabels : false;

  return (
    <ResponsiveContainer width='100%' height={height ? height : 350}>
      <BarChartRe data={data} {...other}>
        <CartesianGrid strokeDasharray='5 5' stroke='#dee2e6' />
        <XAxis
          dataKey={chartOptions.xaxis}
          fontSize='11px'
          angle={xRotateLabels ? -75 : 0}
          textAnchor={xRotateLabels ? 'end' : 'middle'} 
          height={xRotateLabels ? 150 : 20} 
          fontWeight='500'
          label={{
            value: chartOptions.xLabel ? chartOptions.xLabel : '',
            position: 'insideBottom',
            offset: xRotateLabels ? -5 : 0,
            dy: xRotateLabels ? -10 : 3,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
        />
        <YAxis
          fontSize='11px'
          fontWeight='500'
          label={{
            value: chartOptions.labelY,
            angle: -90,
            position: 'insideLeft',
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
        />
        <Tooltip content={<ChartTooltip />} />
        <Legend
          verticalAlign='top'
          align='right'
          iconSize='10px'
          iconType='circle'
          wrapperStyle={{ fontSize: 11, fontWeight: 500 }}
        />
        {chartOptions.data.map((row, i) => (
          <Bar
            key={i}
            dataKey={row.dataKey}
            fill={row.fill || getColor(row.colorIndex || i)}
            name={row.label}
            stackId={chartOptions.stackId}
            maxBarSize={40}
            unit={row.unit || ''}
            animationEasing='ease-in-out'
            animationDuration={1000}
          />
        ))}
      </BarChartRe>
    </ResponsiveContainer>
  )
}

export function HorizontalStackedBarChart({ data, height = 1000, chartOptions }) {
  return (
    <ResponsiveContainer width='100%' height={height}>
      <BarChartRe
        data={data}
        layout='vertical'
        barGap={40}
        barCategoryGap='10%'
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 50,
        }}
      >
        <CartesianGrid strokeDasharray='5 5' stroke='#dee2e6' />
        <YAxis
          type='category'
          dataKey={chartOptions.yaxis}
          fontSize='11px'
          fontWeight='500'
          label={{
            value: chartOptions.yLabel ? chartOptions.yLabel : '',
            angle: -90,
            position: 'insideLeft',
            dx: -50,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
        />
        <XAxis
          type='number'
          fontSize='11px'
          fontWeight='500'
          label={{
            value: chartOptions?.xLabel || '',
            position: 'insideBottom',
            offset: 0,
            dy: 3,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
        />
        <Tooltip content={<ChartTooltip />} />
        <Legend
          verticalAlign='top'
          align='right'
          iconSize='10px'
          iconType='circle'
          wrapperStyle={{ fontSize: 11, fontWeight: 500 }}
        />
        {chartOptions.data.map((row, i) => (
          <Bar
            key={i}
            dataKey={row.dataKey}
            fill={row.fill || getColor(row.colorIndex || i)}
            name={row.label}
            stackId={chartOptions.stackId}
            maxBarSize={40}
            unit={row.unit || ''}
            animationEasing='ease-in-out'
            animationDuration={1000}
            onClick={(e) => {
              if (row.onClick instanceof Function) row.onClick(e)
            }}
          />
        ))}
      </BarChartRe>
    </ResponsiveContainer>
  )
}

export function MultiXAxisBarChart({ data, height = 1000, chartOptions }) {
  return (
    <ResponsiveContainer width='100%' height={height}>
      <BarChartRe
        data={data}
        layout='vertical'
        barGap={40}
        barCategoryGap='10%'
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 50,
        }}
      >
        <CartesianGrid strokeDasharray='5 5' stroke='#dee2e6' />
        <YAxis
          type='category'
          dataKey={chartOptions.yaxis}
          fontSize='11px'
          fontWeight='500'
          label={{
            value: chartOptions.yLabel ? chartOptions.yLabel : '',
            angle: -90,
            position: 'insideLeft',
            dx: -50,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
        />
        <XAxis
          type='number'
          fontSize='11px'
          fontWeight='500'
          label={{
            value: chartOptions?.xLabel || '',
            position: 'insideBottom',
            offset: 0,
            dy: 3,
            fontSize: '11px',
            fontWeight: 500,
            fill: '#666666',
          }}
        />
        <Tooltip content={<ChartTooltip />} />
        <Legend
          verticalAlign='top'
          align='right'
          iconSize='10px'
          iconType='circle'
          wrapperStyle={{ fontSize: 11, fontWeight: 500 }}
        />
        {chartOptions.data.map((row, i) => (
          <Bar
            key={i}
            dataKey={row.dataKey}
            fill={row.fill || getColor(row.colorIndex || i)}
            name={row.label}
            stackId={chartOptions.stackId}
            maxBarSize={40}
            unit={row.unit || ''}
            animationEasing='ease-in-out'
            animationDuration={1000}
            onClick={(e) => {
              if (row.onClick instanceof Function) row.onClick(e)
            }}
          />
        ))}
      </BarChartRe>
    </ResponsiveContainer>
  )
}
