import { Grid, Typography } from "@mui/material";
import Status from "./Status";
import WhatsaAppContext from './Context'
import NDR from "./NDR";

export default function WhatsAppComm({ tab, dateRange }) {
    let tabName;
    if (tab === 8) {
        tabName = 'Shipment Status'
    } else if (tab === 9) {
        tabName = 'NDR Automation'
    }

    return (
        <WhatsaAppContext.Provider value={{ dateRange }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6"> {tabName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {(tab === 8) && <Status />}
                    {(tab === 9) && <NDR />}
                </Grid>
            </Grid>
        </WhatsaAppContext.Provider>
    )
}