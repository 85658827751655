import React from 'react';

const CourierItems = (props) => {
    const { courierList } = props;
    const courierData = (courierList.length > 0) ? courierList : [];
    return (
        <>
            {courierData.map((item) =>
                <tr key={item.id}>
                    <td>{item.cpAccountCode}</td>
                    <td>{item.type}</td>
                    <td>*</td>
                    <td><label className="switch me-2">
                        <input type="checkbox" />
                        <span className="slider round"></span> </label>
                        *InActive</td>
                    <td className="text-end">
                        <a href="courier-listing2.htm" className="action-link my-0 me-3 p-0">
                            <img src="img/rate-card.svg" width="22" alt="" /> Rate card
                        </a>
                        <a href="#" className="action-link m-0 p-0">
                            <img src="img/download.svg" width="22" alt="" /> Download
                        </a>
                    </td>
                </tr>
            )}
        </>
    );
};
export default CourierItems;