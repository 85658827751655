import React, { useState } from "react";
import {
    apiConstants,
    postGeneric,
} from "../../common/constants";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from '../../common/hook-form';
import {
    Button,
    Grid,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import jwt_decode from "jwt-decode";
import { getToken } from "../../helpers/ReduxHelpers";
import { LoadingButton } from "@mui/lab";
import RHFSelectField from "../../common/hook-form/RHFSelectField";
import { actionList } from "../../helpers/UtilityHelper";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormField from "./formField";

const NDRCreateAutomatedRule = ({ setOpen, setReload }) => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const userToken = jwt_decode(getToken());
    const merchantId = userToken && userToken.merchantOid ? userToken.merchantOid : "";

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                ruleName: Yup.string().required("Please provide rule name")
                    .matches(/^rule_\d+$/, 'Rule name must be in the format "rule_" followed by a number'),
                initialAction: Yup.string().required("Please select action"),
                daysToReattempt: Yup.mixed()
                    .when('initialAction', {
                        is: (initialAction) => initialAction && initialAction === "reattempt",
                        then: Yup.number().typeError('Days must be in number').positive().typeError('Days must be in number').required("Days is required!"),
                        otherwise: Yup.string().notRequired(),
                    }),
                // list: Yup.array().of(
                //     Yup.object().shape({
                //         fact: Yup.string().required("Please select Fact"),
                //         operator: Yup.string().required("Please select Operator"),
                //         fieldValue: Yup.array().min(1, "Please select at least one value")
                //             .when('fact', {
                //                 is: (fact) => fact === "daysToReattempt",
                //                 then: Yup.string().notRequired(),
                //                 otherwise: Yup.array().min(1, "Please select at least one value"),
                //             }),
                //     })
                // ),
            })
        ),
        defaultValues: {
            ruleName: "",
            initialAction: "",
            daysToReattempt: "",
            list: [
                {
                    fact: "",
                    operator: "",
                    fieldValue: [],
                },
            ],
        },
        shouldUnregister: true
    });

    const { handleSubmit, reset, control, watch, register, resetField,getValues } = methods;

    const onSubmitCreateRule = async (data) => {
        setLoading(true);
        try {
            let payload = {
                "ruleList": data.ruleName,
                "action": data.initialAction,
            };
            if (data.daysToReattempt) {
                payload["preferredDeliveryDate"] = data.daysToReattempt
            }
            data.list.forEach(item => {
                payload[item.fact] = item.fact === "daysSinceFirstAttempt" ?
                    item.firstAttempt :
                    item.fieldValue?.map(value => value.toString());
            });
            await postGeneric(`${apiConstants.NDR_RULEUPDATE}?merchantOid=${merchantId}&status=ACTIVE`, payload).then((res) => {
                if (res.status === 200) {
                    if (res.data && res.data?.status === 'ERROR') {
                        enqueueSnackbar(res.data.errorMsg || "Error creating NDR rule", { variant: "error" });
                    } else {
                        enqueueSnackbar("Successfully created!", { variant: "success" });
                        setOpen(false)
                        setReload(reload => reload + 1)
                    }
                }
            })
            setLoading(false)
        } catch (err) {
            console.log('err', err);
            const message = err?.response?.data?.message || err.message;
            enqueueSnackbar(message || "Error creating rule", { variant: "error" });
            setLoading(false)
            // setOpen(false)
        }
        setLoading(false);
    };
    const reattemptWatch = watch("initialAction")
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <FormProvider
                                methods={methods}
                                onSubmit={handleSubmit(onSubmitCreateRule)}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            1. Name the rule <br />
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Every rule name is unique to help you differentiate. <br />
                                            Rule name should be in format "rule_x" (x can be any integer number starting from 1)
                                        </Typography>
                                        <RHFTextField
                                            name="ruleName"
                                            label="Rule Name"
                                            width="100%"
                                            color="secondary"
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            2. Add the conditions
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Select the conditions (Facts eg - OFD count), operator (say IN, meaning to have all the selected values) and value (ie value in the order when it failed delivery )
                                        </Typography>
                                    </Grid>
                                    <FormField control={control} register={register}
                                        watch={watch} reset={resetField} getValues={getValues}/>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            3. Action to be taken
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Once the above condtions are met, we can either mark it RTO or REATTEMPT. In the case of reattempt, please share the preferred number of day after which we should re attempt
                                        </Typography>
                                        <RHFSelectField
                                            name="initialAction"
                                            label="Action"
                                            width="100%"
                                            options={actionList}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {reattemptWatch === 'reattempt' && (
                                            <RHFTextField
                                                name="daysToReattempt"
                                                label="Days To Reattempt"
                                                width="100%"
                                                color="secondary"
                                                variant="filled"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <LoadingButton
                                            type="submit"
                                            loading={loading}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            Submit
                                        </LoadingButton>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            sx={{ ml: 1 }}
                                            // onClick={() => { reset() }}
                                            onClick={() => {
                                                reset({ list: [{ fact: "", operator: "", fieldValue: [] }], initialAction: '' })
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    );
};
export { NDRCreateAutomatedRule }

