import React, { useEffect, useRef, useState } from "react";
import jwt_decode from "jwt-decode";
import withLayout from "../../../../hocs/Layout";
import { getToken } from "../../../../helpers/ReduxHelpers";
import { findInObjArr, filterByProp } from "../../../../helpers/UtilityHelper";
import {
  apiConstants,
  post,
  get,
  postMultipart,
  getDownload,
} from "../../../../common/constants";
import "../location.css";
import Loader from "../../../../common/Loader";
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import ProzoDataGrid from "../../../../common/ProzoDataGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { AddCircleRounded, Delete, DownloadRounded, Edit, UploadFileRounded } from "@mui/icons-material";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import PageTitle from "../../../../common/PageTitle";
import { GridToolbar } from "@mui/x-data-grid";
import AddPickupLocation from "./AddPickupLocationV2";
import { FilterLocation } from "../Filter";

export function MerchantLocations(props) {
  const [show, setShow] = useState(false);
  const [locationList, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [isDeleted, setIsdeleted] = useState();
  const [filteredLocationList, setFilteredLocationList] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const bulkUploadRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setShow(false);
    setCurrentId(null);
  };

  const handleShow = () => setShow(true);

  const userToken = jwt_decode(getToken());
  const merchantId =
    userToken && userToken.merchantOid ? userToken.merchantOid : "";

  const loadLocation = () => {
    setLoading(true);
    get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT}/${merchantId}`)
      .then((response) => {
        setLoading(false);
        if (
          response.data.pickup_details &&
          response.data.pickup_details.length > 0
        ) {
          setLocation(
            filterByProp(response.data.pickup_details, "is_deleted")
          )
          // setLocation(response.data.pickup_details);

        } else {
          setLocation(response.data.pickup_details);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };


  const toggleStatus = (id, status) => {
    setCurrentId(null);
    setLoading(true);
    const result = findInObjArr(locationList, id, "id");
    const addressCopy = Object.assign({}, result, {
      status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    });
    post(
      `${apiConstants.BASE_LOCAL}${apiConstants.ADD_LOCATION}/${merchantId}`,
      addressCopy
    )
      .then((response) => {
        setLoading(false);
        handleClose();
        loadLocation();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleEditClick = (id) => {
    const result = findInObjArr(locationList, id, "id");
    setCurrentId(result);
    handleShow();
  };

  const handleDownloadSampleClick = () => {
    setLoading(true);
    getDownload(
      `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=MERCHANT_PICKUP`
    )
      .then((response) => {
        setLoading(false);
        const binaryData = [];
        binaryData.push(response.data);
        const href = URL.createObjectURL(
          new Blob(binaryData, { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "pickup-location.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleBulkUpload = (e) => {
    const param = e.target.files[0];
    const formData = new FormData();
    formData.append("file", param);
    formData.append("fileName", param.name);
    setLoading(true);

    postMultipart(`${apiConstants.UPLOAD_LOCATION}/${merchantId}`, formData)
      .then((response) => {
        setLoading(false);
        loadLocation();
        enqueueSnackbar("Successfully uploaded!", { variant: "success" });
        if (bulkUploadRef.current) {
          bulkUploadRef.current.value = null;
        }
      })
      .catch((err) => {
        enqueueSnackbar("Could not be uploaded!", { variant: "error" });
        setLoading(false);
        if (bulkUploadRef.current) {
          bulkUploadRef.current.value = null;
        }
      });
  };

  const toggleDelete = (id, isDelete) => {
    setCurrentId(id);
    setIsdeleted(isDelete)
    setDeleteConfirm(true);
  };

  const handleDelete = (id, deleteVal) => {
    setLoading(true);
    const deleted = deleteVal === null || deleteVal === "0" ? "1" : "0";
    const result = findInObjArr(locationList, id, "id");
    const addressCopy = Object.assign({}, result, { is_deleted: deleted });
    post(
      `${apiConstants.BASE_LOCAL}${apiConstants.ADD_LOCATION}/${merchantId}`,
      addressCopy
    )
      .then((response) => {
        setLoading(false);
        handleClose();
        setIsdeleted()
        setCurrentId()
        loadLocation();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setIsdeleted()
        setCurrentId()
      });
  };


  useEffect(() => {
    loadLocation();
  }, [reload]);

  const handleAddClick = () => {
    setCurrentId(null);
    handleShow();
  };


  const handleFilter = (filterValues) => {
    const { contactNumber, name } = filterValues
    const filteredLocations = locationList.filter((location) => {
      const matchesContact = contactNumber ? location.contactNumber?.includes(contactNumber) : true
      const matchesName = name ? location.name?.toLowerCase().includes(filterValues.name.toLowerCase()) : true
      return matchesContact && matchesName
    })
    setFilteredLocationList(filteredLocations)
  };

  useEffect(() => {
    setFilteredLocationList(locationList);
  }, [locationList]);

  const resetFilter = () => {
    setFilteredLocationList(locationList);
  };

  return (
    <>
      <PageTitle>Pickup Locations</PageTitle>
      {loading && <Loader />}
      <input
        type="file"
        onChange={handleBulkUpload}
        ref={bulkUploadRef}
        style={{ display: "none" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              justifyContent: "flex-end",
              width: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ButtonGroup
              sx={{
                "& button": {
                  borderRight: "0 !important",
                  color: "inherit",
                  textTransform: "capitalize",
                },
              }}
              variant="text"
            >
              <Button
                startIcon={<AddCircleRounded color="primary" />}
                type="button"
                color="primary"
                onClick={handleAddClick}
              >
                Add
              </Button>
              <Button
                startIcon={<UploadFileRounded color="primary" />}
                type="button"
                color="warning"
                onClick={() => bulkUploadRef.current.click()}
              >
                Upload
              </Button>
              <Button
                startIcon={<DownloadRounded color="primary" />}
                type="button"
                color="warning"
                onClick={handleDownloadSampleClick}
              >
                Sample Download
              </Button>
            </ButtonGroup>
          </Box>
          <FilterLocation onFilter={handleFilter} resetFilter={resetFilter} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ProzoDataGrid
            rows={filteredLocationList || []}
            getRowId={(row) => row.id}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            rowCount={filteredLocationList?.length || 0}
            paginationMode={'client'}
            columns={[
              { headerName: "Name", field: "name", flex: 1.2 },
              {
                headerName: "Address",
                field: "pincode",
                flex: 2.5,
                renderCell: ({ row }) => {
                  const addArr = [];
                  if (row.address_line) addArr.push(row.address_line);
                  if (row.city) addArr.push(row.city);
                  if (row.state) addArr.push(row.state);
                  if (row.pincode) addArr.push(row.pincode);
                  if (row.country) addArr.push(row.country);
                  return addArr.join(", ");
                },
              },
              { headerName: "Contact No", field: "contactNumber", flex: 1 },
              { headerName: "Email", field: "email", flex: 1 },
              { headerName: "GSTIN", field: "gstin", flex: 1 },
              {
                headerName: "Status",
                field: "status",
                flex: 0.7,
                renderCell: ({ row }) => {
                  return (
                    <Chip
                      sx={{ fontWeight: 500 }}
                      label={row.status}
                      size="small"
                      color={row.status === "ACTIVE" ? "success" : "error"}
                    />
                  );
                },
              },
              {
                headerName: "Is Default",
                field: "is_default",
                flex: 0.7,
                renderCell: ({ row }) => {
                  return (
                    <Chip
                      sx={{ fontWeight: 500 }}
                      label={row.is_default === true ? "Yes" : "No"}
                      size="small"
                      color={row.is_default === true ? "success" : "error"}
                    />
                  );
                },
              },
              {
                headerName: "Action",
                field: "action",
                flex: 0.8,
                renderCell: ({ row }) => {
                  return (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Tooltip title="Toggle Status">
                            <IconButton
                              onClick={() => toggleStatus(row.id, row.status)}
                            >
                              {row.status === "ACTIVE" ? (
                                <CancelIcon color="primary" fontSize="small" />
                              ) : (

                                <CheckCircleIcon color="primary" fontSize="small" />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Pickup Location">
                            <IconButton onClick={() => handleEditClick(row.id)}>
                              <Edit color="primary" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Pickup Location">
                            <IconButton
                              onClick={() => toggleDelete(row.id, row.is_deleted)}
                            >
                              <Delete color="primary" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <AddPickupLocation
        show={show}
        setShow={setShow}
        merchantId={merchantId}
        setReload={setReload}
        reload={reload}
        handleClose={handleClose}
        currentId={currentId}
      />
      <ConfirmDialog
        title=''
        open={deleteConfirm}
        setOpen={setDeleteConfirm}
        onConfirm={() => handleDelete(currentId, isDeleted)}
      >
        Are you sure you want to delete Pickup Location?
      </ConfirmDialog>
    </>
  );
}

export default withLayout(() => {
  return (
    <div id="main-content" className="container-fluid">
      <MerchantLocations />
    </div>
  );
});
