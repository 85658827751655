import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import { formatNumber, numberPrecision } from "../../../../helpers/UtilityHelper"
import _ from 'lodash'
import InfoBox from "../../../../common/InfoBox";
import { StackedBarChart } from "../../../charts/BarChart";

const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    labelY: 'NDR Count',
    stackId: 'status',
    data: [
        { dataKey: '0_3_Days', label: '0-3 Days', chartType: 'bar', fill: 'rgb(0, 197, 153)' },
        { dataKey: '4_7_Days', label: '4-7 Days', chartType: 'bar', fill: 'rgb(0, 167, 235)' },
        { dataKey: '8_15_Days', label: '8-15 Days', chartType: 'bar', fill: 'rgb(255, 225, 91)' },
        { dataKey: '15+_Days', label: '15+ Days', chartType: 'bar', fill: 'red' }
    ]
}
export default function ResolutionSummary({ data }) {

    let total03Days = _.sumBy(data?.lineChartData, (item) => item['0_3_Days'] || 0);
    let total47Days = _.sumBy(data?.lineChartData, (item) => item['4_7_Days'] || 0);
    let total815Days = _.sumBy(data?.lineChartData, (item) => item['8_15_Days'] || 0);
    let total15Days = _.sumBy(data?.lineChartData, (item) => item['15+_Days'] || 0);
    let totalNDRCount = total03Days + total47Days + total815Days + total15Days;
    let total03DaysPercentage = (total03Days * 100) / totalNDRCount;
    let total47DaysPercentage = (total47Days * 100) / totalNDRCount;
    let total815DaysPercentage = (total815Days * 100) / totalNDRCount;
    let total15DaysPercentage = (total15Days * 100) / totalNDRCount;

    let k = 0
    _.forEach(data?.lineChartData, function (value, key) {
        k = k + value['0_3_Days'] + value['4_7_Days'] + value['8_15_Days'] + value['15+_Days'];
    })

    return (
        <Card elevation={1}>
            <CardHeader title="NDR Resolution Days" subheader={
                <Typography variant="body2" sx={{mt:1}}>
                    This chart shows the count of days  in which the NDR resolution was completed once the shipments were marked NDR. E.g. If 200 shipments were marked NDR on 20 June 2024, what number of shipments were either delivered or RTO’ed within 0-3 days, 4-7 days, 8-15 days and 15+ days for the shipments that were marked NDR on 20 June 2024.
                    <br /> Attainment of a firm decision of a NDR marked shipment (forward delivery, RTO or marking lost) is considered an NDR resolution.
                </Typography>} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <InfoBox header="0-3 Days" value={formatNumber(total03Days) + ' (' + numberPrecision(total03DaysPercentage, 1) + '%)'} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="4-7 Days" value={formatNumber(total47Days) + ' ( ' + numberPrecision(total47DaysPercentage, 1) + '%)'} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="8-15 Days" value={formatNumber(total815Days) + ' ( ' + numberPrecision(total815DaysPercentage, 1) + '%)'} />
                    </Grid>
                    <Grid item xs={3}>
                        <InfoBox header="15+ Days" value={formatNumber(total15Days) + ' ( ' + numberPrecision(total15DaysPercentage, 1) + '%)'} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <StackedBarChart data={data.lineChartData} chartOptions={chartOptions} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}