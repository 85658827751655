import React from "react";
import { Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { formatNumber, stringToColor, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { Box } from "@mui/system";
import _ from 'lodash'
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import CompositChart from "../../../charts/CompositChart";
import MaterialTable from "../../../../common/MaterialTable";
import useGetCourier from "../useGetCourier";

export default function AgeingSplit({ data, uniqCourierNames = [] }) {
    const [view, setView] = React.useState("chart")

    const { data: allCouriers = [] } = useGetCourier();
    const couriersGroupedById = _.groupBy(allCouriers, 'id');

    const { rows, allColumns } = transformChartDataToTable(data, [
        ...uniqCourierNames.map((z) => {
            return { key: z, label: z.replace(/_/g, " ") }
        })
    ])

    const tableColumns = [
        {
            label: 'Name',
            align: 'left',
            value: (row) => {
                const courierDetails = couriersGroupedById[row.name][0];
                const courierName = courierDetails ? (courierDetails.parent || courierDetails.name) : undefined;
                return courierName;
            },
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return formatNumber(row[z])
            },
            label: z
        })))
    ]

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxisLabel: 'Number of Shipments',
        data: uniqCourierNames.map((courier) => {

            const courierDetails = couriersGroupedById?.[courier]?.[0];
            const courierName = courierDetails ? (courierDetails.parent || courierDetails.name) : undefined;

            return {
                dataKey: courier,
                label: courierName,
                chartType: 'stackedBar',
                stackId: 'ageing',
                fill: stringToColor(courier?.replace(/_/g, " "))
            }

        })
    }

    return (
        <Card elevation={1}>
            <CardHeader title="Courier Ageing Split" action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Data In %">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Data In Count">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />

            <CardContent>
                {view === 'chart' && (
                    <CompositChart data={data} chartOptions={chartOptions} />
                )}

                {view === 'table' && (
                    <MaterialTable exportExcel={false} size="small" rows={rows} columns={tableColumns} />
                )}
            </CardContent>
        </Card>
    )
}