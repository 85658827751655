import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import MUIModal from '../../common/MUIModal';
import { Grid } from "@mui/material";
import { FormProvider, RHFDateField, RHFTextField } from "../../common/hook-form";
// import * as Yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from '@mui/lab';
// import { subDays } from 'date-fns';

const NprAction = (props) => {
    const { show, handleClose, currentNDRId, isLoadingBtn } = props;

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getFullYear()), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
    }

    const onSubmit = (data) => {
        if (data.preferred_date) {
            data.preferred_date = convertDate(data.preferred_date);
        } else {
            data.preferred_date = null;
        }
        props.handleReattemp(data);
    };

    // const phoneRegExp = /^\d{10}$/;
    const methods = useForm({
        // resolver: yupResolver(
        //     Yup.object().shape({
        //         address: Yup.string().required("Address is required!"),
        //         landmark: Yup.string().required("Landmark is required!"),
        //         phone_number: Yup.string()
        //             .matches(phoneRegExp, { message: 'Phone Number must be exactly 10 digits', excludeEmptyString: true })
        //             .min(10, 'Phone Number must be at least 10 digits')
        //             .required("Phone Number is required!"),
        //         drop_pincode: Yup.number().typeError('Pincode must be valid').positive().typeError('Pincode must be valid').required("Pincode is required!"),
        //         preferred_date: Yup.date()
        //             .typeError('Reattempt date is required')
        //             .required('Reattempt date is required')
        //             .min(subDays(new Date(), 1), 'Reattempt date cannot be in the past', { inclusive: true }),
        //     })
        // ),

    })
    const { handleSubmit: handleSubmitForm, reset } = methods

    const hideModal = () => {
        handleClose();
    };

console.log('currentNDRId',currentNDRId);

    useEffect(() => {
        if (currentNDRId && currentNDRId.pickup_details) {
            let defaultValues = {
                phone_number: currentNDRId.pickup_details?.from_phone_number,
                drop_pincode: currentNDRId.pickup_details?.from_pincode,
                address: currentNDRId.pickup_details?.from_address,
                awb: currentNDRId.awb_number,
                // preferred_date: new Date().toISOString().slice(0, 10), // Formats the date as YYYY-MM-DD
                preferred_date: null
            };
            reset({ ...defaultValues });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNDRId]);


    return (
        <MUIModal open={show} handleClose={hideModal} title={`Reattempt Action / ${currentNDRId.awb_number}`}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <FormProvider
                        methods={methods}
                        onSubmit={handleSubmitForm(onSubmit)}
                        className="w-100"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <RHFTextField multiline disabled name="awb" label="AWB" variant="filled" />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField multiline name="address" label="Address" variant="filled" />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="landmark" label="Landmark" variant="filled" />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="drop_pincode" label="Pincode" variant="filled" />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="phone_number" label="Phone Number" variant="filled" />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFDateField name="preferred_date" disablePast label="Preferred Date" variant="filled" />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField multiline name="comment" label="Remark" variant="filled" />
                            </Grid>
                            <Grid item xs={3} md={8} sx={{ mt: 2 }}>
                                <LoadingButton
                                    loading={isLoadingBtn}
                                    // fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid>

        </MUIModal>
    );
};
export default NprAction;