import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  apiConstants,
  postGeneric,
  postMultipart,
} from "../../../common/constants";
import { yupFileValidation } from "../../../helpers/UtilityHelper";
import { Chip, Grid, Link,  Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import {
  FormProvider,
  RHFFileField,
  RHFSelectField,
  RHFTextField,
} from "../../../common/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import MUIModal from "../../../common/MUIModal";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { getToken } from "../../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";

const allowedExtensions = ["image/jpeg", "image/png", "application/pdf"];

const MerchantKYC = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [modal, setModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {  merchantData, merchantStatus } = props;

  const userToken = jwt_decode(getToken());
  const merchantId =
    userToken && userToken.merchantOid ? userToken.merchantOid : "";

  const method = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        "Company COI": yupFileValidation(Yup, "file", allowedExtensions, false),
        PAN: yupFileValidation(Yup, "file", allowedExtensions),
        "Bank Details": yupFileValidation(Yup, "file", allowedExtensions),
        "Aadhar Card": yupFileValidation(Yup, "file", allowedExtensions),
        "MSME Certificate": yupFileValidation(
          Yup,
          "file",
          allowedExtensions,
          false
        ),
        "Company Finanacials": yupFileValidation(
          Yup,
          "file",
          allowedExtensions,
          false
        ),
        GST: yupFileValidation(Yup, "file", allowedExtensions),
        PANNO: Yup.string().required("Please enter pan number"),
        AADHARNO: Yup.string().required("Please enter aadhar number"),
        GSTNO: Yup.string().required("Please enter gst number"),
        COMPANYTYPE: Yup.string().required("Please select company type"),
      })
    ),
  });
  const { handleSubmit: handleSubmit1 } = method;

  const onSubmit1 = (data) => {
    setIsUploading(true);
    const request = new FormData();
    for (const p in data) {
      if (data[p] instanceof FileList) {
        request.append(p, data[p][0]);
      }
    }
    const mandatoryData = {
      id: merchantId,
      companyType: data.COMPANYTYPE,
      gst: data.GSTNO,
      pan: data.PANNO,
      aadhar: data.PANNO,
    };
    postMultipart(`merchant/submit_kyc_details/${merchantId}`, request)
      .then((response) => {
        setIsUploading(false);
        if (response.status === 200) {
          setModal(true);
          postGeneric(apiConstants.ADD_MANDATORY, mandatoryData)
            .then(() => {})
            .catch((err) =>
              enqueueSnackbar(
                err?.response?.data?.message || "Oops something went wrong!",
                { variant: "error" }
              )
            );
        }
        enqueueSnackbar("Successfully uploaded!", { variant: "success" });
      })
      .catch((err) => {
        setIsUploading(false);
        enqueueSnackbar(
          err?.response?.data?.message || "Oops something went wrong!",
          { variant: "error" }
        );
      });
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      {merchantStatus === "KYC_PENDING" && (
        <FormProvider methods={method} onSubmit={handleSubmit1(onSubmit1)}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <RHFFileField name="PAN" label="PAN Card*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFFileField name="GST" label="GST Registration Certificate*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFFileField name="Bank Details" label="Cancelled Cheque*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFFileField name="Aadhar Card" label="Aadhar Card*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFFileField
                name="Company COI"
                label="Certificate of Incorporation"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFFileField name="MSME Certificate" label="MSME Certificate" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFFileField
                name="Company Financials"
                label="Company Financials"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFTextField name="PANNO" label="Pan Number*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFTextField name="AADHARNO" label="Aadhar Number*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFTextField name="GSTNO" label="GST Number*" />
            </Grid>
            <Grid item xs={6} md={4}>
              <RHFSelectField
                options={[
                  { label: "Limited Company", value: "Limited Company" },
                  {
                    label: "Limited Liability Partnership",
                    value: "Limited Liability Partnership",
                  },
                  { label: "Patnership Firm", value: "Partnership Firm" },
                  {
                    label: "Sole Proprietorship",
                    value: "Sole Proprietorship",
                  },
                ]}
                name="COMPANYTYPE"
                width="100%"
                variant="filled"
                label="Company Type"
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <LoadingButton
                loading={isUploading}
                type="submit"
                variant="contained"
                size="medium"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
          <MUIModal open={modal} title="" handleClose={() => handleClose()}>
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
            >
              <CheckCircleSharpIcon
                color={"success"}
                fontSize="50%"
                sx={{ fontSize: 100, mb: 3 }}
              />
              <Typography variant="h4" gutterBottom>
                Successfully Uploaded
              </Typography>
            </Box>
          </MUIModal>
        </FormProvider>
      )}
      {(merchantStatus === "KYC_UPLOADED" ||
        merchantStatus === "KYC_APPROVED") && (
        <Grid item xs={12} md={12}>
          <ProzoDataGrid
            rows={merchantData?.kycDetails || []}
            getRowId={(row) => row.mongoSignedUrlDetails.id}
            pagination={false}
            paginationMode={"client"}
            columns={[
              {
                headerName: "Document Name",
                field: "kycDocName",
                flex: 1,
                renderCell: ({ row }) => {
                  return row.kycDocName.replaceAll("_", " ");
                },
              },
              {
                headerName: "Status",
                field: "link",
                flex: 1,
                renderCell: ({ row }) => {
                  if (row.active === true) {
                    return (
                      <Chip size="small" color="success" label={"Approved"} />
                    );
                  }
                  else if(row.rejected === true && row.active === false){
                    return(
                    <Chip size="small" color="error" label={"Rejected"} />
                    )
                  }
                  else if(row.active === false){
                    return (
                      <Chip size="small" color="warning" label={"Pending"} />
                    );
                  }
                },
              },
              {
                headerName: "View",
                field: "active",
                flex: 1,
                renderCell: ({ row }) => {
                  return (
                    <Link
                      href={
                        row.mongoSignedUrlDetails?.presignedUrl
                          ? row.mongoSignedUrlDetails?.presignedUrl
                          : "#"
                      }
                      variant="body2"
                    >
                      View Uploaded Proof
                    </Link>
                  );
                },
              },
              // {
              //   headerName: "Action",
              //   field: "action",
              //   flex: 1,
              //   renderCell: ({ row }) => {
              //     return (
              //       <>

              //       </>
              //     );
              //   },
              // },
            ]}
          />
        </Grid>
      )}
    </>
  );
};
export default MerchantKYC;
