import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, DialogActions } from "@mui/material";

export default function MUIModal(props) {
  const {
    open,
    handleClose,
    children,
    title,
    child,
    action,
    hideClose,
    maxWidth,
    minWidth,
    ...rest
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth ? maxWidth : "lg"}
      minwidth ={minWidth ? minWidth : 150}
      fullWidth={true}
      scroll="body"
      {...rest}
    >
      <DialogTitle>
        {title}
        {child}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {action}
        {!hideClose && <Button onClick={handleClose}>Close</Button>}
      </DialogActions>
    </Dialog>
  );
}
