import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import { TableSkeleton } from "../../../../common/Skeletons";
import useOverallPerformance from "./useOverallPerformance";
import ShipmentsOverview from "./ShipmentsOverview";
import ZoneWiseTrends from "./ZoneWiseTrends";
import StatusWiseSplit from "./StatusWiseSplit";
import CourierSplit from "./CourierSplit";
import ExportChartDataToExcel from "../../../../common/ExportChartDataToExcel";
import { getDDMMYYYYHHmmToIST } from "../../../../helpers/UtilityHelper";
import useGetCourier from "../useGetCourier";
import _ from 'lodash';
import { useSelector } from "react-redux";
import DeliveryTrend from "./DeliveryTrends";

function OverallComponent() {


    const [calcOn, setCalcOn] = React.useState('DOD')
    const [shipType, setShipType] = useState("B2C")
    const { isLoading, data } = useOverallPerformance(calcOn, shipType)
    const { data: allCouriers } = useGetCourier()
    const { userInfo } = useSelector((state) => state.user)
    const handleChange = (event) => {
        setShipType(event.target.value);
    }
    const downloadOptions = [
        {
            label: 'Download All orders',
            fileName: 'All_ORDERS',
            data: data?.allRegisteredData || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Courier', value: (row) => _.filter(allCouriers, { 'id': row.courier })[0]?.parent },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Pickup Pincode', value: 'pickupPincode' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'AWB Date', value: (row) => getDDMMYYYYHHmmToIST(row.awbRegisteredDate) },
                { label: 'Estimated Delivery Date', value: (row) => getDDMMYYYYHHmmToIST(row.estimatedDeliveryDate) }
            ]
        }
    ]

    return (
        <Accordion expanded>
            <AccordionSummary sx={{ boxShadow: 1, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">Overall Summary</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {(userInfo?.merchant?.isD2CMerchant && userInfo?.merchant?.isPTLMerchant) &&
                                <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Shipment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={shipType}
                                        onChange={handleChange}
                                        label="Shipment Type"
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: 'red', // Change the icon color
                                            },
                                        }}
                                    >
                                        <MenuItem value={"B2C"}>B2C</MenuItem>
                                        <MenuItem value={"B2B"}>B2B</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <ExportChartDataToExcel options={downloadOptions} />
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides an overall summary of all shipments. All data is populated on basis of the date of shipment creation in Proship.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <ShipmentsOverview data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ZoneWiseTrends data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <StatusWiseSplit data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DeliveryTrend data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CourierSplit data={data?.lineChartData || []} courierIds={data?.uniqCourierIds || []} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function OverallSummary() {
    return (
        <ErrorBoundary>
            <OverallComponent />
        </ErrorBoundary>
    )
}