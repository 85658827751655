import React from 'react';
import withLayout from '../../hocs/Layout';
import "../../../app/components/wallet/wallet.css";
import CodEmail from './CodEmail';
import PageTitle from '../../common/PageTitle';
import MisConfig from './MisConfig';
import LostAndDamageEmail from './LndConfig';
import WeightDiscrepancyEmail from './WdConfig';
import WalletEmailConfig from './Wallet';
import NDREmailConfig from './NdrConfig';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import NPREmailConfig from './NPREmilConfig';

const EmailConfig = () => {
    const [value, setValue] = React.useState("1");
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>Email Configuration</PageTitle>
                <TabContext value={value}>
                    <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
                        <TabList
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            sx={{
                                "& .MuiTab-root": {
                                    px: 6,
                                    py: 2.5,
                                },
                            }}
                        >
                            <Tab
                                iconPosition="top"
                                label="COD"
                                value="1"
                            />
                            <Tab
                                label="MIS"
                                value="2"
                                iconPosition="top"
                            />
                            <Tab
                                label="Lost And Damage"
                                value="3"
                                iconPosition="top"
                            />
                            <Tab
                                label="Weight Discrepancy"
                                value="4"
                                iconPosition="top"
                            />
                            <Tab
                                label="Wallet"
                                value="5"
                                iconPosition="top"
                            />
                            <Tab
                                label="NDR"
                                value="6"
                                iconPosition="top"
                            />
                            <Tab
                                label="NPR"
                                value="7"
                                iconPosition="top"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0 }}>
                        <CodEmail />
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0 }}>
                        <MisConfig />
                    </TabPanel>
                    <TabPanel value="3" sx={{ px: 0 }}>
                        <LostAndDamageEmail />
                    </TabPanel>
                    <TabPanel value="4" sx={{ px: 0 }}>
                        <WeightDiscrepancyEmail />
                    </TabPanel>
                    <TabPanel value="5" sx={{ px: 0 }}>
                        <WalletEmailConfig />
                    </TabPanel>
                    <TabPanel value="6" sx={{ px: 0 }}>
                        <NDREmailConfig />
                    </TabPanel>
                    <TabPanel value="7" sx={{ px: 0 }}>
                        <NPREmailConfig />
                    </TabPanel>
                </TabContext>
            </div>
        </>
    );
}

export default withLayout(EmailConfig);
