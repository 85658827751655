import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function StatCard({ item, image = true }) {
    return (
      <Card sx={{ display: 'flex' }} elevation={1}>
        {image && (
          <CardMedia
            component="img"
            sx={{ height: 100, width: 120, p: 4, objectFit: 'contain' }}
            image={item?.icon}
            alt="Prozo"
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: 1 }}>
          <CardContent sx={{ flex: '1 0 auto', textAlign: 'center', padding: '16px 24px' }}>
            <Typography variant="subtitle1" fontWeight={500} color="text.secondary" component="div">
              {item?.title}
            </Typography>
            <Box sx={{ marginTop: '8px' }}> {/* Increase space between title and count */}
              <Typography fontWeight={600} component="div" variant="h4">
                {item?.number}
              </Typography>
            </Box>
          </CardContent>
        </Box>
      </Card>
    );
  }
  