import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useIdle } from 'react-use';

const InactivityModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Consider the user idle after 5 minutes (300000 milliseconds)
  const isIdle = useIdle(300000);

  useEffect(() => {
    if (isIdle) {
      setIsOpen(true);
    }
  }, [isIdle]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="inactivity-dialog-title"
      aria-describedby="inactivity-dialog-description"
    >
      <DialogTitle id="inactivity-dialog-title">Are you still here ?</DialogTitle>
      <DialogContent>
        <DialogContentText id="inactivity-dialog-description">
          You have been inactive for a while. Do you need more time?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          I'm still here
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InactivityModal;
