import { Button, CardHeader, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { random } from "lodash";
import { useState } from "react";
import _ from "lodash";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFFileField,  RHFTextField } from "../../common/hook-form";

function Remarks(props) {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { id, setReload, setOpen } = props;

    // const approval = [{ label: "YES", value: false }, { label: "NO", value: true }]
    const methods = useForm(
    )
    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        try {
            let uploads = null;
            let request = {
                remark: {
                    text: data.text,
                    internal: false,
                },
                artifactId: id,
                on: props.on
            }
            if (data?.image?.length > 0) {
                let formData = new FormData()
                formData.append('file', data?.image[0] || null)
                formData.append('key', random() * 1000)
                formData.append('type', "IMAGE")

                uploads = await post(`${apiConstants.BASE_LOCAL}${apiConstants.UPLOADS_REMARKS}`,
                    formData
                )
            }
            if (uploads) {
                request.remark.uploads = [uploads.data];
            }
            await post(`${apiConstants.BASE_LOCAL}${apiConstants.SAVE_REMRKS}`,
                request
            ).then((res) => {
                setReload(reload => reload + 1)
                setOpen(open => false)
                reset()
            })
        }
        catch (err) {
            console.log(err)
        }
        setIsSubmitting(false)
    }
    return (

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} >
                <Grid item xs={12} spacing={0} sx={{ paddingTop: 1 }}>
                    <RHFTextField name="text" label="Remarks" color="secondary"
                        multiline
                        rows={4}
                        required />
                </Grid>
                <CardHeader
                    title="Add a Screenshot"
                />
                <Grid item xs={12}>
                    <RHFFileField name="image" />
                </Grid >
            </Grid>
            <Grid xs={12} display="flex" justifyContent="flex-end" sx={{ paddingTop: 2 }}>
                <Box sx={{ paddingTop: 1, marginRight: 3 }} >
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setOpen(false)}
                    >Close</Button>
                </Box>
                <Box sx={{ paddingTop: 1 }} >
                    <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        size="small"
                        type="submit"
                    >Submit</LoadingButton>
                </Box>
            </Grid>
        </FormProvider>
    )
}

export default Remarks;