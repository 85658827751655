import React from 'react';
import { Card, CardContent, CardHeader, Chip, Grid, Typography, Stack, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { parseDateAndTime, addressStringWithPrefix, formatCurrency, shouldMaskData, maskPhoneNumber, maskEmail, maskAddress } from '../../helpers/UtilityHelper';
import { useSelector } from 'react-redux';

const columns = [
    {
        field: 'item_name',
        headerName: 'Product name',
        flex: 2
    },
    {
        field: 'sku_id',
        headerName: 'SKU',
        flex: 1
    },
    {
        field: 'units',
        headerName: 'Qty',
        flex: 0.8
    },
    {
        field: 'tax',
        headerName: 'Tax',
        flex: 1,
        // type: 'number',
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} %`;
        },
    },
    {
        field: 'selling_price',
        headerName: 'Price',
        flex: 1,
        // type: "number",
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `₹ ${valueFormatted}`;
        },
    }
]


const OrderView = ({ resData }) => {
    const orderDetails = (resData && resData?.orderSearch?.data?.data[0]) || null;
    const itemDetails = ((orderDetails) ? orderDetails.item_list : [])
    const payment_mode = ((orderDetails && orderDetails.payment_mode) ? orderDetails.payment_mode : "");
    const color = payment_mode === 'COD' ? "error" : "success";
    const currentStatus = ((orderDetails && orderDetails.orderStatus) ? orderDetails.orderStatus : "");
    const { report } = useSelector(state => state);

    function getStatusColor() {
        if (['FAILED_DELIVERY', "SHIPMENT_HELD", 'RTO', "RTO_INTRANSIT"].includes(currentStatus)) {
            return "error"
        }
        if (['DELIVERED', "RTO_DELIVERED"].includes(currentStatus)) {
            return "#0abb87"
        }
        return "primary"
    }

    return (
        <>
            <Grid container spacing={2} paddingBottom={2}>
                <Grid item xs={6}>
                    <Card sx={{ minHeight: 90, maxHeight: 90 }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography variant='h5'>{(orderDetails && orderDetails.shippingLabelId) ? orderDetails.shippingLabelId : ""}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography variant='subtitle2'>Order Reference Number</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minHeight: 90, maxHeight: 90 }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography color={getStatusColor()} variant='h5'>{currentStatus.replaceAll("_", " ")}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <Typography variant='subtitle2'>Current Status</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid paddingBottom={2}>
                <Card>
                    <CardHeader title="Order Details" sx={{ fontWeight: 800 }} />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.shippingLabelId) ? orderDetails.shippingLabelId : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.channel_name) ? orderDetails.channel_name : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>AWB Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.awb_number) ? orderDetails.awb_number : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Status</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={currentStatus} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Payment Mode</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={color} label={payment_mode} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{orderDetails?.courierDetail?.parent.includes('Blended') ? orderDetails.actualCourierProviderName : orderDetails?.courierDetail?.parent}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Value</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.invoice_value) ? formatCurrency(orderDetails.invoice_value) : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.order_date) ? parseDateAndTime(orderDetails.order_date, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.invoice_number) ? orderDetails.invoice_number : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Cost</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.merchant_pricing?.price) ? formatCurrency(orderDetails.merchant_pricing?.price) : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Zone</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.merchantZone) ? orderDetails.merchantZone : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>EDD</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.edd) ? parseDateAndTime(orderDetails.edd, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Delivery Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.actualDeliveryTime) ? parseDateAndTime(orderDetails.actualDeliveryTime, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Dead Weight</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((orderDetails && orderDetails.shipment_detail[0].item_weight) ? ((orderDetails.shipment_detail[0].item_weight) / 1000).toFixed(2) : "")} kg</Typography></Grid>
                            {orderDetails && orderDetails.manifestDetails && (
                                <>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Manifest Number</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails.manifestDetails ? orderDetails.manifestDetails?.manifestNumber : "")}</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Manifest Link</Typography></Grid>
                                    <Grid item xs={6} md={3}>
                                        <Link target='_blank' href={orderDetails?.manifestDetails?.manifestUrl}>Manifest</Link>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {orderDetails?.isWDSetteled === true &&
                <Grid paddingBottom={2}>
                    <Card>
                        <CardHeader title="Weight Dispcrepancy" sx={{ fontWeight: 800 }} />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Weight Discrepancy Status </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdStatus) ? orderDetails?.wdStatus : ""} </Typography></Grid>
                                {/* <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Update Courier Price </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdChargedPriceCourier) ? formatCurrency(orderDetails?.wdChargedPriceCourier) : ""} </Typography></Grid> */}
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Updated Merchant Price </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdChargedPriceMerchant) ? formatCurrency(orderDetails?.wdChargedPriceMerchant) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Updated Weight </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(orderDetails && orderDetails?.wdChargedWeight) ? ((orderDetails?.wdChargedWeight) / 1000) + " kg" : ""} </Typography></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            }
            <Grid container spacing={2} paddingBottom={2}>
                <Grid item xs={12} md={6}>
                    <Card sx={{ minHeight: 250, maxHeight: 350 }}>
                        <CardHeader title="Pickup Details" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Pickup Name</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.pickup_details?.from_name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Phone Number </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.pickup_details?.from_phone_number}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Email ID</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.pickup_details?.from_email}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Address</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{addressStringWithPrefix("from", orderDetails?.pickup_details)}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ minHeight: 250, maxHeight: 350 }}>
                        <CardHeader title="Delivery Details" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Customer Name</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{orderDetails?.delivery_details?.to_name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Phone Number</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {orderDetails && orderDetails.terminalStatus && (
                                        <Typography component='span'>xxxxxxxxxx</Typography>
                                    )}
                                    {!orderDetails?.terminalStatus && (
                                        <Typography component='span'>{shouldMaskData(report, orderDetails) ?
                                            maskPhoneNumber(orderDetails?.delivery_details?.to_phone_number) :
                                            orderDetails?.delivery_details?.to_phone_number}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Email ID</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskData(report, orderDetails) ?
                                        maskEmail(orderDetails?.delivery_details?.to_email) : orderDetails?.delivery_details?.to_email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='subtitle2' color={"black"}>Shipping Address</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component='span'>{shouldMaskData(report, orderDetails) ?
                                        maskAddress(addressStringWithPrefix("to", orderDetails?.delivery_details)) :
                                        addressStringWithPrefix("to", orderDetails?.delivery_details)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid paddingBottom={2}>
                <Card>
                    <Grid container spacing={1} alignItems="center" justify="center">
                        {itemDetails && itemDetails.length > 0 && (
                            <Grid item xs={12}>
                                <DataGrid
                                    columns={columns}
                                    getRowId={(row) => Math.random()}
                                    rows={itemDetails || []}
                                    hideFooterPagination={true}
                                    autoHeight
                                    disableColumnMenu
                                    disableColumnFilter
                                    disableSelectionOnClick
                                />
                            </Grid>
                        )}
                    </Grid>
                </Card>
            </Grid>
        </>
    );
}

export default OrderView