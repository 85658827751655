import { Box, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";

const StyledDrawer = styled(Drawer)(({ theme, width }) => ({
    '& .MuiDrawer-paper': {
        width: width,
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    }
}))

export default function SideDrawer({ open, setOpen, drawerWidth, title, children, subtitle }) {
    return (
        <StyledDrawer
            anchor='right'
            open={open}
            onClose={() => setOpen(false)}
            width={drawerWidth || '60%'}
        >
            <Box sx={{
                px: '30px',
                py: '10px',
            }} width={1} display="flex" alignItems="center" justifyContent="space-between">
                <Box><Typography variant="h6">{title}</Typography></Box>
                <Box><IconButton onClick={() => setOpen(false)} ><CloseIcon /></IconButton></Box>
            </Box>
            <Box sx={{ px: '30px'}}><Typography variant='subtitle2'>{subtitle}</Typography></Box>
            <Box
                width={1}
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: 2,
                    px: '30px',
                    py: '10px'
                }}>
                {children}
            </Box>
        </StyledDrawer>
    )
}