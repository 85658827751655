import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material"
import { useState } from "react";

export default function DateField({ onChange, ...other }) {
    const [value, setValue] = useState(new Date())
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={value}
                onChange={(v) => {
                    setValue(v)
                    if (onChange instanceof Function)
                        onChange(v)
                }}
                {...other}
                renderInput={(params) => <TextField
                    label="From Date"
                    fullWidth
                    variant="filled"
                    {...params}
                />}
            />
        </LocalizationProvider>
    )
}