import _ from "lodash";
import {
  useGetPickUpDelayQuery,
} from "../../redux/commonRTK";
import {
  getCourierNameById,
  formatDate,
  timeIsGrater,
  parseDate,
} from "../../helpers/UtilityHelper";
import { addDays } from "date-fns";

export default function usePickupDelay(merchantID) {
  let rawDelayData = useGetPickUpDelayQuery({'merchant':merchantID,'shipment_type': "B2C", 'order_type': 'FORWARD'});
  // let rawPickedData = useGetPickedUpQuery({'merchant':merchantID,'shipment_type': "B2C"});

  if (
    rawDelayData.isLoading ||
    rawDelayData.isFetching 
  ) {
    return { isCalculating: true };
  }

  let filterDelayData = [];
  let k = rawDelayData?.data?.data.filter((item, index) => {
    return (item._id?.orderType==='FORWARD SHIPMENT' && item._id.isBreached===true);
  });
  k?.filter((item, index) => {
    item.data.filter((e) =>
      filterDelayData.push({ ...e, courierID: item?._id?.courier })
    );
  });

  const pickUpDelayed = _(filterDelayData || [])
    .transform(function (result, item) {
      const pickedUpHis = item.pickupFailedCount;

      const createdHis = item.awbRegisteredDate;
      const failedPickup = item.pickupFailedCount;

      if (createdHis) {
        const createdDate = parseDate(createdHis);
        const createdTime = formatDate(createdDate, "HH:mm");
        const isCreatedAfter12 = timeIsGrater(createdTime, "12:00");

        let estPickupDate;
        if (isCreatedAfter12) {
          estPickupDate = addDays(parseDate(item.estimatedPickupDate), 1);
        } else {
          estPickupDate = parseDate(item.estimatedPickupDate);
        }

        let pickupDelayInDays = item.pickupBreachedAgeingDays;
        let pickupDelayText = `${item.pickupBreachedAgeingDays} days`;

        let pickupDelayLabel;
        if (pickupDelayInDays === 0) {
          pickupDelayLabel = "On Time Scheduled";
        } else if (pickupDelayInDays < 3) {
          pickupDelayLabel = `+${pickupDelayInDays} Day`;
        } else {
          pickupDelayLabel = "3+ Day";
        }

        const courierName = getCourierNameById(item.courierID);
        result.push({
          ...item,
          order_date: createdHis,
          pickupDelayText,
          pickupDelayInDays,
          courierName,
          pickupDelayLabel,
          estPickupDate,
          failedPickup: failedPickup,
        });
      }
    })
    .value();

  return { pickUpDelayed };
}
