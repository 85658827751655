// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled }
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f8fa',
          border: "1px solid rgba(145, 158, 171, 0.32)",
          borderRadius: theme.shape.borderRadius,
          '&:hover': {
            backgroundColor: '#f5f8fa',
            borderColor: theme.palette.primary.main,
          },
          '&.Mui-focused': {
            backgroundColor: '#f5f8fa',
            borderColor: theme.palette.primary.main,
            borderWidth: "2px"
          },
          '&.Mui-disabled': {
            backgroundColor: '#f5f8fa'
          },
        },
        error: {
          borderColor: '#f1416c',
          borderWidth: "2px"
        },
        underline: {
          "&&&:after": {
            borderBottom: "none"
          },
          '&&&:before': {
            borderBottom: "none"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500_32]
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground
            }
          }
        }
      }
    }
  };
}
