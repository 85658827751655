import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { onlyUnique } from "../../helpers/UtilityHelper";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import { apiConstants, get } from "../../common/constants";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { getToken } from "../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";
import withLayout from "../../hocs/Layout";
import Loader from "../../common/Loader";
import { useGetCouriersQuery } from "../../redux/commonRTKPersist";
import PageTitle from "../../common/PageTitle";

function Rules() {
    const [data, setData] = useState([]);
    const [rules, setRules] = useState([]);
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: {
            Delivery_Type: "",
            Weight: "",
            ZoneName: ""
        }
    });
    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const Region = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];
    const { data: allCouriers = [] } = useGetCouriersQuery();
    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (merchantId) {
            setLoading(true)
            get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT}/${merchantId}`).then((response) => {
                setData(response?.data)
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
        }
    }, [merchantId])


    let newData = data?.mappedOrderRule?.rules?.map((item) => {
        let zone = item?.conditions?.find((d) => {
            return d.fact === "ZONE";

        })
        let drop_pincode = item?.conditions?.find((d) => {
            return d.fact === "DROP_PINCODE";

        })

        let weightKey = "";
        let weight = item?.conditions.filter((t) => t.fact === "WEIGHT"
        )
        if (weight?.length === 2) {
            weightKey = weight[0]?.operator + weight[0]?.value + " and " + weight[1]?.operator + weight[1]?.value
        }
        else {
            weightKey = weight[0]?.operator + weight[0]?.value
        }

        let DELIVERY = item?.conditions?.find((d) => {
            return d.fact === "DELIVERY_TYPE"

        })
        return { ...item, zone: zone?.value, drop_pincode: drop_pincode?.value, weightKey, DELIVERY: DELIVERY?.value }
    })
    let arr = []
    let arr2 = []
    // const filters = newData?.filter((item) => {
    //     if (item?.weightKey !== undefined) {
    //         arr.push(item?.weightKey)
    //     }

    //     if (item?.DELIVERY !== undefined) {
    //         arr2.push(item.DELIVERY)
    //     }
    // })

    let char = arr?.filter(onlyUnique)
    const options = char?.map((op) => ({ label: op, value: op }))
    let deliveredOptions = arr2.filter(onlyUnique);
    const options2 = deliveredOptions?.map((op) => ({ label: op, value: op }))

    function onSubmit(data) {
        let arr2 = [];
        newData?.map((item) => {
            if (data.ZoneName !== "" && data.Weight === "" && data.Delivery_Type === "") {
                if (item.zone === data.ZoneName) {
                    arr2.push(item);

                }
            }
            if (data.Weight !== "" && data.ZoneName === "" && data.Delivery_Type === "") {
                if (item.weightKey === data.Weight) {
                    arr2.push(item)
                }
            }
            if (data.Delivery_Type !== "" && data.ZoneName !== "" && data.Weight === "") {
                if (item.weightKey === data.Delivery_Type) {
                    if (item.zone === data.ZoneName) {
                        arr2.push(item)
                    }
                }

            }
            if (data.Delivery_Type !== "" && data.ZoneName === "" && data.Weight === "") {
                if (item.DELIVERY === data.Delivery_Type) {
                    arr2.push(item)
                }
            }
            if (data.Weight !== "" && data.ZoneName !== "" && data.Delivery_Type !== "") {
                if (item.weightKey === data.Weight) {
                    if (item.zone === data.ZoneName) {
                        if (item.DELIVERY === data.Delivery_Type) {
                            arr2.push(item)
                        }

                    }
                }

            }
            if (data.Weight !== "" && data.ZoneName === "" && data.Delivery_Type !== "") {
                if (item.weightKey === data.Weight) {
                    if (item.DELIVERY === data.Delivery_Type) {
                        arr2.push(item)
                    }
                }
            }
            if (data.Weight !== "" && data.ZoneName !== "" && data.Delivery_Type === "") {
                if (item.weightKey === data.Weight) {
                    if (item.zone === data.ZoneName) {
                        arr2.push(item)
                    }
                }
            }
            if (data.ZoneName !== "" && data.Weight === "" && data.Delivery_Type !== "") {
                if (item.zone === data.ZoneName) {
                    if (item.DELIVERY === data.Delivery_Type) {
                        arr2.push(item)
                    }
                }
            }
            return item;
        })
        setRules(arr2);
    }
    const rulesData = (rules?.length > 0 ? rules : newData)
    const sheets = [
        {
            name: "Allocation Rules",
            data: rulesData,
            columns: [
                {
                    label: 'Zone',
                    value: (col) => col.zone
                },
                {
                    label: 'Weight Slab',
                    value: (col) => col.weightKey,
                },
                {
                    label: 'Delivery Type',
                    value: (col) => col.DELIVERY
                },
                {
                    label: 'Drop Pincode',
                    value: (col) => col.drop_pincode
                },
                ...Array.from({ length: 12 }, (_, i) => ({
                    label: `Priority ${i + 1}`,
                    value: (col) => {
                        if (col?.result?.values[i] && col.result.values[i] !== "") {
                            const courier = allCouriers.find(c => c.cpAccountCode === col?.result?.values[i]);
                            const desiredFieldValue = courier ? courier.parent : "";
                            return desiredFieldValue;
                        } else {
                            return "-";
                        }
                    }
                }))
            ]
        }
    ];

    return (
        <>
            <div id="main-content" className="container-fluid">
                {(loading) && <Loader />}
                <PageTitle>Allocation Rules</PageTitle>
                <div className="row">
                   
                    <Card>
                        <CardHeader title={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography>  </Typography>
                                <DownloadExcelMultipleSheets sheets={sheets} filename="Allocation Rules" />
                            </Box>
                        } />
                        <CardContent>
                            {data?.mappedOrderRule?.type === 'CUSTOM' ? (
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                                        <Grid item xs={12} md={2}>
                                            <RHFSelectField name="ZoneName" variant="filled" width="100%" label="Zone Name" options={Region.map((op) => ({ label: _.startCase(op), value: op }))}
                                            // onChange={zoneHandle} value={zoneName}
                                            />
                                        </Grid>
                                        {options?.length > 0 &&
                                            < Grid item xs={12} md={2}>
                                                <RHFSelectField name="Weight" variant="filled" width="100%" label="Weight" options={options || []}
                                                // onChange={operatorHandle} value={operator} 
                                                />
                                            </Grid>
                                        }
                                        {options2?.length > 0 &&
                                            <Grid item xs={12} md={2}>
                                                <RHFSelectField name="Delivery_Type" variant="filled" width="100%" label="Delivery type" options={options2 || []}
                                                // onChange={deliveryHandle} value={del}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} md={3}>
                                            <Button type="onSubmit" variant="contained">Filter</Button>
                                            <Button variant="contained" color="error" onClick={() => {
                                                reset({
                                                    Delivery_Type: "",
                                                    Weight: "",
                                                    ZoneName: ""
                                                })
                                                setRules("")
                                            }} sx={{ ml: 1 }}>Reset</Button>
                                        </Grid>
                                    </Grid>

                                </FormProvider>
                            ) : data?.mappedOrderRule?.type}


                            {/* {isLoading && <CircularProgress size={50} />} */}
                            {/* {RulesData &&
                    <Box sx={{
                        border: '1px solid #e9ecef',
                        width: 1,
                        m: 1
                    }}>
                        <Grid container>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Category</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.category}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Name</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Type</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.type}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Status</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.status}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                } */}
                            {rulesData &&
                                rulesData?.map((rule, i) => (
                                    <Box key={i} sx={{
                                        border: '1px solid #e9ecef',
                                        width: 1,
                                        m: 1
                                    }}>
                                        <Typography fontSize="small" fontWeight="600" sx={{
                                            px: 2, py: 1, borderBottom: '1px solid #e9ecef',
                                        }}>Rule No. {i + 1}</Typography>
                                        <Grid container>
                                            <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderBottom: '1px solid #e9ecef' }}>
                                                <Typography fontSize="small" fontWeight="600">Conditions</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderBottom: '1px solid #e9ecef', borderLeft: '1px solid #e9ecef' }}>
                                                <Typography fontSize="small" fontWeight="600">Courier Priority</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ alignItems: 'center' }}>
                                            <Grid item xs={12} sm={6} sx={{ px: 2, py: 1 }}>
                                                {
                                                    rule?.conditions.map((con, j) => (<Typography key={j} sx={{ py: 1 }} fontSize="small">{`${con.fact} ${con.operator} ${con.value}`
                                                    }</Typography>))
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef' }}>
                                                {
                                                    rule.result.values.map((cou, k) => {
                                                        const courier = allCouriers.find(c => c.cpAccountCode === cou);
                                                        const desiredFieldValue = courier ? courier.parent : "";
                                                        return (
                                                            <Typography key={k} sx={{ py: 1 }} fontSize="small" component='span'>
                                                                {`Priority ${k + 1} : `}
                                                                <span style={{ fontWeight: 'bold' }}>{desiredFieldValue}</span>
                                                                {" | "}
                                                            </Typography>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            }
                        </CardContent>


                    </Card >
                </div>
            </div>
        </>
    )
}

export default withLayout(Rules);