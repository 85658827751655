import React from "react";
import { apiConstants } from "../../../common/constants";
import { getOrderStatus } from "../../../helpers/UtilityHelper";
import { useGenericPostRequestQuery, useGetPaginatedShipmentsQuery } from "../../../redux/commonRTK";
import _ from 'lodash'
import WhatsaAppContext from "./Context";
import getRequestFilter, { NDR_TEMPLATE_KEY } from "./filter";

export default function useNdr() {
    const { dateRange } = React.useContext(WhatsaAppContext)
    const requestPayload = getRequestFilter(dateRange, NDR_TEMPLATE_KEY)

    const { data: responseData, isLoading, isFetching } = useGenericPostRequestQuery({ url: apiConstants.DELIVERY_DB_SEARCH, data: requestPayload, type: 'POST' })

    const skip = isLoading || isFetching
    const awbNumbers = _(responseData?.data || []).map((item) => item?.meta_data?.awbNumber || null).filter((i) => i).uniq().value()
    const orderSearchQueryPayload = {
        limit: 2147483647,
        andfilter: [
            { field: 'awbNumber', operator: 'in', value: awbNumbers }
        ],
        includes: 'id,awbNumber,orderStatus,courierDetail,paymentMode'
    }
    const { data: allShipments, isLoading: isLoadingShipments, isFetching: isFetchingShipments } = useGetPaginatedShipmentsQuery(orderSearchQueryPayload, { skip })
    if (isLoading || isFetching || isFetchingShipments || isLoadingShipments) return { isLoading: true }

    const { data, count } = responseData
    const { data: allShipmentData } = allShipments
    const result = { isLoading: false, totalSent: count || 0 }

    const groupedByAwbNumber = _(data)
        // .groupBy('meta_data.awbNumber')
        .map(function (items) {
            const awbNumber = items.meta_data.awbNumber
            const id = items.identifier
            const isconversationCompleted = items?.isconversationCompleted
            const row = {id, awbNumber, totalConversations: items.length, conversations: [items],isconversationCompleted }
            const shipment = _.find(allShipmentData, { awb_number: awbNumber })

            if (shipment) {
                row.currentStatus = getOrderStatus(shipment.orderStatus)
                row.courierPartner = shipment.courierDetail.name
                row.paymentMode = shipment.payment_mode
            }
            return row;
        }).value()
    return { ...result, data, groupedByAwbNumber };
}