import React from "react"
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { useGetSelectedFilters } from "../../../../common/contexts/Filters"
import _ from 'lodash'
import { formatDate, getYYYYMMDD, numberPrecision } from "../../../../helpers/UtilityHelper"
import { addDays, addMonths, differenceInDays, differenceInHours, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isAfter, isWithinInterval } from "date-fns"
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK"
import { apiConstants } from "../../../../common/constants"
import { getMerchant } from "../../../../helpers/ReduxHelpers"
import { addWeeks } from "date-fns"

const weekStartsOn = 1

export default function useNdrAnalysis(groupOn = 'WOW', userActionCount) {
    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()

    const skip = !from_date || !to_date
    const requestNdr = { awbRegisteredFromDate: from_date, awbRegisteredToDate: to_date, merchantId: id }
    const { data: ndrAnalysisData, isLoading: isLoadingNdr, isFetching: isFetchingNdr } = useGenericPostRequestQuery({
        data: requestNdr,
        url: apiConstants.get_ndr_analysis_data
    }, { skip })
    const [isCalc, setIsCalc] = React.useState(true)

    let flattenedOrdersBasic = [];
    const flattenNdr = (ndrData, status, courierDetails) => {
        _.forEach(ndrData, (ndrItem) => {
            _.forEach(ndrItem.byResolution, (resolution) => {
                _.forEach(resolution.byResolutionDays, (resolutionDay) => {
                    _.forEach(resolutionDay.orders, (order) => {
                        flattenedOrdersBasic.push({
                            ...order,
                            NDRStatus: status,
                            ...courierDetails
                        });
                    });
                });
            });

            _.forEach(ndrItem.byOpenDays, (openDay) => {
                _.forEach(openDay.orders, (order) => {
                    flattenedOrdersBasic.push({
                        ...order,
                        NDRStatus: status,
                        ...courierDetails
                    });
                });
            });
        });
    };

    _.forEach(ndrAnalysisData, (data) => {
        flattenNdr(data.ndrResolved, "Closed", {
            courierId: data.courierId,
            courierName: data.courierName,
            courierProzoId: data.courierProzoId
        });
        flattenNdr(data.ndrOpen, "Open", {
            courierId: data.courierId,
            courierName: data.courierName,
            courierProzoId: data.courierProzoId
        });
    });

    flattenedOrdersBasic = _.filter(flattenedOrdersBasic, order => {
        if (filters?.paymentMode) {
            return order.paymentMode === filters.paymentMode;
        }
        if (filters?.courier) {
            return order.courierId === filters.courier;
        }
        return flattenedOrdersBasic;
    });

    let flattenedOrders = flattenedOrdersBasic.map((obj) => {
        const reportedDate = new Date(obj.ndrReportedDate);
        const resolutionDate = new Date(obj.ndrResolutionDate);
        const ndrResDays = differenceInDays(resolutionDate, reportedDate);

        return {
            ...obj,
            ndrResDays: ndrResDays,
        };
    });
    console.log(userActionCount)
    if (userActionCount !== 'Overall') {
        flattenedOrders = flattenedOrders.filter((order) => {
            const count = order?.actionUpdateLog?.length || 0;
            if (userActionCount === 6) {
                return count >= userActionCount;
            }
            return count === userActionCount
        })
    }

    if (userActionCount !== 'Overall') {
        flattenedOrders = flattenedOrders.filter((order) => {

            const count = order?.actionUpdateLog?.length || 0;
            if (userActionCount === 6) {
                return count >= userActionCount;
            }
            return count === userActionCount
        })
    }

    const closedNDRDelivered = _.filter(flattenedOrders, (order) => {
        return order.NDRStatus === 'Closed' && order.orderStatus === 'DELIVERED';
    });

    const closedNDRRTO = _.filter(flattenedOrders, (order) => {
        return order.NDRStatus === 'Closed' && order.orderStatus !== 'DELIVERED' && order.orderStatus !== 'LOST';
    });

    const closedNDRLost = _.filter(flattenedOrders, (order) => {
        return order.NDRStatus === 'Closed' && order.orderStatus === 'LOST';
    });

    const openNDR = _.filter(flattenedOrders, (order) => {
        return order.NDRStatus === 'Open';
    });

    const result = React.useMemo(() => {

        if (isLoadingNdr || isFetchingNdr) {
            setIsCalc(true)
            return;
        }

        let intervalArr = [],
            closedNDRDeliveredCount = closedNDRDelivered?.length,
            closedNDRRTOCount = closedNDRRTO?.length,
            closedNDRLostCount = closedNDRLost?.length,
            openNDRCount = openNDR?.length,
            totalNDRCount = closedNDRDelivered?.length + closedNDRRTO?.length + openNDR?.length + closedNDRLost?.length,
            lineChartData = [],
            uniqCourierIds = _(flattenedOrders).uniqBy('courierName').map(function (i) {
                return i.courierNames
            }).value();

        const reattemptConversionSummary = getReattemptSummary(flattenedOrders)


        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allNDRDataGroupedByDate = _.groupBy(flattenedOrders, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()

            let name = null;
            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }

            let row = { name }
            const allNDRDataBetween = _.filter(Object.keys(allNDRDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const closedNDRDelivered = _.filter(flattenedOrders, (order) => {
                return order.NDRStatus === 'Closed' && order.orderStatus === 'DELIVERED';
            });

            const allNDRDataBetweenDates = allNDRDataBetween.map(key => allNDRDataGroupedByDate[key]).flat()

            row.totalNDR = allNDRDataBetweenDates.length;
            row.totalDelivered = _.filter(allNDRDataBetweenDates, function (d) {
                return d?.orderStatus === 'DELIVERED' && d?.NDRStatus === 'Closed'
            }).length;
            row.totalRTO = _.filter(allNDRDataBetweenDates, function (d) {
                return d?.orderStatus !== 'DELIVERED' && d?.NDRStatus === 'Closed'
            }).length;
            row.totalLost = _.filter(allNDRDataBetweenDates, function (d) {
                return d?.orderStatus === 'LOST' && d?.NDRStatus === 'Closed'
            }).length;
            row.totalOpen = _.filter(allNDRDataBetweenDates, function (d) {
                return d?.NDRStatus === 'Open'
            }).length;

            const closedNDRRTOBetweenDate = _.filter(allNDRDataBetweenDates, (order) => {
                return (
                    order.NDRStatus === 'Closed' &&
                    order.orderStatus !== 'DELIVERED' &&
                    order.orderStatus !== 'LOST'
                )
            })

            const markedRtoBefore24HoursForAWB = _(closedNDRRTOBetweenDate)
                .filter(function (clNdr) {
                    try {
                        const ndrResolutionDate = new Date(clNdr.ndrResolutionDate)
                        const lastDeliveryAttemptDate = new Date(clNdr?.failedDeliveryRemarks?.[0].timestamp)
                        const diffInHours = differenceInHours(ndrResolutionDate, lastDeliveryAttemptDate)
                        if (Math.abs(diffInHours) < 24) {
                            return true
                        }
                    } catch (er) { }
                    return false
                })
                .value()
                .map((clNdr) => clNdr.awbNumber)
            row.RTO_MARKED_BEFORE_24_HOURS_COUNT = markedRtoBefore24HoursForAWB.length
            row.RTO_MARKED_BEFORE_24_HOURS_PERC =
                (row.RTO_MARKED_BEFORE_24_HOURS_COUNT / closedNDRRTOCount) * 100
            row.awbNumbers = markedRtoBefore24HoursForAWB
            row.awbDetails = flattenedOrdersBasic.filter((item) =>
                markedRtoBefore24HoursForAWB.includes(item.awbNumber)
            )

            _(allNDRDataBetweenDates)
                .filter(function (d) {
                    return d?.NDRStatus === 'Closed'
                })
                .groupBy(function (item) {
                    let attempt = item.ndrResDays
                    attempt = typeof attempt === 'undefined' ? 0 : attempt
                    if (attempt <= 3) {
                        return '0_3_Days'
                    }
                    if (attempt > 3 && attempt <= 7) {
                        return '4_7_Days'
                    }
                    if (attempt > 7 && attempt <= 15) {
                        return '8_15_Days'
                    } else {
                        return '15+_Days'
                    }
                })
                .forIn(function (value, key) {
                    row[key] = value.length
                })

            // Calculating NDR Action Ageing
            _(allNDRDataBetweenDates)
                .groupBy(function (item) {
                    const actionCount = item.actionUpdateLog.length
                    if (actionCount === 0) {
                        return 'Ageing_0_Day'
                    }
                    if (actionCount === 1) {
                        return 'Ageing_1_Day'
                    } else if (actionCount === 2) {
                        return 'Ageing_2_Days'
                    } else if (actionCount === 3) {
                        return 'Ageing_3_Days'
                    } else if (actionCount <= 5) {
                        return 'Ageing_4-5_Days'
                    } else {
                        return 'Ageing_6+_Days'
                    }
                }).forIn(function (value, key) {
                    row[key] = value.length
                })

            const reattempt = getReattemptSummary(allNDRDataBetweenDates)
            row = { ...row, ...reattempt }

            lineChartData.push(row)
        }

        const results = { reattemptConversionSummary, totalNDRCount, uniqCourierIds, lineChartData, closedNDRDeliveredCount, closedNDRRTOCount, closedNDRLostCount, openNDRCount, flattenedOrders, closedNDRDelivered, closedNDRRTO, closedNDRLost, openNDR }
        setIsCalc(false)
        return results;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from_date, to_date, isFetchingNdr, isLoadingNdr, groupOn, filters, userActionCount])

    if (isCalc) return { isLoading: isCalc }
    return { data: result, isLoading: isCalc };
}

function getReattemptSummary(flattenedOrders) {
    const reattempGroupedData = groupNDR(flattenedOrders)

    const row = {};

    row.actualReattemptHappen = (reattempGroupedData?.ACTUAL_REATTEMPT_HAPPEN || []).length
    row.actualReattemptNotHappen = (reattempGroupedData?.REATTEMPT_NOT_HAPPEN || []).length
    row.reattemptDelivered = (reattempGroupedData?.REATTEMPT_DELIVERED || []).length

    row.reattemptUndelivered = row.actualReattemptHappen + row.actualReattemptNotHappen
    row.actualTotalReattempt = row.reattemptDelivered + row.actualReattemptHappen

    row.totalReattemptRequested = row.reattemptDelivered + row.reattemptUndelivered
    row.reattemptConversionPercentage = Number(numberPrecision((row.reattemptDelivered / row.totalReattemptRequested) * 100))
    row.reattemptAdherence = Number(numberPrecision((row.actualTotalReattempt / row.totalReattemptRequested) * 100))

    return row;
}

function groupNDR(allShipments) {

    const groupedData = _.groupBy(allShipments, function (item) {

        const actionUpdateLog = item?.actionUpdateLog || []
        const failedDeliveryRemark = item?.failedDeliveryRemarks || []

        const shipmentWithReattempt = actionUpdateLog.find(al => al.action === 'REATTEMPT')

        if (shipmentWithReattempt) {

            if (item.orderStatus === 'DELIVERED') {
                return 'REATTEMPT_DELIVERED'
            }

            if ((actionUpdateLog.length > 0) && (failedDeliveryRemark.length > 0)) {

                const lastFailedDeliveryRemark = failedDeliveryRemark[failedDeliveryRemark.length - 1]
                const firstAttemptRemark = actionUpdateLog[0]

                if (lastFailedDeliveryRemark.timestamp && firstAttemptRemark.timestamp) {
                    const lastFailedDeliveryRemarkTime = new Date(lastFailedDeliveryRemark.timestamp)
                    const firstAttemptRemarkTime = new Date(firstAttemptRemark.timestamp)

                    if (isAfter(lastFailedDeliveryRemarkTime, firstAttemptRemarkTime)) {
                        return 'ACTUAL_REATTEMPT_HAPPEN'
                    }
                }

            }

            return 'REATTEMPT_NOT_HAPPEN'
        }

        return 'REATTEMPT_NOT_REQUESTED'
    })

    return groupedData;
}