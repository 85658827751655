import React from "react";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import MUIModal from "../../../common/MUIModal";
import { FormProvider, RHFMultiCheckbox, RHFSearchableSelectField, RHFTextField } from "../../../common/hook-form";
import { apiConstants, post } from "../../../common/constants";
import { toast } from "react-toastify";

const AddUser = (props) => {
  const { show, handleClose, moduleList, loadUsers, currentUser, setCurrentId } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const hideModal = () => {
    handleClose();
    setCurrentId(null);
  };

  const validation1 = {
    username: Yup.string().email().required(),
    accessModules: Yup.array().min(1).required()
  }

  const validation2 = {
    fullname: Yup.string().required(),
    role: Yup.string().required(),
  }

  const methods = useForm({

    resolver: yupResolver(Yup.object().shape(currentUser ? { ...validation1 } : { ...validation1, ...validation2 })),

    defaultValues: {
      username: "",
      fullname: "",
      role: "",
      accessModules: []
    },

    shouldUnregister: true

  });

  const { handleSubmit, setValue } = methods;

  const onSubmitHandler = async (formData) => {

    try {

      setIsLoading(true);

      const request = { ...formData, email: formData.username };

      if (!currentUser) {
        request.role = [request.role]

        await post(`${apiConstants.BASE_LOCAL}${apiConstants.CREATE_USER}`, request);
      }

      const payload = { username: request.username, accessModules: request.accessModules };
      await post(`${apiConstants.BASE_LOCAL}${apiConstants.UPDATE_USER_ROLE}`, payload)

      loadUsers();
      hideModal();
      setCurrentId(null);

    } catch (err) {

      toast.error(err?.response?.data?.message || 'Oops Something went wrong!')

    } finally {
      setIsLoading(false);
    }

  }

  React.useEffect(() => {

    if (currentUser && setValue) {
      setValue('username', currentUser?.email);
      setValue('accessModules', currentUser?.accessList || []);
    }

  }, [setValue, currentUser])

  return (
    <MUIModal open={show || (currentUser ? true : false)} handleClose={hideModal} title={`${currentUser ? 'Edit' : 'Add New'} User`} maxWidth="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={currentUser ? 12 : 4}>
            <RHFTextField inputProps={{ readOnly: currentUser ? true : false }} name="username" label="Enter user email for login" size="medium" variant="filled" />
          </Grid>
          {!currentUser && (
            <Grid item xs={12} md={4}>
              <RHFTextField name="fullname" label="Full Name" size="medium" variant="filled" />
            </Grid>
          )}
          {!currentUser && (
            <Grid item xs={12} md={4}>
              <RHFSearchableSelectField name="role" label="Role" width="100%" mt={0} mb={0} options={[{ label: 'Admin', value: 'admin' }, { label: 'User', value: 'user' }]} />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <RHFMultiCheckbox label="Account Accessibility" name="accessModules" options={moduleList?.filter(v => v !== "SA").map(v => ({ label: v, value: v }))} />
          </Grid>
          <Grid item xs={12} md={12}>
            <LoadingButton loading={isLoading} type="submit" variant="contained" size="large">Create User</LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>
    </MUIModal>
  )
};

export default AddUser;
