import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import userSlice from './userSlice';
import orderSummarySlice from './reducers/dashboard/orderSummarySlice';
import orderDetailSlice from './reducers/orderDetail/orderDetailSlice';
import orderListSlice from './reducers/orderList/orderListSlice';
import trackingListSlice from './reducers/trackingList/trackingListSlice';
import trackingDetailSlice from './reducers/trackingDetail/trackingDetailSlice';
import serviceabilitySlic from './reducers/serviceability/serviceabilitySlic';
import forgotPasswordSlice from './reducers/auth/forgotPasswordSlice';
import resetPasswordSlice from './reducers/auth/resetPasswordSlice';
import signupSlice from './reducers/auth/signupSlice';
import createOrderSlice from './reducers/orderCreate/createOrderSlice';
import bulkOrderCreateSlice from './reducers/bulkOrderCreate/bulkOrderCreateSlice';
import { commonRTK } from './commonRTK';
import { commonRTKPersist } from './commonRTKPersist';
import notificationsReducer from "./notificationSlice";
import manifestSearchOrders from './reducers/manifestData/manifestSearchOrdersSlice';
import reportsSlice from './reportsSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'orderSummarySlice',
    'orderDetailSlice',
    'orderListSlice',
    'trackingListSlice',
    'trackingDetailSlice',
    'serviceabilitySlic',
    'forgotPasswordSlice',
    'resetPasswordSlice',
    'signupSlice',
    "createOrderSlice",
    "bulkOrderCreateSlice",
    'commonRTK',
    "manifestSearchOrders"
  ]
}

const reducers = combineReducers({
  report: reportsSlice,
  user: userSlice,
  [commonRTK.reducerPath]: commonRTK.reducer,
  [commonRTKPersist.reducerPath]: commonRTKPersist.reducer,
  orderSummarySlice: orderSummarySlice,
  orderDetailSlice: orderDetailSlice,
  orderListSlice: orderListSlice,
  trackingListSlice: trackingListSlice,
  trackingDetailSlice: trackingDetailSlice,
  serviceabilitySlic: serviceabilitySlic,
  forgotPasswordSlice: forgotPasswordSlice,
  resetPasswordSlice: resetPasswordSlice,
  signupSlice: signupSlice,
  createOrderSlice: createOrderSlice,
  bulkOrderCreateSlice: bulkOrderCreateSlice,
  notifications: notificationsReducer,
  manifestSearchOrders:manifestSearchOrders
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return reducers(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, commonRTK.middleware, commonRTKPersist.middleware]
});

export const persistor = persistStore(store);
export default store;