import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import { TableSkeleton } from "../../../../common/Skeletons";
import { formatNumber } from "../../../../helpers/UtilityHelper";
import useRVP from "./useRVP";
import StatusWiseSplit from "./StatusWiseSplit";
import CourierWiseSplit from "./CourierWiseSplit";
import PickupAgeing from "./PickupAgeing";
import DeliveryAgeing from "./DeliveryAgeing";
import InfoBox from "../../../../common/InfoBox";
import CourierWiseAgeingSplit from "./CourierWiseAgeingSplit";

function RVPComponent() {

    const [calcOn, setCalcOn] = React.useState('DOD')
    const { isLoading, data } = useRVP(calcOn)

    return (
        <Accordion expanded>
            <AccordionSummary sx={{ borderRadius: 1, boxShadow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">RVP Summary</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides an overall summary of all reverse shipments. All data is populated on basis of the date of shipment creation in Proship.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Total RVP Orders" value={formatNumber(data?.totalRVPOrders)} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="PickedUp" value={`${formatNumber(data?.pickedUpRVP)} (${data?.rvpPickupPercentage}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Pending Pickup" value={`${formatNumber(data?.pendingPickup)} (${data?.rvpPendingPickupPercentage}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Delivered" value={`${formatNumber(data?.delivered)} (${data?.rvpDeliveredPercentage}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="In-Transit" value={formatNumber(data?.intransit)} />
                                </Grid>
                                {data && data?.totalRVPOrders > 0 && (
                                    <>
                                        <Grid item xs={12}>
                                            <StatusWiseSplit data={data?.chartData || []} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CourierWiseSplit uniqCourierIds={data?.uniqCourierIds || []} data={data?.chartData || []} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CourierWiseAgeingSplit
                                                title="Courier Wise Pickup Ageing"
                                                data={data?.courierWiseAgeingPickup || []}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CourierWiseAgeingSplit
                                                title="Courier Wise Delivery Ageing"
                                                data={data?.courierWiseAgeingDelivery || []}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <PickupAgeing data={data?.chartData || []} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DeliveryAgeing data={data?.chartData || []} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function RVP() {
    return (
        <ErrorBoundary>
            <RVPComponent />
        </ErrorBoundary>
    )
}