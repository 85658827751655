import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withLayout from '../../../../hocs/Layout';
// import jwt_decode from "jwt-decode";
// import { getToken } from '../../../../helpers/ReduxHelpers';
import { post, apiConstants } from '../../../../common/constants';
import { postOrder } from '../../../../redux/actions/orderCreate/createOrderAction';
import './step2.css';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../common/Loader';
import { resetOrderForm } from '../../../../redux/reducers/orderCreate/createOrderSlice';
import { Link } from 'react-router-dom';

const CreateOrderStep2 = () => {

    // const userToken = jwt_decode(getToken());
    // const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [isLoading, setLoading] = useState(false);
    const [courierList, setCouriers] = useState([]);
    const [courierType, setcourierType] = useState();
    // const [courierListRule, setCouriersRule] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orderInfo, loading, error, success } = useSelector((state) => state.createOrderSlice);

    const onSubmit = (data) => {
        dispatch(resetOrderForm());
        const cod_amount = (data.payment_mode && data.payment_mode === "COD") ? data.invoice_value : 0;
        const item_list = [{
            "item_name": `${data.item_name}`,
            "item_description": `${data.item_name}`,
            "sku_id": `${data.sku_id}`,
            "selling_price": `${data.selling_price}`,
            "units": `${data.units}`,
            "discount": `0`,
            "tax": `${data.tax}`,
            "hsn": `${data.hsn}`,
        }];

        const shipment_detail = [{
            "item_length": `${data.item_length}`,
            "item_breadth": `${data.item_breadth}`,
            "item_height": `${data.item_height}`,
        }];

        let payload = {
            "dispatch_mode": null,
            "cod_amount": cod_amount,
            "payment_mode": data.payment_mode,
            "invoice_value": data.invoice_value,
            "client_order_id": data.client_order_id,
            "reference": data.client_order_id,
            "invoice_number": data.invoice_number,
            "shipping_charge": data.shipping_charge,
            "transaction_charge": data.transaction_charge,
            "giftwrap_charge": data.giftwrap_charge,
            "shipment_detail": shipment_detail,
            "item_list": item_list,
            "skipRule": (data.courier_type === "Other") ? true : "false"

        }
        if (data.courier_type === "Other") {
            payload["courier_id"] = (data.courier) ? { "prozoId": data.courier } : ""
        }
        const finalPayload = Object.assign(
            {},
            orderInfo,
            payload
        );
        dispatch(postOrder(finalPayload));
    };
    if (error) {
        toast.error(error);
    }
    if (success && Object.keys(success).length > 0) {
        navigate('/preview-order');
    }

    const loadCourier = () => {
        setLoading(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.COURIER_LIST}`, {
            "andfilter": [
                { "field": "isDeleted", "operator": "eq", "value": "0" },
                { "field": "isExternal", "operator": "eq", "value": false },
            ],
            offset: 0,
            limit: 100,
        }).then(response => {
            setLoading(false);
            setCouriers(response.data.data);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }

    useEffect(() => {
        loadCourier();
    }, []);

    const handleCourierType = (e) => {
        dispatch(resetOrderForm());
        // console.log(e.currentTarget.value);
        const currentCourierType = e.currentTarget.value;
        setcourierType(currentCourierType);
        /*if(currentCourierType==="Other"){
            const result = finCourier(courierList,merchant.mappedOrderRule);
            setCouriersRule(result);
        }*/
    }
    // console.log(JSON.stringify(orderInfo));

    return (
        <>
            <div id="main-content" className="container-fluid">
                {(loading || isLoading) &&
                    <Loader />
                }
                <div className="row">
                    <div id="page-title" className="col-12 col-md-3">
                        <Link to={`/create-order`} className="page-title">
                            <img src="img/back.svg" width="21px" alt="" />
                            Create Order
                        </Link>
                    </div>
                    <div className="col-12 col-md-9 text-start text-md-end create-order-switch"> <span>Create order for</span> Single
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span> </label>
                        Bulk </div>
                </div>

                <div className="row">

                    <div className="col-12 col-md-7 col-lg-9">

                        <div className="row">
                            <div className="col-12">
                                <ul className="vcv-timeline">
                                    <li className="vcv-timeline-item vcv-step-done vcv-details" data-step="1" data-step-title="Download"><span>Order Details</span></li>
                                    <li className="vcv-timeline-item vcv-locations vcv-step-active" data-step="2"><span>Item Details</span></li>
                                </ul>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="form-title">Item details</h4>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-4 ${errors.item_name ? "is-invalid" : ""}`}>
                                        <label className="form-label">Item Name</label>
                                        <input
                                            {...register('item_name', {
                                                required: 'Item name is required'
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className={`form-group mb-4 ${errors.units ? "is-invalid" : ""}`}>
                                        <label className="form-label">Quantity</label>
                                        <input
                                            {...register('units', {
                                                required: 'Quantity is required'
                                            })}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number"
                                        />

                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.units ? "is-invalid" : ""}`}>
                                        <label className="form-label">SKU code</label>
                                        <input
                                            {...register('sku_id', {
                                                required: 'SKU code is required'
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="row form-group g-0">
                                        <div className={`col-12 col-md-6 item-value ${errors.selling_price ? "is-invalid" : ""}`}>
                                            <label className="form-label">Item values</label>
                                            <div className="input-group"> <span className="input-group-text">₹</span>
                                                <input
                                                    {...register('selling_price', {
                                                        required: 'Item values is required'
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter amount"
                                                />
                                            </div>
                                        </div>
                                        <div className={`col-12 col-md-6 item-tax position-relative ${errors.tax ? "is-invalid" : ""}`}>
                                            <label className="form-label">Item tax</label>
                                            <div className="input-group"> <span className="input-group-text">%</span>
                                                <input
                                                    {...register('tax', {
                                                        required: 'Item tax is required'
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter amount"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="form-label">HSN</label>
                                        <input
                                            {...register('hsn')}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <h4 className="form-title">Shipment details</h4>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.item_weight ? "is-invalid" : ""}`}>
                                        <label className="form-label">Weight</label>
                                        <div className="input-group mb-3 input-weight">
                                            <input
                                                {...register('item_weight', {
                                                    required: 'Weight is required'
                                                })}
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter dead weight"
                                            />
                                            <span className="input-group-text position-relative"> gm</span> </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={`row g-0 form-group mb-3 ${(errors.item_length || errors.item_breadth || errors.item_height) ? "is-invalid" : ""}`}>
                                        <div className="col-12 position-relative">
                                            <label className="form-label">Dimensions</label>
                                            <div className="input-group input-group-dimensions">
                                                <input
                                                    {...register('item_length', {
                                                        required: 'Length is required'
                                                    })}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Length"
                                                    aria-label="Length"
                                                />
                                                <span className="input-group-text">cm</span>
                                                <input
                                                    {...register('item_breadth', {
                                                        required: 'Breadth is required'
                                                    })}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Breadth"
                                                    aria-label="Breadth"
                                                />
                                                <span className="input-group-text">cm</span>
                                                <input
                                                    {...register('item_height', {
                                                        required: 'Height is required'
                                                    })}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Height"
                                                    aria-label="Height"
                                                />
                                                <span className="input-group-text">cm</span> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.client_order_id ? "is-invalid" : ""}`}>
                                        <label className="form-label">Reference ID</label>
                                        <input
                                            {...register('client_order_id', {
                                                required: 'Client order id is required'
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.invoice_number ? "is-invalid" : ""}`}>
                                        <label className="form-label">Invoice Number</label>
                                        <input
                                            {...register('invoice_number', {
                                                required: 'Invoice number id is required'
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.invoice_value ? "is-invalid" : ""}`}>
                                        <label className="form-label">Invoice Value</label>
                                        <input
                                            {...register('invoice_value', {
                                                required: 'Invoice value is required'
                                            })}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.shipping_charge ? "is-invalid" : ""}`}>
                                        <label className="form-label">Shipping Charge</label>
                                        <input
                                            {...register('shipping_charge', {
                                                required: 'Shipping charge id is required'
                                            })}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.transaction_charge ? "is-invalid" : ""}`}>
                                        <label className="form-label">Transaction Charge</label>
                                        <input
                                            {...register('transaction_charge', {
                                                required: 'Transaction charge id is required'
                                            })}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={`form-group mb-3 ${errors.giftwrap_charge ? "is-invalid" : ""}`}>
                                        <label className="form-label">Gift Wrap charge</label>
                                        <input
                                            {...register('giftwrap_charge', {
                                                required: 'Gift Wrap is required'
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="form-label w-100 mt-4">Payment Method</label>
                                    <div className={`form-check form-check-inline ${errors.payment_mode ? "is-invalid" : ""}`}>
                                        <input
                                            {...register('payment_mode', {
                                                required: 'Payment Method is required'
                                            })}
                                            className="form-check-input"
                                            type="radio"
                                            id="inlineRadio10"
                                            value="PREPAID"
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio10">Prepaid</label>
                                    </div>
                                    <div className={`form-check form-check-inline ${errors.payment_mode ? "is-invalid" : ""}`}>
                                        <input
                                            {...register('payment_mode', {
                                                required: 'Payment Method is required'
                                            })}
                                            className="form-check-input"
                                            type="radio"
                                            id="inlineRadio11"
                                            value="COD"
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio11">COD</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mb-4">
                                    <h4 className="form-title">Courier selection <span className="ms-2"><img src="img/thumbs-up.svg" width="20" alt="" /> Recommended courier option</span> </h4>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className={`form-check form-check-inline ${errors.courier_type ? "is-invalid" : ""}`}>
                                        <input
                                            {...register('courier_type', {
                                                required: 'Courier selection is required'
                                            })}
                                            className="form-check-input"
                                            type="radio"
                                            id="inlineRadio4"
                                            onChange={handleCourierType}
                                            value="Prozo Choice"
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio4">As per recommendation rules </label>
                                    </div>

                                    <div className={`form-check form-check-inline ${errors.courier_type ? "is-invalid" : ""}`}>
                                        <input
                                            {...register('courier_type', {
                                                required: 'Courier selection is required'
                                            })}
                                            className="form-check-input"
                                            type="radio"
                                            value="Other"
                                            onChange={handleCourierType}
                                            id="inlineRadio9"
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio9">Other Couriers</label>
                                    </div>
                                </div>
                                {courierType && courierType === "Other" &&
                                    <div className="col-12 mt-4">
                                        {courierList.map((item) =>

                                            <div className={`card ${errors.courier ? "is-invalid" : ""}`} key={item.id}>
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-12 col-md-3 col-lg-3">
                                                            <div className="form-check m-0 d-flex justify-content-start align-items-center">
                                                                <input
                                                                    {...register('courier', {
                                                                        required: 'Courier is required'
                                                                    })}
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value={item.prozoId}
                                                                />
                                                                <label className="form-check-label" htmlFor="inlineRadio44">
                                                                    {item.cpAccountCode}<br />
                                                                    *<img src="img/expressbees.png" width="135" alt="" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3 col-lg-2 d-flex justify-content-start align-items-center">
                                                            <img src="img/price.svg" className="me-1" height="18" alt="" /> *₹ 1000
                                                        </div>
                                                        <div className="col-12 col-md-3 col-lg-3 d-flex justify-content-start align-items-center">
                                                            <img src="img/calendar.svg" className="me-1" height="18" alt="" /> *4 Jun 22 - *10 Jun 22
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                }
                            </div>

                            <div className="row mb-5">
                                <div className="col-12 text-end">
                                    <button type="button" className="btn btn-outline-primary btn-cancel me-md-4">Cancel</button>
                                    <button type="submit" className="btn btn-primary btn-next onboarding-btn">
                                        Submit {(loading) ? <span className="spinner-border spinner-border-sm"></span> : ""}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-12 col-md-5 col-lg-3 order-tips mt-4 mt-md-0">
                        <div className="card card-tips p-0">
                            <div className="card-header position-relative"><img src="img/tips.svg" height="23" alt="" /> Order Summary<a href="#" className="btn-collapse"><img src="img/arrow-down2.svg" width="9" alt="" /></a></div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="text-start">Order details</h4>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <label className="form-label d-block">Order type</label>
                                        {(orderInfo.order_type) ? orderInfo.order_type : ""}</div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <label className="form-label d-block">Shipment type</label>
                                        {(orderInfo.shipmenttype_details && orderInfo.shipmenttype_details.category) ? orderInfo.shipmenttype_details.category : ""}</div>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="text-start">Customer details</h4>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <label className="form-label d-block">Name</label>
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_name) ? orderInfo.customer_detail.to_name : ""}</div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <label className="form-label d-block">Phone number</label>
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_phone_number) ? orderInfo.customer_detail.to_phone_number : ""}</div>
                                    <div className="col-12">
                                        <label className="form-label d-block">Address</label>
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_address) ? orderInfo.customer_detail.to_address : ""},
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_city) ? orderInfo.customer_detail.to_city : ""},
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_state) ? orderInfo.customer_detail.to_state : ""},
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_country) ? orderInfo.customer_detail.to_country : ""}-
                                        {(orderInfo.customer_detail && orderInfo.customer_detail.to_pincode) ? orderInfo.customer_detail.to_pincode : ""}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-start note">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.84806 10.3333L9.16671 10.5058C8.99539 10.871 8.81037 11.128 8.61164 11.2768C8.41977 11.4256 8.20391 11.5 7.96406 11.5C7.6831 11.5 7.46725 11.4053 7.31649 11.2159C7.16573 11.0198 7.12119 10.7053 7.18286 10.2725L7.64542 7.13768L7.59402 7.08696H6.83337L6.88477 6.73188C7.15202 6.73188 7.40557 6.7285 7.64542 6.72174C7.89211 6.71498 8.15251 6.70145 8.42662 6.68116L8.47801 6.73188L7.94351 10.4145C7.9161 10.6242 7.92295 10.7831 7.96406 10.8913C8.00518 10.9928 8.07371 11.0435 8.16964 11.0435C8.28614 11.0435 8.39921 10.9792 8.50885 10.8507C8.62535 10.7155 8.73842 10.543 8.84806 10.3333ZM8.16964 5.61594C8.03944 5.61594 7.93323 5.57198 7.851 5.48406C7.76876 5.39614 7.72765 5.2913 7.72765 5.16957C7.72765 4.99372 7.78589 4.83816 7.90239 4.7029C8.01889 4.56763 8.16964 4.5 8.35467 4.5C8.49172 4.5 8.59794 4.54396 8.67332 4.63188C8.75555 4.71981 8.79666 4.82464 8.79666 4.94638C8.79666 5.11546 8.73842 5.27101 8.62192 5.41304C8.50542 5.54831 8.35467 5.61594 8.16964 5.61594Z" fill="var(--bs-gray)" />
                                                <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="var(--bs-gray)" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            Enter Item and courier details to get estimated price and confrim order</p>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary btn-next btn w-100 my-0" disabled>Confirm Order</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )

}
export default withLayout(CreateOrderStep2);