import { Button, Grid, Stack, Typography } from "@mui/material";
import { RHFTextField } from "../../../common/hook-form";
import { useFormContext } from "react-hook-form";

function PackageDetailsRVP({ setActiveStep, creatingOrder }) {
    const { trigger } = useFormContext();

    const onNextHandler = async () => {
        // const status = await trigger(['pickupLocation']);
        const status = await trigger(['shipment_detail.0.item_weight', 'shipment_detail.0.item_length', 'shipment_detail.0.item_breadth', 'shipment_detail.0.item_height']);
        if (status) {
            setActiveStep(4)
        }
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="caption" fontWeight={500}>
                    <strong>Note:</strong> All package dimensions must be specified in centimeters (CMs) & Weight in Grams
                </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
                <RHFTextField variant="filled" name="shipment_detail.0.item_weight" label="Weight (gms) *" type="number" placeholder="Enter Weight" />
            </Grid>
            <Grid item xs={12} md={3}>
                <RHFTextField variant="filled" name="shipment_detail.0.item_length" label="Length (cm) *" type="number" placeholder="Enter Length" />
            </Grid>
            <Grid item xs={12} md={3}>
                <RHFTextField variant="filled" name="shipment_detail.0.item_breadth" label="Breadth (cm) *" type="number" placeholder="Enter Breadth" />
            </Grid>
            <Grid item xs={12} md={3}>
                <RHFTextField variant="filled" name="shipment_detail.0.item_height" label="Height (cm) *" type="number" placeholder="Enter Height" />
            </Grid>

            {/* <Grid item xs={12}>
                <Stack direction="row" columnGap={1} sx={{ mt: 3 }}>
                    <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(2)}>Back</Button>
                    <LoadingButton loading={creatingOrder} variant="contained" size="small" type="submit">Create Shipment</LoadingButton>
                </Stack>
            </Grid> */}
            <Grid item xs={12}>
                <Stack direction="row" columnGap={1} sx={{ mt: 3 }}>
                    <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(2)}>Back</Button>
                    <Button variant="contained" size="small" onClick={onNextHandler}>Next</Button>
                </Stack>
            </Grid>

        </Grid>
    )

}

export default PackageDetailsRVP;