import { useState, useEffect } from "react";
import CourierPartnerPopover from "../../common/Couriers";
import { formatNumber, getOrderStatus, getTAT, parseDate } from "../../helpers/UtilityHelper";
import { useGetShipmentsInParallelQuery } from "../../redux/commonRTK";
import { BarChart } from "../charts/ColumnChart";
import { HalfPieChart } from "../charts/PieChart"
import _ from 'lodash';
import { differenceInDays } from "date-fns";
import { Grid, Stack, Typography, Skeleton, IconButton, Tooltip } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useGetCouriersQuery } from "../../redux/commonRTKPersist";
import { useSelector } from "react-redux";
import { DualAxisChart } from "../charts/CompositChart";
import getColor from "../charts/colors";
import Loader from "../../common/Loader";

export default function Couriers({dateRange,shipType}) {
    const { fromDate: from_date, toDate: to_date }={fromDate: dateRange[0].startDate, toDate: dateRange[0].endDate}
    const { data = [], isLoading: isLoading1, isFetching: isFetching1 } = useGetShipmentsInParallelQuery({ from_date, to_date,shipType })
    const [selectedCouriers, setSelectedCouriers] = useState([])
    const { data: allCouriers = [], isLoading, isFetching } = useGetCouriersQuery()
    const [chartArrayDataState, setChartArrayDataState] = useState([])
    const [couriersHasData, setCouriersHasData] = useState([])
    const servedPicodes = (_.uniqBy(data.data, 'delivery_details.to_pincode')).length
    const userInfo = useSelector(state => state.user.userInfo)
    const activeSlas = userInfo?.merchant?.sla?.filter((row) => row.active === true)

    // Building Chart array
    useEffect(() => {
        const groupedByRTO = data ? _.groupBy(data.data, 'courierDetail.key') : []
        const chartArrayData = []
        const couriersWithData = []

        for (const label of ['Courier', 'Total Shipments', 'RTO', 'Shipments Mode', 'Shipments In Transit', 'Pickup Performance', 'Delivery Time']) {
            const column = [label]
            for (const courier of allCouriers) {
                const courerData = groupedByRTO[courier.id]
                if (!courerData) continue;

                if (label === 'Courier') {
                    column.push(courier.name)
                    couriersWithData.push(courier)
                } else if (label === 'Shipments Mode') {
                    const groupByPaymentMode = _.groupBy(courerData, 'payment_mode')
                    column.push({
                        data: (Object.keys(groupByPaymentMode)).map(k => ({ name: k, value: groupByPaymentMode[k].length })),
                        chartType: 'pie',
                        courierName: courier.name
                    })
                } else if (label === 'Total Shipments') {
                    const groupedByStatus = _.groupBy(courerData, 'orderStatus')
                    const delivered = groupedByStatus['DELIVERED'] ? groupedByStatus['DELIVERED'].length : 0
                    const totalOrders = courerData.length
                    column.push({
                        data: [
                            { name: 'Delivered', value: delivered },
                            { name: 'Undelivered', value: totalOrders - delivered }
                        ],
                        chartType: 'pie',
                        courierName: courier.name
                    })
                } else if (label === 'Shipments In Transit') {
                    const groupByStatus = _.groupBy(courerData, function (item) {
                        if ('INTRANSIT' === getOrderStatus(item.orderStatus)) {
                            return item.orderStatus
                        }
                    })

                    delete groupByStatus.undefined
                    const keys = Object.keys(groupByStatus)

                    column.push({
                        data: keys.map(k => ({ name: _.startCase(k), value: groupByStatus[k].length })),
                        chartType: 'barChart',
                        chartOptions: {
                            xaxis: 'name',
                            data: [{ label: 'Shipments', dataKey: 'value' }]
                        },
                        courierName: courier.name
                    })
                } else if (label === 'Pickup Performance') {
                    const groupByStatus = _.groupBy(courerData, function (item) {
                        try {
                            const pickupHistory = item.order_history.find(his => his.orderStatusCode === 4)
                            if (pickupHistory) {
                                const diffInDays = differenceInDays(parseDate(pickupHistory.timestamp), new Date(item.order_date))
                                if (diffInDays <= 1) {
                                    return '0-1'
                                } else if (diffInDays <= 2) {
                                    return '1-2'
                                } else if (diffInDays <= 3) {
                                    return '2-3'
                                } else {
                                    return '3+'
                                }
                            }
                        } catch (e) { }
                    })

                    delete groupByStatus.undefined
                    column.push({
                        data: (_.sortBy(Object.keys(groupByStatus))).map(k => ({ name: `${k} Days`, value: groupByStatus[k].length })),
                        chartType: 'pie',
                        courierName: courier.name
                    })
                } else if (label === 'RTO') {
                    const groupByStatus = _.groupBy(courerData, function (item) {
                        return getOrderStatus(item.orderStatus)
                    })

                    column.push({
                        value: groupByStatus['RTO'] ? groupByStatus['RTO'].length : 0,
                        courierName: courier.name
                    })
                } else if (label === 'Delivery Time') {
                    const courierDataGroupedByZone = _.groupBy(courerData, 'merchantZone')
                    let deliveredOrders = courerData.filter((ship) => 'DELIVERED' === getOrderStatus(ship.orderStatus))
                    deliveredOrders = deliveredOrders.length;

                    const chartData = Object.keys(courierDataGroupedByZone).map((k) => {
                        const deliveredOrdersZone = courierDataGroupedByZone[k].filter((ship) => 'DELIVERED' === getOrderStatus(ship.orderStatus))
                        const deliveryDays = deliveredOrdersZone.map((element) => {
                            let days = getTAT(element);
                            return days
                        })
                        const avgTat = Math.round((_.sum(deliveryDays)) / deliveryDays.length)
                        const orderVolumn = courierDataGroupedByZone[k].length
                        const deliveredPerc = Math.round((deliveredOrdersZone.length / deliveredOrders) * 100)

                        return { name: k, avgTat, orderVolumn, deliveredPerc }
                    })

                    column.push({
                        data: chartData,
                        chartType: 'dualAxis',
                        courierName: courier.name
                    })
                }
            }

            chartArrayData.push(column)
        }

        setCouriersHasData(couriersWithData)
        setChartArrayDataState(chartArrayData)
        setSelectedCouriers([...couriersWithData].splice(0, 3))
    }, [data, allCouriers])

    const courierCallback = (val) => {
        setSelectedCouriers(val)
    }

    const removeColumn = (name, i) => {
        setSelectedCouriers(selectedCouriers.filter((c) => c.name !== name))
    }

    const selectedCourierNames = ['Courier', ...(selectedCouriers.map(c => c.name))]

    return (
        <div className="row">
            {(isLoading || isFetching || isLoading1 || isFetching1) && <Loader />}
            <div className="col-12">
                {/* <div className="card card-courier">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-6 col-md-4 col-lg-auto mb-4 mb-md-0 me-0 me-lg-4">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0"> <img src="/img/courier2.svg" height="38" alt="dash-icon" /> </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="m-0">{activeSlas ? activeSlas.length : '-'}</h4>
                                        All couriers</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-1 mb-4 mb-md-0 me-0 me-lg-4">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0"> <img src="img/active-courier.svg" width="42" height="38" alt="dash-icon" /> </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h4 className="m-0">{activeSlas ? activeSlas.length : '-'}</h4>
                                        Active</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-auto mt-md-3 mt-lg-0 mb-4 mb-md-0">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 ms-lg-5">
                                        <h4 className="m-0">***</h4>
                                        Total Pincodes</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-auto mt-md-3 mt-lg-0 mb-4 mb-md-0">
                                <div className="d-flex align-items-center ms-lg-4">
                                    <div className="flex-grow-1">
                                        <h4 className="m-0">{formatNumber(servedPicodes)}</h4>
                                        Served Pincodes
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="hstack">
                    <div className="ms-auto">
                        <CourierPartnerPopover values={selectedCouriers} setCourier={courierCallback} data={couriersHasData} />
                    </div>
                </div>
                <div className="p-0 mt-2">
                    {(chartArrayDataState.length === 0) ? (
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" width="100%" height={100} />
                            <Skeleton variant="rounded" width="100%" height={150} />
                            <Skeleton variant="rounded" width="100%" height={150} />
                            <Skeleton variant="rounded" width="100%" height={150} />
                        </Stack>
                    ) : (
                        <Grid container>
                            <Grid item container sx={{
                                background: '#fff',
                                border: '1px solid rgb(224 224 224)',
                                marginBottom: 1
                            }}>
                                {chartArrayDataState[0].map((r, i) => <Header selectedCourierNames={selectedCourierNames} key={i} i={i} r={r} removeColumn={removeColumn} />)}
                            </Grid>
                            {
                                chartArrayDataState.map((row, i) => {
                                    if (i === 0 || row.length === 1) return null;
                                    return (
                                        <Grid key={i} item container sx={{
                                            background: '#fff',
                                            border: '1px solid rgb(224 224 224)',
                                            marginBottom: 1
                                        }}>
                                            {row.map((col, j) => (<RenderTableColumn selectedCourierNames={selectedCourierNames} key={j} data={col} index={j} />))}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    )}
                </div>
            </div>
        </div>
    )
}

function Header({ r, i, removeColumn, selectedCourierNames }) {

    if (!selectedCourierNames.includes(r)) return null

    return (
        <Grid item key={i} md={(i === 0) ? 1 : 3.6} sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            background: (i === 0) ? '#f3c349' : '#fff',
            padding: 3
        }}>
            <Typography sx={{
                transform: (i === 0) ? 'rotate(268deg)' : 'none'
            }} align="center" className={`${(i === 0) ? 'm-0' : 'm-0'}`}>{r}</Typography>

            {(i !== 0) &&
                <Tooltip title="Remove">
                    <IconButton size="small" onClick={() => removeColumn(r, i)}>
                        <CancelIcon fontSize="8px" />
                    </IconButton>
                </Tooltip>
            }
        </Grid>
    )
}

function RenderTableColumn({ data, index, selectedCourierNames }) {

    if (index === 0) {
        return (
            <Grid item md={1} sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                background: '#f3c349',
                padding: 2
            }}>
                <Typography sx={{ transform: 'rotate(270deg)' }} align="center">{data}</Typography>
            </Grid>
        )
    }

    if (!selectedCourierNames.includes(data.courierName)) return null

    if (data.chartType === 'pie') {
        return (
            <Grid sx={{
                padding: 2,
                display: 'flex',
            }} item md={3.6} alignItems="center" justifyContent="center">
                <HalfPieChart data={data.data} />
            </Grid>
        )
    }

    if (data.chartType === 'barChart') {
        return (
            <Grid sx={{
                padding: 2,
                display: 'flex',
            }} item md={3.6} alignItems="center" justifyContent="center">
                {data.data.length > 0 &&
                    <BarChart chartOptions={data.chartOptions} height={200} data={data.data} />
                }
            </Grid>
        )
    }

    if (data.chartType === 'dualAxis') {

        const chartOptions = {
            xaxis: 'name',
            yaxis: {
                left: {
                    label: 'Avg Delivery Days',
                    data: [{
                        dataKey: 'avgTat',
                        label: 'Avg Delivery Days',
                        fill: getColor(0),
                        chartType: 'bar'
                    }]
                },
                right: {
                    label: 'Delivery %',
                    tickFormatter: (v) => {
                        return `${v}%`
                    },
                    data: [{
                        dataKey: 'deliveredPerc',
                        unit: '%',
                        label: 'Delivery',
                        fill: getColor(1),
                        chartType: 'line'
                    }]
                }
            }
        }

        return (
            <Grid sx={{
                padding: 2,
                display: 'flex',
            }} item md={3.6} alignItems="center" justifyContent="center">
                {data.data.length > 0 &&
                    <DualAxisChart height={200} data={data.data} chartOptions={chartOptions} />
                }
            </Grid>
        )
    }

    return (
        <Grid sx={{
            padding: 2,
            display: 'flex',
        }} item md={3.6} alignItems="center" justifyContent="center">
            <Typography align="center" variant="h6" fontWeight={500}>{data.value}</Typography>
        </Grid>
    )
}