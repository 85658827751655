import * as React from 'react';
import { getOrderStatus, formatDate, parseDate } from "../../helpers/UtilityHelper";
import { useGetShipmentsInParallelQuery } from "../../redux/commonRTK";
import { BarChart, StackedBarChart } from "../charts/ColumnChart";
import { PieChart } from "../charts/PieChart";
import OrderDistributionMap from "./orders/OrderDistributionMap";
import OrderSummary from "./orders/OrderSummary";
import _ from 'lodash'
import { differenceInDays, eachDayOfInterval, eachWeekOfInterval, getWeek, } from "date-fns";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../../common/Loader';
import { useGetCouriersQuery } from '../../redux/commonRTKPersist';

export default function Orders({dateRange,shipType}) {
    // const [searchParams] = useSearchParams()
    // const viewbySelected = searchParams.get('viewBy')
    const { fromDate: from_date, toDate: to_date }={fromDate: dateRange[0].startDate, toDate: dateRange[0].endDate}
    const { data: allShipments, isLoading, isFetching } = useGetShipmentsInParallelQuery({ from_date, to_date,shipType })
    // console.log(allShipments)
    if (isLoading || isFetching) return <Loader />

    // Item weight calc
    let weightChartData = {
        half: {
            label: '0-500 gm',
            value: 0
        },
        one: {
            label: '500 gm-1 Kg',
            value: 0
        },
        two: {
            label: '1-2 Kg',
            value: 0
        },
        three: {
            label: '2-3 Kg',
            value: 0
        },
        four: {
            label: '3-4 Kg',
            value: 0
        },
        five: {
            label: '4-5 Kg',
            value: 0
        },
        ten: {
            label: '5-10 Kg',
            value: 0
        },
        twenty: {
            label: '10-20 Kg',
            value: 0
        },
        fourty: {
            label: '20-40 Kg',
            value: 0
        },
        more: {
            label: '40+ Kg',
            value: 0
        }
    }

    const orderItemWeight = _(allShipments?.data || []).groupBy("shipment_detail.0.item_weight").value()
    for (const key in orderItemWeight) {
        if (key <= 500) {
            weightChartData.half.value += orderItemWeight[key].length;
        } else if (key > 500 && key <= 1000) {
            weightChartData.one.value += orderItemWeight[key].length;
        } else if (key > 1000 && key <= 2000) {
            weightChartData.two.value += orderItemWeight[key].length;
        } else if (key > 2000 && key <= 3000) {
            weightChartData.three.value += orderItemWeight[key].length;
        } else if (key > 3000 && key <= 4000) {
            weightChartData.four.value += orderItemWeight[key].length;
        } else if (key > 4000 && key <= 5000) {
            weightChartData.five.value += orderItemWeight[key].length;
        } else if (key > 5000 && key <= 10000) {
            weightChartData.ten.value += orderItemWeight[key].length;
        } else if (key > 10000 && key <= 20000) {
            weightChartData.twenty.value += orderItemWeight[key].length;
        } else if (key > 20000 && key <= 40000) {
            weightChartData.fourty.value += orderItemWeight[key].length;
        } else {
            weightChartData.more.value += orderItemWeight[key].length;
        }
    }

    if (weightChartData.fourty.value === 0) delete weightChartData.fourty
    if (weightChartData.more.value === 0) delete weightChartData.more
    weightChartData = Object.values(weightChartData)
    // End item weight

    // Calc payment modes
    const orderPrepayVSCODInfo = _(allShipments?.data || []).groupBy('payment_mode').map(function (item, key) {
        return { name: key, value: item.length }
    }).value()
    // end calc

    // Calc order distribution state wise
    const orderDistribInfo = _(allShipments?.data || []).groupBy('delivery_details.to_state').transform(function (result, items, key) {
        const totalOrders = (allShipments?.data || []).length
        result[key] = { value: items.length, per: ((items.length / totalOrders) * 100).toFixed(2) + "%" }
    }).value()
    // End calc

    return (
        <>
            <OrderSummary data={allShipments} />
            <div className="row">
                <div className="col-12 mb-4">
                    <RecentShipments data={allShipments} from_date={from_date} to_date={to_date} />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-4">
                    <div className="card m-0">
                        <h5 className="card-title">Shipment Distribution</h5>
                        <div className="text-center map" style={{ height: 450 }}>
                            <OrderDistributionMap regionData={orderDistribInfo} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-4">
                    <ZoneAndShipment from_date={from_date} to_date={to_date} />
                </div>
                <div className="col-12 col-md-4">
                    <div className="card">
                        <h5 className="card-title">Prepaid vs COD</h5>
                        <div className="card-body p-0">
                            <PieChart height={340} innerRadius={100} outerRadius={120} data={orderPrepayVSCODInfo} legend={false} tooltip={false} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="card">
                        <div className="card-body p-0">
                            <h5 className="card-title">Weight Profile</h5>
                            <BarChart width='100%' height={340} chartOptions={{
                                xaxis: 'label',
                                xLabel: 'Weight Slabs',
                                yLabel: 'Total Shipments',
                                data: [
                                    {
                                        label: 'Shipments',
                                        dataKey: 'value'
                                    },
                                ]
                            }} data={weightChartData} />
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 col-md-12">
                    <SupplyAnalysis from_date={from_date} to_date={to_date} />
                </div> */}
            </div>
        </>
    )
}

function ZoneAndShipment({ from_date, to_date }) {
    const { data } = useGetShipmentsInParallelQuery({ from_date, to_date })

    const totalOrders = data?.data?.length || 0
    const groupedByZone = _.groupBy(data?.data || 0, 'merchantZone')

    const tableData = Object.keys(groupedByZone).map((zone) => {
        const totalZoneOrders = groupedByZone[zone].length
        const percent = ((totalZoneOrders / totalOrders) * 100).toFixed(2)
        const tatArr = groupedByZone[zone].map((element) => {
            const status = getOrderStatus(element.orderStatus)
            let days = 0;

            if (status === 'DELIVERED') {
                const deliveredHis = element?.order_history?.find(h => h.orderStatusCode === 8)
                const pickupdHis = element?.order_history?.find(h => h.orderStatusCode === 4)
                if (deliveredHis && pickupdHis) {
                    days = differenceInDays(parseDate(deliveredHis.timestamp), parseDate(pickupdHis.timestamp))
                }
            } else {
                const pickupdHis = element?.order_history?.find(h => h.orderStatusCode === 4)
                if (pickupdHis) {
                    days = differenceInDays(new Date(), parseDate(pickupdHis.timestamp))
                }
            }

            return days;
        })

        const avgTat = ((_.sum(tatArr)) / tatArr.length).toFixed(2)

        return { id: zone, zone, percent, avgTat, totalZoneOrders }
    })

    return (
        <div className="card" style={{ height: 540 }}>
            <h5 className="card-title">Zone & Shipments Breakup</h5>
            <PieChart height={400} innerRadius={100} outerRadius={120} data={tableData.map(d => ({ name: d.zone, value: d.totalZoneOrders }))} tooltip={false} legend={false} />
            {/* <ProzoDataGrid autoHeight={true} columns={zoneColumns} rows={tableData} hideFooterPagination={true} disableColumnFilter={true} /> */}
        </div>
    )
}

function RecentShipments({ data = [], from_date, to_date }) {
    const { data: allCouriers } = useGetCouriersQuery()
    const [groupBy, setGroupBy] = React.useState("status")

    const startDate = new Date(from_date);
    const endDate = new Date(to_date);

    let allData;
    if (groupBy === "courier") {

        allData = (data?.data || []).map((order) => {
            const courier = allCouriers.find(c => c.id === order.courierDetail?.key)
            return { ...order, courier: courier?.parent || 'N/A' }
        })

    } else {
        allData = data?.data || []
    }

    allData = allData.map((row) => ({
        ...row,
        week: 'Week ' + getWeek(new Date(row.order_date)),
    }))

    const ordersGroupedByStatus = [];
    const dateDiff = differenceInDays(endDate, startDate)
    let allDates = [], allGroupedData = [];

    let type = 'days';
    if (dateDiff < 32) {
        allDates = eachDayOfInterval({ start: startDate, end: endDate }).map(row => formatDate(row, 'yyyy-MM-dd'))
        allGroupedData = _.groupBy(allData, 'order_date')
    } else if (dateDiff >= 30 && dateDiff < 180) {
        allDates = eachWeekOfInterval({ start: startDate, end: endDate }).map(row => 'Week ' + getWeek(row))
        allGroupedData = _.groupBy(allData, 'week')
        type = 'week'
    }

    for (const k of allDates) {
        const obj = { name: k }
        if (type === 'days') {
            obj.name = formatDate(k, 'dd-MMM')
        }

        const dataOnDate = allGroupedData[k] || []
        for (const shipment of dataOnDate) {

            if (groupBy === 'pickup') {
                const kk = `${_.startCase(shipment.pickup_details.from_city)} (${shipment.pickup_details.from_pincode})`
                if (obj[kk]) {
                    obj[kk] += 1
                } else {
                    obj[kk] = 1
                }
            } else if (groupBy === 'courier') {
                if (obj[shipment.courier]) {
                    obj[shipment.courier] += 1
                } else {
                    obj[shipment.courier] = 1
                }
            } else {
                const orderStatus = getOrderStatus(shipment.orderStatus)
                if (obj[orderStatus]) {
                    obj[orderStatus] += 1
                } else {
                    obj[orderStatus] = 1
                }
            }
        }

        ordersGroupedByStatus.push(obj)
    }

    let chartColumns = [
        {
            label: 'Pending Pickup',
            dataKey: 'ORDER_PLACED',
            fill: 'rgb(0, 167, 235)'
        },
        {
            label: 'In Transit',
            dataKey: 'INTRANSIT',
            fill: 'rgba(240, 180, 27, 0.8)'
        },
        {
            label: 'Delivered',
            dataKey: 'DELIVERED',
            fill: 'rgb(0, 197, 153)'
        },
        {
            label: 'RTO',
            dataKey: 'RTO',
            fill: 'rgb(255, 11, 80)'
        },
        {
            label: 'Lost',
            dataKey: 'LOST'
        },
        {
            label: 'Cancelled',
            dataKey: 'CANCELLED'
        },
    ]

    if (groupBy === 'courier' || groupBy === 'pickup') {
        const d = _.uniq(_.flatten(ordersGroupedByStatus.map(r => Object.keys(r))))
        chartColumns = d.filter(r => r !== 'name').map(r => ({ label: r, dataKey: r }))
    }

    return (
        <div className="card m-0">
            <div className="hstack">
                <h5 className="card-title">Recent Shipments</h5>
                <RadioGroup
                    row
                    onChange={(e) => setGroupBy(e.target.value)}
                    value={groupBy}
                    className="ms-auto"
                >
                    <FormControlLabel value="status" control={<Radio size="small" />} label="Group By Status" />
                    <FormControlLabel value="courier" control={<Radio size="small" />} label="Group By Courier" />
                    <FormControlLabel value="pickup" control={<Radio size="small" />} label="Group By Pickup Points" />
                </RadioGroup>
            </div>
            <div className="text-center">
                <StackedBarChart width='100%' height={450} chartOptions={{
                    xaxis: 'name',
                    stackId: 'status',
                    labelY: 'Shipments',
                    data: chartColumns
                }} data={ordersGroupedByStatus} />
            </div>
        </div>
    )
}

// function SupplyAnalysis({ from_date, to_date }) {

//     let supplyAnalysisDataArray = [];
//     const { data: supplyAnalysisData = [] } = useGetOrdersGroupByPickupPincodesQuery({ from_date, to_date })
//     for (const row of supplyAnalysisData) {
//         let obj = {
//             label: row._id,
//             data: {
//                 ORDER_PLACED: {
//                     name: 'Pending Pickup',
//                     value: 0,
//                     fill: "rgb(0, 167, 235)"
//                 },
//                 INTRANSIT: {
//                     name: 'In-Transit',
//                     value: 0,
//                     fill: 'rgba(240, 180, 27, 0.8)'
//                 },
//                 DELIVERED: {
//                     name: 'Delivered',
//                     value: 0,
//                     fill: 'rgb(0, 197, 153)'
//                 },
//                 DELIVERY_FAILED: {
//                     name: 'Delivery Failed',
//                     value: 0,
//                     fill: '#aa0717'
//                 },
//                 RTO: {
//                     name: 'RTO',
//                     value: 0,
//                     fill: 'rgb(255, 11, 80)'
//                 }
//             }
//         }
//         for (const shipment of row.data) {
//             const shipmentStatus = getOrderStatus(shipment.currentOrderStatus)
//             if (shipmentStatus) obj.data[shipmentStatus].value += 1
//         }

//         supplyAnalysisDataArray.push(obj)
//     }

//     return (
//         <div className="card">
//             <div className="card-body p-0">
//                 <h5 className="card-title">Pickup Points</h5>
//                 <div className="card-body p-0" style={{ overflowX: 'scroll' }}>
//                     <div className="row justify-content-center">
//                         {
//                             supplyAnalysisDataArray.map((row, i) => {
//                                 const data = Object.values(row.data)
//                                 return (
//                                     <div key={i} className="col-12 col-lg-6 position-relative">
//                                         <PieChart height={400} innerRadius={90} outerRadius={110} data={data} tooltip={false} legend={false} />
//                                         <p className="m-0 text-center position-absolute w-100" style={{ top: '8%', fontSize: '12px', fontWeight: 500 }}>Pincode: {row.label}</p>
//                                     </div>
//                                 )
//                             })
//                         }
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }