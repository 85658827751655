import React from 'react';
import { formatCurrency, formatNumber } from '../../helpers/UtilityHelper';

const RevenueSummary = ({ data }) => {
  return (
    <div className="row">
      <div className="col-12 mb-4">
        <div className="card card-pr card-order-stats h-auto m-0">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/out-for-pickup.svg" width="38" height="36" alt="proship-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{formatNumber(data?.totalShipments)}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Total Shipments</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/total-order.svg" width="36" height="36" alt="proship-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{formatCurrency(data?.totalRevenue, true)}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Sales Revenue</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/out-for-pickup.svg" width="38" height="36" alt="proship-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{formatCurrency(data?.totalShipmentCost, true)}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">Shipping Cost</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/delivered.svg" width="36" height="36" alt="proship-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{formatCurrency(Math.round(data?.totalRTOCost), true)}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">RTO Cost</div>
              </div>
              <div className="col-6 col-md-3 col-lg mb-4 mb-lg-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <img src="img/ndr2.svg" height="42" alt="proship-icon" /> </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="m-0">{formatCurrency(data?.totalLostShipmentsCost)}</h4>
                  </div>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0"> Lost Shipments Cost</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueSummary;
