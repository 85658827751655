import React, { useState } from 'react';
import { formatDate,  placed } from '../../../helpers/UtilityHelper';
import { Link } from 'react-router-dom';
import { Button, Chip, Grid, Stack } from '@mui/material';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import { LoadingButton } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/Download';
import { apiConstants, postGeneric } from '../../../common/constants';

const TrackingListitem = (props) => {
    const { trackingListInfo } = props;
    const listData = (trackingListInfo.waybillDetails && trackingListInfo.waybillDetails.length > 0) ? trackingListInfo.waybillDetails : [];
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(100);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setIsLoading] = useState(false)
    // const [finalData, setFinalData] = useState([]);

    const bulkCreateHandler = () => {
        // console.log(selectedRows)
        // console.log(listData)
        // return false;

        setIsLoading(true)
        postGeneric(apiConstants.GET_BULK_LABELS, {
            awbList: selectedRows
        }).then(({ data }) => {
            window.open(data.mergedPdf, true)
        }).catch(e => console.error(e))
            .finally(() => setIsLoading(false))
    };

    const columns = [
        {
            field: "actions",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Order Details",
            flex: 1.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.orderId}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                        </Grid>
                    </>
                );
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.shippingLabelId;
            },
        },
        {
            field: "order_date",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Order Date",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                let orderStatusHistory = row.order_history;
                let os = orderStatusHistory.find((item) => placed.includes(item.orderStatusEnum));
                if (os) {
                    return os.creationDate ? formatDate(os.creationDate) : ""
                } else {
                    return row.createdDate ? formatDate(row.createdDate) : ""
                }
            },
            valueGetter: (params) => {
                const { row } = params;
                let orderStatusHistory = row.order_history;
                let os = orderStatusHistory.find((item) => placed.includes(item.orderStatusEnum));
                if (os) {
                    return os.creationDate ? formatDate(os.creationDate) : ""
                } else {
                    return row.createdDate ? formatDate(row.createdDate) : ""
                }
            },
        },
        {
            field: "awb",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "AWB Number",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row.waybill ? row.waybill : ""
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.waybill;
            },
        },
        {
            field: "courier",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Courier Partner",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row.courierPartner ? row.courierPartner : ""
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.courierPartner;
            },
        },
        {
            field: "pickupLocation",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Pickup Location",
            flex: 1
        },
        {
            field: "deliveryLocation",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Delivery Location",
            flex: 1
        },
        {
            field: "shipment",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Charged Weight",
            flex: 1.1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.item_weight} gms
                            </Grid>
                        </Grid>
                    </>
                );
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.item_weight + " gms";
            },
        },
        {
            field: "paymentDetails",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Payment Details",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.paymentMode ? row.paymentMode : "";
                const color = paymentMode === "COD" ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.invoiceValue ? "Rs. " + row.invoiceValue : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                );
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.invoiceValue + " / " + row.paymentMode;
            },
        },
        {
            field: "statusDetails",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <Chip variant="filled" label={row.currentStatus} />;
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.currentStatus;
            },
        },
        {
            field: "action",
            headerAlign: "center",
            hideSortIcons: true,
            disableExport: true,
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                size="small"
                                color="warning"
                                component={Link}
                                to={`/order-detail?waybill=${row.waybill}`}
                            >
                                Details
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];
    return (
        <>
            {
                selectedRows.length > 0 && (
                    <Stack
                        direction='row'
                        sx={{
                            mb: 1,
                            justifyContent: 'flex-end'
                        }}>
                        <LoadingButton
                            loading={loading}
                            onClick={bulkCreateHandler}
                            variant='contained'
                            startIcon={<CheckCircleIcon />}
                        >
                            Download Labels
                        </LoadingButton>
                    </Stack>
                )
            }
            <ProzoDataGrid columns={columns}
                autoHeight={true}
                rows={listData.map((row, index) => ({ ...row, id: index }))}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                setPage={setPage}
                exportToExcel={true}
                fileName={"Tracking List"}
                page={page}
                pagination={true}
                hideFooterPagination={false}
                // filterMode={"server"}
                checkboxSelection
                onSelectionModelChange={(_ids) => setSelectedRows(_ids)}
                paginationMode={'client'}
                rowHeight={180}
                rowCount={listData?.length || 0}
                getRowId={(row) => row.waybill}
            />
        </>
    );
};
export default TrackingListitem;