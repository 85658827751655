import React, { useState } from 'react';
import { parseAmount } from '../../helpers/UtilityHelper';

const BalanceDetails = (props) => {
    const { walletDetails, makePayment, merchantId } = props;
    const [addedAmount, setAddedAmount] = useState("");
    const hadleAmount = (e) => {

        if (e.target.value && e.target.value > 0) {
            setAddedAmount(e.target.value);
        }
        else {
            setAddedAmount("");
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        makePayment(addedAmount);
    }
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card card-wallet p-0 mb-4 mb-md-0 shadow-none border-bottom'>
                        <div className='card-body'>
                            <div className='row balance-row'>
                                <div className='balance col-12 col-md-3 col-lg-3'>
                                    <h4 className="form-title">{(walletDetails.walletType && walletDetails.walletType === "Prepaid") ? "Available Balance" : "Due Balance"}</h4>
                                    <p> ₹{(walletDetails.walletType && walletDetails.walletType === "Prepaid") ? parseAmount(walletDetails.walletBalance) : parseAmount(walletDetails.dueAmount)} </p>
                                    <h4 className="form-title">Wallet Type</h4>
                                    <p> {(walletDetails.walletType) ? walletDetails.walletType : ""} </p>
                                    {(walletDetails.walletType && walletDetails.walletType === "Prepaid") &&
                                        <>
                                            <h4 className="form-title">
                                                {(walletDetails.walletType && walletDetails.walletType === "Prepaid") ? "Alert Threshold" : "Credit Limit"}
                                            </h4>
                                            <p> ₹{(walletDetails.walletType && walletDetails.walletType === "Prepaid") ? parseAmount(walletDetails.alertThreshold) : parseAmount(walletDetails.creditLimit)} </p>
                                        </>
                                    }
                                </div>

                                <div className='col-12 col-md-12 col-lg-8 mb-3 mb-lg-0'>
                                    <div className="col-12 col-md-12">
                                        <div className="mb-4">
                                            <div className="mb-4">
                                                <label className="form-label">Select amount for quick recharge</label>
                                            </div>
                                            <div className="mb-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary amt-btn"
                                                    onClick={() => setAddedAmount(5000)}
                                                >
                                                    5000
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary amt-btn"
                                                    onClick={() => setAddedAmount(10000)}
                                                >
                                                    10,000
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary amt-btn"
                                                    onClick={() => setAddedAmount(20000)}
                                                >
                                                    20,000
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary amt-btn"
                                                    onClick={() => setAddedAmount(50000)}
                                                >
                                                    50,000
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary amt-btn"
                                                    onClick={() => setAddedAmount(100000)}
                                                >
                                                    1,00,000
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="col-12 col-md-12">
                                            <div className="mb-4">
                                                <label className="form-label">Amount to recharge</label>
                                                <input
                                                    type="number"
                                                    onChange={hadleAmount}
                                                    value={addedAmount}
                                                    className="form-control"
                                                    placeholder="Enter Amount to recharge"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button
                                                disabled={(addedAmount || merchantId!=='63bcf153e1b76852a159a7b5') ? false : true}
                                                type="submit"
                                                className="btn btn-primary btn-next"
                                            >
                                                Pay
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default BalanceDetails;