import { createSlice } from '@reduxjs/toolkit';
import { getOrderLists, syncOrder } from '../../actions/orderList/orderListAction';
import { updateInObjArr } from '../../../helpers/UtilityHelper';
const initialState = {
    loading: false,
    orderListInfo: {
        count: 0,
        remaining: 0,
        data: []
    },
    syncOrderInfo: {},
    syncError: null,
    suncSuccess: false,
    error: null,
    success: false,
}


export const orderListSlice = createSlice({
    name: 'orderList',
    initialState,
    reducers: {
        resetOrderList: (state) => {
            state.loading = false
            state.orderListInfo = {
                count: 0,
                remaining: 0,
                data: []
            }
            state.error = null
            state.syncOrderInfo = {}
            state.syncError = null
            state.suncSuccess = false
        },
    },
    extraReducers: {
        // get orderSummary api aggregate/orders
        [getOrderLists.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null
            }
        },
        [getOrderLists.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                orderListInfo: {
                    count: (payload.count) ? payload.count : 0,
                    remaining: (payload.remaining) ? payload.remaining : 0,
                    data: (payload.data) ? [...state.orderListInfo.data, ...payload.data] : []
                }
            }
        },
        [getOrderLists.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload
            }
        },
        [syncOrder.pending]: (state) => {
            return {
                ...state,
                loading: true,
                syncError: null
            }
        },
        [syncOrder.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                syncOrderInfo: payload,
                orderListInfo: {
                    data: updateInObjArr(state.orderListInfo.data, "awb_number", payload.awb_number, payload)
                }

            }
        },
        [syncOrder.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                syncError: payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetOrderList, resetSyncOrder } = orderListSlice.actions

export default orderListSlice.reducer