import React, { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withLayout from '../../../hocs/Layout';
import { apiConstants, post, get } from '../../../common/constants';
import { getToken } from '../../../helpers/ReduxHelpers';
import { findInObjArr, titleCase } from '../../../helpers/UtilityHelper';
import Loader from '../../../common/Loader';
import UserNotFound from './userNotFound';
import AddUser from './addUser';
import UserList from './userList';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageTitle from '../../../common/PageTitle';

const MerchantUsers = () => {
    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const [userList, setUsers] = useState(false);
    const [moduleList, setModules] = useState([]);
    // const [userModuleList, setUserModules] = useState(false);
    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const [currentId, setCurrentId] = useState();

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const loadUsers = () => {
        setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT_USERS}/?merchantid=${merchantId}`).then(response => {
            setLoading(false);
            setUsers(response.data.data);
        }).catch(err => { console.error(err); setLoading(false); });
    }

    const loadModules = () => {
        setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.LIST_MODULES}`).then(response => {
            const moduleList = response.data?.filter((val) => val !== "INTERNAL")
            setLoading(false);
            setModules(moduleList);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }

    const handleEditClick = (id) => {
        const result = findInObjArr(userList, id, "id");
        setCurrentId(result);
    }

    const handletoggleStatus = (id, status) => {

        setCurrentId(null);
        setLoading(true);
        const result = findInObjArr(userList, id, "id");
        const payload = {
            "username": result.username,
            "actionType": (status === "ACTIVE") ? "DEACTIVATE" : "ACTIVATE"
        };
        post(`${apiConstants.BASE_LOCAL}${apiConstants.UPDATE_USER_ACTION}`, payload)
            .then(response => {
                setLoading(false);
                handleClose();
                loadUsers();

            })
            .catch(err => { console.error(err); setLoading(false); });

    }

    const toggleStatus = (id, status) => {
        const actionType = (status === "ACTIVE") ? "DEACTIVATE" : "ACTIVATE"
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-ui">
                        <h1>Are you sure?</h1>
                        <p>You want to {titleCase(actionType)}?</p>
                        <button className="btn btn-outline-primary btn-previous me-md-4 me-2 mt-0" onClick={onClose}>No</button>
                        <button
                            className="btn btn-primary btn-previous me-md-4 me-2 mt-0 onboarding-btn"
                            onClick={() => {
                                handletoggleStatus(id, status);
                                onClose();
                            }}
                        >
                            Yes, {titleCase(actionType)}
                        </button>
                    </div>
                );
            }
        });
    };

    useEffect(() => {
        loadModules()
        loadUsers();
    }, []);

    return (
        <>
            <PageTitle>User Management</PageTitle>
            <div id="main-content" className="container-fluid">
                {loading &&
                    <Loader />
                }

                {(!userList || userList.length === 0) &&
                    <UserNotFound handleShow={handleShow} />
                }
                {(userList && userList.length > 0) &&
                    <UserList
                        userList={userList}
                        handleShow={handleShow}
                        handleEditClick={handleEditClick}
                        toggleStatus={toggleStatus}
                    />
                }

            </div>
            <AddUser
                show={show}
                loadUsers={loadUsers}
                handleClose={handleClose}
                moduleList={moduleList}
                currentUser={currentId}
                setCurrentId={setCurrentId}
            />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
export default withLayout(MerchantUsers);