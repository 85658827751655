import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { DualAxisChart } from '../../../charts/CompositChart'

function RTOBeforeDeliveryAttempt({ data }) {

  const chartOptions = {
    layout: 'horizontal',
    xaxis: 'name',
    yaxis: {
      left: {
        label: 'Percentage %',
        data: [
          {
            dataKey: 'RTO_MARKED_BEFORE_24_HOURS_PERC',
            label: 'Percentage %',
            chartType: 'line',
            fill: 'green',
            unit: '%',
          },
        ],
      },
      right: {
        label: 'Shipment Count',
        data: [
          {
            dataKey: 'RTO_MARKED_BEFORE_24_HOURS_COUNT',
            label: 'Number of Shipments',
            chartType: 'line',
          },
        ],
      },
    },
  }

  return (
    <>
      <Card elevation={1}>
        <CardHeader title='Marked RTO Before 24 Hours of Failed Delivery' subheader={
          <Typography variant='body2'>
            This chart shows the count and % of shipments that were marked as RTO before 24 hours of failed delivery therefore not allowing the merchant or Prozo to take NDR actions.
            This data also includes OTP based cancellations. 
            <br/>After removing OTP based cancellation cases, we need to escalate to the courier partner and ask for either re-attempt or ask the courier partner to refund forward as well as return freight cost since the merchant/Prozo couldn’t get time to resolve the NDR issue.
          </Typography>
        } />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <DualAxisChart data={data} chartOptions={chartOptions} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default RTOBeforeDeliveryAttempt
