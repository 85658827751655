import { useEffect } from 'react';
import { usePageTitle } from './PageTitleContext';

const PageTitle = ({ children }) => {
    const { setPageTitle } = usePageTitle();

    useEffect(() => {
        setPageTitle(children);
        return () => {
            setPageTitle('');
        };
    }, [children, setPageTitle]);

    return null; // PageTitle doesn't need to render anything
};

export default PageTitle;
