import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import { TableSkeleton } from "../../../../common/Skeletons";
import usePickupPerformance from "./usePickupPerformance";
import OverallAgeing from "./OverallAgeing";
import CourierWiseSplit from "./CourierWiseSplit";
import PincodeWiseSplit from "./PincodeWiseSplit";
import { useSelector } from "react-redux";

function PickupComponent() {

    const [calcOn, setCalcOn] = React.useState('DOD')
    const [shipType, setShipType] = useState("B2C")
    const { isLoading, data } = usePickupPerformance(calcOn, shipType)
    const handleChange = (event) => {
        setShipType(event.target.value);
    }
    const { userInfo } = useSelector((state) => state.user)
    return (
        <Accordion expanded>
            <AccordionSummary sx={{ boxShadow: 1, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">Pickup Performance</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {(userInfo?.merchant?.isD2CMerchant && userInfo?.merchant?.isPTLMerchant) &&
                                <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Shipment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={shipType}
                                        onChange={handleChange}
                                        label="Shipment Type"
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: 'red', // Change the icon color
                                            },
                                        }}
                                    >
                                        <MenuItem value={"B2C"}>B2C</MenuItem>
                                        <MenuItem value={"B2B"}>B2B</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides analysis on the pickup performance. All data is populated on basis of the date of shipment creation in Proship and the corresponding pickup date.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <OverallAgeing data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CourierWiseSplit data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <PincodeWiseSplit data={data} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function PickupPerformance() {
    return (
        <ErrorBoundary>
            <PickupComponent />
        </ErrorBoundary>
    )
}