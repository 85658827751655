/** @format */

import React from "react";
import { makeChatBotCall, wait } from "../../common/constants";

function useChatBot({
  textFieldRef,
  setConversation,
  threadId,
  setThreadId,
  ctToken,
}) {
  const [isFetching, setIsFetcing] = React.useState(false);

  const makeBackendCallHandler = async (message, threadId, ctToken) => {
    try {
      setIsFetcing(true);

      pushMessageToList(message, "sent");
      const firstPayload = {
        message,
        thread_id: threadId,
        run_id: null,
        last_index: 0,
        poll: false,
      };
      const messageResponse = await makeChatBotCall(firstPayload, ctToken);

      if (messageResponse.message && messageResponse.last_index > 0) {
        const isErrorOrNot = messageResponse?.status === "error" ? true : false;
        pushMessageToList(messageResponse.message, "received", isErrorOrNot, 2);
      }

      let lastIndex = messageResponse?.last_index;
      let isPoll = messageResponse.poll;
      let isStatus = messageResponse.status;
      let iteration = 0;

      while (isPoll && isStatus === "in_progress" && iteration <= 60) {
        const pollPayload = {
          poll: true,
          thread_id: messageResponse.thread_id,
          run_id: messageResponse.run_id,
          last_index: lastIndex,
        };
        await wait(lastIndex === 0 ? 2000 : 5000);
        const response = await makeChatBotCall(pollPayload, ctToken);
        isPoll = response.poll;
        isStatus = response.status;
        if (response.message && response.last_index > 0) {
          lastIndex = response.last_index;
          const isErrorOrNot = response?.status === "error" ? true : false;
          pushMessageToList(
            response.message,
            "received",
            isErrorOrNot,
            iteration
          );
        }
        iteration++;
      }

      setThreadId(messageResponse.thread_id);
    } catch (e) {
      pushMessageToList(e.message, "received", true);
    } finally {
      setIsFetcing(false);
    }
  };

  const onKeyPressHandler = (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    const typedMessage = textFieldRef.current.value;

    if (typedMessage.trim() === "") {
      return;
    }

    textFieldRef.current.value = "";
    makeBackendCallback(typedMessage.trim(), threadId, ctToken);
  };

  const pushMessageToList = (message, type, isError = false, iteration = 0) => {
    const messageBody = {
      isError,
      message,
      type,
      timestamp: new Date(),
      id: new Date().getTime(),
    };

    setConversation((conversation) => {
      if (iteration >= 1 && type !== "sent") {
        const updatedConversation = [...conversation];
        const lastIndex = updatedConversation.length - 1;
        if (updatedConversation[lastIndex].type !== "sent") {
          updatedConversation[lastIndex].startIndex =
            updatedConversation[lastIndex].message.length;
          updatedConversation[lastIndex].message += message;

          return updatedConversation;
        } else {
          return [...conversation, messageBody];
        }
      } else {
        return [...conversation, messageBody];
      }
    });
  };

  const makeBackendCallback = React.useCallback(
    makeBackendCallHandler,
    // eslint-disable-next-line
    []
  );
  return { onKeyPressHandler, isFetching, pushMessageToList };
}

export default useChatBot;
