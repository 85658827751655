import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import withLayout from '../../hocs/Layout';
import {  Card, CardContent, Grid } from '@mui/material';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { apiConstants,  getGeneric } from '../../common/constants';
import { downloadCSV } from '../../helpers/UtilityHelper';
import PageTitle from '../../common/PageTitle';
import { getToken } from "../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";


const ZoneMapping = () => {
    const [isLoading, setIsLoading] = useState(false)
    const userToken = jwt_decode(getToken());
    const merchantId = userToken && userToken ? userToken : "";


    const onSubmit = async (val) => {
        setIsLoading(true);
        try {
            const data = await getGeneric(`${apiConstants.FETCH_ZONE_MAPPING}?fromPincode=${val.fromPincode}&merchantOid=${merchantId.merchantOid}`)
            const filename = 'zone-mapping.csv';
            downloadCSV(data?.data,filename)
        } catch (e) {
            // enqueueSnackbar(e?.response?.data?.message || e.message, { variant: 'error' })
        }
        setIsLoading(false);
    };

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                fromPincode: Yup.number().typeError("From pincode must be a number").integer("From pincode must be an number").required("From pincode is required"),
            })
        ),
        defaultValues: {
            fromPincode: '',
        }
    })
    const { handleSubmit: handleSubmitForm } = methods;
    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>Zone Mapping</PageTitle>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmitForm(onSubmit)}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={3}>
                                    <RHFTextField
                                        mandatory="true"
                                        name="fromPincode"
                                        label="From Pincode"
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{ml:1}}>
                                    <LoadingButton loading={isLoading} type="submit" size='medium' variant="contained" color="primary">Download Zone Mapping</LoadingButton>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </div>
        </>
    );

}
export default withLayout(ZoneMapping);