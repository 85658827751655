import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { RHFTextField } from '../../common/hook-form';
import {
    Button,
    Grid,
} from "@mui/material";
import RHFSelectField from "../../common/hook-form/RHFSelectField";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";
import { factList, fieldoperatorList, ndrReasonList, ofdCountList, paymentModeList } from "../../helpers/UtilityHelper";
import useGetCourier from "../dashboard/performance/useGetCourier";
import _ from "lodash";

const FormField = ({ watch, control, reset }) => {
    
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });



    return (
        <>
            {fields.map((field, index) => {
                return <Form key={field.id} index={index} watch={watch} fields={fields.length} append={append} remove={remove} reset={reset} />
            })}
        </>
    )
}

export default FormField


const Form = ({ index, watch, fields, append, remove, reset }) => {
    const { data: allCouriers } = useGetCourier()

    const couriers = allCouriers && _.uniqBy(
        allCouriers.filter((val) => val.supported_shipment === "B2C"),
        'parent'
    ).map((val) => ({
        label: val.parent,
        value: val.parent,
    }));

    const getActionOptions = (factValue) => {
        const actionOptionsMap = {
            ofdCount: ofdCountList,
            ndrReason: ndrReasonList,
            paymentMode: paymentModeList,
            courierParentName: couriers
        };
        return actionOptionsMap[factValue] || [];
    };

    const data = watch(`list.${index}.fact`)
    const factData = getActionOptions(data)
    useEffect(() => {
        reset(`list[${index}].fieldValue`)
    }, [data])

    return (
        <>
            <Grid container spacing={2} sx={{ ml: 1 }} key={index}>
                <Grid item xs={3}>
                    <RHFSelectField
                        name={`list[${index}].fact`}
                        label="Fact"
                        width="100%"
                        options={factList}
                    />
                </Grid>
                <Grid item xs={3}>
                    <RHFSelectField
                        name={`list[${index}].operator`}
                        label="Operator"
                        width="100%"
                        options={fieldoperatorList}
                    />
                </Grid>
                {watch(`list[${index}].fact`) === "daysSinceFirstAttempt" ?
                    <Grid item xs={3}>
                        <RHFTextField
                            // name="firstAttempt"
                            name={`list[${index}].firstAttempt`}
                            label="Days since first reattempt"
                            width="100%"
                            color="secondary"
                            variant="filled"
                        />
                    </Grid>
                    :
                    <Grid item xs={3}>
                        <RHFMultipleSelectField
                            name={`list[${index}].fieldValue`}
                            label="Field Value"
                            width="100%"
                            options={factData}
                        />
                    </Grid>
                }
                {fields !== 1 && (
                    <Grid item xs={1.5} sx={{ mt: 1 }}>
                        <Button
                            onClick={() => remove(index)}
                            color="error"
                            variant="contained"
                            size="small"
                        >
                            Remove
                        </Button>
                    </Grid>
                )}
                {fields === index + 1 && (
                    <Grid item xs={1.5} sx={{ mt: 1 }}>
                        <Button
                            onClick={() =>
                                append({
                                    fact: '',
                                    operator: '',
                                    fieldValue: [],
                                })
                            }
                            variant="contained"
                            size="small"
                        >
                            Add
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    )
}