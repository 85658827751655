import React from "react";
import { Box, Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider } from "../../../common/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup"
import { apiConstants, postGeneric } from "../../../common/constants";
import { useNavigate } from "react-router-dom";
import CustomerDetails from "./CustomerDetails"
import PickupLocation from "./PickupLocation"
import OrderDetails from "./OrderDetails";
import PackageDetails from "./PackageDetails";
import { useGetMerchantQuery } from "../../../redux/commonRTK";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import PageTitle from "../../../common/PageTitle";
import { useSnackbar } from "notistack";
import SelectCourier from "./Courier";

const steps = [
    "Customer Details",
    "Pickup Details",
    "Order Details",
    "Package Details",
    "Select Courier",
];

function CreateB2COrder() {
    const merchant = getMerchant();
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();
    const [creatingOrder, setIsCreatingOrder] = React.useState(false);
    const { data } = useGetMerchantQuery({ id: merchant.id });
    const pickupLocations = data?.pickup_details || [];
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        defaultValues: {
            reference: '',
            payment_mode: 'PREPAID',
            order_type: 'Forward Shipment',
            delivery_details: {
                to_phone_number: '',
                to_name: '',
                to_email: '',
                to_pincode: '',
                to_city: '',
                to_state: '',
                to_address: '',
            },
            client_order_id: '',
            invoice_value: '',
            invoice_number: '',
            shipping_charge: '',
            transaction_charge: '',
            giftwrap_charge: '',
            shipment_detail: [{
                item_weight: '',
                item_length: '',
                item_breadth: '',
                item_height: '',
            }],
        },
        resolver: yupResolver(
            Yup.object().shape({
                delivery_details: Yup.object().shape({
                    to_phone_number: Yup.string()
                        .required("Please enter your phone number.")
                        .matches(/^\d{10}$/, "Your phone number must be exactly 10 digits long."),
                    to_name: Yup.string().required("Please enter your name."),
                    // to_email: Yup.string()
                    //     .email("Please enter a valid email address.")
                    //     .required("Please enter your email address."),
                    to_pincode: Yup.string()
                        .required("Please enter your pincode.")
                        .matches(/^\d{6}$/, "Pincode must be exactly 6 digits long."),
                    to_city: Yup.string().required("Please enter your city."),
                    to_state: Yup.string().required("Please enter your state."),
                    to_address: Yup.string().required("Please enter your address."),
                }),
                item_list: Yup.array().of(
                    Yup.object().shape({
                        item_name: Yup.string().required("Please enter the item name."),
                        units: Yup.number()
                            .min(1, "Quantity must be at least 1.")
                            .required("Please enter the quantity."),
                        sku_id: Yup.string().required("Please enter the SKU ID."),
                        selling_price: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Selling price must be at least Rs. 1.")
                            .required("Please enter the selling price."),
                        discount: Yup.number().optional(),
                        tax: Yup.number().optional(),
                        hsn: Yup.string().optional(),
                    })
                ),
                cod_amount: Yup.number().when('payment_mode', {
                    is: val => val === 'COD',
                    then: Yup.number()
                        .transform((value, originalValue) => {
                            const stringValue = String(originalValue).replace(/[\s]+/g, ''); // Remove spaces
                            return stringValue.match(/^[0-9]*$/) ? Number(stringValue) : null;
                        })
                        .typeError("COD amount must be a numeric value.") // Handling type error for non-numeric input
                        .required("COD amount is required when payment mode is COD.")
                        .min(1, "COD amount must be at least Rs. 1.")
                        .max(Yup.ref('invoice_value'), "COD amount cannot be greater than the invoice value.")
                }),
                rvp_reason: Yup.string().when('order_type', {
                    is: val => {
                        return val === 'Reverse Shipment';
                    },
                    then: Yup.string().required("RVP reason is mandatory for Reverse Shipment."),
                }),
                payment_mode: Yup.string().required("Please select a payment mode."),
                reference: Yup.string().required("Please enter a reference number."),
                // client_order_id: Yup.string().required("Please enter the client order ID."),
                pickupLocation: Yup.string().required("Please enter the pickup location."),
                invoice_value: Yup.number()
                    .transform((value, originalValue) => {
                        let stringValue = originalValue != null ? String(originalValue).trim() : "";
                        return stringValue === "" ? null : value;
                    })
                    .nullable()
                    .min(1, "Invoice amount must be at least Rs. 1.")
                    .required("Please enter the invoice value."),
                invoice_number: Yup.string().optional(),
                shipping_charge: Yup.string().optional(),
                transaction_charge: Yup.string().optional(),
                giftwrap_charge: Yup.string().optional(),
                shipment_detail: Yup.array().of(
                    Yup.object().shape({
                        item_weight: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Weight must be at least 1 gram.")
                            .required("Weight is required."),
                        item_length: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Length must be at least 1 cm.")
                            .required("Length is required."),
                        item_breadth: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Breadth must be at least 1 cm.")
                            .required("Breadth is required."),
                        item_height: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Height must be at least 1 cm.")
                            .required("Height is required.")
                    })
                ).required("Please provide shipment details.")
            })
        )
    });

    const { handleSubmit } = methods;

    const onSubmit = (formData) => {
        setIsCreatingOrder(true);
        let request = { ...formData, skipRule: false, dispatch_mode: null };

        const selectedAddress = pickupLocations.find(loc => loc.id === request.pickupLocation);
        const pickup_details = {
            from_address: selectedAddress.address_line,
            from_city: selectedAddress.city,
            from_country: selectedAddress.country,
            from_name: selectedAddress.name,
            from_pincode: selectedAddress.pincode,
            from_state: selectedAddress.state,
            from_phone_number: selectedAddress.contactNumber,
            from_email: selectedAddress.email,
            gstin: selectedAddress.gstin
        }

        if (request.payment_mode === "PREPAID") {
            request.cod_amount = 0;
        }
        request.skipRule = request.courier_type === "Other" ? true : false;
        if (request.courier_type !== "Other") {
            delete request.courierPartner;
        } else {
            request.courier_id = {
                prozoId: request.courierPartner
            };
            delete request.courierPartner;
        }


        request = { ...request, pickup_details };
        request.delivery_details.to_country = 'IN';
        request.is_reverse = false;

        postGeneric(apiConstants.CREATE_ORDER, request)
            .then((response) => {
                enqueueSnackbar("Successfully Created!", { variant: 'success' });
                if (request.order_type === "Forward Shipment") {
                    navigate("/orders");
                } else {
                    navigate("/reverse-orders");
                }
            }).catch((err) => {
                const message = err?.response?.data?.message || err.message;
                enqueueSnackbar(message || "Error creating order", { variant: "error" });
            }).finally(() => {
                setIsCreatingOrder(false)
            })
    }

    return (
        <div id="main-content" className="container-fluid">
            <PageTitle>Create Forward Shipment </PageTitle>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={2}>
                    <Stepper orientation="vertical" activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Box sx={{ p: 5 }} component={Paper} elevation={1}>
                        <Typography sx={{ mb: 3 }} variant="h6" >{activeStep + 1}. {steps[activeStep]}</Typography>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            {(activeStep === 0) && (
                                <CustomerDetails setActiveStep={setActiveStep} />
                            )}
                            {(activeStep === 1) && (
                                <PickupLocation setActiveStep={setActiveStep} />
                            )}
                            {(activeStep === 2) && (
                                <OrderDetails setActiveStep={setActiveStep} />
                            )}
                            {(activeStep === 3) && (
                                <PackageDetails creatingOrder={creatingOrder} setActiveStep={setActiveStep} />
                            )}
                            {(activeStep === 4) && (
                                <SelectCourier creatingOrder={creatingOrder}
                                    setActiveStep={setActiveStep}
                                    formData={methods.getValues()}
                                    pickupLocations={pickupLocations}
                                />
                            )}
                        </FormProvider>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateB2COrder;