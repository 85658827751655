
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, Card, CardContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField } from '../../../common/hook-form';
import { apiConstants, patch } from '../../../common/constants';


const EditCustomerDetails = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { handleClose, formLoading, shippingAddress, refetch } = props;

    const method = useForm({
        defaultValues: {
            email: '',
            address1: '',
            address2: '',
            phone: '',
            city: '',
            zip: '',
            state: '',
            province: '',
            additional_phone: '',
            first_name: '',
            last_name: '',
        },
    })
    const {
        handleSubmit,
        reset,
    } = method;

    const onSubmit = (data) => {
        let payload = {}
        if (data.hasOwnProperty('zip') && data.zip.trim() !== '') {
            payload.zip = data.zip;
        }
        if (data.hasOwnProperty('email') && data.email.trim() !== '') {
            payload.email = data.email;
        }
        if (data.hasOwnProperty('address1') && data.address1.trim() !== '') {
            payload.address1 = data.address1;
        }
        if (data.hasOwnProperty('address2') && data.address2.trim() !== '') {
            payload.address2 = data.address2;
        }
        if (data.hasOwnProperty('province') && data.province.trim() !== '') {
            payload.province = data.province;
        }
        if (data.hasOwnProperty('state') && data.state.trim() !== '') {
            payload.state = data.state;
        }
        if (data.hasOwnProperty('phone') && data.phone.trim() !== '') {
            payload.phone = data.phone;
        }
        if (data.hasOwnProperty('city') && data.city.trim() !== '') {
            payload.city = data.city;
        }
        if (data.hasOwnProperty('additional_phone') && data.additional_phone.trim() !== '') {
            payload.additional_phone = data.additional_phone;
        }
        if (data.hasOwnProperty('first_name') && data.first_name.trim() !== '') {
            payload.first_name = data.first_name;
        }
        if (data.hasOwnProperty('last_name') && data.last_name.trim() !== '') {
            payload.last_name = data.last_name;
        }
        patch(`${apiConstants.BASE_LOCAL}${apiConstants.UPDATE_CUSTOMER_DETAILS}?channelOrderId=${shippingAddress?.channelOrderId}`, payload)
            .then((response) => {
                if (response.status === 200 && response.data?.status === "SUCCESS") {
                    enqueueSnackbar("Success", { variant: 'success' })
                } else {
                    enqueueSnackbar("Some Error Occurred", { variant: 'error' })
                }
                refetch()
                handleClose()
            }).catch((_err) => {
                console.log(_err)
                enqueueSnackbar(_err.response?.data?.message, { variant: 'error' })
            })
    };

    useEffect(() => {
        if (shippingAddress.shipping_address) {
            let defaultValues = {
                first_name: shippingAddress.shipping_address?.first_name ? shippingAddress.shipping_address?.first_name : '',
                last_name: shippingAddress.shipping_address?.last_name ? shippingAddress.shipping_address?.last_name : '',
                email: shippingAddress.shipping_address?.email ? shippingAddress.shipping_address?.email : '',
                address1: shippingAddress.shipping_address?.address1 ? shippingAddress.shipping_address?.address1 : '',
                address2: shippingAddress.shipping_address?.address2 ? shippingAddress.shipping_address?.address2 : '',
                phone: shippingAddress.shipping_address?.phone ? shippingAddress.shipping_address?.phone : '',
                city: shippingAddress.shipping_address?.city ? shippingAddress.shipping_address?.city : '',
                zip: shippingAddress.shipping_address?.zip ? shippingAddress.shipping_address?.zip : '',
                state: shippingAddress.shipping_address?.state ? shippingAddress.shipping_address?.state : '',
                province: shippingAddress.shipping_address?.province ? shippingAddress.shipping_address?.province : '',
                additional_phone: shippingAddress.shipping_address?.additional_phone ? shippingAddress.shipping_address?.additional_phone : '',
            };
            reset({ ...defaultValues });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingAddress]);

    return (
        <Card>
            <CardContent>
                <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="First Name" name="first_name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Last Name" name="last_name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Email" name="email" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Address1" name="address1" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Address2" name="address2" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Phone" name="phone" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Alternate Phone" name="additional_phone" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="City" name="city" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Zip" name="zip" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="State" name="state" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Province" name="province" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mt: 4 }}>
                        <Button type="submit" variant="contained" color="primary" disabled={formLoading}>
                            Save {formLoading && <span className="spinner-border spinner-border-sm"></span>}</Button>
                        <Button type="button" variant="outlined" color="primary" onClick={handleClose} sx={{ ml: 1 }}>Cancel</Button>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    );
};

export default EditCustomerDetails;
