import React from "react";
import { apiConstants } from "../../../common/constants";
import { formatDate } from "../../../helpers/UtilityHelper";
import { useGenericPostRequestQuery } from "../../../redux/commonRTK";
import _ from 'lodash'
import WhatsaAppContext from "./Context";
import getRequestFilter, { NON_NDR_TEMPLATE_KEY } from "./filter";

export default function useStatus() {
    const { dateRange } = React.useContext(WhatsaAppContext)
    const requestPayload = getRequestFilter(dateRange, NON_NDR_TEMPLATE_KEY)

    const { data: responseData, isLoading, isFetching } = useGenericPostRequestQuery({ url: apiConstants.DELIVERY_DB_SEARCH, data: requestPayload, type: 'POST' })

    if (isLoading || isFetching) return { isLoading: true }

    let { data, count } = responseData
    data = _.orderBy(data, 'created_date', 'desc')
    const result = { isLoading: false, totalSent: count || 0 }

    const groupedByStatus = _(data).groupBy('current_status').value()
    const totalDelivered = groupedByStatus?.delivered?.length || 0
    const totalRead = groupedByStatus?.read?.length || 0
    const totalFailed = groupedByStatus?.undefined?.length || 0

    const groupedByTemplateDate = _(data)
        .groupBy(function (item) {
            return formatDate(item.created_date, 'dd MMM, yyyy')
        }).map(function (items, key) {
            const row = { name: key }

            const groupedByTemplate = _(items)
                .groupBy('template_name')
                .transform(function (result, items, template) {
                    result[template] = items.length
                }).value()

            return { ...row, ...groupedByTemplate };

        }).value()

    return { ...result, groupedByTemplateDate, totalRead, totalDelivered, totalFailed, data };
}