import React from 'react';
import { Grid, Tab } from '@mui/material'
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import withLayout from "../../../hocs/Layout";
import OverallSummary from './overall/OverallSummary';
import ForwardDeliveries from './forward_deliveries/ForwardDeliveries';
import PickupPerformance from './pickup/PickupPerformance';
import RtoSummary from "./rto/RtoSummary"
import NDRSummary from "./ndr/NDRSummary"
import Lost from "./lost/PerformanceLost"
import RVP from './rvp/RVP'
import Stuck from './stuck/Stuck'
import DateSelector, { DateRangeContextProvider } from '../../../common/contexts/DateSelector';
import GenericFilters, { FiltersContextProvider } from "../../../common/contexts/Filters";
import PageTitle from '../../../common/PageTitle';

function Performance() {
    const [value, setValue] = React.useState('1');

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <div id="main-content" className="container-fluid">
            <PageTitle>Performance Dashboard</PageTitle>
            <DateRangeContextProvider>
                <FiltersContextProvider>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ background: '#fff', mb: 1, boxShadow: 1, borderRadius: 1 }}>
                                <Box sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={9.5}>
                                            <GenericFilters />
                                        </Grid>
                                        {(value !== "8") && (
                                            <Grid item xs={2.5}>
                                                <DateSelector />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                                <TabList onChange={handleChange} variant='scrollable'>
                                    <Tab label="Overall Summary" value="1" />
                                    <Tab label="Forward Deliveries" value="2" />
                                    <Tab label="Pickup Performance" value="3" />
                                    <Tab label="RTO" value="4" />
                                    <Tab label="NDR Analysis" value="5" />
                                    <Tab label="Lost & Damaged" value="6" />
                                    <Tab label="RVP" value="7" />
                                    <Tab label="Stuck shipments" value="8" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <OverallSummary />
                            </TabPanel>
                            <TabPanel value="2" sx={{ p: 0 }}>
                                <ForwardDeliveries />
                            </TabPanel>
                            <TabPanel value="3" sx={{ p: 0 }}>
                                <PickupPerformance />
                            </TabPanel>
                            <TabPanel value="4" sx={{ p: 0 }}>
                                <RtoSummary />
                            </TabPanel>
                            <TabPanel value="5" sx={{ p: 0 }}>
                                <NDRSummary />
                            </TabPanel>
                            <TabPanel value="6" sx={{ p: 0 }}>
                                <Lost />
                            </TabPanel>
                            <TabPanel value="7" sx={{ p: 0 }}>
                                <RVP />
                            </TabPanel>
                            <TabPanel value="8" sx={{ p: 0 }}>
                                <Stuck />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </FiltersContextProvider>
            </DateRangeContextProvider>
        </div>
    )
}

export default withLayout(Performance);