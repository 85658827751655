import React, { useEffect, useState } from 'react';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Button, Chip, Grid } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { parseDateAndTime } from '../../helpers/UtilityHelper';
import Loader from '../../common/Loader';
import { useGetNDRDataQuery } from '../../redux/commonRTK';
import { buildFilterConditions } from '../../helpers/UtilityHelper';
import ConfirmDialog from '../../common/ConfirmDialog';
import { apiConstants, post } from '../../common/constants';
import { useSnackbar } from "notistack";
import BulkOrderAction from "./bulkOrderAction";
import NdrAction from "./action";
import { Link } from 'react-router-dom';
import PageTitle from '../../common/PageTitle';

const PendingNdrData = (props) => {

    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [pendingNdrData, setPendingNDRData] = useState({});
    const [rtoConfirm, setRtoConfirm] = React.useState(false);
    const [id, setId] = useState('');
    const { enqueueSnackbar } = useSnackbar()
    const [currentNDRId, setCurrentNDRId] = useState({});
    const [currentNDRAction, setCurrentNDRAction] = useState();
    const [actionShow, setActionShow] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);
    const [bulkOrderShow, setBulkOrderShow] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [reload, setReload] = useState(0)
    const exclude =
        "shipmentDetail.cartonItems,invoiceNumber,shipmentType,returnInfo,labelUrl,dispatchMode,giftwrapCharge,transactionCharge,shippingCharge,pickupDetails,isInsured,codType,riskType,appointmentDelivery,async,order_date,courierDetail.prozoId,courierDetail.key,createdDate,actualPickUpTime,lastStatusUpdateTime,epd,edd,order_history,merchantPricing,merchant,courier,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,itemList,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,modifiedByUser,lastModifiedDate";
    const filterConditions = buildFilterConditions(props.filters);
    const { data: pendingData, isLoading, refetch } = useGetNDRDataQuery({
        andfilter: [
            {
                field: "orderStatus",
                operator: "in",
                value: ["FAILED_DELIVERY", "SHIPMENT_HELD"],
            },
            {
                field: "ndrData.ndrStatus",
                operator: "nin",
                value: ["INITIATED", "CLOSED"],
            },
            ...filterConditions.andfilter
        ],
        orfilter: [...filterConditions.orfilter],
        offset: page * sizePerPage,
        limit: sizePerPage,
        skipCount: true,
        excludes: exclude,
    })

    // useEffect(() => {
    //     refetch()
    // }, [reload, refetch])

    useEffect(() => {
        setPage(0)
    }, [sizePerPage, props.filters])

    useEffect(() => {
        if (reload !== 0) {
            refetch();
        }
    }, [reload, refetch]);


    const columns = [
        {
            field: 'orderdetails', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.reference}
                        </Grid>
                        {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                            <Grid item xs={12}>
                                {row.shippingLabelId}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {(row.awbRegisteredDate) ? parseDateAndTime(row.awbRegisteredDate) : ""}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'courierDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.awb_number}`}>
                                    {row.awb_number}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.name}<br />
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'status', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                const orderStatus = row.orderStatus.replace(/_/g, ' ');
                return (<Chip size="small" label={orderStatus} />)

            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Address', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container >
                            <Grid item xs={12}>
                                Name : {row.delivery_details.to_name}
                            </Grid>
                            <Grid item xs={12}>
                                Phone : {row.delivery_details.to_phone_number}
                            </Grid>
                            <Grid item xs={12}>
                                {row.delivery_details.to_address},{row.delivery_details.to_city},{row.delivery_details.to_state},{row.delivery_details.to_pincode}
                            </Grid>
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'ndrReason', headerAlign: "center", hideSortIcons: true, headerName: 'NDR Reason', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.ndrCpStatusDescription) ? row.ndrData.ndrCpStatusDescription : "-NA-"}

                            </Grid>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.ndrReportedDate) ? parseDateAndTime(row.ndrData.ndrReportedDate, true) : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'ndrcomments', headerAlign: "center", hideSortIcons: true, headerName: 'NDR Comments', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.remark) ? row.ndrData.remark : "-"}

                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'ofdAttemptCount', headerAlign: "center", hideSortIcons: true, headerName: 'OFD Attempt Count', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {(row.ndrData && row.ndrData.ofdCount) ? `${row.ndrData.ofdCount} attempt` : "0 attempts"}
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" color="primary" onClick={() => handleActionShow(row, "reattempt")}>Reattempt</Button>
                        </Grid>
                        {/* <Grid item xs={12}>
                                <Button variant="contained" size="small" color="warning" onClick={() => props.handleReturn(row.awb_number)}>RTO</Button>
                            </Grid> */}
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" color="error" onClick={() => handleClickRTO(row.awb_number)}>RTO</Button>
                        </Grid>

                    </Grid>
                )
            },
        },
    ];

    useEffect(() => {
        if (pendingData) {
            setPendingNDRData(pendingData);
        }
    }, [pendingData]);


    const handleActionShow = (row, type) => {
        setCurrentNDRId(row);
        setCurrentNDRAction(type);
        setActionShow(true);
    };

    const handleActionClose = () => {
        setActionShow(false);
        setBulkOrderShow(false);
    };

    const handleClickRTO = (row) => {
        setRtoConfirm(true);
        setId(row);
    };

    const handleReattempt = (data) => {
        const payload = Object.assign(
            {},
            {
                waybill: `${currentNDRId.awb_number}`,
                action: "REATTEMPT",
            },
            data
        );
        const param = {
            ndr_waybills_data: [payload],
        };
        sendNDRAction(param);
    };

    const handleReturn = (id) => {
        const payload = Object.assign(
            {},
            {
                waybill: `${id}`,
                action: "INITIATE_RTO",
            }
        );
        const param = {
            ndr_waybills_data: [payload],
        };
        sendNDRAction(param);
    };

    const sendNDRAction = (param) => {
        setIsLoadingBtn(true)
        setIsExecuting(true);
        post(`${apiConstants.BASE_LOCAL}${apiConstants.NDR_ACTION}`, param)
            .then((response) => {
                setIsLoadingBtn(false)
                setIsExecuting(false);
                setCurrentNDRId({});
                setCurrentNDRAction();
                setActionShow(false);
                setReload(reload => reload + 1)
                if (response?.data?.result?.ndr_update_status[0]?.status === true && response.status === 200) {
                    enqueueSnackbar('Action performed successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(response?.data?.result?.ndr_update_status[0]?.cp_response, { variant: 'error' });
                }
            })
            .catch((err) => {
                setIsLoadingBtn(false)
                const message = err?.response?.data?.message || err.message;
                if (message === 'Target System Error') {
                    enqueueSnackbar("Please wait a few minutes before you try again", { variant: 'error' });
                }
                else {
                    enqueueSnackbar(err?.response?.data?.message || err.message, { variant: 'error' });
                }

                setIsExecuting(false);
            });
    };

    return (
        <>
            <PageTitle>NDR Management</PageTitle>
            <Grid item container spacing={2}>
                {(isLoading || isExecuting) && <Loader />}
                {/* <Grid item xs={12}>
                    <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        onClick={() => setBulkOrderShow(true)}
                    >
                        Bulk Upload Sample
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ ml: 1 }} variant='body' color={'error'}>In case of RTO : We will only take RTO action. Other details i.e
                        Address, Preferred Date, Contact number will not be considered.
                    </Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <ProzoDataGrid columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(pendingNdrData.data && pendingNdrData.data.length > 0) ? pendingNdrData.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={180}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id} />
                </Grid>
                <ConfirmDialog
                    title=''
                    open={rtoConfirm}
                    setOpen={setRtoConfirm}
                    onConfirm={() => handleReturn(id)}>
                    Are you sure you want request for RTO?
                </ConfirmDialog>
                <NdrAction
                    show={actionShow}
                    handleClose={handleActionClose}
                    currentNDRId={currentNDRId}
                    currentNDRAction={currentNDRAction}
                    handleReattemp={handleReattempt}
                    setReload={setReload}
                    isLoadingBtn={isLoadingBtn}
                />
                <BulkOrderAction
                    show={bulkOrderShow}
                    handleClose={handleActionClose}
                    currentNDRId={currentNDRId}
                    currentNDRAction={currentNDRAction}
                    handleReattemp={handleReattempt}
                    pendingNdrData={pendingNdrData}
                    setReload={setReload}
                />
            </Grid>
        </>
    );
};

export default PendingNdrData

