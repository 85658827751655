// import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
// import { RHFRadioGroup, RHFTextField } from "../../../common/hook-form";
// import React from "react";
// import { useFieldArray, useFormContext } from "react-hook-form";
// import { AddCircleTwoTone, RemoveCircleTwoTone } from "@mui/icons-material";
// import { LoadingButton } from "@mui/lab";
// import { getMerchant } from "../../../helpers/ReduxHelpers";
// import { apiConstants, postGeneric } from "../../../common/constants";

// const emptyItemObject = {
//     item_name: '',
//     units: 0,
//     sku_id: '',
//     selling_price: '',
//     discount: 0,
//     tax: 0,
//     hsn: ''
// };

// function OrderDetailsRVP({ setActiveStep, response }) {
//     const [addAdditionalInfo, setAddAdditionalInfo] = React.useState(false);
//     const { trigger, control, watch, setValue, getValues } = useFormContext();
//     const paymentMode = watch('payment_mode');
//     const shipmentType = 'Reverse Shipment'


//     const { append, remove, fields } = useFieldArray({
//         name: 'item_list',
//         control: control,
//     })

//     const onNextHandler = async () => {
//         const status = await trigger(['item_list', 'cod_amount', 'reference', 'payment_mode', 'client_order_id', 'invoice_value', 'rvp_reason']);
//         if (status) {
//             setActiveStep(3)
//         }
//     }


//     React.useEffect(() => {
//         if (fields.length === 0) {
//             append(emptyItemObject)
//         }

//         // eslint-disable-next-line
//     }, [fields])

//     const onKeyPressHandler = (e) => {
//         if (e.charCode === 13) {
//             onNextHandler();
//         }
//     }

//     const generateRefNumber = () => {
//         setValue('reference', 'PROSHIP_GENERATED_REFERENCE')
//     };

//     React.useEffect(() => {
//         if (response) {
//             setValue('client_order_id', response?.client_order_id);
//             setValue('invoice_value', response?.invoice_value);
//             // setValue('reference', response?.reference);
//             setValue('reference', `rvp_${response?.reference}`);
//             setValue('payment_mode', response?.payment_mode);
//             setValue('rvp_reason', response?.rvp_reason);
//             response?.item_list?.forEach((item, index) => {
//                 const itemNameFieldName = `item_list.${index}.item_name`;
//                 setValue(itemNameFieldName, item.item_name);
//                 const unitsFieldName = `item_list.${index}.units`;
//                 setValue(unitsFieldName, item.units);
//                 const sellingPriceFieldName = `item_list.${index}.selling_price`;
//                 setValue(sellingPriceFieldName, item.selling_price);
//                 const skuIdFieldName = `item_list.${index}.sku_id`;
//                 setValue(skuIdFieldName, item.sku_id);
//                 const discountFieldName = `item_list.${index}.discount`;
//                 setValue(discountFieldName, item.discount);
//                 const taxFieldName = `item_list.${index}.tax`;
//                 setValue(taxFieldName, item.tax);
//                 const hsnFieldName = `item_list.${index}.hsn`;
//                 setValue(hsnFieldName, item.hsn);
//             });
//         }
//     }, [response, setValue]);


//     const totalFields = fields?.length || 0;

//     return (
//         <Grid container spacing={2}>

//             <Grid item xs={12} md={6}>
//                 <RHFRadioGroup label="Payment Mode" name="payment_mode" options={[
//                     { label: "PREPAID", value: "PREPAID" },
//                     { label: "COD", value: "COD" },
//                 ]} />
//             </Grid>
//             {(shipmentType === 'Reverse Shipment') && (
//                 <Grid item xs={12} md={12}>
//                     <RHFTextField variant="filled" name="rvp_reason" label="RVP Reason" placeholder="Reason for RVP, mandatory for some courier partners." required />
//                 </Grid>
//             )}

//             {(paymentMode === 'COD') && (
//                 <Grid item xs={12} md={3}>
//                     <RHFTextField variant="filled" name="cod_amount" label="COD Amount" placeholder="Amount to be collect" required />
//                 </Grid>
//             )}

//             <Grid item xs={12} md={3}>
//                 <RHFTextField
//                     variant="filled"
//                     name="reference"
//                     label="Reference"
//                     placeholder="Uniq Reference Number"
//                     required
//                     InputProps={{
//                         endAdornment: (
//                             <Button size="small" onClick={generateRefNumber}>Generate</Button>
//                         )
//                     }}
//                 />
//             </Grid>

//             <Grid item xs={12} md={3}>
//                 <RHFTextField
//                     variant="filled"
//                     name="client_order_id"
//                     label="Client Order ID"
//                     placeholder="Enter client order ID"
//                     required
//                 />
//             </Grid>

//             <Grid item xs={12} md={3}>
//                 <RHFTextField
//                     variant="filled"
//                     name="invoice_value"
//                     label="Invoice Value"
//                     placeholder="Total invoice amount"
//                     type="number"
//                     required
//                 />
//             </Grid>

//             {fields.map((field, index) => (
//                 <Grid item xs={12} key={field.id}>
//                     <FieldGroup index={index} getValues={getValues} setValue={setValue} remove={remove} append={append} onKeyPressHandler={onKeyPressHandler} totalFields={totalFields} response={response} />
//                 </Grid>
//             ))}

//             <Grid item xs={12}>
//                 <Button
//                     onClick={() => setAddAdditionalInfo(!addAdditionalInfo)}
//                     startIcon={<AddCircleTwoTone fontSize="small" />}
//                     sx={{ textTransform: 'none', letterSpacing: 0, fontWeight: 400, fontSize: 12 }}
//                 >
//                     Add Shipping Charges, Giftwrap, Transaction fee & Invoice Number
//                 </Button>
//             </Grid>

//             {addAdditionalInfo && (
//                 <React.Fragment>
//                     <Grid item xs={12} md={6}>
//                         <RHFTextField variant="filled" name="invoice_number" label="Invoice Number" />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <RHFTextField variant="filled" name="shipping_charge" type="number" label="Shipping Charge" />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <RHFTextField variant="filled" name="transaction_charge" type="number" label="Transaction Charge" />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <RHFTextField variant="filled" name="giftwrap_charge" type="number" label="Gift Wrap Charge" />
//                     </Grid>
//                 </React.Fragment>
//             )}

//             <Grid item xs={12}>
//                 <Stack direction="row" columnGap={1} sx={{ mt: 3 }}>
//                     <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(1)}>Back</Button>
//                     <Button variant="contained" size="small" onClick={onNextHandler}>Next</Button>
//                 </Stack>
//             </Grid>
//         </Grid>
//     )
// }

// function FieldGroup({ index, onKeyPressHandler, totalFields, remove, append, getValues, setValue, response }) {
//     const keyName = `item_list.${index}`;
//     const merchant = getMerchant();
//     const [isSearching, setIsSearching] = React.useState(false)
//     const [addTaxRates, setAddTaxRates] = React.useState(false)

//     const fetchSKUDetails = async (skuId) => {
//         const requestBody = {
//             "collection": "product_master",
//             "offset": 0,
//             "limit": 20,
//             "skipCount": true,
//             "countOnly": false,
//             "sort": {
//                 "field": "createdDate",
//                 "direction": "DESC"
//             },
//             "andfilter": [
//                 {
//                     "field": "merchantId",
//                     "operator": "eq",
//                     "value": merchant.id
//                 },
//                 {
//                     "field": "skuCode",
//                     "operator": "eq",
//                     "value": skuId
//                 }
//             ]
//         };

//         setIsSearching(true);
//         return postGeneric(apiConstants.REPORT_GENERIC_SEARCH, requestBody)
//             .then((res) => {
//                 setIsSearching(false);
//                 return res?.data?.data;
//             })
//             .catch((err) => {
//                 console.log(err);
//                 throw err;
//             });
//     };

//     const handleSearchClick = async (index) => {
//         const skuId = getValues(`${keyName}.sku_id`);
//         if (skuId && skuId !== '') {
//             const skuDetails = await fetchSKUDetails(skuId);
//             if (skuDetails?.length > 0) {
//                 const itemDetails = skuDetails[0]; // Assuming single result for simplicity
//                 setValue(`${keyName}.item_name`, itemDetails.name);
//                 setValue(`${keyName}.units`, 1);
//                 setValue(`${keyName}.selling_price`, itemDetails.mrp);
//                 setValue(`${keyName}.discount`, 0);
//                 setValue(`${keyName}.tax`, 0);
//                 setValue(`${keyName}.hsn`, itemDetails.hsnCode);
//             }
//         }
//     };

//     React.useEffect(() => {
//         if (response?.item_list && response.item_list[index]) {
//             const item = response.item_list[index];
//             setValue(`${keyName}.item_name`, item.item_name);
//             setValue(`${keyName}.units`, item.units);
//             setValue(`${keyName}.selling_price`, item.selling_price);
//             setValue(`${keyName}.sku_id`, item.sku_id);
//             setValue(`${keyName}.discount`, item.discount);
//             setValue(`${keyName}.tax`, item.tax);
//             setValue(`${keyName}.hsn`, item.hsn);
//         }
//     }, [response, index, keyName, setValue]);


//     return (
//         <Box sx={{ backgroundColor: '#3783e71a', borderRadius: 1, p: 2, border: '1px solid #ccc' }}>
//             <Grid container spacing={1} sx={{ alignItems: 'center' }}>
//                 <Grid item xs={12} md={3}>
//                     <RHFTextField
//                         onKeyPress={onKeyPressHandler}
//                         name={`${keyName}.sku_id`}
//                         label="SKU ID"
//                         required variant="filled"
//                         autoFocus={true}
//                         placeholder="Search for item details"
//                         InputProps={{
//                             endAdornment: (
//                                 <LoadingButton size="small" loading={isSearching} onClick={() => handleSearchClick(index)}>Search</LoadingButton>
//                             )
//                         }}
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={3}>
//                     <RHFTextField
//                         onKeyPress={onKeyPressHandler}
//                         name={`${keyName}.item_name`}
//                         label="Name"
//                         placeholder="Product name"
//                         required
//                         variant="filled"
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={2}>
//                     <RHFTextField
//                         onKeyPress={onKeyPressHandler}
//                         name={`${keyName}.units`}
//                         label="Quantity"
//                         type="number"
//                         required
//                         variant="filled"
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={2}>
//                     <RHFTextField
//                         onKeyPress={onKeyPressHandler}
//                         name={`${keyName}.selling_price`}
//                         label="Unit Price"
//                         placeholder="Selling Price per unit including discount and taxes"
//                         type="number"
//                         required
//                         variant="filled"
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={2}>
//                     {((index + 1) === totalFields) && (
//                         <IconButton size="small" color="primary" onClick={() => append(emptyItemObject)}>
//                             <AddCircleTwoTone />
//                         </IconButton>
//                     )}
//                     {(totalFields !== 1) && (
//                         <IconButton size="small" color="error" onClick={() => remove(index)}>
//                             <RemoveCircleTwoTone />
//                         </IconButton>
//                     )}
//                 </Grid>
//                 <Grid item xs={12}>
//                     <Button
//                         onClick={() => setAddTaxRates(!addTaxRates)}
//                         startIcon={<AddCircleTwoTone fontSize="small" />}
//                         sx={{ textTransform: 'none', letterSpacing: 0, fontWeight: 400, fontSize: 12 }}
//                     >
//                         Add HSN Code, Discount and Tax Rates
//                     </Button>
//                 </Grid>
//                 {addTaxRates && (
//                     <React.Fragment>
//                         <Grid item xs={12} md={3}>
//                             <RHFTextField
//                                 onKeyPress={onKeyPressHandler}
//                                 name={`${keyName}.discount`}
//                                 label="Discount Amount (Rs.)"
//                                 placeholder="Given discount "
//                                 type="number"
//                                 variant="filled"
//                             />
//                         </Grid>
//                         <Grid item xs={12} md={3}>
//                             <RHFTextField
//                                 onKeyPress={onKeyPressHandler}
//                                 name={`${keyName}.tax`}
//                                 label="Tax"
//                                 placeholder="Tax % "
//                                 type="number"
//                                 variant="filled"
//                             />
//                         </Grid>
//                         <Grid item xs={12} md={3}>
//                             <RHFTextField
//                                 onKeyPress={onKeyPressHandler}
//                                 name={`${keyName}.hsn`}
//                                 label="HSN"
//                                 placeholder="HSN Code"
//                                 type="number"
//                                 variant="filled"
//                             />
//                         </Grid>
//                     </React.Fragment>
//                 )}
//             </Grid>
//         </Box>
//     )
// }

// export default OrderDetailsRVP;


import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import { RHFRadioGroup, RHFTextField } from "../../../common/hook-form";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddCircleTwoTone, RemoveCircleTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import { apiConstants, postGeneric } from "../../../common/constants";

const emptyItemObject = {
    item_name: '',
    units: 0,
    sku_id: '',
    selling_price: '',
    discount: 0,
    tax: 0,
    hsn: ''
};

function OrderDetailsRVP({ setActiveStep, response,reset }) {
    const [addAdditionalInfo, setAddAdditionalInfo] = React.useState(false);
    const { trigger, control, watch, setValue, getValues } = useFormContext();
    const paymentMode = watch('payment_mode');
    const shipmentType = 'Reverse Shipment';

    const { append, remove, fields } = useFieldArray({
        name: 'item_list',
        control,
    });

    const onNextHandler = async () => {
        const status = await trigger(['item_list', 'cod_amount', 'reference', 'payment_mode', 'client_order_id', 'invoice_value', 'rvp_reason']);
        if (status) {
            setActiveStep(3);
        }
    };

    // React.useEffect(() => {
    //     if (fields.length === 0 && response) {
    //         setValue('client_order_id', response.client_order_id);
    //         setValue('invoice_value', response.invoice_value);
    //         setValue('reference', `rvp_${response.reference}`);
    //         setValue('payment_mode', response.payment_mode);
    //         setValue('rvp_reason', response.rvp_reason);
    
    //         response.item_list.forEach((item) => {
    //             append(item);
    //         });
    //     }
    
    //     // eslint-disable-next-line
    // }, [response]);

    React.useEffect(() => {
        if (fields.length === 0) {
            if (response) {
                setValue('client_order_id', response.client_order_id);
                setValue('invoice_value', response.invoice_value);
                setValue('reference', `rvp_${response.reference}`);
                setValue('payment_mode', response.payment_mode);
                setValue('rvp_reason', response.rvp_reason);

                response.item_list.forEach((item) => {
                    append(item);
                });
            } else {
                append(emptyItemObject); // Initialize with an empty item field
            }
        }
        // eslint-disable-next-line
    }, [response]);

    
    
    const onKeyPressHandler = (e) => {
        if (e.charCode === 13) {
            onNextHandler();
        }
    };

    const generateRefNumber = () => {
        setValue('reference', 'PROSHIP_GENERATED_REFERENCE');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <RHFRadioGroup
                    label="Payment Mode"
                    name="payment_mode"
                    options={[
                        { label: "PREPAID", value: "PREPAID" },
                        { label: "COD", value: "COD" },
                    ]}
                />
            </Grid>
            {shipmentType === 'Reverse Shipment' && (
                <Grid item xs={12} md={12}>
                    <RHFTextField
                        variant="filled"
                        name="rvp_reason"
                        label="RVP Reason"
                        placeholder="Reason for RVP, mandatory for some courier partners."
                        required
                    />
                </Grid>
            )}
            {paymentMode === 'COD' && (
                <Grid item xs={12} md={3}>
                    <RHFTextField
                        variant="filled"
                        name="cod_amount"
                        label="COD Amount"
                        placeholder="Amount to be collect"
                        required
                    />
                </Grid>
            )}
            <Grid item xs={12} md={3}>
                <RHFTextField
                    variant="filled"
                    name="reference"
                    label="Reference"
                    placeholder="Uniq Reference Number"
                    required
                    InputProps={{
                        endAdornment: (
                            <Button size="small" onClick={generateRefNumber}>
                                Generate
                            </Button>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <RHFTextField
                    variant="filled"
                    name="client_order_id"
                    label="Client Order ID"
                    placeholder="Enter client order ID"
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <RHFTextField
                    variant="filled"
                    name="invoice_value"
                    label="Invoice Value"
                    placeholder="Total invoice amount"
                    type="number"
                    required
                />
            </Grid>

            {fields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                    <FieldGroup
                        index={index}
                        onKeyPressHandler={onKeyPressHandler}
                        remove={remove}
                        append={append}
                        getValues={getValues}
                        setValue={setValue}
                        fields={fields}
                    />
                </Grid>
            ))}

            <Grid item xs={12}>
                <Button
                    onClick={() => setAddAdditionalInfo(!addAdditionalInfo)}
                    startIcon={<AddCircleTwoTone fontSize="small" />}
                    sx={{ textTransform: 'none', letterSpacing: 0, fontWeight: 400, fontSize: 12 }}
                >
                    Add Shipping Charges, Giftwrap, Transaction fee & Invoice Number
                </Button>
            </Grid>

            {addAdditionalInfo && (
                <>
                    <Grid item xs={12} md={6}>
                        <RHFTextField variant="filled" name="invoice_number" label="Invoice Number" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField variant="filled" name="shipping_charge" type="number" label="Shipping Charge" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField variant="filled" name="transaction_charge" type="number" label="Transaction Charge" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFTextField variant="filled" name="giftwrap_charge" type="number" label="Gift Wrap Charge" />
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <Stack direction="row" columnGap={1} sx={{ mt: 3 }}>
                    <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(1)}>
                        Back
                    </Button>
                    <Button variant="contained" size="small" onClick={onNextHandler}>
                        Next
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}

function FieldGroup({ index, onKeyPressHandler, remove, append, getValues, setValue, fields }) {
    const { control } = useFormContext();
    const keyName = `item_list.${index}`;
    const merchant = getMerchant();
    const [isSearching, setIsSearching] = React.useState(false);
    const [addTaxRates, setAddTaxRates] = React.useState(false);

    const fetchSKUDetails = async (skuId) => {
        const requestBody = {
            collection: "product_master",
            offset: 0,
            limit: 20,
            skipCount: true,
            countOnly: false,
            sort: { field: "createdDate", direction: "DESC" },
            andfilter: [
                { field: "merchantId", operator: "eq", value: merchant.id },
                { field: "skuCode", operator: "eq", value: skuId },
            ],
        };

        setIsSearching(true);
        return postGeneric(apiConstants.REPORT_GENERIC_SEARCH, requestBody)
            .then((res) => {
                setIsSearching(false);
                return res?.data?.data;
            })
            .catch((err) => {
                console.log(err);
                setIsSearching(false);
                throw err;
            });
    };

    const handleSearchClick = async () => {
        const skuId = getValues(`${keyName}.sku_id`);
        if (skuId) {
            const skuDetails = await fetchSKUDetails(skuId);
            if (skuDetails?.length > 0) {
                const itemDetails = skuDetails[0];
                setValue(`${keyName}.item_name`, itemDetails.name);
                setValue(`${keyName}.units`, 1);
                setValue(`${keyName}.selling_price`, itemDetails.mrp);
                setValue(`${keyName}.discount`, 0);
                setValue(`${keyName}.tax`, 0);
                setValue(`${keyName}.hsn`, itemDetails.hsnCode);
            }
        }
    };

    return (
        <Box sx={{ backgroundColor: '#3783e71a', borderRadius: 1, p: 2, border: '1px solid #ccc' }}>
            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={3}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.sku_id`}
                        label="SKU ID"
                        required
                        variant="filled"
                        autoFocus
                        placeholder="Search for item details"
                        InputProps={{
                            endAdornment: (
                                <LoadingButton size="small" loading={isSearching} onClick={handleSearchClick}>
                                    Search
                                </LoadingButton>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.item_name`}
                        label="Name"
                        placeholder="Product name"
                        required
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.units`}
                        label="Quantity"
                        type="number"
                        required
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <RHFTextField
                        onKeyPress={onKeyPressHandler}
                        name={`${keyName}.selling_price`}
                        label="Unit Price"
                        placeholder="Selling Price per unit including discount and taxes"
                        type="number"
                        required
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    {index === fields.length - 1 && (
                        <IconButton size="small" color="primary" onClick={() => append(emptyItemObject)}>
                            <AddCircleTwoTone />
                        </IconButton>
                    )}
                    {fields.length > 1 && (
                        <IconButton size="small" color="error" onClick={() => remove(index)}>
                            <RemoveCircleTwoTone />
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => setAddTaxRates(!addTaxRates)}
                        startIcon={<AddCircleTwoTone fontSize="small" />}
                        sx={{ textTransform: 'none', letterSpacing: 0, fontWeight: 400, fontSize: 12 }}
                    >
                        Add HSN Code, Discount and Tax Rates
                    </Button>
                </Grid>
                {addTaxRates && (
                    <>
                        <Grid item xs={12} md={3}>
                            <RHFTextField
                                onKeyPress={onKeyPressHandler}
                                name={`${keyName}.discount`}
                                label="Discount Amount (Rs.)"
                                placeholder="Given discount"
                                type="number"
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFTextField
                                onKeyPress={onKeyPressHandler}
                                name={`${keyName}.tax`}
                                label="Tax"
                                placeholder="Tax %"
                                type="number"
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFTextField
                                onKeyPress={onKeyPressHandler}
                                name={`${keyName}.hsn`}
                                label="HSN"
                                placeholder="HSN Code"
                                type="number"
                                variant="filled"
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}

export default OrderDetailsRVP;
