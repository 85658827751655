import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import withLayout from "../../hocs/Layout";
import "./ndr.css";
import { Badge, Button, Card, CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import MUITabs from "../../common/MUITabs";
import { FormProvider, RHFDateRangePickerField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import InitiatedNdrData from "./InitiatedNdr";
import ClosedNdrData from "./ClosedNdr";
import PendingNdrData from "./PendingNdr";
import { DoneAllOutlined, PauseCircle, Phone, PublishedWithChangesOutlined, UploadFileOutlined } from "@mui/icons-material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../common/MUIModal";
import { buildFilterConditions, parseDateAndTime } from "../../helpers/UtilityHelper";
import { useGetNDRDataQuery } from "../../redux/commonRTK";
import NDRBulkUpload from "./BulkUploads";
import NDRCall from "./NDRCalls";
import NDRAutomatedRule from "./automatedRule";

const NDRListing = () => {

  const [filterChange, setFiltersChange] = useState(0);
  const [filters, setFilters] = useState(0);
  const [active, setActive] = useState(0);
  const filterConditions = buildFilterConditions(filters);

  const { data: pendingData } = useGetNDRDataQuery({
    andfilter: [
      {
        field: "orderStatus",
        operator: "in",
        value: ["FAILED_DELIVERY", "SHIPMENT_HELD"],
      },
      {
        field: "ndrData.ndrStatus",
        operator: "nin",
        value: ["INITIATED", "CLOSED"],
      },
      ...filterConditions.andfilter
    ],
    offset: 0,
    limit: 1,
    includes: "id",
  })

  const { data: initiatedData } = useGetNDRDataQuery({
    andfilter: [
      {
        field: 'ndrData.ndrStatus',
        operator: 'eq',
        value: 'INITIATED',
      },
      ...filterConditions.andfilter
    ],
    offset: 0,
    limit: 1,
    includes: "id",
  });

  function handleTabChange(event, newValue) {

    setFiltersChange(filterChange + 1);
    setActive(newValue);
  }

  const tabHeaders = [
    {
      key: 1,
      value: "Pending",
      iconPosition: "end",
      icon: (
        <Badge badgeContent={pendingData?.count} color='error' max={10000}>
          <PauseCircle color="info" />
        </Badge>
      ),
    },
    {
      key: 2,
      value: "Initiated",
      iconPosition: "end",
      icon: (
        <Badge badgeContent={initiatedData?.count} color='info' max={10000}>
          <PublishedWithChangesOutlined color="info" />
        </Badge>
      ),
    },
    {
      key: 3,
      value: "Closed",
      iconPosition: "end",
      icon: (
        // <Badge badgeContent={closedData?.count} color='info' max={10000}>
        <DoneAllOutlined color="success" />
        // </Badge>

      )
    },
    {
      key: 4,
      value: "Bulk Actions",
      iconPosition: "end",
      icon: (
        // <Badge badgeContent={closedData?.count} color='info' max={10000}>
        <UploadFileOutlined color="success" />
        // </Badge>

      )
    },
    {
      key: 5,
      value: "Call Recordings",
      iconPosition: "end",
      icon: (
        // <Badge badgeContent={closedData?.count} color='info' max={10000}>
        <Phone color="success" />
        // </Badge>

      )
    },
    // {
    //   key: 6,
    //   value: "Automated Rule",
    //   iconPosition: "end",
    // }

  ];
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    // formState: { isSubmitting }
  } = methods;

  const onSubmit = (data) => {
    setFiltersChange(filterChange + 1)
    setFilters(data);
  }

  return (
    <>
      <div id="main-content" className="container-fluid">
        <Grid item container spacing={4}>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', bgcolor: '#fff', boxShadow: 1 }}>
              <MUITabs
                headers={tabHeaders}
                active={active}
                handleChange={handleTabChange}
              />
            </Box>
          </Grid>
          {[0, 1, 2].includes(active) && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                      </Grid>
                      <Grid item xs={2}>
                        <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                      </Grid>
                      {/* <Grid item xs={2}>
                    <RHFTextField name="customerName" label="Customer Name" color="secondary" variant="filled" />
                  </Grid> */}
                      <Grid item xs={2}>
                        <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                      </Grid>
                      <Grid item xs={2}>
                        <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                      </Grid>
                      <Grid item xs={2}>
                        <RHFTextField name="ndrReason" label="NDR Reason" color="secondary" variant="filled" />
                      </Grid>
                      <Grid item xs={2}>
                        <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                      </Grid>
                      <Grid item xs={12}>
                        <Button type="submit" style={{ "marginTop": 10 }} variant="contained" color="info" >Filter</Button>
                        <Button sx={{ ml: 1 }} type="submit" style={{ "marginTop": 10 }} variant="contained" color="error" onClick={() => {
                          reset({
                            state: '',
                            city: '',
                            awb: '',
                            customerName: '',
                            reference: '',
                            date: ''
                          })
                        }}>Reset</Button>
                      </Grid>
                      {/* <Grid item xs={1}>
                <Button type="submit" style={{ "marginTop": 10 }} variant="contained" color="info"
                  onClick={handleDownload}>Export</Button>
              </Grid> */}
                    </Grid>
                  </FormProvider>
                </CardContent>
              </Card>
            </Grid>
          )}
          {active === 0 && (
            <Grid item xs={12}>
              <PendingNdrData filters={filters} filterChange={filterChange} />
            </Grid>
          )}
          {active === 1 && (
            <Grid item xs={12}>
              <InitiatedNdrData filters={filters} filterChange={filterChange} />
            </Grid>
          )}
          {active === 2 && (
            <Grid item xs={12}>
              <ClosedNdrData filters={filters} filterChange={filterChange} />
            </Grid>
          )}
          {active === 3 && (
            <Grid item xs={12}>
              <NDRBulkUpload />
            </Grid>
          )}
          {active === 4 && (
            <Grid item xs={12}>
              <NDRCall />
            </Grid>
          )}
          {active === 5 && (
            <Grid item xs={12}>
              <NDRAutomatedRule />
            </Grid>
          )}
        </Grid>

      </div>
    </>
  );
};
export function ActionLogModal(props) {
  const { data, logModal, setLogModal } = props;
  const actionLogModal = [
    {
      field: "ndrAction",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Action",
      flex: 0.6,
    },
    {
      field: "reattemptNumber",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Reattempt No.",
      flex: 0.6,
    },
    {
      field: "updatedAddress",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Updated Address",
      flex: 1.5,
    },
    {
      field: "updatedContactNumber",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Updated Contact Number",
      flex: 1,
    },
    {
      field: "cpResponse",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Action Status",
      flex: 0.6,
    },
    {
      field: "actionTakenBy",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Action Taken By",
      flex: 1,
    },
    {
      field: "reattemptRequestDate",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Reattempt Request Date",
      flex: 0.8,
      renderCell: (params) => {
        const { row } = params;
        return parseDateAndTime(row?.ndrCurrentAttemptDate, true)
      },
    },
    {
      field: "actionDate",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Action Date",
      flex: 0.8,
      renderCell: (params) => {
        const { row } = params;
        return parseDateAndTime(row?.actionDate)
      },
    },

  ];
  return (<MUIModal
    open={logModal}
    title="NDR Action Log"
    handleClose={() => setLogModal(false)}
  >
    <ProzoDataGrid
      autoHeight={true}
      columns={actionLogModal}
      rows={data ? data : []}
      paginationMode="client"
      rowCount={data?.length}
      getRowId={(row) => row.actionDate}
    />
  </MUIModal>
  )
}

export default withLayout(NDRListing);
