import React from "react";
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { TableSkeleton } from "../../../../common/Skeletons";
import { Box } from "@mui/system";
import _ from 'lodash'
import PercentIcon from '@mui/icons-material/Percent';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import useGetCourier from "../useGetCourier";

export default function CourierWiseSplit({ data, uniqCourierIds }) {

    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()
    const [tableView, setTableView] = React.useState("percentage")

    if (isLoadingCouriers || isFetchingCouriers) return <TableSkeleton rows={10} />

    const allPresentCouriers = allCouriers.filter(row => uniqCourierIds.includes(row.id))

    const { rows, allColumns } = transformChartDataToTable(data, [
        ...allPresentCouriers.map((z) => {
            const key = tableView === 'percentage' ? `${z.id}_perc` : `${z.id}_count`
            return { key, label: `${z.parent} / ${z.id}` }
        })
    ])

    return (
        <Card elevation={1}>
            <CardHeader title="Courier Wise Split" action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setTableView(v)} value={tableView}>
                        <ToggleButton value="percentage" title="View Data In %">
                            <PercentIcon />
                        </ToggleButton>
                        <ToggleButton value="number" title="View Data In Count">
                            <LooksOneIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent sx={{ px: 0 }}>
                <TableContainer sx={{ maxHeight: 1200, overflowX: 'auto' }}>
                    <Table size="small" density sx={{ borderCollapse: 'collapse', minWidth: 750 }}>
                        <TableHead sx={{ backgroundColor: '#000', color: '#fff' }}>
                            <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                                <TableCell sx={{
                                    borderRight: '1px solid #ccc',
                                    color: '#fff',
                                    position: 'sticky',
                                    left: 0, 
                                    zIndex: 2,
                                    backgroundColor: '#000' 
                                }}>Name</TableCell>
                                {allColumns.map((val, j) => (
                                    <TableCell key={j} sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>{val}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, i) => {
                                return (
                                    <TableRow key={i} sx={{ borderBottom: '1px solid #ccc' }}>
                                        <TableCell sx={{
                                            borderRight: '1px solid #ccc',
                                            position: 'sticky',
                                            left: 0,  
                                            zIndex: 1, 
                                            backgroundColor: '#fff', 
                                            padding: '6px',
                                        }}>{row.name}</TableCell>
                                        {allColumns.map((val, j) => (
                                            <React.Fragment key={j}>
                                                <TableCell sx={{ borderRight: '1px solid #ccc' }}>{_.get(row, val, 0)}</TableCell>
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </CardContent>
        </Card>
    )
}