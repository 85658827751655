import { Box, Grid, Button } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as XLSX from "xlsx/xlsx.mjs";
import { apiConstants, post } from "../../common/constants";
import { toast } from "react-toastify";
import _ from "lodash";
import MUIModal from "../../common/MUIModal";
// import DownloadSampleFile from "../../common/DownloadSampleFile";
import { GetApp } from "@mui/icons-material";
// import BulkSampleFile from './ndr_bulk_order.xlsx';

const BulkOrderAction = (props) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Step 1

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        bulkFile: Yup.mixed().required("Please Select a File"),
      })
    ),
  });
  const { show, handleClose, setReload } = props;
  const hideModal = () => {
    handleClose();
  };

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setIsUploadingFile(true);
    setErrorMessage('')
    try {
      let file = data.bulkFile[0];
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = async function (e) {
        let data = e.target.result;
        let workbook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
        });
        let result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          let roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
          let lowerCase = _.mapValues(roa, (val) => {
            let obj = _.mapKeys(val, (v, k) => {
              return k.toLowerCase();
            });
            return obj;
          });
          if (roa.length > 0) {
            result = lowerCase;
          }
        });
        await post(`${apiConstants.BASE_LOCAL}${apiConstants.NDR_ACTION}`, {
          ndr_waybills_data: _.values(result),
        }).then((res) => {
          setIsUploadingFile(false);
          let result = res?.data?.result?.ndr_update_status?.[0];
          if (result.status === true) {
            toast("Uploaded Successfully", { type: "success" });
            hideModal();
            setReload(reload => reload + 1);
          } else {
            toast(result.responseObj, { type: "error" });
            hideModal();
            setIsUploadingFile(false);
            setReload(reload => reload + 1);
          }
        }).catch((err) => {
          console.log("err", err);
          toast("Some error occurred", { type: "error" });
          setIsUploadingFile(false);
        });
      };
    } catch (err) {
      console.log('e', err);
      setErrorMessage(err.message);
      setIsUploadingFile(false);
    }
  };

  // const handleBulkSample = () => {
  //   getDownload(
  //     `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=NDR_BULK_ACTION`
  //   )
  //     .then((response) => {
  //       const binaryData = [];
  //       binaryData.push(response.data);
  //       const href = URL.createObjectURL(
  //         new Blob(binaryData, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  //       );
  //       const link = document.createElement("a");
  //       link.href = href;
  //       link.setAttribute("download", "bulk-upload-ndr.xlsx");
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(href);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const downloadLocalFile = () => {
  //   const href = URL.createObjectURL(
  //     new Blob([BulkSampleFile], { type: "application/octet-stream" })
  //   );
  //   const link = document.createElement("a");
  //   link.href = href;
  //   link.setAttribute("download", "ndr-bulk-sample.xlsx");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(href);
  // };

  return (
    <>
      <MUIModal open={show} handleClose={hideModal} title="NDR Bulk Update">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider
              methods={methods}
              onSubmit={handleSubmit(onSubmit)}
              className="w-100"
            >
              <RHFFileField name="bulkFile" />
              <Box sx={{ my: 2 }} />
              <LoadingButton
                startIcon={<FileUploadIcon />}
                type="submit"
                size="small"
                loading={isUploadingFile}
                variant="contained"
                color="primary"
              >
                Upload
              </LoadingButton>
              {/* <DownloadSampleFile fileName="NDR_BULK_ACTION" csv={true}/> */}
              <Button
                variant="outlined"
                startIcon={<GetApp />}
                size="small"
                sx={{ ml: 2 }}
                onClick={() =>
                  window.open(
                    "https://djslb35m04okm.cloudfront.net/ndr-bulk-sample.xlsx"
                  )
                }
              >
                Download Sample
              </Button>
              <Grid item xs={12} md={6} style={{ color: "red" }} mt={2}>{errorMessage}</Grid>
            </FormProvider>
          </Grid>
        </Grid>

      </MUIModal>

    </>
  );
};

export default BulkOrderAction;
