import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Stack } from '@mui/material'

export default function ProzoDataGrid(props) {
  let {
    rows,
    columns,
    columnVisibilityModel,
    disableColumnMenu,
    pagination,
    paginationMode,
    checkboxSelection = false,
    rowSelectionModel,
    setRowSelectionModel,
    keepNonExistentRowsSelected,
    disableColumnSelector,
    rowSpacing = true,
    hideFooterPagination,
    disableColumnFilter,
    rowCount,
    page,
    setPage,
    sizePerPage,
    setSizePerPage,
    hideCountNumber,
    boxShadow = 1,
    ...restProps
  } = props;

  const rowsPerPageOptions = [10, 15, 20, 50, 100]
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  return (
    <DataGrid
      columns={columns}
      style={{ background: "white", width: '100%' }}
      fullwidth
      getRowHeight={() => 'auto'}
      rows={rows}
      disableSelectionOnClick={true}
      rowCount={rowCount}
      checkboxSelection={checkboxSelection}
      selectionModel={rowSelectionModel}
      onSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
      paginationMode={paginationMode ? paginationMode : 'server'}
      rowsPerPageOptions={rowsPerPageOptions}
      pagination={pagination ? pagination : true}
      columnVisibilityModel={columnVisibilityModel ? columnVisibilityModel : {}}
      hideFooterPagination={hideFooterPagination ? hideFooterPagination : false}
      disableColumnFilter={disableColumnFilter ? disableColumnFilter : true}
      disableColumnMenu={disableColumnMenu ? disableColumnMenu : true}
      disableColumnSelector={disableColumnSelector ? disableColumnSelector : true}
      onPageSizeChange={setSizePerPage}
      onPageChange={setPage}
      autoHeight={true}
      getRowSpacing={rowSpacing ? getRowSpacing : ''}
      page={page}
      pageSize={sizePerPage}
      getRowId={props.getRowId}
      sx={{
        /* [`& .${gridClasses.row}`]: {
          bgcolor: (theme) =>
            theme.palette.mode === 'light' ? "#eee" : grey[900],
        }, */
        borderRadius: 0,
        boxShadow,
        //mb: 3
      }}
      components={getComponents(props.exportToExcel || false)}
      localeText={{
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) => {
            if (hideCountNumber) {
              return from + "-" + to + " of many";
            }
            return `${from} - ${to} of ${count === Number.MAX_VALUE ? "many" : count
              }`
          }
        }
      }}
      componentsProps={{
        toolbar: {
          printOptions: { disableToolbarButton: true },
        }
      }}
      {...restProps}
    />
  );
}

function getComponents(exportToExcel) {
  let options = {
    NoRowsOverlay: () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No results found!
      </Stack>
    ),
    NoResultsOverlay: () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No results found!
      </Stack>
    )
  }

  if (exportToExcel) {
    options.Toolbar = CustomToolbar
  }

  return options

}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}