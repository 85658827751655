import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, size = 'small', trim = false, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={(event) => {
            let value = event.target.value;

            if (trim) {
              value = value.trim();
            }

            if (other.type === 'number') {
              // field.onChange(+value)
              field.onChange(value === '' ? '' : +value);
            } else {
              field.onChange(value)
            }
          }}
          sx={{ fontSize: 14 }}
          fullWidth
          size={size}
          variant="outlined"
          value={typeof field.value === 'number' && field.value === 0 ? field.value : field.value}
          error={!!error}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          helperText={error?.message}
          {...other}
          label={(
            <span>
              {other.label}
              {other.mandatory && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </span>
          )}
        />
      )}
    />
  );
}
