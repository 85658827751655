import React, { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import ErrorBoundary from "../../../../common/ErrorBoundary"
import { Box } from "@mui/system"
import useRtoPerformance from "./useRtoPerformance"
import { TableSkeleton } from "../../../../common/Skeletons"
import { formatNumber } from "../../../../helpers/UtilityHelper"
import SplitInLineChart from "./SplitInLineChart"
import PincodeAndCityWise from "./PincodeAndCityWise"
import RTOAnalysis from "./RtoAnalysis"
import ReasonAnalysis from "./ReasonAnalysis"
import CourierWiseSplit from "./CourierWiseSplit"
import InfoBox from "../../../../common/InfoBox"
import { useSelector } from "react-redux"

function RtoComponent() {

    const [calcOn, setCalcOn] = React.useState('DOD')
    const [shipType, setShipType] = useState("B2C")
    const { isLoading, data } = useRtoPerformance(calcOn, shipType)
    const { userInfo } = useSelector((state) => state.user)
    const handleChange = (event) => {
        setShipType(event.target.value);
    }
    return (
        <Accordion expanded>
            <AccordionSummary sx={{ boxShadow: 1, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">RTO Performance</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            {(userInfo?.merchant?.isD2CMerchant && userInfo?.merchant?.isPTLMerchant) &&
                                <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Shipment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={shipType}
                                        onChange={handleChange}
                                        label="Shipment Type"
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: 'red', // Change the icon color
                                            },
                                        }}
                                    >
                                        <MenuItem value={"B2C"}>B2C</MenuItem>
                                        <MenuItem value={"B2B"}>B2B</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides analysis on the rto related aspects. All data is populated on basis of the date of shipment creation in Proship.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <InfoBox header="Total Shipments" value={formatNumber(data?.awbRegisteredOrderCount)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InfoBox header="RTO" value={formatNumber(data?.rtoCount)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InfoBox header="RTO %" value={`${data?.overallRtoPercentage}`} />
                                </Grid>
                                <Grid item xs={3}>
                                    <InfoBox header="Avg Delivery Attempts" value={data?.avgDeliveryAttemptCount} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SplitInLineChart data={data?.lineChartData || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReasonAnalysis data={data?.lineChartData || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <PincodeAndCityWise data={data} />
                                </Grid>
                                <Grid item xs={12}>
                                    <RTOAnalysis data={data?.lineChartData || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CourierWiseSplit data={data?.lineChartData || []} courierIds={data?.uniqCourierIds || []} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function RtoSummary() {
    return (
        <ErrorBoundary>
            <RtoComponent />
        </ErrorBoundary>
    )
}