import React, { useState, useEffect } from 'react';
import withLayout from '../../../hocs/Layout';
import './orderList.css';
import { Grid, Button, Badge, Box, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from "../../../common/hook-form"
import { delivered, deliveryFailed, rto, transit, placed, cancelled, lost } from '../../../helpers/UtilityHelper';
// import { /* viewbyDDOptSelected, post, apiConstants, */ getDashboardDateFilter } from '../../../common/constants';
import 'react-toastify/dist/ReactToastify.css';
// import store from '../../../redux/store';
/* import jwt_decode from "jwt-decode";
import { getToken } from '../../../helpers/ReduxHelpers'; */
import MUITabs from '../../../common/MUITabs';
import DeliveryFailedOrders from "./DeliveryFailedOrders";
import DeliveredOrders from "./DeliveredOrders";
import RTOOrders from "./RTOOrders";
import AllOrders from "./AllOrders";
import InTransitOrders from "./InTransit";
import PickupOrders from "./PickupsOrders";
import _ from "lodash";
import { useGetNDRDataQuery } from "../../../redux/commonRTK";
import FlightIcon from '@mui/icons-material/Flight';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import WarningIcon from '@mui/icons-material/Warning';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import FunctionsIcon from '@mui/icons-material/Functions';
import MUIModal from '../../../common/MUIModal';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
// import RHFReactDateRange from '../../../common/hook-form/RHFReactDateRange';
// import RHFDateRangeFieldFromTo from '../../../common/hook-form/RHFDateRangeFieldFromTo';

const ListOrder2 = (props) => {
  const [filterChange, setFiltersChange] = useState(0);
  const [filters, setFilters] = useState(0);
  const [active, setActive] = useState(0);

  // const { fromDate: from_date, toDate: to_date } = getDashboardDateFilter('last_30_days')
  // const { data: orderDataGroupedByStatus = [] } = useGetOrdersGroupByStatusQuery({ from_date, to_date, count_only: true, merchant_id:merchant.merchantId })
  const shipmentType = props.shipmentType ? props.shipmentType : 'B2C';
  let defaultAndFilters = [
    { "field": "orderSubStatus", "operator": "ne", "value": "UNPROCESSED" },
    // {"field": "orderType", "operator": "eq", "value": 'FORWARD SHIPMENT' },
    { "field": "shipmentType", "operator": "eq", "value": shipmentType }];
  if (props.shipmentType === 'B2C') {
    defaultAndFilters.push({ "field": "orderType", "operator": "eq", "value": 'FORWARD SHIPMENT' });
  }

  const allOrderData = [];

  const { data: deliveryFailedOrders } = useGetNDRDataQuery({
    andfilter: [...defaultAndFilters, { "field": "orderStatus", "operator": "in", "value": ['FAILED_DELIVERY'] }],
    "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
    "offset": 0, "limit": 1, "includes": "id",
  })

  const { data: pickupOrders } = useGetNDRDataQuery({
    andfilter: [...defaultAndFilters, { "field": "orderStatus", "operator": "in", "value": ['ORDER_PLACED', 'PICKUP_PENDING', 'OUT_FOR_PICKUP', 'PICKUP_FAILED', 'MANIFESTED', 'AWB_REGISTERED'] }],
    "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
    "offset": 0, "limit": 1, "includes": "id",
  })

  const { data: inTransitOrders } = useGetNDRDataQuery({
    andfilter: [...defaultAndFilters, { "field": "orderStatus", "operator": "in", "value": ['INTRANSIT', 'SHIPMENT_DELAYED', 'CONTACT_CUSTOMER_CARE', 'SHIPMENT_HELD', 'PICKED_UP', 'OUT_FOR_DELIVERY'] }],
    "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
    "offset": 0, "limit": 1, "includes": "id",
  })
  // const deliveredOrders = [];
  // const rtoOrders = [];
  const { data: deliveredOrders } = useGetNDRDataQuery({
    andfilter: [...defaultAndFilters, { "field": "orderStatus", "operator": "eq", "value": 'DELIVERED' }],
    "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
    "offset": 0, "limit": 1, "includes": "id",
  })

  const { data: rtoOrders } = useGetNDRDataQuery({
    andfilter: [...defaultAndFilters, { "field": "orderStatus", "operator": "in", "value": ['RTO_REQUESTED', 'RTO', 'RTO_INTRANSIT', 'RTO_CONTACT_CUSTOMER_CARE', 'RTO_SHIPMENT_DELAY', 'RTO_OUT_FOR_DELIVERY', 'RTO_FAILED', 'RTO_DELIVERED'] }],
    "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
    "offset": 0, "limit": 1, "includes": "id",
  })



  function handleTabChange(event, newValue) {
    setActive(newValue);
  }

  const methods = useForm({ defaultValues: { status: "" } });
  const {
    handleSubmit,
    reset,
    setValue,
    // formState: { isSubmitting }
  } = methods;

  useEffect(() => {
    setValue('status', '')

    // eslint-disable-next-line
  }, [active])


  const tabHeaders = [
    {
      key: 1, value: "All", icon: (
        <Badge badgeContent={allOrderData?.count} color='info' max={10000}>
          <FunctionsIcon color="info" />
        </Badge>
      ),
    },
    {
      key: 2,
      value: "Delivery Failed",
      icon: (
        <Badge badgeContent={deliveryFailedOrders?.count} color='error' max={5000}>
          <SmsFailedIcon color="error" />
        </Badge>
      ),
    },
    {
      key: 3, value: "Pickups", icon: (
        <Badge badgeContent={pickupOrders?.count} color='primary' max={10000}>
          <AccessAlarmsIcon color="info" />
        </Badge>
      ),
    },
    {
      key: 4, value: "In Transit", icon: (
        <Badge badgeContent={inTransitOrders?.count} color='warning' max={10000}>
          <FlightIcon color="warning" />
        </Badge>
      ),
    },
    {
      key: 5, value: "Delivered", icon: (
        <Badge badgeContent={deliveredOrders?.count} color='success' max={10000}>
          <CheckBoxIcon color="success" />
        </Badge>
      ),
    },
    {
      key: 6, value: "RTO", icon: (
        <Badge badgeContent={rtoOrders?.count} color='error' max={10000}>
          <WarningIcon color="error" />
        </Badge>
      ),
    },

  ];

  const onSubmit = (data) => {
    setFiltersChange(filterChange + 1)
    setFilters(data);
  }

  return (
    <div id="main-content" className="container-fluid">
      <Grid item container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%', bgcolor: '#fff', boxShadow: 1 }}>
            <MUITabs
              headers={tabHeaders}
              active={active}
              handleChange={handleTabChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ padding: 1 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFSelectField
                    label="Payment Mode"
                    name="payment_mode"
                    options={[{ label: "COD", value: 'COD' }, { label: "PREPAID", value: 'PREPAID' }]}
                    width="100%"
                  />
                </Grid>

                {/* <Grid item xs={2}>
                  <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                </Grid> */}
                <Grid item xs={2}>
                  <RHFDateRangePickerField label="Created Date" maxDate={new Date()} />
                </Grid>
                {[0, 2, 3, 5].includes(active) && (
                  <Grid item xs={3}>
                    <OrderStatusWiseFilter active={active} />
                  </Grid>
                )}
                {[0].includes(active) && (
                  <>
                    <Grid item xs={2}>
                      <RHFTextField name="email" label="Customer Email" color="secondary" variant="filled" />
                    </Grid>
                    <Grid item xs={2}>
                      <RHFTextField name="phone" label="Customer Phone" color="secondary" variant="filled" />
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <Button type="submit" style={{ "marginTop": 10 }} variant="contained" color="info">Filter</Button>
                  <Button sx={{ ml: 2 }} type="submit" style={{ "marginTop": 10 }} variant="contained" color="error" onClick={() => {
                    reset({
                      state: '',
                      city: '',
                      awb: '',
                      reference: ''
                    })
                  }}>Reset</Button>
                </Grid>

                {/* <Grid item xs={1}>
                <Button type="submit" style={{ "marginTop": 10 }} variant="contained" color="info"
                  onClick={handleDownload}>Export</Button>
              </Grid> */}
              </Grid>
            </FormProvider>
          </Paper>
        </Grid>

        {active === 1 && (
          <Grid item xs={12}>
            <DeliveryFailedOrders shipmentType={shipmentType} filters={filters} filterChange={filterChange} tab={active} count={deliveryFailedOrders?.count} />
          </Grid>
        )}
        {active === 2 && (
          <Grid item xs={12}>
            <PickupOrders shipmentType={shipmentType} filters={filters} filterChange={filterChange} tab={active} count={pickupOrders?.count} />
          </Grid>
        )}
        {active === 3 && (
          <Grid item xs={12}>
            <InTransitOrders shipmentType={shipmentType} filters={filters} filterChange={filterChange} tab={active} count={inTransitOrders?.count} />
          </Grid>
        )}
        {active === 4 && (
          <Grid item xs={12}>
            <DeliveredOrders shipmentType={shipmentType} filters={filters} filterChange={filterChange} tab={active} count={deliveredOrders?.count} />
          </Grid>
        )}
        {active === 5 && (
          <Grid item xs={12}>
            <RTOOrders shipmentType={shipmentType} filters={filters} filterChange={filterChange} tab={active} count={rtoOrders?.count} />
          </Grid>
        )}
        {active === 0 && (
          <Grid item xs={12}>
            <AllOrders shipmentType={shipmentType} filters={filters} filterChange={filterChange} tab={active} count={allOrderData?.count} />
          </Grid>
        )}

      </Grid>
    </div>
  );
};

export function OrderStatusWiseFilter({ active }) {
  let options = [...deliveryFailed, ...rto, ...transit, ...delivered, ...placed, ...cancelled, ...lost]

  if (active === 1) {
    options = deliveryFailed
  } else if (active === 2) {
    options = placed
  }
  else if (active === 3) {
    options = transit
  } else if (active === 4) {
    options = delivered
  } else if (active === 5) {
    options = rto
  }

  options = options.map((op) => ({ label: _.startCase(op), value: op }))

  return (
    <RHFSelectField name="status" width="100%" variant="filled" label="Order Status" options={_.orderBy(options, 'label')} />
  )
}

export const exclude = "returnInfo,itemList.hsn,deliveryTime,itemList.itemUrl,itemList.itemDescription,actualDeliveryTat,proshipDeliveryTat,courierDeliveryTat,actualPickUpTime,lastStatusUpdateTime,edd,epd,lifeCycleComplete,courier,merchantDetail,courierId,version,order_history,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,merchantPricing.pricingObj,courierPricingRTO,modifiedByUser,lastModifiedDate,terminalStatus,merchantPricingRTO.merchantRateCard,merchantPricingRTO.pricingObj";

export function ItemListModal(props) {
  const { data, itemModal, setItemModal } = props;
  const columnsItemModal = [
    {
      field: "sku_id",
      headerAlign: "left",
      hideSortIcons: true,
      headerName: "SKU",
      flex: 1,
    },
    {
      field: "item_name",
      headerAlign: "left",
      hideSortIcons: true,
      headerName: "SKU Name",
      flex: 2,
    },
    {
      field: "units",
      headerAlign: "left",
      hideSortIcons: true,
      headerName: "Units",
      flex: 0.6,
    },
    {
      field: "selling_price",
      headerAlign: "left",
      hideSortIcons: true,
      headerName: "Selling Price",
      flex: 1,
    },
    {
      field: "tax",
      headerAlign: "left",
      hideSortIcons: true,
      headerName: "Tax",
      flex: 1,
    },
  ];
  return (<MUIModal
    open={itemModal}
    title="SKU List"
    handleClose={() => setItemModal(false)}
  >
    <ProzoDataGrid
      autoHeight={true}
      columns={columnsItemModal}
      rows={data?.length > 0 ? data?.map((val, index) => ({ ...val, id: index })) : []}
      getRowId={(row) => row?.id}
      paginationMode="client"
      rowCount={data?.length}
    />
  </MUIModal>
  )
}

export default withLayout(ListOrder2);
