import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { isAdmin, hasPageAccess } from "../helpers/ReduxHelpers";
import { Avatar, Button, ListItemIcon, Typography } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { markAsView } from "../redux/notificationSlice"
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PasswordIcon from '@mui/icons-material/Password';
import { usePageTitle } from '../common/PageTitleContext';
import MarqueeNotification from "../common/Notification"
import { apiConstants, postGeneric } from '../common/constants';

const menuPaperProps = {
    sx: {
        borderRadius: 1,
        '& li': {
            px: 2,
            py: 1,
            fontWeight: 500
        },
        p: 0,
        overflow: 'visible',
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

export default function TopBar(props) {
    // const { setEnabled } = props;
    // const [isLoading, setIsLoading] = useState(false)
    const [notificationList, setNotificationList] = useState([])
    const dispatch = useDispatch()
    const { user: { userInfo }, notifications } = useSelector((state) => state);
    const { pageTitle } = usePageTitle();
    let navigate = useNavigate();


    const onLogOut = () => {
        dispatch({ type: 'USER_LOGGED_OUT' });
    }

    const markNotificationRead = (notificationId) => {
        dispatch(markAsView({ notificationId }))
    }

    useEffect(() => {
        window?.FreshworksWidget('hide', 'launcher')
    }, []);

    const unreadNotifications = notifications.filter(noti => !noti.view)

    const openWidget = () => {
        window.FreshworksWidget('open');
        window.FreshworksWidget('identify', 'ticketForm', {
            // name: userInfo?.username,
            email: userInfo?.username,
        }, {
            formId: 88000047445 // Ticket Form ID
        });
        window.FreshworksWidget('prefill', 'ticketForm', {
            group_id: 88000099283,
            type: "Problem",
            custom_fields: {
                cf_brand_name: userInfo?.merchant?.name
            }
        });
    }
    // const accessList = getRole();
    // const VerifiedRole = _.intersection(accessList, ["ROLE_PROSHIPADMIN", "ROLE_ADMIN"])

    useEffect(() => {
        // setIsLoading(true)
        postGeneric(`${apiConstants.GET_NOTIFICATIONS}`, {
            "andfilter": [{
                field: "active",
                operator: "eq",
                value: true,
            }],
            "limit": 10
        }).then((res) => {
            setNotificationList(res?.data?.data)
            // setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            // setIsLoading(false)
        })
    }, [])


    return (
        <Box sx={{
            flexGrow: 1,
            ml: {
                md: '230px',
                mb: 2
            },
            paddingTop: notificationList?.length > 0 ? '40px' : '0px',
        }}>
            {notificationList?.length > 0 &&
                <MarqueeNotification notificationList={notificationList} />
            }
            <AppBar position="static" sx={{ background: '#fff', boxShadow: 0, borderBottom: "1px solid rgba(145, 158, 171, 0.24)" }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        sx={{
                            display: {
                                md: 'none'
                            },
                            mr: 2
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color="black" variant={'subtitle1'} component="div">
                        {pageTitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <PopupState variant="popover" popupId="notification-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    {/* <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        disableElevation={true}
                                        onClick={() => openWidget()}
                                        startIcon={<HelpCenterRounded />}>
                                        Help
                                    </Button> */}
                                    {/* {VerifiedRole?.length > 0 &&
                                        <Button
                                            size="small"
                                            variant='outlined'
                                            color='warning'
                                            onClick={() => setEnabled(enabled => true)}
                                            sx={{
                                                textTransform: 'capitalize',
                                                color: '#212b36c2',
                                            }}>
                                            Quick Tour
                                        </Button>
                                    } */}
                                    <Button
                                        {...bindTrigger(popupState)}
                                        size="small"
                                        variant='outlined'
                                        color='warning'
                                        onClick={() => openWidget()}
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: '#212b36c2',
                                            ml: 2
                                        }}
                                        id='menu-help'
                                    >Help
                                    </Button>
                                    <IconButton
                                        {...bindTrigger(popupState)}
                                        disabled={notifications.length === 0}
                                        size="small"
                                    >
                                        <Badge badgeContent={unreadNotifications.length} color="error">
                                            <NotificationsIcon size="small" />
                                        </Badge>
                                    </IconButton>
                                    <Menu
                                        {...bindMenu(popupState)}
                                        keepMounted
                                        PaperProps={menuPaperProps}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        {notifications.map((noti) => (
                                            <MenuItem onMouseEnter={() => markNotificationRead(noti.notificationId)} onClick={() => window.open(noti.url)} key={noti.notificationId} divider>
                                                <Box>
                                                    <Typography fontWeight={500}>{noti.title}</Typography>
                                                    <Typography whiteSpace="break-spaces" variant="body2">{noti.description}</Typography>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>


                        <PopupState variant="popover" popupId="profile-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <Button
                                        {...bindTrigger(popupState)}
                                        size="small"
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: '#212b36c2',
                                        }}
                                        endIcon={<Avatar sx={{
                                            backgroundColor: "#3783e7",
                                            borderRadius: '5px',
                                            height: 28,
                                            width: 35
                                        }}>{userInfo?.merchant?.name?.charAt(0)}</Avatar>}
                                    >
                                        Hi, {userInfo?.merchant?.name?.split(' ').shift()}
                                    </Button>
                                    <Menu
                                        {...bindMenu(popupState)}
                                        keepMounted
                                        PaperProps={menuPaperProps}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >

                                        <MenuItem dense divider sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Typography fontWeight={500}>{userInfo?.merchant?.name}</Typography>
                                            <Typography variant='body2'>{userInfo?.username}</Typography>
                                        </MenuItem>
                                        {(isAdmin() || hasPageAccess("/setting")) && (
                                            <MenuItem dense onClick={() => navigate("/setting")}>
                                                <ListItemIcon>
                                                    <Settings fontSize='small' />
                                                </ListItemIcon>
                                                Settings
                                            </MenuItem>
                                        )}
                                        <MenuItem dense onClick={() => navigate("/change-password")}>
                                            <ListItemIcon>
                                                <PasswordIcon color="warning" fontSize="small" />
                                            </ListItemIcon>
                                            Change Password
                                        </MenuItem>
                                        <MenuItem dense onClick={onLogOut}>
                                            <ListItemIcon>
                                                <Logout fontSize="small" color="error" />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}