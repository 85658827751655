import React from "react";
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { rvpAgeing, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { Box } from "@mui/system";
import _ from 'lodash'
import PercentIcon from '@mui/icons-material/Percent';
import LooksOneIcon from '@mui/icons-material/LooksOne';

export default function DeliveryAgeing({ data }) {
    const [tableView, setTableView] = React.useState("percentage")

    const { rows, allColumns } = transformChartDataToTable(data, [
        ...rvpAgeing.map((z, order) => {
            const key = tableView === 'percentage' ? `Delivery_${z}_perc` : `Delivery_${z}_count`
            return { key, label: z, order }
        })
    ])

    return (
        <Card elevation={1}>
            <CardHeader title="Delivery Ageing" action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setTableView(v)} value={tableView}>
                        <ToggleButton value="percentage" title="View Data In %">
                            <PercentIcon />
                        </ToggleButton>
                        <ToggleButton value="number" title="View Data In Count">
                            <LooksOneIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent sx={{ px: 0 }}>
                <TableContainer sx={{ maxHeight: 1200 }}>
                    <Table size="small" density sx={{ borderCollapse: 'collapse' }}>
                        <TableHead sx={{ backgroundColor: '#000', color: '#fff' }}>
                            <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                                <TableCell sx={{ borderRight: '1px solid #ccc', color: '#fff',position: 'sticky',
                                    left: 0, 
                                    zIndex: 2,
                                    backgroundColor: '#000'  }}>Name</TableCell>
                                {allColumns.map((val, j) => (
                                    <TableCell key={j} sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>{val}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(_.orderBy(rows, 'order', 'asc')).map((row, i) => {
                                return (
                                    <TableRow key={i} sx={{ borderBottom: '1px solid #ccc' }}>
                                        <TableCell sx={{ borderRight: '1px solid #ccc',position: 'sticky',
                                            left: 0,  
                                            zIndex: 1, 
                                            backgroundColor: '#fff', 
                                            padding: '6px', }}>{row.name}</TableCell>
                                        {allColumns.map((val, j) => (
                                            <React.Fragment key={j}>
                                                <TableCell sx={{ borderRight: '1px solid #ccc' }}>{_.get(row, val, 0)}</TableCell>
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}