import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  formatCurrency,
  formatDate,
} from "../../helpers/UtilityHelper";
import { VerticalBarChart } from "../charts/ColumnChart";
import { Link } from "react-router-dom";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import SingleProgress from "../charts/SingleProgress";
import OrderItemsModal from "../../common/OrderItemsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { apiConstants, post } from "../../common/constants";
import { Stack } from "@mui/system";

export default function PickupDelay({ pickUpDelayed }) {
  const [sizePerPage, setSizePerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [orderItems, setOrderItems] = React.useState(null);
  const [openList, setOpenList] = React.useState(false);

  const delayedAgingChartData = _(pickUpDelayed)
    .groupBy(function (item) {
      if (item.pickupPendingAgeingDays >= 7) return "7+";
      return `${item.pickupPendingAgeingDays}`;
    })
    .map(function (items, key) {
      return { name: key, value: items.length };
    })
    .orderBy("name", "asc")
    .value();

  const noOfAttemptsChart = _(pickUpDelayed)
    .groupBy(function (item) {
      return item.pickupFailedCount;
    })
    .map(function (items, key) {
      const totalItems = pickUpDelayed.length;
      const itemsGrouped = items.length;
      return {
        name: key,
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();


  const courierWiseSplit = _(pickUpDelayed)
    .groupBy("courierName")
    .map(function (items, key) {
      const totalItems = pickUpDelayed.length;
      const itemsGrouped = items.length;
      return {
        name: key.replaceAll('Forward','').replaceAll('_',' ').replaceAll('FORWARD','').replaceAll('-',''),
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();

  const paymentModeSplit = _(pickUpDelayed)
    .groupBy("paymentMode")
    .map(function (items, key) {
      const totalItems = pickUpDelayed.length;
      const itemsGrouped = items.length;
      return {
        name: key,
        value: itemsGrouped,
        perc: Math.round((itemsGrouped / totalItems) * 100),
      };
    })
    .orderBy("perc", "desc")
    .value();
  

  const pendingPickupKeys = ["On Time", "+1 Day", "+2 Day", "3+ Day"];

  const pendingPickupGroupedByCourier = _(pickUpDelayed)
    .groupBy("courierName")
    .map(function (items, key) {
      const itemsGrouped = _(items).groupBy("pickupDelayLabel").value();
      let obj = { name: key };
      const total = items.length;
      const totalRevenue = _.sumBy(items, "invoiceValue");
      for (let k of Object.keys(itemsGrouped)) {
        const totalCount = itemsGrouped[k].length;
        const totalRevenueCourier = _.sumBy(itemsGrouped[k], "invoiceValue");
        obj[k] = totalCount;
        obj[`${k}_perc`] = (totalCount / total) * 100;
        obj[`${k}_revenue`] = totalRevenueCourier;
        obj[`${k}_revenue_perc`] = (totalRevenueCourier / totalRevenue) * 100;
      }
      return obj;
    })
    .value();

  const getItemList = (awb) => {
    const bodyData = {
      includes: "itemList",
      andfilter: [
        {
          field: "awbNumber",
          operator: "eq",
          value: awb,
        },
      ],
    };

    post(`${apiConstants.BASE_LOCAL}${apiConstants.ORDER_LISTS}`, bodyData)
      .then((response) => {
        setOpenList(true);
        setOrderItems(response.data.data[0].item_list);
      })
      .catch((err) => {
        setOpenList(false);
        console.error(err);
      });
  };

  const handleClose = () => {
    setOrderItems(null);
    setOpenList(false);
  };
  return (
    <Grid container spacing={2}>
      <OrderItemsModal
        items={orderItems}
        setOpen={handleClose}
        openList={openList}
      />

      <Grid item xs={12} md={5}>
        <Card>
          <CardHeader
            title="Delayed Pickup Ageing"
            subheader="This sections contains the shipments which are yet to be picked up by the courier partners. The shipments with no pickup attempts and one or more than one pickup attempts are part of this data. "
          />
          <CardContent>
            <VerticalBarChart
              height={362}
              data={delayedAgingChartData}
              chartOptions={{
                yaxis: "name",
                yLabel: "Ageing (in Days)",
                xLabel: "Number of Shipments",
                data: [
                  {
                    dataKey: "value",
                    label: "Shipments",
                  },
                ],
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={7}>
        <Stack spacing={1}>
          <Card>
            <CardHeader title="Number of Pickup Attempts" />
            <CardContent>
              <SingleProgress data={noOfAttemptsChart} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Courier Partners for Delayed Shipments" />
            <CardContent>
              <SingleProgress data={courierWiseSplit} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Payment Mode of Delayed Shipments" />
            <CardContent>
              <SingleProgress data={paymentModeSplit} />
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title="Pickup Delays"
            action={
              <DownloadExcelMultipleSheets
                sheets={[
                  {
                    name: "Pickup Delayed Shipments",
                    data: pickUpDelayed,
                    columns: [
                      { label: "Reference", value: "shippingLabelId" },
                      { label: "Courier Partner", value: "courierName" },
                      { label: "AWB Number", value: "awb_number" },
                      {
                        label: "Order Date",
                        value: (row) =>
                          formatDate(row.order_date, "dd MMM, yyyy"),
                      },
                      { label: "Zone", value: "merchantZone" },
                      { label: "Delay", value: "pickupDelayText" },
                      { label: "Pickup Attempt", value: "failedPickup" },
                      { label: "Invoice Value", value: "invoice_value" },
                    ],
                  },
                ]}
                filename="Delayed Shipments"
              />
            }
          />
          <CardContent sx={{ px: 0 }}>
            <ProzoDataGrid
              columns={[
                {
                  field: "shippingLabelId",
                  headerName: "Reference",
                  width: 140,
                },
                {
                  field: "Reference",
                  headerName: "ID",
                  width: 180,
                  renderCell: ({ row }) => {
                    return (
                      <Box
                        width={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        {/* <Button
                          sx={{
                            padding: "0px 5px",
                            fontSize: "11px",
                            backgroundColor: "aliceblue",
                            borderRadius: "3px",
                          }}
                          startIcon={
                            <VisibilityIcon
                              sx={{ fontSize: "15px !important" }}
                            />
                          }
                          variant="link"
                          onClick={() => setOrderItems(row.item_list)}
                        >
                          View Items
                        </Button> */}

                        <Button
                          sx={{
                            padding: "0px 5px",
                            fontSize: "11px",
                            backgroundColor: "aliceblue",
                            borderRadius: "3px",
                          }}
                          startIcon={
                            <VisibilityIcon
                              sx={{ fontSize: "15px !important" }}
                            />
                          }
                          variant="link"
                          onClick={() => {
                            getItemList(row.awbNumber);
                          }}
                        >
                          View Items
                        </Button>
                      </Box>
                    );
                  },
                },
                {
                  field: "courierName",
                  headerName: "Courier Partner",
                  width: 180,
                },
                {
                  field: "invoice_value",
                  headerName: "Invoice Value",
                  width: 120,
                  renderCell: ({ row }) => formatCurrency(row.invoiceValue),
                },
                {
                  field: "awb_number",
                  headerName: "AWB Number",
                  width: 140,
                  renderCell: ({ row }) => {
                    return (
                      <Link to={`/order-detail?waybill=${row.awbNumber}`}>
                        {row.awbNumber}
                      </Link>
                    );
                  },
                },
                {
                  field: "order_date",
                  headerName: "Created Date",
                  width: 180,
                  renderCell: ({ row }) =>
                    formatDate(row.awbRegisteredDate, "dd MMM, yyyy hh:mm aa"),
                },

                { field: "merchantZone", headerName: "Zone", width: 100 },

                {
                  field: "estimatedPickupDate",
                  headerName: "Est Pickup",
                  width: 180,
                  renderCell: ({ row }) => {
                    return formatDate(
                      row.estimatedPickupDate,
                      "dd MMM, yyyy hh:mm aa"
                    );
                  },
                },

                {
                  field: "pickupDelayText",
                  headerName: "Delay",
                  width: 120,
                  renderCell: ({ row }) => {
                    return (
                      <Typography
                        fontSize="14px"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {row.pickupDelayText}
                      </Typography>
                    );
                  },
                  cellClassName: ({ row }) => {
                    if (row.pickupDelayInDays >= 8) {
                      return "delay-dark-red";
                    }

                    return `delay-cell-${row.pickupDelayInDays}`;
                  },
                },
                {
                  field: "failedPickup",
                  headerName: "Pickup Attempts",
                  width: 120,
                  align: "center",
                  headerAlign: "center",
                },
              ]}
              autoHeight={true}
              rowHeight={70}
              rows={_.orderBy(
                pickUpDelayed,
                "awbRegisteredDate",
                "desc"
              ).splice(page * sizePerPage, sizePerPage)}
              sizePerPage={sizePerPage}
              setSizePerPage={setSizePerPage}
              setPage={setPage}
              page={page}
              pagination={true}
              hideFooterPagination={false}
              filterMode={"server"}
              rowCount={pickUpDelayed.length}
              getRowId={(row) => row.id}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Courier Wise Delay" />
          <CardContent>
            {pendingPickupGroupedByCourier.map((row) => {
              const data = pendingPickupKeys.map((key) => ({
                name: key,
                value: row[key] || 0,
                perc: Math.round(row[`${key}_perc`] || 0),
              }));
              return (
                <Box mb={3}>
                  <Typography variant="caption" fontWeight={500}>
                    {row.name}
                  </Typography>
                  <SingleProgress data={data} />
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Courier Wise Revenue" />
          <CardContent>
            {pendingPickupGroupedByCourier.map((row) => {
              const data = pendingPickupKeys.map((key) => {
                return {
                  name: key,
                  value: row[`${key}_revenue`] || 0,
                  perc: Math.round(row[`${key}_revenue_perc`] || 0),
                };
              });
              return (
                <Box mb={3}>
                  <Typography variant="caption" fontWeight={500}>
                    {row.name}
                  </Typography>
                  <SingleProgress data={data} />
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
  );
}
