import { Grid } from "@mui/material";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { useEffect, useState } from "react";
import { apiConstants, post } from "../../../common/constants"

function QcDetails({ data }) {
    const [qcData, setQcData] = useState();
    const expiredUrl = data.qcDetails?.images?.find((elem) => elem.signedURLExpired === true)

    useEffect(() => {
        if (expiredUrl) {
            post(`${apiConstants.BASE_LOCAL}${apiConstants.REFRESH_S3_URL}`, data.qcDetails?.images).then((res) => {
                setQcData(res.data?.responseObj)
            }).catch((err) => {
                console.log(err);
            })
        } else {
            setQcData(data?.qcDetails?.images)
        }
    }, [expiredUrl])

    const columns = [
        {
            field: 'signedLink',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Link',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container spacing={2} sx={{ height: 50, alignItems: 'center' }}>
                            <Grid item xs={12}>
                                <a href={row?.signedLink} target="_blank" rel="noreferrer">View</a>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        }
    ]

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={(qcData && qcData.map((row, index) => ({ ...row, id: index }))) || []}
                        pagination={false}
                        hideFooterPagination={true}
                        rowHeight={120}
                        getRowId={(row) => row.id}
                    />

                </Grid>
            </Grid>
        </>
    )
}

export default QcDetails;